<nova-media
	[copyright]="copyright"
	[copyrightPosition]="copyrightPosition"
	[version]="version"
	[mediaId]="mediaId"
	[ngStyle]="setMyStyles(getStyle())"
	[bg]="true"
    [backgroundPosition]="backgroundPosition"
	[fallback]="fallback"
	[forceVideo]="forceVideo"
></nova-media>
