import * as tslib_1 from "tslib";
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
var PageCoverAtlasCopcoComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageCoverAtlasCopcoComponent, _super);
    function PageCoverAtlasCopcoComponent(nova, uiEventHandler, sanitizer, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.sanitizer = sanitizer;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        return _this;
    }
    Object.defineProperty(PageCoverAtlasCopcoComponent.prototype, "blue", {
        get: function () {
            return this.data.ciEdge === 'blue';
        },
        enumerable: true,
        configurable: true
    });
    PageCoverAtlasCopcoComponent = tslib_1.__decorate([
        NovaTemplate('PageCoverAtlasCopcoComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            DomSanitizer,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], PageCoverAtlasCopcoComponent);
    return PageCoverAtlasCopcoComponent;
}(Page));
export { PageCoverAtlasCopcoComponent };
