import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import { ActivatedRoute, Router } from '@angular/router';
var PageBookScrollSliderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageBookScrollSliderComponent, _super);
    function PageBookScrollSliderComponent(nova, sanitizer, mediaService, uiEventHandler, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.sanitizer = sanitizer;
        _this.mediaService = mediaService;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        _this.sliderHeight = '100vh';
        return _this;
    }
    PageBookScrollSliderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sliderHeight = $('body').width() < 991 ? this.data.mediaSize + "vh" : '100vh';
        this.mediaService.loadMedia(this.data.medias, true).then(function (data) {
            _this.media = data.map(function (val) {
                val.pos = _this.calcPosition(val.org['description_position'] || 'center-center');
                return val;
            });
            setTimeout(function () {
                var options = {
                    autoScaleSlider: false,
                    navigateByClick: false,
                    arrowsNav: true,
                    arrowsNavAutoHide: true,
                    arrowsNavHideOnTouch: true,
                    imageScalePadding: 0,
                    imageScaleMode: 'fill',
                    loop: true,
                    keyboardNavEnabled: false,
                    globalCaption: true,
                    imgHeight: 800
                };
                if (_this.data.showThumbs && _this.data.showThumbs === 'yes' && !_this.uiEventHandler.isMobile()) {
                    options['controlNavigation'] = 'thumbnails';
                    options['thumbs'] = {
                        appendSpan: true,
                        firstMargin: true,
                        paddingBottom: 4,
                        fitInViewport: false
                    };
                }
                if (_this.data.slider_speed && _this.data.slider_speed !== 0) {
                    options['autoPlay'] = {
                        enabled: true,
                        stopAtAction: false,
                        pauseOnHover: true,
                        delay: _this.data.slider_speed * 1000
                    };
                }
                $(_this.royalSlider.nativeElement).royalSlider(options);
            }, 100);
        });
    };
    PageBookScrollSliderComponent.prototype.getCopyrightUrl = function (c) {
        if (c) {
            if (!c.startsWith('http://') && !c.startsWith('https://')) {
                return 'http://' + c;
            }
            return c;
        }
    };
    PageBookScrollSliderComponent.prototype.getMediaUrl = function (data) {
        return this.mediaService.getMediaUrlByData(data, !this.uiEventHandler.isMobile()
            ? !this.uiEventHandler.isTablet()
                ? 'vertical-wide'
                : 'horizontal-wide'
            : 'horizontal-wide');
    };
    PageBookScrollSliderComponent.prototype.calcPosition = function (posLogo) {
        var logoStyle = '';
        var flexboxStyle = '';
        var posLogoArr = posLogo.split('-');
        if (posLogoArr[0] === 'top') {
            flexboxStyle += 'justify-content: flex-start;';
        }
        else if (posLogoArr[0] === 'bottom') {
            flexboxStyle += 'justify-content: flex-end;';
        }
        else if (posLogoArr[0] === 'center') {
            flexboxStyle += 'justify-content: center;';
        }
        if (posLogoArr[1] === 'left') {
            logoStyle += 'align-self: flex-start;text-align: left;';
        }
        else if (posLogoArr[1] === 'right') {
            logoStyle += 'align-self: flex-end;text-align: right;';
        }
        else if (posLogoArr[1] === 'center') {
            logoStyle += 'align-self: center;text-align: center;';
        }
        return {
            logo: this.convertCSS(logoStyle),
            flexbox: this.convertCSS(flexboxStyle)
        };
    };
    PageBookScrollSliderComponent.prototype.convertCSS = function (css) {
        var input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {}, attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    };
    PageBookScrollSliderComponent.prototype.setMyStyles = function (obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    };
    PageBookScrollSliderComponent = tslib_1.__decorate([
        NovaTemplate('PageBookScrollSliderComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            DomSanitizer,
            MediaService,
            UIEventHandlerService,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], PageBookScrollSliderComponent);
    return PageBookScrollSliderComponent;
}(Page));
export { PageBookScrollSliderComponent };
