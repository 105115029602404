import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { SSRService } from '../../../_services/ssr.service';
import { Router, NavigationEnd } from '@angular/router';
import { MagazineService } from '../../../_services/magazine.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { ColorFormatter } from '../../../novamag-api/Types';
import { OnChanges } from '@angular/core/src/metadata/lifecycle_hooks';

@Component({
    selector: 'nova-fti-title-bar',
    templateUrl: './nova-fti-title-bar.component.html',
    styleUrls: ['./nova-fti-title-bar.component.scss']
})
export class NovaFtiTitleBarComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public issue;
    @Output() toggleOverlay = new EventEmitter<boolean | undefined>();
    @Output() pageChange = new EventEmitter();
    @Input() public overlayVisible = false;

    public nav;
    public font;
    public infoPage: string;
    public homeLinks;
    public magData;
    public selectStartPage: boolean;
    public menuOpen = false;

    private sub: Subscription;
    private subFont: Subscription;
    private subRouter: Subscription;
    private logoSub: Subscription;

    currentPage: number;
    pageNum: number;

    constructor(
        public nova: NovaAPIService,
        private ssr: SSRService,
        private router: Router,
        private mag: MagazineService,
        public ui: UIEventHandlerService
    ) {}

    ngOnInit() {
        this.sub = this.mag.getMagazine().subscribe(data => {
            this.magData = data;
            this.nav = data.navigation;
            this.selectStartPage = data.selectStartPage === 'archiv';
            this.subFont = this.nova.getFont(data.navigation.font).subscribe(fontData => {
                this.font = fontData.name;
            });
        });

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.getCurrentPage();
            }
        });

        this.getCurrentPage();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.issue && changes.issue.currentValue !== changes.issue.previousValue) {
            this.getCurrentPage();
        }
    }

    getCurrentPage() {
        const params = this.router.routerState.root.firstChild.params['value'];
        const pageHandle = params.pageHandle;

        this.infoPage = this.nova.issue.infoPage;
        this.homeLinks = this.nova.issue.homeLinks;

        if (pageHandle) {
            this.nova.pages.forEach((page, index) => {
                if (page.handle === pageHandle) {
                    this.currentPage = index;
                }
            });

            this.pageNum = this.nova.pages.length;
        }
    }

    get pageTitle() {
        return this.ui.getContentTableEntryName(this.nova.pages[this.currentPage]);
    }

    toggleFtiOverlay(event) {
        // hacky way to stop rendering twice
        if (event.isTrusted && this.ui.isMobile()) {
            return;
        }

        this.toggleOverlay.emit();
    }

    getColor(c) {
        return new ColorFormatter(c).rgba();
    }

    mobileBG() {
        return new ColorFormatter(this.nav.backgroundColor).rgba();
    }

    ngOnDestroy() {
        this.sub && this.sub.unsubscribe();
        this.subFont && this.subFont.unsubscribe();
        this.subRouter && this.subRouter.unsubscribe();
        this.logoSub && this.logoSub.unsubscribe();
    }

    goToInfoPage(pageId) {
        const pageIndex = this.nova.pages.findIndex(page => page._id === pageId);
        this.pageChange.emit(pageIndex + 1);
    }

    goToIndexPage() {
        const pageIndex = this.nova.pages.findIndex(page => page.templateName.indexOf('index-') != -1);
        this.pageChange.emit(pageIndex + 1);
    }

    goToUrl(url: string) {
        const currentPage = this.issue.pages[this.currentPage];

        if (currentPage.templateName === 'blog-index') {
            this.router.navigate([this.router.url.substring(0, this.router.url.lastIndexOf('/'))]);

            return;
        }

        this.router.navigate([url], {
            queryParamsHandling: 'preserve'
        });
    }
}
