import { Component, OnChanges, Input } from '@angular/core';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { MagazineService } from 'app/_services/magazine.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';

@Component({
    selector: 'nova-london-overlay-contact',
    templateUrl: './nova-london-overlay-contact.component.html',
    styleUrls: ['./nova-london-overlay-contact.component.scss']
})
export class NovaLondonOverlayContactComponent implements OnChanges {
    @Input() magazine;
    @Input() issue;
    @Input() public font;

    public contactForm;
    public contactLabel;

    constructor(
        public nova: NovaAPIService,
        public mag: MagazineService,
        public uiEventHandler: UIEventHandlerService
    ) {}

    ngOnChanges() {
        this.contactLabel = this.issue.contact_label || this.magazine.contact_label;
        this.contactForm =
            (this.issue.contact_enabled && this.issue.contact_form) ||
            (this.magazine.contact_enabled && this.magazine.contact_form);
    }
}
