<div class="magazine" *ngIf="loaded">
	<nova-ci-style></nova-ci-style>
	<div class="ci-bar" [class.nomag]="!showMagazine">
		<div class="shine"></div>
	</div>
	<nova-title-bar [issue]="nova.issue"
		*ngIf="navType === 'hamburg' && nova.issue && (!uiEventHandler.isMobile() || nova.issue.showMobileLogo == 'yes')"
		[hidden]="!showMagazine || isPhantomJS() || uiEventHandler.hideTitleBar">
	</nova-title-bar>

	<nova-london-title-bar (openOverlay)="londonOverlay.visible = true;" [magazine]="magazine" [issue]="nova.issue"
		(pageChange)="goTo($event)" *ngIf="navType === 'london' && nova.issue"
		[hidden]="!showMagazine || isPhantomJS() || uiEventHandler.hideTitleBar">
	</nova-london-title-bar>

	<nova-budapest-title-bar (openOverlay)="budapestOverlay.visible = true;" [issue]="nova.issue"
		(pageChange)="goTo($event)" *ngIf="navType === 'budapest' && nova.issue"
		[hidden]="!showMagazine || isPhantomJS() || uiEventHandler.hideTitleBar">
	</nova-budapest-title-bar>

	<nova-fti-title-bar (toggleOverlay)="toggleOverlay(!ftiOverlay.visible)" [issue]="nova.issue" (pageChange)="goTo($event)" [overlayVisible]="ftiOverlay && ftiOverlay.visible"
		*ngIf="navType === 'fti' && nova.issue"
		[hidden]="!showMagazine || isPhantomJS() || uiEventHandler.hideTitleBar">
	</nova-fti-title-bar>

	<div class="content-wrap" [ngClass]="getNavClass()">
		<div class="backdrop" *ngIf="navType === 'fti' && ftiOverlay && ftiOverlay.visible" (click)="toggleOverlay(false)">
		</div>
		<main [@slideInOutAnimation]="prepareRouteTransition(outlet)"
			(@slideInOutAnimation.done)="animationDone($event)">
			<router-outlet #outlet="outlet"></router-outlet>
		</main>

		<nova-ci-logo></nova-ci-logo>

		<div class="token-info"
			*ngIf="ssr.hasToken() && uiEventHandler.showDisabledMsg && isDisabled() != '' && !nova.isScreenShot">
			<span> <b>Vorschau</b> - <span [innerHtml]="isDisabled()"></span> </span>
			<i class="icon-cancel" (click)="uiEventHandler.showDisabledMsg = false"></i>
		</div>
		<nav *ngIf="ssr.isBrowser && magazine" class="right-nav" data-lp-show-on-access
            [class.right-nav--ta]="nova && nova.issue && nova.issue.travelAgency"
			[hidden]="!nova.issue || nova.isScreenShot || uiEventHandler.disableActionNav">
			<nova-nav-border-item [hidden]="
					!uiEventHandler.customContentTable ||
					!uiEventHandler.showMenu ||
					!nova.pages ||
					nova.pages.length == 0
				" class="icon custom-content-table" (click)="customContentTable()" id="openContentTable" [class.openSub]="
					uiEventHandler.topClose ||
					uiEventHandler.showContactForm ||
					uiEventHandler.showSearch ||
					!uiEventHandler.showNavArrows ||
					uiEventHandler.disableNav
				">
				<i [class.icon-open-book]="!uiEventHandler.customIcons"
					[class.icon-index-seat]="uiEventHandler.customIcons"></i>
			</nova-nav-border-item>
			<nova-matrix this.uiEventHandler.disableNav="true;" [class.openSub]="
					uiEventHandler.topClose ||
					uiEventHandler.showContactForm ||
					uiEventHandler.showSearch ||
					!uiEventHandler.showNavArrows ||
					uiEventHandler.disableNav
				" (openImpressum)="impressum.open()" (openTerms)="terms.open()"
				[mobileStyle]="setMyStyles(mobileMatrixStyle())" [showPDF]="showPDF" [showPrint]="showPrint"
				[showMagazine]="showMagazine" [magazine]="magazine"
				[languages]="getLanguages()"></nova-matrix>
			<nova-nav-border-item [forceClick]="false" [hidden]="!uiEventHandler.showNavArrows" (click)="slideRight()"
				[style]="getNavColors()" [activeStyle]="getNavActiveColors()" class="next-page" [class.disabled]="
					page == nova.pages.length && !uiEventHandler.galleryPopup && !uiEventHandler.disableNav
				">
				<i class="icon-arrow-right"></i>
			</nova-nav-border-item>
			<nova-nav-border-item [forceClick]="false" [hidden]="!uiEventHandler.showNavArrows" (click)="slideLeft()"
				[style]="getNavColors()" [activeStyle]="getNavActiveColors()" class="last-page"
				[class.disabled]="page < 2 && !uiEventHandler.galleryPopup && !uiEventHandler.disableNav">
				<i class="icon-arrow-left"></i>
			</nova-nav-border-item>
		</nav>
		<nav *ngIf="!ssr.isBrowser && magazine" class="right-nav" [hidden]="!nova.issue"
             [class.right-nav--ta]="nova && nova.issue && nova.issue.travelAgency"
        >
			<nova-nav-border-item [hidden]="!uiEventHandler.showNavArrows" (click)="slideRight()" class="next-page"
				[style]="getNavColors()" [activeStyle]="getNavActiveColors()" [class.disabled]="
					page >= nova.pages.length && !uiEventHandler.galleryPopup && !uiEventHandler.disableNav
				">
				<i class="icon-arrow-right"></i>
			</nova-nav-border-item>
			<nova-nav-border-item [hidden]="!uiEventHandler.showNavArrows" (click)="slideLeft()"
				[style]="getNavColors()" [activeStyle]="getNavActiveColors()" class="last-page"
				[class.disabled]="page <= 1 && !uiEventHandler.galleryPopup && !uiEventHandler.disableNav">
				<i class="icon-arrow-left"></i>
			</nova-nav-border-item>
		</nav>
		<nova-contact-popup *ngIf="magazine && magazine.contact_enabled"></nova-contact-popup>
	</div>

	<nova-london-overlay #londonOverlay (pageChange)="goTo($event)" [magazine]="magazine" [issue]="nova.issue"
		[languages]="getLanguages()" *ngIf="ssr.isBrowser && navType === 'london'" [active]="deepLink || 'pages'" (toggleOverlay)="toggleOverlay()">
	</nova-london-overlay>

	<nova-budapest-overlay #budapestOverlay (pageChange)="goTo($event)" [magazine]="magazine" [issue]="nova.issue"
		[languages]="getLanguages()" *ngIf="ssr.isBrowser && navType === 'budapest'" [active]="deepLink || 'pages'" (toggleOverlay)="toggleOverlay()">
	</nova-budapest-overlay>

	<nova-fti-overlay #ftiOverlay (pageChange)="goTo($event)" [magazine]="magazine" [issue]="nova.issue"
		[languages]="getLanguages()" *ngIf="ssr.isBrowser && navType === 'fti'" [overlayVisible]="ftiOverlay && ftiOverlay.visible" [active]="deepLink || 'pages'">
	</nova-fti-overlay>

	<nova-nav-border-item *ngIf="magazine"
		[hidden]="!uiEventHandler.videoBg || uiEventHandler.galleryPopup || uiEventHandler.showContentTable"
		id="mute-button" (click)="uiEventHandler.toggleMute()" class="icon">
		<i class="fa" [ngClass]="{ 'fa-volume-off': uiEventHandler.mute, 'fa-volume-up': !uiEventHandler.mute }"></i>
	</nova-nav-border-item>
	<div class="mobile-nav" *ngIf="showMagazine && nova.issue && uiEventHandler.isMobile() && navType !== 'fti'"
		[ngStyle]="setMyStyles(getMobileStyle())">
		<div class="issue-name" [ngStyle]="{ 'font-family': font }">
			<span>
				<b *ngIf="nova.issue.number">{{ nova.issue.number }} <br /> </b>{{ nova.issue.name }}</span>
		</div>
		<div class="arrows" [ngStyle]="{ 'border-right-color': c(navStyle.borderColor) }">
			<a [ngStyle]="setMyStyles(mobileLinkStyle)" [class.disabled]="page <= 1" (click)="slideLeft()">
				<i class="icon-arrow-left"></i>
			</a>
			<a [ngStyle]="setMyStyles(mobileLinkStyle)" [class.disabled]="page >= nova.pages.length"
				(click)="slideRight()">
				<i class="icon-arrow-right"></i>
			</a>
		</div>
	</div>
	<nova-impressum #impressum></nova-impressum>
	<nova-terms *ngIf="uiEventHandler.showTerms"></nova-terms>
	<nova-newsletter-popup *ngIf="uiEventHandler.showNewsletterForm"></nova-newsletter-popup>
	<nova-cookie-popup *ngIf="uiEventHandler.showCookiePopup"></nova-cookie-popup>
    <paid-content-dialog *ngIf="uiEventHandler.showPaidContentDialog"></paid-content-dialog>
</div>

<nova-font-loader *ngIf="nova.fonts" [fonts]="nova.fonts"></nova-font-loader>
<nova-font-loader *ngIf="magazine" [fonts]="[magazine.navigation.font]"></nova-font-loader>
