import * as tslib_1 from "tslib";
import { OnInit, AfterContentInit, OnDestroy } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { ColorFormatter } from '../../../novamag-api/Types';
import { ElementRef } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MagazineService } from 'app/_services/magazine.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
var PageIndexIssueComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageIndexIssueComponent, _super);
    function PageIndexIssueComponent(nova, mag, renderer, uiEventHandler, meta, title, translate, router, route) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.mag = mag;
        _this.renderer = renderer;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.title = title;
        _this.translate = translate;
        _this.router = router;
        _this.route = route;
        _this.mobile = false;
        return _this;
    }
    PageIndexIssueComponent.prototype.ngOnInit = function () {
        this.mobile = $('body').width() <= 1024;
    };
    PageIndexIssueComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        // LEFT >>>
        if (this.data['boxColorLeft']) {
            var c = new ColorFormatter(this.data['boxColorLeft']);
            if (!this.mobile)
                this.renderer.setStyle(this.left.nativeElement, 'background-color', c.rgba());
            if (this.mobile)
                this.renderer.setStyle(this.leftMobile.nativeElement, 'background-color', c.rgba());
        }
        if (this.data['textColorLeft']) {
            var c = new ColorFormatter(this.data['textColorLeft']);
            if (!this.mobile)
                this.renderer.setStyle(this.left.nativeElement, 'color', c.rgb());
            if (this.mobile)
                this.renderer.setStyle(this.leftMobile.nativeElement, 'color', c.rgb());
        }
        if (this.data['boxWidthLeft']) {
            this.renderer.setStyle(this.leftHolder.nativeElement, 'width', this.data['boxWidthLeft']);
        }
        this.sub = this.mag.getMagazine().subscribe(function (data) {
            _this.subFont = _this.nova.getFont(data.navigation.font).subscribe(function (fontData) {
                if (_this.mobile)
                    _this.renderer.setStyle(_this.left.nativeElement, 'font-family', fontData.name);
                if (_this.mobile)
                    _this.renderer.setStyle(_this.leftMobile.nativeElement, 'font-family', fontData.name);
            });
            _this.pagesLeft = _this.nova.pages.filter(function (page) {
                return !page.templateName.startsWith('index-') &&
                    page.status === 'active' &&
                    page.showImagesLeft !== 'no' &&
                    page.showInIndex !== 'no';
            });
            var leftUrl = _this.pagesLeft[0]['handle'].split('/');
            _this.urlLeft = leftUrl && leftUrl[0] + '/' + leftUrl[1];
        });
        // <<< LEFT
        // Right >>>
        if (this.data['boxColorRight']) {
            var c = new ColorFormatter(this.data['boxColorRight']);
            if (!this.mobile)
                this.renderer.setStyle(this.right.nativeElement, 'background-color', c.rgba());
            if (this.mobile)
                this.renderer.setStyle(this.rightMobile.nativeElement, 'background-color', c.rgba());
        }
        if (this.data['textColorRight']) {
            var c = new ColorFormatter(this.data['textColorRight']);
            if (!this.mobile)
                this.renderer.setStyle(this.right.nativeElement, 'color', c.rgb());
            if (this.mobile)
                this.renderer.setStyle(this.rightMobile.nativeElement, 'color', c.rgba());
        }
        if (this.data['boxWidthRight']) {
            this.renderer.setStyle(this.rightHolder.nativeElement, 'width', this.data['boxWidthRight']);
        }
        this.sub = this.mag.getMagazine().subscribe(function (data) {
            _this.subFont = _this.nova.getFont(data.navigation.font).subscribe(function (fontData) {
                if (!_this.mobile)
                    _this.renderer.setStyle(_this.right.nativeElement, 'font-family', fontData.name);
                if (_this.mobile)
                    _this.renderer.setStyle(_this.rightMobile.nativeElement, 'font-family', fontData.name);
            });
            _this.pagesRight = _this.data['issueIndex'];
            var rightUrl = _this.data['issueIndex'][0] && _this.data['issueIndex'][0]['handle'].split('/');
            _this.urlRight = rightUrl && rightUrl[0] + '/' + rightUrl[1];
        });
        // <<< Right
    };
    PageIndexIssueComponent.prototype.goToPage = function (page, floor) {
        var params = this.router.routerState.root.firstChild.params['value'];
        if (!floor) {
            this.router.navigate(['/', params['langHandle'], params['issueHandle'], page], {
                queryParamsHandling: 'preserve'
            });
        }
        else {
            this.router.navigate(['/', params['langHandle'], params['issueHandle'], page, floor], {
                queryParamsHandling: 'preserve'
            });
        }
    };
    PageIndexIssueComponent.prototype.goToIssue = function (url) {
        window.open(url, '_self');
    };
    PageIndexIssueComponent.prototype.getType = function (type) {
        return this.translate.get(type);
    };
    PageIndexIssueComponent.prototype.ngOnDestroy = function () {
        if (this.sub)
            this.sub.unsubscribe();
        if (this.subFont)
            this.subFont.unsubscribe();
    };
    PageIndexIssueComponent = tslib_1.__decorate([
        NovaTemplate('PageIndexIssueComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            MagazineService,
            Renderer2,
            UIEventHandlerService,
            Meta,
            Title,
            TranslateService,
            Router,
            ActivatedRoute])
    ], PageIndexIssueComponent);
    return PageIndexIssueComponent;
}(Page));
export { PageIndexIssueComponent };
