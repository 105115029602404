<div id="page-{{ id }}" class="page page-form-classic">
    <nova-bg [data]="data" [forceVideo]="true"></nova-bg>
    <nova-scrollbar-holder>
      <div class="scroller">
        <nova-mobile-img [data]="data" class="show-for-small-only left"></nova-mobile-img>
        <div class="bg animated fadeInUp clear" [ngStyle]="{'background-color':getColor(data['box_color'])}" [hidden]="!data.text && !data.header && !data.subHeader && !data.label">
          <nova-title [class.noBg]="!data.background_media" [data]="data"></nova-title>
          <nova-text-element *ngIf="data.text" class="con" [data]="data.text" [myid]="data._id" (openGallery)="gallery.openSlider($event)"></nova-text-element>
          <nova-form [form]="data.form" [data]="data"></nova-form>
          <nova-content-links class="content-links" *ngIf="data.links && data.links.length > 0" [floors]="data.links"></nova-content-links>
        </div>
      </div>
    </nova-scrollbar-holder>
    <nova-text-gallery #gallery [medias]="data.textImages" [myid]="data._id"></nova-text-gallery>
  </div>
