<div class="videoPopup page-video page-video-cinema page-{{ id }}">
  <nova-bg [data]="data" [forceVideo]="true"></nova-bg>
  <nova-scrollbar-holder>
    <div class="scroller">
      <div class="center-box animated fadeIn center">
        <div class="hide-for-small-only">
          <nova-title [data]="data"></nova-title>
        </div>
        <div class="bg row">
            <div class="video col-md-7 right iframe full">
                <nova-media [bg]="false" [mediaId]="data['video']" [iframe]="true"></nova-media>
            </div>
          <div class="col-md-5 left show-for-small-only">
            <div class="white scrollbar-holder">
              <div class="scroller">
                <nova-title [data]="data"></nova-title>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nova-scrollbar-holder>
</div>
