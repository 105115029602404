import { Component, EventEmitter, Output, Input } from '@angular/core';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { MagazineService } from 'app/_services/magazine.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { Router } from '@angular/router';

@Component({
    selector: 'nova-fti-overlay-pages',
    templateUrl: './nova-fti-overlay-pages.component.html',
    styleUrls: ['./nova-fti-overlay-pages.component.scss']
})
export class NovaFtiOverlayPagesComponent {
    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
    @Input() currentPage = -1;
    @Input() font;
    @Input() borderColor;
    @Input() searchString;

    @Input() pages;

    constructor(
        public nova: NovaAPIService,
        public mag: MagazineService,
        public uiEventHandler: UIEventHandlerService,
        public router: Router
    ) {}

    isActive(check) {
        if (check) {
            return this.borderColor;
        }
    }

    getThumbnail(page) {
        if (
            (page.templateName.startsWith('hotel-detail-') ||
                page.templateName === 'roundtrip-standard' ||
                page.templateName === 'transfer-standard' ||
                page.templateName === 'trip-detail-standard') &&
            page.images &&
            page.images.length > 0
        ) {
            return `url(${page.images[0].filename})`;
        }

        if (page.thumbnail && !this.nova.isThumbLoading()) {
            return `url(${page.thumbnail.url})`;
        }

        return `url('assets/img/templates/pages/${page.templateName}.svg')`;
    }

    makeUrl(value) {
        if (value.pageHandle) {
            return ['/', this.nova.issue.language, this.nova.getCurrentIssueHandle(), value.pageHandle, value.handle];
        }

        return ['/', this.nova.issue.language, this.nova.getCurrentIssueHandle(), value.handle];
    }

    pad(num, size) {
        let s = num + '';
        while (s.length < size) s = '0' + s;

        return s;
    }
}
