import { Router } from '@angular/router';
import { MagazineService } from './magazine.service';
import { NovaAPIService } from './nova-api.service';
import Plausible from './lib/tracker';
import { environment } from 'environments/environment';
var InjectService = /** @class */ (function () {
    function InjectService(router, magService, nova) {
        this.router = router;
        this.magService = magService;
        this.nova = nova;
    }
    InjectService.prototype.injectPlausibleProvider = function () {
        if (!environment.plausibleApiUrl)
            return;
        var enableAutoPageviews = Plausible({
            trackLocalhost: true,
            domain: window.location.host,
            apiHost: environment.plausibleApiUrl
        }).enableAutoPageviews;
        enableAutoPageviews();
    };
    InjectService.prototype.gaConf = function () {
        if (typeof ga === 'function') {
            this.tracker = ga;
        }
        if (typeof gtag === 'function') {
            this.tracker = gtag;
        }
        if (this.tracker) {
            this.tracker('set', 'anonymizeIp', true);
            this.tracker('require', 'linker');
            this.tracker('linker:autoLink', ['novamag.me']);
            this.tracker('send', 'pageview');
        }
    };
    InjectService.prototype.injectAnalyticsProvider = function (project, language) {
        $('#analytics').empty();
        var trackingProvider = project.trackingProvider;
        language = language && language !== 'all' ? language : project.language;
        switch (trackingProvider) {
            case 'analytics':
                return this.injectAnalytics();
            default:
                return null;
        }
    };
    InjectService.prototype.injectAnalytics = function () {
        var _this = this;
        var params = this.router.routerState.root.firstChild && this.router.routerState.root.firstChild['params']['value'];
        if (params && params.issueHandle) {
            this.nova.getCurrentIssue().subscribe(function (issue) {
                if (issue.analytics && issue._id !== _this.injectedAnalytics) {
                    _this.injectedAnalytics = issue._id;
                    $('#analytics')
                        .empty()
                        .append(issue.analytics);
                    _this.gaConf();
                }
                if (!issue.analytics) {
                    _this.magService.getMagazine().subscribe(function (magazine) {
                        if (magazine.analytics && magazine._id !== _this.injectedAnalytics) {
                            _this.injectedAnalytics = magazine._id;
                            $('#analytics')
                                .empty()
                                .append(magazine.analytics);
                            _this.gaConf();
                        }
                    }, function (err) {
                        console.log('injectAnalytics -> getCurrentIssue -> getMagazine', err);
                    });
                }
            }, function (err) {
                console.log('injectAnalytics -> getCurrentIssue', err);
            });
        }
        else {
            this.magService.getMagazine().subscribe(function (magazine) {
                if (magazine.analytics && magazine._id !== _this.injectedAnalytics) {
                    _this.injectedAnalytics = magazine._id;
                    $('#analytics')
                        .empty()
                        .append(magazine.analytics);
                    _this.gaConf();
                }
            }, function (err) {
                console.log('injectAnalytics -> getMagazine', err);
            });
        }
    };
    return InjectService;
}());
export { InjectService };
