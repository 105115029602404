import * as tslib_1 from "tslib";
import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Meta, Title } from '@angular/platform-browser';
import * as $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';
var PageCoverClassicComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageCoverClassicComponent, _super);
    function PageCoverClassicComponent(nova, uiEventHandler, sanitizer, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.sanitizer = sanitizer;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        _this.resizeSub = _this.uiEventHandler.resizeEvent$.subscribe(_this.onResize.bind(_this));
        return _this;
    }
    PageCoverClassicComponent.prototype.ngOnDestroy = function () {
        this.resizeSub.unsubscribe();
    };
    PageCoverClassicComponent.prototype.ngOnInit = function () {
        var _this = this;
        var titleStyle = '';
        var helperStyle = '';
        var flexboxStyle = '';
        var logoStyle = '';
        var posLogo = this.data['logoPosition'];
        var posTitle = this.data['contentPosition'];
        var posLogoArr = posLogo.split('-');
        var posTitleArr = posTitle.split('-');
        if (posTitleArr[1] === 'right') {
            titleStyle += 'text-align: right;';
        }
        else if (posTitleArr[1] === 'center') {
            titleStyle += 'text-align: center;';
        }
        // move boxes next to each other
        if (posLogoArr[0] === posTitleArr[0] && posLogoArr[1] !== posTitleArr[1]) {
            flexboxStyle += 'flex-direction: row;';
            if (posTitleArr[0] === 'center') {
                titleStyle += 'align-self: center;';
                logoStyle += 'align-self: center;';
            }
            else if (posTitleArr[0] === 'bottom') {
                titleStyle += 'align-self: flex-end;';
                logoStyle += 'align-self: flex-end;';
            }
            else {
                titleStyle += 'align-self: flex-start;';
                logoStyle += 'align-self: flex-start;';
            }
            if (posLogoArr[1] === 'right' || (posLogoArr[1] === 'center' && posTitleArr[1] === 'left')) {
                logoStyle += 'order: 2;';
                titleStyle += 'margin-right: 20px;';
                if (posLogoArr[1] === 'right') {
                    logoStyle += 'text-align: right;';
                }
            }
            else {
                titleStyle += 'margin-left: 20px;';
            }
            if (posLogoArr[1] === 'left' && posTitleArr[1] === 'right') {
                flexboxStyle += 'justify-content: space-between;';
                helperStyle += 'display: none;';
            }
        }
        else {
            if (posLogoArr[1] === 'left') {
                logoStyle += 'align-self: flex-start;text-align: left;';
            }
            else if (posLogoArr[1] === 'right') {
                logoStyle += 'align-self: flex-end;text-align: right;';
            }
            else if (posLogoArr[1] === 'center') {
                logoStyle += 'align-self: center;text-align: center;';
            }
            if (posTitleArr[1] === 'center') {
                titleStyle += 'text-align: center;';
            }
            else if (posTitleArr[1] === 'left') {
                titleStyle += 'align-self: flex-start;';
            }
            else if (posTitleArr[1] === 'right') {
                titleStyle += 'align-self: flex-end;';
            }
            if (posLogoArr[0] === 'center' && posTitleArr[0] === 'center') {
                logoStyle += 'margin-bottom: 20px;';
            }
            // move logo to top
            if (posLogoArr[0] === 'top') {
                if (posTitleArr[0] !== 'top') {
                    logoStyle += 'flex-grow: 1;';
                    if (posTitleArr[0] === 'center') {
                        titleStyle += 'flex-grow: 1;';
                    }
                }
                else {
                    helperStyle += 'order: 2;';
                    helperStyle += 'flex-grow: 1;';
                    logoStyle += 'margin-bottom: 20px;';
                }
            }
            if (posTitleArr[0] === 'top') {
                if (posLogo !== 'top-left' && posLogo !== 'top-center' && posLogo !== 'top-right') {
                    titleStyle += 'flex-grow: 1;';
                    // titleStyle += 'order: 0;';
                    if (posLogo === 'center-center' || posLogo === 'center-right' || posLogo === 'center-left') {
                        logoStyle += 'flex-grow: 1;';
                    }
                }
            }
            // move logo to bottom
            if (posLogo === 'bottom-left' || posLogo === 'bottom-center' || posLogo === 'bottom-right') {
                if (posTitle !== 'bottom-left' && posTitle !== 'bottom-center' && posTitle !== 'bottom-right') {
                    logoStyle += 'order: 2;';
                }
                else {
                    helperStyle += 'flex-grow: 1;';
                    logoStyle += 'margin-bottom: 20px;';
                }
            }
            // move title to top
            if (posTitle === 'top-left' || posTitle === 'top-center' || posTitle === 'top-right') {
                if (posLogo !== 'top-left' && posLogo !== 'top-center' && posLogo !== 'top-right') {
                    titleStyle += 'order: 0;';
                    logoStyle += 'order: 1;';
                }
            }
            if (posLogo === 'bottom-left' || posLogo === 'bottom-right' || posLogo === 'bottom-center') {
                if (posTitle === 'center-center' || posTitle === 'center-right' || posTitle === 'center-left') {
                    helperStyle += 'flex-grow: 1;';
                    titleStyle += 'flex-grow: 1;';
                }
            }
            if (posTitle === 'bottom-left' || posTitle === 'bottom-right' || posTitle === 'bottom-center') {
                if (posLogo === 'center-center' || posLogo === 'center-right' || posLogo === 'center-left') {
                    helperStyle += 'flex-grow: 1;';
                    logoStyle += 'flex-grow: 1;';
                }
            }
        }
        this.titleStyle = this.convertCSS(titleStyle);
        this.helperStyle = this.convertCSS(helperStyle);
        this.flexboxStyle = this.convertCSS(flexboxStyle);
        this.logoStyle = this.convertCSS(logoStyle);
        setTimeout(function () {
            _this.calcMaxSize();
        }, 100);
    };
    PageCoverClassicComponent.prototype.calcMaxSize = function () {
        var _this = this;
        var logo_size = '';
        if (this.data['logo'] && this.data['logo'].url) {
            var divW_1 = $(this.logo.nativeElement).width();
            var logo_1 = $(this.logo.nativeElement).find('img')[0];
            if (logo_1['naturalHeight']) {
                logo_size += 'max-height: ' + (divW_1 * logo_1['naturalHeight']) / logo_1['naturalWidth'] + 'px;';
            }
            else {
                $(logo_1).on('load', function () {
                    divW_1 = $(_this.logo.nativeElement).width();
                    logo_size += 'max-height: ' + (divW_1 * logo_1['naturalHeight']) / logo_1['naturalWidth'] + 'px;';
                });
            }
        }
        if (this.data['logoSize']) {
            logo_size += 'height: calc(50px + ' + this.data['logoSize'] / 2 + 'vw);';
        }
        this.logo_size = this.convertCSS(logo_size);
    };
    PageCoverClassicComponent.prototype.onResize = function (e) {
        this.calcMaxSize();
    };
    PageCoverClassicComponent.prototype.convertCSS = function (css) {
        var input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {};
            var attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    };
    PageCoverClassicComponent.prototype.setMyStyles = function (obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    };
    PageCoverClassicComponent = tslib_1.__decorate([
        NovaTemplate('PageCoverClassicComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            DomSanitizer,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], PageCoverClassicComponent);
    return PageCoverClassicComponent;
}(Page));
export { PageCoverClassicComponent };
