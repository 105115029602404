import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { MagazineService } from 'app/_services/magazine.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { Router } from '@angular/router';
function createUrl(template, issueHandle, language, handle, parentHandle) {
    return "/" + language + "/" + issueHandle + (parentHandle && template !== 'blog-article' ? "/" + parentHandle : '') + "/" + handle;
}
var NovaLondonOverlayPagesComponent = /** @class */ (function () {
    function NovaLondonOverlayPagesComponent(nova, mag, uiEventHandler, router) {
        this.nova = nova;
        this.mag = mag;
        this.uiEventHandler = uiEventHandler;
        this.router = router;
        this.pageChange = new EventEmitter();
        this.currentPage = -1;
        this.pages = [];
    }
    NovaLondonOverlayPagesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.pages = this.nova.pages.reduce(function (acc, page) {
            var e_1, _a;
            if (page.pageId || page.showInIndex === 'no')
                return acc;
            acc.push(tslib_1.__assign({}, page, { routerLink: createUrl(page.templateName, _this.nova.issue.handle, page.language, page.handle) }));
            if (page.templateName === 'blog-index' &&
                page.showBlogPostsInIndex === 'active' &&
                page.contents.length > 0) {
                try {
                    for (var _b = tslib_1.__values(page.contents.sort(function (a, b) { return b.order - a.order; })), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var post = _c.value;
                        acc.push(tslib_1.__assign({}, post, { routerLink: createUrl(post.templateName, _this.nova.issue.handle, post.language, post.handle, page.handle) }));
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
            return acc;
        }, []);
    };
    NovaLondonOverlayPagesComponent.prototype.isActivePage = function (page) {
        return (page.handle ===
            window.location.href
                .split('?')[0]
                .split('/')
                .pop());
    };
    NovaLondonOverlayPagesComponent.prototype.isActive = function (check) {
        if (check) {
            return this.borderColor;
        }
    };
    NovaLondonOverlayPagesComponent.prototype.pad = function (num, size) {
        var s = num + '';
        while (s.length < size) {
            s = '0' + s;
        }
        return s;
    };
    return NovaLondonOverlayPagesComponent;
}());
export { NovaLondonOverlayPagesComponent };
