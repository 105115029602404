import { Location } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Page } from 'app/pages/page';
import { NovaTemplate } from 'app/_decorators/template.decorator';
import { BlogService, Category } from 'app/_services/blog.service';
import { MediaService } from 'app/_services/media.service';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { fadeAnimation } from '../travelworld-modern/travelworld-slider/fadeIn.animation';

@Component({
    templateUrl: './blog-index.component.html',
    styleUrls: ['./blog-index.component.scss'],
    animations: [fadeAnimation]
})
@NovaTemplate('PageBlogIndexComponent')
export class PageBlogIndexComponent extends Page implements OnInit, AfterViewInit {
    public categories: Category[] = [];
    public selectedCategories: string[] = [];
    public posts = [];
    public filteredPosts = [];
    public blogFonts = [];
    public blogNavigationIcon;
    public categoriesHaveIcons = false;
    public isLoading = true;
    public categoriesVisible = false;
    public searchValue = '';

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public route: ActivatedRoute,
        public title: Title,
        public mediaService: MediaService,
        public location: Location,
        public blogService: BlogService,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    async ngOnInit() {
        this.blogFonts = [this.data.font, this.data.font_bold];

        this.uiEventHandler.blogPage = {
            ...this.data,
            contents: this.data.contents.filter(post => post.templateName === 'blog-article')
        };

        this.uiEventHandler.currentBlogPost = undefined;

        this.route.queryParams.subscribe(
            ({ categories }) => (categories ? (this.selectedCategories = categories.split(',')) : null)
        );

        this.blogService.setBlogStyles(this.data);
        this.posts = this.data.contents;
        await this.getCategories();
        this.filterPosts();
        this.blogNavigationIcon = await this.blogService.getBlogNavigationIcon(this.data.blogNavigationIcon);

        this.isLoading = false;
    }

    ngAfterViewInit() {
        this.data.contents &&
            this.data.contents.length > 0 &&
            $('.next-page')[0].style.setProperty('opacity', '1', 'important');
    }

    selectCategory(event, category?: string) {
        event.stopPropagation();

        this.selectedCategories = category ? [category.toLowerCase()] : [];
        this.filterPosts();

        this.location.replaceState(
            window.location.pathname,
            this.selectedCategories.length > 0 ? `?categories=${this.selectedCategories.join(',')}` : ''
        );

        this.categoriesVisible = false;
    }

    sortPosts(posts) {
        return posts.filter(post => post.templateName === 'blog-article').sort((a, b) => {
            const orderA = a.order;
            const orderB = b.order;

            if (orderA > orderB) {
                return -1;
            }

            if (orderA < orderB) {
                return 1;
            }

            return 0;
        });
    }

    filterPosts() {
        const posts =
            this.selectedCategories.length > 0
                ? this.posts.filter(
                      post =>
                          post.categories &&
                          post.categories.some(cat => this.selectedCategories.includes(cat.toLowerCase()))
                  )
                : this.posts;

        this.filteredPosts = this.sortPosts(this.filterByKeywords(posts, this.searchValue));

        setTimeout(() => this.blogService.equalizeHeights('.single-post__content'), 1500);
    }

    onChangeSearchFilter(newValue) {
        this.searchValue = newValue;
        this.filterPosts();
    }

    filterByKeywords(entries, keyword) {
        const fields = ['label', 'subHeader', 'text', 'header', 'name', 'dateCreated'];
        keyword = keyword.toLowerCase();

        if (keyword.trim() === '') {
            return entries;
        }

        return entries.filter(post => {
            for (let i = 0; i < fields.length; i++) {
                const field = fields[i];
                if (!post.hasOwnProperty(field)) {
                    continue;
                }

                if (post[field].toLowerCase().indexOf(keyword) >= 0) {
                    return true;
                }
            }

            return false;
        });
    }

    async getCategories() {
        const blogCategories =
            this.data && this.data.categories
                ? this.data.categories.filter(cat => cat.name && cat.status === 'active')
                : [];

        if (this.categories.length > 0) {
            return;
        }

        for (const category of blogCategories) {
            if (!category.image) {
                this.categories = [...this.categories, { ...category }];
                continue;
            }

            const url = await this.mediaService.getMediaUrl(category.image, 'preview').toPromise();
            this.categories = [...this.categories, { ...category, image: url }];
        }

        if (this.categories.some(cat => Boolean(cat.image))) {
            this.categoriesHaveIcons = true;
        }
    }

    toggleCategories() {
        this.categoriesVisible = !this.categoriesVisible;
    }
}
