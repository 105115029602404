<div #scrollContainer class="scrollbar-holder do-scroll">
    <div class="scroller">
        <div class="bg-holder">
            <div class="carousel-container">
                <nova-image-slider [images]="images" [apiImages]="data.images" fullWidth="true"></nova-image-slider>
            </div>
        </div>
        <div class="center clear first-viewport">
            <div class="first-viewport-wrapper">
                <div class="page-hotel-detail-premium--header-box"
                    [ngStyle]="{ 'background-color': getColor(data['box_color']) }">
                    <div class="page-hotel-detail-premium--header">
                        <div style="display: flex; flex-direction: column; flex: 2;">
                            <div style="display: flex; flex-direction: row; align-items: flex-start;">
                                <nova-title [data]="data" style="margin-bottom: 0"></nova-title>
                                <div class="stars" style="margin-left: 5px">
                                    <div *ngFor="let i of hotelHelper.createArray(data.stars)" [class]="i "></div>
                                </div>
                            </div>


                            <div *ngIf="data.country_labels" class="tags">
                                <nova-element *ngFor="let label of data.country_labels; let i = index;" [data]="data"
                                    content_name="{{'country_labels_' + i}}" name="country_labels"></nova-element>
                            </div>
                            <div *ngIf="data.hotel_labels" class="tags hotel-tags">
                                <nova-element *ngFor="let label of data.hotel_labels; let i = index;" [data]="data"
                                    content_name="{{'hotel_labels_' + i}}" name="hotel_labels"></nova-element>
                            </div>

                        </div>
                        <div style="display: flex; flex-direction: row; justify-content: space-evenly; flex: 1; flex-wrap: wrap; align-items: center;">
                            <img *ngIf="data.gti && isUrl(data.gti)" [src]="data.gti" class="page-hotel-detail-premium--gti">
                        
                            <img class="page-hotel-detail-premium--logo" *ngIf="data.logo_media && isUrl(data.logo_media)"
                                [src]="getImageProxyUrl(data.logo_media)">

                            <nova-media *ngIf="data.logo_media && !isUrl(data.logo_media)" [mediaId]="data.logo_media"
                                class="page-hotel-detail-premium--logo" version="small" [bg]="false" [fallback]="true">
                            </nova-media>
                        </div>
                    </div>

                    <div *ngIf="teaser" class="page-hotel-detail-premium--header-box--text" [innerHtml]="teaser"></div>

                    <div class="page-hotel-detail-premium--cta_button_wrapper" (click)="scroll(scrollTo)" #scrollTo>
                        <div class="page-hotel-detail-premium--cta_button" title="Scroll down">
                            <i class="fa fa-angle-down"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="center clear" [style.margin-top.px]="getWindowHeight()">
            <div class="page-hotel-detail-premium--price-box">
                <div class="page-hotel-detail-premium--price-box--text">
                    <nova-element [data]="data" name="price_header"></nova-element>
                    <nova-element [data]="data" class="page-hotel-detail-premium--price-box--price" name="price_text">
                    </nova-element>
                </div>

                <div class="cta-container cta-container__small">
                    <button *ngIf="data.call_to_action_link && !showContactForm; else openContactFormButton"
                        type="button" class="mt-0 btn-link btn-primary page-hotel-detail-premium--price-box--button"
                        (click)="goToUrl(data.call_to_action_link)">
                        <nova-element *ngIf="data.cta" [data]="data" name="cta" class="btn-content"></nova-element>
                        <nova-element *ngIf="data.call_to_action_button && !data.cta" [data]="data"
                            name="call_to_action_button"></nova-element>
                    </button>

                    <ng-template #openContactFormButton>
                        <button *ngIf="data.contactForm" type="button" class="mt-0 btn-link btn-primary"
                            (click)="openModal('contact')">
                            <nova-element *ngIf="data.cta" [data]="data" name="cta" class="btn-content"></nova-element>
                        </button>

                        <button *ngIf="!data.contactForm" type="button" class="mt-0 btn-link btn-primary"
                            (click)="openModal('moreInfo')">23
                            <nova-element [data]="data" name="cta" class="btn-content"></nova-element>
                        </button>
                    </ng-template>
                </div>
            </div>

            <div class="page-hotel-detail-premium--hotel-pricing-box" *ngIf="data.pricingData">
                <app-hotel-pricing [ignoreMobile]="true" font_name="price_text_fonts" title_font_name="price_title_font"
                    text_font_name="price_text_font" name="pricingData" [data]="data"></app-hotel-pricing>
            </div>
        </div>

        <div *ngIf="data.premium" class="gallery-box">
            <div *ngFor="let block of data.premium" class="gallery-box--item"
                (click)="selectBlock(block); uiEventHandler.isMobile() && scroll(selectedBlockElement)">
                <img
                    class="gallery-box--img"
                    *ngIf="block.image && block.image[0]"
                    [src]="getImageProxyUrlForBlock(block.image[0].filename)"
                >
                <img
                    class="gallery-box--img"
                    *ngIf="block.image && !block.image[0] && block.image.filename"
                    [src]="getImageProxyUrlForBlock(block.image.filename)"
                >
                <div class="backdrop" *ngIf="selectedBlock !== block"></div>
                <div class="gallery-box--title">
                    <nova-text-element class="con" [data]="block.title"></nova-text-element>
                </div>
                <div class="gallery-box--action">
                    <div class="plus-button"><span><i class="fa fa-plus"></i></span></div>
                </div>
            </div>
        </div>

        <div *ngIf="!data.premium" class="gallery-box">
            <div *ngFor="let block of data.blocks" class="gallery-box--item"
                (click)="selectBlock(block); uiEventHandler.isMobile() && scroll(selectedBlockElement)">
                <nova-media [mediaId]="block.media" version="block"></nova-media>
                <div class="backdrop" *ngIf="selectedBlock !== block"></div>
                <div class="gallery-box--title">
                    <nova-element *ngIf="block.header && !block.title" [data]="block" name="header"></nova-element>
                    <nova-text-element *ngIf="block.title" class="con" [data]="block.title"></nova-text-element>
                </div>
                <div class="gallery-box--action">
                    <div class="plus-button"><span><i class="fa fa-plus"></i></span></div>
                </div>
            </div>
        </div>

        <span #selectedBlockElement>
            <hr class="orangeSpacer" *ngIf="selectedBlock" />
            <app-selected-block *ngIf="selectedBlock" [selectedContent]="selectedBlock"></app-selected-block>
        </span>

        <div class="map">
            <hr class="orangeSpacer" />
            <iframe width="100%" height="500" frameborder="0" style="border:0" [src]="googleMapsUrl">
            </iframe>
        </div>
        <div class="cta-container">
            <nova-fti-button *ngIf="data.bookinginformationtitle" (click)="openModal('bookingInformation')" variant="secondary">
                <i class="icon icon-information"></i>
                <nova-element [data]="data.bookinginformationtitle" class="btn-content"></nova-element>
            </nova-fti-button>

            <button *ngIf="data.call_to_action_link && !showContactForm; else openContactFormButton" type="button"
                class="btn-link btn-primary" (click)="goToUrl(data.call_to_action_link)">
                <nova-element *ngIf="data.cta" [data]="data" name="cta" class="btn-content"></nova-element>
                <nova-element *ngIf="data.call_to_action_button && !data.cta" [data]="data"
                    name="call_to_action_button"></nova-element>
            </button>

            <ng-template #openContactFormButton>
                <button *ngIf="data.contactForm" type="button" class="btn-link btn-primary"
                    (click)="openModal('contact')">
                    <nova-element *ngIf="data.cta" [data]="data" name="cta" class="btn-content"></nova-element>
                </button>

                <button *ngIf="!data.contactForm" type="button" class="btn-link btn-primary"
                    (click)="openModal('moreInfo')">
                    <nova-element [data]="data" name="cta" class="btn-content"></nova-element>
                </button>
            </ng-template>
        </div>
    </div>

    <div *ngIf="modalOpen" [@fadeAnimation]="'in'" class="modal contact" style="top: 0">
        <nova-nav-border-item (click)="closeModal($event)" class="my-close-icon icon-cancel"></nova-nav-border-item>

        <div class="wrapper">
            <div class="modal-content contact">
                <div *ngIf="modalType === 'contact'">
                    <nova-contactform [data]="data"></nova-contactform>
                </div>

                <div *ngIf="modalType === 'moreInfo'">
                    <div class="modal-content__container">
                        <nova-fti-season-data [data]="data"></nova-fti-season-data>
                    </div>
                </div>

                <div *ngIf="modalType === 'bookingInformation'">
                    <div class="modal-content__container">
                        <nova-text-element [data]="data.bookinginformationtitle"></nova-text-element>
                        <nova-text-element [data]="data.bookinginformation"></nova-text-element>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<nova-text-gallery [medias]="data.textImages" #gallery></nova-text-gallery>