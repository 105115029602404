import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation, ElementRef, Renderer2 } from '@angular/core';
import { NovaAPIService } from '../../_services/nova-api.service';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NovaNavItemComponent } from '../nova-nav-item/nova-nav-item.component';
import { MagazineService } from 'app/_services/magazine.service';

@Component({
    selector: 'nova-nav-border-item',
    template: '<ng-content></ng-content>',
    styles: [
        `
            nova-nav-border-item > span {
                opacity: 0.4;
            }
            nova-nav-border-item > span.active {
                opacity: 1;
            }
        `
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NovaNavBorderItemComponent extends NovaNavItemComponent implements OnInit {
    constructor(
        nova: NovaAPIService,
        mag: MagazineService,
        ui: UIEventHandlerService,
        sanitizer: DomSanitizer,
        public element: ElementRef,
        public renderer: Renderer2
    ) {
        super(nova, mag, ui, sanitizer, element, renderer);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    afterInit() {
        if (!this.style) {
            this.style = {};
        }
        if (!this.style['border']) {
            this.style['border'] = '1px solid ' + this.ui.getColor(this.nav.borderColor);
        }
        super.afterInit();
    }
}
