import { ErrorDisabledComponent } from './error/error-page.component.1';
import { ErrorPageComponent } from './error/error-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { PageNotFoundComponent } from './not-found-component';
import { PageComponent } from './pages/page.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ErrorComponent } from './error/error.component';
import { ArchiveScrollComponent } from './landing-page/templates/archive-scroll/archive-scroll.component';
import { ArchiveGridComponent } from './landing-page/templates/archive-grid/archive-grid.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_helpers/auth.guard';
import { AuthComponent } from './auth/auth.component';

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'login/:code', component: LoginComponent },
    {
        path: 'login/imprint',
        component: LoginComponent,
        data: {
            imprint: true
        }
    },
    {
        path: 'login/terms',
        component: LoginComponent,
        data: {
            terms: true
        }
    },
    { path: 'auth', component: AuthComponent },
    {
        path: 'error',
        component: ErrorComponent
    },
    {
        path: 'error/:code/:message',
        component: ErrorComponent
    },
    {
        path: 'errorPage',
        component: ErrorPageComponent
    },
    {
        path: 'errorDisabled',
        component: ErrorDisabledComponent
    },
    {
        path: 'imprint',
        component: LandingPageComponent,
        canActivate: [AuthGuard],
        data: {
            imprint: true
        }
    },
    {
        path: 'terms',
        component: LandingPageComponent,
        canActivate: [AuthGuard],
        data: {
            terms: true
        }
    },
    {
        path: '',
        component: LandingPageComponent,
        canActivate: [AuthGuard]
    },
    {
        path: ':langHandle',
        component: LandingPageComponent,
        canActivate: [AuthGuard]
    },
    {
        path: ':langHandle/:issueHandle',
        component: PageComponent,
        canActivate: [AuthGuard]
    },
    {
        path: ':langHandle/:issueHandle/:pageHandle',
        component: PageComponent,
        canActivate: [AuthGuard]
    },
    {
        path: ':langHandle/:issueHandle/:pageHandle/:contentHandle',
        component: PageComponent,
        canActivate: [AuthGuard]
    },
    {
        path: '',
        redirectTo: '/',
        pathMatch: 'full'
    },
    { path: '**', component: PageNotFoundComponent }
];

export class CustomStrategy extends RouteReuseStrategy {
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }
    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {}
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return null;
    }
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return false;
    }
}

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
export const routedComponents = [
    PageNotFoundComponent,
    LandingPageComponent,
    ArchiveScrollComponent,
    ArchiveGridComponent,
    ErrorComponent,
    ErrorPageComponent,
    ErrorDisabledComponent,
    LoginComponent,
    AuthComponent
];
