import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { MagazineService } from '../_services/magazine.service';
import { Subscription } from 'rxjs';
import { SSRService } from '../_services/ssr.service';
import { UIEventHandlerService } from '../_services/uievent-handler.service';
import { ColorFormatter } from '../novamag-api/Types';
import { Title } from '@angular/platform-browser';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    public model: any = {
        username: null,
        password: null
    };
    public loading = false;
    private sub: Subscription;
    public returnUrl: string;
    public authType: string;
    public magId: string;
    public magazine: any;
    public logo: string;
    public buttonHovered = false;
    public error = null;

    public code: number;
    private paramsSub: any;

    constructor(
        private route: ActivatedRoute,
        private ui: UIEventHandlerService,
        private mag: MagazineService,
        private ssr: SSRService,
        private router: Router,
        private authenticationService: AuthService,
        public title: Title
    ) {}

    ngOnInit() {
        if (this.ssr.isBrowser) {
            this.authenticationService.logout();
        }
        this.ui.disableActionNav = true;
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

        this.sub = this.mag.getMagazine().subscribe(mag => {
            this.authType = mag.authType || 'none';
            this.magId = mag._id;
            this.magazine = mag;
            this.logo = mag.logo && mag.logo.url ? mag.logo.url : '';
        });

        this.paramsSub = this.route.params.subscribe(params => {
            this.code = +params['code'];
        });

        this.title.setTitle('Log in');
    }

    login(formData: any) {
        this.loading = true;
        if (this.authType === 'global' || this.authType === 'password-protection') {
            this.model.username = this.magId + '@novamag.de';
        }
        this.authenticationService.login(this.model.username, this.model.password, this.magId).subscribe(
            () => {
                this.ui.disableActionNav = false;

                if (this.returnUrl === '/' || !this.returnUrl) {
                    // get index page url
                    return this.mag
                        .getIssues()
                        .toPromise()
                        .then(({ issues }) => {
                            if (issues && issues.length > 0) {
                                if (issues[0].languages && issues[0].languages.length > 0) {
                                    const url = `${this.magazine.language}/${issues[0].languages[0].handle}`;

                                    this.router.navigate([url]);
                                    setTimeout(() => location.reload(), 100);

                                    return;
                                }
                            }

                            this.router.navigate(['/']);
                            setTimeout(() => location.reload(), 100);
                        });
                }

                this.router.navigate([this.returnUrl]);
                setTimeout(() => location.reload(), 100);
            },
            error => {
                if (error.error.message === 'User not aviable' || error.error.message === 'Wrong password') {
                    if (formData.form.controls['username']) {
                        formData.form.controls['username'].setErrors({ incorrect: true });
                    }
                    formData.form.controls['password'].setErrors({ incorrect: true });
                    this.error = 'invalidUserData';
                }

                if (error.error.message === 'Account is outdated') {
                    this.error = 'accountIsOutdated';
                }

                if (error.error.message === 'Forbidden') {
                    this.error = 'Forbidden';
                }

                this.loading = false;
            }
        );
    }

    imprint() {
        this.ui.showTerms = true;
    }

    terms() {
        this.ui.showTerms = true;
    }

    textToHtml(text) {
        return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }

    getVersion() {
        if (this.ui.isMobile()) {
            return 'horizontal-wide';
        } else {
            return false;
        }
    }

    color(color) {
        return new ColorFormatter(color).rgba();
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        if (this.paramsSub) {
            this.paramsSub.unsubscribe();
        }
    }
}
