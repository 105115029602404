import { DomSanitizer } from '@angular/platform-browser';
import { Component, ViewEncapsulation } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: 'multiple-background.component.html',
    styleUrls: ['../../templates/multiple-background/multiple-background.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('ServerMultipleBackgroundComponent')
export class ServerMultipleBackgroundComponent extends Page implements OnInit {
    constructor(
        public nova: NovaAPIService,
        public sanitizer: DomSanitizer,
        public renderer: Renderer2,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    ngOnInit() {
        this.addOgTags();
    }
}
