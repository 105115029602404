/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nova-london-overlay-social.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./nova-london-overlay-social.component";
import * as i4 from "../../../_services/nova-api.service";
import * as i5 from "../../../_services/magazine.service";
import * as i6 from "../../../_services/uievent-handler.service";
import * as i7 from "@angular/router";
var styles_NovaLondonOverlaySocialComponent = [i0.styles];
var RenderType_NovaLondonOverlaySocialComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NovaLondonOverlaySocialComponent, data: {} });
export { RenderType_NovaLondonOverlaySocialComponent as RenderType_NovaLondonOverlaySocialComponent };
export function View_NovaLondonOverlaySocialComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 29, "div", [["class", "share"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "social-button facebook"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.share("facebook") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "icon-facebook"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "a", [["class", "social-button twitter"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.share("twitter") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "icon-twitter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 4, "a", [["class", "social-button xing"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.share("xing") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["class", "icon-xing"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(18, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 4, "a", [["class", "social-button linkedin"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.share("linkedin") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "i", [["class", "icon-linkedin"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(22, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(24, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 4, "a", [["class", "social-button mail"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.share("mail") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 0, "i", [["class", "icon-mail-o"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(28, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("SHARE")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("SHARE")); _ck(_v, 10, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("SHARE")); _ck(_v, 16, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 22, 0, i1.ɵnov(_v, 23).transform("SHARE")); _ck(_v, 22, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 28, 0, i1.ɵnov(_v, 29).transform("SHARE")); _ck(_v, 28, 0, currVal_4); }); }
export function View_NovaLondonOverlaySocialComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-london-overlay-social", [], null, null, null, View_NovaLondonOverlaySocialComponent_0, RenderType_NovaLondonOverlaySocialComponent)), i1.ɵdid(1, 49152, null, 0, i3.NovaLondonOverlaySocialComponent, [i4.NovaAPIService, i5.MagazineService, i6.UIEventHandlerService, i7.Router, i2.TranslateService], null, null)], null, null); }
var NovaLondonOverlaySocialComponentNgFactory = i1.ɵccf("nova-london-overlay-social", i3.NovaLondonOverlaySocialComponent, View_NovaLondonOverlaySocialComponent_Host_0, { font: "font", magazine: "magazine" }, {}, []);
export { NovaLondonOverlaySocialComponentNgFactory as NovaLondonOverlaySocialComponentNgFactory };
