import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
if (environment.production) {
    enableProdMode();
}
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';
import * as __NgCli_bootstrap_1 from "./app/app.browser.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (typeof Modernizr !== 'undefined' && !Modernizr['flexbox']) {
    window.location.href = 'BROWSER_UPDATE_URL_REPLACE';
}
if (typeof window !== 'undefined') {
    document.body.classList.remove('hidden');
}
document.addEventListener('DOMContentLoaded', function () {
    __NgCli_bootstrap_2.platformBrowser()
        .bootstrapModuleFactory(__NgCli_bootstrap_1.AppBrowserModuleNgFactory)
        .catch(function (err) { return console.log(err); });
});
