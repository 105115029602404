/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nova-mobile-img.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./nova-mobile-img.component";
import * as i4 from "../../_services/media.service";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../_services/uievent-handler.service";
var styles_NovaMobileImgComponent = [i0.styles];
var RenderType_NovaMobileImgComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NovaMobileImgComponent, data: {} });
export { RenderType_NovaMobileImgComponent as RenderType_NovaMobileImgComponent };
function View_NovaMobileImgComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "source", [], [[8, "src", 4], [8, "type", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.src; var currVal_1 = _v.context.$implicit.type; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_NovaMobileImgComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[2, 0], ["videoBgElm", 1]], null, 2, "video", [["autoplay", ""], ["loop", ""], ["muted", ""], ["playsinline", ""]], [[8, "poster", 4]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaMobileImgComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.videoSources; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 0, 0, currVal_0); }); }
function View_NovaMobileImgComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "plyr__video-embed"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[2, 0], ["videoBgElm", 1]], null, 0, "iframe", [["allowFullScreen", ""], ["frameborder", "0"], ["height", "100%"], ["mozallowfullscreen", ""], ["scrolling", "no"], ["webkitAllowFullScreen", ""], ["width", "100%"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.trustedUrl; _ck(_v, 1, 0, currVal_0); }); }
function View_NovaMobileImgComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["bgElm", 1]], null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaMobileImgComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaMobileImgComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.video && !_co.fallback) && _co.mediaData) && _co.mediaService.isLocalVideo(_co.mediaData)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (((_co.video && !_co.fallback) && _co.mediaData) && _co.mediaService.isVimeo(_co.mediaData)); _ck(_v, 4, 0, currVal_1); }, null); }
function View_NovaMobileImgComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["height", "1080px"], ["width", "1920px"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 0, 0, currVal_0); }); }
function View_NovaMobileImgComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "img-copyright"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\u00A9 ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mediaData.copyright; _ck(_v, 1, 0, currVal_0); }); }
export function View_NovaMobileImgComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { bgElm: 0 }), i1.ɵqud(671088640, 2, { videoBgElm: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaMobileImgComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaMobileImgComponent_5)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaMobileImgComponent_6)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.video && (_co.uiEventHandler.isMobile() || _co.uiEventHandler.isTablet())); _ck(_v, 3, 0, currVal_0); var currVal_1 = (!_co.video && _co.url); _ck(_v, 5, 0, currVal_1); var currVal_2 = ((!_co.video && _co.mediaData) && _co.mediaData.copyright); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_NovaMobileImgComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-mobile-img", [], [[1, "style", 2]], null, null, View_NovaMobileImgComponent_0, RenderType_NovaMobileImgComponent)), i1.ɵdid(1, 114688, null, 0, i3.NovaMobileImgComponent, [i4.MediaService, i1.Renderer2, i5.DomSanitizer, i6.UIEventHandlerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).valueAsStyle; _ck(_v, 0, 0, currVal_0); }); }
var NovaMobileImgComponentNgFactory = i1.ɵccf("nova-mobile-img", i3.NovaMobileImgComponent, View_NovaMobileImgComponent_Host_0, { data: "data", mediaId: "mediaId", mediaSize: "mediaSize", mediaVersion: "mediaVersion" }, {}, ["*"]);
export { NovaMobileImgComponentNgFactory as NovaMobileImgComponentNgFactory };
