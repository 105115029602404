import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'underscore';
import * as $ from 'jquery';
import { Meta, Title } from '@angular/platform-browser';
import { MediaService } from 'app/_services/media.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './travelworld.component.html',
    styleUrls: ['./travelworld.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageTravelworldComponent')
export class PageTravelworldComponent extends Page implements OnInit, AfterViewInit {
    public media: any[];
    public teaser: boolean;

    public active = -1;
    private wait = false;
    private nav;

    public gallerySliderMedia;

    @ViewChild('mobileScroller') private mobileScroller: ElementRef;

    constructor(
        public nova: NovaAPIService,
        public mediaService: MediaService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    ngOnInit() {
        this.teaser =
            (this.data.contents && this.data.contents.length > 0) ||
            (this.data.text && this.data.text.length > 0) ||
            this.data.header ||
            this.data.subHeader ||
            this.data.label;
        this.mediaService.loadMedia(this.data.medias).then(data => {
            this.media = data;
        });

        let arr = this.data.textImages;
        this.data.medias.forEach(element => {
            arr = arr.concat(element.textImages);
        });
        this.gallerySliderMedia = _.unique(arr);
        this.nova.magazineService.getMagazine().subscribe(data => {
            this.nav = data.navigation;
        });
    }

    getEntryStyle(val, wide?: boolean) {
        if (wide === true) {
            return 'url(' + this.mediaService.getMediaUrlByData(val, 'horizontal-wide') + ')';
        }
        return 'url(' + this.mediaService.getMediaUrlByData(val) + ')';
    }

    getId(id, key) {
        return id + '-' + key;
    }

    getFontColor(c, c2) {
        if (!c || !c2) return;

        return this.convertCSS(`
        background-color: rgba(${c2.r}, ${c2.g}, ${c2.b}, 1);
        color: rgba(${c.r}, ${c.g}, ${c.b}, 1);
      `);
    }

    getButtonStyling(val) {
        const c = val['ctaBackgroundcolor'];
        const c2 = val['ctaColor'];

        if (c && c2) {
            return {
                'background-color': `rgba(${c.r}, ${c.g}, ${c.b}, ${c.a})`,
                color: `rgba(${c2.r}, ${c2.g}, ${c2.b}, ${c2.a})`,
                'border-color': `rgba(${c2.r}, ${c2.g}, ${c2.b}, ${c2.a})`
            };
        } else {
            return null;
        }
    }

    calcWidth() {
        const length = Object.keys(this.data.medias).length;
        let classes = '';

        if (length === 1) {
            classes += 'slides-1 ';
        } else if (length === 2) {
            classes += 'slides-2 ';
        } else if (length === 3) {
            classes += 'slides-3 ';
        } else if (length === 4) {
            classes += 'slides-4 ';
        } else if (length === 5) {
            classes += 'slides-5 ';
        }

        if (this.active !== -1) {
            classes += 'active-slide ';
        }

        return classes;
    }

    ngAfterViewInit() {
        if (this.data.medias.length === 1) {
            const newHeight =
                $('body').height() -
                $(this.mobileScroller.nativeElement)
                    .find('.bg.teaser')
                    .outerHeight();

            $(this.mobileScroller.nativeElement)
                .find('.poker-medium')
                .css('height', newHeight + 'px');
        }
    }

    open(id) {
        if (this.active === id || this.wait) return;

        if (this.active === -1) {
            this.active = id;
        } else {
            this.active = -1;
        }

        this.triggerWait();
    }

    close() {
        if (this.wait) return;

        this.active = -1;
        this.triggerWait();
    }

    triggerWait() {
        this.wait = true;
        setTimeout(() => {
            this.wait = false;
            // resize sub content boxes
            if (this.active >= 0) {
                var elm = $($(this.mobileScroller.nativeElement).find('.poker .slide')[this.active]).find('.bg-small');
                if (elm.height() / $('body').height() >= 0.4) {
                    elm.height($('body').height() * 0.4 + 'px');
                }
            }
        }, 500);
    }

    convertCSS(css) {
        let input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {},
                attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    }

    setMyStyles(obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    }
}
