import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from './network.service';
import { map, shareReplay } from 'rxjs/operators';

@Injectable()
export class FontService {
    private fontCache: Observable<any>[] = [];

    constructor(private network: NetworkService) {}

    public getFontUrl(arr: string[]) {
        let enc = encodeURI(arr.join(','));
        enc = enc.replace(/%20/g, '+');
        if (enc === 'Arial') return;
        const resp = this.network.getUrl(`fonts/${enc}`);
        return resp;
    }

    public getFont(fontName: string): Observable<any> {
        if (this.fontCache[fontName]) return this.fontCache[fontName];
        this.fontCache[fontName] = this.network.fetch(`font/${encodeURI(fontName)}`).pipe(
            map(data => {
                if (data['status'] === 'success') {
                    if (data['font']['type'] === 'uploaded') {
                        data['font']['name'] = `'${fontName}', Arial`;
                    }
                    if (data['font']['type'] === 'google') {
                        data['font']['name'] = `'${data.font.name}', Arial`;
                    }
                    return data['font'];
                }

                console.log("oops, can't find font");
                throw new Error('can not find font');
            }),
            shareReplay(1)
        );

        return this.fontCache[fontName];
    }

    public loadFonts(fonts: string[]) {
        let i = 0;
        const length = fonts.length;
        let returned = false;

        return new Promise((resolve, reject) => {
            if (fonts.length === 0) resolve();

            fonts.forEach(val => {
                this.getFont(val).subscribe(() => {
                    i++;
                    if (i >= length && !returned) {
                        returned = true;
                        resolve();
                    }
                });
            });
        });
    }
}
