import * as tslib_1 from "tslib";
import { Location } from '@angular/common';
import { AfterViewInit, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Page } from 'app/pages/page';
import { NovaTemplate } from 'app/_decorators/template.decorator';
import { BlogService } from 'app/_services/blog.service';
import { MediaService } from 'app/_services/media.service';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { ActivatedRoute, Router } from '@angular/router';
var PagePflugfelderIndexComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PagePflugfelderIndexComponent, _super);
    function PagePflugfelderIndexComponent(nova, uiEventHandler, meta, title, mediaService, location, blogService, router, route) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.title = title;
        _this.mediaService = mediaService;
        _this.location = location;
        _this.blogService = blogService;
        _this.router = router;
        _this.route = route;
        _this.posts = [];
        _this.filteredPosts = [];
        _this.blogFonts = [];
        _this.categoriesHaveIcons = false;
        _this.isLoading = true;
        _this.categoriesVisible = false;
        _this.searchValue = '';
        _this.regions = {};
        return _this;
    }
    PagePflugfelderIndexComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var regions;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                regions = {};
                this.posts = this.data.contents.sort(function (a, b) {
                    if (a.name > b.name)
                        return 1;
                    if (b.name > a.name)
                        return -1;
                    return 0;
                });
                this.posts = this.data.contents.filter(function (content) { return content.showInOverview !== false; }).map(function (content) {
                    if (!content.region)
                        return;
                    if (!regions[content.region])
                        regions[content.region] = [];
                    regions[content.region].push(tslib_1.__assign({}, content, { sortedDistricts: _this.renderDistricts(content.relatedDistricts, content.districts) }));
                });
                this.regions = Object.entries(regions).sort(function (a, b) {
                    if (a[0] > b[0])
                        return 1;
                    if (b[0] > a[0])
                        return -1;
                    return 0;
                });
                this.isLoading = false;
                return [2 /*return*/];
            });
        });
    };
    PagePflugfelderIndexComponent.prototype.filter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var regions;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                regions = {};
                this.posts = this.data.contents
                    .filter(function (content) { return content.showInOverview !== false; })
                    .filter(function (content) {
                    return content.districts.toLowerCase().includes(_this.searchValue.toLowerCase()) ||
                        content.header.toLowerCase().includes(_this.searchValue.toLowerCase()) ||
                        !content.relatedDistricts;
                })
                    .map(function (content) {
                    if (!content.region)
                        return;
                    if (!regions[content.region])
                        regions[content.region] = [];
                    regions[content.region].push(tslib_1.__assign({}, content, { sortedDistricts: _this.renderDistricts(content.relatedDistricts, content.districts) }));
                });
                this.regions = Object.entries(regions).sort(function (a, b) {
                    if (a[0] > b[0])
                        return 1;
                    if (b[0] > a[0])
                        return -1;
                    return 0;
                });
                return [2 /*return*/];
            });
        });
    };
    PagePflugfelderIndexComponent.prototype.ngAfterViewInit = function () {
        this.data.contents.length > 0 && $('.next-page')[0].style.setProperty('opacity', '1', 'important');
    };
    PagePflugfelderIndexComponent.prototype.onItemSelect = function () {
        this.filter();
    };
    PagePflugfelderIndexComponent.prototype.OnItemDeSelect = function () {
        this.filter();
    };
    PagePflugfelderIndexComponent.prototype.onSelectAll = function () {
        this.filter();
    };
    PagePflugfelderIndexComponent.prototype.onDeSelectAll = function () {
        this.filter();
    };
    PagePflugfelderIndexComponent.prototype.open = function (id) {
        $("#" + id).toggleClass('open');
    };
    PagePflugfelderIndexComponent.prototype.stop = function (event) {
        event.stopPropagation();
    };
    PagePflugfelderIndexComponent.prototype.renderDistricts = function (relatedDistricts, districts) {
        var arr = tslib_1.__spread(relatedDistricts);
        if (districts.length > 0) {
            districts.split(',').map(function (district) {
                arr.push({ name: district.trim() });
            });
        }
        var sortedDistricts = arr.filter(function (item) { return item.name; }).sort(function (a, b) {
            if (a.name > b.name)
                return 1;
            if (b.name > a.name)
                return -1;
            return 0;
        });
        return sortedDistricts;
    };
    PagePflugfelderIndexComponent = tslib_1.__decorate([
        NovaTemplate('PagePflugfelderIndexComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            Meta,
            Title,
            MediaService,
            Location,
            BlogService,
            Router,
            ActivatedRoute])
    ], PagePflugfelderIndexComponent);
    return PagePflugfelderIndexComponent;
}(Page));
export { PagePflugfelderIndexComponent };
