import { ErrorComponent } from './error.component';
import { ColorFormatter } from './../novamag-api/Types';
import { UIEventHandlerService } from './../_services/uievent-handler.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NovaAPIService } from '../_services/nova-api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './error-disabled.component.html',
    styleUrls: ['./error.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ErrorDisabledComponent {
    constructor() {}
}
