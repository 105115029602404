import * as tslib_1 from "tslib";
import { OnInit, ElementRef, Renderer2 } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { MediaService } from 'app/_services/media.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import Hls from 'hls.js';
import * as Plyr from 'plyr';
var NovaMobileImgComponent = /** @class */ (function () {
    function NovaMobileImgComponent(mediaService, renderer, sanitizer, uiEventHandler) {
        this.mediaService = mediaService;
        this.renderer = renderer;
        this.sanitizer = sanitizer;
        this.uiEventHandler = uiEventHandler;
        this.mediaVersion = 'horizontal-wide';
    }
    Object.defineProperty(NovaMobileImgComponent.prototype, "valueAsStyle", {
        get: function () {
            return this.sanitizer.bypassSecurityTrustStyle("--mobile-img-size: " + (this.mediaId ? (this.data && this.data.mediaSize) || 25 : 0) + "vh");
        },
        enumerable: true,
        configurable: true
    });
    NovaMobileImgComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.data) {
            this.mediaId = this.data.mobileMedia || this.data.tabletMedia || this.data.background_media;
            this.mediaVersion = this.data.mediaVersion || this.data.tabletMediaVersion || 'horizontal-w3ide';
        }
        if (!this.mediaId) {
            return;
        }
        this.mediaService
            .getMedia(this.mediaId)
            .pipe(switchMap(function (data) {
            if (_this.mediaService.isVideo(data)) {
                _this.video = true;
                _this.videoSources = _this.mediaService.getVideo(data, true);
            }
            _this.mediaData = data;
            return _this.mediaService.getMediaUrl(_this.mediaId, (_this.video && 'screenshot') || _this.mediaVersion);
        }))
            .subscribe(function (url) {
            _this.url = url;
            setTimeout(function () {
                if (_this.mediaData.type && _this.mediaData.type === 'bunny') {
                    if (Hls.isSupported()) {
                        if (typeof window !== undefined) {
                            setTimeout(function () {
                                var videoOptions = {
                                    quality: {
                                        default: 720,
                                        options: [0, 720]
                                    }
                                };
                                var hls = new Hls({
                                    enableWorker: true,
                                    startLevel: 4
                                });
                                hls.loadSource(_this.mediaData.url);
                                hls.attachMedia(_this.videoBgElm.nativeElement);
                                hls.on(Hls.Events.MANIFEST_PARSED, function () {
                                    if (hls.currentLevel === -1) {
                                        // Auto
                                        if (hls.levels.length === 1) {
                                            hls.currentLevel = 0;
                                        }
                                        else if (hls.levels.length === 2) {
                                            hls.currentLevel = 1;
                                        }
                                        else {
                                            hls.currentLevel = 2;
                                        }
                                    }
                                    _this.player = new Plyr(_this.videoBgElm.nativeElement, tslib_1.__assign({}, videoOptions, { muted: true, controls: [], autoplay: true }));
                                });
                            }, 10);
                        }
                    }
                    else {
                        if (_this.videoBgElm &&
                            _this.videoBgElm.nativeElement.canPlayType('application/vnd.apple.mpegurl')) {
                            _this.videoBgElm.nativeElement.src = _this.mediaData.url;
                            _this.player = new Plyr(_this.videoBgElm.nativeElement, {
                                autoplay: true,
                                loop: { active: true },
                                muted: true,
                                controls: [],
                                clickToPlay: false,
                                fullscreen: { enabled: false },
                                storage: { enabled: false }
                            });
                        }
                    }
                    if (_this.video && typeof window !== 'undefined' && _this.videoBgElm) {
                        if (_this.mediaService.isLocalVideo(_this.mediaData)) {
                        }
                        else if (_this.mediaService.isVimeo(_this.mediaData)) {
                            _this.trustedUrl = _this.sanitizer.bypassSecurityTrustResourceUrl(_this.videoSources[0].src + "?background=1");
                        }
                    }
                    if (_this.bgElm && _this.bgElm.nativeElement) {
                        _this.renderer.setStyle(_this.bgElm.nativeElement, 'background-image', 'url(' +
                            _this.mediaService.getImageProxyUrl((_this.video && _this.url) || _this.mediaData.thumbnail, (_this.video && 'screenshot') || _this.mediaVersion) +
                            ')');
                    }
                    else {
                        _this.url = _this.mediaService.getImageProxyUrl(url, _this.mediaVersion);
                    }
                }
            }, 100);
        });
    };
    return NovaMobileImgComponent;
}());
export { NovaMobileImgComponent };
