import * as tslib_1 from "tslib";
import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { PagePortraitClassicComponent } from '../portrait-classic/portrait-classic.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ColorFormatter } from '../../../novamag-api/Types';
import { Meta, Title } from '@angular/platform-browser';
import { NovaTextGalleryComponent } from 'app/_components/nova-text-gallery/nova-text-gallery.component';
import * as $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';
var PagePortraitComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PagePortraitComponent, _super);
    function PagePortraitComponent(nova, uiEventHandler, sanitizer, meta, title, router, route) {
        var _this = _super.call(this, nova, uiEventHandler, sanitizer, meta, title, route, router) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.sanitizer = sanitizer;
        _this.meta = meta;
        _this.title = title;
        _this.router = router;
        _this.route = route;
        _this.isRevealed = false;
        _this.isAnimating = false;
        _this.isSliderOpen = false;
        return _this;
    }
    PagePortraitComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        var arrowStyle = '';
        var afterStyle = '';
        var c;
        if (this.data.arrowColor) {
            c = new ColorFormatter(this.data['arrowColor']);
            arrowStyle += 'color: ' + c.rgb() + ';';
        }
        c = new ColorFormatter({ r: 255, g: 255, b: 255, a: 255 });
        if (this.data['box_color']) {
            c = new ColorFormatter(this.data['box_color']);
        }
        c.c.a = 0;
        afterStyle = 'background: -webkit-linear-gradient(top, ' + c.rgba() + ' 0%, ';
        c.c.a = 1;
        afterStyle += c.rgba() + ' 85%);';
        c.c.a = 0;
        afterStyle += 'background: linear-gradient(to bottom, ' + c.rgba() + ' 0%, ';
        c.c.a = 1;
        afterStyle += c.rgba() + ' 85%);';
        this.arrowStyle = this.convertCSS(arrowStyle);
        this.afterStyle = this.convertCSS(afterStyle);
        setTimeout(function () {
            $(_this.container.nativeElement).on('scroll', function () {
                _this.scrollPage();
            });
        }, 2000);
        // refreshing the page...
        var pageScroll = this.scrollY();
        if (pageScroll) {
            this.isRevealed = true;
            $(this.container.nativeElement).addClass('notrans modify');
        }
    };
    PagePortraitComponent.prototype.scrollPage = function () {
        var scrollVal = this.scrollY();
        if ($(this.container.nativeElement).hasClass('notrans')) {
            $(this.container.nativeElement).removeClass('notrans');
            return false;
        }
        if (this.isAnimating) {
            return false;
        }
        if (scrollVal <= 0 && this.isRevealed) {
            this.toggle(false);
        }
        else if (scrollVal > 0 && !this.isRevealed) {
            this.toggle(true);
        }
    };
    PagePortraitComponent.prototype.close = function () {
        $(this.container.nativeElement).animate({
            scrollTop: 0
        }, 500);
        this.toggle(false);
    };
    PagePortraitComponent.prototype.scrollY = function () {
        return $(this.container.nativeElement).scrollTop();
    };
    PagePortraitComponent.prototype.toggle = function (reveal) {
        var _this = this;
        this.isAnimating = true;
        var container = $(this.container);
        if (reveal) {
            this.isRevealed = true;
        }
        else {
            this.isRevealed = false;
        }
        setTimeout(function () {
            _this.isAnimating = false;
        }, 600);
    };
    PagePortraitComponent.prototype.openSlider = function ($event) {
        this.gallery.openSlider($event);
        this.isSliderOpen = true;
    };
    PagePortraitComponent.prototype.closeSlider = function () {
        var _this = this;
        // console.log('close');
        setTimeout(function () {
            _this.isSliderOpen = false;
        }, 100);
    };
    PagePortraitComponent.prototype.ngOnDestroy = function () {
        $(this.container.nativeElement).off('scroll');
    };
    PagePortraitComponent.prototype.convertCSS = function (css) {
        var input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {}, attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    };
    PagePortraitComponent.prototype.setMyStyles = function (obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    };
    PagePortraitComponent = tslib_1.__decorate([
        NovaTemplate('PagePortraitComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            DomSanitizer,
            Meta,
            Title,
            Router,
            ActivatedRoute])
    ], PagePortraitComponent);
    return PagePortraitComponent;
}(PagePortraitClassicComponent));
export { PagePortraitComponent };
