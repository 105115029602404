/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cover-atlas-copco.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../_components/nova-bg/nova-bg.component.ngfactory";
import * as i3 from "../../../_components/nova-bg/nova-bg.component";
import * as i4 from "../../../_services/uievent-handler.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../_components/nova-title/nova-title.component.ngfactory";
import * as i7 from "../../../_components/nova-title/nova-title.component";
import * as i8 from "./cover-atlas-copco.component";
import * as i9 from "../../../_services/nova-api.service";
import * as i10 from "@angular/platform-browser";
import * as i11 from "@angular/router";
var styles_PageCoverAtlasCopcoComponent = [i0.styles];
var RenderType_PageCoverAtlasCopcoComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PageCoverAtlasCopcoComponent, data: {} });
export { RenderType_PageCoverAtlasCopcoComponent as RenderType_PageCoverAtlasCopcoComponent };
function View_PageCoverAtlasCopcoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "Logo"], ["src", "assets/img/templates_assets/cover-atlas-copco/logo-white.svg"]], null, null, null, null, null))], null, null); }
function View_PageCoverAtlasCopcoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "Logo"], ["src", "assets/img/templates_assets/cover-atlas-copco/logo-black.svg"]], null, null, null, null, null))], null, null); }
export function View_PageCoverAtlasCopcoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "page page-cover-atlas-copco"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "nova-bg", [], null, null, null, i2.View_NovaBgComponent_0, i2.RenderType_NovaBgComponent)), i1.ɵdid(2, 114688, null, 0, i3.NovaBgComponent, [i4.UIEventHandlerService], { data: [0, "data"], forceVideo: [1, "forceVideo"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "title-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "blue-box"]], [[2, "white", null]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "con"]], [[2, "white", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageCoverAtlasCopcoComponent_1)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageCoverAtlasCopcoComponent_2)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "nova-title", [], null, null, null, i6.View_NovaTitleComponent_0, i6.RenderType_NovaTitleComponent)), i1.ɵdid(11, 114688, null, 0, i7.NovaTitleComponent, [], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.data; var currVal_2 = true; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_5 = _co.blue; _ck(_v, 7, 0, currVal_5); var currVal_6 = !_co.blue; _ck(_v, 9, 0, currVal_6); var currVal_7 = _co.data; _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "page-", _co.id, ""); _ck(_v, 0, 0, currVal_0); var currVal_3 = !_co.blue; _ck(_v, 4, 0, currVal_3); var currVal_4 = !_co.blue; _ck(_v, 5, 0, currVal_4); }); }
export function View_PageCoverAtlasCopcoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_PageCoverAtlasCopcoComponent_0, RenderType_PageCoverAtlasCopcoComponent)), i1.ɵdid(1, 49152, null, 0, i8.PageCoverAtlasCopcoComponent, [i9.NovaAPIService, i4.UIEventHandlerService, i10.DomSanitizer, i10.Meta, i10.Title, i11.ActivatedRoute, i11.Router], null, null)], null, null); }
var PageCoverAtlasCopcoComponentNgFactory = i1.ɵccf("ng-component", i8.PageCoverAtlasCopcoComponent, View_PageCoverAtlasCopcoComponent_Host_0, {}, {}, []);
export { PageCoverAtlasCopcoComponentNgFactory as PageCoverAtlasCopcoComponentNgFactory };
