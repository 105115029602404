/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./nova-element-with-children.component";
import * as i3 from "../../_services/nova-api.service";
import * as i4 from "../../_services/uievent-handler.service";
var styles_NovaElementWithChildrenComponent = [];
var RenderType_NovaElementWithChildrenComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NovaElementWithChildrenComponent, data: {} });
export { RenderType_NovaElementWithChildrenComponent as RenderType_NovaElementWithChildrenComponent };
export function View_NovaElementWithChildrenComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { elm: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["elm", 1]], null, 2, "span", [], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.styles; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NovaElementWithChildrenComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "nova-element-with-children", [], null, null, null, View_NovaElementWithChildrenComponent_0, RenderType_NovaElementWithChildrenComponent)), i0.ɵdid(1, 1097728, null, 0, i2.NovaElementWithChildrenComponent, [i0.Renderer2, i3.NovaAPIService, i0.ElementRef, i4.UIEventHandlerService], null, null)], null, null); }
var NovaElementWithChildrenComponentNgFactory = i0.ɵccf("nova-element-with-children", i2.NovaElementWithChildrenComponent, View_NovaElementWithChildrenComponent_Host_0, { data: "data", name: "name", content_name: "content_name", ignoreSize: "ignoreSize", ignoreFont: "ignoreFont", styles: "styles" }, {}, ["*"]);
export { NovaElementWithChildrenComponentNgFactory as NovaElementWithChildrenComponentNgFactory };
