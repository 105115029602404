import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Meta, Title } from '@angular/platform-browser';
import * as $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './single-background.component.html',
    styleUrls: ['../multiple-background/multiple-background.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageSingleBackgroundComponent')
export class PageSingleBackgroundComponent extends Page {
    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    getVersion() {
        if (this.uiEventHandler.isMobile()) {
            return 'horizontal-wide';
        } else {
            return false;
        }
    }

    getScrollbarStyle(c) {
        if (this.uiEventHandler.isMobile()) {
            const height = $('body').height() - $('.mobile-nav').height();
            return this.convertCSS(`
        background-color: rgba(${c.r}, ${c.g}, ${c.b}, ${c.a});
        height: ${height}px;
      `);
        }

        return '';
    }

    convertCSS(css) {
        let input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {},
                attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    }

    setMyStyles(obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    }
}
