import * as tslib_1 from "tslib";
import { OnInit, Renderer2, AfterViewChecked } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { ActivatedRoute, Router } from '@angular/router';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Floor } from '../../floor';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { Meta, Title } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import { isPlatformBrowser } from '@angular/common';
var PagePflugfelderContentsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PagePflugfelderContentsComponent, _super);
    function PagePflugfelderContentsComponent(media, route, router, uiEventHandler, nova, meta, title, _renderer2, document, platformId) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.media = media;
        _this.meta = meta;
        _this.title = title;
        _this._renderer2 = _renderer2;
        _this.document = document;
        _this.platformId = platformId;
        _this.marketSections = {
            buyHouses: [],
            buyFlats: [],
            rentFlats: []
        };
        _this.marketSectionsLoading = true;
        _this.selectedCategory = 0;
        _this.districts = [];
        _this.mapLoaded = false;
        _this.contactData = {
            name: 'Pflugfelder Immobilien',
            position: 'Ihr Team der P Immobilien Beratung GmbH',
            phone: '07141/93660',
            mail: 'info@pflugfelder.de'
        };
        _this.sortedDistricts = [];
        return _this;
    }
    PagePflugfelderContentsComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var indexUrl, _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, styleLink, mapGlScript, widgetScript;
            return tslib_1.__generator(this, function (_t) {
                switch (_t.label) {
                    case 0:
                        this.uiEventHandler.showNavArrows = false;
                        this.uiEventHandler.topClose = true;
                        indexUrl = location.href.substring(0, location.href.lastIndexOf('/'));
                        this.sortedDistricts = this.renderDistricts(this.data.relatedDistricts, this.data.districts);
                        _a = this;
                        _b = {};
                        _c = {
                            title: this.data.section01
                        };
                        return [4 /*yield*/, this.media.getMediaUrl(this.data.section01_image, 'source').toPromise()];
                    case 1:
                        _d = [
                            (_c.image = _t.sent(),
                                _c.price = this.data.section01_price,
                                _c.priceRange = this.data.section01_priceRange,
                                _c)
                        ];
                        _e = {
                            title: this.data.section05
                        };
                        return [4 /*yield*/, this.media.getMediaUrl(this.data.section05_image, 'source').toPromise()];
                    case 2:
                        _d = _d.concat([
                            (_e.image = _t.sent(),
                                _e.priceTrend = this.data.section05_priceTrend,
                                _e)
                        ]);
                        _f = {
                            title: this.data.section09
                        };
                        return [4 /*yield*/, this.media.getMediaUrl(this.data.section09_image, 'source').toPromise()];
                    case 3:
                        _d = _d.concat([
                            (_f.image = _t.sent(),
                                _f.price = this.data.section09_price,
                                _f.priceRange = this.data.section09_priceRange,
                                _f)
                        ]);
                        _g = {
                            title: this.data.section08
                        };
                        return [4 /*yield*/, this.media.getMediaUrl(this.data.section08_image, 'source').toPromise()];
                    case 4:
                        _b.buyHouses = _d.concat([
                            (_g.image = _t.sent(),
                                _g)
                        ]);
                        _h = {
                            title: this.data.section02
                        };
                        return [4 /*yield*/, this.media.getMediaUrl(this.data.section02_image, 'source').toPromise()];
                    case 5:
                        _j = [
                            (_h.image = _t.sent(),
                                _h.price = this.data.section02_price,
                                _h.priceRange = this.data.section02_priceRange,
                                _h)
                        ];
                        _k = {
                            title: this.data.section06
                        };
                        return [4 /*yield*/, this.media.getMediaUrl(this.data.section06_image, 'source').toPromise()];
                    case 6:
                        _j = _j.concat([
                            (_k.image = _t.sent(),
                                _k.priceTrend = this.data.section06_priceTrend,
                                _k)
                        ]);
                        _l = {
                            title: this.data.section10
                        };
                        return [4 /*yield*/, this.media.getMediaUrl(this.data.section10_image, 'source').toPromise()];
                    case 7:
                        _j = _j.concat([
                            (_l.image = _t.sent(),
                                _l.price = this.data.section10_price,
                                _l.priceRange = this.data.section10_priceRange,
                                _l)
                        ]);
                        _m = {
                            title: this.data.section04
                        };
                        return [4 /*yield*/, this.media.getMediaUrl(this.data.section04_image, 'source').toPromise()];
                    case 8:
                        _b.buyFlats = _j.concat([
                            (_m.image = _t.sent(),
                                _m.rentalYield = this.data.section04_rentalYield,
                                _m)
                        ]);
                        _o = {
                            title: this.data.section03
                        };
                        return [4 /*yield*/, this.media.getMediaUrl(this.data.section03_image, 'source').toPromise()];
                    case 9:
                        _p = [
                            (_o.image = _t.sent(),
                                _o.price = this.data.section03_price,
                                _o.priceRange = this.data.section03_priceRange,
                                _o)
                        ];
                        _q = {
                            title: this.data.section07
                        };
                        return [4 /*yield*/, this.media.getMediaUrl(this.data.section07_image, 'source').toPromise()];
                    case 10:
                        _p = _p.concat([
                            (_q.image = _t.sent(),
                                _q.priceTrend = this.data.section07_priceTrend,
                                _q)
                        ]);
                        _r = {
                            title: this.data.section11
                        };
                        return [4 /*yield*/, this.media.getMediaUrl(this.data.section11_image, 'source').toPromise()];
                    case 11:
                        _p = _p.concat([
                            (_r.image = _t.sent(),
                                _r.price = this.data.section11_price,
                                _r.priceRange = this.data.section11_priceRange,
                                _r)
                        ]);
                        _s = {
                            title: this.data.section04
                        };
                        return [4 /*yield*/, this.media.getMediaUrl(this.data.section04_image, 'source').toPromise()];
                    case 12:
                        _a.marketSections = (_b.rentFlats = _p.concat([
                            (_s.image = _t.sent(),
                                _s.rentalYield = this.data.section04_rentalYield,
                                _s)
                        ]),
                            _b);
                        this.marketSectionsLoading = false;
                        this.setContactData(this.data.contact);
                        if (isPlatformBrowser(this.platformId)) {
                            styleLink = this._renderer2.createElement('link');
                            styleLink.href = 'https://api.mapbox.com/mapbox-gl-js/v2.4.1/mapbox-gl.css';
                            styleLink.rel = 'stylesheet';
                            this._renderer2.appendChild(this.document.head, styleLink);
                            mapGlScript = this._renderer2.createElement('script');
                            mapGlScript.src = 'https://api.mapbox.com/mapbox-gl-js/v2.4.1/mapbox-gl.js';
                            mapGlScript.setAttribute('defer', 'defer');
                            this._renderer2.appendChild(this.document.head, mapGlScript);
                            widgetScript = this._renderer2.createElement('script');
                            widgetScript.src = 'https://iframe.immowissen.org/loader.min.js';
                            widgetScript.dataset.company = 'pflugfelder-immobilien';
                            widgetScript.dataset.slug = 'immobilienbewertung';
                            widgetScript.dataset.bottimmo = true;
                            widgetScript.setAttribute('defer', 'defer');
                            this._renderer2.appendChild(this.document.querySelector('#widget-container'), widgetScript);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PagePflugfelderContentsComponent.prototype.setContactData = function (contact) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (contact) {
                    case 'Ansprechpartner 2':
                        this.contactData = {
                            name: 'Fabian Dietz',
                            position: 'Regionalleiter Neckar-Enz',
                            phone: '+49 (0) 171 44 97 956',
                            mail: 'fdietz@pflugfelder.de'
                        };
                        break;
                    case 'Ansprechpartner 3':
                        this.contactData = {
                            name: 'Jürgen Hengstberger',
                            position: 'Regionalleiter Strohgäu',
                            phone: '+49 (0) 173 / 425 77 55',
                            mail: 'jhengstberger@pflugfelder.de'
                        };
                        break;
                    case 'Ansprechpartner 4':
                        this.contactData = {
                            name: 'Yannick Altendorfer',
                            position: 'Regionalleiter Ludwigsburg',
                            phone: '+49 (0) 171 / 44 95 181',
                            mail: 'yaltendorfer@pflugfelder.de'
                        };
                        break;
                    case 'Ansprechpartner 5':
                        this.contactData = {
                            name: 'Nathalie Schmid',
                            position: 'Regionalleiterin Stuttgart',
                            phone: '+49 (0)174 / 318 77 55',
                            mail: 'nschmid@pflugfelder.de'
                        };
                        break;
                    default:
                        break;
                }
                return [2 /*return*/];
            });
        });
    };
    PagePflugfelderContentsComponent.prototype.selectCategory = function (category) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.selectedCategory = category;
                return [2 /*return*/];
            });
        });
    };
    PagePflugfelderContentsComponent.prototype.ngAfterViewChecked = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var mapScript, _a, lat, lng, randomLetter, mapId;
            return tslib_1.__generator(this, function (_b) {
                if (isPlatformBrowser(this.platformId)) {
                    // @ts-ignore
                    if (window.mapboxgl && !this.mapLoaded) {
                        mapScript = this._renderer2.createElement('script');
                        _a = tslib_1.__read(this.data.coordinates.split(','), 2), lat = _a[0], lng = _a[1];
                        randomLetter = (Math.random() + 1).toString(36).substring(7);
                        mapId = this.data._id.replace(/\d+/g, randomLetter);
                        mapScript.text = "\n                window.mapboxgl.accessToken = 'pk.eyJ1IjoiaWlibWFwcyIsImEiOiJja3dseG93d3AweGt5Mm9tdG8ydnZsNGt2In0.ovU408o_AqCDJgESUApaHA';\n                let " + mapId + " = new mapboxgl.Map({\n                    container: 'map',\n                    style: 'mapbox://styles/iibmaps/ckromvmcq3up519o1au7nfo37', center: { lat: " + lat + ", lon: " + lng + " },\n                    zoom: " + (this.data.zoomLevel || '12') + ",\n                });\n\n                " + mapId + ".on('load', () => {\n                    " + mapId + ".addSource('residential-map-areas-source', { type: 'raster',\n                        tiles: ['https://prod.gcp-maps.iib-it.de/areas/{z}/{x}/{y}.png'],\n                        scheme: 'tms',\n                        tileSize: 256\n                    });\n\n                    " + mapId + ".addSource('residential-map-ratings-source', {\n                        type: 'raster',\n                        tiles: ['https://prod.gcp-maps.iib-it.de/ratings/{z}/{x}/{y}.png'],\n                        scheme: 'tms',\n                        tileSize: 256\n                    });\n\n                    " + mapId + ".addLayer({\n                        id: 'residential-map-areas',\n                        type: 'raster',\n                        source: 'residential-map-areas-source', minzoom: 10,\n                        maxzoom: 18,\n                    }, 'iib_gewerbe');\n\n                    " + mapId + ".addLayer({\n                        id: 'residential-map-ratings',\n                        type: 'raster',\n                        source: 'residential-map-ratings-source', minzoom: 10,\n                        maxzoom: 18,\n                    });\n                });\n            ";
                        mapScript.setAttribute('defer', 'defer');
                        this._renderer2.appendChild(this.document.body, mapScript);
                        this.mapLoaded = true;
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    PagePflugfelderContentsComponent.prototype.getItemTitle = function (title) {
        return title.replace('m2 - Preisen', 'm<sup>2</sup> - Preisen');
    };
    PagePflugfelderContentsComponent.prototype.renderDistricts = function (relatedDistricts, districts) {
        var arr = tslib_1.__spread(relatedDistricts);
        if (districts.length > 0) {
            districts.split(',').map(function (district) {
                arr.push({ name: district.trim() });
            });
        }
        var sortedDistricts = arr.filter(function (item) { return item.name; }).sort(function (a, b) {
            if (a.name > b.name)
                return 1;
            if (b.name > a.name)
                return -1;
            return 0;
        });
        return sortedDistricts;
    };
    PagePflugfelderContentsComponent = tslib_1.__decorate([
        NovaTemplate('PagePflugfelderContentsComponent'),
        tslib_1.__metadata("design:paramtypes", [MediaService,
            ActivatedRoute,
            Router,
            UIEventHandlerService,
            NovaAPIService,
            Meta,
            Title,
            Renderer2, Object, Object])
    ], PagePflugfelderContentsComponent);
    return PagePflugfelderContentsComponent;
}(Floor));
export { PagePflugfelderContentsComponent };
