import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { ActivatedRoute, Router } from '@angular/router';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Floor } from '../../floor';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { Meta, Title } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
var PageGebirgslandeplaetzeDetailComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageGebirgslandeplaetzeDetailComponent, _super);
    function PageGebirgslandeplaetzeDetailComponent(media, route, router, uiEventHandler, nova, meta, title, document, platformId) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.media = media;
        _this.meta = meta;
        _this.title = title;
        _this.document = document;
        _this.platformId = platformId;
        return _this;
    }
    PageGebirgslandeplaetzeDetailComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.uiEventHandler.showNavArrows = false;
                this.uiEventHandler.topClose = true;
                this.params = this.route.params['value'];
                return [2 /*return*/];
            });
        });
    };
    PageGebirgslandeplaetzeDetailComponent.prototype.downloadMap2 = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.media.getMediaUrl(this.data.map2, 'original').toPromise()];
                    case 1:
                        url = _a.sent();
                        window.open(url);
                        return [2 /*return*/];
                }
            });
        });
    };
    PageGebirgslandeplaetzeDetailComponent.prototype.getSymbolClass = function (symbol) {
        switch (symbol) {
            case 'abwindN':
            case 'abwindNO':
            case 'abwindO':
            case 'abwindSO':
            case 'hindernisBahninstallationen':
            case 'sackgasseSO':
            case 'schattenSO':
                return symbol + '-' + this.params['langHandle'];
            default:
                return symbol;
        }
    };
    PageGebirgslandeplaetzeDetailComponent.prototype.goToLegend = function () {
        var pageName = this.params['langHandle'] === 'en' ? 'symbols-and-terms' : 'symbole-und-begriffe';
        this.router.navigate(['/', this.params['langHandle'], this.params['issueHandle'], pageName], {
            queryParamsHandling: 'preserve'
        });
    };
    PageGebirgslandeplaetzeDetailComponent = tslib_1.__decorate([
        NovaTemplate('PageGebirgslandeplaetzeDetailComponent'),
        tslib_1.__metadata("design:paramtypes", [MediaService,
            ActivatedRoute,
            Router,
            UIEventHandlerService,
            NovaAPIService,
            Meta,
            Title, Object, Object])
    ], PageGebirgslandeplaetzeDetailComponent);
    return PageGebirgslandeplaetzeDetailComponent;
}(Floor));
export { PageGebirgslandeplaetzeDetailComponent };
