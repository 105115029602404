import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'nova-title',
    templateUrl: './nova-title.component.html'
})
export class NovaTitleComponent implements OnInit {
    @Input() public data;

    @Input() public classes;

    constructor() {}

    ngOnInit() {}
}
