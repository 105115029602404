import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { ActivatedRoute, Router } from '@angular/router';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Floor } from '../../floor';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { Meta, Title } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import * as $ from 'jquery';

@Component({
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageProductComponent')
export class PageProductComponent extends Floor implements OnInit {
    public data: any;
    public id: number;
    public pageId: number;
    public url: string;
    public urlThumb: string;

    constructor(
        public media: MediaService,
        route: ActivatedRoute,
        router: Router,
        uiEventHandler: UIEventHandlerService,
        nova: NovaAPIService,
        public meta: Meta,
        public title: Title
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    ngOnInit() {
        this.media.getMedia(this.data.product_image).subscribe(data => {
            this.url = data.url;
            this.urlThumb = data.thumb.url;
            if (!this.uiEventHandler.isMobile()) {
                requestAnimationFrame(() => {
                    $('.pan').pan();
                });
            }
        });
    }
}
