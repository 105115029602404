/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nova-content-sidebar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../nova-element/nova-element.component.ngfactory";
import * as i3 from "../nova-element/nova-element.component";
import * as i4 from "../../_services/nova-api.service";
import * as i5 from "../../_services/uievent-handler.service";
import * as i6 from "../nova-text-element/nova-text-element.component.ngfactory";
import * as i7 from "../nova-text-element/nova-text-element.component";
import * as i8 from "../../_services/media.service";
import * as i9 from "@angular/router";
import * as i10 from "@angular/platform-browser";
import * as i11 from "../../_services/magazine.service";
import * as i12 from "../../_services/cookie.service";
import * as i13 from "@angular/common";
import * as i14 from "./nova-content-sidebar.component";
import * as i15 from "@ngx-translate/core";
var styles_NovaContentSidebarComponent = [i0.styles];
var RenderType_NovaContentSidebarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NovaContentSidebarComponent, data: {} });
export { RenderType_NovaContentSidebarComponent as RenderType_NovaContentSidebarComponent };
function View_NovaContentSidebarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h3", [["class", "dateline"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "nova-element", [["name", "label"]], null, null, null, i2.View_NovaElementComponent_0, i2.RenderType_NovaElementComponent)), i1.ɵdid(2, 1097728, null, 0, i3.NovaElementComponent, [i1.Renderer2, i4.NovaAPIService, i1.ElementRef, i5.UIEventHandlerService], { data: [0, "data"], name: [1, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; var currVal_1 = "label"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_NovaContentSidebarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-element", [["name", "header"]], null, null, null, i2.View_NovaElementComponent_0, i2.RenderType_NovaElementComponent)), i1.ɵdid(1, 1097728, null, 0, i3.NovaElementComponent, [i1.Renderer2, i4.NovaAPIService, i1.ElementRef, i5.UIEventHandlerService], { data: [0, "data"], name: [1, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; var currVal_1 = "header"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_NovaContentSidebarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "nova-text-element", [], null, [[null, "openGallery"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("openGallery" === en)) {
        var pd_0 = (_co.gallery.openSlider($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_NovaTextElementComponent_0, i6.RenderType_NovaTextElementComponent)), i1.ɵdid(2, 4964352, null, 0, i7.NovaTextElementComponent, [i5.UIEventHandlerService, i8.MediaService, i4.NovaAPIService, i9.Router, i10.DomSanitizer, i9.ActivatedRoute, i11.MagazineService, i12.CookieService, i13.DOCUMENT], { data: [0, "data"], myid: [1, "myid"] }, { openGallery: "openGallery" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.subHeader; var currVal_1 = _co.data._id; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_NovaContentSidebarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "scrollbar-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "scroller"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaContentSidebarComponent_1)), i1.ɵdid(3, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaContentSidebarComponent_2)), i1.ɵdid(6, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaContentSidebarComponent_3)), i1.ɵdid(8, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.label; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.data.header; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.data.subHeader; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_NovaContentSidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-content-sidebar", [], null, null, null, View_NovaContentSidebarComponent_0, RenderType_NovaContentSidebarComponent)), i1.ɵdid(1, 114688, null, 0, i14.NovaContentSidebarComponent, [i15.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NovaContentSidebarComponentNgFactory = i1.ɵccf("nova-content-sidebar", i14.NovaContentSidebarComponent, View_NovaContentSidebarComponent_Host_0, { data: "data", gallery: "gallery" }, {}, []);
export { NovaContentSidebarComponentNgFactory as NovaContentSidebarComponentNgFactory };
