import { assignIn } from 'lodash';
import { Injectable } from '@angular/core';

@Injectable()
export class RenderService {
    public foundImages;
    public foundMedia;
    public foundFonts = [];

    public render(output) {
        if (typeof window === 'undefined') {
            return output;
        }

        this.foundImages = [];
        this.foundMedia = [];

        if (!output) {
            return '';
        }

        const elm = $('<div>' + output + '</div>');
        const styleElm = elm.find('[style]');
        $.each(styleElm, (_, val) => {
            const font = $(val).css('font-family');
            if (font) {
                this.foundFonts.push(font.replace(/['"]+/g, ''));
            }
        });

        $.each(elm.find('video'), (_, val) => {
            if ($(val).hasClass('loaded')) return;

            if ($(val).data('video-id')) {
                this.foundMedia.push($(val).data('video-id'));
            }

            if ($(val).attr('poster')) {
                $(val).removeAttr('poster');
            }

            let filter = false;

            if ($(val).data('type') === 'internal') {
                $(val).append('<source src="' + $(val).data('link') + '" type="video/mp4">');
            } else if ($(val).data('type') === 'vimeo') {
                $(val).hide();
                let url = $(val).data('link');
                url = url.replace('//vimeo.com/', '//player.vimeo.com/video/');
                $(val)
                    .parent()
                    .append(
                        '<span class="embed-container"><span class="hiddenlayer"></span><iframe scrolling="no" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen src="' +
                            url +
                            '"></iframe></span>'
                    );
            } else if ($(val).data('type') === 'youtube') {
                $(val).hide();
                let url = $(val).data('link');
                url = url.replace('watch?v=', 'embed/') + '?modestbranding=1&showinfo=0&playsinline=1';
                $(val)
                    .parent()
                    .append(
                        '<span class="embed-container"><span class="hiddenlayer"></span><iframe scrolling="no" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen src="' +
                            url +
                            '"></iframe></span>'
                    );
            }

            $(val)
                .closest('p')
                .addClass('video-p');

            if ($(val).data('size') === 'big') {
                $(val)
                    .closest('p')
                    .addClass('big-parent');

                filter = true;
            }

            $(val)
                .closest('.fr-video')
                .wrap('<span class="figure video"><span class="inner"></span></span>');

            if (
                $(val)
                    .closest('.fr-video')
                    .data('caption-titel')
            ) {
                $(val).attr(
                    'data-caption-titel',
                    $(val)
                        .closest('.fr-video')
                        .data('caption-titel')
                );
            }

            if (
                $(val)
                    .closest('.fr-video')
                    .data('caption-description')
            ) {
                $(val).attr(
                    'data-caption-description',
                    $(val)
                        .closest('.fr-video')
                        .data('caption-description')
                );
            }

            if (filter) {
                $(val)
                    .closest('p')
                    .contents()
                    .filter(function() {
                        return this.nodeName !== 'FIGURE' && this.nodeName !== 'SPAN';
                    })
                    .wrap('<p></p>');
            }

            $(val).addClass('loaded');
        });

        $.each(elm.find('table'), (_key, val) => {
            $(val).wrap('<div class="table-wrapper"></div>');
        });

        const imgElm = elm.find('img');
        $.each(imgElm, (_key, val) => {
            const url = $(val).attr('src');
            const parser = document.createElement('a');
            parser.href = url;

            if ($(val).data('source')) {
                this.foundMedia.push($(val).data('source'));
            }

            if ($(val).data('expand') !== false && !$(val).data('link')) {
                if ($(val).data('source') && $(val).data('version') !== 'original') {
                    this.foundImages.push({
                        media: $(val).data('source'),
                        name: $(val).data('caption-titel'),
                        description: $(val).data('caption-description'),
                        original: false
                    });
                } else {
                    this.foundImages.push({
                        url: $(val).attr('src'),
                        media: $(val).data('source'),
                        name: $(val).data('caption-titel'),
                        description: $(val).data('caption-description'),
                        original: true
                    });
                }
            }

            let classes = '';
            const style = {};
            const innerStyle = {};
            let filter = false;

            const width = $(val)[0].style.width;

            if (width) {
                let setWith = true;
                $(val).css('width', '');

                if (width.endsWith('%')) {
                    const widthP = width.substring(0, width.length - 1);

                    if (parseInt(widthP, 10) >= 100) {
                        classes += ' big';
                        setWith = false;

                        $(val)
                            .closest('p')
                            .addClass('big-parent');
                        filter = true;
                    }
                }

                if (setWith) {
                    assignIn(style, { width });
                }
            }

            if ($(val).hasClass('fr-dii')) {
                classes += ' inline';
            }

            if ($(val).hasClass('fr-dib') && !$(val).hasClass('fr-fil') && !$(val).hasClass('fr-fir')) {
                classes += ' center';
            }

            if ($(val).hasClass('fr-fil') && !$(val).hasClass('fr-dib')) {
                classes += ' lefti';
            }

            if ($(val).hasClass('fr-fir') && !$(val).hasClass('fr-dib')) {
                classes += ' righti';
                if (width) {
                    assignIn(style, { width });
                    $(val).css('width', '');
                }
            }

            if ($(val).hasClass('fr-dib') && $(val).hasClass('fr-fir')) {
                assignIn(style, { width: '100%', 'text-align': 'right' });
                assignIn(innerStyle, { width, display: 'inline-block' });
            }

            if ($(val).data('expand') !== false && !$(val).data('link')) {
                classes += ' hover';
            }

            if ($(val).data('link')) {
                classes += ' link';
            }

            const toCss = object =>
                Object.entries(object).reduce((styleString, [propName, propValue]) => {
                    return `${styleString}${propName}: ${propValue}; `;
                }, '');

            if (
                $(val)
                    .parent()
                    .hasClass('inner')
            ) {
                return;
            }

            const elmInner = $(val).wrap(
                `<span class="figure img ${classes}" style="${toCss(style)}">
                    <span class="inner" style="${toCss(innerStyle)}"></span>
                </span>`
            );

            if ($(val).data('expand') === true && !$(val).data('link')) {
                elmInner.closest('.inner').append('<span class="hover"><i class="fa fa-expand"></i></span>');
            }

            if (filter) {
                $(val)
                    .closest('p')
                    .contents()
                    .filter(function() {
                        return this.nodeName !== 'FIGURE' && this.nodeName !== 'SPAN';
                    })
                    .wrap('<p></p>');
            }
        });

        return elm.html() || '';
    }
}
