import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { ArchiveTemplate } from '../ArchiveTemplate';
import { MagazineService } from '../../../_services/magazine.service';
import { NovaAPIService } from '../../../_services/nova-api.service';
var ArchiveGridComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ArchiveGridComponent, _super);
    function ArchiveGridComponent(mag, nova, uiEventHandler) {
        var _this = _super.call(this, mag, nova, uiEventHandler) || this;
        _this.mag = mag;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        return _this;
    }
    ArchiveGridComponent.prototype.ngOnInit = function () { };
    return ArchiveGridComponent;
}(ArchiveTemplate));
export { ArchiveGridComponent };
