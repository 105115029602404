import {
    Component,
    ElementRef,
    OnInit,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import { SharedHotelHelperService } from '../hotel-detail-standard/shared-hotel-helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit } from '@angular/core/src/metadata/lifecycle_hooks';
import { MultiSelectItem } from './MultiSelectItem';
import { fadeAnimation } from '../travelworld-modern/travelworld-slider/fadeIn.animation';
import { IndexRoundtripFilterPipe } from './filter-tags.pipe';
import { setRemainingFilters, setFilters } from '../index-trips/index-trips.component';

@Component({
    templateUrl: './index-roundtrip.component.html',
    styleUrls: ['./index-roundtrip.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [SharedHotelHelperService, IndexRoundtripFilterPipe],
    animations: [fadeAnimation]
})
@NovaTemplate('PageIndexRoundtripComponent')
export class PageIndexRoundtripComponent extends Page implements OnInit, AfterViewInit {
    filteredData = [];
    allFilters: MultiSelectItem[] = [];
    allCountryFilters: MultiSelectItem[] = [];
    selectedFilters: MultiSelectItem[] = [];
    selectedInterests: MultiSelectItem[] = [];
    selectedStartingPoints: MultiSelectItem[] = [];

    interestsDropdownSettings = {
        singleSelection: false,
        text: 'Nach Reiseart filtern',
        enableCheckAll: false,
        showCheckbox: true,
        enableSearchFilter: false,
        classes: 'nova-multiselect'
    };

    categoriesDropdownSettings = {
        singleSelection: false,
        text: 'Nach Interessen filtern',
        enableCheckAll: false,
        showCheckbox: true,
        enableSearchFilter: false,
        classes: 'nova-multiselect'
    };

    startingPointsDropdownSettings = {
        singleSelection: false,
        text: 'Nach Startort filtern',
        enableCheckAll: false,
        showCheckbox: true,
        enableSearchFilter: false,
        classes: 'nova-multiselect'
    };

    contentFontStyle = '';
    allFilterStrings = [];
    allCountryFilterStrings = [];
    startingPointFilters = [];

    @ViewChild('font_size') private fontSizeElement: ElementRef;
    @ViewChild('filter_font') private filterFontElement: ElementRef;
    @ViewChildren('roundtripDetailHeaderElements') private roundtripDetailHeaderElements: QueryList<ElementRef>;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public route: ActivatedRoute,
        public router: Router,
        public roundtripHelper: SharedHotelHelperService,
        public media: MediaService,
        public title: Title,
        private renderer: Renderer2,
        private filterPipe: IndexRoundtripFilterPipe
    ) {
        super(nova, uiEventHandler, route, router, meta, title);

        const path = window.location.pathname;
        const filter = sessionStorage.getItem('filter:' + path);

        if (filter) {
            const filterData = JSON.parse(filter);

            if (filterData.selectedFilters) {
                this.selectedFilters = filterData.selectedFilters;
            }

            if (filterData.selectedInterests) {
                this.selectedInterests = filterData.selectedInterests;
            }

            if (filterData.selectedStartingPoints) {
                this.selectedStartingPoints = filterData.selectedStartingPoints;
            }
        }
    }

    ngOnInit(): void {
        this.data.contents.forEach(content =>
            this.roundtripHelper.handleTagInputs(content, ['roundtrip_categories', 'interests', 'startingpoint'])
        );

        this.allFilters = setFilters(this.allFilterStrings, this.data.contents, 'roundtrip_categories');
        this.allCountryFilters = setFilters(this.allCountryFilterStrings, this.data.contents, 'interests');

        this.startingPointFilters = setFilters(
            this.startingPointFilters,
            this.data.contents.filter(item => item.startingpoint && item.startingpoint.every(i => Boolean(i))),
            'startingpoint'
        );

        this.filter();
    }

    filter() {
        this.filteredData = this.filterPipe.transform(
            this.data.contents,
            this.selectedFilters,
            this.selectedInterests,
            this.selectedStartingPoints
        );

        // FTI-83 - dont show roundtrips without name
        this.filteredData = this.filteredData.filter(roundtrip => roundtrip.header !== undefined);

        // setRemainingFilters(this.allFilters, this.filteredData, 'roundtrip_categories');
        setRemainingFilters(this.allCountryFilters, this.filteredData, 'interests');

        const path = window.location.pathname;
        sessionStorage.setItem(
            'filter:' + path,
            JSON.stringify({
                selectedFilters: this.selectedFilters,
                selectedInterests: this.selectedInterests,
                selectedStartingPoints: this.selectedStartingPoints
            })
        );
    }

    ngAfterViewInit() {
        if (this.data['font']) {
            this.nova.getFont(this.data['font']).subscribe(fontData => {
                this.renderer.setStyle(this.fontSizeElement.nativeElement, 'font-family', fontData.name);
            });
        }

        if (this.data['filter_font'] && this.filterFontElement) {
            this.nova.getFont(this.data['filter_font']).subscribe(fontData => {
                this.renderer.setStyle(this.filterFontElement.nativeElement, 'font-family', fontData.name);
            });
        }

        if (this.data['roundtrip_detail_header_font']) {
            this.nova.getFont(this.data['roundtrip_detail_header_font']).subscribe(fontData => {
                this.roundtripDetailHeaderElements &&
                    this.roundtripDetailHeaderElements.toArray().map(element => {
                        this.renderer.setStyle(element.nativeElement, 'font-family', fontData.name);
                    });
            });
        }

        if (this.data['roundtrip_detail_font']) {
            this.nova.getFont(this.data['roundtrip_detail_font']).subscribe(fontData => {
                this.contentFontStyle = fontData.name;
            });
        }
    }

    goTo(id) {
        const floor = this.data.contents.filter(content => content._id === id)[0];
        const params = this.route.params['value'];

        this.router.navigate(['/', params['langHandle'], params['issueHandle'], params['pageHandle'], floor.handle], {
            queryParamsHandling: 'preserve'
        });
    }
}
