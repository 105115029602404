/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./nova-element.component";
import * as i2 from "../../_services/nova-api.service";
import * as i3 from "../../_services/uievent-handler.service";
var styles_NovaElementComponent = [];
var RenderType_NovaElementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NovaElementComponent, data: {} });
export { RenderType_NovaElementComponent as RenderType_NovaElementComponent };
export function View_NovaElementComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { elm: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["elm", 1]], null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data[(_co.content_name || _co.name)]; _ck(_v, 1, 0, currVal_0); }); }
export function View_NovaElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "nova-element", [], null, null, null, View_NovaElementComponent_0, RenderType_NovaElementComponent)), i0.ɵdid(1, 1097728, null, 0, i1.NovaElementComponent, [i0.Renderer2, i2.NovaAPIService, i0.ElementRef, i3.UIEventHandlerService], null, null)], null, null); }
var NovaElementComponentNgFactory = i0.ɵccf("nova-element", i1.NovaElementComponent, View_NovaElementComponent_Host_0, { data: "data", name: "name", content_name: "content_name", ignoreSize: "ignoreSize", ignoreFont: "ignoreFont" }, {}, []);
export { NovaElementComponentNgFactory as NovaElementComponentNgFactory };
