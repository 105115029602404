import { AgmCoreModule } from '@agm/core';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ComponentsModule } from 'app/_components/components.module';
import { FilterTravelworldModernPipe } from './templates/travelworld-modern/filter-category-tags.pipe';
import { FormsModule } from '@angular/forms';
import { HotelComponent } from './templates/index-hotel/hotel/hotel.component';
import { HotelDetailPremiumContentComponent } from './templates/hotel-detail-premium/hotel-detail-premium-content/hotel-detail-premium-content.component';
import { HotelDetailStandardContentComponent } from './templates/hotel-detail-standard/hotel-detail-standard-content/hotel-detail-standard-content.component';
import { HotelPricingComponent } from './templates/hotel-detail-standard/hotel-pricing/hotel-pricing.component';
import { IndexHotelFilterPipe } from './templates/index-hotel/filter-tags.pipe';
import { IndexRoundtripFilterPipe } from './templates/index-roundtrip/filter-tags.pipe';
import { IndexTripFilterPipe } from './templates/index-trips/filter-tags.pipe';
import { NgModule } from '@angular/core';
import { PageArticleClassicComponent } from './templates/article-classic/article-classic.component';
import { PageArticleScrollColumnsComponent } from './templates/article-scroll-columns/article-scroll-columns.component';
import { PageArticleScrollComponent } from './templates/article-scroll/article-scroll.component';
import { PageArticleTeaserComponent } from './templates/article-teaser/article-teaser.component';
import { PageBackgroundAcrossLeftComponent } from './templates/background-across-left/background-across-left.component';
import { PageBackgroundAcrossRightComponent } from './templates/background-across-right/background-across-right.component';
import { PageBackgroundCleanComponent } from './templates/background-clean/background-clean.component';
import { PageBlogArticleComponent } from './templates/blog-article/blog-article.component';
import { PageBlogIndexComponent } from './templates/blog-index/blog-index.component';
import { PageBookScrollComponent } from './templates/book-scroll/book-scroll.component';
import { PageBookScrollProductComponent } from './templates/book-scroll-product/book-scroll-product.component';
import { PageBookScrollReverseComponent } from './templates/book-scroll-reverse/book-scroll-reverse.component';
import { PageBookScrollSliderComponent } from './templates/book-scroll-slider/book-scroll-slider.component';
import { PageBookScrollStrikingComponent } from './templates/book-scroll-striking/book-scroll-striking.component';
import { PageComponent } from './page.component';
import { PageCoverAtlasCopcoComponent } from './templates/cover-atlas-copco/cover-atlas-copco.component';
import { PageCoverClassicComponent } from './templates/cover-classic/cover-classic.component';
import { PageCoverClassicContentComponent } from './templates/cover-classic-content/cover-classic-content.component';
import { PageCoverClassicLogoComponent } from './templates/cover-classic-logo/cover-classic-logo.component';
import { PageCoverClassicTextComponent } from './templates/cover-classic-text/cover-classic-text.component';
import { PageCoverTitleComponent } from './templates/cover-title/cover-title.component';
import { PageEasyScrollComponent } from './templates/easy-scroll/easy-scroll.component';
import { PageExternalContentComponent } from 'app/pages/templates/external-content/external-content.component';
import { PageExternalInlineComponent } from './templates/external-inline/external-inline.component';
import { PageFormClassicComponent } from './templates/form-classic/form-classic.component';
import { PageFtiNewsletterComponent } from './templates/fti-newsletter/fti-newsletter.component';
import { PageFtiTravelquizComponent } from './templates/fti-travelquiz/fti-travelquiz.component';
import { PageFtiWeatherfinderComponent } from './templates/fti-weatherfinder/fti-weatherfinder.component';
import { PageGebirgslandeplaetzeDetailComponent } from './templates/gebirgslandeplaetze-detail/gebirgslandeplaetze-detail.component';
import { PageGrowComponent } from './templates/page-grow/page-grow.component';
import { PageHighlightsDerWocheComponent } from './templates/highlights-der-woche/highlights-der-woche.component';
import { PageHotelDetailPremiumComponent } from './templates/hotel-detail-premium/hotel-detail-premium.component';
import { PageHotelDetailStandardComponent } from './templates/hotel-detail-standard/hotel-detail-standard.component';
import { PageImageClassicComponent } from 'app/pages/templates/image-classic/image-classic.component';
import { PageImageGalleryComponent } from './templates/image-gallery/image-gallery.component';
import { PageIndexClassicComponent } from 'app/pages/templates/index-classic/index-classic.component';
import { PageIndexCoverComponent } from './templates/index-cover/index-cover.component';
import { PageIndexFloatComponent } from './templates/index-float/index-float.component';
import { PageIndexGridComponent } from './templates/index-grid/index-grid.component';
import { PageIndexHotelComponent } from './templates/index-hotel/index-hotel.component';
import { PageIndexIssueComponent } from 'app/pages/templates/index-issue/index-issue.component';
import { PageIndexRoundtripComponent } from './templates/index-roundtrip/index-roundtrip.component';
import { PageIndexTransferComponent } from './templates/index-transfer/index-transfer.component';
import { PageIndexTripsComponent } from './templates/index-trips/index-trips.component';
import { PageInterviewClassicComponent } from './templates/interview-classic/interview-classic.component';
import { PageMultipleBackgroundComponent } from 'app/pages/templates/multiple-background/multiple-background.component';
import { PageNotFoundComponent } from '../not-found-component';
import { PagePandoraPage1Component } from './templates/pandora-page-1/pandora-page-1.component';
import { PagePandoraPage2Component } from './templates/pandora-page-2/pandora-page-2.component';
import { PagePandoraPage3Component } from './templates/pandora-page-3/pandora-page-3.component';
import { PagePandoraPage4Component } from './templates/pandora-page-4/pandora-page-4.component';
import { PagePflugfelderContentsComponent } from './templates/pflugfelder-contents/pflugfelder-contents.component';
import { PagePflugfelderIndexComponent } from './templates/pflugfelder-index/pflugfelder-index.component';
import { PageGebirgslandeplaetzeIndexComponent } from './templates/gebirgslandeplaetze-index/gebirgslandeplaetze-index.component';
import { PagePokerComponent } from 'app/pages/templates/poker/poker.component';
import { PagePokerLabelComponent } from 'app/pages/templates/poker-label/poker-label.component';
import { PagePortraitClassicComponent } from './templates/portrait-classic/portrait-classic.component';
import { PagePortraitComponent } from 'app/pages/templates/portrait/portrait.component';
import { PageProductComponent } from './templates/product/product.component';
import { PageProductGalleryComponent } from './templates/product-gallery/product-gallery.component';
import { PageRoundtripStandardComponent } from './templates/roundtrip-standard/roundtrip-standard.component';
import { PageSingleBackgroundComponent } from './templates/single-background/single-background.component';
import { PageSwitchComponent } from 'app/pages/templates/switch/switch.component';
import { PageTimelineClassicComponent } from './templates/timeline-classic/timeline-classic.component';
import { PageTimelineLeftComponent } from './templates/timeline-left/timeline-left.component';
import { PageTimelineRightComponent } from './templates/timeline-right/timeline-right.component';
import { PageTopicTeaserComponent } from './templates/topic-teaser/topic-teaser.component';
import { PageTopicTeaserReverseComponent } from './templates/topic-teaser-reverse/topic-teaser-reverse.component';
import { PageTransferStandardComponent } from './templates/transfer-standard/transfer-standard.component';
import { PageTravelworldComponent } from './templates/travelworld/travelworld.component';
import { PageTravelworldModernComponent } from './templates/travelworld-modern/travelworld-modern.component';
import { PageTripDetailStandardComponent } from './templates/trip-detail-standard/trip-detail-standard.component';
import { PageVideoCinemaComponent } from './templates/video-cinema/video-cinema.component';
import { PageVideoClassicComponent } from './templates/video-classic/video-classic.component';
import { RoundtripComponent } from './templates/index-roundtrip/roundtrip/roundtrip.component';
import { RoundtripStandardContentComponent } from './templates/roundtrip-standard/roundtrip-standard-content/roundtrip-standard-content.component';
import { RouterModule } from '@angular/router';
import { SelectedBlockComponent } from './templates/hotel-detail-premium/selected-block/selected-block.component';
import { ServerArticleScrollComponent } from 'app/pages/templates_server/article-scroll/article-scroll.component';
import { ServerImageClassicComponent } from './templates_server/image-classic/image-classic.component';
import { ServerIndexClassicComponent } from 'app/pages/templates_server/index-classic/index-classic.component';
import { ServerIndexFloatComponent } from './templates_server/index-float/index-float.component';
import { ServerIndexGridComponent } from './templates_server/index-grid/index-grid.component';
import { ServerMultipleBackgroundComponent } from 'app/pages/templates_server/multiple-background/multiple-background.component';
import { ServerPokerComponent } from 'app/pages/templates_server/poker/poker.component';
import { ServerVideoCinemaComponent } from './templates_server/video-cinema/video-cinema.component';
import { ServerVideoClassicComponent } from 'app/pages/templates_server/video-classic/video-classic.component';
import { SharedModule } from 'app/shared.module';
import { TransferComponent } from './templates/index-transfer/transfer/transfer.component';
import { TravelworldSliderComponent } from './templates/travelworld-modern/travelworld-slider/travelworld-slider.component';
import { TripComponent } from './templates/index-trips/trips/trips.component';
import { TripDetailStandardContentComponent } from './templates/trip-detail-standard/trip-detail-standard-content/trip-detail-standard-content.component';
import { TripPricingComponent } from './templates/trip-detail-standard/trip-pricing/trip-pricing.component';
import { TurncatePipe } from './templates/index-trips/turncate.pipe';

@NgModule({
    imports: [SharedModule, ComponentsModule, AgmCoreModule, AngularMultiSelectModule, FormsModule, RouterModule],
    entryComponents: [
        PageArticleClassicComponent,
        PageArticleScrollColumnsComponent,
        PageArticleScrollComponent,
        PageArticleTeaserComponent,
        PageBackgroundAcrossLeftComponent,
        PageBackgroundAcrossRightComponent,
        PageBackgroundCleanComponent,
        PageBlogArticleComponent,
        PageBlogIndexComponent,
        PageBookScrollComponent,
        PageBookScrollProductComponent,
        PageBookScrollReverseComponent,
        PageBookScrollSliderComponent,
        PageBookScrollStrikingComponent,
        PageCoverAtlasCopcoComponent,
        PageCoverClassicComponent,
        PageCoverClassicContentComponent,
        PageCoverClassicLogoComponent,
        PageCoverClassicTextComponent,
        PageCoverTitleComponent,
        PageEasyScrollComponent,
        PageExternalContentComponent,
        PageExternalInlineComponent,
        PageFormClassicComponent,
        PageFtiNewsletterComponent,
        PageFtiTravelquizComponent,
        PageFtiWeatherfinderComponent,
        PageGebirgslandeplaetzeDetailComponent,
        PageGrowComponent,
        PageHighlightsDerWocheComponent,
        PageHotelDetailPremiumComponent,
        PageHotelDetailStandardComponent,
        PageImageClassicComponent,
        PageImageGalleryComponent,
        PageIndexClassicComponent,
        PageIndexCoverComponent,
        PageIndexFloatComponent,
        PageIndexGridComponent,
        PageIndexHotelComponent,
        PageIndexIssueComponent,
        PageIndexRoundtripComponent,
        PageIndexTransferComponent,
        PageIndexTripsComponent,
        PageInterviewClassicComponent,
        PageMultipleBackgroundComponent,
        PageNotFoundComponent,
        PagePandoraPage1Component,
        PagePandoraPage2Component,
        PagePandoraPage3Component,
        PagePandoraPage4Component,
        PagePflugfelderContentsComponent,
        PagePflugfelderIndexComponent,
        PageGebirgslandeplaetzeIndexComponent,
        PagePokerComponent,
        PagePokerLabelComponent,
        PagePortraitClassicComponent,
        PagePortraitComponent,
        PageProductComponent,
        PageProductGalleryComponent,
        PageRoundtripStandardComponent,
        PageSingleBackgroundComponent,
        PageSwitchComponent,
        PageTimelineClassicComponent,
        PageTimelineLeftComponent,
        PageTimelineRightComponent,
        PageTopicTeaserComponent,
        PageTopicTeaserReverseComponent,
        PageTransferStandardComponent,
        PageTravelworldComponent,
        PageTravelworldModernComponent,
        PageTripDetailStandardComponent,
        PageVideoCinemaComponent,
        PageVideoClassicComponent,
        RoundtripStandardContentComponent,
        ServerArticleScrollComponent,
        ServerImageClassicComponent,
        ServerIndexClassicComponent,
        ServerIndexFloatComponent,
        ServerIndexGridComponent,
        ServerMultipleBackgroundComponent,
        ServerPokerComponent,
        ServerVideoCinemaComponent,
        ServerVideoClassicComponent,
        TripDetailStandardContentComponent,
        TripPricingComponent
    ],
    exports: [PageComponent],
    declarations: [
        FilterTravelworldModernPipe,
        HotelComponent,
        HotelDetailPremiumContentComponent,
        HotelDetailStandardContentComponent,
        HotelPricingComponent,
        IndexHotelFilterPipe,
        IndexRoundtripFilterPipe,
        IndexTripFilterPipe,
        PageArticleClassicComponent,
        PageArticleScrollColumnsComponent,
        PageArticleScrollComponent,
        PageArticleTeaserComponent,
        PageBackgroundAcrossLeftComponent,
        PageBackgroundAcrossRightComponent,
        PageBackgroundCleanComponent,
        PageBlogArticleComponent,
        PageBlogIndexComponent,
        PageBookScrollComponent,
        PageBookScrollProductComponent,
        PageBookScrollReverseComponent,
        PageBookScrollSliderComponent,
        PageBookScrollStrikingComponent,
        PageComponent,
        PageCoverAtlasCopcoComponent,
        PageCoverClassicComponent,
        PageCoverClassicContentComponent,
        PageCoverClassicLogoComponent,
        PageCoverClassicTextComponent,
        PageCoverTitleComponent,
        PageEasyScrollComponent,
        PageExternalContentComponent,
        PageExternalInlineComponent,
        PageFormClassicComponent,
        PageFtiNewsletterComponent,
        PageFtiTravelquizComponent,
        PageFtiWeatherfinderComponent,
        PageGebirgslandeplaetzeDetailComponent,
        PageGrowComponent,
        PageHighlightsDerWocheComponent,
        PageHotelDetailPremiumComponent,
        PageHotelDetailStandardComponent,
        PageImageClassicComponent,
        PageImageGalleryComponent,
        PageIndexClassicComponent,
        PageIndexCoverComponent,
        PageIndexFloatComponent,
        PageIndexGridComponent,
        PageIndexHotelComponent,
        PageIndexIssueComponent,
        PageIndexRoundtripComponent,
        PageIndexTransferComponent,
        PageIndexTripsComponent,
        PageInterviewClassicComponent,
        PageMultipleBackgroundComponent,
        PagePandoraPage1Component,
        PagePandoraPage2Component,
        PagePandoraPage3Component,
        PagePandoraPage4Component,
        PagePflugfelderContentsComponent,
        PagePflugfelderIndexComponent,
        PageGebirgslandeplaetzeIndexComponent,
        PagePokerComponent,
        PagePokerLabelComponent,
        PagePortraitClassicComponent,
        PagePortraitComponent,
        PageProductComponent,
        PageProductGalleryComponent,
        PageRoundtripStandardComponent,
        PageSingleBackgroundComponent,
        PageSwitchComponent,
        PageTimelineClassicComponent,
        PageTimelineLeftComponent,
        PageTimelineRightComponent,
        PageTopicTeaserComponent,
        PageTopicTeaserReverseComponent,
        PageTransferStandardComponent,
        PageTravelworldComponent,
        PageTravelworldModernComponent,
        PageTripDetailStandardComponent,
        PageVideoCinemaComponent,
        PageVideoClassicComponent,
        RoundtripComponent,
        RoundtripStandardContentComponent,
        SelectedBlockComponent,
        ServerArticleScrollComponent,
        ServerImageClassicComponent,
        ServerIndexClassicComponent,
        ServerIndexFloatComponent,
        ServerIndexGridComponent,
        ServerMultipleBackgroundComponent,
        ServerPokerComponent,
        ServerVideoCinemaComponent,
        ServerVideoClassicComponent,
        TransferComponent,
        TravelworldSliderComponent,
        TripComponent,
        TripDetailStandardContentComponent,
        TripPricingComponent,
        TurncatePipe
    ]
})
export class PagesModule {}
