import { Pipe, PipeTransform } from '@angular/core';
import { MultiSelectItem } from './MultiSelectItem';

@Pipe({
    name: 'indexTripFilter',
    pure: false
})
export class IndexTripFilterPipe implements PipeTransform {
    transform(contents: any, selectedFilters: MultiSelectItem[], selectedCountries: MultiSelectItem[]): any {
        if (!contents) {
            return contents;
        }

        let filterSelected = content => {
            return true;
        };

        if (selectedFilters.length) {
            filterSelected = content => {
                return selectedFilters
                    .map(item => item.id)
                    .every(fil => content.hotel_labels && content.hotel_labels.indexOf(fil) > -1)
                    ? content
                    : false;
            };
        }

        let filterCountries = content => {
            return true;
        };
        if (selectedCountries.length) {
            filterCountries = content => {
                return selectedCountries
                    .map(item => item.id)
                    .some(fil => content.country_filters && content.country_filters.indexOf(fil) > -1)
                    ? content
                    : null;
            };
        }

        return contents.filter(content => filterSelected(content) && filterCountries(content));
    }
}
