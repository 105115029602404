<div id="page-{{ id }}" class="page page-index-classic">
  <nova-bg [data]="data" [forceVideo]="true"></nova-bg>
  <nova-scrollbar-holder>
    <div class="scroller">
      <div class="bg clear" #elm [class.hideImg]="data.showImages !== 'yes'">
        <h1 *ngIf="data.header">
          <nova-element [data]="data" name="header"></nova-element>
        </h1>
        <h1 *ngIf="!data.header">{{ uiEventHandler.CONTENT_TABLE }}</h1>
        <h2 *ngIf="data.subHeader">
          <nova-element [data]="data" name="subHeader"></nova-element>
        </h2>
        <div class="item" *ngFor="let value of pages;let key = index;" tabIndex="-1">
          <img (click)="goToPage(value.handle)" *ngIf="value.thumbnail && !nova.isThumbLoading(value.thumbnail)"
            [src]="value.thumbnail.url" />
          <img (click)="goToPage(value.handle)" *ngIf="!value.thumbnail || nova.isThumbLoading(value.thumbnail)"
            [src]="'assets/img/templates/pages/' + value.templateName+'.svg'" />
          <div class="right-con">
            <b (click)="goToPage(value.handle)" class="title"
              [style.font-family]="font">{{ uiEventHandler.getContentTableEntryName(value) }}</b>
            <span [style.font-family]="font" (click)="goToPage(value.handle, floor.handle)"
              *ngFor="let floor of value.contents; let key2 = index;" [hidden]="floor.showInIndex == 'no'">
              <b>&bull;
                <!-- {{ getType(floor.templateName) | async }} // -->
              </b>
              {{ floor.index_title || floor.header || floor.name }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </nova-scrollbar-holder>
</div>
