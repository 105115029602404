import * as tslib_1 from "tslib";
import { ElementRef, OnInit, QueryList, Renderer2 } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from 'app/_decorators/template.decorator';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import { SharedHotelHelperService } from '../hotel-detail-standard/shared-hotel-helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IndexTripFilterPipe } from './filter-tags.pipe';
export var setRemainingFilters = function (filterRef, contents, key) {
    var availableLabels = [];
    contents.forEach(function (content) {
        var contentLabels = content[key] || [];
        availableLabels = tslib_1.__spread(availableLabels, contentLabels);
    });
    availableLabels = availableLabels.filter(function (item, pos) { return availableLabels.indexOf(item) === pos; });
    // add all labels
    availableLabels.map(function (x) {
        if (!filterRef.find(function (_a) {
            var id = _a.id;
            return x === id;
        })) {
            filterRef.push({ id: x, itemName: x });
        }
    });
    // remove unused labels
    for (var i = filterRef.length - 1; i >= 0; i--) {
        var id = filterRef[i].id;
        if (availableLabels.indexOf(id) === -1) {
            filterRef.splice(i, 1);
        }
    }
    // sort labels
    filterRef.sort(function (a, b) { return a.id.toLowerCase().localeCompare(b.id.toLowerCase()); });
};
export var setFilters = function (filterStrings, contents, key) {
    contents.forEach(function (content) { return (filterStrings = tslib_1.__spread(filterStrings, (content[key] ? content[key] : []))); });
    return filterStrings
        .filter(function (item, pos) { return item && filterStrings.indexOf(item) === pos; })
        .sort(function (a, b) { return a.toLowerCase().localeCompare(b.toLowerCase()); })
        .map(function (item) { return ({ id: item, itemName: item }); });
};
var PageIndexTripsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageIndexTripsComponent, _super);
    function PageIndexTripsComponent(nova, uiEventHandler, sanitizer, meta, route, router, tripsHelper, media, title, renderer, filterPipe) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.sanitizer = sanitizer;
        _this.meta = meta;
        _this.route = route;
        _this.router = router;
        _this.tripsHelper = tripsHelper;
        _this.media = media;
        _this.title = title;
        _this.renderer = renderer;
        _this.filterPipe = filterPipe;
        _this.filteredData = [];
        _this.allFilters = [];
        _this.allCountryFilters = [];
        _this.selectedFilters = [];
        _this.selectedCountryFilters = [];
        _this.dropdownSettings = {
            singleSelection: false,
            text: 'Nach Interessen filtern',
            enableCheckAll: false,
            showCheckbox: true,
            enableSearchFilter: false,
            classes: 'nova-multiselect'
        };
        _this.countryDropdownSettings = {
            singleSelection: false,
            text: 'Nach Regionen filtern',
            enableCheckAll: false,
            showCheckbox: true,
            enableSearchFilter: false,
            classes: 'nova-multiselect'
        };
        _this.contentFontStyle = '';
        _this.allFilterStrings = [];
        _this.allCountryFilterStrings = [];
        var path = window.location.pathname;
        var filter = sessionStorage.getItem('filter:' + path);
        if (filter) {
            var filterData = JSON.parse(filter);
            if (filterData.selectedFilters) {
                _this.selectedFilters = filterData.selectedFilters;
            }
            if (filterData.selectedCountryFilters) {
                _this.selectedCountryFilters = filterData.selectedCountryFilters;
            }
        }
        return _this;
    }
    PageIndexTripsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.data.contents.forEach(function (content) { return _this.tripsHelper.handleTagInputs(content); });
        this.allFilters = setFilters(this.allFilterStrings, this.data.contents, 'hotel_labels');
        this.allCountryFilters = setFilters(this.allCountryFilterStrings, this.data.contents, 'country_filters');
        this.filter();
    };
    PageIndexTripsComponent.prototype.filter = function () {
        this.filteredData = this.filterPipe.transform(this.data.contents, this.selectedFilters, this.selectedCountryFilters);
        // FTI-83 - dont show trips without name
        this.filteredData = this.filteredData.filter(function (trip) { return trip.header !== undefined; });
        setRemainingFilters(this.allFilters, this.filteredData, 'hotel_labels');
        var path = window.location.pathname;
        sessionStorage.setItem('filter:' + path, JSON.stringify({
            selectedFilters: this.selectedFilters,
            selectedCountryFilters: this.selectedCountryFilters
        }));
    };
    PageIndexTripsComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.data['font']) {
            this.nova.getFont(this.data['font']).subscribe(function (fontData) {
                _this.renderer.setStyle(_this.fontSizeElement.nativeElement, 'font-family', fontData.name);
            });
        }
        if (this.data['filter_font'] && this.filterFontElement) {
            this.nova.getFont(this.data['filter_font']).subscribe(function (fontData) {
                _this.renderer.setStyle(_this.filterFontElement.nativeElement, 'font-family', fontData.name);
            });
        }
        if (this.data['trip_detail_header_font']) {
            this.nova.getFont(this.data['trip_detail_header_font']).subscribe(function (fontData) {
                _this.TripDetailHeaderElements &&
                    _this.TripDetailHeaderElements.toArray().map(function (element) {
                        _this.renderer.setStyle(element.nativeElement, 'font-family', fontData.name);
                    });
            });
        }
        if (this.data['trip_detail_font']) {
            this.nova.getFont(this.data['trip_detail_font']).subscribe(function (fontData) {
                _this.contentFontStyle = fontData.name;
            });
        }
    };
    PageIndexTripsComponent.prototype.goTo = function (id) {
        var floor = this.data.contents.filter(function (content) { return content._id === id; })[0];
        var params = this.route.params['value'];
        this.router.navigate(['/', params['langHandle'], params['issueHandle'], params['pageHandle'], floor.handle], {
            queryParamsHandling: 'preserve'
        });
    };
    PageIndexTripsComponent = tslib_1.__decorate([
        NovaTemplate('PageIndexTripsComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            DomSanitizer,
            Meta,
            ActivatedRoute,
            Router,
            SharedHotelHelperService,
            MediaService,
            Title,
            Renderer2,
            IndexTripFilterPipe])
    ], PageIndexTripsComponent);
    return PageIndexTripsComponent;
}(Page));
export { PageIndexTripsComponent };
