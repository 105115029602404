import * as tslib_1 from "tslib";
import { Page } from './../../page';
import { DomSanitizer } from '@angular/platform-browser';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MediaService } from 'app/_services/media.service';
import { ActivatedRoute, Router } from '@angular/router';
var ServerImageClassicComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ServerImageClassicComponent, _super);
    function ServerImageClassicComponent(nova, mediaService, sanitizer, renderer, uiEventHandler, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.mediaService = mediaService;
        _this.sanitizer = sanitizer;
        _this.renderer = renderer;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        return _this;
    }
    ServerImageClassicComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.addOgTags();
        this.len = this.data.medias.length;
        this.mediaService.loadMedia(this.data.medias).then(function (data) {
            _this.media = data;
        });
    };
    ServerImageClassicComponent = tslib_1.__decorate([
        NovaTemplate('ServerImageClassicComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            MediaService,
            DomSanitizer,
            Renderer2,
            UIEventHandlerService,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], ServerImageClassicComponent);
    return ServerImageClassicComponent;
}(Page));
export { ServerImageClassicComponent };
