<div id="page-{{ id }}" class="page page-poker">
    <div class="mobile-scroller-handler scrollbar-holder" [ngClass]="{single: data.medias.length == 1, half: data.medias.length == 2 && !teaser}">
      <div class="scroller" [ngClass]="{hasContent: (data.links && data.links.length > 0) || data.text || data.header || data.subHeader || data.label}">
        <div #teaser class="bg teaser animated fadeInDown scrollbar-holder" [ngStyle]="{'background-color':getColor(data['box_color'], true)}" *ngIf="teaser">
          <div class="scroller">
            <nova-title [data]="data"></nova-title>
            <nova-text-element class="con" [data]="data.text"></nova-text-element>
            <nova-content-links class="content-links" *ngIf="data.links && data.links.length > 0" [floors]="data.links"></nova-content-links>
          </div>
        </div>
        <div class="poker" [ngClass]="class">
          <div *ngFor="let val of media;let key = index;" class="slide" [ngStyle]="{'background-image':getEntryStyle(val)}">
            <span *ngIf="val.copyright" class="img-copyright">&copy; {{ val.copyright }}<span *ngIf="val.copyrightUrl"> - {{ val.copyrightUrl }}</span></span>
            <div class="bg-small animated fadeInLeft easeOutQuad" [ngStyle]="{'background-color':getColor(val.org['box_color'], true)}" *ngIf="val.org.header || val.org.text">
              <span class="icon-cancel"></span>
              <nova-scrollbar-holder>
                <div class="scroller">
                  <nova-element *ngIf="val.org.header" [data]="val.org" name="header"></nova-element>
                  <nova-text-element [data]="val.org.text"></nova-text-element>
                </div>
              </nova-scrollbar-holder>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
