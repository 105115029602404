import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
var NovaContactFormFieldComponent = /** @class */ (function () {
    function NovaContactFormFieldComponent() {
        this.minDate = new Date();
    }
    NovaContactFormFieldComponent.prototype.ngOnInit = function () {
        var field = this.field;
        if (field && field.disabled === 'true') {
            this.form.controls[field.id].disable();
        }
    };
    Object.defineProperty(NovaContactFormFieldComponent.prototype, "isValid", {
        get: function () {
            var field = this.form.controls[this.field.id];
            if (field.disabled || !this.formSubmitted) {
                return;
            }
            if (!field.valid) {
                if (field.hasError('pattern')) {
                    return this.field.label + " ist keine g\u00FCltige E-Mail Adresse.";
                }
                if (field.hasError('matDatepickerMin')) {
                    return "Die " + this.field.label + " darf nicht in der Vergangenheit liegen.";
                }
                return this.field.label + " ist ein Pflichtfeld.";
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NovaContactFormFieldComponent.prototype, "label", {
        get: function () {
            return this.field.required === 'true' ? "* " + this.field.label : this.field.label;
        },
        enumerable: true,
        configurable: true
    });
    return NovaContactFormFieldComponent;
}());
export { NovaContactFormFieldComponent };
