import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Floor } from '../../floor';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MediaService } from 'app/_services/media.service';
var PageInterviewClassicComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageInterviewClassicComponent, _super);
    function PageInterviewClassicComponent(nova, media, uiEventHandler, route, router, meta, title) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.media = media;
        _this.meta = meta;
        _this.title = title;
        _this.loaded = false;
        _this.i = 0;
        return _this;
    }
    PageInterviewClassicComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.i = 0;
        this.data.blocks.forEach(function (block) {
            if (block.media) {
                _this.media.getMediaUrl(block.media, 'source').subscribe(function (data) {
                    block.mediaUrl = data;
                    _this.load();
                });
            }
            else {
                _this.load();
            }
        });
    };
    PageInterviewClassicComponent.prototype.load = function () {
        this.i++;
        if (this.i === this.data.blocks.length) {
            this.loaded = true;
        }
    };
    PageInterviewClassicComponent.prototype.getFontSize = function () {
        return this.uiEventHandler.fontSize + 'em';
    };
    PageInterviewClassicComponent = tslib_1.__decorate([
        NovaTemplate('PageInterviewClassicComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            MediaService,
            UIEventHandlerService,
            ActivatedRoute,
            Router,
            Meta,
            Title])
    ], PageInterviewClassicComponent);
    return PageInterviewClassicComponent;
}(Floor));
export { PageInterviewClassicComponent };
