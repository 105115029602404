import { Location } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Page } from 'app/pages/page';
import { NovaTemplate } from 'app/_decorators/template.decorator';
import { BlogService } from 'app/_services/blog.service';
import { MediaService } from 'app/_services/media.service';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { fadeAnimation } from '../travelworld-modern/travelworld-slider/fadeIn.animation';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './pflugfelder-index.component.html',
    styleUrls: ['./pflugfelder-index.component.scss'],
    animations: [fadeAnimation]
})
@NovaTemplate('PagePflugfelderIndexComponent')
export class PagePflugfelderIndexComponent extends Page implements OnInit, AfterViewInit {
    public posts = [];
    public filteredPosts = [];
    public blogFonts = [];
    public blogNavigationIcon;
    public categoriesHaveIcons = false;
    public isLoading = true;
    public categoriesVisible = false;
    public searchValue = '';
    public regions = {};

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public mediaService: MediaService,
        public location: Location,
        public blogService: BlogService,
        public router: Router,
        public route: ActivatedRoute
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    async ngOnInit() {
        const regions = {};

        this.posts = this.data.contents.sort((a, b) => {
            if (a.name > b.name) return 1;
            if (b.name > a.name) return -1;

            return 0;
        });

        this.posts = this.data.contents.filter(content => content.showInOverview !== false).map(content => {
            if (!content.region) return;
            if (!regions[content.region]) regions[content.region] = [];

            regions[content.region].push({
                ...content,
                sortedDistricts: this.renderDistricts(content.relatedDistricts, content.districts)
            });
        });

        this.regions = Object.entries(regions).sort((a, b) => {
            if (a[0] > b[0]) return 1;
            if (b[0] > a[0]) return -1;

            return 0;
        });

        this.isLoading = false;
    }

    async filter() {
        const regions = {};
        this.posts = this.data.contents
            .filter(content => content.showInOverview !== false)
            .filter(
                content =>
                    content.districts.toLowerCase().includes(this.searchValue.toLowerCase()) ||
                    content.header.toLowerCase().includes(this.searchValue.toLowerCase()) ||
                    !content.relatedDistricts
            )
            .map(content => {
                if (!content.region) return;
                if (!regions[content.region]) regions[content.region] = [];
                regions[content.region].push({
                    ...content,
                    sortedDistricts: this.renderDistricts(content.relatedDistricts, content.districts)
                });
            });

        this.regions = Object.entries(regions).sort((a, b) => {
            if (a[0] > b[0]) return 1;
            if (b[0] > a[0]) return -1;

            return 0;
        });
    }

    ngAfterViewInit() {
        this.data.contents.length > 0 && $('.next-page')[0].style.setProperty('opacity', '1', 'important');
    }

    onItemSelect() {
        this.filter();
    }
    OnItemDeSelect() {
        this.filter();
    }
    onSelectAll() {
        this.filter();
    }
    onDeSelectAll() {
        this.filter();
    }

    open(id) {
        $(`#${id}`).toggleClass('open');
    }

    stop(event: Event) {
        event.stopPropagation();
    }

    renderDistricts(relatedDistricts, districts) {
        const arr = [...relatedDistricts];

        if (districts.length > 0) {
            districts.split(',').map(district => {
                arr.push({ name: district.trim() });
            });
        }

        const sortedDistricts = arr.filter(item => item.name).sort((a, b) => {
            if (a.name > b.name) return 1;
            if (b.name > a.name) return -1;

            return 0;
        });

        return sortedDistricts;
    }
}
