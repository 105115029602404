import * as tslib_1 from "tslib";
import { AfterViewChecked } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { NovaAPIService } from 'app/_services/nova-api.service';
import * as _ from 'underscore';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { TranslateService } from '@ngx-translate/core';
import { TransferState, makeStateKey, Meta, Title } from '@angular/platform-browser';
import { SSRService } from 'app/_services/ssr.service';
import { InjectService } from 'app/_services/inject.service';
import { MagazineService } from 'app/_services/magazine.service';
import { MediaService } from 'app/_services/media.service';
import { ChangeDetectorRef } from '@angular/core';
import { PresentationService } from 'app/_services/presentation.service';
import { NewsletterService } from 'app/_services/newsletter.service';
import { LogoAndFaviconService } from 'app/_services/logoAndFavicon.service';
import { skip } from 'rxjs/operators';
import { NovaLondonOverlayComponent } from 'app/_components/nova-london-overlay/nova-london-overlay.component';
import { NovaBudapestOverlayComponent } from 'app/_components/nova-budapest-overlay/nova-budapest-overlay.component';
import { NovaFtiOverlayComponent } from 'app/_components/fti/nova-fti-overlay/nova-fti-overlay.component';
import { CookieService as CustomCookieService } from './_services/cookie.service';
import { CookieService } from 'ngx-cookie-service';
var LANG_KEY = makeStateKey('lang');
function isValidDeepLinkParam(param) {
    return param === 'imprint' || param === 'privacy';
}
var AppComponent = /** @class */ (function () {
    function AppComponent(nova, cdr, media, magazineService, ssr, injectService, translate, uiEventHandler, router, presentation, newsletter, LogoAndFavicon, metaService, titleService, document, customCookieService, cookieService, state) {
        var _this = this;
        this.nova = nova;
        this.cdr = cdr;
        this.media = media;
        this.magazineService = magazineService;
        this.ssr = ssr;
        this.injectService = injectService;
        this.translate = translate;
        this.uiEventHandler = uiEventHandler;
        this.router = router;
        this.presentation = presentation;
        this.newsletter = newsletter;
        this.LogoAndFavicon = LogoAndFavicon;
        this.metaService = metaService;
        this.titleService = titleService;
        this.document = document;
        this.customCookieService = customCookieService;
        this.cookieService = cookieService;
        this.state = state;
        this.pageIndex = 1;
        this.langs = [];
        this.showMagazine = false;
        this.clickWait = false;
        this.wait = false;
        this.showPDF = false;
        this.showPrint = false;
        var defaultLang = this.state.get(LANG_KEY, null);
        var presentationPagesIndex = 0;
        if (!defaultLang) {
            defaultLang = this.nova.findLang();
        }
        this.translate.setDefaultLang(defaultLang);
        this.translate.use(defaultLang);
        this.translate.get('CONTENT_TABLE').subscribe(function (res) {
            _this.uiEventHandler.CONTENT_TABLE = res;
        });
        this.magazineService.getMagazine().subscribe(function (magazine) {
            if (!_this.ssr.isBrowser) {
                return;
            }
            if (magazine.notifications && magazine.notifications.enabled) {
                _this.loadCleverPushIntegration(magazine);
            }
        });
        if (this.ssr.isBrowser && typeof window !== 'undefined') {
            this.injectService.injectPlausibleProvider();
        }
        this.router.events.subscribe(function (event) {
            if (event instanceof RoutesRecognized && !_this.loaded) {
                var eventParams_1 = event.state.root.firstChild.params;
                _this.magazineService.getIssues().subscribe(function (data) {
                    var params = eventParams_1;
                    if (router.routerState.root.firstChild && router.routerState.root.firstChild['params']) {
                        params = router.routerState.root.firstChild.params['value'];
                    }
                    _this.nova.issuesOrg = data.issues;
                    _this.magazine = data.magazine;
                    _this.navStyle = _this.magazine.navigation;
                    _this.magazineService.issuesData = data.issuesData;
                    _this.nova.getFont(_this.magazine.navigation.font).subscribe(function (fontData) {
                        _this.font = fontData.name;
                    });
                    if (params['issueHandle']) {
                        _this.showMagazine = true;
                    }
                    else {
                        _this.showMagazine = false;
                    }
                    _this.checkForLanguageUpdate(Object.assign({}, params));
                    _this.loaded = true;
                    if (_this.magazine.preloaderLogo) {
                        _this.preloaderLogo = _this.magazine.preloaderLogo.url;
                    }
                    else if (_this.magazine.logo) {
                        _this.preloaderLogo = _this.magazine.logo.url;
                    }
                    _this.mobileNavStyle();
                    _this.LogoAndFavicon.setFaviconAndLogo();
                    if (_this.ssr.isBrowser && _this.loaded && _this.showMagazine) {
                        _this.nova.getPageData().subscribe(function (data) {
                            if (data['data']['pdf_version']) {
                                _this.showPDF = true;
                            }
                            else {
                                _this.showPDF = false;
                            }
                            if (_this.nova.issue && _this.nova.issue['showPrint'] === 'no') {
                                _this.showPrint = false;
                            }
                            else {
                                _this.showPrint = true;
                            }
                        });
                    }
                    if (_this.magazine.presentation) {
                        _this.presentation.load(_this.magazine.presentation);
                    }
                    if (_this.presentation.enabled) {
                        _this.presentation.nextPage$.pipe(skip(1)).subscribe(function () {
                            var presentationPages = _this.nova.issue.presentationPages;
                            if (presentationPages) {
                                presentationPagesIndex = _this.handlePresentationPages(presentationPagesIndex, presentationPages);
                            }
                            else {
                                _this.autoNavigate(_this.pageIndex);
                            }
                        });
                    }
                    if (_this.ssr.isBrowser && typeof window !== 'undefined') {
                        _this.handleCookies(_this.magazine);
                    }
                }, function (err) {
                    _this.loaded = true;
                    if (err.status === 451) {
                        _this.router.navigate(['/errorDisabled'], { queryParamsHandling: 'preserve' });
                        return;
                    }
                    if (err.status === 401 || (err instanceof Error && err.message === 'Unauthorized')) {
                        _this.router.navigate(['/login'], {
                            queryParamsHandling: 'preserve'
                        });
                        return;
                    }
                    if (err.status === 403 || (err instanceof Error && err.message === 'Forbidden')) {
                        _this.router.navigate(['/login'], {
                            queryParamsHandling: 'preserve'
                        });
                        return;
                    }
                    _this.router.navigate(['/error'], { queryParamsHandling: 'preserve' });
                });
            }
            if (event instanceof NavigationEnd) {
                _this.magazineService.getSEOData(event.url).subscribe(function (data) {
                    if (_this.ssr.isBrowser) {
                        _this.renderSeoData(data);
                    }
                });
                var params_1 = router.routerState.root.firstChild.params['value'];
                if (params_1['issueHandle']) {
                    _this.showMagazine = true;
                    if (params_1['contentHandle']) {
                        _this.uiEventHandler.showNavArrows = false;
                    }
                    else {
                        _this.uiEventHandler.showNavArrows = true;
                    }
                    _this.uiEventHandler.showMenu = true;
                    if (!params_1['pageHandle']) {
                        _this.pageIndex = 1;
                    }
                }
                else {
                    _this.uiEventHandler.showNavArrows = false;
                    _this.uiEventHandler.showMenu = false;
                    _this.showMagazine = false;
                }
                _this.pageIndex = _this.getCurrentRouteId(router.routerState.root.firstChild);
                if (_this.ssr.isBrowser && _this.loaded && _this.showMagazine) {
                    _this.nova.getPageData().subscribe(function (data) {
                        if (params_1['contentHandle']) {
                            if (data.data.templateName === 'image-gallery') {
                                _this.uiEventHandler.showNavArrows = true;
                            }
                            else {
                                _this.uiEventHandler.showNavArrows = false;
                            }
                        }
                        else {
                            _this.uiEventHandler.showNavArrows = true;
                        }
                        if (data['data']['pdf_version']) {
                            _this.showPDF = true;
                        }
                        else {
                            _this.showPDF = false;
                        }
                        if (_this.nova.issue && _this.nova.issue['showPrint'] === 'no') {
                            _this.showPrint = false;
                        }
                        else {
                            _this.showPrint = true;
                        }
                    });
                    _this.LogoAndFavicon.setFaviconAndLogo();
                }
            }
        });
        this.uiEventHandler.screenshotEvent$.subscribe(function () {
            if (_this.nova.isScreenShot) {
                _this.nova.preloadPageMedia().then(function () {
                    $('body').addClass('readyForScreenshot');
                });
            }
        });
        this.uiEventHandler.resizeEvent$.subscribe(function () {
            _this.nova.media.getMediaVersion();
        });
    }
    AppComponent.prototype.ngAfterViewChecked = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var params, pageParam;
            return tslib_1.__generator(this, function (_a) {
                params = new URLSearchParams(this.document.location.search.substring(1));
                pageParam = params.get('page');
                if (this.nova.issue && pageParam && isValidDeepLinkParam(pageParam)) {
                    this.deepLink = pageParam;
                    if (this.ftiOverlay) {
                        this.ftiOverlay.visible = true;
                    }
                    if (this.budapestOverlay) {
                        this.budapestOverlay.visible = true;
                    }
                    if (this.londonOverlay) {
                        this.londonOverlay.visible = true;
                    }
                }
                this.ssr.isBrowser &&
                    typeof window !== 'undefined' &&
                    this.magazine &&
                    this.customCookieService.mapUserCentricsCustomServices(this.magazine.cookieConsent);
                return [2 /*return*/];
            });
        });
    };
    AppComponent.prototype.handleCookies = function (magazine) {
        var e_1, _a, e_2, _b;
        var consentCookie = this.uiEventHandler.getCookie('CookieConsent');
        if (magazine.cookieConsent.type === 'none') {
            return;
        }
        if ((consentCookie && JSON.parse(consentCookie).statistics) || magazine.cookieConsent.type === 'usercentrics') {
            this.injectService.injectAnalyticsProvider(magazine, this.nova.lang);
            if (!this.nova.isScreenShot) {
                this.customCookieService.init(magazine.language, magazine.cookieConsent, magazine.trackingProvider);
            }
        }
        else {
            var cookies = this.cookieService.getAll();
            if (!cookies) {
                return;
            }
            try {
                for (var _c = tslib_1.__values(Object.keys(cookies)), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var cookie = _d.value;
                    if (cookie.startsWith('_g')) {
                        var novamagDomain = document.location.hostname.includes('localhost')
                            ? "localhost"
                            : document.location.hostname.includes('novlab')
                                ? ".novlab.de"
                                : ".novamag.de";
                        this.cookieService.delete(cookie, '/', novamagDomain);
                        try {
                            for (var _e = tslib_1.__values(magazine.domains), _f = _e.next(); !_f.done; _f = _e.next()) {
                                var domain = _f.value;
                                var domainArr = domain.domain.split('.');
                                var domainRoot = "." + domainArr.splice(domainArr.length - 2).join('.');
                                this.cookieService.delete(cookie, '/', domainRoot);
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    };
    AppComponent.prototype.renderSeoData = function (data) {
        var _this = this;
        this.titleService.setTitle(data.metaTitle);
        if (data.canonicalLink) {
            this.metaService.addTag({
                rel: 'canonical',
                href: data.canonicalLink
            });
        }
        var robots = [];
        robots.push(data.noIndex ? 'noindex' : 'index');
        robots.push(data.noFollow ? 'nofollow' : 'follow');
        this.nova.addOrSetTag({ name: 'description', content: data.metaDescription || '' });
        this.nova.addOrSetTag({
            name: 'keywords',
            content: (data.metaKeywords && data.metaKeywords.join(', ')) || ''
        });
        this.nova.addOrSetTag({ name: 'robots', content: robots.join(', ') });
        this.nova.addOrSetTag({ property: 'og:type', content: data.ogType || 'website' });
        this.nova.addOrSetTag({ property: 'og:title', content: data.ogTitle || '' });
        this.nova.addOrSetTag({ property: 'og:description', content: data.ogDescription || '' });
        this.nova.addOrSetTag({ property: 'og:image', content: data.ogImage || '' });
        this.nova.addOrSetTag({ name: 'twitter:card', content: data.twitterType || '' });
        this.nova.addOrSetTag({ name: 'twitter:title', content: data.twitterTitle || '' });
        this.nova.addOrSetTag({ name: 'twitter:description', content: data.twitterDescription || '' });
        this.nova.addOrSetTag({ name: 'twitter:image', content: data.twitterImage || '' });
        if (data.ogType === 'article') {
            if (data.ArticlePublishedTime) {
                this.nova.addOrSetTag({
                    property: 'article:published_time',
                    content: data.ArticlePublishedTime
                });
            }
            if (data.ArticleModifiedTime) {
                this.nova.addOrSetTag({
                    property: 'article:modified_time',
                    content: data.ArticleModifiedTime
                });
            }
            if (data.ArticleExpirationTime) {
                this.nova.addOrSetTag({
                    property: 'article:expiration_time',
                    content: data.ArticleExpirationTime
                });
            }
            if (data.articleAuthor) {
                this.nova.addOrSetTag({ property: 'article:author', content: data.articleAuthor });
            }
            if (data.articleSection) {
                this.nova.addOrSetTag({ property: 'article:section', content: data.articleSection });
            }
            if (data.articleTags) {
                data.articleTags.map(function (tag) { return _this.nova.addTag({ property: 'article:tag', content: tag }); });
            }
        }
    };
    AppComponent.prototype.handlePresentationPages = function (presentationPagesIndex, presentationPages) {
        var _this = this;
        presentationPagesIndex = presentationPages.findIndex(function (presentationPageId) { return presentationPageId === _this.nova.issue.pages[_this.pageIndex - 1]._id; });
        var nextPageId = presentationPages[presentationPagesIndex + 1];
        if (!nextPageId) {
            nextPageId = presentationPages[0];
            presentationPagesIndex = -1;
        }
        var nextPageIndex = this.nova.issue.pages.findIndex(function (page) { return page._id === nextPageId; });
        this.autoNavigate(nextPageIndex);
        return presentationPagesIndex + 1;
    };
    Object.defineProperty(AppComponent.prototype, "navType", {
        get: function () {
            return this.magazine && this.magazine.navigation.style;
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.getNavClass = function () {
        if (this.magazine) {
            return this.magazine.navigation.style;
        }
    };
    AppComponent.prototype.getNavColors = function () {
        if (['london', 'budapest', 'fti'].includes(this.navType)) {
            return {
                color: this.uiEventHandler.getColor(this.magazine.navigation.arrowColor),
                'background-color': this.uiEventHandler.getColor(this.magazine.navigation.arrowColorBackground)
            };
        }
        return {};
    };
    AppComponent.prototype.getNavActiveColors = function () {
        if (['london', 'budapest', 'fti'].includes(this.navType)) {
            return {
                color: this.uiEventHandler.getColor(this.magazine.navigation.arrowColorHover),
                'background-color': this.uiEventHandler.getColor(this.magazine.navigation.arrowColorBackgroundHover)
            };
        }
        return {};
    };
    AppComponent.prototype.customContentTable = function () {
        var _this = this;
        if (this.clickWait || this.uiEventHandler.topClose) {
            return;
        }
        this.clickWait = true;
        setTimeout(function () {
            _this.clickWait = false;
        }, 100);
        if (!this.nova.pages) {
            return;
        }
        var params = this.router.routerState.root.firstChild.params['value'];
        this.nova.pages.forEach(function (page) {
            if (page._id === _this.nova.issue.navigation.indexPage) {
                _this.router.navigate(['/', params['langHandle'], params['issueHandle'], page.handle], {
                    queryParamsHandling: 'preserve'
                });
            }
        });
    };
    AppComponent.prototype.mobileNavStyle = function () {
        var style = "\n            border-left: 1px solid " + this.c(this.navStyle.borderColor) + ";\n            color: " + this.c(this.navStyle.textColor) + ";\n        ";
        this.mobileLinkStyle = this.convertCSS(style);
    };
    AppComponent.prototype.isDisabled = function () {
        var params = this.router.routerState.root.firstChild.params['value'];
        var floor = -1;
        var page = -1;
        if (params['pageHandle']) {
            this.nova.pages.forEach(function (val, index) {
                if (val.handle === params['pageHandle']) {
                    page = index;
                }
            });
        }
        if (params['contentHandle'] && page !== -1) {
            this.nova.pages[page].contents.forEach(function (val, index) {
                if (val.handle === params['contentHandle']) {
                    floor = index;
                }
            });
        }
        if (page !== -1) {
            if (floor !== -1 &&
                this.nova.pages[page].contents[floor] &&
                this.nova.pages[page].contents[floor].status !== 'active') {
                return 'Dieser Inhalt ist <b class="inactive">Inaktiv</b>';
            }
            if (this.nova.pages[page].status !== 'active') {
                return 'Diese Seite ist <b class="inactive">Inaktiv</b>';
            }
        }
        if (params['issueHandle']) {
            var found_1;
            this.nova.issues.forEach(function (issue) {
                if (issue.handle === params['issueHandle']) {
                    found_1 = issue;
                }
            });
            if (found_1 && found_1['status'] !== 'active') {
                return 'Diese Ausgabe ist <b class="inactive">Inaktiv</b>';
            }
        }
        if (this.magazine && this.magazine.status !== 'active') {
            return 'Dieses Magazin ist <b class="inactive">Inaktiv</b>';
        }
        return '';
    };
    AppComponent.prototype.c = function (color, alpha) {
        return this.uiEventHandler.getColor(color, alpha);
    };
    AppComponent.prototype.checkForLanguageUpdate = function (params) {
        var _this = this;
        var newLang = params.langHandle;
        if (!newLang || newLang === '') {
            newLang = this.nova.findLang();
        }
        if (this.nova.lang !== newLang) {
            this.nova.lang = newLang;
            this.nova.mapIssue();
            // check if language exists
            var langs = this.getLanguages();
            if (langs.length <= 0) {
                if (newLang === this.magazine.language)
                    return;
                newLang = this.magazine.language;
                this.nova.lang = newLang;
                var availableLanguages_1 = [];
                Array.from(this.magazineService.issuesData).map(function (item) {
                    availableLanguages_1.push(item[0].language);
                });
                this.magazineService.availableLanguages = _.unique(availableLanguages_1, 'language');
                langs = this.magazineService.availableLanguages;
            }
            if (langs.indexOf(newLang) === -1) {
                newLang = langs[0];
            }
            if (this.nova.lang !== newLang) {
                this.nova.lang = newLang;
                this.nova.mapIssue();
                params.langHandle = this.nova.lang;
                // this prevents the router from navigating to the start page everytime, otherwise deeplinks won't work
                if (!params.issueHandle) {
                    this.router.navigate(['/', params.langHandle], { queryParamsHandling: 'preserve' });
                }
            }
        }
        if (this.ssr.isBrowser && typeof window !== 'undefined') {
            $('html').attr('lang', newLang);
        }
        this.state.set(LANG_KEY, newLang);
        this.translate.use(newLang);
        this.translate.get('CONTENT_TABLE').subscribe(function (res) {
            _this.uiEventHandler.CONTENT_TABLE = res;
        });
    };
    AppComponent.prototype.getLanguages = function () {
        return this.magazineService.availableLanguages;
    };
    AppComponent.prototype.toggleOverlay = function (value) {
        this.closeOverlay();
        if (this.ftiOverlay) {
            this.ftiOverlay.visible = value !== undefined ? value : !this.ftiOverlay.visible;
        }
    };
    AppComponent.prototype.closeOverlay = function () {
        var overlayComponent = this.ftiOverlay || this.budapestOverlay || this.londonOverlay;
        if (overlayComponent.visible) {
            this.router.navigate([], {
                queryParams: {
                    page: null
                },
                queryParamsHandling: 'merge'
            });
            overlayComponent.visible = false;
            overlayComponent.active = 'pages';
            return;
        }
    };
    AppComponent.prototype.goTo = function (pageIndex) {
        var _this = this;
        if (this.wait || !pageIndex)
            return;
        this.wait = true;
        var nextPageIndex = this.validatePage(pageIndex);
        var params = this.router.routerState.root.firstChild.params['value'];
        this.closeOverlay();
        this.router.navigate(['/', params['langHandle'], params['issueHandle'], this.nova.pages[nextPageIndex - 1].handle], {
            queryParamsHandling: 'preserve'
        });
        // reset menu button
        if (this.ssr.isBrowser && typeof window !== 'undefined') {
            this.document.querySelector('.menu-button svg').classList.remove('fa-close');
            this.document.querySelector('.menu-button svg').classList.add('fa-bars');
        }
        setTimeout(function () {
            _this.wait = false;
        }, 100);
    };
    AppComponent.prototype.urlHasImg = function () {
        var params = new URLSearchParams(this.router.url.split('?')[1]);
        return params.has('img');
    };
    AppComponent.prototype.slideLeft = function () {
        var _this = this;
        if (this.uiEventHandler.currentBlogPost) {
            var params = this.router.routerState.root.firstChild.params['value'];
            var currentPageIndex = this.uiEventHandler.blogPage.contents.findIndex(function (page) { return page._id === _this.uiEventHandler.currentBlogPost._id; });
            this.router.navigate([
                '/',
                params['langHandle'],
                params['issueHandle'],
                this.uiEventHandler.blogPage.handle,
                currentPageIndex === this.uiEventHandler.blogPage.contents.length - 1
                    ? ''
                    : this.uiEventHandler.blogPage.contents[currentPageIndex + 1].handle
            ], {
                queryParamsHandling: 'preserve'
            });
            return;
        }
        if (this.uiEventHandler.disableNav) {
            this.uiEventHandler.onArrowEvent('left');
        }
        else {
            return this.goTo(this.pageIndex - 1);
        }
    };
    AppComponent.prototype.slideRight = function () {
        var _this = this;
        if (this.uiEventHandler.blogPage) {
            if ($('.next-page').attr('disabled'))
                return;
            // navigate from blog index to first single blog post
            if (this.uiEventHandler.blogPage.contents.length > 0 && !this.uiEventHandler.currentBlogPost) {
                this.router.navigate([
                    '/',
                    this.router.routerState.root.firstChild.params['value']['langHandle'],
                    this.router.routerState.root.firstChild.params['value']['issueHandle'],
                    this.uiEventHandler.blogPage.handle,
                    this.uiEventHandler.blogPage.contents[this.uiEventHandler.blogPage.contents.length - 1].handle
                ]);
                return;
            }
            // navigate from single blog post to next single blog post or to the next page if it's the last blog post and there are more pages
            if (this.uiEventHandler.currentBlogPost) {
                var params = this.router.routerState.root.firstChild.params['value'];
                var currentBlogPostIndex = this.uiEventHandler.blogPage.contents.findIndex(function (page) { return page._id === _this.uiEventHandler.currentBlogPost._id; });
                if (currentBlogPostIndex === 0) {
                    return this.goTo(this.pageIndex + 1);
                }
                this.router.navigate([
                    '/',
                    params['langHandle'],
                    params['issueHandle'],
                    this.uiEventHandler.blogPage.handle,
                    this.uiEventHandler.blogPage.contents[currentBlogPostIndex - 1].handle
                ], {
                    queryParamsHandling: 'preserve'
                });
                return;
            }
        }
        if (this.uiEventHandler.disableNav) {
            this.uiEventHandler.onArrowEvent('right');
        }
        else {
            return this.goTo(this.pageIndex + 1);
        }
    };
    AppComponent.prototype.validatePage = function (pageIndex) {
        if (pageIndex < 1)
            return 1;
        if (pageIndex > this.nova.pages.length)
            return this.nova.pages.length;
        return pageIndex;
    };
    AppComponent.prototype.isLast = function () {
        return this.pageIndex >= this.nova.pages.length;
    };
    AppComponent.prototype.handleKeyboardEvent = function (keypressEvent) {
        if (this.uiEventHandler.showPaidContentDialog === true)
            return;
        if (keypressEvent.which === 39) {
            this.slideRight();
        }
        else if (keypressEvent.which === 37) {
            this.slideLeft();
        }
    };
    AppComponent.prototype.getCurrentRouteId = function (route) {
        var found = 1;
        if (this.nova.pages) {
            this.nova.pages.forEach(function (page, index) {
                if (page.handle === route.params['value'].pageHandle) {
                    found = index + 1;
                }
            });
        }
        return found;
    };
    AppComponent.prototype.prepareRouteTransition = function (outlet) {
        if (!outlet['activated']) {
            return null;
        }
        try {
            this.pageIndex = this.getCurrentRouteId(outlet.activatedRoute);
            this.currAni = '';
            if (!this.oldId) {
                this.currAni = '';
                return this.currAni;
            }
            if (this.pageIndex < this.oldId) {
                this.currAni = 'left';
            }
            else {
                this.currAni = 'right';
            }
            if (this.oldId !== this.pageIndex) {
                if (this.currAni !== '' && this.oldAni === this.currAni) {
                    if (this.currAni.endsWith('-alt')) {
                        this.currAni = this.currAni.substring(0, this.currAni.length - 4);
                    }
                    else {
                        this.currAni += '-alt';
                    }
                }
            }
            else {
                this.currAni = this.oldAni;
            }
            return this.currAni;
        }
        catch (e) {
            console.error(e);
            return '';
        }
    };
    AppComponent.prototype.animationDone = function () {
        this.oldId = this.pageIndex;
        this.oldAni = this.currAni;
    };
    AppComponent.prototype.isPhantomJS = function () {
        return this.nova.isScreenShot;
    };
    AppComponent.prototype.mobileMatrixStyle = function () {
        var ret = '';
        if (this.uiEventHandler.isMobile()) {
            ret += 'border-top: 1px solid ' + this.uiEventHandler.getColor(this.magazine.navigation.borderColor) + ';';
            ret += 'border-left: 1px solid ' + this.uiEventHandler.getColor(this.magazine.navigation.borderColor) + ';';
        }
        return this.convertCSS(ret);
    };
    AppComponent.prototype.getMobileStyle = function () {
        var ret = '';
        if (typeof window === 'undefined') {
            return;
        }
        if (this.magazine.navigation['backgroundColor']) {
            ret += "background-color: " + this.uiEventHandler.getColor(this.magazine.navigation['backgroundColor']) + "; ";
        }
        if (this.magazine.navigation['textColor']) {
            ret += "color: " + this.uiEventHandler.getColor(this.magazine.navigation['textColor']) + "; ";
        }
        if (this.magazine.navigation['font']) {
            ret += "font-family: " + this.magazine.navigation['font'] + "; ";
        }
        if (this.magazine.navigation['borderColor']) {
            ret += "border-top: 1px solid " + this.uiEventHandler.getColor(this.magazine.navigation['borderColor']) + "; ";
        }
        return this.convertCSS(ret);
    };
    AppComponent.prototype.convertCSS = function (css) {
        var input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {}, attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    };
    AppComponent.prototype.setMyStyles = function (obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    };
    AppComponent.prototype.loadCleverPushIntegration = function (mag) {
        var defaultCleverPushScript = "<script type=\"application/javascript\" src=\"https://static.cleverpush.com/channel/loader/" + mag.notifications.channel_id + ".js\"></script>";
        var userCentricscleverPushScript = "<script type=\"text/plain\" data-usercentrics=\"CleverPush\" data-name=\"CleverPush\" src=\"https://static.cleverpush.com/channel/loader/" + mag.notifications.channel_id + ".js\"></script>";
        var cleverPushScript = !mag.cookieConsent || (mag.cookieConsent && mag.cookieConsent.type === 'default')
            ? defaultCleverPushScript
            : userCentricscleverPushScript;
        $('head').append(cleverPushScript);
    };
    AppComponent.prototype.autoNavigate = function (page) {
        if (page === this.nova.pages.length) {
            this.goTo(1);
        }
        else {
            this.goTo(page + 1);
        }
    };
    return AppComponent;
}());
export { AppComponent };
