import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
var PageBookScrollStrikingComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageBookScrollStrikingComponent, _super);
    function PageBookScrollStrikingComponent(nova, uiEventHandler, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        _this.customPhantomEvent = true;
        return _this;
    }
    PageBookScrollStrikingComponent.prototype.ngOnInit = function () {
        var _this = this;
        var positonStyle = 'flex-grow: 1;';
        var positon = this.data['contentPosition'] || 'center-center';
        var positonArr = positon.split('-');
        if (positonArr[0] === 'top') {
            positonStyle += 'align-self: flex-start;';
            positonStyle += 'margin-top: 75px;';
        }
        if (positonArr[0] === 'center') {
            positonStyle += 'align-self: center;';
        }
        if (positonArr[0] === 'bottom') {
            positonStyle += 'align-self: flex-end;';
        }
        if (positonArr[1] === 'left') {
            positonStyle += 'text-align: left;';
        }
        if (positonArr[1] === 'center') {
            positonStyle += 'text-align: center;';
        }
        if (positonArr[1] === 'right') {
            positonStyle += 'text-align: right;';
        }
        this.positonStyle = this.convertCSS(positonStyle);
        if (typeof window !== 'undefined') {
            setTimeout(function () {
                _this.uiEventHandler.onScreenShotEvent(_this.data.id);
                $('.page-book-scroll').removeClass('animated slideInLeft fadeScaleUp fadeScaleUp2');
            }, 1000);
        }
    };
    PageBookScrollStrikingComponent.prototype.convertCSS = function (css) {
        var input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {};
            var attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    };
    PageBookScrollStrikingComponent.prototype.setMyStyles = function (obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    };
    PageBookScrollStrikingComponent = tslib_1.__decorate([
        NovaTemplate('PageBookScrollStrikingComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], PageBookScrollStrikingComponent);
    return PageBookScrollStrikingComponent;
}(Page));
export { PageBookScrollStrikingComponent };
