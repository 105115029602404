/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../nova-element/nova-element.component.ngfactory";
import * as i3 from "../nova-element/nova-element.component";
import * as i4 from "../../_services/nova-api.service";
import * as i5 from "../../_services/uievent-handler.service";
import * as i6 from "./nova-title.component";
var styles_NovaTitleComponent = [];
var RenderType_NovaTitleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NovaTitleComponent, data: {} });
export { RenderType_NovaTitleComponent as RenderType_NovaTitleComponent };
function View_NovaTitleComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "h3", [["class", "dateline"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "nova-element", [["name", "label"]], null, null, null, i2.View_NovaElementComponent_0, i2.RenderType_NovaElementComponent)), i0.ɵdid(3, 1097728, null, 0, i3.NovaElementComponent, [i0.Renderer2, i4.NovaAPIService, i0.ElementRef, i5.UIEventHandlerService], { data: [0, "data"], name: [1, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "dateline"; var currVal_1 = _co.classes; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.data; var currVal_3 = "label"; _ck(_v, 3, 0, currVal_2, currVal_3); }, null); }
function View_NovaTitleComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "h1", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "nova-element", [["name", "header"]], null, null, null, i2.View_NovaElementComponent_0, i2.RenderType_NovaElementComponent)), i0.ɵdid(3, 1097728, null, 0, i3.NovaElementComponent, [i0.Renderer2, i4.NovaAPIService, i0.ElementRef, i5.UIEventHandlerService], { data: [0, "data"], name: [1, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.classes; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.data; var currVal_2 = "header"; _ck(_v, 3, 0, currVal_1, currVal_2); }, null); }
function View_NovaTitleComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "h2", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "nova-element", [["name", "subHeader"]], null, null, null, i2.View_NovaElementComponent_0, i2.RenderType_NovaElementComponent)), i0.ɵdid(3, 1097728, null, 0, i3.NovaElementComponent, [i0.Renderer2, i4.NovaAPIService, i0.ElementRef, i5.UIEventHandlerService], { data: [0, "data"], name: [1, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.classes; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.data; var currVal_2 = "subHeader"; _ck(_v, 3, 0, currVal_1, currVal_2); }, null); }
export function View_NovaTitleComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_NovaTitleComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NovaTitleComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NovaTitleComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.label; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.data.header; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.data.subHeader; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_NovaTitleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "nova-title", [], null, null, null, View_NovaTitleComponent_0, RenderType_NovaTitleComponent)), i0.ɵdid(1, 114688, null, 0, i6.NovaTitleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NovaTitleComponentNgFactory = i0.ɵccf("nova-title", i6.NovaTitleComponent, View_NovaTitleComponent_Host_0, { data: "data", classes: "classes" }, {}, []);
export { NovaTitleComponentNgFactory as NovaTitleComponentNgFactory };
