<div class="login" [style.background]="color(magazine.navigation.borderColor)">
	<div class="flex-center">
		<nova-media [mediaId]="magazine.cookieBackground" [version]="getVersion()" [bg]="true" [opacity]="0.8">
		</nova-media>
		<div [style.background]="color(magazine.navigation.backgroundColor)">
			<div class="logo">
				<img *ngIf="logo" [src]="logo" alt="Logo" />
				<h2 [style.color]="color(magazine.navigation.textColor)">{{ magazine.name }}</h2>
			</div>
			<form name="form" (ngSubmit)="f.form.valid && login(f)" #f="ngForm" novalidate
				[style.border-top-color]="color(magazine.navigation.borderColor)">
				<div class="cookie-text" [style.color]="color(magazine.navigation.textColor)">
					Bitte melden Sie sich mit Ihrem Passwort an. Mit dem Einloggen erkennen Sie an, dass diese Inhalte nur für den persönlichen Gebrauch bestimmt sind. Eine Weitergabe an Dritte ist nicht gestattet.
				</div>

				<div *ngIf="f.submitted && !f.form.valid && authType == 'user'" class="alert alert-danger">
					<span *ngIf="error === 'invalidUserData'">
						Der eingegeben Username oder das Passwort ist inkorrekt
					</span>
					<span *ngIf="f.submitted && !model.username">Username eingeben</span>
					<span *ngIf="f.submitted && !model.password">Passwort eingeben</span>
				</div>

				<div *ngIf="f.submitted && !f.form.valid && (authType == 'global' || authType == 'password-protection')" class="alert alert-danger">
					<span *ngIf="error === 'invalidUserData'">Das eingegebene Passwort ist nicht korrekt</span>
					<span *ngIf="f.submitted && !model.password">Passwort eingeben</span>
				</div>

				<div *ngIf="f.submitted && error && f.form.valid" class="alert alert-danger">
					<span *ngIf="error === 'accountIsOutdated'">
						<nova-text-element *ngIf="magazine.noAccessMessage" [data]="magazine.noAccessMessage">
						</nova-text-element>
						<span *ngIf="!magazine.noAccessMessage">
							Ohje, dein Account ist abgelaufen.
						</span>
					</span>
					<span *ngIf="error === 'Forbidden'">
						<nova-text-element *ngIf="magazine.noAccessMessage" [data]="magazine.noAccessMessage">
						</nova-text-element>
						<span *ngIf="!magazine.noAccessMessage">
							Ohje, du bist nicht berechtigt dieses Magazin zu lesen.
						</span>
					</span>
				</div>

				<div *ngIf="code && !f.submitted">
					<div class="alert alert-danger">
						<nova-text-element *ngIf="magazine.noAccessMessage" [data]="magazine.noAccessMessage">
						</nova-text-element>
						<span *ngIf="!magazine.noAccessMessage">
							Ohje, du bist nicht berechtigt dieses Magazin zu lesen. Bitte logge dich erneut ein.
						</span>
					</div>
				</div>

				<div *ngIf="authType == 'user'" class="form-group"
					[ngClass]="{ 'has-error': f.submitted && !username.valid }">
					<label for="username">Username</label>
					<input type="text" class="form-control" name="username" [(ngModel)]="model.username"
						#username="ngModel" required />
				</div>
				<div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
					<input type="password" class="form-control" name="password" [(ngModel)]="model.password"
						#password="ngModel" required placeholder="Enter password"
						[style.color]="color(magazine.navigation.textColor)"
						[style.background-color]="color(magazine.navigation.backgroundColor)"
						[style.border-bottom-color]="color(magazine.navigation.textColor)"
						[style.border-bottom-style]="'solid'" [style.border-bottom-width]="'1px'" />


				</div>
				<div class="form-group">
					<button [disabled]="loading" (mouseover)="buttonHovered = true" (mouseleave)="buttonHovered = false"
						class="btn btn-primary"
						[style.color]="buttonHovered ? color(magazine.navigation.textHoverColor) : color(magazine.navigation.textColor)"
						[style.background-color]="buttonHovered ? color(magazine.navigation.backgroundHoverColor) : color(magazine.navigation.backgroundColor)"
						[style.border-color]="color(magazine.navigation.textColor)" [style.border-style]="'solid'"
						[style.border-width]="'1px'">
						Log in
					</button>
				</div>
			</form>
			<div class="links">
				<a *ngIf="magazine.termsTitle || magazine.termsText" (click)="terms()"
					[style.color]="color(magazine.navigation.textColor)">{{
					magazine.termsTitle || 'Privacy'
				}}</a>
			</div>
		</div>
	</div>
</div>
