/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nova-fti-overlay-privacy.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../nova-text-element/nova-text-element.component.ngfactory";
import * as i3 from "../../../nova-text-element/nova-text-element.component";
import * as i4 from "../../../../_services/uievent-handler.service";
import * as i5 from "../../../../_services/media.service";
import * as i6 from "../../../../_services/nova-api.service";
import * as i7 from "@angular/router";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../../../../_services/magazine.service";
import * as i10 from "../../../../_services/cookie.service";
import * as i11 from "@angular/common";
import * as i12 from "./nova-fti-overlay-privacy.component";
var styles_NovaFtiOverlayPrivacyComponent = [i0.styles];
var RenderType_NovaFtiOverlayPrivacyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NovaFtiOverlayPrivacyComponent, data: {} });
export { RenderType_NovaFtiOverlayPrivacyComponent as RenderType_NovaFtiOverlayPrivacyComponent };
function View_NovaFtiOverlayPrivacyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-text-element", [["class", "con"]], null, null, null, i2.View_NovaTextElementComponent_0, i2.RenderType_NovaTextElementComponent)), i1.ɵdid(1, 4964352, null, 0, i3.NovaTextElementComponent, [i4.UIEventHandlerService, i5.MediaService, i6.NovaAPIService, i7.Router, i8.DomSanitizer, i7.ActivatedRoute, i9.MagazineService, i10.CookieService, i11.DOCUMENT], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.defaultTermsText; _ck(_v, 1, 0, currVal_0); }, null); }
function View_NovaFtiOverlayPrivacyComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-text-element", [["class", "con"]], null, null, null, i2.View_NovaTextElementComponent_0, i2.RenderType_NovaTextElementComponent)), i1.ɵdid(1, 4964352, null, 0, i3.NovaTextElementComponent, [i4.UIEventHandlerService, i5.MediaService, i6.NovaAPIService, i7.Router, i8.DomSanitizer, i7.ActivatedRoute, i9.MagazineService, i10.CookieService, i11.DOCUMENT], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.termsText; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NovaFtiOverlayPrivacyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaFtiOverlayPrivacyComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaFtiOverlayPrivacyComponent_2)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showDefault; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.showCustom; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_NovaFtiOverlayPrivacyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-fti-overlay-privacy", [], null, null, null, View_NovaFtiOverlayPrivacyComponent_0, RenderType_NovaFtiOverlayPrivacyComponent)), i1.ɵdid(1, 573440, null, 0, i12.NovaFtiOverlayPrivacyComponent, [i6.NovaAPIService, i9.MagazineService], null, null)], null, null); }
var NovaFtiOverlayPrivacyComponentNgFactory = i1.ɵccf("nova-fti-overlay-privacy", i12.NovaFtiOverlayPrivacyComponent, View_NovaFtiOverlayPrivacyComponent_Host_0, { magazine: "magazine", issue: "issue", font: "font" }, {}, []);
export { NovaFtiOverlayPrivacyComponentNgFactory as NovaFtiOverlayPrivacyComponentNgFactory };
