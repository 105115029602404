import { Page } from './../../page';
import { DomSanitizer } from '@angular/platform-browser';
import { Component, ViewEncapsulation } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { OnInit } from '@angular/core';
import { MediaService } from 'app/_services/media.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: 'image-classic.component.html',
    styleUrls: ['../../templates/image-classic/image-classic.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('ServerImageClassicComponent')
export class ServerImageClassicComponent extends Page implements OnInit {
    public media: any[];
    public len: number;

    constructor(
        public nova: NovaAPIService,
        public mediaService: MediaService,
        public sanitizer: DomSanitizer,
        public renderer: Renderer2,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    ngOnInit() {
        this.addOgTags();
        this.len = this.data.medias.length;
        this.mediaService.loadMedia(this.data.medias).then(data => {
            this.media = data;
        });
    }
}
