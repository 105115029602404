import * as tslib_1 from "tslib";
import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Meta, Title } from '@angular/platform-browser';
import * as $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';
var PageCoverClassicLogoComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageCoverClassicLogoComponent, _super);
    function PageCoverClassicLogoComponent(nova, uiEventHandler, sanitizer, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.sanitizer = sanitizer;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        _this.resizeSub = _this.uiEventHandler.resizeEvent$.subscribe(_this.onResize.bind(_this));
        return _this;
    }
    PageCoverClassicLogoComponent.prototype.ngOnDestroy = function () {
        this.resizeSub.unsubscribe();
    };
    PageCoverClassicLogoComponent.prototype.ngOnInit = function () {
        var _this = this;
        var logoStyle = '';
        var flexboxStyle = '';
        var posLogo = this.data['logoPosition'];
        var posLogoArr = posLogo.split('-');
        if (posLogoArr[0] === 'top') {
            flexboxStyle += 'justify-content: flex-start;';
        }
        else if (posLogoArr[0] === 'bottom') {
            flexboxStyle += 'justify-content: flex-end;';
        }
        else if (posLogoArr[0] === 'center') {
            flexboxStyle += 'justify-content: center;';
        }
        if (posLogoArr[1] === 'left') {
            logoStyle += 'align-self: flex-start;text-align: left;';
        }
        else if (posLogoArr[1] === 'right') {
            logoStyle += 'align-self: flex-end;text-align: right;';
        }
        else if (posLogoArr[1] === 'center') {
            logoStyle += 'align-self: center;text-align: center;';
        }
        this.logoStyle = this.convertCSS(logoStyle);
        this.flexboxStyle = this.convertCSS(flexboxStyle);
        setTimeout(function () {
            _this.calcMaxSize();
        }, 100);
    };
    PageCoverClassicLogoComponent.prototype.calcMaxSize = function () {
        var _this = this;
        var logo_size = '';
        if (this.data['logo'] && this.data['logo'].url) {
            var divW_1 = $(this.logo.nativeElement).width();
            var logo_1 = $(this.logo.nativeElement).find('img')[0];
            if (logo_1['naturalHeight']) {
                logo_size += 'max-height: ' + (divW_1 * logo_1['naturalHeight']) / logo_1['naturalWidth'] + 'px;';
            }
            else {
                $(logo_1).on('load', function () {
                    divW_1 = $(_this.logo.nativeElement).width();
                    logo_size += 'max-height: ' + (divW_1 * logo_1['naturalHeight']) / logo_1['naturalWidth'] + 'px;';
                });
            }
        }
        if (this.data['logoSize']) {
            logo_size += 'height: calc(50px + ' + this.data['logoSize'] / 2 + 'vw);';
        }
        this.logo_size = this.convertCSS(logo_size);
    };
    PageCoverClassicLogoComponent.prototype.onResize = function (e) {
        this.calcMaxSize();
    };
    PageCoverClassicLogoComponent.prototype.convertCSS = function (css) {
        var input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {}, attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    };
    PageCoverClassicLogoComponent.prototype.setMyStyles = function (obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    };
    PageCoverClassicLogoComponent = tslib_1.__decorate([
        NovaTemplate('PageCoverClassicLogoComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            DomSanitizer,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], PageCoverClassicLogoComponent);
    return PageCoverClassicLogoComponent;
}(Page));
export { PageCoverClassicLogoComponent };
