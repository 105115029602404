<div class="selected-item">

    <div class="selected-item--image-slider" #sliderContainer>
        <div class="carousel-container">
            <div class="navigation">
                <div class="arrows">
                    <div class="arrow" (click)="slideLeft()">
                        <i class="icon-arrow-left"></i>
                    </div>
                    <div class="arrow" (click)="slideRight()">
                        <i class="icon-arrow-right"></i>
                    </div>
                </div>
            </div>

            <div *ngIf="selectedContent.image && selectedContent.image[0]" #owl class="owl-carousel">
                <div *ngFor="let img of selectedContent.image; let index = index" class="slide">
                    <img
                        class="selected-item--img"
                        [src]="getImageProxyUrlForBlock(img.filename)"
                    >
                </div>
            </div>
            <div *ngIf="selectedContent.image && selectedContent.image.filename" #owl class="owl-carousel">
                <div class="slide">
                    <img
                        class="selected-item--img"
                        [src]="getImageProxyUrlForBlock(selectedContent.image.filename)"
                    >
                </div>
            </div>
            <div *ngIf="!selectedContent.image" #owl class="owl-carousel">
                <div *ngFor="let image of images; let index = index" class="slide">
                    <nova-media [mediaId]="image.mediaId" version="horizontal" ></nova-media>
                    <div class="caption-box" *ngIf="selectedContent['slider_caption_'+(index+1)]">
                        <div class="caption-text">
                            <nova-element [data]="selectedContent" [name]="'slider_caption_'+(index+1)"></nova-element>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="selected-item--text-content" [style.height.px]="imagesHeight">
        <nova-text-element *ngIf="selectedContent.content" [data]="selectedContent.content"></nova-text-element>
        <nova-text-element *ngIf="selectedContent.text" [data]="selectedContent.text"></nova-text-element>
        <nova-media
            *ngIf="selectedContent.videoid || selectedContent.video"
            [mediaId]="selectedContent.videoid || selectedContent.video"
            version="embed"
            [forceAutoplay]="!uiEventHandler.isTablet()"
            ></nova-media>
    </div>

</div>
