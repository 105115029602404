import * as tslib_1 from "tslib";
import { Page } from '../../page';
import { NovaTemplate } from 'app/_decorators/template.decorator';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
var PageTransferStandardComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageTransferStandardComponent, _super);
    function PageTransferStandardComponent(nova, uiEventHandler, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        return _this;
    }
    PageTransferStandardComponent = tslib_1.__decorate([
        NovaTemplate('PageTransferStandardComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], PageTransferStandardComponent);
    return PageTransferStandardComponent;
}(Page));
export { PageTransferStandardComponent };
