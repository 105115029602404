import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title } from '@angular/platform-browser';
import { Color } from 'app/novamag-api/Types';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './easy-scroll.component.html',
    styleUrls: ['./easy-scroll.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageEasyScrollComponent')
export class PageEasyScrollComponent extends Page implements OnInit {
    public bgColor: Color;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
        this.customPhantomEvent = true;
    }

    ngOnInit() {
        if (typeof window !== 'undefined') {
            setTimeout(() => {
                this.uiEventHandler.onScreenShotEvent(this.data.id);
                $('.page-easy-scroll .bg').removeClass('animated slideInLeft fadeScaleUp');
            }, 3000);
        }

        this.bgColor = this.data['box_color'];

        if (this.uiEventHandler.isMobile() && this.data['box_color'].a === 0) {
            this.bgColor = this.data['background_color'];
        }
    }
}
