import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'nova-contactform',
    templateUrl: './nova-contactform.component.html',
    styleUrls: ['./nova-contactform.component.scss']
})
export class NovaContactFormComponent implements OnInit, OnDestroy {
    @Input() data;
    public formTemplate;
    public form: FormGroup;
    public myRecaptcha = new FormControl(false);
    public submitted = false;
    public isSubmitting = false;
    public submitSuccess = false;
    public errors = [];
    public fontRegular: string;
    public fontBold: string;
    public useCaptcha = false;

    eventListenerIntercept = event => event.stopImmediatePropagation();

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        this.fontBold = `'${this.data.fonts[0]}'`;
        this.fontRegular = `'${this.data.fonts[1]}'`;

        const group = {
            ...(this.useCaptcha ? { recaptcha: this.myRecaptcha } : {})
        };

        this.formTemplate = {
            ...(this.data.dynformelems ? { topSection: this.data.dynformelems } : {}),
            ...this.data.contactForm
        };

        if (this.data.dynformelems && this.data.dynformelems.fontfamily) {
            this.fontBold = `'${this.data.dynformelems.fontfamily}'`;
            this.fontRegular = `'${this.data.dynformelems.fontfamily}'`;
        }

        const emailToField = this.data.providerelems.field;

        const ta = this.data['travelAgency'];
        if (ta && ta['email'] && !emailToField['value']) {
            emailToField['value'] = ta['email'];
            emailToField['disabled'] = 'true';
        }

        // Add emailTo field to form if not already present
        this.formTemplate.topSection.field.some(field => field.id === 'emailto')
            ? null
            : this.formTemplate.topSection.field.unshift(emailToField);

        delete this.formTemplate['messages'];
        delete this.formTemplate['fontfamily'];
        delete this.formTemplate['channel'];

        for (const section of Object.entries(this.formTemplate)) {
            const sectionFields = {};

            for (const field of (section[1] as any).field) {
                const validators = [
                    ...(field.required === 'true' && field.type !== 'checkbox' ? [Validators.required] : ['']),
                    ...(field.type === 'email' ? [Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)] : ['']),
                    ...(field.type === 'checkbox' && field.required === 'true' ? [Validators.requiredTrue] : [''])
                ].filter(el => el);

                const value = field.type === 'select' ? field.options[0] : field.value;

                sectionFields[field.id] = [value || '', validators];

                if ('subfield' in field) {
                    // children
                    sectionFields[field.subfield.id] = this.fb.array([]);
                }
            }

            group[section[0]] = this.fb.group(sectionFields);
        }

        this.form = this.fb.group(group);

        this.onUpdate();
        window.addEventListener('keyup', this.eventListenerIntercept, true);
    }

    ngOnDestroy() {
        window.removeEventListener('keyup', this.eventListenerIntercept, true);
    }

    onUpdate(): void {
        this.form.get('travelinfo.childpax').valueChanges.subscribe(val => {
            const childAges = this.form.get('travelinfo.childage').value;

            if (childAges.length > val) {
                while (this.children.length > val) {
                    this.children.removeAt(this.children.length - 1);
                }
            } else {
                for (let index = childAges.length; index < val; index++) {
                    this.children.push(this.newChild());
                }
            }
        });
    }

    getFormattedDate(date: string) {
        const d = new Date(date);
        return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
    }

    onSubmit() {
        this.submitted = true;

        if (!this.form.valid) {
            console.error('Form has errors');

            return;
        }

        // Mock stuff
        const urlParams = new URLSearchParams(document.location.search.substring(1));
        const mockApiUrl = urlParams.get('mockApiUrl');
        const mockAddress = urlParams.get('mockAddress');
        const mockChannel = urlParams.get('mockChannel');

        this.isSubmitting = true;
        let formValue = this.form.getRawValue();

        formValue['travelinfo']['depdate'] = this.getFormattedDate(formValue['travelinfo']['depdate']);
        formValue['travelinfo']['retdate'] = this.getFormattedDate(formValue['travelinfo']['retdate']);

        // We don't need this on the other side
        if (this.useCaptcha) {
            delete formValue.recaptcha;
        }

        const formData = {
            ...(mockAddress && { mockAddress }),
            channel: mockChannel || this.data.contactForm['channel'],
            ...formValue['travelinfo'],
            ...formValue['contactdata'],
            ...formValue['topSection']
        };

        this.errors = [];

        fetch(mockApiUrl || 'https://fti-mailing.novamag.de/', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error(res.statusText);
                }

                return res.json();
            })
            .then(() => {
                this.errors = [];
                this.isSubmitting = false;
                this.submitSuccess = true;
            })
            .catch(error => {
                console.error(error);
                this.isSubmitting = false;
                this.errors = [...this.errors, error];
                this.submitSuccess = false;
            });
    }

    get children(): FormArray {
        return this.form.get('travelinfo.childage') as FormArray;
    }

    newChild(): FormGroup {
        return this.fb.group({
            age: '0'
        });
    }

    getFormSection(name: string) {
        return this.form.get(name);
    }

    onCaptchaScriptError() {
        console.error('Could not load Google reCAPTCHA');
    }
}
