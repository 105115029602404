import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PageIndexGridComponent } from 'app/pages/templates/index-grid/index-grid.component';
import { Meta, Title } from '@angular/platform-browser';
import { MagazineService } from 'app/_services/magazine.service';
var ServerIndexGridComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ServerIndexGridComponent, _super);
    function ServerIndexGridComponent(nova, mag, uiEventHandler, router, route, meta, title) {
        var _this = _super.call(this, nova, mag, uiEventHandler, meta, title, route, router) || this;
        _this.nova = nova;
        _this.mag = mag;
        _this.uiEventHandler = uiEventHandler;
        _this.router = router;
        _this.route = route;
        _this.meta = meta;
        _this.title = title;
        return _this;
    }
    ServerIndexGridComponent.prototype.ngOnInit = function () {
        this.addOgTags();
    };
    ServerIndexGridComponent = tslib_1.__decorate([
        NovaTemplate('ServerIndexGridComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            MagazineService,
            UIEventHandlerService,
            Router,
            ActivatedRoute,
            Meta,
            Title])
    ], ServerIndexGridComponent);
    return ServerIndexGridComponent;
}(PageIndexGridComponent));
export { ServerIndexGridComponent };
