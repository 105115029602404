<ng-template #sectionItem let-item="item">
    <h3 class="small" [innerHTML]="item.title.replace('m2 - Preisen', 'm<sup>2</sup> - Preisen')"></h3>

    <img *ngIf="!item.rentalYield && !item.title.includes('iib Markteinschätzung Häuser für 2023/2024')" [src]="item.image" [alt]="item.title">

    <div *ngIf="item.rentalYield || item.title.includes('iib Markteinschätzung Häuser für 2023/2024')" class="with-legend mb-30">
        <div class="legend"></div>
        <img class="mb-30" [src]="item.image" [alt]="item.title">
    </div>

    <div *ngIf="item.price && item.priceRange" class="price-container">
        <span class="price font-bold">&Oslash; {{item.price}}</span>
        <span class="price-range">
            Preisspanne <span [innerHTML]='item.title.startsWith("Häuser") ? "(Gesamtpreis)" : "(Preis pro m<sup>2</sup>)"'></span>
            <br/>
            {{item.priceRange}}
        </span>
    </div>

    <div *ngIf="item.rentalYield" class="price-container">
        <span class="price font-bold">&Oslash; {{item.rentalYield}}</span>
        <span class="price-range">aktuelle Mietrendite p.a.</span>
    </div>

    <div *ngIf="item.priceTrend" class="price-container">
        <span class="price font-bold">&Oslash; {{item.priceTrend}}</span>
        <span class="price-range">Veränderung 2021 nach 2022</span>
    </div>
    
    <hr>
</ng-template>

<div class="content-pflugfelder" [ngStyle]="{'background-color':getColor(data['background_color'])}">
    <div class="container">
        <section class="title">
            <div><span class="label">Teilmarkt</span></div>
            <h1>{{data.header}}</h1>
        </section>

        <section class="districts">
            <strong>{{data.districtType}}</strong>
            <span *ngFor="let district of sortedDistricts; last as isLast">
                <a *ngIf="district.handle" (click)="stop($event)" [routerLink]="['../' + district.handle]">{{district.name}}</a>
                <span *ngIf="!district.handle">{{district.name}}</span>
                <span *ngIf="!isLast">, </span>
            </span>
        </section>

        <section class="map mt-30">
            <strong>Wohnlagen</strong>

            <div class="map-legend">
                <span>Einfache Wohnlage</span>
                <span>Mittlere Wohnlage</span>
                <span>Gute Wohnlage</span>
                <span>Sehr gute Wohnlage</span>
                <span>Top Wohnlage</span>
            </div>
            <div class="map-container">
                <span>Karte lädt …</span>
                <div id="map"></div>
            </div>
        </section>

        <section class="market-data mt-100" *ngIf="!marketSectionsLoading">
            <h3>Marktdaten</h3>
            <p>Bitte wählen Sie Ihre Kategorie aus</p>

            <div class="categories-container">
                <div class="category-selector">
                    <div>
                        <a (click)="selectCategory(0)" [class]="selectedCategory === 0 ? 'active buy-a-house' : 'buy-a-house'" alt="Haus kaufen"></a>
                        <p>Häuser<br>Kauf</p>
                    </div>
                    <div>
                        <a (click)="selectCategory(1)" [class]="selectedCategory === 1 ? 'active buy-a-flat' : 'buy-a-flat'" alt="Wohnung kaufen"></a>
                        <p>Wohnungen<br>Kauf</p>
                    </div>
                    <div>
                        <a (click)="selectCategory(2)" [class]="selectedCategory === 2 ? 'active rent-a-flat' : 'rent-a-flat'" alt="Wohnung mieten"></a>
                        <p>Wohnungen<br>Miete</p>
                    </div>
                </div>

                <div class="categories">
                    <ul class="category-content" id="section01" *ngIf="selectedCategory === 0">
                        <li *ngFor="let item of marketSections.buyHouses; index as i;">
                            <ng-container *ngTemplateOutlet="sectionItem;context:{item: item}"></ng-container>
                        </li>
                    </ul>
                    <ul class="category-content" id="section02" *ngIf="selectedCategory === 1">
                        <li *ngFor="let item of marketSections.buyFlats; index as i;">
                            <ng-container *ngTemplateOutlet="sectionItem;context:{item: item}"></ng-container>
                        </li>
                    </ul>
                    <ul class="category-content" id="section03" *ngIf="selectedCategory === 2">
                        <li *ngFor="let item of marketSections.rentFlats; index as i;">
                            <ng-container *ngTemplateOutlet="sectionItem;context:{item: item}"></ng-container>
                        </li>
                    </ul>

                </div>
            </div>


        </section>

        <section class="contact mt-100">
            <h3>Kontakt</h3>
            <p>Vereinbaren Sie jetzt einen <strong>individiuellen Beratungstermin.</strong></p>

            <div class="contact-card">
                <div [class]="'contact-image contact-' + data.contact.split(' ')[1]"></div>
                <div class="contact-address">
                    <address>
                        <h4 class="font-bold">{{contactData.name}}</h4>
                        <h5>{{contactData.position}}</h5>
                        <span class="phone font-bold">{{contactData.phone}}</span>
                        <a class="email" href="mailto:{{contactData.mail}}">{{contactData.mail}}</a>
                    </address>
                </div>
            </div>
        </section>

        <section class="rating mt-100">
            <h3>Ihre Immobilie in {{data.header}}</h3>

            <p>Eine erste Grundlage für die Entscheidung zum Verkauf Ihrer Immobilie liefert unsere Online-Immobilienbewertung. Für die Wertermittlung benötigen wir nur wenige Basis-Informationen über die Immobilie, die Sie bewerten lassen möchten. Je nach Ausstattung, Zustand und Lage der Immobilie können wir Ihnen binnen weniger Minuten eine schnelle Einschätzung zum Objektwert übermitteln. Für die Bewertung von Mehrfamilienhäusern bitten wir Sie, sich direkt an unser Team zu wenden.</p>

            <div id="widget-container"></div>
        </section>
    </div>
</div>
<nova-nav-border-item class="icon-cancel my-close-icon" (click)="closeContent()"></nova-nav-border-item>
