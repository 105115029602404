﻿import { ComponentTemplate } from './component';
import { NovaAPIService } from '../_services/nova-api.service';
import { UIEventHandlerService } from '../_services/uievent-handler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { NovaTextGalleryComponent } from '../_components/nova-text-gallery/nova-text-gallery.component';

export class Floor extends ComponentTemplate {
    public pageId: number;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public route: ActivatedRoute,
        public router: Router,
        public meta: Meta,
        public title: Title
    ) {
        super(nova, uiEventHandler, meta, title);
    }

    closeContent(gallery?: NovaTextGalleryComponent) {
        if (gallery && gallery.open) {
            return;
        }

        const params = this.route.params['value'];

        if (this.data && this.data.parentPage && this.data.parentPage.templateName === 'blog-index') {
            history.back();

            return;
        }

        this.router.navigate(['/', params['langHandle'], params['issueHandle'], params['pageHandle']], {
            queryParams: {
                img: null
            },
            queryParamsHandling: 'merge'
        });
    }

    showPrint() {
        return !this.showPDF() && this.nova.issue.showPrint === 'yes';
    }

    showPDF() {
        return !!this.data['pdf_version'];
    }

    print() {
        this.nova.print();
    }

    printPDF() {
        this.nova.print();
    }
}
