import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Meta, Title } from '@angular/platform-browser';
import * as $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './external-content.component.html',
    styleUrls: ['./external-content.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageExternalContentComponent')
export class PageExternalContentComponent extends Page implements OnInit {
    public url: SafeResourceUrl;

    @ViewChild('iframe') public iframe: ElementRef;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
        this.customPhantomEvent = true;
    }

    ngOnInit() {
        if (!this.data.url.startsWith('http://') && !this.data.url.startsWith('https://')) {
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl('http://' + this.data.url);
        } else {
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
        }
        if (typeof window !== 'undefined') {
            setTimeout(() => {
                $(this.iframe.nativeElement).on('load error', () => {
                    setTimeout(() => {
                        this.uiEventHandler.onScreenShotEvent(this.data.id);
                    }, 1000);
                });
            });
        }
    }
}
