import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { PageExternalInlineComponent } from '../external-inline/external-inline.component';
@Component({
    templateUrl: './fti-weatherfinder.component.html',
    styleUrls: ['./fti-weatherfinder.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageFtiWeatherfinderComponent')
export class PageFtiWeatherfinderComponent extends PageExternalInlineComponent implements OnInit {
    ngOnInit() {
        if (typeof window !== 'undefined') {
            if (this.data.weatherscript) {
                if (this.data.weatherscript.substring(0, 7) === '<iframe') {
                    const tmp = document.createElement('div');
                    tmp.innerHTML = this.data.weatherscript;
                    const src = tmp.querySelector('iframe').getAttribute('src');
                    this.iframeSrc = src;
                } else {
                    this.iframeSrc = this.data.weatherscript;
                }

                this.scrolling = this.data.scrolling || this.scrolling;
                this.minHeight = this.data.minHeight || this.minHeight;
                this.heightCalculationMethod = this.data.heightCalculationMethod || this.heightCalculationMethod;

                const self = this;
                setTimeout(() => {
                    self.uiEventHandler.onScreenShotEvent(self.data.id);
                }, 5000);
            }
        }
    }
}
