import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ColorFormatter } from '../../../novamag-api/Types';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './portrait-classic.component.html',
    styleUrls: ['../portrait/portrait.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PagePortraitClassicComponent')
export class PagePortraitClassicComponent extends Page implements OnInit {
    public titleStyle;
    public helperStyle;
    public logoStyle;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    getColor() {
        let c = new ColorFormatter({ r: 255, g: 255, b: 255, a: 255 });
        if (this.data['box_color']) {
            c = new ColorFormatter(this.data['box_color']);
        }
        return c.rgba();
    }

    ngOnInit() {
        this.setTitlePos();
    }

    setTitlePos() {
        let titleStyle = '';
        if (!this.uiEventHandler.isMobile()) {
            let helperStyle = '';
            let logoStyle = '';

            const posLogo = this.data['contentPosition'];
            const posTitle: string = 'bottom-center';

            // position on x axis
            if (posLogo === 'top-left' || posLogo === 'bottom-left' || posLogo === 'center-left') {
                logoStyle += 'align-self: flex-start;';
            }
            if (posLogo === 'top-right' || posLogo === 'bottom-right' || posLogo === 'center-right') {
                logoStyle += 'align-self: flex-end;';
                logoStyle += 'text-align: right;';
            }
            if (posLogo === 'top-center' || posLogo === 'bottom-center' || posLogo === 'center-center') {
                logoStyle += 'text-align: center;';
            }
            if (posTitle === 'top-left' || posTitle === 'bottom-left' || posTitle === 'center-left') {
                titleStyle += 'align-self: flex-start;';
            }
            if (posTitle === 'top-right' || posTitle === 'bottom-right' || posTitle === 'center-right') {
                titleStyle += 'align-self: flex-end;';
            }

            if (
                (posLogo === 'center-center' || posLogo === 'center-right' || posLogo === 'center-left') &&
                (posTitle === 'center-center' || posTitle === 'center-right' || posTitle === 'center-left')
            ) {
                logoStyle += 'margin-bottom: 60px;';
            }

            // move logo to top
            if (posLogo === 'top-left' || posLogo === 'top-right' || posLogo === 'top-center') {
                if (posTitle !== 'top-left' && posTitle !== 'top-center' && posTitle !== 'top-right') {
                    logoStyle += 'flex-grow: 1;';
                    if (posTitle === 'center-center' || posTitle === 'center-right' || posTitle === 'center-left') {
                        titleStyle += 'flex-grow: 1;';
                    }
                } else {
                    helperStyle += 'order: 2;';
                    helperStyle += 'flex-grow: 1;';
                    logoStyle += 'margin-bottom: 60px;';
                }
            }
            if (posTitle === 'top-left' || posTitle === 'top-right' || posTitle === 'top-center') {
                if (posLogo !== 'top-left' && posLogo !== 'top-center' && posLogo !== 'top-right') {
                    titleStyle += 'flex-grow: 1;';
                    // titleStyle += 'order: 0;';
                    if (posLogo === 'center-center' || posLogo === 'center-right' || posLogo === 'center-left') {
                        logoStyle += 'flex-grow: 1;';
                    }
                }
            }

            // move logo to bottom
            if (posLogo === 'bottom-left' || posLogo === 'bottom-center' || posLogo === 'bottom-right') {
                if (posTitle !== 'bottom-left' && posTitle !== 'bottom-center' && posTitle !== 'bottom-right') {
                    logoStyle += 'order: 2;';
                } else {
                    helperStyle += 'flex-grow: 1;';
                    logoStyle += 'margin-bottom: 60px;';
                }
            }
            // move title to top
            if (posTitle === 'top-left' || posTitle === 'top-center' || posTitle === 'top-right') {
                if (posLogo !== 'top-left' && posLogo !== 'top-center' && posLogo !== 'top-right') {
                    titleStyle += 'order: 0;';
                }
            }

            if (posLogo === 'bottom-left' || posLogo === 'bottom-right' || posLogo === 'bottom-center') {
                if (posTitle === 'center-center' || posTitle === 'center-right' || posTitle === 'center-left') {
                    helperStyle += 'flex-grow: 1;';
                    titleStyle += 'flex-grow: 1;';
                }
            }
            if (posTitle === 'bottom-left' || posTitle === 'bottom-right' || posTitle === 'bottom-center') {
                if (posLogo === 'center-center' || posLogo === 'center-right' || posLogo === 'center-left') {
                    helperStyle += 'flex-grow: 1;';
                    logoStyle += 'flex-grow: 1;';
                }
            }

            this.helperStyle = this.convertCSS(helperStyle);
            this.logoStyle = this.convertCSS(logoStyle);
        }

        if (this.data.arrowBackgroundColor) {
            const c = new ColorFormatter(this.data['arrowBackgroundColor']);
            titleStyle += 'background-color: ' + c.rgba() + ';';
        }

        this.titleStyle = this.convertCSS(titleStyle);
    }

    convertCSS(css) {
        let input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {},
                attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    }

    setMyStyles(obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    }
}
