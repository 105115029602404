import {
    Component,
    ElementRef,
    HostListener,
    Inject,
    Input,
    PLATFORM_ID,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { OnChanges } from '@angular/core/src/metadata/lifecycle_hooks';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { NovaAPIService } from 'app/_services/nova-api.service';

@Component({
    selector: 'app-selected-block',
    templateUrl: './selected-block.component.html',
    styleUrls: ['./selected-block.component.scss']
})
export class SelectedBlockComponent implements OnChanges {
    @ViewChild('owl') owl: ElementRef;
    @ViewChild('sliderContainer') sliderContainer: ElementRef;
    @Input() selectedContent;
    imagesHeight: number;
    isBrowser: boolean = isPlatformBrowser(this.platformId);
    images;
    private sliderInited = false;
    private owlOptions = {
        autoplay: true,
        loop: false,
        rewind: true,
        autoplayTimeout: 6000,
        items: 1,
        singleItem: true,
        dots: false
    };

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        public uiEventHandler: UIEventHandlerService,
        public nova: NovaAPIService
    ) {}

    initSlider() {
        if (this.isBrowser) {
            setTimeout(() => {
                $(this.owl.nativeElement).owlCarousel(this.owlOptions);
                this.sliderInited = true;
                this.setImageHeight();

                setTimeout(() => {
                    this.setImageHeight();
                }, 1000);
            }, 0);
        }
    }

    @HostListener('window:resize')
    resize() {
        this.setImageHeight();
    }

    setImageHeight() {
        this.imagesHeight = Math.round($(this.sliderContainer.nativeElement).innerHeight());
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedContent && changes.selectedContent.previousValue !== changes.selectedContent.currentValue) {
            this.destroyOwlSlider();
            this.initSlider();

            this.images = this.getImages(changes.selectedContent.currentValue);
        }
    }

    destroyOwlSlider() {
        if (this.isBrowser && this.sliderInited) {
            $(this.owl.nativeElement)
                .trigger('destroy.owl.carousel')
                .removeClass('owl-loaded');
            $(this.owl.nativeElement)
                .find('.owl-stage-outer')
                .children()
                .unwrap();
        }
    }

    getImages(selectedBlock: any) {
        const images = [];
        const sliderImageProps = [
            {
                mediaId: 'slider_media_1',
                caption: 'slider_caption_1'
            },
            {
                mediaId: 'slider_media_2',
                caption: 'slider_caption_2'
            },
            {
                mediaId: 'slider_media_3',
                caption: 'slider_caption_3'
            },
            {
                mediaId: 'slider_media_4',
                caption: 'slider_caption_4'
            },
            {
                mediaId: 'slider_media_5',
                caption: 'slider_caption_5'
            }
        ];

        sliderImageProps.forEach(
            prop =>
                selectedBlock[prop.mediaId]
                    ? images.push({
                          mediaId: selectedBlock[prop.mediaId],
                          caption: selectedBlock[prop.caption] ? selectedBlock[prop.caption] : false
                      })
                    : null
        );

        return images;
    }

    stopAutoSlide() {
        $(this.owl.nativeElement).trigger('stop.owl.autoplay');
    }

    slideLeft() {
        this.stopAutoSlide();
        $(this.owl.nativeElement).trigger('prev.owl.carousel');
    }

    slideRight() {
        this.stopAutoSlide();
        $(this.owl.nativeElement).trigger('next.owl.carousel');
    }

    getImageProxyUrlForBlock(url) {
        const width = 800;
        const height = 600;
        const aspect = '1:1';
        const rt = 'fill';
        const size = `s:${width}:${height}:${aspect}`;
        return `${this.nova.getImgUrl()}/insecure/rt:${rt}/${size}/g:sm/plain/${url}`;
    }
}
