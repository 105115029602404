import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { ActivatedRoute, Router } from '@angular/router';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Floor } from '../../floor';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { Meta, Title } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import * as $ from 'jquery';
var PageProductComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageProductComponent, _super);
    function PageProductComponent(media, route, router, uiEventHandler, nova, meta, title) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.media = media;
        _this.meta = meta;
        _this.title = title;
        return _this;
    }
    PageProductComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.media.getMedia(this.data.product_image).subscribe(function (data) {
            _this.url = data.url;
            _this.urlThumb = data.thumb.url;
            if (!_this.uiEventHandler.isMobile()) {
                requestAnimationFrame(function () {
                    $('.pan').pan();
                });
            }
        });
    };
    PageProductComponent = tslib_1.__decorate([
        NovaTemplate('PageProductComponent'),
        tslib_1.__metadata("design:paramtypes", [MediaService,
            ActivatedRoute,
            Router,
            UIEventHandlerService,
            NovaAPIService,
            Meta,
            Title])
    ], PageProductComponent);
    return PageProductComponent;
}(Floor));
export { PageProductComponent };
