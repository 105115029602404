<div id="page-{{ id }}" class="page page-book-scroll-slider">
    <div class="left">
        <div #royalSlider class="royalSlider rsDefault" [style.height]="sliderHeight">
            <div *ngFor="let val of media" class="rsContent position-relative"
                [ngStyle]="{
                    'background-color':getColor(val.org['background_color']),
                    'background-image': 'url(' + this.getMediaUrl(val) + ')',
                    'background-size': 'cover',
                    'background-position': (val.org.media_position && val.org.media_position.split('-').join(' ')) || 'center center'
                }">
                <img *ngIf="(val.size && media.length > 1)" class="rsTmb" width="50"
                    [src]="this.mediaService.getMediaUrlByData(val)" />

                <a target="_blank" [href]="getCopyrightUrl(val.copyrightUrl)" *ngIf="val && val.copyright"
                    class="img-copyright">&copy; {{ val.copyright }}</a>

                <div *ngIf="!val.size" [ngStyle]="{'background-color':getColor(val.org['background_color'])}"
                    class="rsTmb rsTmbDiv"></div>

                <div class="rsCaption center" [ngStyle]="setMyStyles(val.pos.flexbox)">
                    <div [ngStyle]="{'padding-bottom': (data.showThumbs) ? '70px' : '10px'}">
                        <h1 *ngIf="val.org.description" [ngStyle]="setMyStyles(val.pos.logo)">
                            <nova-element [data]="val.org" name="description"></nova-element>
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <nova-scrollbar-holder [ngStyle]="{'backgroundColor':getColor(data['box_color'])}">
        <div class="scroller">
            <div class="bg">
                <div class="real-bg" [ngStyle]="{'backgroundColor':getColor(data['box_color'])}">
                    <nova-title [data]="data"></nova-title>
                    <nova-text-element [data]="data.text" (openGallery)="gallery.openSlider($event)">
                    </nova-text-element>
                    <nova-content-links class="content-links" *ngIf="data.links && data.links.length > 0"
                        [floors]="data.links"></nova-content-links>
                </div>
            </div>
        </div>
    </nova-scrollbar-holder>
    <nova-text-gallery [medias]="data.textImages" #gallery></nova-text-gallery>
</div>
