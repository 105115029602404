<div class="white-popup mfp-with-anim" [ngStyle]="{'backgroundColor':getColor(data['background_color'])}">
  <div class="table-row scrollbar-holder">
    <div class="scroller">
      <nova-content-sidebar [gallery]="gallery" [data]="data"></nova-content-sidebar>
      <div class="text-content">
        <div [ngClass]="{hidden: !uiEventHandler.textLoading}" class="loader">
          <div class="preloader-wrapper big active">
            <div class="spinner-layer spinner-blue-only" [ngStyle]="{'borderColor':spinnerColor}">
              <div class="circle-clipper left">
                <div class="circle"></div>
              </div><div class="gap-patch">
              <div class="circle"></div>
            </div><div class="circle-clipper right">
              <div class="circle"></div>
            </div>
            </div>
          </div>
        </div>
        <div [ngClass]="{hiddenOpac: uiEventHandler.textLoading, oneColumn: uiEventHandler.column == 'single'}" class="article-text scrollbar-holder" #textElm>
          <div class="scroller" [ngStyle]="{'fontSize':getFontSize()}">
            <nova-text-element (openGallery)="gallery.openSlider($event)" [data]="data.text" [columnize]="'true'" [myid]="data._id"></nova-text-element>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nova-text-gallery [medias]="data.textImages" #gallery></nova-text-gallery>
</div>
