import { Meta, Title } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RenderService } from './render.service';
import { PreloaderService } from './preloader.service';
import { map, shareReplay } from 'rxjs/operators';
import * as _ from 'underscore';
import { SSRService, LinkDefinition } from 'app/_services/ssr.service';
import { MagazineService } from 'app/_services/magazine.service';
import { NetworkService } from 'app/_services/network.service';
import { FontService } from 'app/_services/font.service';
import { FormService } from 'app/_services/form.service';
import { MediaService } from 'app/_services/media.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NovaAPIService {
    public issue: any;
    public issues: any[];
    public issuesOrg: any[];
    public pages = [];
    private pageIds = new Map();
    private contentIds = new Map();
    public lang: string;
    public fonts: string[];
    public isScreenShot = false;
    public trackingEnabled = false;
    public parentPage;

    constructor(
        public meta: Meta,
        public font: FontService,
        public form: FormService,
        public media: MediaService,
        public ssr: SSRService,
        public magazineService: MagazineService,
        public network: NetworkService,
        public title: Title,
        public translate: TranslateService,
        public router: Router,
        public renderService: RenderService,
        public preloader: PreloaderService,
        public route: ActivatedRoute
    ) {
        if (this.ssr.isBrowser) {
            const screenShot = this.ssr.getParameterByName('screenShot');
            this.isScreenShot = screenShot === 'true';
        } else {
            this.isScreenShot = false;
        }
    }

    isThumbLoading() {
        return false;
    }

    getReqUrl() {
        return this.ssr.getRequestUrl();
    }

    setMetaData(titleClear, subtitleClear?, thumb?, keywords?) {
        let url;
        if (typeof window === 'undefined') {
            url = this.ssr.getRequestUrl();
        } else {
            url = window.location.href;
        }
        let subtitle = '';
        if (subtitleClear) {
            subtitle = subtitleClear;
        }
    }

    addTag(tag: LinkDefinition) {
        return this.ssr.addTag(tag);
    }

    addOrSetTag({
        name,
        itemprop,
        property,
        content,
        canonical
    }: {
        name?: string;
        itemprop?: string;
        property?: string;
        canonical?: string;
        content: any;
    }) {
        if (name) {
            if (this.meta.getTag(`name='${name}'`)) {
                this.meta.updateTag({ name, content }, `name='${name}'`);
            } else {
                this.meta.addTag({ name, content });
            }
        }

        if (itemprop) {
            if (this.meta.getTag(`itemprop='${itemprop}'`)) {
                this.meta.updateTag({ itemprop, content }, `itemprop='${itemprop}'`);
            } else {
                this.meta.addTag({ itemprop, content });
            }
        }

        if (property) {
            if (this.meta.getTag(`property='${property}'`)) {
                this.meta.updateTag({ property, content }, `property='${property}'`);
            } else {
                this.meta.addTag({ property, content });
            }
        }
    }

    preloadPageMedia(imgArr?) {
        imgArr = imgArr ? imgArr : this.media.getRequestedUrls();
        let mediaLoaded = false;

        return new Promise(resolve => {
            this.preloader.preloadImages(imgArr).then(() => {
                setTimeout(() => {
                    if (!mediaLoaded) {
                        mediaLoaded = true;
                        resolve(imgArr);
                    }
                }, 2000);
            });

            setTimeout(() => {
                if (!mediaLoaded) {
                    mediaLoaded = true;
                    resolve(imgArr);
                }
            }, 10000);
        });
    }

    getPageData(): Observable<any> {
        return this.getCurrentIssue().pipe(
            map(() => {
                const pageHandle = this.router.routerState.root.firstChild.params['value'].pageHandle;
                const contentHandle = this.router.routerState.root.firstChild.params['value'].contentHandle;

                let id = 0;
                let contentId;

                if (pageHandle) {
                    this.pages.forEach((page, index) => {
                        if (page.handle === pageHandle) {
                            id = index;
                        }

                        if (page.templateName === 'blog-article') {
                            this.parentPage = this.pages.find(p => p._id === page.pageId);
                        }
                    });
                }

                if (contentHandle) {
                    contentId = this.pages[id].contents.findIndex(content => content.handle === contentHandle);

                    const parentPage =
                        pageHandle && contentHandle && this.pages.find(page => page.handle === pageHandle);

                    return {
                        id,
                        contentId,
                        data: this.magazineService.formatHtml({ ...this.pages[id].contents[contentId], parentPage })
                    };
                } else {
                    // triggers rendering bug
                    this.router.navigate(['/', this.issue.language, this.issue.handle, this.pages[id]['handle']], {
                        queryParamsHandling: 'preserve'
                    });

                    return {
                        id,
                        data: this.magazineService.formatHtml({ ...this.pages[id], parentPage: this.parentPage })
                    };
                }
            })
        );
    }

    filterIssuesByLang(issueArr) {
        return issueArr
            .map(issue => {
                let langData;
                if (typeof issue.languagesMap[this.lang] !== 'undefined') {
                    langData = issue.languagesMap[this.lang];

                    return Object.assign(issue, langData);
                }

                return;
            })
            .filter(issue => issue !== undefined);
    }

    mapIssue() {
        this.issues = this.filterIssuesByLang(this.issuesOrg);
    }

    getCurrentIssue(magazineLanguage?: string): Observable<any> {
        const params = this.router.routerState.root.firstChild.params['value'];

        if (this.issue && params.issueHandle === this.issue.handle && params.langHandle === this.issue.language) {
            if (!this.ssr.getToken()) {
                return of(this.issue);
            }
        }

        return this.magazineService.getIssue(params.issueHandle, params.langHandle || magazineLanguage).pipe(
            map(issue => {
                this.pages = issue.pages;
                this.pageIds = issue.pageIds;
                this.contentIds = issue.contentIds;
                this.issue = issue;

                return issue;
            }),
            map(() => {
                if (this.ssr.isBrowser) {
                    this.trackingEnabled = true;
                }
                this.setLoaded(this.issue['fonts'], this.issue.fonts);

                return this.issue;
            }),
            shareReplay(1)
        );
    }

    private setLoaded(fontsArr, renderedFonts = []) {
        fontsArr = fontsArr.concat(renderedFonts);
        fontsArr = _.uniq(fontsArr);
        fontsArr = _.compact(fontsArr);
        this.fonts = fontsArr;
    }

    findLang() {
        let newlang;

        if (!this.ssr.isBrowser) {
            const req = this.ssr.getRequest();
            if (req.headers['accept-language']) {
                newlang = req.headers['accept-language'].split(';')[0].split(',')[0];
                if (newlang.search('-') !== -1) {
                    newlang = newlang.substr(0, newlang.search('-'));
                }
            }
        } else {
            newlang = navigator['language'] || navigator['userLanguage'];

            if (newlang.search('-') !== -1) {
                newlang = newlang.substr(0, newlang.search('-'));
            }
        }

        return newlang;
    }

    public getMagazineUrl(): string {
        return this.network.getMagazineUrl();
    }

    public getApiUrl(): string {
        return this.network.getAPIUrl();
    }

    public getAliasUrl(): string {
        return this.network.getAliasUrl();
    }

    public getImgUrl(): string {
        return this.network.getIMGUrl();
    }

    public getCurrentIssueHandle() {
        const params = this.router.routerState.root.firstChild.params['value'];
        const issueHandle = params.issueHandle;

        return issueHandle;
    }

    public findPage(id: string) {
        return this.pageIds.get(id);
    }

    public findContent(id: string) {
        return this.contentIds.get(id);
    }

    getFont(fontName: string): Observable<any> {
        return this.font.getFont(fontName);
    }

    print() {
        this.getPageData().subscribe(data => {
            if (typeof data['data']['pdf_version'] !== 'undefined' && data['data']['pdf_version']) {
                this.media.getMedia(data['data']['pdf_version']).subscribe(media => {
                    window.open(media.url, '_blank');
                });
            } else {
                const isIE11 = !!(window as any).MSInputMethodContext && !!(document as any).documentMode;
                const url = this.router.url;
                let printFrame = document.getElementById('printIframe') as HTMLIFrameElement;

                if (isIE11) {
                    const printWin = window.open('/amp/print?from=' + url);
                    printWin.focus();
                    if (printWin.onload) {
                        printWin.onload = function() {
                            window.print();
                            window.onafterprint = function() {
                                window.close();
                            };
                        };
                    }
                } else {
                    if (printFrame) {
                        printFrame.src = '/amp/print?from=' + url;
                    } else {
                        printFrame = document.createElement('iframe');
                        printFrame.id = 'printIframe';
                        printFrame.src = '/amp/print?from=' + url;
                        document.body.appendChild(printFrame);
                    }
                    setTimeout(function() {
                        printFrame.contentWindow.print();
                    }, 0);
                }
            }
        });
    }
}
