import { Component, EventEmitter, ViewEncapsulation, OnInit, OnDestroy, Output } from '@angular/core';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MagazineService } from 'app/_services/magazine.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'nova-ci-style',
    templateUrl: './nova-ci-style.component.html',
    encapsulation: ViewEncapsulation.None
})
export class NovaCiStyleComponent implements OnInit, OnDestroy {
    @Output('pageChange') change: EventEmitter<number> = new EventEmitter<number>();

    public css: any;
    private sub: Subscription;

    constructor(
        public mag: MagazineService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        this.sub = this.mag.getMagazine().subscribe(data => {
            if (data && data.navigation.style) {
                this.css = this.sanitizer.bypassSecurityTrustResourceUrl(
                    'assets/build/navigations/' + data.navigation.style + '.css'
                );

                if (typeof window !== 'undefined') {
                    $(':root').css('--scollbar-thumb-bg', this.uiEventHandler.getColor(data.navigation.borderColor));
                    $(':root').css('--plyr-color-main', '#fff');
                    $(':root').css('--plyr-video-control-color', '#fff');
                    $(':root').css('--plyr-video-control-color-hover', '#0bc4c5');
                    $(':root').css('--plyr-video-control-background-hover', '#000');

                    data.font && $(':root').css('--font-main', `'${data.font.replace('+', ' ')}'`);
                    data.navigation.font && $(':root').css('--font-nav', `'${data.navigation.font.replace('+', ' ')}'`);
                }
            }
        });
    }

    brightness(color) {
        const lum = 2.99 * color.r + 5.87 * color.g + 1.14 * color.b;
        if (lum < 1275 && color.a !== 0) {
            return {
                r: '255',
                g: '255',
                b: '255',
                a: '1'
            };
        }
        return {
            r: '0',
            g: '0',
            b: '0',
            a: '1'
        };
    }

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe();
    }
}
