/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../_components/nova-nav-border-item/nova-nav-border-item.component.ngfactory";
import * as i3 from "../_components/nova-nav-border-item/nova-nav-border-item.component";
import * as i4 from "../_services/nova-api.service";
import * as i5 from "../_services/magazine.service";
import * as i6 from "../_services/uievent-handler.service";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@angular/common";
import * as i9 from "./page.component";
import * as i10 from "../_services/ssr.service";
import * as i11 from "@angular/router";
var styles_PageComponent = [i0.styles];
var RenderType_PageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageComponent, data: {} });
export { RenderType_PageComponent as RenderType_PageComponent };
function View_PageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_PageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-nav-border-item", [["class", "my-print-icon icon-print"]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onMouseEnter($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseLeave($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onTouchStart($event.target) !== false);
        ad = (pd_2 && ad);
    } if (("touchend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onTouchEnd($event.target) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.nova.print() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_NovaNavBorderItemComponent_0, i2.RenderType_NovaNavBorderItemComponent)), i1.ɵdid(1, 245760, null, 0, i3.NovaNavBorderItemComponent, [i4.NovaAPIService, i5.MagazineService, i6.UIEventHandlerService, i7.DomSanitizer, i1.ElementRef, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_PageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-nav-border-item", [["class", "my-print-icon icon-pdf"]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onMouseEnter($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseLeave($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onTouchStart($event.target) !== false);
        ad = (pd_2 && ad);
    } if (("touchend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onTouchEnd($event.target) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.nova.print() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_NovaNavBorderItemComponent_0, i2.RenderType_NovaNavBorderItemComponent)), i1.ɵdid(1, 245760, null, 0, i3.NovaNavBorderItemComponent, [i4.NovaAPIService, i5.MagazineService, i6.UIEventHandlerService, i7.DomSanitizer, i1.ElementRef, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_PageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "content-utils"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageComponent_3)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageComponent_4)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "nova-nav-border-item", [["class", "my-close-icon icon-cancel"]], [[8, "hidden", 0]], [[null, "click"], [null, "mouseenter"], [null, "mouseleave"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onMouseEnter($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onMouseLeave($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6).onTouchStart($event.target) !== false);
        ad = (pd_2 && ad);
    } if (("touchend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6).onTouchEnd($event.target) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.closeContent(_co.gallery) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_NovaNavBorderItemComponent_0, i2.RenderType_NovaNavBorderItemComponent)), i1.ɵdid(6, 245760, null, 0, i3.NovaNavBorderItemComponent, [i4.NovaAPIService, i5.MagazineService, i6.UIEventHandlerService, i7.DomSanitizer, i1.ElementRef, i1.Renderer2], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showPrint(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.showPDF(); _ck(_v, 4, 0, currVal_1); _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.uiEventHandler.hideContentCloseIcon; _ck(_v, 5, 0, currVal_2); }); }
export function View_PageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { templateContainer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i8.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, [[1, 3], ["dynamicItemView", 2]], null, 0, null, View_PageComponent_1)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageComponent_2)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.hasParent && (_co.template !== "blog-article")) ? "content" : "page"); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.uiEventHandler.topClose || _co.hasParent) && (_co.template !== "blog-article")); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_PageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_PageComponent_0, RenderType_PageComponent)), i1.ɵdid(1, 245760, null, 0, i9.PageComponent, [i10.SSRService, i1.ComponentFactoryResolver, i4.NovaAPIService, i11.Router, i11.ActivatedRoute, i6.UIEventHandlerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageComponentNgFactory = i1.ɵccf("ng-component", i9.PageComponent, View_PageComponent_Host_0, {}, {}, []);
export { PageComponentNgFactory as PageComponentNgFactory };
