import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PageIndexClassicComponent } from 'app/pages/templates/index-classic/index-classic.component';
import { Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MagazineService } from 'app/_services/magazine.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: '../../templates/index-classic/index-classic.component.html',
    styleUrls: ['../../templates/index-classic/index-classic.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('ServerIndexFloatComponent')
export class ServerIndexFloatComponent extends PageIndexClassicComponent implements OnInit {
    constructor(
        public nova: NovaAPIService,
        public mag: MagazineService,
        public renderer: Renderer2,
        public uiEventHandler: UIEventHandlerService,
        public router: Router,
        public route: ActivatedRoute,
        public meta: Meta,
        public title: Title,
        public translate: TranslateService
    ) {
        super(nova, mag, renderer, uiEventHandler, meta, title, translate, router, route);
    }

    ngOnInit() {
        this.addOgTags();
    }
}
