<div class="share">
    <a class="social-button whatsapp" (click)="share('whatsapp')">
        {{ 'WhatsApp' | translate }}
    </a>
    <a class="social-button mail" (click)="share('mail')">
        {{ 'E-Mail' | translate }}
    </a>
    <a class="social-button url" (click)="share('copy')">
        {{ 'URL' | translate }}
    </a>
</div>
