import * as tslib_1 from "tslib";
import { AfterContentInit, OnDestroy } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { ColorFormatter } from '../../../novamag-api/Types';
import { ElementRef } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MagazineService } from 'app/_services/magazine.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
var PageIndexClassicComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageIndexClassicComponent, _super);
    function PageIndexClassicComponent(nova, mag, renderer, uiEventHandler, meta, title, translate, router, route) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.mag = mag;
        _this.renderer = renderer;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.title = title;
        _this.translate = translate;
        _this.router = router;
        _this.route = route;
        return _this;
    }
    PageIndexClassicComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        if (this.data['box_color']) {
            var c = new ColorFormatter(this.data['box_color']);
            this.renderer.setStyle(this.elm.nativeElement, 'background-color', c.rgba());
        }
        if (this.data['textColor']) {
            var c = new ColorFormatter(this.data['textColor']);
            this.renderer.setStyle(this.elm.nativeElement, 'color', c.rgb());
        }
        this.sub = this.mag.getMagazine().subscribe(function (data) {
            _this.subFont = _this.nova.getFont(data.navigation.font).subscribe(function (fontData) {
                _this.font = fontData.name;
                _this.renderer.setStyle(_this.elm.nativeElement, 'font-family', fontData.name);
            });
            _this.pages = _this.nova.pages.filter(function (page) {
                return (page.templateName === 'index-hotel' ||
                    page.templateName === 'index-roundtrip' ||
                    page.templateName === 'index-trips' ||
                    !page.templateName.startsWith('index-')) &&
                    page.status === 'active' &&
                    page.showInIndex !== 'no';
            });
        });
    };
    PageIndexClassicComponent.prototype.goToPage = function (page, floor) {
        var params = this.router.routerState.root.firstChild.params['value'];
        if (!floor) {
            this.router.navigate(['/', params['langHandle'], params['issueHandle'], page], {
                queryParamsHandling: 'preserve'
            });
        }
        else {
            this.router.navigate(['/', params['langHandle'], params['issueHandle'], page, floor], {
                queryParamsHandling: 'preserve'
            });
        }
    };
    PageIndexClassicComponent.prototype.getType = function (type) {
        return this.translate.get(type);
    };
    PageIndexClassicComponent.prototype.ngOnDestroy = function () {
        if (this.sub)
            this.sub.unsubscribe();
        if (this.subFont)
            this.subFont.unsubscribe();
    };
    PageIndexClassicComponent = tslib_1.__decorate([
        NovaTemplate('PageIndexClassicComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            MagazineService,
            Renderer2,
            UIEventHandlerService,
            Meta,
            Title,
            TranslateService,
            Router,
            ActivatedRoute])
    ], PageIndexClassicComponent);
    return PageIndexClassicComponent;
}(Page));
export { PageIndexClassicComponent };
