<div [ngStyle]="setMyStyles(getStyle())">
  <a (click)="goTo(link)" *ngFor="let link of links" [ngStyle]="{
        'font-family':link.font,
        'font-size':link.fontSize,
        'color':link.styleColor,
        'padding':link.padding,
        'box-shadow':link.boxShadow,
        'line-height':link.lineHeight,
        'background-color':link.styleBg,
        'border-color':link.styleBorderColor,
        'border-style':'solid',
        'border-width':link.borderWidth > 0 ? link.borderWidth + 'px' : '0px',
        'font-weight':(link.bold || link.text_bold) ? 'bold' : 'normal',
        'text-decoration':(link.underline || link.text_underline) ? 'underline' : 'none',
        'font-style':(link.italic || link.text_italic) ? 'italic' : 'normal',
        'width':link.width}">
    <div class="a-con">
      <div *ngIf="showThumbs === 'showThumbs' && link.thumb" class="img" [ngStyle]="{'background-image':'url('+link.thumb+')'}"></div>
      <nova-media *ngIf="showThumbs === 'showIcons' && link.linkIcon" class="link-icon" [mediaId]="link.linkIcon"[version]="original" [bg]="false" [fallback]="true"></nova-media>
      <i *ngIf="showThumbs === 'showIcons' && !link.linkIcon" [ngStyle]="{'margin-right': '0.875em'}"
        [class]="getIcon(link.templateName)"></i>
      <span class="a-con__text">{{ link.text || '&nbsp;' }}</span>
    </div>
  </a>
  <span class="clear"></span>
</div>
