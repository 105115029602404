import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { SharedHotelHelperService } from '../../hotel-detail-standard/shared-hotel-helper.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { NovaAPIService } from 'app/_services/nova-api.service';

@Component({
    selector: 'app-transfer',
    templateUrl: './transfer.component.html',
    encapsulation: ViewEncapsulation.None
})
export class TransferComponent {
    @Input() transfer;
    @Input() contentFontStyle;
    @Output() handleClick = new EventEmitter();

    constructor(
        public transferHelper: SharedHotelHelperService,
        public uiEventHandler: UIEventHandlerService,
        public nova: NovaAPIService
    ) {}

    goTo(id) {
        this.handleClick.emit(id);
    }

    getImageProxyUrl(url) {
        const width = 600;
        const height = 400;
        const aspect = '1:1';
        const rt = 'fill';
        const size = `s:${width}:${height}:${aspect}`;
        return `${this.nova.getImgUrl()}/insecure/rt:${rt}/${size}/g:sm/plain/${url}`;
    }
}
