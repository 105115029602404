<div id="page-{{ id }}" class="page page-book-scroll-striking">
	<div class="left">
		<nova-bg [data]="data" [version]="'vertical-wide'"></nova-bg>
		<div class="flexbox"><nova-title [ngStyle]="setMyStyles(positonStyle)" [data]="data"></nova-title></div>
	</div>
	<nova-scrollbar-holder [ngStyle]="{ backgroundColor: getColor(data['box_color']) }">
		<div class="scroller">
			<div class="bg">
				<div class="real-bg" [ngStyle]="{ backgroundColor: getColor(data['box_color']) }">
					<div class="mobile-img show-for-medium-only">
						<nova-mobile-img [data]="data" class="show-for-small-only"></nova-mobile-img>
						<div class="center"><nova-title [data]="data"></nova-title></div>
					</div>
					<nova-title class="show-for-small-only" [data]="data"></nova-title>
					<nova-text-element
						[data]="data.text"
						(openGallery)="gallery.openSlider($event)"
					></nova-text-element>
					<nova-content-links
						class="content-links"
						*ngIf="data.links && data.links.length > 0"
						[floors]="data.links"
					></nova-content-links>
				</div>
			</div>
		</div>
	</nova-scrollbar-holder>
	<nova-text-gallery [medias]="data.textImages" #gallery></nova-text-gallery>
</div>
