<div id="page-{{ id }}" class="page page-index-trip"
[ngStyle]="{'backgroundColor':getColor(data['background_color'])}">
    <nova-bg [data]="data" [forceVideo]="true"></nova-bg>
    <nova-scrollbar-holder>
        <div class="scroller">
            <div class="show-for-medium-only">
                <nova-media [mediaId]="data.background_media" version="horizontal"></nova-media>
            </div>
            <div class="center clear">
                <div class="title-wrapper">
                    <nova-title [data]="data"></nova-title>
                </div>
                <div class="filter-bar" #filter_font>
                    <div class="filter-bar__filters">
                        <div class="filter-bar__box">
                            <angular2-multiselect
                                [data]="allFilters"
                                [(ngModel)]="selectedFilters"
                                (ngModelChange)="filter()"
                                [settings]="dropdownSettings"
                            ></angular2-multiselect>
                        </div>
                        <div class="filter-bar__box">
                            <angular2-multiselect
                                [data]="allCountryFilters"
                                [(ngModel)]="selectedCountryFilters"
                                (ngModelChange)="filter()"
                                [settings]="countryDropdownSettings"
                            ></angular2-multiselect>
                        </div>
                    </div>

					<p class="space-top"><b
                        #font_size_bold>{{(filteredData).length}}</b>
                        {{(filteredData).length == 1 ? 'Ausflug' : 'Ausflüge'}} verfügbar</p>
                </div>

                <div class="product-list flex-container">
                    <div #tripDetailHeaderElements
                        class="flex-container trip-item standard"
                        *ngFor="let trip of filteredData"
                        [@fadeAnimation]="'in'"
                        [ngStyle]="{backgroundColor: getColor(trip.background_color)}">

                        <app-trip [trip]="trip" (handleClick)="goTo($event)" class="trip-item-container" [contentFontStyle]="contentFontStyle"></app-trip>
                    </div>

                </div>

                <div class="filter-bar filter-footer" *ngIf="data.text">
                    <nova-text-element [data]="data.text"></nova-text-element>
                </div>
            </div>
        </div>
        <nova-content-links style="display: none" [floors]="data.links"></nova-content-links>
        <nova-text-gallery [medias]="data.textImages" #gallery></nova-text-gallery>
        <nova-font-loader *ngIf="data.font" [fonts]="[data.font]"></nova-font-loader>
        <nova-font-loader *ngIf="data.font_bold" [fonts]="[data.font_bold]"></nova-font-loader>
    </nova-scrollbar-holder>
</div>
