import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnInit, QueryList, Renderer2 } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import { SharedHotelHelperService } from '../hotel-detail-standard/shared-hotel-helper.service';
import { FilterTravelworldModernPipe } from './filter-category-tags.pipe';
import { ActivatedRoute, Router } from '@angular/router';
var PageTravelworldModernComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageTravelworldModernComponent, _super);
    function PageTravelworldModernComponent(nova, uiEventHandler, sanitizer, meta, hotelHelper, media, title, renderer, tagsFilter, router, route) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.sanitizer = sanitizer;
        _this.meta = meta;
        _this.hotelHelper = hotelHelper;
        _this.media = media;
        _this.title = title;
        _this.renderer = renderer;
        _this.tagsFilter = tagsFilter;
        _this.router = router;
        _this.route = route;
        _this.filteredData = [];
        _this.continentFilters = [];
        _this.countryFilters = [];
        _this.categoryFilters = [];
        _this.selectedContinentFilters = [];
        _this.selectedCountryFilters = [];
        _this.selectedCategoryFilters = [];
        _this.continentDropdownSettings = {
            singleSelection: false,
            text: 'Kontinente filtern',
            enableCheckAll: false,
            showCheckbox: true,
            enableSearchFilter: false,
            classes: 'nova-multiselect'
        };
        _this.countryDropdownSettings = {
            singleSelection: false,
            text: 'Länder filtern',
            enableCheckAll: false,
            showCheckbox: true,
            enableSearchFilter: false,
            classes: 'nova-multiselect'
        };
        _this.categoryDropdownSettings = {
            singleSelection: false,
            text: 'Reise-Interessen filtern',
            enableCheckAll: false,
            showCheckbox: true,
            enableSearchFilter: false,
            classes: 'nova-multiselect'
        };
        return _this;
    }
    PageTravelworldModernComponent.prototype.setRemainingContinentFilters = function (contents) {
        var filterStrings = [];
        contents.forEach(function (content) {
            filterStrings = tslib_1.__spread(filterStrings, (content.continent_labels ? content.continent_labels : []));
        });
        this.continentFilters = filterStrings
            .filter(function (item, pos) { return filterStrings.indexOf(item) === pos; })
            .sort(function (a, b) { return a.toLowerCase().localeCompare(b.toLowerCase()); })
            .map(function (item) { return ({ id: item, itemName: item }); });
    };
    PageTravelworldModernComponent.prototype.setRemainingCountryFilters = function (contents) {
        var filterStrings = [];
        contents.forEach(function (content) {
            filterStrings = tslib_1.__spread(filterStrings, (content.country_labels ? content.country_labels : []));
        });
        this.countryFilters = filterStrings
            .filter(function (item, pos) { return filterStrings.indexOf(item) === pos; })
            .sort(function (a, b) { return a.toLowerCase().localeCompare(b.toLowerCase()); })
            .map(function (item) { return ({ id: item, itemName: item }); });
    };
    PageTravelworldModernComponent.prototype.setRemainingCategoryFilters = function (contents) {
        var filterStrings = [];
        contents.forEach(function (content) {
            filterStrings = tslib_1.__spread(filterStrings, (content.category_labels ? content.category_labels : []));
        });
        this.categoryFilters = filterStrings
            .filter(function (item, pos) { return filterStrings.indexOf(item) === pos; })
            .sort(function (a, b) { return a.toLowerCase().localeCompare(b.toLowerCase()); })
            .map(function (item) { return ({ id: item, itemName: item }); });
    };
    PageTravelworldModernComponent.prototype.filter = function () {
        this.filteredData = this.tagsFilter.transform(this.data.blocks, this.selectedContinentFilters, this.selectedCountryFilters, this.selectedCategoryFilters);
        this.filteredData = this.filteredData.map(function (item) {
            if (item.link) {
                item.link = item.link + window.location.search;
            }
            return item;
        });
        this.setRemainingContinentFilters(this.filteredData);
        this.setRemainingCountryFilters(this.filteredData);
        this.setRemainingCategoryFilters(this.filteredData);
    };
    PageTravelworldModernComponent.prototype.ngOnInit = function () {
        this.filter();
    };
    PageTravelworldModernComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.data['filter_font']) {
            this.nova.getFont(this.data['filter_font']).subscribe(function (fontData) {
                _this.filterFontElements.forEach(function (element) {
                    _this.renderer.setStyle(element.nativeElement, 'font-family', fontData.name);
                });
            });
        }
        if (this.data['filter_font_header']) {
            this.nova.getFont(this.data['filter_font_header']).subscribe(function (fontData) {
                _this.filterFontHeaderElements.forEach(function (element) {
                    _this.renderer.setStyle(element.nativeElement, 'font-family', fontData.name);
                });
            });
        }
    };
    PageTravelworldModernComponent.prototype.getImageUrl = function (id) {
        var imageUrl = '';
        this.media.getMedia(id).subscribe(function (_a) {
            var url = _a.url;
            return (imageUrl = url);
        });
        return imageUrl;
    };
    PageTravelworldModernComponent.prototype.getFont = function (name) {
        return name.replace('+', ' ');
    };
    PageTravelworldModernComponent = tslib_1.__decorate([
        NovaTemplate('PageTravelworldModernComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            DomSanitizer,
            Meta,
            SharedHotelHelperService,
            MediaService,
            Title,
            Renderer2,
            FilterTravelworldModernPipe,
            Router,
            ActivatedRoute])
    ], PageTravelworldModernComponent);
    return PageTravelworldModernComponent;
}(Page));
export { PageTravelworldModernComponent };
