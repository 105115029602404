import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PageIndexClassicComponent } from 'app/pages/templates/index-classic/index-classic.component';
import { Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MagazineService } from 'app/_services/magazine.service';
import { TranslateService } from '@ngx-translate/core';
var ServerIndexClassicComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ServerIndexClassicComponent, _super);
    function ServerIndexClassicComponent(nova, mag, renderer, uiEventHandler, router, route, meta, title, translate) {
        var _this = _super.call(this, nova, mag, renderer, uiEventHandler, meta, title, translate, router, route) || this;
        _this.nova = nova;
        _this.mag = mag;
        _this.renderer = renderer;
        _this.uiEventHandler = uiEventHandler;
        _this.router = router;
        _this.route = route;
        _this.meta = meta;
        _this.title = title;
        _this.translate = translate;
        return _this;
    }
    ServerIndexClassicComponent.prototype.ngOnInit = function () {
        this.addOgTags();
    };
    ServerIndexClassicComponent = tslib_1.__decorate([
        NovaTemplate('ServerIndexClassicComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            MagazineService,
            Renderer2,
            UIEventHandlerService,
            Router,
            ActivatedRoute,
            Meta,
            Title,
            TranslateService])
    ], ServerIndexClassicComponent);
    return ServerIndexClassicComponent;
}(PageIndexClassicComponent));
export { ServerIndexClassicComponent };
