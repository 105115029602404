import { OnInit, OnDestroy, EventEmitter, SimpleChanges } from '@angular/core';
import { NovaAPIService } from '../../_services/nova-api.service';
import { Router, NavigationEnd } from '@angular/router';
import { MagazineService } from '../../_services/magazine.service';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import { ColorFormatter } from '../../novamag-api/Types';
import { StripeService } from 'app/_services/stripe.service';
var NovaBudapestTitleBarComponent = /** @class */ (function () {
    function NovaBudapestTitleBarComponent(nova, router, mag, ui, stripe) {
        this.nova = nova;
        this.router = router;
        this.mag = mag;
        this.ui = ui;
        this.stripe = stripe;
        this.openOverlay = new EventEmitter();
        this.pageChange = new EventEmitter();
    }
    NovaBudapestTitleBarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sub = this.mag.getMagazine().subscribe(function (data) {
            _this.magData = data;
            _this.nav = data.navigation;
            _this.selectStartPage = data.selectStartPage === 'archiv';
            _this.subFont = _this.nova.getFont(data.navigation.font).subscribe(function (fontData) {
                _this.font = fontData.name;
            });
        });
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                _this.getCurrentPage();
            }
        });
        this.getCurrentPage();
    };
    NovaBudapestTitleBarComponent.prototype.ngOnChanges = function (changes) {
        if (changes.issue && changes.issue.currentValue !== changes.issue.previousValue) {
            this.getCurrentPage();
        }
    };
    NovaBudapestTitleBarComponent.prototype.getCurrentPage = function () {
        var _this = this;
        var params = this.router.routerState.root.firstChild.params['value'];
        var pageHandle = params.pageHandle;
        this.infoPage = this.nova.issue.infoPage;
        this.homeLinks = this.nova.issue.homeLinks;
        this.logoLinkTarget = this.nova.issue.logoLinkTarget;
        this.logoLink = this.nova.issue.logoLink;
        this.logoLinkTarget = this.nova.issue.logoLinkTarget;
        if (pageHandle) {
            this.nova.pages.forEach(function (page, index) {
                if (page.handle === pageHandle) {
                    _this.currentPage = index;
                }
            });
            this.pageNum = this.nova.pages.length;
        }
    };
    Object.defineProperty(NovaBudapestTitleBarComponent.prototype, "pageTitle", {
        get: function () {
            return this.ui.getContentTableEntryName(this.nova.pages[this.currentPage]);
        },
        enumerable: true,
        configurable: true
    });
    NovaBudapestTitleBarComponent.prototype.openBudapestOverlay = function () {
        this.openOverlay.emit();
    };
    NovaBudapestTitleBarComponent.prototype.getColor = function (c) {
        return new ColorFormatter(c).rgba();
    };
    NovaBudapestTitleBarComponent.prototype.getLogoPadding = function (logoSize) {
        var maxPadding = window.innerWidth > 768 ? 25 : 20;
        var calculatedPadding = (maxPadding * Number(logoSize.replace('%', ''))) / 100;
        var yPadding = logoSize ? maxPadding - calculatedPadding : 10;
        return yPadding + "px 20px";
    };
    NovaBudapestTitleBarComponent.prototype.mobileBG = function () {
        return new ColorFormatter(this.nav.backgroundColor).rgba();
    };
    NovaBudapestTitleBarComponent.prototype.ngOnDestroy = function () {
        this.sub && this.sub.unsubscribe();
        this.subFont && this.subFont.unsubscribe();
        this.subRouter && this.subRouter.unsubscribe();
        this.logoSub && this.logoSub.unsubscribe();
    };
    NovaBudapestTitleBarComponent.prototype.goToInfoPage = function (pageId) {
        var pageIndex = this.nova.pages.findIndex(function (page) { return page._id === pageId; });
        this.pageChange.emit(pageIndex + 1);
    };
    NovaBudapestTitleBarComponent.prototype.goToUrl = function (url) {
        var currentPage = this.issue.pages[this.currentPage];
        if (currentPage.templateName === 'blog-index') {
            this.router.navigate([this.router.url.substring(0, this.router.url.lastIndexOf('/'))]);
            return;
        }
        this.router.navigate([url], {
            queryParamsHandling: 'preserve'
        });
    };
    return NovaBudapestTitleBarComponent;
}());
export { NovaBudapestTitleBarComponent };
