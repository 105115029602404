import { DomSanitizer } from '@angular/platform-browser';
import { Component, ViewEncapsulation } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { OnInit } from '@angular/core';
import { MediaService } from 'app/_services/media.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: 'poker.component.html',
    styleUrls: ['../../templates/poker/poker.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('ServerPokerComponent')
export class ServerPokerComponent extends Page implements OnInit {
    public media: any[];
    public teaser: boolean;
    public class: string;

    constructor(
        public nova: NovaAPIService,
        private mediaService: MediaService,
        public sanitizer: DomSanitizer,
        public renderer: Renderer2,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    ngOnInit() {
        this.addOgTags();
        const length = Object.keys(this.data.medias).length;
        if (length === 1) {
            this.class = 'slides-1 ';
        } else if (length === 2) {
            this.class = 'slides-2 ';
        } else if (length === 3) {
            this.class = 'slides-3 ';
        } else if (length === 4) {
            this.class = 'slides-4 ';
        } else if (length === 5) {
            this.class = 'slides-5 ';
        }

        this.teaser =
            (this.data.contents && this.data.contents.length > 0) ||
            (this.data.text && this.data.text.length > 0) ||
            this.data.header ||
            this.data.subHeader ||
            this.data.label;
        this.mediaService.loadMedia(this.data.medias).then(data => {
            this.media = data;
        });
    }

    getEntryStyle(val, wide?: boolean) {
        if (wide === true) {
            return 'url(' + this.mediaService.getMediaUrlByData(val, 'horizontal-wide') + ')';
        }

        return 'url(' + this.mediaService.getMediaUrlByData(val) + ')';
    }
}
