import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { MagazineService } from '../../../_services/magazine.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
    selector: 'nova-london-overlay-search',
    templateUrl: './nova-london-overlay-search.component.html',
    styleUrls: [
        '../nova-london-overlay-pages/nova-london-overlay-pages.component.scss',
        './nova-london-overlay-search.component.scss'
    ]
})
export class NovaLondonOverlaySearchComponent implements OnInit {
    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
    currentPage = -1;
    @Input() public font;
    @Input() public borderColor;
    pages = [];
    public searchField;

    constructor(
        public nova: NovaAPIService,
        public mag: MagazineService,
        public uiEventHandler: UIEventHandlerService,
        public router: Router
    ) {}

    ngOnInit() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.getCurrentPage();
            }
        });
        this.getCurrentPage();
    }

    searchPage(page, s) {
        if (page.header && page.header.search(s) != -1) {
            return true;
        }
        if (page.subHeader && page.subHeader.search(s) != -1) {
            return true;
        }
        if (page.text && page.text.search(s) != -1) {
            return true;
        }
        if (page.label && page.label.search(s) != -1) {
            return true;
        }
        if (page.index_title && page.index_title.search(s) != -1) {
            return true;
        }
        if (page.medias) {
            let found = false;
            page.medias.forEach(block => {
                if (this.searchPage(block, s)) {
                    found = true;
                }
            });
            if (found) return true;
        }
        if (page.blocks) {
            let found = false;
            page.blocks.forEach(block => {
                if (this.searchPage(block, s)) {
                    found = true;
                }
            });
            if (found) return true;
        }
        if (page.textLeft && page.textLeft.search(s) != -1) {
            return true;
        }
        if (page.textRight && page.textRight.search(s) != -1) {
            return true;
        }

        return false;
    }

    escapeRegExp(str) {
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    }

    getPages(q) {
        let s = new RegExp(this.escapeRegExp(q), 'i');
        let arr = this.nova.pages.map((page, index) => {
            return {
                ...page,
                key: index,
                subKey: 1
            };
        });
        arr = arr.filter(page => {
            return this.searchPage(page, s);
        });
        this.nova.pages.forEach((page, index) => {
            page.contents.filter((con, index2) => {
                if (this.searchPage(con, s)) {
                    con.type = 'content';
                    con.pageHandle = page.handle;
                    con.key = index;
                    con.subKey = index2;
                    arr.push(con);
                }
            });
        });
        this.pages = arr;
    }
    isActive(check) {
        if (check) {
            return this.borderColor;
        }
    }

    getCurrentPage() {
        const params = this.router.routerState.root.firstChild.params['value'];
        const pageHandle = params.pageHandle;
        if (pageHandle) {
            this.nova.pages.forEach((page, index) => {
                if (page.handle === pageHandle) {
                    this.currentPage = index;
                }
            });
        }
    }

    goTo(page) {
        this.pageChange.emit(page);
    }

    pad(num, size) {
        let s = num + '';
        while (s.length < size) s = '0' + s;
        return s;
    }
}
