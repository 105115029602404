import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NovaAPIService } from '../../_services/nova-api.service';
import { ColorFormatter } from '../../novamag-api/Types';
import { ActivatedRoute, Router } from '@angular/router';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import { of } from 'rxjs';

@Component({
    selector: 'nova-content-links',
    templateUrl: './nova-content-links.component.html',
    styleUrls: ['./nova-content-links.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NovaContentLinksComponent implements OnInit {
    constructor(
        public nova: NovaAPIService,
        private router: Router,
        private route: ActivatedRoute,
        private ui: UIEventHandlerService
    ) {}

    public apiUrl;

    @Input() public showThumbs = 'showIcons';
    @Input() public floors;

    public links = [];
    public page;

    async ngOnInit() {
        this.nova.getPageData().subscribe(response => {
            this.page = response['data'];
        });

        const links = [];

        for await (const link of this.floors) {
            if (link.loaded) {
                links.push(link);
                continue;
            }

            if (link.type === 'page' || link.type === 'content') {
                let handle;

                if (link.type === 'page') {
                    handle = this.nova.findPage(link.target);
                } else if (link.type === 'content') {
                    handle = this.nova.findContent(link.target);
                }

                if (handle) {
                    link.handle = { pageId: handle.page, floorId: handle.floor };
                    link.linkIcon = link.link_icon || handle.linkIcon;
                    link.thumb = handle.thumb || 'assets/img/templates/pages/' + handle.templateName + '.svg';
                    link.templateName = handle.templateName;
                } else {
                    continue;
                }
            }

            if (link.type === 'external') {
                if (!link.target) {
                    console.warn('External link has no target', link);
                    continue;
                }

                link.templateName = 'external';

                if (!link.target.startsWith('http://') && !link.target.startsWith('https://')) {
                    link.target = 'http://' + link.target;
                }

                if (link.link_icon) {
                    link.linkIcon = link.link_icon;
                }
            }

            if (link['text_bgColor']) {
                const c = new ColorFormatter(link['text_bgColor']);
                link['styleBg'] = c.rgba();
            }

            if (link['text_color']) {
                const c = new ColorFormatter(link['text_color']);
                link['styleColor'] = c.rgba();
            }

            if (link['text_borderColor']) {
                const c = new ColorFormatter(link['text_borderColor']);
                link['styleBorderColor'] = c.rgba();
            }

            if (link['text_borderWidth']) {
                link['borderWidth'] = link['text_borderWidth'];
            }

            if (link['text_size']) {
                link['paddingIcon'] = this.convertCSS(2 - link['text_size'] / 20 + 'em');
                link['fontSize'] = this.convertCSS(this.ui.calcFontSize(link['text_size']));
            } else {
                link['fontSize'] = this.convertCSS(this.ui.calcFontSize(1));
                link['paddingIcon'] = '2em';
            }

            if (link['text_padding']) {
                let pad = link['text_padding']['top'] + 'px ';
                pad += link['text_padding']['right'] + 4 + 'px ';
                pad += link['text_padding']['bottom'] + 'px ';
                pad += link['text_padding']['left'] + 4 + 'px';

                let boxShadow = '';
                let bg = 'transparent';

                if (link['backgroundColor']) {
                    const c = new ColorFormatter(link['backgroundColor']);
                    bg = c.rgba();
                }

                boxShadow += '-' + link['text_padding']['left'] + 'px 0 0 ' + bg;
                boxShadow += ', ' + link['text_padding']['right'] + 'px 0 0 ' + bg;
                link['boxShadow'] = this.convertCSS(boxShadow);

                const padding = link['text_padding']['top'] + link['text_padding']['bottom'];
                link['lineHeight'] = this.convertCSS('calc(1.2em + ' + padding + 'px)');
                link['padding'] = this.convertCSS(pad);
            }

            if (this.page.links_style === 'grid') {
                link['width'] = '100%';
            }

            if (link['text_fonts']) {
                await this.nova
                    .getFont(link['text_fonts'])
                    .toPromise()
                    .then(fontData => {
                        link.font = fontData.name;
                        links.push(link);
                    })
                    .catch(() => {
                        link.font = 'system-ui';
                        links.push(link);
                    });
            } else {
                links.push(link);
            }

            link.loaded = true;
        }

        this.links = links;
    }

    goTo(link) {
        if (link.type === 'external') {
            const win = window.open(link.target, '_blank');
            win.focus();
        } else if (link.handle) {
            const params = this.route.params['value'];
            if (link.handle.floorId) {
                this.router.navigate(
                    ['/', params['langHandle'], params['issueHandle'], link.handle.pageId, link.handle.floorId],
                    { queryParamsHandling: 'preserve' }
                );
            } else {
                this.router.navigate(['/', params['langHandle'], params['issueHandle'], link.handle.pageId], {
                    queryParamsHandling: 'preserve'
                });
            }
        }
    }

    getIcon(type) {
        if (type === 'interview-classic') return 'fas fa-comment';
        else if (type === 'external') return 'fas fa-external-link-alt';
        else if (type === 'image-classic') return 'fas fa-images';
        else if (type === 'image-gallery') return 'fas fa-images';
        else if (type === 'product') return 'fas fa-images';
        else if (type === 'form-classic') return 'fas fa-edit';
        else if (type === 'video-classic' || type === 'video-cinema') return 'fas fa-video';
        else if (
            type === 'article-classic' ||
            type === 'article-scroll' ||
            type === 'timeline-classic' ||
            type === 'timeline-left' ||
            type === 'timeline-right'
        )
            return 'fas fa-book-open';
        else return 'fas fa-book';
    }

    convertCSS(css) {
        const input = css.toString();
        if (input.indexOf(':') > -1) {
            const result = {},
                attributes = input.split(';');
            for (let i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    const entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }

            return result;
        }

        return css;
    }

    getStyle() {
        if (typeof window === 'undefined') {
            return;
        }

        let style = '';

        if (this.page.links_style === 'grid') {
            style += `display: grid;`;
        }

        if (this.page.links_min_width) {
            style += `grid-template-columns: repeat(auto-fit, minmax(${this.page.links_min_width}px, 1fr));`;
        }

        if (this.page.links_grid_gap) {
            style += `gap: ${this.page.links_grid_gap}px;`;

            if (this.page.links_grid_gap > 1) {
                if (this.page.background_bottom_box_color && this.page.background_bottom_box_color.a > 0) {
                    style += `padding: 19px;`;
                } else {
                    style += `padding-top: ${this.page.links_grid_gap}px;`;
                }
            }
        }

        return this.convertCSS(style);
    }

    setMyStyles(obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    }
}
