import * as tslib_1 from "tslib";
import { rgbaObjectToString } from '../_helpers/rgba-object-to-string';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaService } from './media.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "./media.service";
var BlogService = /** @class */ (function () {
    function BlogService(sanitizer, mediaService) {
        this.sanitizer = sanitizer;
        this.mediaService = mediaService;
    }
    BlogService.prototype.setBlogStyles = function (blogIndexPage) {
        if (!blogIndexPage) {
            return;
        }
        var root = document.documentElement;
        if (root.style.getPropertyValue('--blog-grid-columns-desktop')) {
            return;
        }
        blogIndexPage.font && root.style.setProperty('--font-regular', "'" + blogIndexPage.font.replace('+', ' ') + "'");
        blogIndexPage.font_bold &&
            root.style.setProperty('--font-bold', "'" + blogIndexPage.font_bold.replace('+', ' ') + "'");
        root.style.setProperty('--blog-grid-columns-desktop', blogIndexPage.gridColumnsDesktop);
        root.style.setProperty('--blog-grid-columns-mobile', blogIndexPage.gridColumnsMobile);
        root.style.setProperty('--blog-grid-gap-desktop', blogIndexPage.gridGapDesktop + "px");
        root.style.setProperty('--blog-grid-gap-mobile', blogIndexPage.gridGapMobile + "px");
        root.style.setProperty('--blog-background-color-content', (blogIndexPage.background_color_content && rgbaObjectToString(blogIndexPage.background_color_content)) ||
            '#fff');
        root.style.setProperty('--blog-background-color', (blogIndexPage.background_color && rgbaObjectToString(blogIndexPage.background_color)) || '#fff');
    };
    BlogService.prototype.getBlogNavigationIcon = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!id) {
                            return [2 /*return*/, undefined];
                        }
                        return [4 /*yield*/, this.mediaService.getMediaUrl(id, 'preview').toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    BlogService.prototype.getActiveCategories = function (blogCategories, postCategories) {
        return blogCategories.filter(function (cat) { return (postCategories.includes(cat.name) ? cat.name && cat.status === 'active' : undefined); });
    };
    BlogService.prototype.equalizeHeights = function (selector) {
        if (selector === void 0) { selector = '.single-post__content'; }
        var e_1, _a, e_2, _b;
        var elements = $(selector);
        var maxHeight = 0;
        try {
            for (var elements_1 = tslib_1.__values(elements), elements_1_1 = elements_1.next(); !elements_1_1.done; elements_1_1 = elements_1.next()) {
                var post = elements_1_1.value;
                if (maxHeight === 0 || maxHeight < post.offsetHeight) {
                    maxHeight = post.offsetHeight;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (elements_1_1 && !elements_1_1.done && (_a = elements_1.return)) _a.call(elements_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        try {
            for (var elements_2 = tslib_1.__values(elements), elements_2_1 = elements_2.next(); !elements_2_1.done; elements_2_1 = elements_2.next()) {
                var post = elements_2_1.value;
                post.style.height = maxHeight + "px";
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (elements_2_1 && !elements_2_1.done && (_b = elements_2.return)) _b.call(elements_2);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    BlogService.prototype.getColoredCategories = function (blogCategories, postCategories) {
        var e_3, _a;
        if (!blogCategories || !postCategories) {
            return '';
        }
        var coloredCategories = [];
        var _loop_1 = function (category) {
            var categoryArray = [];
            if (typeof category === 'string') {
                categoryArray = blogCategories.filter(function (cat) { return cat.name.toUpperCase() === category.toUpperCase(); });
            }
            else {
                categoryArray = blogCategories.filter(function (cat) { return cat.name.toUpperCase() === category.name.toUpperCase(); });
            }
            if (!categoryArray[0]) {
                return "continue";
            }
            var textColor = categoryArray[0].name_color ? rgbaObjectToString(categoryArray[0].name_color) : 'black';
            coloredCategories.push("<span style=\"color: " + textColor + "\">" + categoryArray[0].name + "</span>");
        };
        try {
            for (var postCategories_1 = tslib_1.__values(postCategories), postCategories_1_1 = postCategories_1.next(); !postCategories_1_1.done; postCategories_1_1 = postCategories_1.next()) {
                var category = postCategories_1_1.value;
                _loop_1(category);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (postCategories_1_1 && !postCategories_1_1.done && (_a = postCategories_1.return)) _a.call(postCategories_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return this.sanitizer.bypassSecurityTrustHtml(coloredCategories.join(' | '));
    };
    BlogService.prototype.getCategoryColor = function (blogCategories, postCategories) {
        var e_4, _a;
        if (!blogCategories || !postCategories) {
            return '';
        }
        var defaultCategoryColor = { r: 255, g: 255, b: 255, a: 0 };
        var categoryColor = '';
        var _loop_2 = function (category) {
            var categoryArray = [];
            if (typeof category === 'string') {
                categoryArray = blogCategories.filter(function (cat) { return cat.name.toUpperCase() === category.toUpperCase(); });
            }
            else {
                categoryArray = blogCategories.filter(function (cat) { return cat.name.toUpperCase() === category.name.toUpperCase(); });
            }
            if (!categoryArray[0]) {
                return "continue";
            }
            if (JSON.stringify(categoryArray[0].category_color) === JSON.stringify(defaultCategoryColor)) {
                return "continue";
            }
            categoryColor = rgbaObjectToString(categoryArray[0].category_color);
        };
        try {
            for (var postCategories_2 = tslib_1.__values(postCategories), postCategories_2_1 = postCategories_2.next(); !postCategories_2_1.done; postCategories_2_1 = postCategories_2.next()) {
                var category = postCategories_2_1.value;
                _loop_2(category);
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (postCategories_2_1 && !postCategories_2_1.done && (_a = postCategories_2.return)) _a.call(postCategories_2);
            }
            finally { if (e_4) throw e_4.error; }
        }
        return categoryColor;
    };
    BlogService.prototype.getFormattedDate = function (date) {
        var formattedDate = new Date(date).toLocaleDateString('de');
        if (formattedDate === 'Invalid Date') {
            return date;
        }
        return formattedDate;
    };
    BlogService.ngInjectableDef = i0.defineInjectable({ factory: function BlogService_Factory() { return new BlogService(i0.inject(i1.DomSanitizer), i0.inject(i2.MediaService)); }, token: BlogService, providedIn: "root" });
    return BlogService;
}());
export { BlogService };
