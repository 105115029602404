/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./transfer-standard.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../trip-detail-standard/trip-detail-standard-content/trip-detail-standard-content.component.ngfactory";
import * as i3 from "../trip-detail-standard/shared-hotel-helper.service";
import * as i4 from "../trip-detail-standard/trip-detail-standard-content/trip-detail-standard-content.component";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../../_services/nova-api.service";
import * as i7 from "../../../_components/nova-text-gallery/nova-text-gallery.component.ngfactory";
import * as i8 from "../../../_components/nova-text-gallery/nova-text-gallery.component";
import * as i9 from "../../../_services/uievent-handler.service";
import * as i10 from "@angular/common";
import * as i11 from "./transfer-standard.component";
import * as i12 from "@angular/router";
var styles_PageTransferStandardComponent = [i0.styles];
var RenderType_PageTransferStandardComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PageTransferStandardComponent, data: {} });
export { RenderType_PageTransferStandardComponent as RenderType_PageTransferStandardComponent };
export function View_PageTransferStandardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "page page-transfer-standard"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-trip-detail-standard-content", [], null, null, null, i2.View_TripDetailStandardContentComponent_0, i2.RenderType_TripDetailStandardContentComponent)), i1.ɵprd(512, null, i3.SharedHotelHelperService, i3.SharedHotelHelperService, []), i1.ɵdid(3, 4440064, null, 0, i4.TripDetailStandardContentComponent, [i5.DomSanitizer, i1.PLATFORM_ID, i3.SharedHotelHelperService, i6.NovaAPIService], { data: [0, "data"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "nova-text-gallery", [], null, null, null, i7.View_NovaTextGalleryComponent_0, i7.RenderType_NovaTextGalleryComponent)), i1.ɵdid(5, 49152, [["gallery", 4]], 0, i8.NovaTextGalleryComponent, [i9.UIEventHandlerService, i1.Renderer2, i10.DOCUMENT], { medias: [0, "medias"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.data; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.data.textImages; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "page-", _co.id, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_PageTransferStandardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_PageTransferStandardComponent_0, RenderType_PageTransferStandardComponent)), i1.ɵdid(1, 49152, null, 0, i11.PageTransferStandardComponent, [i6.NovaAPIService, i9.UIEventHandlerService, i5.Meta, i5.Title, i12.ActivatedRoute, i12.Router], null, null)], null, null); }
var PageTransferStandardComponentNgFactory = i1.ɵccf("ng-component", i11.PageTransferStandardComponent, View_PageTransferStandardComponent_Host_0, {}, {}, []);
export { PageTransferStandardComponentNgFactory as PageTransferStandardComponentNgFactory };
