/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nova-budapest-overlay-privacy.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./nova-budapest-overlay-privacy.component";
import * as i5 from "../../../_services/nova-api.service";
import * as i6 from "../../../_services/magazine.service";
var styles_NovaBudapestOverlayPrivacyComponent = [i0.styles];
var RenderType_NovaBudapestOverlayPrivacyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NovaBudapestOverlayPrivacyComponent, data: {} });
export { RenderType_NovaBudapestOverlayPrivacyComponent as RenderType_NovaBudapestOverlayPrivacyComponent };
function View_NovaBudapestOverlayPrivacyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "privacy-link"], ["target", "_blank"]], [[4, "font-family", null], [8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.font; var currVal_1 = _co.termsUrl; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("PRIVACY_POLICY")); _ck(_v, 1, 0, currVal_2); }); }
function View_NovaBudapestOverlayPrivacyComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[4, "font-family", null], [8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.font; var currVal_1 = _co.termsText; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_NovaBudapestOverlayPrivacyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [], [[4, "font-family", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaBudapestOverlayPrivacyComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaBudapestOverlayPrivacyComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.termsUrl; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.termsText; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.font; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.termsTitle; _ck(_v, 1, 0, currVal_1); }); }
export function View_NovaBudapestOverlayPrivacyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-budapest-overlay-privacy", [], null, null, null, View_NovaBudapestOverlayPrivacyComponent_0, RenderType_NovaBudapestOverlayPrivacyComponent)), i1.ɵdid(1, 573440, null, 0, i4.NovaBudapestOverlayPrivacyComponent, [i5.NovaAPIService, i6.MagazineService], null, null)], null, null); }
var NovaBudapestOverlayPrivacyComponentNgFactory = i1.ɵccf("nova-budapest-overlay-privacy", i4.NovaBudapestOverlayPrivacyComponent, View_NovaBudapestOverlayPrivacyComponent_Host_0, { magazine: "magazine", issue: "issue", font: "font" }, {}, []);
export { NovaBudapestOverlayPrivacyComponentNgFactory as NovaBudapestOverlayPrivacyComponentNgFactory };
