import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    PLATFORM_ID,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import { SharedHotelHelperService } from '../hotel-detail-standard/shared-hotel-helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AfterContentInit } from '@angular/core/src/metadata/lifecycle_hooks';
import { isPlatformBrowser } from '@angular/common';

@Component({
    templateUrl: './highlights-der-woche.component.html',
    styleUrls: ['./highlights-der-woche.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [SharedHotelHelperService]
})
@NovaTemplate('PageHighlightsDerWocheComponent')
export class PageHighlightsDerWocheComponent extends Page implements OnInit, AfterContentInit {
    @ViewChild('font_size') private fontSizeElement: ElementRef;
    @ViewChildren('font_size_bold') private fontSizeBoldElements: QueryList<ElementRef>;
    isBrowser: boolean = isPlatformBrowser(this.platformId);

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public route: ActivatedRoute,
        public router: Router,
        public hotelHelper: SharedHotelHelperService,
        public media: MediaService,
        public title: Title,
        private renderer: Renderer2,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    ngOnInit(): void {
        this.data.top_hotels.forEach(content => {
            return this.hotelHelper.handleTagInputs(content);
        });
    }

    ngAfterContentInit() {
        if (this.data['font']) {
            this.nova.getFont(this.data['font']).subscribe(fontData => {
                this.renderer.setStyle(this.fontSizeElement.nativeElement, 'font-family', fontData.name);
            });
        }
        if (this.data['font_bold']) {
            this.nova.getFont(this.data['font_bold']).subscribe(fontData => {
                this.fontSizeBoldElements.toArray().map(element => {
                    this.renderer.setStyle(element.nativeElement, 'font-family', fontData.name);
                });
            });
        }
    }

    scroll(el: HTMLElement) {
        el.scrollIntoView({ behavior: 'smooth' });
    }

    getVersion() {
        if (this.uiEventHandler.isMobile()) {
            return 'vertical-wide';
        } else {
            return 'horizontal-wide';
        }
    }

    goToUrl(url: string) {
        if (this.isBrowser) {
            window.open(url);
        }
    }

    getWindowHeight() {
        if (this.isBrowser) {
            return window.innerHeight + 'px';
        }
    }
}
