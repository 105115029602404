import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
var NovaContactFormComponent = /** @class */ (function () {
    function NovaContactFormComponent(fb) {
        this.fb = fb;
        this.myRecaptcha = new FormControl(false);
        this.submitted = false;
        this.isSubmitting = false;
        this.submitSuccess = false;
        this.errors = [];
        this.useCaptcha = false;
        this.eventListenerIntercept = function (event) { return event.stopImmediatePropagation(); };
    }
    NovaContactFormComponent.prototype.ngOnInit = function () {
        var e_1, _a, e_2, _b;
        this.fontBold = "'" + this.data.fonts[0] + "'";
        this.fontRegular = "'" + this.data.fonts[1] + "'";
        var group = tslib_1.__assign({}, (this.useCaptcha ? { recaptcha: this.myRecaptcha } : {}));
        this.formTemplate = tslib_1.__assign({}, (this.data.dynformelems ? { topSection: this.data.dynformelems } : {}), this.data.contactForm);
        if (this.data.dynformelems && this.data.dynformelems.fontfamily) {
            this.fontBold = "'" + this.data.dynformelems.fontfamily + "'";
            this.fontRegular = "'" + this.data.dynformelems.fontfamily + "'";
        }
        var emailToField = this.data.providerelems.field;
        var ta = this.data['travelAgency'];
        if (ta && ta['email'] && !emailToField['value']) {
            emailToField['value'] = ta['email'];
            emailToField['disabled'] = 'true';
        }
        // Add emailTo field to form if not already present
        this.formTemplate.topSection.field.some(function (field) { return field.id === 'emailto'; })
            ? null
            : this.formTemplate.topSection.field.unshift(emailToField);
        delete this.formTemplate['messages'];
        delete this.formTemplate['fontfamily'];
        delete this.formTemplate['channel'];
        try {
            for (var _c = tslib_1.__values(Object.entries(this.formTemplate)), _d = _c.next(); !_d.done; _d = _c.next()) {
                var section = _d.value;
                var sectionFields = {};
                try {
                    for (var _e = tslib_1.__values(section[1].field), _f = _e.next(); !_f.done; _f = _e.next()) {
                        var field = _f.value;
                        var validators = tslib_1.__spread((field.required === 'true' && field.type !== 'checkbox' ? [Validators.required] : ['']), (field.type === 'email' ? [Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)] : ['']), (field.type === 'checkbox' && field.required === 'true' ? [Validators.requiredTrue] : [''])).filter(function (el) { return el; });
                        var value = field.type === 'select' ? field.options[0] : field.value;
                        sectionFields[field.id] = [value || '', validators];
                        if ('subfield' in field) {
                            // children
                            sectionFields[field.subfield.id] = this.fb.array([]);
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                group[section[0]] = this.fb.group(sectionFields);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.form = this.fb.group(group);
        this.onUpdate();
        window.addEventListener('keyup', this.eventListenerIntercept, true);
    };
    NovaContactFormComponent.prototype.ngOnDestroy = function () {
        window.removeEventListener('keyup', this.eventListenerIntercept, true);
    };
    NovaContactFormComponent.prototype.onUpdate = function () {
        var _this = this;
        this.form.get('travelinfo.childpax').valueChanges.subscribe(function (val) {
            var childAges = _this.form.get('travelinfo.childage').value;
            if (childAges.length > val) {
                while (_this.children.length > val) {
                    _this.children.removeAt(_this.children.length - 1);
                }
            }
            else {
                for (var index = childAges.length; index < val; index++) {
                    _this.children.push(_this.newChild());
                }
            }
        });
    };
    NovaContactFormComponent.prototype.getFormattedDate = function (date) {
        var d = new Date(date);
        return d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear();
    };
    NovaContactFormComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (!this.form.valid) {
            console.error('Form has errors');
            return;
        }
        // Mock stuff
        var urlParams = new URLSearchParams(document.location.search.substring(1));
        var mockApiUrl = urlParams.get('mockApiUrl');
        var mockAddress = urlParams.get('mockAddress');
        var mockChannel = urlParams.get('mockChannel');
        this.isSubmitting = true;
        var formValue = this.form.getRawValue();
        formValue['travelinfo']['depdate'] = this.getFormattedDate(formValue['travelinfo']['depdate']);
        formValue['travelinfo']['retdate'] = this.getFormattedDate(formValue['travelinfo']['retdate']);
        // We don't need this on the other side
        if (this.useCaptcha) {
            delete formValue.recaptcha;
        }
        var formData = tslib_1.__assign({}, (mockAddress && { mockAddress: mockAddress }), { channel: mockChannel || this.data.contactForm['channel'] }, formValue['travelinfo'], formValue['contactdata'], formValue['topSection']);
        this.errors = [];
        fetch(mockApiUrl || 'https://fti-mailing.novamag.de/', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(function (res) {
            if (!res.ok) {
                throw new Error(res.statusText);
            }
            return res.json();
        })
            .then(function () {
            _this.errors = [];
            _this.isSubmitting = false;
            _this.submitSuccess = true;
        })
            .catch(function (error) {
            console.error(error);
            _this.isSubmitting = false;
            _this.errors = tslib_1.__spread(_this.errors, [error]);
            _this.submitSuccess = false;
        });
    };
    Object.defineProperty(NovaContactFormComponent.prototype, "children", {
        get: function () {
            return this.form.get('travelinfo.childage');
        },
        enumerable: true,
        configurable: true
    });
    NovaContactFormComponent.prototype.newChild = function () {
        return this.fb.group({
            age: '0'
        });
    };
    NovaContactFormComponent.prototype.getFormSection = function (name) {
        return this.form.get(name);
    };
    NovaContactFormComponent.prototype.onCaptchaScriptError = function () {
        console.error('Could not load Google reCAPTCHA');
    };
    return NovaContactFormComponent;
}());
export { NovaContactFormComponent };
