import * as tslib_1 from "tslib";
import { assignIn } from 'lodash';
var RenderService = /** @class */ (function () {
    function RenderService() {
        this.foundFonts = [];
    }
    RenderService.prototype.render = function (output) {
        var _this = this;
        if (typeof window === 'undefined') {
            return output;
        }
        this.foundImages = [];
        this.foundMedia = [];
        if (!output) {
            return '';
        }
        var elm = $('<div>' + output + '</div>');
        var styleElm = elm.find('[style]');
        $.each(styleElm, function (_, val) {
            var font = $(val).css('font-family');
            if (font) {
                _this.foundFonts.push(font.replace(/['"]+/g, ''));
            }
        });
        $.each(elm.find('video'), function (_, val) {
            if ($(val).hasClass('loaded'))
                return;
            if ($(val).data('video-id')) {
                _this.foundMedia.push($(val).data('video-id'));
            }
            if ($(val).attr('poster')) {
                $(val).removeAttr('poster');
            }
            var filter = false;
            if ($(val).data('type') === 'internal') {
                $(val).append('<source src="' + $(val).data('link') + '" type="video/mp4">');
            }
            else if ($(val).data('type') === 'vimeo') {
                $(val).hide();
                var url = $(val).data('link');
                url = url.replace('//vimeo.com/', '//player.vimeo.com/video/');
                $(val)
                    .parent()
                    .append('<span class="embed-container"><span class="hiddenlayer"></span><iframe scrolling="no" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen src="' +
                    url +
                    '"></iframe></span>');
            }
            else if ($(val).data('type') === 'youtube') {
                $(val).hide();
                var url = $(val).data('link');
                url = url.replace('watch?v=', 'embed/') + '?modestbranding=1&showinfo=0&playsinline=1';
                $(val)
                    .parent()
                    .append('<span class="embed-container"><span class="hiddenlayer"></span><iframe scrolling="no" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen src="' +
                    url +
                    '"></iframe></span>');
            }
            $(val)
                .closest('p')
                .addClass('video-p');
            if ($(val).data('size') === 'big') {
                $(val)
                    .closest('p')
                    .addClass('big-parent');
                filter = true;
            }
            $(val)
                .closest('.fr-video')
                .wrap('<span class="figure video"><span class="inner"></span></span>');
            if ($(val)
                .closest('.fr-video')
                .data('caption-titel')) {
                $(val).attr('data-caption-titel', $(val)
                    .closest('.fr-video')
                    .data('caption-titel'));
            }
            if ($(val)
                .closest('.fr-video')
                .data('caption-description')) {
                $(val).attr('data-caption-description', $(val)
                    .closest('.fr-video')
                    .data('caption-description'));
            }
            if (filter) {
                $(val)
                    .closest('p')
                    .contents()
                    .filter(function () {
                    return this.nodeName !== 'FIGURE' && this.nodeName !== 'SPAN';
                })
                    .wrap('<p></p>');
            }
            $(val).addClass('loaded');
        });
        $.each(elm.find('table'), function (_key, val) {
            $(val).wrap('<div class="table-wrapper"></div>');
        });
        var imgElm = elm.find('img');
        $.each(imgElm, function (_key, val) {
            var url = $(val).attr('src');
            var parser = document.createElement('a');
            parser.href = url;
            if ($(val).data('source')) {
                _this.foundMedia.push($(val).data('source'));
            }
            if ($(val).data('expand') !== false && !$(val).data('link')) {
                if ($(val).data('source') && $(val).data('version') !== 'original') {
                    _this.foundImages.push({
                        media: $(val).data('source'),
                        name: $(val).data('caption-titel'),
                        description: $(val).data('caption-description'),
                        original: false
                    });
                }
                else {
                    _this.foundImages.push({
                        url: $(val).attr('src'),
                        media: $(val).data('source'),
                        name: $(val).data('caption-titel'),
                        description: $(val).data('caption-description'),
                        original: true
                    });
                }
            }
            var classes = '';
            var style = {};
            var innerStyle = {};
            var filter = false;
            var width = $(val)[0].style.width;
            if (width) {
                var setWith = true;
                $(val).css('width', '');
                if (width.endsWith('%')) {
                    var widthP = width.substring(0, width.length - 1);
                    if (parseInt(widthP, 10) >= 100) {
                        classes += ' big';
                        setWith = false;
                        $(val)
                            .closest('p')
                            .addClass('big-parent');
                        filter = true;
                    }
                }
                if (setWith) {
                    assignIn(style, { width: width });
                }
            }
            if ($(val).hasClass('fr-dii')) {
                classes += ' inline';
            }
            if ($(val).hasClass('fr-dib') && !$(val).hasClass('fr-fil') && !$(val).hasClass('fr-fir')) {
                classes += ' center';
            }
            if ($(val).hasClass('fr-fil') && !$(val).hasClass('fr-dib')) {
                classes += ' lefti';
            }
            if ($(val).hasClass('fr-fir') && !$(val).hasClass('fr-dib')) {
                classes += ' righti';
                if (width) {
                    assignIn(style, { width: width });
                    $(val).css('width', '');
                }
            }
            if ($(val).hasClass('fr-dib') && $(val).hasClass('fr-fir')) {
                assignIn(style, { width: '100%', 'text-align': 'right' });
                assignIn(innerStyle, { width: width, display: 'inline-block' });
            }
            if ($(val).data('expand') !== false && !$(val).data('link')) {
                classes += ' hover';
            }
            if ($(val).data('link')) {
                classes += ' link';
            }
            var toCss = function (object) {
                return Object.entries(object).reduce(function (styleString, _a) {
                    var _b = tslib_1.__read(_a, 2), propName = _b[0], propValue = _b[1];
                    return "" + styleString + propName + ": " + propValue + "; ";
                }, '');
            };
            if ($(val)
                .parent()
                .hasClass('inner')) {
                return;
            }
            var elmInner = $(val).wrap("<span class=\"figure img " + classes + "\" style=\"" + toCss(style) + "\">\n                    <span class=\"inner\" style=\"" + toCss(innerStyle) + "\"></span>\n                </span>");
            if ($(val).data('expand') === true && !$(val).data('link')) {
                elmInner.closest('.inner').append('<span class="hover"><i class="fa fa-expand"></i></span>');
            }
            if (filter) {
                $(val)
                    .closest('p')
                    .contents()
                    .filter(function () {
                    return this.nodeName !== 'FIGURE' && this.nodeName !== 'SPAN';
                })
                    .wrap('<p></p>');
            }
        });
        return elm.html() || '';
    };
    return RenderService;
}());
export { RenderService };
