import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './background-clean.component.html',
    styleUrls: ['./background-clean.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageBackgroundCleanComponent')
export class PageBackgroundCleanComponent extends Page implements OnInit {
    public logoStyle;
    public flexboxStyle;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    ngOnInit() {
        const logoStyle = {};
        const flexboxStyle = {};

        const posLogo = this.data['contentPosition'];
        const posLogoArr = posLogo.split('-');

        if (posLogoArr[0] === 'top') {
            flexboxStyle['justify-content'] = 'flex-start';
        } else if (posLogoArr[0] === 'bottom') {
            flexboxStyle['justify-content'] = 'flex-end';
        } else if (posLogoArr[0] === 'center') {
            flexboxStyle['justify-content'] = 'center';
        }

        if (posLogoArr[1] === 'left') {
            logoStyle['align-self'] = 'flex-start';
        } else if (posLogoArr[1] === 'right') {
            logoStyle['align-self'] = 'flex-end';
        } else if (posLogoArr[1] === 'center') {
            logoStyle['align-self'] = 'center';
        }

        this.logoStyle = logoStyle;
        this.flexboxStyle = flexboxStyle;
    }
}
