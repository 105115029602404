import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ColorFormatter } from '../../../novamag-api/Types';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
var PageArticleTeaserComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageArticleTeaserComponent, _super);
    function PageArticleTeaserComponent(nova, uiEventHandler, sanitizer, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.sanitizer = sanitizer;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        _this.hide = false;
        return _this;
    }
    PageArticleTeaserComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.data['header_fonts']) {
            this.nova.getFont(this.data['header_fonts']).subscribe(function (fontData) {
                _this.fonts = fontData.name;
            });
        }
    };
    PageArticleTeaserComponent.prototype.boxStyle = function () {
        var arr = '';
        if (this.data['background_box_color']) {
            var c = new ColorFormatter(this.data['background_box_color']);
            arr += 'background-color: ' + c.rgba() + ';';
        }
        return this.convertCSS(arr);
    };
    PageArticleTeaserComponent.prototype.boxStyleBottom = function () {
        var arr = '';
        if (this.data['background_bottom_box_color']) {
            var c = new ColorFormatter(this.data['background_bottom_box_color']);
            arr += 'background-color: ' + c.rgba() + ';';
        }
        return this.convertCSS(arr);
    };
    PageArticleTeaserComponent.prototype.headerStyle = function () {
        var arr = '';
        if (this.data['header_color']) {
            var c = new ColorFormatter(this.data['header_color']);
            arr += 'color: ' + c.rgb() + ';';
        }
        if (this.data['header_bgColor']) {
            var c = new ColorFormatter(this.data['header_bgColor']);
            arr += 'background-color: ' + c.rgba() + ';';
        }
        if (this.fonts !== '') {
            arr += 'font-family: ' + this.fonts + ';';
        }
        return this.convertCSS(arr);
    };
    PageArticleTeaserComponent.prototype.convertCSS = function (css) {
        var input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {};
            var attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    };
    PageArticleTeaserComponent.prototype.setMyStyles = function (obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    };
    PageArticleTeaserComponent = tslib_1.__decorate([
        NovaTemplate('PageArticleTeaserComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            DomSanitizer,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], PageArticleTeaserComponent);
    return PageArticleTeaserComponent;
}(Page));
export { PageArticleTeaserComponent };
