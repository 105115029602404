import * as tslib_1 from "tslib";
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
var PageBackgroundAcrossLeftComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageBackgroundAcrossLeftComponent, _super);
    function PageBackgroundAcrossLeftComponent(nova, uiEventHandler, sanitizer, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.sanitizer = sanitizer;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        return _this;
    }
    PageBackgroundAcrossLeftComponent.prototype.getGradient = function () {
        var trans = tslib_1.__assign({}, this.data['box_color']);
        trans.a = 0;
        return this.sanitizer.bypassSecurityTrustStyle('linear-gradient(' + this.getColor(this.data['box_color']) + ', ' + this.getColor(trans) + ')');
    };
    PageBackgroundAcrossLeftComponent = tslib_1.__decorate([
        NovaTemplate('PageBackgroundAcrossLeftComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            DomSanitizer,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], PageBackgroundAcrossLeftComponent);
    return PageBackgroundAcrossLeftComponent;
}(Page));
export { PageBackgroundAcrossLeftComponent };
