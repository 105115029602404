/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../nova-budapest-overlay-pages/nova-budapest-overlay-pages.component.scss.shim.ngstyle";
import * as i1 from "./nova-budapest-overlay-search.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/forms";
import * as i6 from "./nova-budapest-overlay-search.component";
import * as i7 from "../../../_services/nova-api.service";
import * as i8 from "../../../_services/magazine.service";
import * as i9 from "../../../_services/uievent-handler.service";
import * as i10 from "@angular/router";
var styles_NovaBudapestOverlaySearchComponent = [i0.styles, i1.styles];
var RenderType_NovaBudapestOverlaySearchComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_NovaBudapestOverlaySearchComponent, data: {} });
export { RenderType_NovaBudapestOverlaySearchComponent as RenderType_NovaBudapestOverlaySearchComponent };
function View_NovaBudapestOverlaySearchComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.thumbnail.url; _ck(_v, 0, 0, currVal_0); }); }
function View_NovaBudapestOverlaySearchComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = (((("assets/img/templates/" + ((_v.parent.context.$implicit.type === "content") ? "contents" : "pages")) + "/") + _v.parent.context.$implicit.templateName) + ".svg"); _ck(_v, 0, 0, currVal_0); }); }
function View_NovaBudapestOverlaySearchComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 12, "div", [["class", "item"], ["tabIndex", "-1"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 11, "div", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goTo((_v.context.$implicit.key + 1), _v.context.$implicit.subKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 0, "div", [["class", "overlay"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_NovaBudapestOverlaySearchComponent_2)), i2.ɵdid(4, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_NovaBudapestOverlaySearchComponent_3)), i2.ɵdid(6, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(7, 0, null, null, 5, "span", [], null, null, null, null, null)), i2.ɵdid(8, 278528, null, 0, i3.NgStyle, [i2.KeyValueDiffers, i2.ElementRef, i2.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i2.ɵpod(9, { "fontFamily": 0 }), (_l()(), i2.ɵeld(10, 0, null, null, 1, "b", [], [[4, "background-color", null]], null, null, null, null)), (_l()(), i2.ɵted(11, null, ["", ""])), (_l()(), i2.ɵted(12, null, [" ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_v.context.$implicit.thumbnail && !_co.nova.isThumbLoading(_v.context.$implicit.thumbnail)); _ck(_v, 4, 0, currVal_1); var currVal_2 = (!_v.context.$implicit.thumbnail || _co.nova.isThumbLoading(_v.context.$implicit.thumbnail)); _ck(_v, 6, 0, currVal_2); var currVal_3 = _ck(_v, 9, 0, _co.font); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentPage == _v.context.$implicit.key); _ck(_v, 1, 0, currVal_0); var currVal_4 = _co.isActive((_co.currentPage == _v.context.$implicit.key)); _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.pad((_v.context.$implicit.key + 1), 2); _ck(_v, 11, 0, currVal_5); var currVal_6 = _co.uiEventHandler.getContentTableEntryName(_v.context.$implicit); _ck(_v, 12, 0, currVal_6); }); }
export function View_NovaBudapestOverlaySearchComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "h2", [], [[4, "font-family", null]], null, null, null, null)), (_l()(), i2.ɵted(1, null, ["", ""])), i2.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i2.ChangeDetectorRef]), (_l()(), i2.ɵeld(3, 0, null, null, 6, "input", [["class", "form-control search-input"], ["type", "text"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i2.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i2.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i2.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i2.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = (_co.getPages($event) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.searchField = $event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i2.ɵdid(4, 16384, null, 0, i5.DefaultValueAccessor, [i2.Renderer2, i2.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i2.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i2.ɵdid(6, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i2.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i2.ɵdid(8, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), i2.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i2.ChangeDetectorRef]), (_l()(), i2.ɵeld(10, 0, null, null, 2, "div", [["class", "items"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_NovaBudapestOverlaySearchComponent_1)), i2.ɵdid(12, 278528, null, 0, i3.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_10 = _co.searchField; _ck(_v, 6, 0, currVal_10); var currVal_11 = _co.pages; _ck(_v, 12, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.font; _ck(_v, 0, 0, currVal_0); var currVal_1 = i2.ɵunv(_v, 1, 0, i2.ɵnov(_v, 2).transform("SEARCH")); _ck(_v, 1, 0, currVal_1); var currVal_2 = i2.ɵunv(_v, 3, 0, i2.ɵnov(_v, 9).transform("SEARCH")); var currVal_3 = i2.ɵnov(_v, 8).ngClassUntouched; var currVal_4 = i2.ɵnov(_v, 8).ngClassTouched; var currVal_5 = i2.ɵnov(_v, 8).ngClassPristine; var currVal_6 = i2.ɵnov(_v, 8).ngClassDirty; var currVal_7 = i2.ɵnov(_v, 8).ngClassValid; var currVal_8 = i2.ɵnov(_v, 8).ngClassInvalid; var currVal_9 = i2.ɵnov(_v, 8).ngClassPending; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_NovaBudapestOverlaySearchComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "nova-budapest-overlay-search", [], null, null, null, View_NovaBudapestOverlaySearchComponent_0, RenderType_NovaBudapestOverlaySearchComponent)), i2.ɵdid(1, 114688, null, 0, i6.NovaBudapestOverlaySearchComponent, [i7.NovaAPIService, i8.MagazineService, i9.UIEventHandlerService, i10.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NovaBudapestOverlaySearchComponentNgFactory = i2.ɵccf("nova-budapest-overlay-search", i6.NovaBudapestOverlaySearchComponent, View_NovaBudapestOverlaySearchComponent_Host_0, { font: "font", borderColor: "borderColor" }, { pageChange: "pageChange" }, []);
export { NovaBudapestOverlaySearchComponentNgFactory as NovaBudapestOverlaySearchComponentNgFactory };
