import { Component, OnChanges, Input } from '@angular/core';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { MagazineService } from 'app/_services/magazine.service';
@Component({
    selector: 'nova-fti-overlay-imprint',
    templateUrl: './nova-fti-overlay-imprint.component.html',
    styleUrls: ['./nova-fti-overlay-imprint.component.scss']
})
export class NovaFtiOverlayImprintComponent implements OnChanges {
    @Input() magazine;
    @Input() issue;
    @Input() public font;

    public imprintTitle: string;
    public imprintText;

    constructor(public nova: NovaAPIService, public mag: MagazineService) {}

    ngOnChanges() {
        this.imprintTitle = this.issue.imprintTitle || this.magazine.imprintTitle;
        this.imprintText = (this.issue.imprintText || this.magazine.imprintText).replace(/(?:\r\n|\r|\n)/g, '<br>');
    }
}
