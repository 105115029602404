<div id="page-{{ id }}" class="page page-article-scroll-columns">
	<nova-bg [data]="data" [forceVideo]="true"></nova-bg>
	<nova-scrollbar-holder>
		<div class="scroller">
            <nova-mobile-img [data]="data" class="show-for-small-only left"></nova-mobile-img>
			<div
				class="bg animated fadeInUp clear"
				[style.background-color]="getColor(data['box_color'])"
				[hidden]="!data.textLeft && !data.textRight && !data.header && !data.subHeader && !data.label"
			>
				<nova-title [data]="data"></nova-title>

				<div class="columns">
					<nova-text-element
						class="con"
						[data]="data.textLeft"
						[myid]="data._id"
						(openGallery)="gallery.openSlider($event)"
						[style.border-color]="getColor(data['verticalLineColor'])"
					></nova-text-element>

					<nova-text-element
						class="con"
						[data]="data.textRight"
						[myid]="data._id"
						(openGallery)="gallery.openSlider($event)"
					></nova-text-element>
				</div>

				<nova-content-links
					class="content-links"
					*ngIf="data.links && data.links.length > 0"
					[floors]="data.links"
				></nova-content-links>
			</div>
		</div>
	</nova-scrollbar-holder>
	<nova-text-gallery #gallery [medias]="data.textImages" [myid]="data._id"></nova-text-gallery>
</div>
