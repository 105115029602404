<div *ngIf="video && (uiEventHandler.isMobile() || uiEventHandler.isTablet())" #bgElm>
	<video #videoBgElm *ngIf="video && !fallback && mediaData && mediaService.isLocalVideo(mediaData)" playsinline muted
		autoplay loop [poster]="url">
		<source *ngFor="let src of videoSources" [src]="src.src" [type]="src.type" />
	</video>

	<div *ngIf="video && !fallback && mediaData && mediaService.isVimeo(mediaData)" class="plyr__video-embed">
		<iframe #videoBgElm [src]="trustedUrl" scrolling="no" frameborder="0" webkitAllowFullScreen mozallowfullscreen
			allowFullScreen width="100%" height="100%"></iframe>
	</div>

</div>

<img *ngIf="!video && url" width="1920px" height="1080px" [src]="url" />
<span *ngIf="!video && mediaData && mediaData.copyright" class="img-copyright">&copy; {{ mediaData.copyright }}</span>
<ng-content></ng-content>
