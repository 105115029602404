<div id="terms" [hidden]="!uiEventHandler.showTerms">
  <div>
    <nova-nav-item class="icon-cancel my-close-icon" (click)="hide()" style="position: sticky; float: right;">
    </nova-nav-item>
    <nova-text-element 
        class="con"
        [data]="termsText"
    ></nova-text-element>
    <a class="privacy-link" [href]="termsUrl" target="_blank" *ngIf="termsUrl">{{ 'PRIVACY_POLICY' | translate }}</a>
  </div>
</div>