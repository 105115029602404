import * as tslib_1 from "tslib";
import { DomSanitizer } from '@angular/platform-browser';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { PageVideoCinemaComponent } from 'app/pages/templates/video-cinema/video-cinema.component';
import { ActivatedRoute, Router } from '@angular/router';
var ServerVideoCinemaComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ServerVideoCinemaComponent, _super);
    function ServerVideoCinemaComponent(nova, sanitizer, renderer, uiEventHandler, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, meta, title, route, router) || this;
        _this.nova = nova;
        _this.sanitizer = sanitizer;
        _this.renderer = renderer;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        return _this;
    }
    ServerVideoCinemaComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.addOgTags();
    };
    ServerVideoCinemaComponent = tslib_1.__decorate([
        NovaTemplate('ServerVideoCinemaComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            DomSanitizer,
            Renderer2,
            UIEventHandlerService,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], ServerVideoCinemaComponent);
    return ServerVideoCinemaComponent;
}(PageVideoCinemaComponent));
export { ServerVideoCinemaComponent };
