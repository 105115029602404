import { Component, OnChanges, Input } from '@angular/core';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { MagazineService } from 'app/_services/magazine.service';
@Component({
    selector: 'nova-london-overlay-imprint',
    templateUrl: './nova-london-overlay-imprint.component.html',
    styleUrls: ['./nova-london-overlay-imprint.component.scss']
})
export class NovaLondonOverlayImprintComponent implements OnChanges {
    @Input() magazine;
    @Input() issue;
    @Input() public font;

    public imprintText;

    constructor(public nova: NovaAPIService, public mag: MagazineService) {}

    ngOnChanges() {
        this.imprintText = this.issue.imprintText || this.magazine.imprintText;
    }
}
