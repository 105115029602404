import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
    HostListener,
    ElementRef,
    Renderer2
} from '@angular/core';
import { NovaAPIService } from '../../_services/nova-api.service';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { MagazineService } from 'app/_services/magazine.service';

// host[style] does not work with SSR

@Component({
    selector: 'nova-nav-item',
    template: '<ng-content></ng-content>',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NovaNavItemComponent implements OnInit, OnDestroy {
    @Input() public style = {};

    @Input() public activeStyle = {};

    @Input('forceClick') public forceClick: boolean = true;

    @Input('hover') public hoverable: boolean = true;

    @Input('inverted') public inverted: boolean = false;

    public nav;
    private sub: Subscription;

    public hover: boolean = false;
    public touch: boolean = false;

    public savedStyle;
    public hoverStyle;

    constructor(
        public nova: NovaAPIService,
        private mag: MagazineService,
        public ui: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public element: ElementRef,
        public renderer: Renderer2
    ) {}

    @HostListener('mouseenter', ['$event.target'])
    onMouseEnter() {
        this.hover = !this.inverted;
        this.getStyle();
    }

    @HostListener('mouseleave', ['$event.target'])
    onMouseLeave() {
        this.hover = this.inverted;
        this.getStyle();
    }

    @HostListener('touchstart', ['$event.target'])
    onTouchStart() {
        this.touch = !this.inverted;
        this.getStyle();
    }

    @HostListener('touchend', ['$event.target'])
    onTouchEnd(targetElement) {
        this.touch = this.inverted;
        if (this.forceClick) {
            targetElement.click();
        }
        this.getStyle();
    }

    ngOnInit() {
        this.hover = this.inverted;
        this.sub = this.mag.getMagazine().subscribe(data => {
            this.nav = data.navigation;
            this.afterInit();
            this.getStyle();
        });
    }

    afterInit() {}

    isTouch() {
        if (typeof window === undefined) {
            return false;
        }
        return !$('html').hasClass('no-touchevents');
    }

    getStyle() {
        if (this.hoverable && ((this.hover && !this.isTouch()) || this.touch)) {
            if (!this.activeStyle || !this.activeStyle['background-color']) {
                this.renderer.setStyle(
                    this.element.nativeElement,
                    'background-color',
                    this.ui.getColor(this.nav.backgroundHoverColor)
                );
            }
            if (!this.activeStyle || !this.activeStyle['color']) {
                this.renderer.setStyle(this.element.nativeElement, 'color', this.ui.getColor(this.nav.textHoverColor));
            }

            for (const key in this.style) {
                if (!this.activeStyle[key]) {
                    this.renderer.setStyle(this.element.nativeElement, key, this.style[key]);
                }
            }

            for (const key in this.activeStyle) {
                if (key) this.renderer.setStyle(this.element.nativeElement, key, this.activeStyle[key]);
            }
        } else {
            if (!this.style || !this.style['background-color']) {
                this.renderer.setStyle(
                    this.element.nativeElement,
                    'background-color',
                    this.ui.getColor(this.nav.backgroundColor)
                );
            }
            if (!this.style || !this.style['color']) {
                this.renderer.setStyle(this.element.nativeElement, 'color', this.ui.getColor(this.nav.textColor));
            }

            for (const key in this.style) {
                if (key) this.renderer.setStyle(this.element.nativeElement, key, this.style[key]);
            }
        }
    }

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe();
    }
}
