import { OnInit, EventEmitter } from '@angular/core';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { Router } from '@angular/router';
import { MediaService } from 'app/_services/media.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { SSRService } from 'app/_services/ssr.service';
var NovaMatrixComponent = /** @class */ (function () {
    function NovaMatrixComponent(nova, media, uiEventHandler, translate, router, ssr) {
        this.nova = nova;
        this.media = media;
        this.uiEventHandler = uiEventHandler;
        this.translate = translate;
        this.router = router;
        this.ssr = ssr;
        this.openImpressum = new EventEmitter();
        this.openTerms = new EventEmitter();
        this.clickWait = false;
        this.openurl = function (url) {
            window.open(url, '_blank');
        };
    }
    NovaMatrixComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.ssr.isBrowser && typeof window !== 'undefined') {
            if ($('html.touchevents').length === 0) {
                var timer_1;
                var timerSub_1;
                $(document).on('mouseenter', 'nav.right-nav nova-matrix .icon, nav.right-nav .matrix', function () {
                    var _this = this;
                    $('nav.right-nav .matrix').addClass('active');
                    if (timer_1) {
                        clearTimeout(timer_1);
                        timer_1 = null;
                    }
                    timer_1 = setTimeout(function () {
                        $('nav.right-nav .matrix').removeClass('active');
                    }, 400);
                    if ($(this)
                        .parent()
                        .parent()
                        .hasClass('sub') ||
                        $(this)
                            .parent()
                            .parent()
                            .hasClass('sub2')) {
                        $('nav.right-nav nova-matrix .icon').removeClass('active');
                        $(this)
                            .parent()
                            .parent()
                            .parent()
                            .addClass('active');
                        if (timerSub_1) {
                            clearTimeout(timerSub_1);
                            timerSub_1 = null;
                        }
                        timerSub_1 = setTimeout(function () {
                            $(_this)
                                .parent()
                                .parent()
                                .parent()
                                .removeClass('active');
                        }, 400);
                    }
                });
            }
            var menuClick_1 = false;
            $(document).click(function (event) {
                if (!$(event.target).closest('nav.right-nav .matrix').length) {
                    $('.touchevents nav.right-nav nova-matrix .icon, .touchevents nav.right-nav .matrix').removeClass('active');
                }
            });
            $(document).on('click touchend', 'nav.right-nav nova-matrix .icon, nav.right-nav .matrix', function (val) {
                if (!menuClick_1) {
                    menuClick_1 = true;
                    setTimeout(function () {
                        menuClick_1 = false;
                    }, 100);
                    if ($(val.currentTarget).data('close') === false) {
                        return;
                    }
                    if ($(val.currentTarget).find('.sub').length > 0) {
                        $(val.currentTarget).toggleClass('active');
                    }
                    else {
                        $('.touchevents nav.right-nav nova-matrix .icon, .touchevents nav.right-nav .matrix').removeClass('active');
                    }
                    if ($(val.currentTarget).attr('id') === 'openContentTable') {
                        _this.toggleContentTable();
                    }
                    if ($(val.currentTarget).attr('id') === 'openSearch') {
                        _this.toggleSearch();
                    }
                }
            });
        }
    };
    NovaMatrixComponent.prototype.increaseFont = function () {
        var _this = this;
        if (this.uiEventHandler.fontSize >= 4)
            return;
        if (this.uiEventHandler.column !== 'single' && this.uiEventHandler.columnizeEnabled) {
            this.uiEventHandler.textLoading = true;
        }
        this.uiEventHandler.fontSize += 0.2;
        setTimeout(function () {
            _this.uiEventHandler.onFontEvent(_this.uiEventHandler.fontSize);
        }, 50);
    };
    NovaMatrixComponent.prototype.decreaseFont = function () {
        var _this = this;
        if (this.uiEventHandler.fontSize <= 0.6)
            return;
        if (this.uiEventHandler.column !== 'single' && this.uiEventHandler.columnizeEnabled) {
            this.uiEventHandler.textLoading = true;
        }
        this.uiEventHandler.fontSize -= 0.2;
        setTimeout(function () {
            _this.uiEventHandler.onFontEvent(_this.uiEventHandler.fontSize);
        }, 50);
    };
    NovaMatrixComponent.prototype.printPdf = function () {
        this.nova.print();
    };
    NovaMatrixComponent.prototype.share = function (type) {
        var _this = this;
        var params = this.router.routerState.root.firstChild.params['value'];
        if (params['issueHandle']) {
            this.nova.getPageData().subscribe(function (data) {
                _this.realShare(type, data['data']);
            });
        }
        else {
            this.realShare(type);
        }
    };
    NovaMatrixComponent.prototype.realShare = function (type, data) {
        var _this = this;
        var shareUrl = window.location.href; // current page
        shareUrl = shareUrl.replace('#/', '');
        var encodedUrl = encodeURIComponent(shareUrl);
        this.translate.get(['MAIL_SUBJECT', 'MAIL_BODY']).subscribe(function (res) {
            var mailSubject = res['MAIL_SUBJECT'];
            var mailBody = res['MAIL_BODY'];
            // get data from current page
            var pageTitle = '';
            var title = _this.getTitle();
            // image url
            var media = '';
            if (data) {
                media = data.thumb;
                pageTitle = data.header;
            }
            if (type === 'mail') {
                window.location.href = 'mailto:?&subject=' + mailSubject + '&body=' + mailBody + shareUrl;
            }
            else if (type === 'whatsapp') {
                window.location.href = 'whatsapp://send?text=' + mailBody + shareUrl;
            }
            else {
                var host = '';
                if (type === 'xing') {
                    host = 'https://www.xing.com/spi/shares/new?url=' + encodedUrl;
                }
                else if (type === 'facebook') {
                    host = 'https://www.facebook.com/sharer/sharer.php?u=' + encodedUrl;
                    if (media) {
                        host += '&picture=' + media;
                    }
                    host += '&title=' + title + '&description=' + pageTitle;
                }
                else if (type === 'linkedin') {
                    host =
                        'https://www.linkedin.com/shareArticle?mini=true&title=' +
                            mailSubject +
                            '&summary=' +
                            mailBody +
                            '&source=&url=' +
                            shareUrl;
                }
                else if (type === 'twitter') {
                    host = 'https://twitter.com/intent/tweet?text=' + mailBody + shareUrl;
                }
                else if (type === 'pinterest') {
                    host =
                        'https://pinterest.com/pin/create/button/?url=' +
                            shareUrl +
                            '&description=' +
                            mailBody +
                            shareUrl +
                            '&media=' +
                            media;
                }
                window.open(host, '_blank');
            }
        });
    };
    NovaMatrixComponent.prototype.getTitle = function () {
        if (this.nova.issue) {
            return this.nova.issue.name;
        }
        else if (this.magazine) {
            return this.magazine.name;
        }
        return 'NO TITLE SET';
    };
    NovaMatrixComponent.prototype.columnClass = function () {
        if (this.uiEventHandler.column === 'columns') {
            return 'icon-text';
        }
        return 'icon-two-columns-layout';
    };
    NovaMatrixComponent.prototype.switchColumns = function () {
        this.uiEventHandler.columnChanged = true;
        if (this.uiEventHandler.column === 'columns') {
            this.uiEventHandler.column = 'single';
        }
        else {
            this.uiEventHandler.column = 'columns';
            this.uiEventHandler.textLoading = true;
        }
        this.uiEventHandler.onFontEvent(this.uiEventHandler.fontSize);
    };
    NovaMatrixComponent.prototype.toggleContentTable = function () {
        var _this = this;
        if (this.clickWait)
            return;
        this.clickWait = true;
        setTimeout(function () {
            _this.clickWait = false;
        }, 100);
        this.uiEventHandler.showContentTableFirstTime = true;
        this.uiEventHandler.showContentTable = !this.uiEventHandler.showContentTable;
    };
    NovaMatrixComponent.prototype.toggleSearch = function () {
        var _this = this;
        if (this.clickWait)
            return;
        this.clickWait = true;
        setTimeout(function () {
            _this.clickWait = false;
        }, 100);
        this.uiEventHandler.showSearch = !this.uiEventHandler.showSearch;
    };
    NovaMatrixComponent.prototype.getBrandingLink = function () {
        var url = environment['utmSource'];
        url += "/?utm_source=" + ((this.uiEventHandler.isMobile() && 'branding-mobile') || 'branding-desktop');
        url += "" + (typeof window !== 'undefined' && "&utm_medium=" + encodeURIComponent(window.location.href));
        return url;
    };
    NovaMatrixComponent.prototype.openContactForm = function () {
        var _this = this;
        if (this.clickWait)
            return;
        this.clickWait = true;
        setTimeout(function () {
            _this.clickWait = false;
        }, 100);
        this.uiEventHandler.showContactForm = !this.uiEventHandler.showContactForm;
    };
    NovaMatrixComponent.prototype.switchLang = function (lang) {
        var _this = this;
        var params = this.router.routerState.root.firstChild.params['value'];
        var oldLang = this.nova.lang;
        this.nova.lang = lang;
        this.translate.use(lang);
        this.nova.mapIssue();
        this.translate.get('CONTENT_TABLE').subscribe(function (res) {
            _this.uiEventHandler.CONTENT_TABLE = res;
        });
        if (params['issueHandle']) {
            var found_1;
            this.nova.issues.forEach(function (value) {
                if (!found_1 &&
                    value['languagesMap'][oldLang] &&
                    value['languagesMap'][oldLang].handle === params['issueHandle']) {
                    if (value['languagesMap'][lang]) {
                        found_1 = value['languagesMap'][lang].handle;
                    }
                }
            });
            if (!found_1) {
                found_1 = params['issueHandle'];
            }
            if (params['pageHandle']) {
                if (params['contentHandle']) {
                    this.router.navigate(['/', lang, found_1, params['pageHandle'], params['contentHandle']], {
                        queryParamsHandling: 'preserve'
                    });
                }
                else {
                    this.router.navigate(['/', lang, found_1, params['pageHandle']], { queryParamsHandling: 'preserve' });
                }
            }
            else {
                this.router.navigate(['/', lang, found_1], { queryParamsHandling: 'preserve' });
            }
        }
        else {
            this.router.navigate(['/', lang], { queryParamsHandling: 'preserve' });
        }
    };
    Object.defineProperty(NovaMatrixComponent.prototype, "isSeat", {
        get: function () {
            return this.uiEventHandler.customIcons;
        },
        enumerable: true,
        configurable: true
    });
    return NovaMatrixComponent;
}());
export { NovaMatrixComponent };
