import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
var PageTimelineRightComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageTimelineRightComponent, _super);
    function PageTimelineRightComponent(nova, uiEventHandler, sanitizer, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.sanitizer = sanitizer;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        return _this;
    }
    PageTimelineRightComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.data.blocks = this.data.blocks.map(function (block) {
            var left = 'transparent ' + _this.getColor(block['background_color']) + ' transparent transparent';
            var right = 'transparent transparent transparent ' + _this.getColor(block['background_color']);
            block.triangleLeft = _this.convertCSS(left);
            block.triangleRight = _this.convertCSS(right);
            return block;
        });
    };
    PageTimelineRightComponent.prototype.convertCSS = function (css) {
        var input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {}, attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    };
    PageTimelineRightComponent.prototype.setMyStyles = function (obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    };
    PageTimelineRightComponent = tslib_1.__decorate([
        NovaTemplate('PageTimelineRightComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            DomSanitizer,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], PageTimelineRightComponent);
    return PageTimelineRightComponent;
}(Page));
export { PageTimelineRightComponent };
