import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Floor } from 'app/pages/floor';
import { NovaTemplate } from 'app/_decorators/template.decorator';
import { BlogService } from 'app/_services/blog.service';
import { MediaService } from 'app/_services/media.service';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';

@Component({
    templateUrl: './blog-article.component.html',
    styleUrls: ['./blog-article.component.scss']
})
@NovaTemplate('PageBlogArticleComponent')
export class PageBlogArticleComponent extends Floor implements OnInit, OnDestroy {
    public media;
    public sliderHeight = '200px';
    public categories = [];
    public relatedPosts = [];
    public blogFonts = [];
    public blogNavigationIcon;

    @ViewChild('royalSlider') private royalSlider;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public route: ActivatedRoute,
        public router: Router,
        public title: Title,
        public mediaService: MediaService,
        public blogService: BlogService
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
        this.uiEventHandler.showNavArrows = true;
    }

    async ngOnDestroy() {
        $('.last-page')[0].style.setProperty('opacity', 'unset');
        $('.next-page')[0].style.setProperty('opacity', 'unset');

        this.uiEventHandler.currentBlogPost = undefined;
    }

    async ngOnInit() {
        this.blogFonts = [this.data.parentPage.font, this.data.parentPage.font_bold];
        this.sliderHeight = $('body').width() < 991 && `${this.data.mediaSize}vh`;
        this.getCategories();
        this.blogService.setBlogStyles(this.data.parentPage);
        this.blogNavigationIcon = await this.blogService.getBlogNavigationIcon(this.data.parentPage.blogNavigationIcon);

        if (this.data.parentPage.showRelatedPosts === 'active') {
            this.relatedPosts = this.data.parentPage.contents
                .filter(blogPost => blogPost._id !== this.data._id && blogPost.templateName === 'blog-article')
                .sort((a, b) => b.order - a.order)
                .map(value => ({ value, sort: Math.random() }))
                .sort((a, b) => a.sort - b.sort)
                .map(({ value }) => value)
                .slice(0, 3);
        }

        this.uiEventHandler.hideTitleBar = false;

        const { contents: posts, _id: parentPageId } = this.data.parentPage;
        const currentPostIndex = posts.findIndex(post => post._id === this.data._id);
        const currentPageIndex = this.nova.pages.findIndex(page => page._id === parentPageId);

        this.uiEventHandler.hideTitleBar = false;

        this.uiEventHandler.blogPage = {
            ...this.data.parentPage,
            contents: this.data.parentPage.contents.filter(post => post.templateName === 'blog-article')
        };

        this.uiEventHandler.currentBlogPost = this.data;

        $('.last-page')[0].style.setProperty('opacity', '1', 'important');

        if (currentPageIndex === this.nova.pages.length - 1 && currentPostIndex === 0) {
            $('.next-page')[0].style.setProperty('opacity', '0.5', 'important');
            $('.next-page')[0].setAttribute('disabled', 'true');
        }

        const sliderImages = await this.mediaService.loadMedia(this.data.medias, true);

        this.media = sliderImages.map(val => ({
            ...val,
            pos: this.calcPosition(val.org['description_position'] || 'center-center')
        }));

        setTimeout(() => {
            const options = {
                autoScaleSlider: false,
                navigateByClick: false,
                arrowsNav: true,
                arrowsNavAutoHide: true,
                arrowsNavHideOnTouch: true,
                imageScalePadding: 0,
                loop: true,
                keyboardNavEnabled: true,
                globalCaption: true,
                imgHeight: '100%'
            };

            if (this.data.showThumbs && this.data.showThumbs === 'yes' && !this.uiEventHandler.isMobile()) {
                options['controlNavigation'] = 'thumbnails';
                options['thumbs'] = {
                    appendSpan: true,
                    firstMargin: true,
                    paddingBottom: 4,
                    fitInViewport: false
                };
            }
            if (this.data.slider_speed && this.data.slider_speed !== 0) {
                options['autoPlay'] = {
                    enabled: true,
                    stopAtAction: false,
                    pauseOnHover: true,
                    delay: this.data.slider_speed * 1000
                };
            }

            $(this.royalSlider.nativeElement).royalSlider(options);

            this.uiEventHandler.showMenu = true;
            this.uiEventHandler.showCloseIcon = false;
        }, 0);
    }

    async getCategories() {
        const blogCategories = this.data.categories;

        for (const category of blogCategories) {
            const imageId = this.data.parentPage.categories.filter(cat => cat.name === category);

            if (!imageId || !imageId.length) {
                continue;
            }

            const url = await this.mediaService.getMediaUrl(imageId[0].image, 'preview').toPromise();
            this.categories = [...this.categories, { name: category, image: url }];
        }
    }

    getCopyrightUrl(c) {
        if (c) {
            if (!c.startsWith('http://') && !c.startsWith('https://')) {
                return 'http://' + c;
            }

            return c;
        }
    }

    getMediaUrl(data) {
        return this.mediaService.getMediaUrlByData(data, 'horizontal-wide');
    }

    calcPosition(posLogo) {
        let logoStyle = '';
        let flexboxStyle = '';
        const posLogoArr = posLogo.split('-');

        if (posLogoArr[0] === 'top') {
            flexboxStyle += 'justify-content: flex-start;';
        } else if (posLogoArr[0] === 'bottom') {
            flexboxStyle += 'justify-content: flex-end;';
        } else if (posLogoArr[0] === 'center') {
            flexboxStyle += 'justify-content: center;';
        }

        if (posLogoArr[1] === 'left') {
            logoStyle += 'align-self: flex-start;text-align: left;';
        } else if (posLogoArr[1] === 'right') {
            logoStyle += 'align-self: flex-end;text-align: right;';
        } else if (posLogoArr[1] === 'center') {
            logoStyle += 'align-self: center;text-align: center;';
        }

        return {
            logo: this.convertCSS(logoStyle),
            flexbox: this.convertCSS(flexboxStyle)
        };
    }

    convertCSS(css) {
        const input = css.toString();
        if (input.indexOf(':') > -1) {
            const result = {};
            const attributes = input.split(';');
            for (let i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    const entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }

            return result;
        }

        return css;
    }

    setMyStyles(obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    }

    getThumbnail(post) {
        if (post.thumbnail && post.thumbnail.url) {
            return 'url(' + post.thumbnail.url + ')';
        }
    }

    sliderPrev() {
        $(this.royalSlider.nativeElement)
            .data('royalSlider')
            .prev();
    }

    sliderNext() {
        $(this.royalSlider.nativeElement)
            .data('royalSlider')
            .next();
    }
}
