import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NovaAPIService } from '../../_services/nova-api.service';
import { MagazineService } from '../../_services/magazine.service';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthGuard } from 'app/_helpers/auth.guard';
import { StripeService } from 'app/_services/stripe.service';
import { environment } from 'environments/environment';
import { SSRService } from 'app/_services/ssr.service';
var PaidContentDialogComponent = /** @class */ (function () {
    function PaidContentDialogComponent(authGuard, magService, nova, ssr, stripe, uiEventHandler) {
        this.authGuard = authGuard;
        this.magService = magService;
        this.nova = nova;
        this.ssr = ssr;
        this.stripe = stripe;
        this.uiEventHandler = uiEventHandler;
        this.loginForm = new FormGroup({
            email: new FormControl('', [
                Validators.required,
                // regex from: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
                Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")
            ])
        }, { updateOn: 'blur' });
        this.isSubmitting = false;
        this.submitSuccess = false;
        this.isLoading = false;
        this.formError = false;
        this.state = {
            step: 'initial',
            status: 'stale',
            message: '',
            email: ''
        };
    }
    PaidContentDialogComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.isLoading = false;
                return [2 /*return*/];
            });
        });
    };
    PaidContentDialogComponent.prototype.createCleanUrl = function (dirtyUrl) {
        var url = new URL(dirtyUrl);
        if (url.hostname === 'localhost') {
            return 'https://novamag.de';
        }
        // remove params and only return path
        return "" + url.origin + url.pathname;
    };
    PaidContentDialogComponent.prototype.onLoginSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isSubmitting = true;
                        this.state = {
                            step: 'login',
                            status: 'success'
                        };
                        return [4 /*yield*/, fetch(environment.apiUrl + "v1/stripe/login", {
                                method: 'POST',
                                headers: new Headers({
                                    'Content-Type': 'application/json'
                                }),
                                body: JSON.stringify({
                                    email: this.loginForm.get('email').value,
                                    language: this.nova.issue.language,
                                    publication_id: this.nova.issue.issueId,
                                    stripe_account: this.stripe.stripeAccount,
                                    url: this.createCleanUrl(window.location.href)
                                })
                            })];
                    case 1:
                        _a.sent();
                        this.isSubmitting = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    PaidContentDialogComponent.prototype.redirectToCheckout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isSubmitting = true;
                        return [4 /*yield*/, fetch(environment.apiUrl + "v1/stripe/checkout", {
                                method: 'POST',
                                headers: new Headers({
                                    'Content-Type': 'application/json'
                                }),
                                body: JSON.stringify({
                                    language: this.nova.issue.language,
                                    publication_id: this.nova.issue.issueId,
                                    return_url: this.createCleanUrl(window.location.href),
                                    stripe_account: this.stripe.stripeAccount
                                })
                            })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, res.json()];
                    case 2:
                        result = _a.sent();
                        if (result.code === 200 && this.ssr.isBrowser && typeof window !== 'undefined') {
                            window.location = result.url;
                        }
                        else {
                            this.isSubmitting = false;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PaidContentDialogComponent.prototype.setStep = function (state) {
        this.state = tslib_1.__assign({ step: 'initial', status: 'stale', message: '' }, state);
    };
    PaidContentDialogComponent.prototype.close = function () {
        this.uiEventHandler.showPaidContentDialog = false;
    };
    return PaidContentDialogComponent;
}());
export { PaidContentDialogComponent };
