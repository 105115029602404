import * as tslib_1 from "tslib";
import { ServerArticleScrollComponent } from 'app/pages/templates_server/article-scroll/article-scroll.component';
import { ComponentFactoryResolver, OnInit, Type, ViewContainerRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NovaAPIService } from '../_services/nova-api.service';
import { PageNotFoundComponent } from '../not-found-component';
import { getComponent } from '../_decorators/template.decorator';
import { SSRService } from 'app/_services/ssr.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
var PageComponent = /** @class */ (function () {
    function PageComponent(ssr, resolver, nova, router, route, uiEventHandler) {
        this.ssr = ssr;
        this.resolver = resolver;
        this.nova = nova;
        this.router = router;
        this.route = route;
        this.uiEventHandler = uiEventHandler;
        this.hasParent = false;
    }
    PageComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.nova.getCurrentIssue().subscribe(function (_a) {
                    var travelAgency = _a.travelAgency;
                    return (_this.travelAgency = travelAgency);
                });
                this.nova.getPageData().subscribe(function (data) {
                    _this.id = data['id'];
                    data.data.travelAgency = _this.travelAgency;
                    if (typeof data['contentId'] !== 'undefined' || typeof data['pageId'] !== 'undefined') {
                        _this.contentId = data['contentId'] || data['pageId'];
                    }
                    _this.data = data['data'];
                    _this.loadComponent(data['data']);
                }, function (error) {
                    if (error.message === '401') {
                        _this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
                    }
                    else if (error.message === 'no pages found') {
                        _this.router.navigate(['/errorPage'], { queryParamsHandling: 'preserve' });
                    }
                    else if (error.message === '423') {
                        return _this.router.navigate(['/'], {
                            queryParamsHandling: 'preserve'
                        });
                    }
                    else {
                        _this.router.navigate(['/error'], { queryParamsHandling: 'preserve' });
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    PageComponent.prototype.loadComponent = function (page) {
        var params = this.router.routerState.root.firstChild.params['value'];
        if (!page || !page.templateName) {
            if (page.parentPage) {
                return this.router.navigate(['/', params['langHandle'], params['issueHandle'], page.parentPage.handle], {
                    queryParamsHandling: 'preserve'
                });
            }
            else {
                return this.router.navigate(['/'], {
                    queryParamsHandling: 'preserve'
                });
            }
        }
        this.hasParent = 'pageId' in page;
        this.template = this.data.templateName;
        this.uiEventHandler.topClose = false;
        this.uiEventHandler.showNavArrows = !this.hasParent;
        // find matching component
        var component = this.getComponentMapping(page.templateName);
        // create factory
        var factory = this.resolver.resolveComponentFactory(component);
        // clear container
        this.templateContainer.clear();
        // create component and pass data
        var componentRef = this.templateContainer.createComponent(factory);
        componentRef.instance['data'] = page;
        if (this.hasParent) {
            componentRef.instance['pageId'] = this.id;
            componentRef.instance['id'] = this.contentId;
            this.uiEventHandler.hideTitleBar = true;
        }
        else {
            componentRef.instance['id'] = this.id;
            this.uiEventHandler.hideTitleBar = false;
        }
    };
    PageComponent.prototype.toCamelCase = function (val) {
        var s = val
            .replace(/\b\w/g, function (m) {
            return m.toUpperCase();
        })
            .replace(/-/g, '');
        return val.charAt(0).toUpperCase() + s.slice(1);
    };
    PageComponent.prototype.getComponentMapping = function (template) {
        var componentName = 'Page';
        if (!this.ssr.isBrowser) {
            componentName = 'Server';
        }
        componentName += this.toCamelCase(template) + 'Component';
        if (template === 'Pflugfelder - Teilmarkt Index') {
            componentName = 'PagePflugfelderIndexComponent';
        }
        if (template === 'Pflugfelder Contents') {
            componentName = 'PagePflugfelderContentsComponent';
        }
        if (template === 'gebirgslandeplaetze-detailseite') {
            componentName = 'PageGebirgslandeplaetzeDetailComponent';
        }
        var component = getComponent(componentName);
        if (component) {
            return component;
        }
        if (!this.ssr.isBrowser) {
            return ServerArticleScrollComponent;
        }
        return PageNotFoundComponent;
    };
    PageComponent.prototype.closeContent = function (gallery) {
        if (gallery && gallery.open) {
            return;
        }
        var params = this.route.params['value'];
        this.uiEventHandler.topClose = false;
        if (this.data && this.data.parentPage && this.data.parentPage.templateName === 'blog-index') {
            history.back();
            return;
        }
        this.router.navigate(['/', params['langHandle'], params['issueHandle'], params['pageHandle']], {
            queryParams: {
                img: null
            },
            queryParamsHandling: 'merge'
        });
    };
    PageComponent.prototype.showPrint = function () {
        if (this.nova.issue &&
            !this.data.templateName.includes('-standard') &&
            !this.data.templateName.includes('-premium')) {
            return !this.showPDF() && this.nova.issue.showPrint === 'yes';
        }
        return false;
    };
    PageComponent.prototype.showPDF = function () {
        return this.data && this.data['pdf_version'] ? !!this.data['pdf_version'] : false;
    };
    PageComponent.prototype.ngOnDestroy = function () {
        var _this = this;
        setTimeout(function () {
            _this.uiEventHandler.topClose = false;
        }, 100);
    };
    return PageComponent;
}());
export { PageComponent };
