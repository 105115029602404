/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nova-terms.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../nova-nav-item/nova-nav-item.component.ngfactory";
import * as i4 from "../nova-nav-item/nova-nav-item.component";
import * as i5 from "../../_services/nova-api.service";
import * as i6 from "../../_services/magazine.service";
import * as i7 from "../../_services/uievent-handler.service";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../nova-text-element/nova-text-element.component.ngfactory";
import * as i10 from "../nova-text-element/nova-text-element.component";
import * as i11 from "../../_services/media.service";
import * as i12 from "@angular/router";
import * as i13 from "../../_services/cookie.service";
import * as i14 from "@angular/common";
import * as i15 from "./nova-terms.component";
var styles_NovaTermsComponent = [i0.styles];
var RenderType_NovaTermsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NovaTermsComponent, data: {} });
export { RenderType_NovaTermsComponent as RenderType_NovaTermsComponent };
function View_NovaTermsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "privacy-link"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.termsUrl; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("PRIVACY_POLICY")); _ck(_v, 1, 0, currVal_1); }); }
export function View_NovaTermsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["id", "terms"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "nova-nav-item", [["class", "icon-cancel my-close-icon"], ["style", "position: sticky; float: right;"]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onMouseEnter($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onMouseLeave($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).onTouchStart($event.target) !== false);
        ad = (pd_2 && ad);
    } if (("touchend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3).onTouchEnd($event.target) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.hide() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i3.View_NovaNavItemComponent_0, i3.RenderType_NovaNavItemComponent)), i1.ɵdid(3, 245760, null, 0, i4.NovaNavItemComponent, [i5.NovaAPIService, i6.MagazineService, i7.UIEventHandlerService, i8.DomSanitizer, i1.ElementRef, i1.Renderer2], { style: [0, "style"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "nova-text-element", [["class", "con"]], null, null, null, i9.View_NovaTextElementComponent_0, i9.RenderType_NovaTextElementComponent)), i1.ɵdid(5, 4964352, null, 0, i10.NovaTextElementComponent, [i7.UIEventHandlerService, i11.MediaService, i5.NovaAPIService, i12.Router, i8.DomSanitizer, i12.ActivatedRoute, i6.MagazineService, i13.CookieService, i14.DOCUMENT], { data: [0, "data"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaTermsComponent_1)), i1.ɵdid(7, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "position: sticky; float: right;"; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.termsText; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.termsUrl; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.uiEventHandler.showTerms; _ck(_v, 0, 0, currVal_0); }); }
export function View_NovaTermsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-terms", [], null, null, null, View_NovaTermsComponent_0, RenderType_NovaTermsComponent)), i1.ɵdid(1, 245760, null, 0, i15.NovaTermsComponent, [i5.NovaAPIService, i6.MagazineService, i8.DomSanitizer, i12.Router, i7.UIEventHandlerService, i12.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NovaTermsComponentNgFactory = i1.ɵccf("nova-terms", i15.NovaTermsComponent, View_NovaTermsComponent_Host_0, {}, {}, []);
export { NovaTermsComponentNgFactory as NovaTermsComponentNgFactory };
