<div id="page-{{ id }}" class="page-gebirgslandeplaetze-index">
    <nova-bg [data]="data" class="background"></nova-bg>
    <div class="container" [class.no-image]="!data.background_media" *ngIf="!isLoading">
        <div>
            <nova-element [data]="data" name="label" class="header__label"></nova-element>
            <h1><nova-element [data]="data" name="header" class="header__header"></nova-element></h1>
        </div>
        <div>
            <nova-element [data]="data" name="text"></nova-element>
        </div>
        <section class="search">
            <div class="search-input">
                <i class="fa fa-search fa-lg"></i>
                <input type="text" [(ngModel)]="searchValue" (ngModelChange)="filter()" placeholder="{{'SEARCH' | translate }} ">
            </div>
            <div class="filter-section">
                <span>
                    <input type="checkbox" id="small" name="small" value="small" [(ngModel)]="filterSmallGlaciers" (ngModelChange)="filter()">
                    <i class="fa fa-circle fa-lg" [ngStyle]="{'color': getIconColor(1000)}"></i>
                    <label for="small">< 2000 m</label>
                </span>
                <span>
                    <input type="checkbox" id="medium" name="medium" value="medium" [(ngModel)]="filterMediumGlaciers" (ngModelChange)="filter()">
                    <i class="fa fa-circle fa-lg" [ngStyle]="{'color': getIconColor(2000)}"></i>
                    <label for="medium">2000 m - 2700 m</label>
                </span>
                <span>
                    <input type="checkbox" id="large" name="large" value="large" [(ngModel)]="filterLargeGlaciers" (ngModelChange)="filter()">
                    <i class="fa fa-circle fa-lg" [ngStyle]="{'color': getIconColor(3000)}"></i>
                    <label for="large">> 2700 m</label>
                </span>
            </div>
        </section>
        <ul class="ul-reset glacier-list">
            <li *ngFor="let glacier of glaciers" class="single-glacier" [routerLink]="glacier.handle" [@fadeAnimation]="'in'">
                <div class="single-glacier__header">
                    <span>
                        <h4 class="single-glacier__name">{{glacier.glacier_name}}</h4>
                        <span class="single-glacier__number">({{glacier.glacier_number}})</span>
                    </span>
                    <i class="fa fa-circle fa-lg" [ngStyle]="{'color': getIconColor(glacier.glacierHeight_index)}"></i>
                </div>
                <div class="single-glacier__content">
                    <span class="single-glacier__content-row">
                        <i class="single-glacier__icon single-glacier__icon-height"></i>
                        <p>{{glacier.glacierHeight_meters}} | {{glacier.glacierHeight_feet}}</p>
                    </span>
                    <span class="single-glacier__content-row">
                        <i class="single-glacier__icon single-glacier__icon-temperature"></i>
                        <p>{{glacier.temperature}}</p>
                    </span>
                    <ul class="single-glacier__canton-list">
                        <li *ngFor="let canton of glacier.canton" class="single-glacier__content-row">
                            <i [class]="'single-glacier__icon single-glacier__icon-' + canton.toLowerCase()"></i>
                            <p>{{canton | translate }}</p>
                        </li>
                    </ul>
                </div>
            </li>
            <li *ngIf="glaciers.length === 0" class="glacier-list__no-result">{{'NO_SEARCH_RESULT' | translate }} </li>
        </ul>
        <p class="responsibility-warning">
            <span class="responsibility-warning__header">
                <i class="responsibility-warning__icon-attention"></i>
                <nova-element [data]="data" name="responsibilityWarning_header"></nova-element>
            </span>
            <nova-element [data]="data" name="responsibilityWarning_text"></nova-element>
        </p>
        <p class="textbox">
            <nova-element [data]="data" name="text1_text"></nova-element>
        </p>
        <p class="textbox">
            <b><nova-element [data]="data" name="text2_header"></nova-element></b>
            <br>
            <nova-element [data]="data" name="text2_text"></nova-element>
        </p>
        <section class="gebirgslandeplaetze">
            <a [href]="data.gebirgslandeplaetze_url" target=”_blank” class="gebirgslandeplaetze__link-box">{{'CLICK_FOR' | translate }} <i class="gebirgslandeplaetze__swiss-icon"></i></a>
            <button class="gebirgslandeplaetze__download-button" type="submit" (click)="downloadGebirgslandeplaetzeMap()">{{'CLICK_FOR' | translate }} <i class="gebirgslandeplaetze__download-icon"></i></button>
            <nova-media class="gebirgslandeplaetze__image" [mediaId]="data.gebirgslandeplaetze_jpg" version="original"></nova-media>
        </section>
    </div>
</div>