<div id="page-{{ id }}" class="page page-cover-title">
  <nova-bg [data]="data" [forceVideo]="true"></nova-bg>
  <div class="logo clearfix">
    <div *ngIf="data.logo && data.logo.url" class="logo-box" [ngStyle]="setMyStyles(getLogoStyle())"><img
        [src]="data.logo.url" alt="Logo" /></div>
    <div class="title" [ngStyle]="setMyStyles(getTitleStyle())" [ngClass]="{textOnly: !data.logo || !data.logo.url}">
      <h1>{{ data.header }}</h1>
      <h2>{{ data.subHeader }}</h2>
      <span class="after" [ngStyle]="setMyStyles(getBorderTopColor())"></span>
    </div>
  </div>
</div>