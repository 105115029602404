import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'underscore';
import * as $ from 'jquery';
import { Meta, Title } from '@angular/platform-browser';
import { MediaService } from 'app/_services/media.service';
import { ActivatedRoute, Router } from '@angular/router';
var PageSwitchComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageSwitchComponent, _super);
    function PageSwitchComponent(nova, mediaService, uiEventHandler, sanitizer, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.mediaService = mediaService;
        _this.uiEventHandler = uiEventHandler;
        _this.sanitizer = sanitizer;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        _this.active = -1;
        _this.wait = false;
        return _this;
    }
    PageSwitchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.teaser =
            (this.data.contents && this.data.contents.length > 0) ||
                (this.data.text && this.data.text.length > 0) ||
                this.data.header ||
                this.data.subHeader ||
                this.data.label;
        this.mediaService.loadMedia(this.data.medias).then(function (data) {
            _this.media = data;
        });
        var arr = this.data.textImages;
        this.data.medias.forEach(function (element) {
            arr = arr.concat(element.textImages);
        });
        this.gallerySliderMedia = _.unique(arr);
        this.nova.magazineService.getMagazine().subscribe(function (data) {
            _this.nav = data.navigation;
        });
        console.log(this.data);
    };
    PageSwitchComponent.prototype.getEntryStyle = function (val, wide) {
        if (wide === true) {
            return ('url(' +
                this.mediaService.getImageProxyUrl(this.mediaService.getMediaUrlByData(val, 'horizontal-wide'), 'horizontal-wide') +
                ')');
        }
        return 'url(' + this.mediaService.getImageProxyUrl(this.mediaService.getMediaUrlByData(val)) + ')';
    };
    PageSwitchComponent.prototype.getId = function (id, key) {
        return id + '-' + key;
    };
    PageSwitchComponent.prototype.getFontColor = function (c, c2) {
        if (!c || !c2) {
            return;
        }
        return this.convertCSS("\n        background-color: rgba(" + c2.r + ", " + c2.g + ", " + c2.b + ", 1);\n        color: rgba(" + c.r + ", " + c.g + ", " + c.b + ", 1);\n      ");
    };
    PageSwitchComponent.prototype.getButtonStyling = function (val, hover) {
        if (hover === void 0) { hover = false; }
        if (!val['buttonColorSecondary']) {
            val['buttonColorSecondary'] = { r: 255, g: 255, b: 255, a: 1 };
        }
        var c = hover ? val['buttonColorSecondary'] : val['buttonColorPrimary'];
        var c2 = hover ? val['buttonColorPrimary'] : val['buttonColorSecondary'];
        if (c && c2) {
            return {
                'background-color': "rgba(" + c.r + ", " + c.g + ", " + c.b + ", " + c.a + ")",
                color: "rgba(" + c2.r + ", " + c2.g + ", " + c2.b + ", " + c2.a + ")",
                'border-color': "rgba(" + c2.r + ", " + c2.g + ", " + c2.b + ", " + c2.a + ")"
            };
        }
        else {
            return null;
        }
    };
    PageSwitchComponent.prototype.calcWidth = function () {
        var length = Object.keys(this.data.medias).length;
        var classes = '';
        if (length === 1) {
            classes += 'slides-1 ';
        }
        else if (length === 2) {
            classes += 'slides-2 ';
        }
        else if (length === 3) {
            classes += 'slides-3 ';
        }
        else if (length === 4) {
            classes += 'slides-4 ';
        }
        else if (length === 5) {
            classes += 'slides-5 ';
        }
        if (this.active !== -1) {
            classes += 'active-slide ';
        }
        return classes;
    };
    PageSwitchComponent.prototype.ngAfterViewInit = function () {
        if (this.data.medias.length === 1) {
            var newHeight = $('body').height() -
                $(this.mobileScroller.nativeElement)
                    .find('.bg.teaser')
                    .outerHeight();
            $(this.mobileScroller.nativeElement)
                .find('.switch-medium')
                .css('height', newHeight + 'px');
        }
    };
    PageSwitchComponent.prototype.open = function (id) {
        if (this.data.medias[id].text1 === '') {
            if (this.active === id || this.wait) {
                return;
            }
            if (this.active === -1) {
                this.active = id;
            }
            else {
                this.active = -1;
            }
            this.triggerWait();
        }
    };
    PageSwitchComponent.prototype.close = function () {
        if (this.wait) {
            return;
        }
        this.active = -1;
        this.triggerWait();
    };
    PageSwitchComponent.prototype.triggerWait = function () {
        var _this = this;
        this.wait = true;
        setTimeout(function () {
            _this.wait = false;
            // resize sub content boxes
            if (_this.active >= 0) {
                var elm = $($(_this.mobileScroller.nativeElement).find('.switch .slide')[_this.active]).find('.bg-small');
                if (elm.height() / $('body').height() >= 0.4) {
                    elm.height($('body').height() * 0.4 + 'px');
                }
            }
        }, 500);
    };
    PageSwitchComponent.prototype.convertCSS = function (css) {
        var input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {}, attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    };
    PageSwitchComponent.prototype.setMyStyles = function (obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    };
    PageSwitchComponent = tslib_1.__decorate([
        NovaTemplate('PageSwitchComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            MediaService,
            UIEventHandlerService,
            DomSanitizer,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], PageSwitchComponent);
    return PageSwitchComponent;
}(Page));
export { PageSwitchComponent };
