/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nova-budapest-overlay-pages.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./nova-budapest-overlay-pages.component";
import * as i4 from "../../../_services/nova-api.service";
import * as i5 from "../../../_services/magazine.service";
import * as i6 from "../../../_services/uievent-handler.service";
import * as i7 from "@angular/router";
var styles_NovaBudapestOverlayPagesComponent = [i0.styles];
var RenderType_NovaBudapestOverlayPagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NovaBudapestOverlayPagesComponent, data: {} });
export { RenderType_NovaBudapestOverlayPagesComponent as RenderType_NovaBudapestOverlayPagesComponent };
function View_NovaBudapestOverlayPagesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.thumbnail.url; _ck(_v, 0, 0, currVal_0); }); }
function View_NovaBudapestOverlayPagesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = (("assets/img/templates/pages/" + _v.parent.context.$implicit.templateName) + ".svg"); _ck(_v, 0, 0, currVal_0); }); }
function View_NovaBudapestOverlayPagesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "item"], ["tabIndex", "-1"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goTo((_v.context.index + 1), 1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "overlay"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaBudapestOverlayPagesComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaBudapestOverlayPagesComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 5, "span", [], null, null, null, null, null)), i1.ɵdid(8, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(9, { "fontFamily": 0 }), (_l()(), i1.ɵeld(10, 0, null, null, 1, "b", [], [[4, "background-color", null]], null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵted(12, null, [" ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_v.context.$implicit.thumbnail && !_co.nova.isThumbLoading(_v.context.$implicit.thumbnail)); _ck(_v, 4, 0, currVal_2); var currVal_3 = (!_v.context.$implicit.thumbnail || _co.nova.isThumbLoading(_v.context.$implicit.thumbnail)); _ck(_v, 6, 0, currVal_3); var currVal_4 = _ck(_v, 9, 0, _co.font); _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.showInIndex == "no"); _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.currentPage == _v.context.index); _ck(_v, 1, 0, currVal_1); var currVal_5 = _co.isActive((_co.currentPage == _v.context.index)); _ck(_v, 10, 0, currVal_5); var currVal_6 = _co.pad((_v.context.index + 1), 2); _ck(_v, 11, 0, currVal_6); var currVal_7 = _co.uiEventHandler.getContentTableEntryName(_v.context.$implicit); _ck(_v, 12, 0, currVal_7); }); }
export function View_NovaBudapestOverlayPagesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaBudapestOverlayPagesComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nova.pages; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NovaBudapestOverlayPagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-budapest-overlay-pages", [], null, null, null, View_NovaBudapestOverlayPagesComponent_0, RenderType_NovaBudapestOverlayPagesComponent)), i1.ɵdid(1, 49152, null, 0, i3.NovaBudapestOverlayPagesComponent, [i4.NovaAPIService, i5.MagazineService, i6.UIEventHandlerService, i7.Router], null, null)], null, null); }
var NovaBudapestOverlayPagesComponentNgFactory = i1.ɵccf("nova-budapest-overlay-pages", i3.NovaBudapestOverlayPagesComponent, View_NovaBudapestOverlayPagesComponent_Host_0, { currentPage: "currentPage", font: "font", borderColor: "borderColor" }, { pageChange: "pageChange" }, []);
export { NovaBudapestOverlayPagesComponentNgFactory as NovaBudapestOverlayPagesComponentNgFactory };
