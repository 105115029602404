import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as $ from 'jquery';
import { UIEventHandlerService } from './uievent-handler.service';

interface WindowWithUc extends Window {
    uc: any;
}

interface CustomUsercentricsMapping {
    name: string;
    standardId: string;
    customId: string;
}
interface CookieServiceConfig {
    type: 'default' | 'none' | 'usercentrics';
    userId?: string;
    customServiceIds?: CustomUsercentricsMapping[];
}

const defaultConfig: CookieServiceConfig = {
    type: 'default'
};

@Injectable({
    providedIn: 'root'
})
export class CookieService {
    public config: CookieServiceConfig = defaultConfig;
    public language = 'de';
    private hasMappedCustomServices = false;

    constructor(public sanitizer: DomSanitizer, public uiEventHandler: UIEventHandlerService) {}

    init(language: string = 'de', serviceConfig?: CookieServiceConfig, trackingProvider?: string) {
        this.language = language;

        if (!serviceConfig || serviceConfig.type === 'default') {
            this.injectDefaultScripts();
            let cookieConsent = {};
            try {
                const consentCookie = this.uiEventHandler.getCookie('CookieConsent');

                if (consentCookie) {
                    cookieConsent = JSON.parse(consentCookie);
                }
            } catch (e) {
                console.log('CookieConsent Error', e);
            }

            return;
        }

        if (serviceConfig.type === 'usercentrics') {
            if (!serviceConfig.userId) {
                throw new Error('No userCentrics Id given!');
            }

            this.config = serviceConfig;

            $('head').append(
                `
                <meta data-privacy-proxy-server = "https://privacy-proxy-server.usercentrics.eu">
                <script id="usercentrics-cmp" data-settings-id="${
                    serviceConfig.userId
                }" src="https://app.usercentrics.eu/browser-ui/latest/bundle.js" defer></script>
                <script type="application/javascript" src="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js"></script>`
            );

            this.injectUserCentricsScripts();
        }
    }

    createCookieCompliantContent(content: string) {
        if (!content) {
            return;
        }

        return this.sanitizer.bypassSecurityTrustHtml(content);
    }

    injectDefaultScripts() {
        const scripts = `<script async defer type="application/javascript">(function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));</script>
        <script async defer type="application/javascript" src="https://platform.instagram.com/en_US/embeds.js"></script>
        <script async defer type="application/javascript" src="https://cdn.embedly.com/widgets/platform.js" charset="utf-8"></script>`;

        $('head').append(scripts);
    }

    injectUserCentricsScripts() {
        const scripts = `<script async defer type="text/plain" data-type="application/javascript" data-usercentrics="Facebook" data-name="facebook">(function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));</script>
        <script async defer type="text/plain" data-type="application/javascript" data-usercentrics="Instagram" data-name="instagram" src="https://platform.instagram.com/en_US/embeds.js"></script>
        <script async defer type="text/plain" data-type="application/javascript" data-usercentrics="Embedly" data-name="embedly" src="https://cdn.embedly.com/widgets/platform.js" charset="utf-8"></script>
        `;

        $('head').append(scripts);
    }

    mapUserCentricsCustomServices(serviceConfig: CookieServiceConfig) {
        if (!serviceConfig || serviceConfig.type === 'default' || this.hasMappedCustomServices) {
            return;
        }

        if (
            window &&
            (window as WindowWithUc).uc &&
            serviceConfig.customServiceIds &&
            serviceConfig.customServiceIds.length > 0
        ) {
            const mappedServiceIds = serviceConfig.customServiceIds.map(service => ({
                [service.standardId]: service.customId
            }));

            (window as WindowWithUc).uc.setServiceAlias(...mappedServiceIds);

            // prevent re-rendering
            this.hasMappedCustomServices = true;
        }
    }
}
