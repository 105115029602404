import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { MagazineService } from 'app/_services/magazine.service';
import { Router, NavigationEnd } from '@angular/router';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';

@Component({
    selector: 'nova-fti-overlay-archive',
    templateUrl: './nova-fti-overlay-archive.component.html',
    styleUrls: ['../nova-fti-overlay-pages/nova-fti-overlay-pages.component.scss']
})
export class NovaFtiOverlayArchiveComponent implements OnInit {
    @Input() public lang;
    @Input() public font;
    @Input() public borderColor;
    @Output() issueChange: EventEmitter<number> = new EventEmitter<number>();
    currentIssue = '';

    constructor(
        public nova: NovaAPIService,
        public mag: MagazineService,
        private router: Router,
        public uiEventHandler: UIEventHandlerService
    ) {}

    ngOnInit() {
        const params =
            this.router.routerState.root.firstChild && this.router.routerState.root.firstChild['params']['value'];

        if (params && params.issueHandle) {
            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    this.getCurrentIssue();
                }
            });
            this.getCurrentIssue();
        }
    }

    isActive(check) {
        if (check) {
            return this.borderColor;
        }
    }

    getCurrentIssue() {
        const params = this.router.routerState.root.firstChild.params['value'];
        const issueHandle = params.issueHandle;
        this.currentIssue = issueHandle;
    }

    goToLanding() {
        this.issueChange.emit();
        this.router.navigate(['/', this.lang], { queryParamsHandling: 'preserve' });
    }

    openIssue(issue) {
        this.issueChange.emit(issue);
        // this.router would be better, but it doesn't work with resetting the magazine data for now e.g. languages in overlay do not reset
        // this.router.navigate(['/', this.lang, issue], { queryParamsHandling: 'preserve' });
        window.location.href = `/${this.lang}/${issue}?${window.location.search}`;
    }

    getLangIssue(value) {
        return value.languagesMap[this.lang];
    }
}
