import * as tslib_1 from "tslib";
import { OnInit, ElementRef, Renderer2 } from '@angular/core';
import { NovaAPIService } from '../../_services/nova-api.service';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NovaNavItemComponent } from '../nova-nav-item/nova-nav-item.component';
import { MagazineService } from 'app/_services/magazine.service';
var NovaNavBorderItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(NovaNavBorderItemComponent, _super);
    function NovaNavBorderItemComponent(nova, mag, ui, sanitizer, element, renderer) {
        var _this = _super.call(this, nova, mag, ui, sanitizer, element, renderer) || this;
        _this.element = element;
        _this.renderer = renderer;
        return _this;
    }
    NovaNavBorderItemComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    NovaNavBorderItemComponent.prototype.afterInit = function () {
        if (!this.style) {
            this.style = {};
        }
        if (!this.style['border']) {
            this.style['border'] = '1px solid ' + this.ui.getColor(this.nav.borderColor);
        }
        _super.prototype.afterInit.call(this);
    };
    return NovaNavBorderItemComponent;
}(NovaNavItemComponent));
export { NovaNavBorderItemComponent };
