<div id="page-{{ id }}" class="page page-article-scroll">
  <nova-bg [data]="data" [forceVideo]="true"></nova-bg>
  <nova-scrollbar-holder>
    <div class="scroller">
      <div class="bg animated fadeInUp clear" #bgElm [hidden]="!data.text && !data.header && !data.subHeader && !data.label">
        <nova-title [data]="data"></nova-title>
        <nova-text-element *ngIf="data.text" class="con" [data]="data.text" [myid]="data._id"></nova-text-element>
        <nova-content-links class="content-links" *ngIf="data.links && data.links.length > 0" [floors]="data.links"></nova-content-links>
      </div>
    </div>
  </nova-scrollbar-holder>
</div>
