import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SSRService } from '../_services/ssr.service';
import { UIEventHandlerService } from '../_services/uievent-handler.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private ui: UIEventHandlerService, private ssr: SSRService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let currentUser;
        let cookie = this.ui.getCookie('currentUser');
        if (cookie) {
            currentUser = JSON.parse(cookie);
        }

        let headers = {};

        if (currentUser && currentUser.authToken) {
            headers['x-auth-token'] = `${currentUser.authToken}`;
        }

        if (this.ssr.hasToken() && this.ui.getCookie('preview-token')) {
            headers['x-preview-token'] = this.ui.getCookie('preview-token');
        }

        request = request.clone({
            setHeaders: headers
        });

        return next.handle(request);
    }
}

export const JwtInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true
};
