import { Component, OnInit, ViewEncapsulation, AfterContentInit, OnDestroy } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { ColorFormatter } from '../../../novamag-api/Types';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { MagazineService } from 'app/_services/magazine.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './index-issue.component.html',
    styleUrls: ['./index-issue.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageIndexIssueComponent')
export class PageIndexIssueComponent extends Page implements OnInit, AfterContentInit, OnDestroy {
    @ViewChild('leftHolder') public leftHolder: ElementRef;
    @ViewChild('left') public left: ElementRef;
    @ViewChild('leftMobile') public leftMobile: ElementRef;
    @ViewChild('rightHolder') public rightHolder: ElementRef;
    @ViewChild('right') public right: ElementRef;
    @ViewChild('rightMobile') public rightMobile: ElementRef;

    public apiUrl: string;
    private sub: Subscription;
    private subFont: Subscription;
    public pagesLeft;
    public pagesRight;
    public urlLeft;
    public urlRight;

    public mobile = false;

    constructor(
        public nova: NovaAPIService,
        public mag: MagazineService,
        public renderer: Renderer2,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public translate: TranslateService,
        public router: Router,
        public route: ActivatedRoute
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    ngOnInit() {
        this.mobile = $('body').width() <= 1024;
    }

    ngAfterContentInit() {
        // LEFT >>>
        if (this.data['boxColorLeft']) {
            const c = new ColorFormatter(this.data['boxColorLeft']);
            if (!this.mobile) this.renderer.setStyle(this.left.nativeElement, 'background-color', c.rgba());
            if (this.mobile) this.renderer.setStyle(this.leftMobile.nativeElement, 'background-color', c.rgba());
        }

        if (this.data['textColorLeft']) {
            const c = new ColorFormatter(this.data['textColorLeft']);
            if (!this.mobile) this.renderer.setStyle(this.left.nativeElement, 'color', c.rgb());
            if (this.mobile) this.renderer.setStyle(this.leftMobile.nativeElement, 'color', c.rgb());
        }

        if (this.data['boxWidthLeft']) {
            this.renderer.setStyle(this.leftHolder.nativeElement, 'width', this.data['boxWidthLeft']);
        }

        this.sub = this.mag.getMagazine().subscribe(data => {
            this.subFont = this.nova.getFont(data.navigation.font).subscribe(fontData => {
                if (this.mobile) this.renderer.setStyle(this.left.nativeElement, 'font-family', fontData.name);
                if (this.mobile) this.renderer.setStyle(this.leftMobile.nativeElement, 'font-family', fontData.name);
            });

            this.pagesLeft = this.nova.pages.filter(
                page =>
                    !page.templateName.startsWith('index-') &&
                    page.status === 'active' &&
                    page.showImagesLeft !== 'no' &&
                    page.showInIndex !== 'no'
            );

            const leftUrl = this.pagesLeft[0]['handle'].split('/');
            this.urlLeft = leftUrl && leftUrl[0] + '/' + leftUrl[1];
        });
        // <<< LEFT

        // Right >>>
        if (this.data['boxColorRight']) {
            const c = new ColorFormatter(this.data['boxColorRight']);
            if (!this.mobile) this.renderer.setStyle(this.right.nativeElement, 'background-color', c.rgba());
            if (this.mobile) this.renderer.setStyle(this.rightMobile.nativeElement, 'background-color', c.rgba());
        }

        if (this.data['textColorRight']) {
            const c = new ColorFormatter(this.data['textColorRight']);
            if (!this.mobile) this.renderer.setStyle(this.right.nativeElement, 'color', c.rgb());
            if (this.mobile) this.renderer.setStyle(this.rightMobile.nativeElement, 'color', c.rgba());
        }

        if (this.data['boxWidthRight']) {
            this.renderer.setStyle(this.rightHolder.nativeElement, 'width', this.data['boxWidthRight']);
        }

        this.sub = this.mag.getMagazine().subscribe(data => {
            this.subFont = this.nova.getFont(data.navigation.font).subscribe(fontData => {
                if (!this.mobile) this.renderer.setStyle(this.right.nativeElement, 'font-family', fontData.name);
                if (this.mobile) this.renderer.setStyle(this.rightMobile.nativeElement, 'font-family', fontData.name);
            });

            this.pagesRight = this.data['issueIndex'];

            const rightUrl = this.data['issueIndex'][0] && this.data['issueIndex'][0]['handle'].split('/');
            this.urlRight = rightUrl && rightUrl[0] + '/' + rightUrl[1];
        });
        // <<< Right
    }

    goToPage(page, floor?) {
        const params = this.router.routerState.root.firstChild.params['value'];
        if (!floor) {
            this.router.navigate(['/', params['langHandle'], params['issueHandle'], page], {
                queryParamsHandling: 'preserve'
            });
        } else {
            this.router.navigate(['/', params['langHandle'], params['issueHandle'], page, floor], {
                queryParamsHandling: 'preserve'
            });
        }
    }

    goToIssue(url) {
        window.open(url, '_self');
    }

    getType(type) {
        return this.translate.get(type);
    }

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe();
        if (this.subFont) this.subFont.unsubscribe();
    }
}
