<div class="items">
  <div class="item" *ngFor="let val of nova.issues; let key = index;" tabIndex="-1">
      <div [class.active]="currentIssue == getLangIssue(val).handle" (click)="openIssue(getLangIssue(val).handle)" *ngIf="val.languagesMap[lang]">
        <div class="overlay"></div>
          <nova-media *ngIf="val.archiveBackground || val.background_media" [mediaId]="val.archiveBackground || val.background_media" [version]="'horizontal'" [bg]="false" [fallback]="true"></nova-media>
          <img *ngIf="!val.archiveBackground && !val.background_media"
        [src]="'assets/img/templates/pages/cover-classic-logo.svg'" />
          <span [ngStyle]="{'fontFamily':font}"><b [style.background-color]="isActive(currentIssue == getLangIssue(val).handle)">{{ key + 1 }}</b> {{ getLangIssue(val).number || '' }}{{ getLangIssue(val).number ? ' ' : '' }}{{ getLangIssue(val).name || '' }}</span>
      </div>
  </div>
</div>
