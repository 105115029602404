import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './timeline-right.component.html',
    styleUrls: ['./timeline-right.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageTimelineRightComponent')
export class PageTimelineRightComponent extends Page implements OnInit {
    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    ngOnInit() {
        this.data.blocks = this.data.blocks.map(block => {
            const left = 'transparent ' + this.getColor(block['background_color']) + ' transparent transparent';
            const right = 'transparent transparent transparent ' + this.getColor(block['background_color']);
            block.triangleLeft = this.convertCSS(left);
            block.triangleRight = this.convertCSS(right);
            return block;
        });
    }

    convertCSS(css) {
        let input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {},
                attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    }

    setMyStyles(obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    }
}
