<div id="page-{{ id }}" class="page page-index-grid">
    <div
        class="scroller"
        [ngClass]="{single: pages.length == 1, half: pages.length == 2, three: pages.length == 3}"
    >
        <a
            *ngFor="let val of pages;let key = index;"
            (click)="goToPage(val.handle)"
            [class.gridHalf]="key != 0 && pages.length != 2"
            [class.last]="key == pages.length - 1 && pages.length % 2 == 0"
            [class.first]="key == 0"
            class="issue"
            [ngStyle]="{'background-color':getColor(nav.backgroundColor)}"
        >
            <section class="center">
                <div class="img">
                    <img *ngIf="val.thumbnail && !nova.isThumbLoading(val.thumbnail)" [src]="val.thumbnail.url" />
                    <img *ngIf="!val.thumbnail || nova.isThumbLoading(val.thumbnail)" [src]="'assets/img/templates/pages/' + val.templateName+'.svg'" />
                    <section class="inner">
                        <span [ngStyle]="{'font-family':font, 'color':getColor(nav.textColor)}"><i class="icon-open-magazine"></i><br /><small>{{ 'OPEN' | translate }}</small></span>
                    </section>
                    <figcaption [ngStyle]="{'background-color':getColor(nav.backgroundColor)}">
                        <b [ngStyle]="{'font-family':font, 'color':getColor(nav.textColor)}">{{ uiEventHandler.getContentTableEntryName(val) }}</b>
                        <p *ngIf="val.subHeader" [ngStyle]="{'font-family':font, 'color':getColor(nav.textColor)}">{{ val.subHeader }}</p>
                    </figcaption>
                </div>
            </section>
        </a>
    </div>
</div>
