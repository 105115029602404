import { EventEmitter, Injectable } from '@angular/core';
import { ColorFormatter } from '../novamag-api/Types';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SSRService } from './ssr.service';
import { Router } from '@angular/router';

@Injectable()
export class UIEventHandlerService {
    private oldMobileState: boolean;
    public arrowEvent$: EventEmitter<string>;
    public blogPage = undefined;
    public column = 'columns';
    public columnChanged = false;
    public columnizeEnabled = true;
    public columnizeEvent$: EventEmitter<boolean>;
    public CONTENT_TABLE: string;
    public cookieEvent$: EventEmitter<boolean>;
    public currentBlogPost = undefined;
    public customContentTable = false;
    public customIcons = false;
    public disableActionNav = false;
    public disableNav = false;
    public fontEvent$: EventEmitter<number>;
    public fontSize = 1;
    public galleryPopup = false;
    public hideContentCloseIcon = false;
    public hideTitleBar = false;
    public logo;
    public mute = false;
    public muteEvent$: EventEmitter<boolean>;
    public resizeEvent$: EventEmitter<any>;
    public screenshotEvent$: EventEmitter<number>;
    public showCloseIcon = true;
    public showContactForm = false;
    public showContentTable = false;
    public showContentTableFirstTime = false;
    public showCookiePopup = false;
    public showDisabledMsg = true;
    public showLandingSidebar = false;
    public showMenu = false;
    public showNavArrows = false;
    public showNewsletterForm = false;
    public showPaidContentDialog = false;
    public showSearch = false;
    public showSwipeOverlay = true;
    public showTerms = false;
    public textFloor = false;
    public textLoading = false;
    public topClose = false;
    public videoBg = false;

    constructor(private ssr: SSRService, private router: Router) {
        this.arrowEvent$ = new EventEmitter();
        this.columnizeEvent$ = new EventEmitter();
        this.fontEvent$ = new EventEmitter();
        this.resizeEvent$ = new EventEmitter();
        this.screenshotEvent$ = new EventEmitter();
        this.muteEvent$ = new EventEmitter();
        this.oldMobileState = this.isMobile();
        this.cookieEvent$ = new EventEmitter();

        if (typeof window !== 'undefined') {
            fromEvent(window, 'orientationchange')
                .pipe(debounceTime(100))
                .subscribe(() => location.reload());

            fromEvent(window, 'resize')
                .pipe(debounceTime(100))
                .subscribe(event => {
                    if (this.oldMobileState !== this.isMobile()) {
                        location.reload();
                    }
                    this.resizeEvent$.emit(event);
                });
            if (this.isBadIos()) {
                $('html').addClass('no-vh-support');
            }
        }
    }

    togglePaidContentDialog(visible?: boolean) {
        this.showPaidContentDialog = visible || !this.showPaidContentDialog;
    }

    onColumnizeEvent(value?: boolean) {
        this.columnizeEvent$.emit(value);
    }

    onArrowEvent(value: string) {
        this.arrowEvent$.emit(value);
    }

    onFontEvent(value: number) {
        this.fontEvent$.emit(value);
    }

    onScreenShotEvent(value: number) {
        this.screenshotEvent$.emit(value);
    }

    setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        const expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';

        this.cookieEvent$.emit(cname);
    }

    deleteCookie(cname) {
        document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    parseUrl() {
        const params = this.router.routerState.root.firstChild.params['value'];
        let url = params.langHandle + '/';
        url += params.issueHandle + '/';
        url += params.pageHandle + '/';
        if (params.contentHandle) {
            url += params.contentHandle;
        }

        return url;
    }

    getCookies() {
        let orgCookie;
        if (this.ssr.isBrowser) {
            orgCookie = document.cookie;
        } else {
            orgCookie = this.ssr.getRequest().headers.cookie;
        }

        return orgCookie;
    }

    getCookie(cname) {
        const orgCookie = this.getCookies();

        if (!orgCookie) {
            return;
        }

        const name = cname + '=';
        const decodedCookie = decodeURIComponent(orgCookie);
        const ca = decodedCookie.split(';');

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }

        return '';
    }

    toggleMute() {
        this.mute = !this.mute;
        this.muteEvent$.emit(this.mute);
    }

    getContentTableEntryName(entry) {
        if (entry.index_title) {
            return entry.index_title;
        }
        if (entry.templateName === 'multiple-background' && entry.medias && entry.medias[0]) {
            return entry.medias[0].header || entry.medias[0].name;
        }
        if (entry.templateName === 'index-classic') {
            if (!entry.header) {
                return this.CONTENT_TABLE;
            }

            return entry.header;
        }

        return entry.header || entry.name;
    }

    getColor(data, alpha?) {
        if (alpha) {
            data.a -= alpha;
        }
        const c = new ColorFormatter(data);

        return c.rgba();
    }

    /**
     * Viewport smaller 768px?
     * @returns Boolean
     */
    isMobile() {
        if (typeof window === 'undefined') {
            return false;
        }

        return window.innerWidth < 768;
    }

    /**
     * Viewport smaller 991px?
     * @returns Boolean
     */
    isTablet() {
        if (typeof window === 'undefined') {
            return false;
        }

        return window.innerWidth < 991;
    }

    isTouchDevice() {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    }

    isWide() {
        if (typeof window === 'undefined') {
            return false;
        }

        return window.innerWidth < 1200;
    }

    calcFontSize(size) {
        size = this.normalizeSize(size);

        return `${size}px`;
    }

    calcFontSizeSmall(size) {
        return this.calcFontSize(size);
    }

    normalizeSize(size) {
        if (!size || size < 6) size = 16;
        return size;
    }

    hideSwipeOverlay() {
        if (typeof window !== 'undefined' && this.showSwipeOverlay) {
            setTimeout(() => {
                this.showSwipeOverlay = false;
            }, 3000);
        }
    }

    isBadIos() {
        const matches = navigator.userAgent.match(/(iPhone|iPad|iPod);[^OS]*OS (\d)/);

        if (!matches) {
            return false;
        }

        return parseInt(matches[2], 10) < 8;
    }

    resizeVideos() {
        $("iframe[src^='https://www.youtube.com'], iframe[src^='https://player.vimeo.com']").each(function() {
            if (!$(this).data('aspectRatio')) {
                // if no width / height is given use 16:9 as aspect radio
                if (!$(this).attr('width')) {
                    $(this).data('aspectRatio', 9 / 16);
                } else {
                    $(this)
                        .data('aspectRatio', parseInt($(this).attr('height'), 10) / parseInt($(this).attr('width'), 10))
                        .removeAttr('height')
                        .removeAttr('width');
                }
            }
            const newWidth = $(this).width();
            $(this).height(newWidth * $(this).data('aspectRatio'));
        });
    }

    goToFirst() {
        const params = this.router.routerState.root.firstChild.params['value'];
        this.router.navigate(['/', params['langHandle'], params['issueHandle']], {
            queryParamsHandling: 'preserve'
        });
    }
}
