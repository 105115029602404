/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./nova-nav-item.component";
import * as i2 from "../../_services/nova-api.service";
import * as i3 from "../../_services/magazine.service";
import * as i4 from "../../_services/uievent-handler.service";
import * as i5 from "@angular/platform-browser";
var styles_NovaNavItemComponent = [];
var RenderType_NovaNavItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NovaNavItemComponent, data: {} });
export { RenderType_NovaNavItemComponent as RenderType_NovaNavItemComponent };
export function View_NovaNavItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵncd(null, 0)], null, null); }
export function View_NovaNavItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "nova-nav-item", [], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onMouseEnter($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onMouseLeave($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).onTouchStart($event.target) !== false);
        ad = (pd_2 && ad);
    } if (("touchend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1).onTouchEnd($event.target) !== false);
        ad = (pd_3 && ad);
    } return ad; }, View_NovaNavItemComponent_0, RenderType_NovaNavItemComponent)), i0.ɵdid(1, 245760, null, 0, i1.NovaNavItemComponent, [i2.NovaAPIService, i3.MagazineService, i4.UIEventHandlerService, i5.DomSanitizer, i0.ElementRef, i0.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NovaNavItemComponentNgFactory = i0.ɵccf("nova-nav-item", i1.NovaNavItemComponent, View_NovaNavItemComponent_Host_0, { style: "style", activeStyle: "activeStyle", forceClick: "forceClick", hoverable: "hover", inverted: "inverted" }, {}, ["*"]);
export { NovaNavItemComponentNgFactory as NovaNavItemComponentNgFactory };
