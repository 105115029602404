import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { ColorFormatter } from '../../novamag-api/Types';
import { Subscription } from 'rxjs';
import { MagazineService } from 'app/_services/magazine.service';
import { Router, NavigationEnd } from '@angular/router';
import { SSRService } from 'app/_services/ssr.service';

@Component({
    selector: 'nova-title-bar',
    templateUrl: './nova-title-bar.component.html'
})
export class NovaTitleBarComponent implements OnInit, OnDestroy {
    @Input() public issue;

    public nav;
    public font;
    public magData;
    public selectStartPage: boolean;

    private sub: Subscription;
    private subFont: Subscription;
    private subRouter: Subscription;
    private logoSub: Subscription;

    constructor(
        public nova: NovaAPIService,
        private ssr: SSRService,
        private router: Router,
        private mag: MagazineService,
        public ui: UIEventHandlerService
    ) {}

    ngOnInit() {
        this.sub = this.mag.getMagazine().subscribe(data => {
            this.magData = data;
            this.nav = data.navigation;
            this.selectStartPage = data.selectStartPage === 'archiv';
            this.subFont = this.nova.getFont(data.navigation.font).subscribe(fontData => {
                this.font = fontData.name;
            });
        });
    }

    openLandingpagePreview() {
        this.ui.showLandingSidebar = true;
    }

    openContactForm() {
        this.ui.showContactForm = true;
    }

    getColor(c) {
        return new ColorFormatter(c).rgba();
    }

    mobileBG() {
        if (this.ui.isMobile()) {
            return new ColorFormatter(this.nav.backgroundColor).rgba();
        }
    }

    ngOnDestroy() {
        this.sub && this.sub.unsubscribe();
        this.subFont && this.subFont.unsubscribe();
        this.subRouter && this.subRouter.unsubscribe();
        this.logoSub && this.logoSub.unsubscribe();
    }

    convertCSS(css) {
        const input = css.toString();
        if (input.indexOf(':') > -1) {
            const result = {};
            const attributes = input.split(';');
            for (let i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    const entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }

            return result;
        }

        return css;
    }

    setMyStyles(obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    }
}
