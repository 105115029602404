<div id="page-{{ id }}" class="page page-topic-teaser-reverse">
  <nova-bg [data]="data" [forceVideo]="true"></nova-bg>
  <nova-scrollbar-holder>
    <div class="scroller">
        <nova-mobile-img [data]="data" class="show-for-small-only left"></nova-mobile-img>
      <div class="bg animated fadeIn clear" [ngStyle]="{'backgroundColor':getColor(data['box_color'])}">
        <nova-title [class.noBg]="!data.background_media" [data]="data"></nova-title>
        <nova-text-element class="con" [data]="data.text" (openGallery)="gallery.openSlider($event)"></nova-text-element>
        <nova-content-links class="content-links" *ngIf="data.links && data.links.length > 0" [floors]="data.links"></nova-content-links>
      </div>
    </div>
  </nova-scrollbar-holder>
  <nova-text-gallery [medias]="data.textImages" #gallery></nova-text-gallery>
</div>
