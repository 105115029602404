<div id="page-{{ id }}" class="page page-cover-text">
	<nova-bg [data]="data" [forceVideo]="true"></nova-bg>
	<div class="flexbox" [ngStyle]="setMyStyles(flexboxStyle)">
		<div [ngStyle]="setMyStyles(titleStyle)" class="flex-item title-item">
			<h3 *ngIf="data.label" class="dateline">
				<nova-element [data]="data" name="label"></nova-element>
			</h3>
			<h1 *ngIf="data.header">
				<nova-element [data]="data" name="header"></nova-element>
			</h1>
			<h2 *ngIf="data.subHeader">
				<nova-element [data]="data" name="subHeader"></nova-element>
			</h2>
			<p class="text">
				<nova-element [data]="data" name="text"></nova-element>
			</p>
		</div>
	</div>
</div>
