import * as tslib_1 from "tslib";
import { ElementRef, OnInit, QueryList, Renderer2 } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import { SharedHotelHelperService } from '../hotel-detail-standard/shared-hotel-helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IndexHotelFilterPipe } from './filter-tags.pipe';
import { setRemainingFilters, setFilters } from '../index-trips/index-trips.component';
var PageIndexHotelComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageIndexHotelComponent, _super);
    function PageIndexHotelComponent(nova, uiEventHandler, sanitizer, meta, route, router, hotelHelper, media, title, renderer, filterPipe) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.sanitizer = sanitizer;
        _this.meta = meta;
        _this.route = route;
        _this.router = router;
        _this.hotelHelper = hotelHelper;
        _this.media = media;
        _this.title = title;
        _this.renderer = renderer;
        _this.filterPipe = filterPipe;
        _this.filteredData = [];
        _this.allFilters = [];
        _this.allCountryFilters = [];
        _this.selectedFilters = [];
        _this.selectedCountryFilters = [];
        _this.selectedStars = [];
        _this.stars = [];
        _this.defaultStars = [
            {
                itemName: '6 Sterne',
                id: '6'
            },
            {
                itemName: '5 Sterne',
                id: '5'
            },
            {
                itemName: '4 Sterne',
                id: '4'
            },
            {
                itemName: '3 Sterne',
                id: '3'
            },
            {
                itemName: '2 Sterne',
                id: '2'
            },
            {
                itemName: '1 Stern',
                id: '1'
            }
        ];
        _this.dropdownSettings = {
            singleSelection: false,
            text: 'Nach Interessen filtern',
            enableCheckAll: false,
            showCheckbox: true,
            enableSearchFilter: true,
            classes: 'nova-multiselect'
        };
        _this.starDropdownSettings = {
            singleSelection: false,
            text: 'Nach Hotelkategorie filtern',
            enableCheckAll: false,
            showCheckbox: true,
            enableSearchFilter: false,
            classes: 'nova-multiselect'
        };
        _this.countryDropdownSettings = {
            singleSelection: false,
            text: 'Nach Regionen filtern',
            enableCheckAll: false,
            showCheckbox: true,
            enableSearchFilter: true,
            classes: 'nova-multiselect'
        };
        _this.allFilterStrings = [];
        _this.allCountryFilterStrings = [];
        var path = window.location.pathname;
        var filter = sessionStorage.getItem('filter:' + path);
        if (filter) {
            var filterData = JSON.parse(filter);
            if (filterData.selectedFilters) {
                _this.selectedFilters = filterData.selectedFilters;
            }
            if (filterData.selectedCountryFilters) {
                _this.selectedCountryFilters = filterData.selectedCountryFilters;
            }
            if (filterData.selectedStars) {
                _this.selectedStars = filterData.selectedStars;
            }
        }
        return _this;
    }
    PageIndexHotelComponent.prototype.setRemainingFilters = function (contents) {
        var allFilterStrings = [];
        contents.forEach(function (content) { return (allFilterStrings = tslib_1.__spread(allFilterStrings, (content.hotel_labels ? content.hotel_labels : []))); });
        this.allFilters = allFilterStrings
            .filter(function (item, pos) { return allFilterStrings.indexOf(item) === pos; })
            .sort(function (a, b) { return a.toLowerCase().localeCompare(b.toLowerCase()); })
            .map(function (item) { return ({ id: item, itemName: item }); });
        var path = window.location.pathname;
        var filter = sessionStorage.getItem('filter:' + path);
        if (filter) {
            var filterData = JSON.parse(filter);
            if (filterData.selectedFilters) {
                this.selectedFilters = filterData.selectedFilters;
            }
            if (filterData.selectedCountryFilters) {
                this.selectedCountryFilters = filterData.selectedCountryFilters;
            }
            if (filterData.selectedStars) {
                this.selectedStars = filterData.selectedStars;
            }
        }
    };
    PageIndexHotelComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.data.contents.forEach(function (content) {
            if (!content.stars || (content.stars && content.stars.length <= 0)) {
                content.stars = '0';
            }
            return _this.hotelHelper.handleTagInputs(content);
        });
        this.allFilters = setFilters(this.allFilterStrings, this.data.contents, 'hotel_labels');
        this.allCountryFilters = setFilters(this.allCountryFilterStrings, this.data.contents, 'country_filters');
        this.setStars(this.data.contents);
        this.filter();
    };
    PageIndexHotelComponent.prototype.setStars = function (contents) {
        this.stars = this.defaultStars.filter(function (star) { return contents.map(function (content) { return content.stars.slice(0, 1); }).indexOf(star.id) > -1; });
    };
    PageIndexHotelComponent.prototype.filter = function () {
        this.filteredData = this.filterPipe.transform(this.data.contents, this.selectedFilters, this.selectedStars, this.selectedCountryFilters);
        // FTI-83 - dont show hotels without name
        this.filteredData = this.filteredData.filter(function (hotel) { return hotel.header !== undefined; });
        setRemainingFilters(this.allFilters, this.filteredData, 'hotel_labels');
        this.standard = this.filteredData.filter(function (hotel) { return hotel.templateName === 'hotel-detail-standard'; });
        this.premium = this.filteredData.filter(function (hotel) { return hotel.templateName === 'hotel-detail-premium'; });
        var path = window.location.pathname;
        sessionStorage.setItem('filter:' + path, JSON.stringify({
            selectedFilters: this.selectedFilters,
            selectedCountryFilters: this.selectedCountryFilters,
            selectedStars: this.selectedStars
        }));
    };
    PageIndexHotelComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        if (this.data['font_bold']) {
            this.nova.getFont(this.data['font_bold']).subscribe(function (fontData) {
                _this.fontSizeBoldElements &&
                    _this.fontSizeBoldElements.toArray().map(function (element) {
                        if (!element) {
                            return;
                        }
                        _this.renderer.setStyle(element.nativeElement, 'font-family', fontData.name);
                    });
            });
        }
    };
    PageIndexHotelComponent.prototype.goTo = function (id) {
        var floor = this.data.contents.filter(function (content) { return content._id === id; })[0];
        var params = this.route.params['value'];
        this.router.navigate(['/', params['langHandle'], params['issueHandle'], params['pageHandle'], floor.handle], {
            queryParamsHandling: 'preserve'
        });
    };
    PageIndexHotelComponent = tslib_1.__decorate([
        NovaTemplate('PageIndexHotelComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            DomSanitizer,
            Meta,
            ActivatedRoute,
            Router,
            SharedHotelHelperService,
            MediaService,
            Title,
            Renderer2,
            IndexHotelFilterPipe])
    ], PageIndexHotelComponent);
    return PageIndexHotelComponent;
}(Page));
export { PageIndexHotelComponent };
