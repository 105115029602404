import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ColorFormatter } from '../../../novamag-api/Types';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './article-teaser.component.html',
    styleUrls: ['./article-teaser.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageArticleTeaserComponent')
export class PageArticleTeaserComponent extends Page implements OnInit {
    public fonts;

    public hide = false;
    public headerLines: string[];

    @ViewChild('headline') public headline: ElementRef;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    ngOnInit() {
        if (this.data['header_fonts']) {
            this.nova.getFont(this.data['header_fonts']).subscribe(fontData => {
                this.fonts = fontData.name;
            });
        }
    }

    boxStyle() {
        let arr = '';
        if (this.data['background_box_color']) {
            const c = new ColorFormatter(this.data['background_box_color']);
            arr += 'background-color: ' + c.rgba() + ';';
        }
        return this.convertCSS(arr);
    }

    boxStyleBottom() {
        let arr = '';

        if (this.data['background_bottom_box_color']) {
            const c = new ColorFormatter(this.data['background_bottom_box_color']);
            arr += 'background-color: ' + c.rgba() + ';';
        }

        return this.convertCSS(arr);
    }

    headerStyle() {
        let arr = '';

        if (this.data['header_color']) {
            const c = new ColorFormatter(this.data['header_color']);
            arr += 'color: ' + c.rgb() + ';';
        }
        if (this.data['header_bgColor']) {
            const c = new ColorFormatter(this.data['header_bgColor']);
            arr += 'background-color: ' + c.rgba() + ';';
        }
        if (this.fonts !== '') {
            arr += 'font-family: ' + this.fonts + ';';
        }

        return this.convertCSS(arr);
    }

    convertCSS(css) {
        const input = css.toString();
        if (input.indexOf(':') > -1) {
            const result = {};
            const attributes = input.split(';');
            for (let i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    const entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    }

    setMyStyles(obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    }
}
