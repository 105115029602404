import { Component, Input, OnInit } from '@angular/core';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { ColorFormatter } from '../../novamag-api/Types';

@Component({
    selector: 'nova-bg',
    templateUrl: './nova-bg.component.html',
    styleUrls: ['./nova-bg.component.scss']
})
export class NovaBgComponent implements OnInit {
    @Input() public data;
    @Input() public version;
    @Input() public fallback: boolean;
    @Input() public copyright = true;
    @Input() public forceVideo = false;
    @Input() public media;
    @Input() public bgColor;
    @Input() public backgroundPosition = 'center center';

    public mediaId: string;
    public copyrightPosition = 'right';

    constructor(public uiEventHandler: UIEventHandlerService) {}

    ngOnInit() {
        this.mediaId = this.media || this.data['background_media'];

        if ('contentPosition' in this.data && 'box_variant' in this.data) {
            if (this.data['contentPosition'].includes('right') && this.data['box_variant'] === 'expanded') {
                this.copyrightPosition = 'left';
            }
        }

        this.backgroundPosition =
            this.data['background_position'] && this.data['background_position'].split('-').join(' ');

        if (this.uiEventHandler.isTablet() && this.data.tabletMedia) {
            this.mediaId = this.data.tabletMedia;
            this.version = this.data.tabletMediaVersion || this.version;
        }

        if (this.uiEventHandler.isMobile() && this.data.mobileMedia) {
            this.mediaId = this.data.mobileMedia;
            this.version = this.data.mobileMediaVersion || this.version;
        }
    }

    getStyle() {
        if (typeof window === 'undefined') {
            return;
        }

        let style = '';

        if (this.data['background_color']) {
            const c = new ColorFormatter(this.data['background_color']);
            style += 'background-color: ' + c.rgba() + ';';
        }

        if (this.bgColor) {
            const c = new ColorFormatter(this.bgColor);
            style += 'background-color: ' + c.rgba() + ';';
        }

        if (this.data['background_blur']) {
            const blur = this.data['background_blur'] / 10;
            style += `
        filter: blur(${blur}px);
        -webkit-filter: blur(${blur}px);
        -moz-filter: blur(${blur}px);
        -o-filter: blur(${blur}px);
        -ms-filter: blur(${blur}px);
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
      `;
        }

        return this.convertCSS(style);
    }

    convertCSS(css) {
        const input = css.toString();
        if (input.indexOf(':') > -1) {
            const result = {};
            const attributes = input.split(';');
            for (let i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    const entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }

            return result;
        }

        return css;
    }

    setMyStyles(obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    }
}
