import * as tslib_1 from "tslib";
import { Meta, Title } from '@angular/platform-browser';
import { of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { RenderService } from './render.service';
import { PreloaderService } from './preloader.service';
import { map, shareReplay } from 'rxjs/operators';
import * as _ from 'underscore';
import { SSRService } from 'app/_services/ssr.service';
import { MagazineService } from 'app/_services/magazine.service';
import { NetworkService } from 'app/_services/network.service';
import { FontService } from 'app/_services/font.service';
import { FormService } from 'app/_services/form.service';
import { MediaService } from 'app/_services/media.service';
import { TranslateService } from '@ngx-translate/core';
var NovaAPIService = /** @class */ (function () {
    function NovaAPIService(meta, font, form, media, ssr, magazineService, network, title, translate, router, renderService, preloader, route) {
        this.meta = meta;
        this.font = font;
        this.form = form;
        this.media = media;
        this.ssr = ssr;
        this.magazineService = magazineService;
        this.network = network;
        this.title = title;
        this.translate = translate;
        this.router = router;
        this.renderService = renderService;
        this.preloader = preloader;
        this.route = route;
        this.pages = [];
        this.pageIds = new Map();
        this.contentIds = new Map();
        this.isScreenShot = false;
        this.trackingEnabled = false;
        if (this.ssr.isBrowser) {
            var screenShot = this.ssr.getParameterByName('screenShot');
            this.isScreenShot = screenShot === 'true';
        }
        else {
            this.isScreenShot = false;
        }
    }
    NovaAPIService.prototype.isThumbLoading = function () {
        return false;
    };
    NovaAPIService.prototype.getReqUrl = function () {
        return this.ssr.getRequestUrl();
    };
    NovaAPIService.prototype.setMetaData = function (titleClear, subtitleClear, thumb, keywords) {
        var url;
        if (typeof window === 'undefined') {
            url = this.ssr.getRequestUrl();
        }
        else {
            url = window.location.href;
        }
        var subtitle = '';
        if (subtitleClear) {
            subtitle = subtitleClear;
        }
    };
    NovaAPIService.prototype.addTag = function (tag) {
        return this.ssr.addTag(tag);
    };
    NovaAPIService.prototype.addOrSetTag = function (_a) {
        var name = _a.name, itemprop = _a.itemprop, property = _a.property, content = _a.content, canonical = _a.canonical;
        if (name) {
            if (this.meta.getTag("name='" + name + "'")) {
                this.meta.updateTag({ name: name, content: content }, "name='" + name + "'");
            }
            else {
                this.meta.addTag({ name: name, content: content });
            }
        }
        if (itemprop) {
            if (this.meta.getTag("itemprop='" + itemprop + "'")) {
                this.meta.updateTag({ itemprop: itemprop, content: content }, "itemprop='" + itemprop + "'");
            }
            else {
                this.meta.addTag({ itemprop: itemprop, content: content });
            }
        }
        if (property) {
            if (this.meta.getTag("property='" + property + "'")) {
                this.meta.updateTag({ property: property, content: content }, "property='" + property + "'");
            }
            else {
                this.meta.addTag({ property: property, content: content });
            }
        }
    };
    NovaAPIService.prototype.preloadPageMedia = function (imgArr) {
        var _this = this;
        imgArr = imgArr ? imgArr : this.media.getRequestedUrls();
        var mediaLoaded = false;
        return new Promise(function (resolve) {
            _this.preloader.preloadImages(imgArr).then(function () {
                setTimeout(function () {
                    if (!mediaLoaded) {
                        mediaLoaded = true;
                        resolve(imgArr);
                    }
                }, 2000);
            });
            setTimeout(function () {
                if (!mediaLoaded) {
                    mediaLoaded = true;
                    resolve(imgArr);
                }
            }, 10000);
        });
    };
    NovaAPIService.prototype.getPageData = function () {
        var _this = this;
        return this.getCurrentIssue().pipe(map(function () {
            var pageHandle = _this.router.routerState.root.firstChild.params['value'].pageHandle;
            var contentHandle = _this.router.routerState.root.firstChild.params['value'].contentHandle;
            var id = 0;
            var contentId;
            if (pageHandle) {
                _this.pages.forEach(function (page, index) {
                    if (page.handle === pageHandle) {
                        id = index;
                    }
                    if (page.templateName === 'blog-article') {
                        _this.parentPage = _this.pages.find(function (p) { return p._id === page.pageId; });
                    }
                });
            }
            if (contentHandle) {
                contentId = _this.pages[id].contents.findIndex(function (content) { return content.handle === contentHandle; });
                var parentPage = pageHandle && contentHandle && _this.pages.find(function (page) { return page.handle === pageHandle; });
                return {
                    id: id,
                    contentId: contentId,
                    data: _this.magazineService.formatHtml(tslib_1.__assign({}, _this.pages[id].contents[contentId], { parentPage: parentPage }))
                };
            }
            else {
                // triggers rendering bug
                _this.router.navigate(['/', _this.issue.language, _this.issue.handle, _this.pages[id]['handle']], {
                    queryParamsHandling: 'preserve'
                });
                return {
                    id: id,
                    data: _this.magazineService.formatHtml(tslib_1.__assign({}, _this.pages[id], { parentPage: _this.parentPage }))
                };
            }
        }));
    };
    NovaAPIService.prototype.filterIssuesByLang = function (issueArr) {
        var _this = this;
        return issueArr
            .map(function (issue) {
            var langData;
            if (typeof issue.languagesMap[_this.lang] !== 'undefined') {
                langData = issue.languagesMap[_this.lang];
                return Object.assign(issue, langData);
            }
            return;
        })
            .filter(function (issue) { return issue !== undefined; });
    };
    NovaAPIService.prototype.mapIssue = function () {
        this.issues = this.filterIssuesByLang(this.issuesOrg);
    };
    NovaAPIService.prototype.getCurrentIssue = function (magazineLanguage) {
        var _this = this;
        var params = this.router.routerState.root.firstChild.params['value'];
        if (this.issue && params.issueHandle === this.issue.handle && params.langHandle === this.issue.language) {
            if (!this.ssr.getToken()) {
                return of(this.issue);
            }
        }
        return this.magazineService.getIssue(params.issueHandle, params.langHandle || magazineLanguage).pipe(map(function (issue) {
            _this.pages = issue.pages;
            _this.pageIds = issue.pageIds;
            _this.contentIds = issue.contentIds;
            _this.issue = issue;
            return issue;
        }), map(function () {
            if (_this.ssr.isBrowser) {
                _this.trackingEnabled = true;
            }
            _this.setLoaded(_this.issue['fonts'], _this.issue.fonts);
            return _this.issue;
        }), shareReplay(1));
    };
    NovaAPIService.prototype.setLoaded = function (fontsArr, renderedFonts) {
        if (renderedFonts === void 0) { renderedFonts = []; }
        fontsArr = fontsArr.concat(renderedFonts);
        fontsArr = _.uniq(fontsArr);
        fontsArr = _.compact(fontsArr);
        this.fonts = fontsArr;
    };
    NovaAPIService.prototype.findLang = function () {
        var newlang;
        if (!this.ssr.isBrowser) {
            var req = this.ssr.getRequest();
            if (req.headers['accept-language']) {
                newlang = req.headers['accept-language'].split(';')[0].split(',')[0];
                if (newlang.search('-') !== -1) {
                    newlang = newlang.substr(0, newlang.search('-'));
                }
            }
        }
        else {
            newlang = navigator['language'] || navigator['userLanguage'];
            if (newlang.search('-') !== -1) {
                newlang = newlang.substr(0, newlang.search('-'));
            }
        }
        return newlang;
    };
    NovaAPIService.prototype.getMagazineUrl = function () {
        return this.network.getMagazineUrl();
    };
    NovaAPIService.prototype.getApiUrl = function () {
        return this.network.getAPIUrl();
    };
    NovaAPIService.prototype.getAliasUrl = function () {
        return this.network.getAliasUrl();
    };
    NovaAPIService.prototype.getImgUrl = function () {
        return this.network.getIMGUrl();
    };
    NovaAPIService.prototype.getCurrentIssueHandle = function () {
        var params = this.router.routerState.root.firstChild.params['value'];
        var issueHandle = params.issueHandle;
        return issueHandle;
    };
    NovaAPIService.prototype.findPage = function (id) {
        return this.pageIds.get(id);
    };
    NovaAPIService.prototype.findContent = function (id) {
        return this.contentIds.get(id);
    };
    NovaAPIService.prototype.getFont = function (fontName) {
        return this.font.getFont(fontName);
    };
    NovaAPIService.prototype.print = function () {
        var _this = this;
        this.getPageData().subscribe(function (data) {
            if (typeof data['data']['pdf_version'] !== 'undefined' && data['data']['pdf_version']) {
                _this.media.getMedia(data['data']['pdf_version']).subscribe(function (media) {
                    window.open(media.url, '_blank');
                });
            }
            else {
                var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
                var url = _this.router.url;
                var printFrame_1 = document.getElementById('printIframe');
                if (isIE11) {
                    var printWin = window.open('/amp/print?from=' + url);
                    printWin.focus();
                    if (printWin.onload) {
                        printWin.onload = function () {
                            window.print();
                            window.onafterprint = function () {
                                window.close();
                            };
                        };
                    }
                }
                else {
                    if (printFrame_1) {
                        printFrame_1.src = '/amp/print?from=' + url;
                    }
                    else {
                        printFrame_1 = document.createElement('iframe');
                        printFrame_1.id = 'printIframe';
                        printFrame_1.src = '/amp/print?from=' + url;
                        document.body.appendChild(printFrame_1);
                    }
                    setTimeout(function () {
                        printFrame_1.contentWindow.print();
                    }, 0);
                }
            }
        });
    };
    return NovaAPIService;
}());
export { NovaAPIService };
