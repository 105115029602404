import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SSRService } from './ssr.service';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';

@Injectable()
export class NetworkService {
    private magazineUrl: string;
    private apiUrl: string;
    private imgUrl: string;
    private aliasUrl: string;

    constructor(private http: HttpClient, private ssr: SSRService) {
        let url = '';

        if (this.ssr.isBrowser) {
            url = window.location.hostname;
        } else {
            const req = this.ssr.getRequest();
            const arr = req['headers']['host'].split(':');
            url = arr[0];
        }

        url = url === 'localhost' ? environment['debugUrl'] : url;
        this.parseUrl(url);

        this.imgUrl = environment['imgUrl'];
        this.aliasUrl = environment['aliasUrl'];
    }

    public createInterval(): Observable<any> {
        return of(true);
    }

    private parseUrl(url) {
        this.magazineUrl = url;
        this.apiUrl = environment['apiUrl'];
    }

    public getMagazineUrl(): string {
        return this.magazineUrl;
    }

    public getAPIUrl(): string {
        return this.apiUrl;
    }

    public getIMGUrl(): string {
        return this.imgUrl;
    }

    public getAliasUrl(): string {
        return this.aliasUrl;
    }

    public fetch(url: string, options?: any): Observable<any> {
        const requestUrl = this.getUrl(url);
        let cacheHeader = {};

        if (this.ssr.getParameterByName('preview')) {
            console.log('Fetching:', requestUrl);
            cacheHeader = { 'Cache-Control': 'no-store' };
        }

        return this.http.get(
            requestUrl,
            {
                ...options,
                headers: cacheHeader
            } || {
                headers: cacheHeader
            }
        );
    }

    public post(url: string, data?: any): Observable<any> {
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http.post(this.getUrl(url), 'data=' + encodeURIComponent(JSON.stringify(data)), options);
    }

    public postRaw(url: string, data?: any): Observable<any> {
        return this.http.post(this.getUrl(url), data);
    }

    public getUrl(url: string): string {
        if (this.ssr.getToken()) {
            if (url.includes('?')) {
                if (!url.includes('preview=true')) {
                    url += '&preview=true';
                }
            } else {
                url += '?preview=true';
            }
        }

        if (this.ssr.getParameterByName('token')) {
            if (url.includes('?')) {
                url += `&`;
            } else {
                url += `?`;
            }
            url += `token=${this.ssr.getParameterByName('token')}`;
        }

        return `${this.apiUrl}v1/${url}`;
    }
}
