<div id="page-{{ id }}" class="page page-cover-atlas-copco">
	<nova-bg [data]="data" [forceVideo]="true"></nova-bg>
	<div class="title-box">
		<div [class.white]="!blue" class="blue-box"></div>
		<div [class.white]="!blue" class="con">
			<img *ngIf="blue" src="assets/img/templates_assets/cover-atlas-copco/logo-white.svg" alt="Logo" />
			<img *ngIf="!blue" src="assets/img/templates_assets/cover-atlas-copco/logo-black.svg" alt="Logo" />
			<nova-title [data]="data"></nova-title>
		</div>
	</div>
</div>
