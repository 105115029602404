<div id="page-{{ id }}" class="page page-timeline-classic">
    <nova-bg [data]="data" [forceVideo]="true"></nova-bg>
    <nova-scrollbar-holder>
        <div class="scroller">
            <div class="center clear">
                <nova-title [data]="data"></nova-title>
                <div class="timeline">
                    <div *ngFor="let block of data.blocks;" class="box" [class.right]="block.showSide == 'right'" [class.left]="block.showSide == 'left'">
                        <h2 *ngIf="block.header">
                            <nova-element [data]="block" name="header"></nova-element>
                        </h2>
                        <h3 *ngIf="block.subHeader">
                            <nova-element [data]="block" name="subHeader"></nova-element>
                        </h3>
                        <div class="box-content" [ngStyle]="{'background-color':getColor(block['background_color'])}">
                            <span class="triangle-left" [ngStyle]="{'border-color':block.triangleLeft}"></span>
                            <span class="triangle-right" [ngStyle]="{'border-color':block.triangleRight}"></span>
                            <nova-text-element (openGallery)="gallery.openSlider($event)" [data]="block.text"></nova-text-element>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nova-scrollbar-holder>
    <nova-text-gallery [medias]="data.textImages" #gallery></nova-text-gallery>
</div>
