import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'nova-contactform-field',
    templateUrl: './nova-contactform-field.component.html',
    styleUrls: ['./scss/nova-contactform-field.component.scss']
})
export class NovaContactFormFieldComponent implements OnInit {
    @Input() field: any;
    @Input() form: FormGroup;
    @Input() formSubmitted: boolean;
    @Input() fontBold: string;

    minDate = new Date();

    ngOnInit() {
        const field = this.field;

        if (field && field.disabled === 'true') {
            this.form.controls[field.id].disable();
        }
    }

    get isValid() {
        const field = this.form.controls[this.field.id];

        if (field.disabled || !this.formSubmitted) {
            return;
        }

        if (!field.valid) {
            if (field.hasError('pattern')) {
                return `${this.field.label} ist keine gültige E-Mail Adresse.`;
            }

            if (field.hasError('matDatepickerMin')) {
                return `Die ${this.field.label} darf nicht in der Vergangenheit liegen.`;
            }
            return `${this.field.label} ist ein Pflichtfeld.`;
        }
    }

    get label() {
        return this.field.required === 'true' ? `* ${this.field.label}` : this.field.label;
    }
}
