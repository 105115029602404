<div class="royalSlider rsDefault" #royalSlider *ngIf="loaded">
    <div *ngFor="let val of mediaData; let i = index;" class="my-slide" [class.org]="val.org.original || original">

        <img *ngIf="!val.video" class="rsImg" [src]="val.url" />
        <img *ngIf="val.video" class="rsImg" [src]="val.url" [attr.data-lokal]="val.lokal" [attr.data-rsVideo]="val.videoSrc" />

        <div class="rsCaption">
            <div class="num">{{ i + 1 }}&nbsp;&nbsp;/&nbsp;&nbsp;{{ len() }}</div>
            <div class="caption-container">
                <nova-element *ngIf="val.org.name && !val.org.header" [data]="val.org" name="name"></nova-element>
                <nova-element *ngIf="val.filename && !val.org.name && !val.org.header" [data]="val" name="filename"></nova-element>
                <nova-element *ngIf="val.org.header" [data]="val.org" name="header"></nova-element>
                <nova-element-with-children *ngIf="val.org.description || val.org.link" [data]="val.org" name="description">
                    <p>
                        <span *ngIf="val.org.description">{{val.org.description}}</span>
                        <a *ngIf="val.org.link" [href]="val.org.link" style="margin:0;display:block;">
                            {{val.org.link_text || val.org.link}}
                        </a>
                    </p>
                </nova-element-with-children>
                <a target="_blank" *ngIf="val.copyright" [attr.href]="val.copyrightUrl">&copy; {{ val.copyright }}</a>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!this.uiEventHandler.isMobile() && this.showArrows && mediaData && mediaData.length > 1" class="navigation">
    <div class="arrows">
        <div class="arrow" (click)="previousPage()">
            <i class="icon-arrow-left"></i>
        </div>
        <div class="arrow" (click)="nextPage()">
            <i class="icon-arrow-right"></i>
        </div>
    </div>
</div>

<!--$root.showSwipeOverlay = false-->
<div (click)="closeSwipeOverlay()" class="slider-swipe-gesture-overlay">
    <span class="icon-gallery-swipe"></span>
</div>
