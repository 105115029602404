import { Component, OnChanges, Input } from '@angular/core';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { MagazineService } from 'app/_services/magazine.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';

@Component({
    selector: 'nova-london-overlay-privacy',
    templateUrl: './nova-london-overlay-privacy.component.html',
    styleUrls: ['./nova-london-overlay-privacy.component.scss']
})
export class NovaLondonOverlayPrivacyComponent implements OnChanges {
    @Input() magazine;
    @Input() issue;
    @Input() public font;

    public showDefault = false;
    public showCustom = false;
    public defaultTermsText;
    public termsText;

    constructor(
        public nova: NovaAPIService,
        public mag: MagazineService,
        public uiEventHandler: UIEventHandlerService
    ) {}

    ngOnChanges() {
        this.termsText = this.issue.termsText || this.magazine.termsText;
    }

    openCookieSettings() {
        this.uiEventHandler.showCookiePopup = true;
    }
}
