import { Component, Input, Inject, PLATFORM_ID, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { ApiImage, Image } from './image.interface';
import { isPlatformBrowser } from '@angular/common';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import { NovaAPIService } from 'app/_services/nova-api.service';

@Component({
    selector: 'nova-full-image-slider',
    templateUrl: './full-image-slider.component.html',
    styleUrls: ['./full-image-slider.component.scss']
})
export class FullImageSliderComponent implements OnInit, AfterViewInit {
    @Input() images: Image[];
    @Input() apiImages: ApiImage[];
    @Input() imageVersion: string;
    @Input() currentIndex = 0;
    @Input() fullWidth? = false;

    currentImage: any;
    @ViewChild('owl') public owl: ElementRef;
    apiImageMode = false;

    private owlOptions = {
        autoplay: true,
        loop: true,
        autoplayTimeout: 7000,
        autoplaySpeed: 1000,
        items: 1,
        dots: false
    };

    private isBrowser: boolean = isPlatformBrowser(this.platformId);

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private uiEventHandler: UIEventHandlerService,
        public nova: NovaAPIService
    ) {}

    ngOnInit() {
        if (!this.images || this.apiImages) {
            this.apiImageMode = true;
        }

        this.setCurrentImage();
    }

    getImages() {
        return this.apiImageMode ? this.apiImages : this.images;
    }

    ngAfterViewInit() {
        if (this.isBrowser) {
            this.initOwlCarousels();
        }
    }

    initOwlCarousels() {
        $(this.owl.nativeElement)
            .owlCarousel({ ...this.owlOptions, startPosition: this.currentIndex })
            .on('changed.owl.carousel', event => {
                this.currentIndex = this.getFixedOwlImageIndex(event);
                this.setCurrentImage();
            });
    }

    slideLeft($event) {
        $event.stopPropagation();
        this.stopAutoSlide();
        $(this.owl.nativeElement).trigger('prev.owl.carousel');
    }

    slideRight($event) {
        $event.stopPropagation();
        this.stopAutoSlide();
        $(this.owl.nativeElement).trigger('next.owl.carousel');
    }

    stopAutoSlide() {
        $(this.owl.nativeElement).trigger('stop.owl.autoplay');
    }

    getImageProxyUrl(url) {
        let width = 600;
        let height = 400;
        if (this.isBrowser) {
            const coefficient = this.uiEventHandler.isTablet() ? 1 : 2;
            width = Math.round(window.innerWidth / (this.fullWidth ? 1 : coefficient));
            height = Math.round(window.innerHeight / (this.uiEventHandler.isTablet() ? 3 : 1));
        }
        const aspect = '1:1';
        const rt = 'fill';
        const size = `s:${Math.round(width)}:${height}:${aspect}`;

        return `${this.nova.getImgUrl()}/insecure/rt:${rt}/${size}/g:ce/plain/${url}`;
    }

    private getFixedOwlImageIndex(event: any): number {
        // https://github.com/OwlCarousel2/OwlCarousel2/issues/1203#issuecomment-225634756
        const fixedOwlImageIndex = event.item.index - event.relatedTarget._clones.length / 2;
        return fixedOwlImageIndex >= event.item.count ? event.item.count - 1 : fixedOwlImageIndex;
    }

    private setCurrentImage() {
        this.currentImage = this.apiImageMode ? this.apiImages[this.currentIndex] : this.images[this.currentIndex];
    }
}
