/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./nova-font-loader.component";
import * as i3 from "../../_services/font.service";
import * as i4 from "@angular/platform-browser";
var styles_NovaFontLoaderComponent = [];
var RenderType_NovaFontLoaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NovaFontLoaderComponent, data: {} });
export { RenderType_NovaFontLoaderComponent as RenderType_NovaFontLoaderComponent };
function View_NovaFontLoaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "link", [["rel", "stylesheet"], ["type", "text/css"]], [[8, "href", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 0, 0, currVal_0); }); }
export function View_NovaFontLoaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_NovaFontLoaderComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NovaFontLoaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "nova-font-loader", [], null, null, null, View_NovaFontLoaderComponent_0, RenderType_NovaFontLoaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.NovaFontLoaderComponent, [i3.FontService, i4.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NovaFontLoaderComponentNgFactory = i0.ɵccf("nova-font-loader", i2.NovaFontLoaderComponent, View_NovaFontLoaderComponent_Host_0, { fonts: "fonts" }, {}, []);
export { NovaFontLoaderComponentNgFactory as NovaFontLoaderComponentNgFactory };
