import * as tslib_1 from "tslib";
import { ErrorDisabledComponent } from './error/error-page.component.1';
import { ErrorPageComponent } from './error/error-page.component';
import { Routes, RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { PageNotFoundComponent } from './not-found-component';
import { PageComponent } from './pages/page.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ErrorComponent } from './error/error.component';
import { ArchiveScrollComponent } from './landing-page/templates/archive-scroll/archive-scroll.component';
import { ArchiveGridComponent } from './landing-page/templates/archive-grid/archive-grid.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_helpers/auth.guard';
import { AuthComponent } from './auth/auth.component';
var ɵ0 = {
    imprint: true
}, ɵ1 = {
    terms: true
}, ɵ2 = {
    imprint: true
}, ɵ3 = {
    terms: true
};
var routes = [
    { path: 'login', component: LoginComponent },
    { path: 'login/:code', component: LoginComponent },
    {
        path: 'login/imprint',
        component: LoginComponent,
        data: ɵ0
    },
    {
        path: 'login/terms',
        component: LoginComponent,
        data: ɵ1
    },
    { path: 'auth', component: AuthComponent },
    {
        path: 'error',
        component: ErrorComponent
    },
    {
        path: 'error/:code/:message',
        component: ErrorComponent
    },
    {
        path: 'errorPage',
        component: ErrorPageComponent
    },
    {
        path: 'errorDisabled',
        component: ErrorDisabledComponent
    },
    {
        path: 'imprint',
        component: LandingPageComponent,
        canActivate: [AuthGuard],
        data: ɵ2
    },
    {
        path: 'terms',
        component: LandingPageComponent,
        canActivate: [AuthGuard],
        data: ɵ3
    },
    {
        path: '',
        component: LandingPageComponent,
        canActivate: [AuthGuard]
    },
    {
        path: ':langHandle',
        component: LandingPageComponent,
        canActivate: [AuthGuard]
    },
    {
        path: ':langHandle/:issueHandle',
        component: PageComponent,
        canActivate: [AuthGuard]
    },
    {
        path: ':langHandle/:issueHandle/:pageHandle',
        component: PageComponent,
        canActivate: [AuthGuard]
    },
    {
        path: ':langHandle/:issueHandle/:pageHandle/:contentHandle',
        component: PageComponent,
        canActivate: [AuthGuard]
    },
    {
        path: '',
        redirectTo: '/',
        pathMatch: 'full'
    },
    { path: '**', component: PageNotFoundComponent }
];
var CustomStrategy = /** @class */ (function (_super) {
    tslib_1.__extends(CustomStrategy, _super);
    function CustomStrategy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomStrategy.prototype.shouldDetach = function (route) {
        return false;
    };
    CustomStrategy.prototype.store = function (route, detachedTree) { };
    CustomStrategy.prototype.shouldAttach = function (route) {
        return false;
    };
    CustomStrategy.prototype.retrieve = function (route) {
        return null;
    };
    CustomStrategy.prototype.shouldReuseRoute = function (future, curr) {
        return false;
    };
    return CustomStrategy;
}(RouteReuseStrategy));
export { CustomStrategy };
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export var routedComponents = [
    PageNotFoundComponent,
    LandingPageComponent,
    ArchiveScrollComponent,
    ArchiveGridComponent,
    ErrorComponent,
    ErrorPageComponent,
    ErrorDisabledComponent,
    LoginComponent,
    AuthComponent
];
export { ɵ0, ɵ1, ɵ2, ɵ3 };
