<div class="content-product" [ngStyle]="{'background-color':getColor(data['background_color'])}">
    <div class="center">
        <div *ngIf="!uiEventHandler.isMobile()" class="pan" [attr.data-big]="url">
            <div class="controls">
                <nova-nav-border-item class="my-close-icon zoom-in"><i class="icon-plus"></i></nova-nav-border-item>
                <nova-nav-border-item class="my-close-icon zoom-out"><i class="icon-minus"></i></nova-nav-border-item>
            </div>
        </div>
        <div *ngIf="uiEventHandler.isMobile()" class="pan-mobile">
            <img [src]="url" />
        </div>
    </div>
    <div class="rsCaption">
        <b>{{ data.image_header }}</b>
        <br />
        <p>{{ data.image_subHeader }}</p>
    </div>
</div>
