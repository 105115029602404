import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { MagazineService } from 'app/_services/magazine.service';
import { Router, NavigationEnd } from '@angular/router';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { StripeService } from '../../../_services/stripe.service';

@Component({
    selector: 'nova-london-overlay-archive',
    templateUrl: './nova-london-overlay-archive.component.html',
    styleUrls: ['../nova-london-overlay-pages/nova-london-overlay-pages.component.scss']
})
export class NovaLondonOverlayArchiveComponent implements OnInit {
    @Input() public lang;
    @Input() public font;
    @Input() public borderColor;
    @Output() issueChange: EventEmitter<number> = new EventEmitter<number>();
    currentIssue = '';

    constructor(
        public nova: NovaAPIService,
        public mag: MagazineService,
        private router: Router,
        public uiEventHandler: UIEventHandlerService,
        public stripe: StripeService
    ) {}

    ngOnInit() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.getCurrentIssue();
            }
        });

        this.getCurrentIssue();
    }

    isActive(check) {
        if (check) {
            return this.borderColor;
        }
    }

    getCurrentIssue() {
        const params = this.router.routerState.root.firstChild.params['value'];
        const issueHandle = params.issueHandle;
        this.currentIssue = issueHandle;
    }

    goToLanding() {
        this.issueChange.emit();
        this.router.navigate(['/', this.lang], { queryParamsHandling: 'preserve' });
    }

    openIssue(issue) {
        this.issueChange.emit();
        this.router.navigate(['/', this.lang, issue], { queryParamsHandling: 'preserve' });
    }

    getLangIssue(value) {
        return value.languagesMap[this.lang];
    }

    showPaidContentDialog() {
        this.uiEventHandler.showPaidContentDialog = true;
    }
}
