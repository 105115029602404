<div *ngIf="iframe" class="iframe-container">
    <iframe
        *ngIf="trustedUrl"
          [src]="trustedUrl"
          loading='lazy'
          class='iframe-element'
          allow='accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;'
          allowFullScreen
    ></iframe>
</div>

<div class="bg" *ngIf="bg" #bgElm>
	<div *ngIf="video && mediaData && mediaService.isLocalVideo(mediaData) && (!fallback || forceVideo )"
		(mouseover)="playOnHover && mouseover($event)" (mouseout)="playOnHover && mouseout($event)"
		class="plyr-wrapper">

        <video *ngIf="mediaData.type === 'bunny'" #videoBgElm playsinline crossorigin autoplay muted loop class="fullscreen"></video>

        <video *ngIf="mediaData && mediaData.type !== 'bunny'" #videoBgElm playsinline crossorigin [autoplay]="playOnHover ? false : true" muted loop class="fullscreen"
			[poster]="customScreenshot || mediaUrl">
			<source *ngFor="let src of videoSources" [src]="src.src" [type]="src.type" />
		</video>
	</div>

	<div #videoBgElm class="plyr__video-embed"
		*ngIf="video && !fallback && mediaData && mediaService.isVimeo(mediaData) && !uiEventHandler.isTablet()"
		(mouseover)="playOnHover && mouseover($event)" (mouseout)="playOnHover && mouseout($event)">
		<iframe *ngIf="trustedUrl" [src]="trustedUrl" scrolling="no" frameborder="0" webkitAllowFullScreen
			mozallowfullscreen allowFullScreen></iframe>
	</div>
</div>

<video #videoElm *ngIf="(!iframe && !bg && !autoplay && !fallback && video && mediaData && mediaService.isLocalVideo(mediaData)) || (!iframe && !bg && !fallback && mediaData && mediaData.type && mediaData.type === 'bunny')"
	[poster]="customScreenshot || mediaUrl" controls crossorigin>
	<source *ngFor="let src of videoSources" [src]="src.src" [type]="src.type" />
</video>

<div class="embed-container"
	*ngIf="!iframe && !bg && !fallback && video && mediaData && !mediaService.isLocalVideo(mediaData) && videoSources.length > 0">
	<iframe [src]="trustedUrl" scrolling="no" frameborder="0" webkitAllowFullScreen mozallowfullscreen
		allowFullScreen></iframe>
</div>

<img *ngIf="!iframe && !bg && (fallback || !video) && mediaUrl" [src]="mediaUrl" />

<a
    target="_blank"
    [href]="getCopyrightUrl(mediaData.copyrightUrl)"
    *ngIf="copyright && mediaData && mediaData.copyright && mediaData.copyrightUrl"
    class="img-copyright"
    [ngStyle]="copyrightPositionCSS"
>
    &copy;
	{{ mediaData.copyright }}
</a>
<span [ngStyle]="copyrightPositionCSS"
 *ngIf="mediaData && mediaData.copyright && !mediaData.copyrightUrl" class="img-copyright">&copy;
	{{ mediaData.copyright }}</span>
