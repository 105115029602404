import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    QueryList,
    Renderer2,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import { SharedHotelHelperService } from '../hotel-detail-standard/shared-hotel-helper.service';
import { MultiSelectItem } from '../index-hotel/MultiSelectItem';
import { FilterTravelworldModernPipe } from './filter-category-tags.pipe';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './travelworld-modern.component.html',
    styleUrls: ['./travelworld-modern.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [SharedHotelHelperService, FilterTravelworldModernPipe]
})
@NovaTemplate('PageTravelworldModernComponent')
export class PageTravelworldModernComponent extends Page implements OnInit, AfterViewInit {
    filteredData = [];
    continentFilters: MultiSelectItem[] = [];
    countryFilters: MultiSelectItem[] = [];
    categoryFilters: MultiSelectItem[] = [];
    selectedContinentFilters: MultiSelectItem[] = [];
    selectedCountryFilters: MultiSelectItem[] = [];
    selectedCategoryFilters: MultiSelectItem[] = [];

    continentDropdownSettings = {
        singleSelection: false,
        text: 'Kontinente filtern',
        enableCheckAll: false,
        showCheckbox: true,
        enableSearchFilter: false,
        classes: 'nova-multiselect'
    };

    countryDropdownSettings = {
        singleSelection: false,
        text: 'Länder filtern',
        enableCheckAll: false,
        showCheckbox: true,
        enableSearchFilter: false,
        classes: 'nova-multiselect'
    };

    categoryDropdownSettings = {
        singleSelection: false,
        text: 'Reise-Interessen filtern',
        enableCheckAll: false,
        showCheckbox: true,
        enableSearchFilter: false,
        classes: 'nova-multiselect'
    };

    @ViewChildren('filterFont') private filterFontElements: QueryList<ElementRef>;
    @ViewChildren('filterFontHeader') private filterFontHeaderElements: QueryList<ElementRef>;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public hotelHelper: SharedHotelHelperService,
        public media: MediaService,
        public title: Title,
        private renderer: Renderer2,
        private tagsFilter: FilterTravelworldModernPipe,
        public router: Router,
        public route: ActivatedRoute
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    setRemainingContinentFilters(contents) {
        let filterStrings = [];
        contents.forEach(content => {
            filterStrings = [...filterStrings, ...(content.continent_labels ? content.continent_labels : [])];
        });

        this.continentFilters = filterStrings
            .filter((item, pos) => filterStrings.indexOf(item) === pos)
            .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
            .map(item => ({ id: item, itemName: item }));
    }

    setRemainingCountryFilters(contents) {
        let filterStrings = [];
        contents.forEach(content => {
            filterStrings = [...filterStrings, ...(content.country_labels ? content.country_labels : [])];
        });

        this.countryFilters = filterStrings
            .filter((item, pos) => filterStrings.indexOf(item) === pos)
            .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
            .map(item => ({ id: item, itemName: item }));
    }

    setRemainingCategoryFilters(contents) {
        let filterStrings = [];
        contents.forEach(content => {
            filterStrings = [...filterStrings, ...(content.category_labels ? content.category_labels : [])];
        });

        this.categoryFilters = filterStrings
            .filter((item, pos) => filterStrings.indexOf(item) === pos)
            .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
            .map(item => ({ id: item, itemName: item }));
    }

    filter() {
        this.filteredData = this.tagsFilter.transform(
            this.data.blocks,
            this.selectedContinentFilters,
            this.selectedCountryFilters,
            this.selectedCategoryFilters
        );
        this.filteredData = this.filteredData.map(item => {
            if (item.link) {
                item.link = item.link + window.location.search;
            }

            return item;
        });
        this.setRemainingContinentFilters(this.filteredData);
        this.setRemainingCountryFilters(this.filteredData);
        this.setRemainingCategoryFilters(this.filteredData);
    }
    ngOnInit(): void {
        this.filter();
    }

    ngAfterViewInit() {
        if (this.data['filter_font']) {
            this.nova.getFont(this.data['filter_font']).subscribe(fontData => {
                this.filterFontElements.forEach(element => {
                    this.renderer.setStyle(element.nativeElement, 'font-family', fontData.name);
                });
            });
        }
        if (this.data['filter_font_header']) {
            this.nova.getFont(this.data['filter_font_header']).subscribe(fontData => {
                this.filterFontHeaderElements.forEach(element => {
                    this.renderer.setStyle(element.nativeElement, 'font-family', fontData.name);
                });
            });
        }
    }

    getImageUrl(id: string) {
        let imageUrl = '';
        this.media.getMedia(id).subscribe(({ url }) => (imageUrl = url));

        return imageUrl;
    }

    getFont(name) {
        return name.replace('+', ' ');
    }
}
