/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nova-budapest-overlay-imprint.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./nova-budapest-overlay-imprint.component";
import * as i3 from "../../../_services/nova-api.service";
import * as i4 from "../../../_services/magazine.service";
var styles_NovaBudapestOverlayImprintComponent = [i0.styles];
var RenderType_NovaBudapestOverlayImprintComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NovaBudapestOverlayImprintComponent, data: {} });
export { RenderType_NovaBudapestOverlayImprintComponent as RenderType_NovaBudapestOverlayImprintComponent };
export function View_NovaBudapestOverlayImprintComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [], [[4, "font-family", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], [[4, "font-family", null], [8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.font; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.imprintTitle; _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.font; var currVal_3 = _co.imprintText; _ck(_v, 2, 0, currVal_2, currVal_3); }); }
export function View_NovaBudapestOverlayImprintComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-budapest-overlay-imprint", [], null, null, null, View_NovaBudapestOverlayImprintComponent_0, RenderType_NovaBudapestOverlayImprintComponent)), i1.ɵdid(1, 573440, null, 0, i2.NovaBudapestOverlayImprintComponent, [i3.NovaAPIService, i4.MagazineService], null, null)], null, null); }
var NovaBudapestOverlayImprintComponentNgFactory = i1.ɵccf("nova-budapest-overlay-imprint", i2.NovaBudapestOverlayImprintComponent, View_NovaBudapestOverlayImprintComponent_Host_0, { magazine: "magazine", issue: "issue", font: "font" }, {}, []);
export { NovaBudapestOverlayImprintComponentNgFactory as NovaBudapestOverlayImprintComponentNgFactory };
