<div class="items">
  <a class="item" *ngFor="let page of pages; let key = index;" tabIndex="-1" [routerLink]="page.routerLink" queryParamsHandling="merge" (click)="closeOverlay()">
        <div [class.active]="isActivePage(page)">
            <div class="overlay"></div>
            <img *ngIf="page.thumbnail && !nova.isThumbLoading(page.thumbnail)" [src]="page.thumbnail.url" />
            <img *ngIf="!page.thumbnail || nova.isThumbLoading(page.thumbnail)"
                [src]="'assets/img/templates/pages/' + page.templateName +'.svg'" />
            <span [ngStyle]="{'fontFamily':font}"><b
                [style.background-color]="isActivePage(page) && borderColor">{{ pad(key + 1, 2) }}</b>
                {{ uiEventHandler.getContentTableEntryName(page) }}</span>
        </div>
    </a>
</div>
