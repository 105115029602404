import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class PreloaderService {
    private states = {
        PENDING: 1,
        LOADING: 2,
        RESOLVED: 3,
        REJECTED: 4
    };

    private loadCount = 0;
    private imageCount = 0;
    private imageLocations;
    private state;

    public loadEvent$: EventEmitter<any>;
    private promise: Promise<any>;

    constructor() {
        this.loadEvent$ = new EventEmitter();
    }

    public preloadImages(imageLocations) {
        // I am the image SRC values to preload.
        this.imageLocations = imageLocations;

        // As the images load, we'll need to keep track of the load/error
        // counts when announing the progress on the loading.
        this.imageCount = this.imageLocations.length;

        // I keep track of the current state of the preloader.
        this.state = this.states.PENDING;

        // When loading the images, a promise will be returned to indicate
        // when the loading has completed (and / or progressed).

        this.loadCount = 0;

        return this.load();
    }

    // I determine if the preloader has started loading images yet.
    public isInitiated() {
        return this.state !== this.states.PENDING;
    }

    // I determine if the preloader has failed to load all of the images.
    public isRejected() {
        return this.state === this.states.REJECTED;
    }

    // I determine if the preloader has successfully loaded all of the images.
    public isResolved() {
        return this.state === this.states.RESOLVED;
    }

    // I initiate the preload of the images. Returns a promise.
    public load() {
        // If the images are already loading, return the existing promise.
        if (this.isInitiated()) {
            return this.promise;
        }

        this.state = this.states.LOADING;
        this.promise = new Promise((resolve, reject) => {
            for (let i = 0; i < this.imageCount; i++) {
                let image = $(new Image())
                    .on('load error', event => {
                        this.handleImageLoad(event.target['src'], resolve);
                        image = event = null;
                    })
                    .prop('src', this.imageLocations[i]);
            }
        });

        // Return the deferred promise for the load event.
        return this.promise;
    }

    // I handle the load-success of the given image location.
    private handleImageLoad(imageLocation, resolve) {
        this.loadCount++;
        // Notify the progress of the overall deferred. This is different
        // than Resolving the deferred - you can call notify many times
        // before the ultimate resolution (or rejection) of the deferred.

        const percent = Math.ceil((this.loadCount / this.imageCount) * 100);

        this.loadEvent$.emit({
            percent: (percent < 100 && percent) || 100,
            imageLocation: imageLocation
        });
        // If all of the images have loaded, we can resolve the deferred
        // value that we returned to the calling context.
        // if (this.loadCount === this.imageCount) {
        if (this.loadCount >= this.imageCount || percent >= 100) {
            this.state = this.states.RESOLVED;
            resolve(this.imageLocations);
        }
    }
}
