<div class="title-bar"
     [ngClass]="{ nobg: issue.labelHasBackground == 'no' }"
     [style.font-family]="font"
	[style.color]="getColor(nav.textColor)">
	<nova-nav-item (click)="openLondonOverlay()" class="menu-button"
		[style.border-right-color]="getColor(nav.borderColor)"><i class="fa fa-bars"></i></nova-nav-item>
	<div [style.background-color]="mobileBG()" class="left">
		<div class="logo" (click)="ui.goToFirst()" [style.border-right-color]="getColor(nav.borderColor)"
			*ngIf="issue.showLogo == 'yes'">
			<img [src]="ui.logo || 'assets/img/novamag.svg'" [style.padding]="getLogoPadding(nav.logoSize)" alt="Logo" />
		</div>
		<div class="hide-mobile issue-name" [style.border-right-color]="getColor(nav.borderColor)"
			*ngIf="issue.showName == 'yes' && issue.name">{{ issue.name }}</div>
		<div *ngIf="issue.showNumber == 'yes' && issue.number" [style.border-right-color]="getColor(nav.borderColor)">
			{{ issue.number }}</div>
		<div *ngIf="issue.showPageNumbers == 'yes'" [style.border-right-color]="getColor(nav.borderColor)">
			{{ currentPage + 1 }}/{{ pageNum }}</div>
		<div *ngIf="issue.showPageTitle == 'yes' && !ui.isTablet()"
			[style.border-right-color]="getColor(nav.borderColor)" class="nav-title">{{ pageTitle }}</div>
		<div *ngIf="issue.additionalInformation && !ui.isTablet()"
			[style.border-right-color]="(infoPage || homeLinks?.primary || homeLinks.secondary) && getColor(nav.borderColor)" class="nav-title">
			{{ issue.additionalInformation }}
		</div>
		<div *ngIf="infoPage"
             [style.border-right-color]="infoPage && homeLinks?.primary || homeLinks.secondary && getColor(nav.borderColor)"
             class="nav-title link" (click)="goToInfoPage(infoPage)">
			<i class="icon-impressum"></i>
		</div>
        <div *ngIf="homeLinks?.primary?.target" class="nav-title link" (click)="goToUrl(homeLinks.primary.target)"
        [style.border-right-color]="(shareIcon || homeLinks?.secondary?.target) && getColor(nav.borderColor)">
            <nova-media *ngIf="homeLinks.primary.icon" version="original" class="home-icon-link" [mediaId]="homeLinks.primary.icon"></nova-media>
            <i *ngIf="!homeLinks.primary.icon" class="fa fa-home"></i>
        </div>
        <div *ngIf="homeLinks?.secondary?.target" class="nav-title link" (click)="goToUrl(homeLinks.secondary.target)"
        [style.border-right-color]="(shareIcon || stripe.currentCustomer.email) && getColor(nav.borderColor)">
            <nova-media *ngIf="homeLinks.secondary.icon" version="original" class="home-icon-link" [mediaId]="homeLinks.secondary.icon"></nova-media>
            <i *ngIf="!homeLinks.secondary.icon" class="fa fa-home"></i>
        </div>

        <div *ngIf="shareIcon" class="nav-title link share-menu" [class.show]="hovering" (click)="ui.isTouchDevice() && toggleShareMenu()" (mouseover)="!ui.isTouchDevice() && hovering=true"
            (mouseout)="!ui.isTouchDevice() && hovering=false">
            <nova-media class="home-icon-link" [mediaId]="shareIcon"></nova-media>
            <div class="share-menu-dropdown">
                <a class="social-button whatsapp" (click)="share('whatsapp')">
                    {{ 'WhatsApp' | translate }}
                </a>
                <a class="social-button mail" (click)="share('mail')">
                    {{ 'E-Mail' | translate }}
                </a>
                <a class="social-button url" (click)="share('copy')">
                    {{ 'URL' | translate }}
                </a>
            </div>
        </div>

        <ng-template #blogHome>
            <a routerLink=".." class="nav-title link" style="display: block;">
                <nova-media *ngIf="homeLinks.primary.icon" class="home-icon-link" [mediaId]="homeLinks.primary.icon"></nova-media>
                <i *ngIf="!homeLinks.primary.icon" class="fa fa-home"></i>
            </a>
        </ng-template>

		<div *ngIf="stripe.currentCustomer.email" class="manage-account">
			<i class="far fa-user"></i>
            <span *ngIf="!ui.isTablet()">&nbsp;&nbsp;{{stripe.currentCustomer.email}}</span>
		<div class="buttons">
			(<button (click)="stripe.logout()">{{ 'LOGOUT' | translate }}</button>)
		</div>
	</div>
</div>
