import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule, CustomStrategy, routedComponents } from './app-routings.module';
import { RouteReuseStrategy } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { PagesModule } from 'app/pages/pages.module';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { ComponentsModule } from 'app/_components/components.module';
import { RenderService } from 'app/_services/render.service';
import { PreloaderService } from 'app/_services/preloader.service';
import { FontService } from 'app/_services/font.service';
import { FormService } from 'app/_services/form.service';
import { MagazineService } from 'app/_services/magazine.service';
import { MediaService } from 'app/_services/media.service';
import { NetworkService } from 'app/_services/network.service';
import { SSRService } from 'app/_services/ssr.service';
import { JwtInterceptorProvider } from 'app/_helpers/jwt.interceptor';
import { AuthService } from 'app/_services/auth.service';
import { AuthGuard } from 'app/_helpers/auth.guard';
import { InjectService } from 'app/_services/inject.service';
import { PresentationService } from 'app/_services/presentation.service';
import { NewsletterService } from 'app/_services/newsletter.service';
import { LogoAndFaviconService } from 'app/_services/logoAndFavicon.service';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
    declarations: [AppComponent, routedComponents],
    entryComponents: [...routedComponents],
    imports: [
        BrowserModule.withServerTransition({ appId: 'novamag-client' }),
        AppRoutingModule,
        PagesModule,
        ComponentsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        BrowserTransferStateModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: CustomStrategy },
        NovaAPIService,
        UIEventHandlerService,
        RenderService,
        PreloaderService,
        JwtInterceptorProvider,
        AuthService,
        AuthGuard,
        InjectService,
        FontService,
        FormService,
        MagazineService,
        MediaService,
        NetworkService,
        SSRService,
        PresentationService,
        NewsletterService,
        LogoAndFaviconService,
        CookieService
    ],
    exports: [TranslateModule],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
