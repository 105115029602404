import { DomSanitizer } from '@angular/platform-browser';
import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NovaAPIService } from '../../_services/nova-api.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import { Subscription } from 'rxjs';
import { MagazineService } from 'app/_services/magazine.service';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'nova-terms',
    templateUrl: './nova-terms.component.html',
    styleUrls: ['./nova-terms.component.scss']
})
export class NovaTermsComponent implements OnInit, OnDestroy {
    public font;
    public visible: boolean = false;
    public termsTitle: string;
    public termsText: string;
    public termsUrl: string;
    public cookie;
    public returnUrl: string;

    private sub: Subscription;
    private subFont: Subscription;

    public showDefault: boolean = false;
    public showCustom: boolean = false;

    public defaultTermsText: string;

    constructor(
        public nova: NovaAPIService,
        public mag: MagazineService,
        public sanitizer: DomSanitizer,
        private router: Router,
        public uiEventHandler: UIEventHandlerService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.sub = this.mag.getMagazine().subscribe(data => {
            this.termsText = data.termsText && data.termsText.replace(/(?:\r\n|\r|\n)/g, '<br>');
            this.termsUrl = data.termsUrl;

            this.nova.getCurrentIssue(data.language).subscribe(issue => {
                this.termsTitle = issue.termsTitle || data.termsTitle;
                this.termsText =
                    (issue.termsText || data.termsText) &&
                    (issue.termsText || data.termsText).replace(/(?:\r\n|\r|\n)/g, '<br>');
                this.termsUrl = issue.termsUrl || data.termsUrl;
            });

            this.cookie = data.cookieNotice;

            this.subFont = this.nova.getFont(data.navigation.font).subscribe(fontData => {
                this.font = fontData.name;
            });

            const routeData = this.router.routerState.root.firstChild.snapshot.data;
            if (routeData && routeData.terms) {
                this.visible = true;
                this.returnUrl = this.router.routerState.root.snapshot.queryParams['returnUrl'];
            }

            this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(e => {
                const routeData = this.router.routerState.root.firstChild.snapshot.data;
                if (routeData && routeData.terms) {
                    this.visible = true;
                    this.returnUrl = this.router.routerState.root.snapshot.queryParams['returnUrl'];
                }
            });
        });
    }

    open() {
        // this.visible = true;
        this.uiEventHandler.showTerms = true;
    }

    hide() {
        // this.visible = false;
        this.uiEventHandler.showTerms = false;
        if (this.returnUrl) this.router.navigate([this.returnUrl]);
    }

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe();
        if (this.subFont) this.subFont.unsubscribe();
    }
}
