import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import * as $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './cover-classic-content.component.html',
    styleUrls: ['./cover-classic-content.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageCoverClassicContentComponent')
export class PageCoverClassicContentComponent extends Page implements OnInit, OnDestroy {
    public firstRowStyle;
    public secondRowStyle;
    public thirdRowStyle;

    public header_size;
    public subHeader_size;
    public label_size;
    public text_size;
    public logo_size;

    @ViewChild('logo') public logo: ElementRef;

    private resizeSub: Subscription;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
        this.resizeSub = this.uiEventHandler.resizeEvent$.subscribe(this.onResize.bind(this));
    }

    ngOnDestroy() {
        this.resizeSub.unsubscribe();
    }

    ngOnInit() {
        // this.setFontSize();
        let firstRowStyle = '';
        let secondRowStyle = '';
        let thirdRowStyle = '';

        for (const key in this.data.matrixPosition) {
            const elm = this.data.matrixPosition[key].matrix;
            let num = elm;
            if (elm > 6) {
                num = elm - 6;
            } else if (elm > 3) {
                num = elm - 3;
            }
            let style = this.getRowStyle(num, this.data.matrixPosition[key].align);
            style += 'order: ' + elm + ';';

            if (key === 'logo') {
                firstRowStyle = style;
            } else if (key === 'text') {
                secondRowStyle = style;
            } else if (key === 'contents') {
                thirdRowStyle = style;
            }
        }

        this.firstRowStyle = this.convertCSS(firstRowStyle);
        this.secondRowStyle = this.convertCSS(secondRowStyle);
        this.thirdRowStyle = this.convertCSS(thirdRowStyle);

        setTimeout(() => {
            this.calcMaxSize();
        }, 100);
    }

    getRowStyle(key, align) {
        let ret = '';
        if (key === 1) {
            ret += 'align-self: flex-start;justify-content: flex-start;';
        } else if (key === 2) {
            ret += 'align-self: center;justify-content: center;';
        } else {
            ret += 'align-self: flex-end;justify-content: flex-end;';
        }

        if (align === 'top') {
            ret += 'align-items: flex-start;';
        } else if (align === 'bottom') {
            ret += 'align-items: flex-end;';
        } else {
            ret += 'align-items: center;';
        }

        return ret;
    }

    // setFontSize() {
    //     if (this.data['header_size']) {
    //         const headerSize = parseInt(this.data['header_size'], 10) / 10;
    //         this.header_size = this.convertCSS('calc(43px + ' + headerSize + 'vw)');
    //     }
    //     if (this.data['subHeader_size']) {
    //         const subHeaderSize = parseInt(this.data['subHeader_size'], 10) / 10;
    //         this.subHeader_size = this.convertCSS('calc(23px + ' + subHeaderSize + 'vw)');
    //     }
    //     if (this.data['label_size']) {
    //         const labelSize = parseInt(this.data['label_size'], 10) / 10;
    //         this.label_size = this.convertCSS('calc(11px + ' + labelSize + 'vw)');
    //     }
    //     if (this.data['text_size']) {
    //         const textSize = parseInt(this.data['text_size'], 10) / 10;
    //         this.text_size = this.convertCSS('calc(11px + ' + textSize + 'vw)');
    //     }
    // }

    calcMaxSize() {
        let logo_size = '';

        if (this.data['logo'] && this.data['logo'].url) {
            let divW = $(this.logo.nativeElement).width();
            const logo = $(this.logo.nativeElement).find('img')[0];
            if (logo['naturalHeight']) {
                logo_size += 'max-height: ' + (divW * logo['naturalHeight']) / logo['naturalWidth'] + 'px;';
            } else {
                $(logo).on('load', () => {
                    divW = $(this.logo.nativeElement).width();
                    logo_size += 'max-height: ' + (divW * logo['naturalHeight']) / logo['naturalWidth'] + 'px;';
                });
            }
        }
        if (this.data['logoSize']) {
            logo_size += 'height: calc(50px + ' + this.data['logoSize'] + 'vw);';
        }

        this.logo_size = this.convertCSS(logo_size);
    }

    onResize(e) {
        this.calcMaxSize();
    }

    convertCSS(css) {
        let input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {},
                attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    }

    setMyStyles(obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    }
}
