/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./nova-ci-style.component";
import * as i2 from "../../_services/magazine.service";
import * as i3 from "../../_services/uievent-handler.service";
import * as i4 from "@angular/platform-browser";
var styles_NovaCiStyleComponent = [];
var RenderType_NovaCiStyleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NovaCiStyleComponent, data: {} });
export { RenderType_NovaCiStyleComponent as RenderType_NovaCiStyleComponent };
export function View_NovaCiStyleComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "link", [["rel", "stylesheet"], ["type", "text/css"]], [[8, "href", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.css; _ck(_v, 0, 0, currVal_0); }); }
export function View_NovaCiStyleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "nova-ci-style", [], null, null, null, View_NovaCiStyleComponent_0, RenderType_NovaCiStyleComponent)), i0.ɵdid(1, 245760, null, 0, i1.NovaCiStyleComponent, [i2.MagazineService, i3.UIEventHandlerService, i4.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NovaCiStyleComponentNgFactory = i0.ɵccf("nova-ci-style", i1.NovaCiStyleComponent, View_NovaCiStyleComponent_Host_0, {}, { change: "pageChange" }, []);
export { NovaCiStyleComponentNgFactory as NovaCiStyleComponentNgFactory };
