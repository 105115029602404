/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nova-cookie-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./nova-cookie-popup.component";
import * as i5 from "../../_services/nova-api.service";
import * as i6 from "../../_services/magazine.service";
import * as i7 from "@angular/router";
import * as i8 from "../../_services/uievent-handler.service";
var styles_NovaCookiePopupComponent = [i0.styles];
var RenderType_NovaCookiePopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NovaCookiePopupComponent, data: {} });
export { RenderType_NovaCookiePopupComponent as RenderType_NovaCookiePopupComponent };
function View_NovaCookiePopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "selections"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "input", [["disabled", ""], ["id", "ButtonNecessary"], ["name", "necessary"], ["type", "checkbox"]], [[8, "checked", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "label", [["for", "ButtonNecessary"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "input", [["id", "ButtonPreferences"], ["name", "preferences"], ["type", "checkbox"]], [[8, "checked", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.toggle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "label", [["for", "ButtonPreferences"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "input", [["id", "ButtonStatistics"], ["name", "statistics"], ["type", "checkbox"]], [[8, "checked", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.toggle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "label", [["for", "ButtonStatistics"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(16, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "input", [["id", "ButtonMarketing"], ["name", "marketing"], ["type", "checkbox"]], [[8, "checked", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.toggle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "label", [["for", "ButtonMarketing"]], null, null, null, null, null)), (_l()(), i1.ɵted(19, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.categories.necessary; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("Notwendig")); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.categories.preferences; _ck(_v, 7, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("Pr\u00E4ferenzen")); _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.categories.statistics; _ck(_v, 12, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("Statistiken")); _ck(_v, 14, 0, currVal_5); var currVal_6 = _co.categories.marketing; _ck(_v, 17, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform("Marketing")); _ck(_v, 19, 0, currVal_7); }); }
function View_NovaCookiePopupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-selection"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showSelectionMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("COOKIE_POPUP_MORE")); _ck(_v, 1, 0, currVal_0); }); }
function View_NovaCookiePopupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-selection"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.acceptAndClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("COOKIE_POPUP_ACCEPT")); _ck(_v, 1, 0, currVal_0); }); }
export function View_NovaCookiePopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "nova-cookie-popup-container"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 19, "div", [["class", "nova-cookie-popup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 18, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "cookie-text"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaCookiePopupComponent_1)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 10, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaCookiePopupComponent_2)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "btn btn-deny"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.denyAllAndClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["class", "btn btn-all"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.acceptAllAndClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaCookiePopupComponent_3)), i1.ɵdid(20, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.showSelections; _ck(_v, 9, 0, currVal_3); var currVal_4 = !_co.showSelections; _ck(_v, 12, 0, currVal_4); var currVal_7 = _co.showSelections; _ck(_v, 20, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.uiEventHandler.showCookiePopup; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("COOKIE_POPUP_HEADER")); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("COOKIE_POPUP_TEXT")); _ck(_v, 6, 0, currVal_2); var currVal_5 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("COOKIE_POPUP_DENY")); _ck(_v, 14, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("COOKIE_POPUP_ALLOW")); _ck(_v, 17, 0, currVal_6); }); }
export function View_NovaCookiePopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-cookie-popup", [], null, null, null, View_NovaCookiePopupComponent_0, RenderType_NovaCookiePopupComponent)), i1.ɵdid(1, 245760, null, 0, i4.NovaCookiePopupComponent, [i5.NovaAPIService, i6.MagazineService, i7.Router, i8.UIEventHandlerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NovaCookiePopupComponentNgFactory = i1.ɵccf("nova-cookie-popup", i4.NovaCookiePopupComponent, View_NovaCookiePopupComponent_Host_0, {}, {}, []);
export { NovaCookiePopupComponentNgFactory as NovaCookiePopupComponentNgFactory };
