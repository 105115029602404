import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ColorFormatter } from '../../../novamag-api/Types';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
var PagePortraitClassicComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PagePortraitClassicComponent, _super);
    function PagePortraitClassicComponent(nova, uiEventHandler, sanitizer, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.sanitizer = sanitizer;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        return _this;
    }
    PagePortraitClassicComponent.prototype.getColor = function () {
        var c = new ColorFormatter({ r: 255, g: 255, b: 255, a: 255 });
        if (this.data['box_color']) {
            c = new ColorFormatter(this.data['box_color']);
        }
        return c.rgba();
    };
    PagePortraitClassicComponent.prototype.ngOnInit = function () {
        this.setTitlePos();
    };
    PagePortraitClassicComponent.prototype.setTitlePos = function () {
        var titleStyle = '';
        if (!this.uiEventHandler.isMobile()) {
            var helperStyle = '';
            var logoStyle = '';
            var posLogo = this.data['contentPosition'];
            var posTitle = 'bottom-center';
            // position on x axis
            if (posLogo === 'top-left' || posLogo === 'bottom-left' || posLogo === 'center-left') {
                logoStyle += 'align-self: flex-start;';
            }
            if (posLogo === 'top-right' || posLogo === 'bottom-right' || posLogo === 'center-right') {
                logoStyle += 'align-self: flex-end;';
                logoStyle += 'text-align: right;';
            }
            if (posLogo === 'top-center' || posLogo === 'bottom-center' || posLogo === 'center-center') {
                logoStyle += 'text-align: center;';
            }
            if (posTitle === 'top-left' || posTitle === 'bottom-left' || posTitle === 'center-left') {
                titleStyle += 'align-self: flex-start;';
            }
            if (posTitle === 'top-right' || posTitle === 'bottom-right' || posTitle === 'center-right') {
                titleStyle += 'align-self: flex-end;';
            }
            if ((posLogo === 'center-center' || posLogo === 'center-right' || posLogo === 'center-left') &&
                (posTitle === 'center-center' || posTitle === 'center-right' || posTitle === 'center-left')) {
                logoStyle += 'margin-bottom: 60px;';
            }
            // move logo to top
            if (posLogo === 'top-left' || posLogo === 'top-right' || posLogo === 'top-center') {
                if (posTitle !== 'top-left' && posTitle !== 'top-center' && posTitle !== 'top-right') {
                    logoStyle += 'flex-grow: 1;';
                    if (posTitle === 'center-center' || posTitle === 'center-right' || posTitle === 'center-left') {
                        titleStyle += 'flex-grow: 1;';
                    }
                }
                else {
                    helperStyle += 'order: 2;';
                    helperStyle += 'flex-grow: 1;';
                    logoStyle += 'margin-bottom: 60px;';
                }
            }
            if (posTitle === 'top-left' || posTitle === 'top-right' || posTitle === 'top-center') {
                if (posLogo !== 'top-left' && posLogo !== 'top-center' && posLogo !== 'top-right') {
                    titleStyle += 'flex-grow: 1;';
                    // titleStyle += 'order: 0;';
                    if (posLogo === 'center-center' || posLogo === 'center-right' || posLogo === 'center-left') {
                        logoStyle += 'flex-grow: 1;';
                    }
                }
            }
            // move logo to bottom
            if (posLogo === 'bottom-left' || posLogo === 'bottom-center' || posLogo === 'bottom-right') {
                if (posTitle !== 'bottom-left' && posTitle !== 'bottom-center' && posTitle !== 'bottom-right') {
                    logoStyle += 'order: 2;';
                }
                else {
                    helperStyle += 'flex-grow: 1;';
                    logoStyle += 'margin-bottom: 60px;';
                }
            }
            // move title to top
            if (posTitle === 'top-left' || posTitle === 'top-center' || posTitle === 'top-right') {
                if (posLogo !== 'top-left' && posLogo !== 'top-center' && posLogo !== 'top-right') {
                    titleStyle += 'order: 0;';
                }
            }
            if (posLogo === 'bottom-left' || posLogo === 'bottom-right' || posLogo === 'bottom-center') {
                if (posTitle === 'center-center' || posTitle === 'center-right' || posTitle === 'center-left') {
                    helperStyle += 'flex-grow: 1;';
                    titleStyle += 'flex-grow: 1;';
                }
            }
            if (posTitle === 'bottom-left' || posTitle === 'bottom-right' || posTitle === 'bottom-center') {
                if (posLogo === 'center-center' || posLogo === 'center-right' || posLogo === 'center-left') {
                    helperStyle += 'flex-grow: 1;';
                    logoStyle += 'flex-grow: 1;';
                }
            }
            this.helperStyle = this.convertCSS(helperStyle);
            this.logoStyle = this.convertCSS(logoStyle);
        }
        if (this.data.arrowBackgroundColor) {
            var c = new ColorFormatter(this.data['arrowBackgroundColor']);
            titleStyle += 'background-color: ' + c.rgba() + ';';
        }
        this.titleStyle = this.convertCSS(titleStyle);
    };
    PagePortraitClassicComponent.prototype.convertCSS = function (css) {
        var input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {}, attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    };
    PagePortraitClassicComponent.prototype.setMyStyles = function (obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    };
    PagePortraitClassicComponent = tslib_1.__decorate([
        NovaTemplate('PagePortraitClassicComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            DomSanitizer,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], PagePortraitClassicComponent);
    return PagePortraitClassicComponent;
}(Page));
export { PagePortraitClassicComponent };
