import { OnInit, OnDestroy, EventEmitter, SimpleChanges } from '@angular/core';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { SSRService } from '../../../_services/ssr.service';
import { Router, NavigationEnd } from '@angular/router';
import { MagazineService } from '../../../_services/magazine.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { ColorFormatter } from '../../../novamag-api/Types';
var NovaFtiTitleBarComponent = /** @class */ (function () {
    function NovaFtiTitleBarComponent(nova, ssr, router, mag, ui) {
        this.nova = nova;
        this.ssr = ssr;
        this.router = router;
        this.mag = mag;
        this.ui = ui;
        this.toggleOverlay = new EventEmitter();
        this.pageChange = new EventEmitter();
        this.overlayVisible = false;
        this.menuOpen = false;
    }
    NovaFtiTitleBarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sub = this.mag.getMagazine().subscribe(function (data) {
            _this.magData = data;
            _this.nav = data.navigation;
            _this.selectStartPage = data.selectStartPage === 'archiv';
            _this.subFont = _this.nova.getFont(data.navigation.font).subscribe(function (fontData) {
                _this.font = fontData.name;
            });
        });
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                _this.getCurrentPage();
            }
        });
        this.getCurrentPage();
    };
    NovaFtiTitleBarComponent.prototype.ngOnChanges = function (changes) {
        if (changes.issue && changes.issue.currentValue !== changes.issue.previousValue) {
            this.getCurrentPage();
        }
    };
    NovaFtiTitleBarComponent.prototype.getCurrentPage = function () {
        var _this = this;
        var params = this.router.routerState.root.firstChild.params['value'];
        var pageHandle = params.pageHandle;
        this.infoPage = this.nova.issue.infoPage;
        this.homeLinks = this.nova.issue.homeLinks;
        if (pageHandle) {
            this.nova.pages.forEach(function (page, index) {
                if (page.handle === pageHandle) {
                    _this.currentPage = index;
                }
            });
            this.pageNum = this.nova.pages.length;
        }
    };
    Object.defineProperty(NovaFtiTitleBarComponent.prototype, "pageTitle", {
        get: function () {
            return this.ui.getContentTableEntryName(this.nova.pages[this.currentPage]);
        },
        enumerable: true,
        configurable: true
    });
    NovaFtiTitleBarComponent.prototype.toggleFtiOverlay = function (event) {
        // hacky way to stop rendering twice
        if (event.isTrusted && this.ui.isMobile()) {
            return;
        }
        this.toggleOverlay.emit();
    };
    NovaFtiTitleBarComponent.prototype.getColor = function (c) {
        return new ColorFormatter(c).rgba();
    };
    NovaFtiTitleBarComponent.prototype.mobileBG = function () {
        return new ColorFormatter(this.nav.backgroundColor).rgba();
    };
    NovaFtiTitleBarComponent.prototype.ngOnDestroy = function () {
        this.sub && this.sub.unsubscribe();
        this.subFont && this.subFont.unsubscribe();
        this.subRouter && this.subRouter.unsubscribe();
        this.logoSub && this.logoSub.unsubscribe();
    };
    NovaFtiTitleBarComponent.prototype.goToInfoPage = function (pageId) {
        var pageIndex = this.nova.pages.findIndex(function (page) { return page._id === pageId; });
        this.pageChange.emit(pageIndex + 1);
    };
    NovaFtiTitleBarComponent.prototype.goToIndexPage = function () {
        var pageIndex = this.nova.pages.findIndex(function (page) { return page.templateName.indexOf('index-') != -1; });
        this.pageChange.emit(pageIndex + 1);
    };
    NovaFtiTitleBarComponent.prototype.goToUrl = function (url) {
        var currentPage = this.issue.pages[this.currentPage];
        if (currentPage.templateName === 'blog-index') {
            this.router.navigate([this.router.url.substring(0, this.router.url.lastIndexOf('/'))]);
            return;
        }
        this.router.navigate([url], {
            queryParamsHandling: 'preserve'
        });
    };
    return NovaFtiTitleBarComponent;
}());
export { NovaFtiTitleBarComponent };
