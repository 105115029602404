import { Component, OnInit, OnDestroy } from '@angular/core';
import { NovaAPIService } from '../../_services/nova-api.service';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import { Subscription } from 'rxjs';
import { MagazineService } from 'app/_services/magazine.service';
import { NovaTemplate } from '../../_decorators/template.decorator';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
    selector: 'nova-cookie-popup',
    templateUrl: './nova-cookie-popup.component.html',
    styleUrls: ['./nova-cookie-popup.component.scss']
})
@NovaTemplate('NovaCookiePopupComponent')
export class NovaCookiePopupComponent implements OnInit, OnDestroy {
    public magazine;
    public categories: { necessary: boolean; preferences: boolean; statistics: boolean; marketing: boolean };
    private hideCookieNotice$;
    private sub: Subscription;
    public showSelections = false;

    constructor(
        public nova: NovaAPIService,
        public mag: MagazineService,
        private router: Router,
        public uiEventHandler: UIEventHandlerService
    ) {
        const consent = this.uiEventHandler.getCookie('CookieConsent');

        this.categories = consent
            ? JSON.parse(consent)
            : {
                  necessary: true,
                  preferences: false,
                  statistics: false,
                  marketing: false
              };
    }

    ngOnInit() {
        this.hideCookieNotice$ = this.router.routerState.root.queryParams.pipe(map(params => params.cn === 'false'));

        this.sub = this.hideCookieNotice$.subscribe(hideCookieNotice => {
            this.uiEventHandler.showCookiePopup = !hideCookieNotice;
        });
    }

    toggle(event) {
        this.categories[event.target.name] = event.target.checked;
    }

    acceptAllAndClose() {
        Object.keys(this.categories).forEach(c => (this.categories[c] = true));

        this.acceptAndClose();
    }

    showSelectionMenu() {
        this.showSelections = true;
    }

    acceptAndClose() {
        this.uiEventHandler.setCookie('CookieConsent', JSON.stringify(this.categories), 30);
        this.uiEventHandler.showCookiePopup = false;

        // hard reload to set new cookies and inject analytics scripts
        location.reload();
    }

    denyAllAndClose() {
        this.uiEventHandler.setCookie(
            'CookieConsent',
            JSON.stringify({
                necessary: true,
                preferences: false,
                statistics: false,
                marketing: false
            }),
            7
        );
        this.uiEventHandler.showCookiePopup = false;
    }

    getLang() {
        return this.nova.lang;
    }

    textToHtml(text) {
        return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }

    ngOnDestroy() {
        this.sub && this.sub.unsubscribe();
    }
}
