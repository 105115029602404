import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    PLATFORM_ID,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedHotelHelperService } from '../../hotel-detail-standard/shared-hotel-helper.service';
import { fadeAnimation } from '../../travelworld-modern/travelworld-slider/fadeIn.animation';
import { UIEventHandlerService } from '../../../../_services/uievent-handler.service';
import { MediaService } from 'app/_services/media.service';
import { mergeMap } from 'rxjs/operators';

interface ModalContent {
    label?: string;
    header?: string;
    content: string;
}

@Component({
    selector: 'app-roundtrip-standard-content',
    templateUrl: './roundtrip-standard-content.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [SharedHotelHelperService],
    animations: [fadeAnimation],
    styleUrls: ['./roundtrip-standard-content.component.scss']
})
export class RoundtripStandardContentComponent implements OnInit {
    @Input() data;
    @Input() modalOpen = false;
    @Output() modalOpenChange = new EventEmitter<boolean>();
    isBrowser: boolean = isPlatformBrowser(this.platformId);
    modalContent: ModalContent;
    modalMode = true;
    modalVariant = '';
    modalBackgroundUrl = '';
    showContactForm = false;

    @ViewChild('scrollContainer') flexContainer;
    @ViewChild('smallSlider') slider;

    constructor(
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        @Inject(PLATFORM_ID) private platformId: Object,
        public hotelHelper: SharedHotelHelperService,
        public mediaService: MediaService
    ) {}

    ngOnInit() {
        this.data = this.hotelHelper.handleTagInputs(this.data, ['roundtrip_categories', 'interests']);
        this.showContactForm = this.data.ibe === 'false';

        if (this.data && this.data.medias && this.data.medias[0]) {
            const mediaId = this.data.medias[0].slider_media;
            const version = 'horizontal-wide';

            this.mediaService
                .getMedia(mediaId)
                .pipe(mergeMap(() => this.mediaService.getMediaUrl(mediaId, version)))
                .subscribe(
                    url => (this.modalBackgroundUrl = `url(${this.mediaService.getImageProxyUrl(url, version, 'ce')})`)
                );
        } else if (this.data.images && this.data.images[0]) {
            this.modalBackgroundUrl = `url(${this.mediaService.getImageProxyUrl(
                this.data.images[0].filename,
                null,
                'ce'
            )})`;
        }
    }

    goToUrl(url: string) {
        if (this.isBrowser) {
            window.open(url);
        }
    }

    getVersion() {
        if (this.uiEventHandler.isMobile() || this.uiEventHandler.isTablet()) {
            return 'horizontal-wide';
        } else {
            return 'vertical-wide';
        }
    }

    openFullscreenSliderModal() {
        this.modalVariant = 'image';
        this.modalMode = false;
        this.modalOpen = true;
        this.modalOpenChange.emit(this.modalOpen);

        $('.share-menu') && $('.share-menu').addClass('hidden');
    }

    openContactModal() {
        this.modalVariant = 'contact';
        this.openModal();
    }

    openMapModal() {
        this.modalVariant = 'map';
        this.modalContent = {
            content: `<img src="${this.data.mapUrl}" alt='' />`
        };

        this.openModal();
    }

    openDetailModal(fieldName) {
        this.modalVariant = 'detail';
        this.modalContent = {
            label: this.data[`${fieldName}Label`],
            header: this.data[`${fieldName}Header`],
            content: this.data[fieldName]
        };

        this.openModal();
    }

    openModal() {
        // hacky way to scroll to top inside of the scroll container
        const page = this.flexContainer.nativeElement.parentElement.parentElement;
        page.scroll(0, 0);
        page.classList.add('modal-open');

        this.modalOpen = true;
        this.modalOpenChange.emit(this.modalOpen);

        $('body').addClass('nested-modal-open');
    }

    closeModal() {
        const page = this.flexContainer.nativeElement.parentElement.parentElement;
        page.classList.remove('modal-open');
        $('.share-menu') && $('.share-menu').removeClass('hidden');

        this.modalVariant = '';
        this.modalContent = undefined;
        this.modalOpen = false;
        this.modalMode = true;
        this.modalOpenChange.emit(this.modalOpen);

        $('body').removeClass('nested-modal-open');
    }
}
