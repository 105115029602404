<div id="page-{{ id }}" class="page page-cover-content">
	<nova-bg [data]="data" [forceVideo]="true"></nova-bg>
	<div class="flexbox">
		<div class="flex-item logo" #logo [ngStyle]="setMyStyles(firstRowStyle)">
			<div [ngStyle]="setMyStyles(logo_size)" *ngIf="data.logo && data.logo.url">
				<img [src]="data.logo.url" alt="Logo" />
			</div>
		</div>
		<div class="flex-item" [ngStyle]="setMyStyles(secondRowStyle)">
			<div>
				<h3 *ngIf="data.label" class="dateline">
					<nova-element [data]="data" name="label"></nova-element>
				</h3>
				<h1 *ngIf="data.header">
					<nova-element [data]="data" name="header"></nova-element>
				</h1>
				<h2 *ngIf="data.subHeader">
					<nova-element [data]="data" name="subHeader"></nova-element>
				</h2>
				<p class="text">
					<nova-element [data]="data" name="text"></nova-element>
				</p>
			</div>
		</div>
		<div class="flex-item" [ngStyle]="setMyStyles(thirdRowStyle)">
			<nova-content-links
				[showThumbs]="data.showThumbsForLinks"
				class="content-links"
				*ngIf="data.links && data.links.length > 0"
				[floors]="data.links"
			></nova-content-links>
		</div>
	</div>
</div>
