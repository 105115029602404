<div id="page-{{ id }}" class="page page-fti-newsletter">
  <nova-bg [data]="data" [forceVideo]="true"></nova-bg>
  <nova-scrollbar-holder>
    <div class="scroller">
        <nova-mobile-img [data]="data" class="show-for-small-only left"></nova-mobile-img>
      <div class="bg animated fadeInUp clear" [ngStyle]="{'background-color':getColor(data['box_color'])}"
        [hidden]="!data.text && !data.header && !data.subHeader && !data.label">
        <nova-title [class.noBg]="!data.background_media" [data]="data"></nova-title>
        <div *ngIf="formStatus !== 'success'" class="newsletterForm">
          <nova-text-element class="con" [data]="data.text" [myid]="data._id"
            (openGallery)="gallery.openSlider($event)"></nova-text-element>

          <div *ngIf="formStatus === 'error'" [class.fadeInUp]="showMsg" class="newsletterFormError red animated">
            <p>{{ 'Leider ist bei Ihrer Anmeldung etwas schiefgegangen. Bitte stellen sich sicher das alle Pflichtfelder ausgefüllt sind.' | translate }}</p>
          </div>
          <form action="https://web.inxmail.com/FTI/subscription/servlet" method="post">
            <input type="hidden" name="INXMAIL_SUBSCRIPTION" value="FTI_eMAG_Anmeldung" />
            <input type="hidden" name="INXMAIL_HTTP_REDIRECT" [value]="makeUrl('success')" />
            <input type="hidden" name="INXMAIL_HTTP_REDIRECT_ERROR" [value]="makeUrl('error')" />
            <input type="hidden" name="INXMAIL_CHARSET" value="UTF-8" />
            <label>E-Mail* <input type="text" name="email" /> </label>
            <label>
              Anrede
              <select name="Anrede">
                <option value="Herr">Herr</option>
                <option value="Frau">Frau</option>
                <option value="neutral">Divers</option>
              </select>
            </label>
            <label>Vorname <input type="text" name="Vorname" /> </label>
            <label>Nachname <input type="text" name="Nachname" /> </label>
            <label>
              <input type="checkbox" name="INXMAIL_TRACKINGPERMISSION" required />
              Ich stimme den <a href="https://www.fti.de/datenschutz.html" target="_blank">Datenschutzbestimmungen</a> zu.<br />
            </label>
            <button class="g-recaptcha" data-sitekey="6Ld673sUAAAAANIr4kvsqp3F1i4iiOatKNVWVCL2"
              data-callback="onSubmit">
              Anmelden
            </button>

          </form>

        </div>
        <div *ngIf="formStatus == 'success'" [class.fadeInUp]="showMsg" class="newsletterFormSuccess green animated">
            <div class="header">
                <div class="sign"></div>
            </div>
            <nova-text-element class="con" [data]="data.text_success" [myid]="data._id"
            (openGallery)="gallery.openSlider($event)"></nova-text-element>
        </div>

      </div>
    </div>
  </nova-scrollbar-holder>
  <nova-text-gallery #gallery [medias]="data.textImages" [myid]="data._id"></nova-text-gallery>
</div>
