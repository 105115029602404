import {
    Component,
    ElementRef,
    OnInit,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from 'app/_decorators/template.decorator';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import { SharedHotelHelperService } from '../hotel-detail-standard/shared-hotel-helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit } from '@angular/core/src/metadata/lifecycle_hooks';
import { MultiSelectItem } from './MultiSelectItem';
import { fadeAnimation } from '../travelworld-modern/travelworld-slider/fadeIn.animation';
import { IndexTripFilterPipe } from './filter-tags.pipe';
import { Content } from 'app/novamag-api/Types';

export const setRemainingFilters = (filterRef, contents, key) => {
    let availableLabels = [];
    contents.forEach(content => {
        const contentLabels = content[key] || [];
        availableLabels = [...availableLabels, ...contentLabels];
    });

    availableLabels = availableLabels.filter((item, pos) => availableLabels.indexOf(item) === pos);

    // add all labels
    availableLabels.map(x => {
        if (!filterRef.find(({ id }) => x === id)) {
            filterRef.push({ id: x, itemName: x });
        }
    });

    // remove unused labels
    for (let i = filterRef.length - 1; i >= 0; i--) {
        const { id } = filterRef[i];

        if (availableLabels.indexOf(id) === -1) {
            filterRef.splice(i, 1);
        }
    }

    // sort labels
    filterRef.sort((a, b) => a.id.toLowerCase().localeCompare(b.id.toLowerCase()));
};

export const setFilters = (filterStrings: string[], contents: Content[], key) => {
    contents.forEach(content => (filterStrings = [...filterStrings, ...(content[key] ? content[key] : [])]));

    return filterStrings
        .filter((item, pos) => item && filterStrings.indexOf(item) === pos)
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
        .map(item => ({ id: item, itemName: item }));
};

@Component({
    templateUrl: './index-trips.component.html',
    styleUrls: ['./index-trips.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [SharedHotelHelperService, IndexTripFilterPipe],
    animations: [fadeAnimation]
})
@NovaTemplate('PageIndexTripsComponent')
export class PageIndexTripsComponent extends Page implements OnInit, AfterViewInit {
    filteredData = [];
    allFilters: MultiSelectItem[] = [];
    allCountryFilters: MultiSelectItem[] = [];
    selectedFilters: MultiSelectItem[] = [];
    selectedCountryFilters: MultiSelectItem[] = [];

    dropdownSettings = {
        singleSelection: false,
        text: 'Nach Interessen filtern',
        enableCheckAll: false,
        showCheckbox: true,
        enableSearchFilter: false,
        classes: 'nova-multiselect'
    };
    countryDropdownSettings = {
        singleSelection: false,
        text: 'Nach Regionen filtern',
        enableCheckAll: false,
        showCheckbox: true,
        enableSearchFilter: false,
        classes: 'nova-multiselect'
    };

    contentFontStyle = '';
    allFilterStrings = [];
    allCountryFilterStrings = [];

    @ViewChild('font_size') private fontSizeElement: ElementRef;
    @ViewChild('filter_font') private filterFontElement: ElementRef;
    @ViewChildren('TripDetailHeaderElements') private TripDetailHeaderElements: QueryList<ElementRef>;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public route: ActivatedRoute,
        public router: Router,
        public tripsHelper: SharedHotelHelperService,
        public media: MediaService,
        public title: Title,
        private renderer: Renderer2,
        private filterPipe: IndexTripFilterPipe
    ) {
        super(nova, uiEventHandler, route, router, meta, title);

        const path = window.location.pathname;
        const filter = sessionStorage.getItem('filter:' + path);
        if (filter) {
            const filterData = JSON.parse(filter);
            if (filterData.selectedFilters) {
                this.selectedFilters = filterData.selectedFilters;
            }
            if (filterData.selectedCountryFilters) {
                this.selectedCountryFilters = filterData.selectedCountryFilters;
            }
        }
    }

    ngOnInit(): void {
        this.data.contents.forEach(content => this.tripsHelper.handleTagInputs(content));

        this.allFilters = setFilters(this.allFilterStrings, this.data.contents, 'hotel_labels');
        this.allCountryFilters = setFilters(this.allCountryFilterStrings, this.data.contents, 'country_filters');

        this.filter();
    }

    filter() {
        this.filteredData = this.filterPipe.transform(
            this.data.contents,
            this.selectedFilters,
            this.selectedCountryFilters
        );

        // FTI-83 - dont show trips without name
        this.filteredData = this.filteredData.filter(trip => trip.header !== undefined);

        setRemainingFilters(this.allFilters, this.filteredData, 'hotel_labels');

        const path = window.location.pathname;
        sessionStorage.setItem(
            'filter:' + path,
            JSON.stringify({
                selectedFilters: this.selectedFilters,
                selectedCountryFilters: this.selectedCountryFilters
            })
        );
    }

    ngAfterViewInit() {
        if (this.data['font']) {
            this.nova.getFont(this.data['font']).subscribe(fontData => {
                this.renderer.setStyle(this.fontSizeElement.nativeElement, 'font-family', fontData.name);
            });
        }

        if (this.data['filter_font'] && this.filterFontElement) {
            this.nova.getFont(this.data['filter_font']).subscribe(fontData => {
                this.renderer.setStyle(this.filterFontElement.nativeElement, 'font-family', fontData.name);
            });
        }

        if (this.data['trip_detail_header_font']) {
            this.nova.getFont(this.data['trip_detail_header_font']).subscribe(fontData => {
                this.TripDetailHeaderElements &&
                    this.TripDetailHeaderElements.toArray().map(element => {
                        this.renderer.setStyle(element.nativeElement, 'font-family', fontData.name);
                    });
            });
        }

        if (this.data['trip_detail_font']) {
            this.nova.getFont(this.data['trip_detail_font']).subscribe(fontData => {
                this.contentFontStyle = fontData.name;
            });
        }
    }

    goTo(id) {
        const floor = this.data.contents.filter(content => content._id === id)[0];
        const params = this.route.params['value'];

        this.router.navigate(['/', params['langHandle'], params['issueHandle'], params['pageHandle'], floor.handle], {
            queryParamsHandling: 'preserve'
        });
    }
}
