<form [formGroup]="myForm" *ngIf="form" [hidden]="formSent" (ngSubmit)="onSubmit()">
    <div class="form-group" *ngFor="let field of form.fields; let key = index">
        <label [for]="field.id">{{ field.label }}<span *ngIf="field.required">*</span></label>
        <input
            [formControlName]="field.key"
            class="form-control"
            [attr.required]="field.required"
            [id]="field.id"
            *ngIf="field.type == 'textField' || field.type == 'emailField' || field.type == 'numberField'"
            [type]="convertType(field.type)"
            [name]="field.key"
        />
        <input
            [formControlName]="field.key"
            class="form-control"
            [attr.required]="field.required"
            [id]="field.id"
            *ngIf="field.type == 'customField'"
            [pattern]="field.regEx"
            [name]="field.key"
        />
        <select
            [formControlName]="field.key"
            class="form-control"
            [attr.required]="field.required"
            [id]="field.id"
            *ngIf="field.type == 'select'"
            [name]="field.key"
        >
            <option *ngFor="let val of field.values">{{ val }}</option>
        </select>
        <textarea
            [formControlName]="field.key"
            rows="10"
            class="form-control"
            [attr.required]="field.required"
            [id]="field.key"
            *ngIf="field.type == 'textAreaField'"
            [name]="field.key"
        ></textarea>
    </div>
    <div class="form-group" style="display: flex">
        <label style="font-size: 13px" *ngIf="form.footnote.type === 'default'">
            {{ 'PRIVACY_POLICY_ACCEPT_TEXT' | translate }}
            <a (click)="openTerms()" style="cursor: pointer">{{ 'PRIVACY_POLICY' | translate }}</a
            >). <span>*</span>
        </label>
        <label *ngIf="form.footnote.type === 'custom'" class="form-group" [innerHTML]="form.footnote.text"></label>
    </div>
    <button
        [class.round]="data.form_button_rounded"
        [disabled]="!myForm.valid"
        [ngStyle]="{
            'background-color': uiEventHandler.getColor(data['form_button_bgColor']),
            'border-color': uiEventHandler.getColor(data['form_button_borderColor']),
            color: uiEventHandler.getColor(data['form_button_color'])
        }"
        type="submit"
        class="form-button"
    >
        {{ 'SUBMIT' | translate }}
    </button>
</form>
<div [hidden]="!formSent" [class.fadeInUp]="formSent" class="green animated">
    <div class="header">
        <div class="sign"></div>
    </div>
    <p>{{ 'Ihre Anfrage wurde erfolgreich versendet' | translate }}</p>
</div>
