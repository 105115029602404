/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./nova-columnizer.component";
import * as i2 from "../../_services/uievent-handler.service";
var styles_NovaColumnizerComponent = [];
var RenderType_NovaColumnizerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NovaColumnizerComponent, data: {} });
export { RenderType_NovaColumnizerComponent as RenderType_NovaColumnizerComponent };
export function View_NovaColumnizerComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { elm: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["elm", 1]], null, 1, "div", [], null, null, null, null, null)), i0.ɵncd(null, 0)], null, null); }
export function View_NovaColumnizerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "nova-columnizer", [], null, null, null, View_NovaColumnizerComponent_0, RenderType_NovaColumnizerComponent)), i0.ɵdid(1, 4374528, null, 0, i1.NovaColumnizerComponent, [i2.UIEventHandlerService], null, null)], null, null); }
var NovaColumnizerComponentNgFactory = i0.ɵccf("nova-columnizer", i1.NovaColumnizerComponent, View_NovaColumnizerComponent_Host_0, {}, {}, ["*"]);
export { NovaColumnizerComponentNgFactory as NovaColumnizerComponentNgFactory };
