/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nova-fti-overlay-contact.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../nova-form/nova-form.component.ngfactory";
import * as i3 from "../../../nova-form/nova-form.component";
import * as i4 from "../../../../_services/form.service";
import * as i5 from "@angular/forms";
import * as i6 from "../../../../_services/nova-api.service";
import * as i7 from "../../../../_services/uievent-handler.service";
import * as i8 from "../../../../_services/magazine.service";
import * as i9 from "./nova-fti-overlay-contact.component";
var styles_NovaFtiOverlayContactComponent = [i0.styles];
var RenderType_NovaFtiOverlayContactComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NovaFtiOverlayContactComponent, data: {} });
export { RenderType_NovaFtiOverlayContactComponent as RenderType_NovaFtiOverlayContactComponent };
export function View_NovaFtiOverlayContactComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [], [[4, "font-family", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "nova-form", [], null, null, null, i2.View_NovaFormComponent_0, i2.RenderType_NovaFormComponent)), i1.ɵdid(3, 245760, null, 0, i3.NovaFormComponent, [i4.FormService, i5.FormBuilder, i6.NovaAPIService, i7.UIEventHandlerService, i8.MagazineService], { data: [0, "data"], form: [1, "form"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵEMPTY_MAP; var currVal_3 = _co.contactForm; _ck(_v, 3, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.font; _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.contactLabel || "Kontakt"); _ck(_v, 1, 0, currVal_1); }); }
export function View_NovaFtiOverlayContactComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-fti-overlay-contact", [], null, null, null, View_NovaFtiOverlayContactComponent_0, RenderType_NovaFtiOverlayContactComponent)), i1.ɵdid(1, 573440, null, 0, i9.NovaFtiOverlayContactComponent, [i6.NovaAPIService, i8.MagazineService, i7.UIEventHandlerService], null, null)], null, null); }
var NovaFtiOverlayContactComponentNgFactory = i1.ɵccf("nova-fti-overlay-contact", i9.NovaFtiOverlayContactComponent, View_NovaFtiOverlayContactComponent_Host_0, { magazine: "magazine", issue: "issue", font: "font" }, {}, []);
export { NovaFtiOverlayContactComponentNgFactory as NovaFtiOverlayContactComponentNgFactory };
