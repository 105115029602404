import * as tslib_1 from "tslib";
import { AfterContentInit, OnDestroy } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { ElementRef } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MagazineService } from 'app/_services/magazine.service';
import { ActivatedRoute, Router } from '@angular/router';
var PageIndexFloatComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageIndexFloatComponent, _super);
    function PageIndexFloatComponent(nova, mag, renderer, uiEventHandler, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.mag = mag;
        _this.renderer = renderer;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        return _this;
    }
    PageIndexFloatComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        this.sub = this.mag.getMagazine().subscribe(function (data) {
            _this.subFont = _this.nova.getFont(data.navigation.font).subscribe(function (fontData) {
                _this.renderer.setStyle(_this.elm.nativeElement, 'font-family', fontData.name);
            });
            _this.pages = _this.nova.pages.filter(function (page) {
                return (page.templateName === 'index-hotel' ||
                    page.templateName === 'index-roundtrip' ||
                    page.templateName === 'index-trips' ||
                    !page.templateName.startsWith('index-')) &&
                    page.status === 'active' &&
                    page.showInIndex !== 'no';
            });
        });
    };
    PageIndexFloatComponent.prototype.goToPage = function (page, floor) {
        var params = this.router.routerState.root.firstChild.params['value'];
        if (!floor) {
            this.router.navigate(['/', params['langHandle'], params['issueHandle'], page], {
                queryParamsHandling: 'preserve'
            });
        }
        else {
            this.router.navigate(['/', params['langHandle'], params['issueHandle'], page, floor], {
                queryParamsHandling: 'preserve'
            });
        }
    };
    PageIndexFloatComponent.prototype.getType = function (type) {
        if (type === 'article-classic') {
            return 'artikel';
        }
        else if (type === 'image-classic') {
            return 'galerie';
        }
        else if (type === 'interview-classic') {
            return 'interview';
        }
        else if (type === 'video-classic') {
            return 'video';
        }
        return type;
    };
    PageIndexFloatComponent.prototype.ngOnDestroy = function () {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        if (this.subFont) {
            this.subFont.unsubscribe();
        }
    };
    PageIndexFloatComponent = tslib_1.__decorate([
        NovaTemplate('PageIndexFloatComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            MagazineService,
            Renderer2,
            UIEventHandlerService,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], PageIndexFloatComponent);
    return PageIndexFloatComponent;
}(Page));
export { PageIndexFloatComponent };
