<div class="nova-fti-animated-world-icon">
    <div class="globe">
        <div class="globe__sphere"></div>
        <div class="globe__worldmap">
        <div class="globe__worldmap__back"></div>
        <div class="globe__worldmap__front"></div>
        </div>
        <div class="globe__inner_shadow"></div>
    </div>
    <div *ngIf="text" class="globe-text">{{text}}</div>
</div>
