import * as tslib_1 from "tslib";
import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { NovaGalleryPopupComponent } from 'app/_components/nova-gallery-popup/nova-gallery-popup.component';
import { Meta, Title } from '@angular/platform-browser';
import { MediaService } from 'app/_services/media.service';
import { ColorFormatter } from '../../../novamag-api/Types';
import { ActivatedRoute, Router } from '@angular/router';
var PageImageClassicComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageImageClassicComponent, _super);
    function PageImageClassicComponent(nova, mediaService, uiEventHandler, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.mediaService = mediaService;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        _this.detail = false;
        _this.mobile = false;
        _this.original = false;
        return _this;
    }
    PageImageClassicComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.len = this.data.medias.length;
        this.mobile = $('body').width() <= 1024 || $('body').height() < 650 || this.len < 3;
        this.original =
            this.data._id === 'NSPgz74sSTxWEoxat' ||
                this.data._id === 'FQpQdu6jppKNgdm22' ||
                this.data._id === 'jdFaLnmQCM3MbPqJu';
        var thumbnails = this.data.medias.map(function (x) { return x.thumbnail || x.media; });
        this.mediaService.loadMedia(thumbnails).then(function (data) {
            _this.thumbnails = data;
        });
        this.mediaService.loadMedia(this.data.medias).then(function (data) {
            _this.media = data;
            if (!_this.mobile) {
                setTimeout(function () {
                    $(_this.dgElm.nativeElement).gallery();
                    var width;
                    if ($('body').width() <= 991) {
                        width = $('body').width() * 0.8;
                    }
                    else {
                        width = $('body').width() * 0.5;
                    }
                    $(_this.dgElm.nativeElement)
                        .find('.dg-wrapper > div')
                        .css('width', width + 'px');
                    $(_this.dgElm.nativeElement)
                        .find('.dg-wrapper')
                        .css('width', width + 'px');
                }, 100);
            }
        });
    };
    PageImageClassicComponent.prototype.desktopGalleryNav = function (dir) {
        if (this.mobile) {
            dir === 'right' ? this.slider.nextPage() : this.slider.previousPage();
            return;
        }
        var gallery = $(this.dgElm.nativeElement).data('gallery');
        if (!gallery) {
            return;
        }
        if (dir === 'right') {
            gallery._navigate('next');
        }
        if (dir === 'left') {
            gallery._navigate('prev');
        }
    };
    PageImageClassicComponent.prototype.getItemStyle = function () {
        if ((this.data._id === 'D4gwafRzPFtzjQeYa' ||
            this.data._id === 'N2XCDjjHELb6K39BW' ||
            this.data._id === 'pWKMQYwnBqcdJHB3W') &&
            this.data['background_color']) {
            var c = new ColorFormatter(this.data['background_color']);
            return {
                'background-color': c.rgba()
            };
        }
        return {};
    };
    PageImageClassicComponent.prototype.open = function (key) {
        var _this = this;
        this.detail = true;
        $('#mute-button').fadeOut();
        setTimeout(function () {
            var dgHeight = $('.dg-center .nofullscreen').css('height');
            var navBgColor = $('.next-page').css('background-color');
            var navTextColor = $('.next-page').css('color');
            $('.dg-nav .icon-arrow').css('background-color', navBgColor);
            $('.dg-nav .icon-arrow').css('color', navTextColor);
            $('.dg-nav').css('top', parseInt(dgHeight, 10) / 2 - 10 + "px");
        }, 500);
        this.uiEventHandler.hideContentCloseIcon = true;
        if (this.mobile) {
            this.uiEventHandler.showNavArrows = false;
            this.uiEventHandler.hideTitleBar = true;
            this.slider.goTo(key);
            setTimeout(function () {
                _this.slider.resize();
            }, 100);
            this.uiEventHandler.hideSwipeOverlay();
        }
        else {
            var gallery = $(this.dgElm.nativeElement).data('gallery');
            gallery && gallery.goTo(key);
        }
    };
    PageImageClassicComponent.prototype.close = function () {
        this.detail = false;
        this.uiEventHandler.hideTitleBar = false;
        this.uiEventHandler.hideContentCloseIcon = false;
        $('#mute-button').fadeIn();
    };
    PageImageClassicComponent.prototype.getVersion = function () {
        if (this.original) {
            return 'original';
        }
        if ($('body').width() < 991) {
            return 'horizontal-wide';
        }
    };
    PageImageClassicComponent.prototype.ngOnDestroy = function () {
        this.arrowSub && this.arrowSub.unsubscribe();
    };
    PageImageClassicComponent.prototype.getCopyrightUrl = function (copyrightUrl) {
        if (!copyrightUrl) {
            return null;
        }
        var regex = '^https?://';
        var match = copyrightUrl.match(regex);
        if (match) {
            return copyrightUrl;
        }
        return null;
    };
    PageImageClassicComponent = tslib_1.__decorate([
        NovaTemplate('PageImageClassicComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            MediaService,
            UIEventHandlerService,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], PageImageClassicComponent);
    return PageImageClassicComponent;
}(Page));
export { PageImageClassicComponent };
