import { Injectable } from '@angular/core';
import { SSRService } from './ssr.service';
import { MagazineService } from './magazine.service';
import { UIEventHandlerService } from './uievent-handler.service';
import { NavigationEnd, Router } from '@angular/router';

@Injectable()
export class NewsletterService {
    public enabled = false;
    public showed = false;

    private views = 0;
    private timeout;

    constructor(
        public ssr: SSRService,
        public magazine: MagazineService,
        public uiEventHandler: UIEventHandlerService,
        private router: Router
    ) {
        if (
            this.ssr.isBrowser &&
            this.ssr.getParameterByName('ref') !== 'newsletter' &&
            !this.ssr.getParameterByName('presentation') &&
            !this.ssr.getParameterByName('preview')
        ) {
            this.init();
        }
    }

    init() {
        this.magazine.getMagazine().subscribe(magazine => {
            if (
                magazine.newsletter &&
                magazine.newsletter.showSignUpForm === 'yes' &&
                !this.showed &&
                !this.uiEventHandler.getCookie('newsletterShowed')
            ) {
                this.enabled = true;

                // SHOW AFTER TIME
                if (magazine.newsletter.showSignUpFormAfterTime > 0) {
                    this.timeout = setTimeout(() => {
                        if (!this.showed && !this.uiEventHandler.getCookie('newsletterShowed')) {
                            this.uiEventHandler.showNewsletterForm = true;
                            this.showed = true;
                        }
                    }, magazine.newsletter.showSignUpFormAfterTime * 1000);
                }

                if (magazine.newsletter.showSignUpFormAfterViews > 0) {
                    this.router.events.subscribe(event => {
                        if (event instanceof NavigationEnd) {
                            this.views += 1;
                            if (this.views === magazine.newsletter.showSignUpFormAfterViews + 1) {
                                if (!this.showed && !this.uiEventHandler.getCookie('newsletterShowed')) {
                                    clearTimeout(this.timeout);
                                    this.showed = true;
                                    this.uiEventHandler.showNewsletterForm = true;
                                }
                            }
                        }
                    });
                }
            }
        });
    }
}
