export const environment = {
    production: true,
    apiUrl: 'API_URL',
    imgUrl: 'IMG_URL',
    apiVersion: 'API_VERSION',
    debugUrl: 'DEBUG_URL',
    aliasUrl: 'ALIAS_URL',
    utmSource: 'UTM_SOURCE_URL',
    plausibleApiUrl: 'PLAUSIBLE_API_URL',
    elfsightIds: 'ELFSIGHT_IDS'
};
