<div class="content-gebirgslandeplaetze" [ngStyle]="{'background-color':getColor(data['background_color'])}">
    <nova-bg [data]="data" class="background"></nova-bg>

    <div class="container">

        <section class="header">
            <div class="header__left-section">
                <div class="header__title">
                    <h1>{{data.glacier_name}}</h1>
                    <p>{{'SINCE' | translate }} {{data.glacier_since}}</p>
                </div>
                <div *ngIf="data.glacier_nickname" class="header__nickname">"{{data.glacier_nickname}}"</div>
            </div>
            <div class="header__right-section">
               <p class="header__glacier-number">{{data.glacier_number}}</p>
            </div>
        </section>

        <section class="google-earth">
            <a [href]="data.googleEarth_url" target=”_blank” class="google-earth__link-box">{{'CLICK_FOR' | translate }} <i class="google-earth__icon"></i></a>
            <nova-media class="google-earth__image" [mediaId]="data.googleEarth_screenshot" version="horizontal-wide" [bg]="false" [fallback]="true"></nova-media>
        </section>

        <section class="facts">
            <div class="facts__item">
                <i class="facts__icon facts__icon-glacier"></i>
                <div>
                    <p>{{data.glacierHeight_meters}}</p>
                    <p>{{data.glacierHeight_feet}}</p>
                </div>
            </div>
            <div class="facts__item">
                <i class="facts__icon facts__icon-coordinates"></i>
                <div>
                    <p>{{data.coordinates_wgs84}}</p>
                    <p>{{data.coordinates_swissgrid}}</p>
                </div>
            </div>
            <div class="facts__item">
                <i class="facts__icon facts__icon-temperature"></i>
                <div>
                    <p>{{data.temperature}}</p>
                </div>
            </div>
            <div *ngFor="let canton of data.canton" class="facts__item">
                <i [class]="'facts__icon facts__icon-' + canton.toLowerCase()"></i>
                <div>
                    <p>{{canton | translate }}</p>
                </div>
            </div>
        </section>

        <hr class="divider">

        <section class="infos">
            <i class="infos__icon"></i>
            <div>
                <h2 *ngIf="data.comments_text" class="infos__title">{{'REMARKS' | translate }}</h2>
                <p *ngIf="data.comments_text" class="infos__text">
                    <span>{{data.comments_text}}</span>
                    <span *ngIf="!data.comments_sourceUrl" class="infos__source">{{data.comments_source}}</span>
                    <a *ngIf="data.comments_sourceUrl" [href]="data.comments_sourceUrl" target="_blank" class="infos__source color-accent">
                        {{data.comments_source}}
                    </a>
                </p>
                <h2 *ngIf="data.restrictions_text" class="infos__title">{{'RESTRICTIONS' | translate }}</h2>
                <p *ngIf="data.restrictions_text" class="infos__text">
                    <span>{{data.restrictions_text}}</span>
                    <span *ngIf="!data.restrictions_sourceUrl" class="infos__source">{{data.restrictions_source}}</span>
                    <a *ngIf="data.restrictions_sourceUrl" [href]="data.restrictions_sourceUrl" target="_blank" class="infos__source color-accent">
                        {{data.restrictions_source}}
                    </a>
                </p>
                <h2 *ngIf="data.suitability_text" class="infos__title">{{'SUITABILITY' | translate }}</h2>
                <p *ngIf="data.suitability_text" class="infos__text">
                    <span>{{data.suitability_text}}</span>
                    <span *ngIf="!data.suitability_sourceUrl" class="infos__source">{{data.suitability_source}}</span>
                    <a *ngIf="data.suitability_sourceUrl" [href]="data.suitability_sourceUrl" target="_blank" class="infos__source color-accent">
                        {{data.suitability_source}}
                    </a>
                </p>
                <h2 *ngIf="data.fromPilotToPilot_text" class="infos__title">{{'PILOT_TO_PILOT' | translate }}</h2>
                <p *ngIf="data.fromPilotToPilot_text" class="infos__text">
                    <span>{{data.fromPilotToPilot_text}}</span>
                    <span *ngIf="!data.fromPilotToPilot_sourceUrl" class="infos__source">{{data.fromPilotToPilot_source}}</span>
                    <a *ngIf="data.fromPilotToPilot_sourceUrl" [href]="data.fromPilotToPilot_sourceUrl" target="_blank" class="infos__source color-accent">
                        {{data.fromPilotToPilot_source}}
                    </a>
                </p>
            </div>
        </section>

        <section class="map2">
            <a [href]="data.map2_url" target=”_blank” class="map2__link-box">{{'CLICK_FOR' | translate }} <i class="map2__swiss-icon"></i></a>
            <button class="map2__download-button" type="submit" (click)="downloadMap2()">{{'CLICK_FOR' | translate }} <i class="map2__download-icon"></i></button>
            <nova-media class="map2__image" [mediaId]="data.map2_jpg" version="original" [bg]="false" [fallback]="true"></nova-media>
        </section>

        <section class="location">
            <div>
                <h2 class="location__title">{{'LOCATION' | translate }}</h2>
                <p>
                    <span>{{data.location_text}}</span>
                    <span *ngIf="!data.location_sourceUrl" class="location__source">{{data.location_source}}</span>
                    <a *ngIf="data.location_sourceUrl" [href]="data.location_sourceUrl" target="_blank" class="location__source color-accent">
                        {{data.location_source}}
                    </a>
                </p>
            </div>
            <div class="location__icons">
                    <i *ngFor="let symbol of data.symbols" [class]="'location__icon location__icon-' + getSymbolClass(symbol)"></i>
                    <i *ngIf="data.symbols.length > 0" (click)="goToLegend()" class="location__icon-info"></i>
                    <nova-media class="location__icons-right" [mediaId]="data.windrose" version="original" [bg]="false" [fallback]="true"></nova-media>
            </div>
            <div class="location__info">
                <i class="location__icon-pin"></i>     
                <p class="pre-wrap">{{'SHA_LEGENDE' | translate }}</p>
            </div>
        </section>

    </div>
</div>