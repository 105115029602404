import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import * as $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './cover-classic-logo.component.html',
    styleUrls: ['./cover-classic-logo.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageCoverClassicLogoComponent')
export class PageCoverClassicLogoComponent extends Page implements OnInit, OnDestroy {
    public logoStyle;
    public flexboxStyle;
    public logo_size;

    @ViewChild('logo') public logo: ElementRef;

    private resizeSub: Subscription;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);

        this.resizeSub = this.uiEventHandler.resizeEvent$.subscribe(this.onResize.bind(this));
    }

    ngOnDestroy() {
        this.resizeSub.unsubscribe();
    }

    ngOnInit() {
        let logoStyle = '';
        let flexboxStyle = '';

        const posLogo = this.data['logoPosition'];
        const posLogoArr = posLogo.split('-');

        if (posLogoArr[0] === 'top') {
            flexboxStyle += 'justify-content: flex-start;';
        } else if (posLogoArr[0] === 'bottom') {
            flexboxStyle += 'justify-content: flex-end;';
        } else if (posLogoArr[0] === 'center') {
            flexboxStyle += 'justify-content: center;';
        }

        if (posLogoArr[1] === 'left') {
            logoStyle += 'align-self: flex-start;text-align: left;';
        } else if (posLogoArr[1] === 'right') {
            logoStyle += 'align-self: flex-end;text-align: right;';
        } else if (posLogoArr[1] === 'center') {
            logoStyle += 'align-self: center;text-align: center;';
        }

        this.logoStyle = this.convertCSS(logoStyle);
        this.flexboxStyle = this.convertCSS(flexboxStyle);

        setTimeout(() => {
            this.calcMaxSize();
        }, 100);
    }

    calcMaxSize() {
        let logo_size = '';

        if (this.data['logo'] && this.data['logo'].url) {
            let divW = $(this.logo.nativeElement).width();
            const logo = $(this.logo.nativeElement).find('img')[0];
            if (logo['naturalHeight']) {
                logo_size += 'max-height: ' + (divW * logo['naturalHeight']) / logo['naturalWidth'] + 'px;';
            } else {
                $(logo).on('load', () => {
                    divW = $(this.logo.nativeElement).width();
                    logo_size += 'max-height: ' + (divW * logo['naturalHeight']) / logo['naturalWidth'] + 'px;';
                });
            }
        }
        if (this.data['logoSize']) {
            logo_size += 'height: calc(50px + ' + this.data['logoSize'] / 2 + 'vw);';
        }

        this.logo_size = this.convertCSS(logo_size);
    }

    onResize(e) {
        this.calcMaxSize();
    }

    convertCSS(css) {
        const input = css.toString();
        if (input.indexOf(':') > -1) {
            const result = {},
                attributes = input.split(';');
            for (let i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    const entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    }

    setMyStyles(obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    }
}
