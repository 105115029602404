import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NovaAPIService } from '../../_services/nova-api.service';
import { ColorFormatter } from '../../novamag-api/Types';
import { ActivatedRoute, Router } from '@angular/router';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
var NovaContentLinksComponent = /** @class */ (function () {
    function NovaContentLinksComponent(nova, router, route, ui) {
        this.nova = nova;
        this.router = router;
        this.route = route;
        this.ui = ui;
        this.showThumbs = 'showIcons';
        this.links = [];
    }
    NovaContentLinksComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1, _a, links, _b, _c, link, handle, c, c, c, pad, boxShadow, bg, c, padding, e_1_1;
            var _this = this;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.nova.getPageData().subscribe(function (response) {
                            _this.page = response['data'];
                        });
                        links = [];
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 9, 10, 15]);
                        _b = tslib_1.__asyncValues(this.floors);
                        _d.label = 2;
                    case 2: return [4 /*yield*/, _b.next()];
                    case 3:
                        if (!(_c = _d.sent(), !_c.done)) return [3 /*break*/, 8];
                        link = _c.value;
                        if (link.loaded) {
                            links.push(link);
                            return [3 /*break*/, 7];
                        }
                        if (link.type === 'page' || link.type === 'content') {
                            handle = void 0;
                            if (link.type === 'page') {
                                handle = this.nova.findPage(link.target);
                            }
                            else if (link.type === 'content') {
                                handle = this.nova.findContent(link.target);
                            }
                            if (handle) {
                                link.handle = { pageId: handle.page, floorId: handle.floor };
                                link.linkIcon = link.link_icon || handle.linkIcon;
                                link.thumb = handle.thumb || 'assets/img/templates/pages/' + handle.templateName + '.svg';
                                link.templateName = handle.templateName;
                            }
                            else {
                                return [3 /*break*/, 7];
                            }
                        }
                        if (link.type === 'external') {
                            if (!link.target) {
                                console.warn('External link has no target', link);
                                return [3 /*break*/, 7];
                            }
                            link.templateName = 'external';
                            if (!link.target.startsWith('http://') && !link.target.startsWith('https://')) {
                                link.target = 'http://' + link.target;
                            }
                            if (link.link_icon) {
                                link.linkIcon = link.link_icon;
                            }
                        }
                        if (link['text_bgColor']) {
                            c = new ColorFormatter(link['text_bgColor']);
                            link['styleBg'] = c.rgba();
                        }
                        if (link['text_color']) {
                            c = new ColorFormatter(link['text_color']);
                            link['styleColor'] = c.rgba();
                        }
                        if (link['text_borderColor']) {
                            c = new ColorFormatter(link['text_borderColor']);
                            link['styleBorderColor'] = c.rgba();
                        }
                        if (link['text_borderWidth']) {
                            link['borderWidth'] = link['text_borderWidth'];
                        }
                        if (link['text_size']) {
                            link['paddingIcon'] = this.convertCSS(2 - link['text_size'] / 20 + 'em');
                            link['fontSize'] = this.convertCSS(this.ui.calcFontSize(link['text_size']));
                        }
                        else {
                            link['fontSize'] = this.convertCSS(this.ui.calcFontSize(1));
                            link['paddingIcon'] = '2em';
                        }
                        if (link['text_padding']) {
                            pad = link['text_padding']['top'] + 'px ';
                            pad += link['text_padding']['right'] + 4 + 'px ';
                            pad += link['text_padding']['bottom'] + 'px ';
                            pad += link['text_padding']['left'] + 4 + 'px';
                            boxShadow = '';
                            bg = 'transparent';
                            if (link['backgroundColor']) {
                                c = new ColorFormatter(link['backgroundColor']);
                                bg = c.rgba();
                            }
                            boxShadow += '-' + link['text_padding']['left'] + 'px 0 0 ' + bg;
                            boxShadow += ', ' + link['text_padding']['right'] + 'px 0 0 ' + bg;
                            link['boxShadow'] = this.convertCSS(boxShadow);
                            padding = link['text_padding']['top'] + link['text_padding']['bottom'];
                            link['lineHeight'] = this.convertCSS('calc(1.2em + ' + padding + 'px)');
                            link['padding'] = this.convertCSS(pad);
                        }
                        if (this.page.links_style === 'grid') {
                            link['width'] = '100%';
                        }
                        if (!link['text_fonts']) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.nova
                                .getFont(link['text_fonts'])
                                .toPromise()
                                .then(function (fontData) {
                                link.font = fontData.name;
                                links.push(link);
                            })
                                .catch(function () {
                                link.font = 'system-ui';
                                links.push(link);
                            })];
                    case 4:
                        _d.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        links.push(link);
                        _d.label = 6;
                    case 6:
                        link.loaded = true;
                        _d.label = 7;
                    case 7: return [3 /*break*/, 2];
                    case 8: return [3 /*break*/, 15];
                    case 9:
                        e_1_1 = _d.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 15];
                    case 10:
                        _d.trys.push([10, , 13, 14]);
                        if (!(_c && !_c.done && (_a = _b.return))) return [3 /*break*/, 12];
                        return [4 /*yield*/, _a.call(_b)];
                    case 11:
                        _d.sent();
                        _d.label = 12;
                    case 12: return [3 /*break*/, 14];
                    case 13:
                        if (e_1) throw e_1.error;
                        return [7 /*endfinally*/];
                    case 14: return [7 /*endfinally*/];
                    case 15:
                        this.links = links;
                        return [2 /*return*/];
                }
            });
        });
    };
    NovaContentLinksComponent.prototype.goTo = function (link) {
        if (link.type === 'external') {
            var win = window.open(link.target, '_blank');
            win.focus();
        }
        else if (link.handle) {
            var params = this.route.params['value'];
            if (link.handle.floorId) {
                this.router.navigate(['/', params['langHandle'], params['issueHandle'], link.handle.pageId, link.handle.floorId], { queryParamsHandling: 'preserve' });
            }
            else {
                this.router.navigate(['/', params['langHandle'], params['issueHandle'], link.handle.pageId], {
                    queryParamsHandling: 'preserve'
                });
            }
        }
    };
    NovaContentLinksComponent.prototype.getIcon = function (type) {
        if (type === 'interview-classic')
            return 'fas fa-comment';
        else if (type === 'external')
            return 'fas fa-external-link-alt';
        else if (type === 'image-classic')
            return 'fas fa-images';
        else if (type === 'image-gallery')
            return 'fas fa-images';
        else if (type === 'product')
            return 'fas fa-images';
        else if (type === 'form-classic')
            return 'fas fa-edit';
        else if (type === 'video-classic' || type === 'video-cinema')
            return 'fas fa-video';
        else if (type === 'article-classic' ||
            type === 'article-scroll' ||
            type === 'timeline-classic' ||
            type === 'timeline-left' ||
            type === 'timeline-right')
            return 'fas fa-book-open';
        else
            return 'fas fa-book';
    };
    NovaContentLinksComponent.prototype.convertCSS = function (css) {
        var input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {}, attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    };
    NovaContentLinksComponent.prototype.getStyle = function () {
        if (typeof window === 'undefined') {
            return;
        }
        var style = '';
        if (this.page.links_style === 'grid') {
            style += "display: grid;";
        }
        if (this.page.links_min_width) {
            style += "grid-template-columns: repeat(auto-fit, minmax(" + this.page.links_min_width + "px, 1fr));";
        }
        if (this.page.links_grid_gap) {
            style += "gap: " + this.page.links_grid_gap + "px;";
            if (this.page.links_grid_gap > 1) {
                if (this.page.background_bottom_box_color && this.page.background_bottom_box_color.a > 0) {
                    style += "padding: 19px;";
                }
                else {
                    style += "padding-top: " + this.page.links_grid_gap + "px;";
                }
            }
        }
        return this.convertCSS(style);
    };
    NovaContentLinksComponent.prototype.setMyStyles = function (obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    };
    return NovaContentLinksComponent;
}());
export { NovaContentLinksComponent };
