import { Component, OnInit, ViewEncapsulation, PLATFORM_ID, AfterViewInit } from '@angular/core';
import { NovaTemplate } from 'app/_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    templateUrl: './pandora-page-2.component.html',
    styleUrls: ['./pandora-page-2.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PagePandoraPage2Component')
export class PagePandoraPage2Component extends Page implements OnInit, AfterViewInit {
    public data: any;

    public formStatus: any;
    public showSuccess: boolean;
    public showMsg: boolean;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public router: Router,
        public route: ActivatedRoute
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
        this.route.queryParams.subscribe(params => {
            this.formStatus = params['result'];
        });
        this.showMsg = false;
    }

    ngOnInit() {}

    ngAfterViewInit() {}

    scrollDown() {
        var viewPortHeight = $(window).innerHeight();
        $('.scrollbar-holder')
            .stop()
            .animate({ scrollTop: viewPortHeight }, 500, 'swing', function() {});
    }

    isUrl(media) {
        return typeof media !== 'object' && media.indexOf('http') > -1;
    }

    getImageProxyUrl(url) {
        const size = `s:200:150:1:1`;
        const rt = 'fit';
        return `${this.nova.getImgUrl()}/insecure/rt:${rt}/${size}/g:sm/plain/${url}`;
    }

    filterItemsOfType(type) {
        return this.data.medias.filter(x => x.placement == type);
    }
}
