<div class="nova-popup">
    <div class="content">
        <section class="header">
            <a href="https://novamag.de?utm_source=Paywall&utm_medium=Novamag-Logo&utm_campaign=Paywall" target="_blank" class="logo">
                <img src="assets/img/novamag.svg" />
            </a>
        </section>

        <!-- INITIAL -->
        <section *ngIf="state.step === 'initial'">
            <div class="main-content">
                <p class="pre-wrap">
                    {{ 'PAIDCONTENT_INITIAL_TEXT' | translate }} 
                </p>
                <button (click)="redirectToCheckout()">
                    {{isSubmitting === true ? ('LOADING' | translate) : ('PAIDCONTENT_INITIAL_ACTION' | translate) }}
                </button>
            </div>
        </section>

        <!-- LOG IN -->
        <section *ngIf="state.step === 'login'">
            <div class="main-content" *ngIf="state.status !== 'success'">
                <h2>{{ 'PAIDCONTENT_LOGIN_TITLE' | translate }}</h2>
                <form (ngSubmit)="loginForm.valid && onLoginSubmit()" [formGroup]="loginForm">
                    <div class="form-group">
                        <input type="email" required placeholder="E-Mail" formControlName="email" [class.invalid]="loginForm.get('email').invalid && loginForm.get('email').touched" />
                        <div *ngIf="(loginForm.get('email').invalid && loginForm.get('email').touched) || loginForm.get('email').dirty">
                            <small *ngIf="loginForm.get('email').errors?.required" class="text-danger">{{ 'EMAIL_REQUIRED' | translate }}</small>
                            <small *ngIf="loginForm.get('email').errors?.pattern" class="text-danger">{{ 'EMAIL_INVALID' | translate }}</small>
                        </div>
                    </div>
                    <button type="submit">
                        {{state.status !== 'pending' ? ('PAIDCONTENT_LOGIN_ACTION' | translate) : ('LOADING' | translate) }}
                    </button>

                    <div *ngIf="state.status === 'error'" class="error">
                        <p>{{state.message}}</p>
                    </div>
                </form>
            </div>

            <div *ngIf="state.status === 'success'">
                <p class="pre-wrap">
                    {{ 'PAIDCONTENT_LOGIN_SUCCESS' | translate }}
                </p>
                <button (click)="setStep({step:'initial'})">Ok</button>
            </div>
        </section>
        
        <section>
            <hr>
            <p *ngIf="state.step === 'initial'">
                {{ 'PAIDCONTENT_INITIAL_FOOTER' | translate }}
                <br *ngIf="uiEventHandler.isTablet()">
                <a (click)="setStep({step:'login'})">{{ 'PAIDCONTENT_INITIAL_FOOTER_ACTION' | translate }}</a>
            </p>
            <p *ngIf="state.step === 'login'">
                {{ 'PAIDCONTENT_LOGIN_FOOTER' | translate }}
                <br *ngIf="uiEventHandler.isTablet()">
                <a (click)="setStep({step:'initial'})">{{ 'PAIDCONTENT_LOGIN_FOOTER_ACTION' | translate }}</a>
            </p>
        </section>
    </div>
</div>

