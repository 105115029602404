/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nova-contact-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../nova-nav-item/nova-nav-item.component.ngfactory";
import * as i3 from "../nova-nav-item/nova-nav-item.component";
import * as i4 from "../../_services/nova-api.service";
import * as i5 from "../../_services/magazine.service";
import * as i6 from "../../_services/uievent-handler.service";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../nova-form/nova-form.component.ngfactory";
import * as i9 from "../nova-form/nova-form.component";
import * as i10 from "../../_services/form.service";
import * as i11 from "@angular/forms";
import * as i12 from "./nova-contact-popup.component";
var styles_NovaContactPopupComponent = [i0.styles];
var RenderType_NovaContactPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NovaContactPopupComponent, data: {} });
export { RenderType_NovaContactPopupComponent as RenderType_NovaContactPopupComponent };
export function View_NovaContactPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "nova-contact-popup"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "nova-nav-item", [["class", "icon-cancel my-close-icon"]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onMouseEnter($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onMouseLeave($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).onTouchStart($event.target) !== false);
        ad = (pd_2 && ad);
    } if (("touchend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3).onTouchEnd($event.target) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.close() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_NovaNavItemComponent_0, i2.RenderType_NovaNavItemComponent)), i1.ɵdid(3, 245760, null, 0, i3.NovaNavItemComponent, [i4.NovaAPIService, i5.MagazineService, i6.UIEventHandlerService, i7.DomSanitizer, i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Kontakt"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "nova-form", [], null, null, null, i8.View_NovaFormComponent_0, i8.RenderType_NovaFormComponent)), i1.ɵdid(7, 245760, null, 0, i9.NovaFormComponent, [i10.FormService, i11.FormBuilder, i4.NovaAPIService, i6.UIEventHandlerService, i5.MagazineService], { data: [0, "data"], form: [1, "form"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_1 = i1.ɵEMPTY_MAP; var currVal_2 = _co.magazine.form; _ck(_v, 7, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.uiEventHandler.showContactForm; _ck(_v, 0, 0, currVal_0); }); }
export function View_NovaContactPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-contact-popup", [], null, null, null, View_NovaContactPopupComponent_0, RenderType_NovaContactPopupComponent)), i1.ɵdid(1, 245760, null, 0, i12.NovaContactPopupComponent, [i4.NovaAPIService, i5.MagazineService, i6.UIEventHandlerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NovaContactPopupComponentNgFactory = i1.ɵccf("nova-contact-popup", i12.NovaContactPopupComponent, View_NovaContactPopupComponent_Host_0, {}, {}, []);
export { NovaContactPopupComponentNgFactory as NovaContactPopupComponentNgFactory };
