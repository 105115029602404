import { Location } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Page } from 'app/pages/page';
import { NovaTemplate } from 'app/_decorators/template.decorator';
import { BlogService } from 'app/_services/blog.service';
import { MediaService } from 'app/_services/media.service';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { fadeAnimation } from '../travelworld-modern/travelworld-slider/fadeIn.animation';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './gebirgslandeplaetze-index.component.html',
    styleUrls: ['./gebirgslandeplaetze-index.component.scss'],
    animations: [fadeAnimation]
})
@NovaTemplate('PageGebirgslandeplaetzeIndexComponent')
export class PageGebirgslandeplaetzeIndexComponent extends Page implements OnInit, AfterViewInit {
    public glaciers = [];
    public isLoading = true;
    public searchValue = '';
    public filterSmallGlaciers = false;
    public filterMediumGlaciers = false;
    public filterLargeGlaciers = false;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public mediaService: MediaService,
        public location: Location,
        public blogService: BlogService,
        public router: Router,
        public route: ActivatedRoute
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    async ngOnInit() {
        this.glaciers = this.data.contents.sort((a, b) => {
            if (a.glacier_name > b.glacier_name) return 1;
            if (b.glacier_name > a.glacier_name) return -1;

            return 0;
        });

        this.isLoading = false;
    }

    async filter() {
        this.glaciers = this.data.contents
            .filter(
                content =>
                    content.glacier_name.toLowerCase().includes(this.searchValue.toLowerCase()) ||
                    content.glacier_number.toLowerCase().includes(this.searchValue.toLowerCase()) ||
                    content.canton.some(canton => canton.toLowerCase().includes(this.searchValue.toLowerCase())) ||
                    (content.glacier_nickname &&
                        content.glacier_nickname.toLowerCase().includes(this.searchValue.toLowerCase()))
            )
            .filter(content => {
                if (this.filterSmallGlaciers && this.filterMediumGlaciers && this.filterLargeGlaciers) {
                    return content;
                }

                if (!this.filterSmallGlaciers && !this.filterMediumGlaciers && !this.filterLargeGlaciers) {
                    return content;
                }

                return (
                    (this.filterSmallGlaciers && content.glacierHeight_index < 2000) ||
                    (this.filterMediumGlaciers &&
                        content.glacierHeight_index >= 2000 &&
                        content.glacierHeight_index < 2700) ||
                    (this.filterLargeGlaciers && content.glacierHeight_index >= 2700)
                );
            });
    }

    ngAfterViewInit() {
        this.data.contents.length > 0 && $('.next-page')[0].style.setProperty('opacity', '1', 'important');
    }

    getIconColor(height) {
        if (height < 2000) return '#00F91A'; // green
        if (height >= 2000 && height < 2700) return '#FFFC04'; // yellow
        if (height >= 2700) return '#FF2600'; // red

        return 'black';
    }

    async downloadGebirgslandeplaetzeMap() {
        const url = await this.mediaService.getMediaUrl(this.data.gebirgslandeplaetze_pdf, 'original').toPromise();
        window.open(url);
    }
}
