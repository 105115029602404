/**
 * Typescript Decorator for finding a Component by its string name
 */
export const REGISTRY = new Map<string, any>();

export function NovaTemplate(originalName?: string) {
    return (target: Function) => {
        if (!originalName) {
            console.error('WARNING: Component name should be passed as argument for production builds');
        }
        REGISTRY.set(originalName || target.name, target);
    };
}

export function getComponent(name: string) {
    return REGISTRY.get(name);
}
