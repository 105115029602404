<div class="landing">
    <div class="title-bar"
        *ngIf="magazine && magazine.showLogoOrLabel != 'none'"
        [ngClass]="{nobg: magazine.labelHasBackground == 'no'}"
    >
        <nova-nav-item
            [hover]="false"
            class="logo"
            *ngIf="magazine.showLogoOrLabel == 'both' || magazine.showLogoOrLabel == 'logo'"
        >
            <img [src]="logo || 'assets/img/novamag.svg'" alt="Logo" />
        </nova-nav-item>
        <nova-nav-item
            [ngStyle]="{'font-family':font}"
            [hover]="false"
            class="title title-landing"
            *ngIf="(magazine.showLogoOrLabel == 'both' || magazine.showLogoOrLabel == 'label' && magazine.label)"
        >
            {{ magazine.label }}
        </nova-nav-item>
        <div [ngStyle]="{'border-top-color':ui.getColor(magazine.navigation.backgroundColor)}" class="after"></div>
    </div>
    <ng-template #dynamicItemView></ng-template>
        <div class="fuchs" *ngIf="issues && issues.length == 0">
        <img src="assets/img/error-issues.png" />
    </div>
</div>
