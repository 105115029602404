<div id="page-{{ id }}" class="page page-image-classic">
    <nova-bg [data]="data" [forceVideo]="true"></nova-bg>
    <nova-scrollbar-holder>
        <div class="scroller">
            <div class="row overview">
                <div class="col-lg-3 col-md-4">
                    <nova-mobile-img [data]="data" class="show-for-small-only left"></nova-mobile-img>
                    <div class="api-title clear">
                        <h3 *ngIf="data.label" class="dateline" [ngStyle]="{'background-color':getColor(data['box_color_top'])}">
                            <nova-element [data]="data" name="label"></nova-element>
                        </h3>
                        <div class="headline" [ngStyle]="{'background-color':getColor(data['box_color_top'])}">
                            <h1>
                                <nova-element [data]="data" name="header"></nova-element>
                            </h1>
                            <h2 *ngIf="data.subHeader">
                                <nova-element [data]="data" name="subHeader"></nova-element>
                            </h2>
                        </div>
                    </div>
                    <div class="text" [ngStyle]="{'background-color':getColor(data['box_color_bottom'])}">
                        <nova-text-element class="con" [data]="data.text"></nova-text-element>
                        <nova-content-links class="content-links" *ngIf="data.links && data.links.length > 0" [floors]="data.links"></nova-content-links>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8 img-con" *ngIf="media">
                    <div class="row">
                        <div class="col-md-3 col-lg-2 col-sm-3 col-xs-6" *ngFor="let val of media;let key = index;">
                            <div class="img-bg">
                                <div class="img-placeholder img-placeholder-43">
                                    <img data-id="{{ key }}" width="400px" height="300px" [src]="mediaService.getMediaUrlByData(val, 'thumb')" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nova-scrollbar-holder>
</div>
