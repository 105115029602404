<h2 [style.font-family]="font">{{ 'SEARCH' | translate }}</h2>
<input [placeholder]="'SEARCH' | translate" type="text" (ngModelChange)="getPages($event)" [(ngModel)]="searchField" class="form-control search-input" />
<div class="items">
  <div class="item" *ngFor="let value of pages; let key = index;" tabIndex="-1">
      <div [class.active]="currentPage == value.key" (click)="goTo(value.key+1, value.subKey)" >
          <div class="overlay"></div>
          <img *ngIf="value.thumbnail && !nova.isThumbLoading(value.thumbnail)" [src]="value.thumbnail.url" />
          <img *ngIf="!value.thumbnail || nova.isThumbLoading(value.thumbnail)" [src]="'assets/img/templates/'+ ((value.type === 'content') ? 'contents' : 'pages') +'/' + value.templateName +'.svg'" />
          <span [ngStyle]="{'fontFamily':font}"><b [style.background-color]="isActive(currentPage == value.key)">{{ pad(value.key + 1, 2) }}</b> {{ uiEventHandler.getContentTableEntryName(value) }}</span>
      </div>
  </div>
</div>