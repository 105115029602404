/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nova-fti-animated-world-icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./nova-fti-animated-world-icon.component";
import * as i4 from "../../../_services/ssr.service";
var styles_NovaFtiAnimatedWorldicon = [i0.styles];
var RenderType_NovaFtiAnimatedWorldicon = i1.ɵcrt({ encapsulation: 0, styles: styles_NovaFtiAnimatedWorldicon, data: {} });
export { RenderType_NovaFtiAnimatedWorldicon as RenderType_NovaFtiAnimatedWorldicon };
function View_NovaFtiAnimatedWorldicon_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "globe-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 1, 0, currVal_0); }); }
export function View_NovaFtiAnimatedWorldicon_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "nova-fti-animated-world-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "globe"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "globe__sphere"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "globe__worldmap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "globe__worldmap__back"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "globe__worldmap__front"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "globe__inner_shadow"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaFtiAnimatedWorldicon_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 8, 0, currVal_0); }, null); }
export function View_NovaFtiAnimatedWorldicon_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-fti-animated-world-icon", [], null, null, null, View_NovaFtiAnimatedWorldicon_0, RenderType_NovaFtiAnimatedWorldicon)), i1.ɵdid(1, 114688, null, 0, i3.NovaFtiAnimatedWorldicon, [i4.SSRService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NovaFtiAnimatedWorldiconNgFactory = i1.ɵccf("nova-fti-animated-world-icon", i3.NovaFtiAnimatedWorldicon, View_NovaFtiAnimatedWorldicon_Host_0, { text: "text" }, {}, []);
export { NovaFtiAnimatedWorldiconNgFactory as NovaFtiAnimatedWorldiconNgFactory };
