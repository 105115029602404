import { DomSanitizer } from '@angular/platform-browser';
import { Component, ViewEncapsulation } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { OnInit } from '@angular/core';
import { PageVideoCinemaComponent } from 'app/pages/templates/video-cinema/video-cinema.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: '../../templates/video-cinema/video-cinema.component.html',
    styleUrls: ['../../templates/video-classic/video-classic.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('ServerVideoCinemaComponent')
export class ServerVideoCinemaComponent extends PageVideoCinemaComponent implements OnInit {
    constructor(
        public nova: NovaAPIService,
        public sanitizer: DomSanitizer,
        public renderer: Renderer2,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, meta, title, route, router);
    }

    ngOnInit() {
        super.ngOnInit();
        this.addOgTags();
    }
}
