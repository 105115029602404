<div class="items">
  <div class="item" *ngFor="let value of nova.pages; let key = index;" tabIndex="-1"
    [hidden]="value.showInIndex == 'no'">
    <div [class.active]="currentPage == key" (click)="goTo(key+1, 1)">
      <div class="overlay"></div>
      <img *ngIf="value.thumbnail && !nova.isThumbLoading(value.thumbnail)" [src]="value.thumbnail.url" />
      <img *ngIf="!value.thumbnail || nova.isThumbLoading(value.thumbnail)"
        [src]="'assets/img/templates/pages/' + value.templateName +'.svg'" />
      <span [ngStyle]="{'fontFamily':font}"><b
          [style.background-color]="isActive(currentPage == key)">{{ pad(key + 1, 2) }}</b>
        {{ uiEventHandler.getContentTableEntryName(value) }}</span>
    </div>
  </div>
</div>