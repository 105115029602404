/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./topic-teaser.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../_components/nova-content-links/nova-content-links.component.ngfactory";
import * as i3 from "../../../_components/nova-content-links/nova-content-links.component";
import * as i4 from "../../../_services/nova-api.service";
import * as i5 from "@angular/router";
import * as i6 from "../../../_services/uievent-handler.service";
import * as i7 from "../../../_components/nova-bg/nova-bg.component.ngfactory";
import * as i8 from "../../../_components/nova-bg/nova-bg.component";
import * as i9 from "../../../_components/nova-scrollbar-holder/nova-scrollbar-holder.component.ngfactory";
import * as i10 from "../../../_components/nova-scrollbar-holder/nova-scrollbar-holder.component";
import * as i11 from "../../../_components/nova-mobile-img/nova-mobile-img.component.ngfactory";
import * as i12 from "../../../_components/nova-mobile-img/nova-mobile-img.component";
import * as i13 from "../../../_services/media.service";
import * as i14 from "@angular/platform-browser";
import * as i15 from "@angular/common";
import * as i16 from "../../../_components/nova-title/nova-title.component.ngfactory";
import * as i17 from "../../../_components/nova-title/nova-title.component";
import * as i18 from "../../../_components/nova-text-element/nova-text-element.component.ngfactory";
import * as i19 from "../../../_components/nova-text-element/nova-text-element.component";
import * as i20 from "../../../_services/magazine.service";
import * as i21 from "../../../_services/cookie.service";
import * as i22 from "../../../_components/nova-text-gallery/nova-text-gallery.component.ngfactory";
import * as i23 from "../../../_components/nova-text-gallery/nova-text-gallery.component";
import * as i24 from "./topic-teaser.component";
var styles_PageTopicTeaserComponent = [i0.styles];
var RenderType_PageTopicTeaserComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PageTopicTeaserComponent, data: {} });
export { RenderType_PageTopicTeaserComponent as RenderType_PageTopicTeaserComponent };
function View_PageTopicTeaserComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-content-links", [["class", "content-links"]], null, null, null, i2.View_NovaContentLinksComponent_0, i2.RenderType_NovaContentLinksComponent)), i1.ɵdid(1, 114688, null, 0, i3.NovaContentLinksComponent, [i4.NovaAPIService, i5.Router, i5.ActivatedRoute, i6.UIEventHandlerService], { floors: [0, "floors"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.links; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PageTopicTeaserComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "page page-topic-teaser"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "nova-bg", [], null, null, null, i7.View_NovaBgComponent_0, i7.RenderType_NovaBgComponent)), i1.ɵdid(2, 114688, null, 0, i8.NovaBgComponent, [i6.UIEventHandlerService], { data: [0, "data"], forceVideo: [1, "forceVideo"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 13, "nova-scrollbar-holder", [], null, null, null, i9.View_NovaScrollbarHolderComponent_0, i9.RenderType_NovaScrollbarHolderComponent)), i1.ɵdid(4, 114688, null, 0, i10.NovaScrollbarHolderComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 11, "div", [["class", "scroller"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "nova-mobile-img", [["class", "show-for-small-only left"]], [[1, "style", 2]], null, null, i11.View_NovaMobileImgComponent_0, i11.RenderType_NovaMobileImgComponent)), i1.ɵdid(7, 114688, null, 0, i12.NovaMobileImgComponent, [i13.MediaService, i1.Renderer2, i14.DomSanitizer, i6.UIEventHandlerService], { data: [0, "data"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "bg animated fadeIn clear"]], null, null, null, null, null)), i1.ɵdid(9, 278528, null, 0, i15.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(10, { "backgroundColor": 0 }), (_l()(), i1.ɵeld(11, 0, null, null, 1, "nova-title", [], [[2, "noBg", null]], null, null, i16.View_NovaTitleComponent_0, i16.RenderType_NovaTitleComponent)), i1.ɵdid(12, 114688, null, 0, i17.NovaTitleComponent, [], { data: [0, "data"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "nova-text-element", [["class", "con"]], null, [[null, "openGallery"]], function (_v, en, $event) { var ad = true; if (("openGallery" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18).openSlider($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i18.View_NovaTextElementComponent_0, i18.RenderType_NovaTextElementComponent)), i1.ɵdid(14, 4964352, null, 0, i19.NovaTextElementComponent, [i6.UIEventHandlerService, i13.MediaService, i4.NovaAPIService, i5.Router, i14.DomSanitizer, i5.ActivatedRoute, i20.MagazineService, i21.CookieService, i15.DOCUMENT], { data: [0, "data"] }, { openGallery: "openGallery" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageTopicTeaserComponent_1)), i1.ɵdid(16, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "nova-text-gallery", [], null, null, null, i22.View_NovaTextGalleryComponent_0, i22.RenderType_NovaTextGalleryComponent)), i1.ɵdid(18, 49152, [["gallery", 4]], 0, i23.NovaTextGalleryComponent, [i6.UIEventHandlerService, i1.Renderer2, i15.DOCUMENT], { medias: [0, "medias"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.data; var currVal_2 = true; _ck(_v, 2, 0, currVal_1, currVal_2); _ck(_v, 4, 0); var currVal_4 = _co.data; _ck(_v, 7, 0, currVal_4); var currVal_5 = _ck(_v, 10, 0, _co.getColor(_co.data["box_color"])); _ck(_v, 9, 0, currVal_5); var currVal_7 = _co.data; _ck(_v, 12, 0, currVal_7); var currVal_8 = _co.data.text; _ck(_v, 14, 0, currVal_8); var currVal_9 = (_co.data.links && (_co.data.links.length > 0)); _ck(_v, 16, 0, currVal_9); var currVal_10 = _co.data.textImages; _ck(_v, 18, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "page-", _co.id, ""); _ck(_v, 0, 0, currVal_0); var currVal_3 = i1.ɵnov(_v, 7).valueAsStyle; _ck(_v, 6, 0, currVal_3); var currVal_6 = !_co.data.background_media; _ck(_v, 11, 0, currVal_6); }); }
export function View_PageTopicTeaserComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_PageTopicTeaserComponent_0, RenderType_PageTopicTeaserComponent)), i1.ɵdid(1, 114688, null, 0, i24.PageTopicTeaserComponent, [i4.NovaAPIService, i6.UIEventHandlerService, i14.Meta, i14.Title, i5.ActivatedRoute, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageTopicTeaserComponentNgFactory = i1.ɵccf("ng-component", i24.PageTopicTeaserComponent, View_PageTopicTeaserComponent_Host_0, {}, {}, []);
export { PageTopicTeaserComponentNgFactory as PageTopicTeaserComponentNgFactory };
