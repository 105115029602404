<ng-template #empty>
    <span></span>
</ng-template>

<nova-media
    *ngIf="!fullWidth && images && images[0]"
    class="background-image"
    [mediaId]="images[0].slider_media"
    version="vertical-wide"
    [bg]="true"></nova-media>
<div
    class="background-image api-background"
    *ngIf="!fullWidth && apiImageMode"
    [ngStyle]="{'background-image': 'url('+getImageProxyUrl(getImages()[0].filename) +')'}"></div>

<div class="backdrop"
*ngIf="!fullWidth"></div>

<div class="carousel">
    <ng-container *ngTemplateOutlet="empty"></ng-container>
    <div class="carousel-container">
        <div class="navigation">
            <div class="arrows">
                <div class="arrow" (click)="slideLeft()">
                    <i class="icon-arrow-left"></i>
                </div>
                <div class="arrow" (click)="slideRight()">
                    <i class="icon-arrow-right"></i>
                </div>
            </div>
        </div>
        <div #owl
             class="owl-carousel">
            <div *ngFor="let image of getImages()"
                 class="slide">
                <img
                    *ngIf="apiImageMode"
                    [src]="getImageProxyUrl(image.filename, !fullWidth ? 'slider' : 'horizontal' )"
                    [alt]="image.description1"
                >
                <nova-media
                    *ngIf="!apiImageMode"
                    [mediaId]="image.slider_media"
                    version="!fullWidth ? horizontal-wide : horizontal"
                ></nova-media>
            </div>
        </div>
        <div class="caption" *ngIf="!fullWidth">
            <div *ngIf="apiImageMode" class="api-caption">
                {{currentImage.description1}}
            </div>
            <nova-element *ngIf="!apiImageMode"
                          [data]="currentImage" name="caption"></nova-element>
        </div>
    </div>
    <div class="bottom-nav" *ngIf="!fullWidth">
        <div #owlNav
             class="owl-carousel">
        <div class="image"
             *ngFor="let image of getImages(); let index = index"
             (click)="goToImage(index)"
        >
            <div *ngIf="index !== currentIndex" class="backdrop"></div>

            <nova-media
                *ngIf="!apiImageMode"
                [mediaId]="image.slider_media"
                version="thumbnail"></nova-media>

            <img
                *ngIf="apiImageMode"
                [src]="getImageProxyUrl(image.filename, 'thumb')" [alt]="image.description1">
        </div>
        </div>
    </div>

</div>
