<div #scrollContainer class="flex-container" [ngClass]="modalOpen ? 'modal-open' : ''">
    <div class="flex-item image-slider">
        <nova-full-image-slider #smallSlider (click)="uiEventHandler.isMobile() || uiEventHandler.isTablet() ? null : openFullscreenSliderModal()"
                                [imageVersion]="getVersion()"
                                [images]="data.medias" [apiImages]="data.images"></nova-full-image-slider>
    </div>

    <div class="flex-item do-scroll">
        <div class="padded-content">
            <div class="hotel-name">
                <nova-text-element [data]="data.header"></nova-text-element>
            </div>

            <div *ngIf="data.roundtrip_categories" class="tags roundtrip-tags">
                <nova-element
                    *ngFor="let label of data.roundtrip_categories; let i = index;"
                    [data]="data"
                    content_name="{{'roundtrip_categories_' + i}}"
                    name="roundtrip_categories"
                ></nova-element>
            </div>

            <div *ngIf="data.interests" class="tags" style="margin-top: 0;">
                <nova-element
                    *ngFor="let label of data.interests; let i = index;"
                    [data]="data"
                    content_name="{{'interests_' + i}}"
                    name="interests"
                ></nova-element>
            </div>

            <nova-text-element style="margin-top: 20px;" [data]="data.subHeader"></nova-text-element>

            <div class="highlights-container">
                <img *ngIf="data.mapUrl" src={{data.mapUrl}} alt="" (click)="openMapModal()">
                <nova-text-element [data]="data.highlights"></nova-text-element>
            </div>


            <p class="hotel-text" *ngIf="data.text" style="margin-top: 30px">
                <nova-text-element [data]="data.text"></nova-text-element>
            </p>

            <nova-text-element [data]="data.pricing"></nova-text-element>


            <div class="link-container">
                <nova-fti-button *ngIf="data.openRoundtripDetailsLayerButtonText" variant="secondary" (click)="openDetailModal('roundtripDetails')">
                    <i class="icon icon-information"></i>
                    <nova-text-element [data]="data.openRoundtripDetailsLayerButtonText"></nova-text-element>
                </nova-fti-button>


                <nova-fti-button *ngIf="data.openInfoLayerButtonText" variant="secondary" (click)="openDetailModal('infos')">
                    <i class="icon icon-information"></i>
                    <nova-text-element [data]="data.openInfoLayerButtonText"></nova-text-element>
                </nova-fti-button>


                <nova-fti-button *ngIf="data.openBookingLayerButtonText" variant="secondary" (click)="openDetailModal('booking')">
                    <i class="icon icon-information"></i>
                    <nova-text-element [data]="data.openBookingLayerButtonText"></nova-text-element>
                </nova-fti-button>


                <button *ngIf="showContactForm; else ibeLink" type="button" class="btn-link btn-primary" (click)="openContactModal()">
                    <nova-text-element [data]="data.bookingRequestButtonText" class="btn-content"></nova-text-element>
                </button>

                <ng-template #ibeLink>
                    <button type="button" class="btn-link btn-primary" (click)="goToUrl(data.bookingRequestUrl)">
                        <nova-text-element [data]="data.bookingRequestButtonText" class="btn-content"></nova-text-element>
                    </button>
                </ng-template>
            </div>
        </div>
    </div>
    <nova-font-loader *ngIf="data.fonts" [fonts]="[data.fonts]"></nova-font-loader>

    <div *ngIf="modalOpen"
         [@fadeAnimation]="'in'"
         class="roundtrip-modal" [ngClass]="modalVariant"
         [style.background-image]="modalVariant !== 'map' && modalVariant !== 'contact' && modalBackgroundUrl"
         >
        <nova-nav-border-item (click)="closeModal()" class="my-close-icon icon-cancel"></nova-nav-border-item>

        <div class="wrapper" *ngIf="modalMode">
            <div class="modal-content {{modalVariant}}">
                <div *ngIf="modalVariant !== 'contact'; else contactform" >
                    <div class="modal-content--header" *ngIf="modalContent.label || modalContent.header">
                        <nova-text-element *ngIf="modalContent.label" [data]="modalContent.label"></nova-text-element>
                        <nova-text-element *ngIf="modalContent.header" [data]="modalContent.header"></nova-text-element>
                    </div>
                    <nova-text-element [data]="modalContent.content"></nova-text-element>
                </div>

                <ng-template #contactform>
                    <nova-contactform [data]="data"></nova-contactform>
                </ng-template>
            </div>

        </div>

        <nova-full-image-slider *ngIf="!modalMode" [images]="data.medias" [fullWidth]="true" [apiImages]="data.images" imageVersion="horizontal-wide" [currentIndex]="slider.currentIndex"></nova-full-image-slider>
    </div>
</div>
