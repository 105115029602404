import {
    AfterViewInit,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    PLATFORM_ID,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { SharedHotelHelperService } from '../shared-hotel-helper.service';
import { fadeAnimation } from '../../travelworld-modern/travelworld-slider/fadeIn.animation';
import { NovaAPIService } from 'app/_services/nova-api.service';

export type ModalType = 'contact' | 'moreInfo' | 'bookingInformation';

@Component({
    selector: 'app-hotel-detail-standard-content',
    templateUrl: './hotel-detail-standard-content.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [fadeAnimation],
    providers: [SharedHotelHelperService]
})
export class HotelDetailStandardContentComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() data;
    @Output() modalOpenChange = new EventEmitter<boolean>();
    @ViewChild('scrollContainer') flexContainer;
    isBrowser: boolean = isPlatformBrowser(this.platformId);
    googleMapsUrl: SafeResourceUrl;
    public showContactForm: boolean;
    public modalOpen = false;
    public modalType: ModalType;
    public teaser: SafeHtml;

    constructor(
        public sanitizer: DomSanitizer,
        @Inject(PLATFORM_ID) private platformId: Object,
        public hotelHelper: SharedHotelHelperService,
        public nova: NovaAPIService
    ) {}

    ngOnInit() {
        this.showContactForm = this.data.ibe === 'false';
        this.data = this.hotelHelper.handleTagInputs(this.data);
        this.googleMapsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.hotelHelper.getMapsUrl(this.data));
        this.teaser = this.sanitizer.bypassSecurityTrustHtml(this.data.text);
    }

    ngAfterViewInit() {
        const moreInfoButton = document.querySelector('#moreInfo');

        if (!moreInfoButton) {
            return;
        }

        moreInfoButton.addEventListener('click', () => this.openModal('moreInfo'));
    }

    ngOnDestroy() {
        const moreInfoButton = document.querySelector('#moreInfo');

        if (!moreInfoButton) {
            return;
        }

        moreInfoButton.removeEventListener('click', () => this.openModal('moreInfo'));
    }

    goToUrl(url: string) {
        this.isBrowser && window.open(url);
    }

    openModal(modalType: ModalType): void {
        const page = this.flexContainer.nativeElement.parentElement.parentElement;
        page.scroll(0, 0);
        page.classList.add('modal-open');

        this.modalOpen = true;
        this.modalType = modalType;

        this.modalOpenChange.emit(this.modalOpen);

        $('body').addClass('nested-modal-open');
    }

    closeModal(event) {
        event.stopImmediatePropagation();

        const page = this.flexContainer.nativeElement.parentElement.parentElement;
        page.classList.remove('modal-open');

        this.modalOpen = false;
        this.modalOpenChange.emit(this.modalOpen);

        $('body').removeClass('nested-modal-open');
    }

    isUrl(media) {
        return typeof media !== 'object' && media.indexOf('http') > -1;
    }

    getImageProxyUrl(url) {
        const size = `s:200:150:1:1`;
        const rt = 'fit';

        return `${this.nova.getImgUrl()}/insecure/rt:${rt}/${size}/g:sm/plain/${url}`;
    }
}
