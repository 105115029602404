import * as tslib_1 from "tslib";
import { OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
var ServerArticleScrollComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ServerArticleScrollComponent, _super);
    function ServerArticleScrollComponent(nova, renderer, uiEventHandler, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.renderer = renderer;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        return _this;
    }
    ServerArticleScrollComponent.prototype.ngOnInit = function () {
        this.addOgTags();
    };
    ServerArticleScrollComponent.prototype.ngAfterViewInit = function () {
        this.renderer.setStyle(this.bgElm.nativeElement, 'background-color', this.getColor(this.data['box_color']));
    };
    ServerArticleScrollComponent = tslib_1.__decorate([
        NovaTemplate('ServerArticleScrollComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            Renderer2,
            UIEventHandlerService,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], ServerArticleScrollComponent);
    return ServerArticleScrollComponent;
}(Page));
export { ServerArticleScrollComponent };
