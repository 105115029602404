<div id="page-{{ id }}" class="page page-easy-scroll">
	<nova-bg [data]="data" *ngIf="!uiEventHandler.isMobile()"></nova-bg>
	<div
		class="bg animated slideInLeft easeOutQuad scrollbar-holder"
		[ngStyle]="{ backgroundColor: getColor(bgColor) }"
	>
		<div class="scroller">
			<nova-mobile-img [data]="data" class="show-for-small-only left"></nova-mobile-img>
			<div class="shift clear">
				<nova-title [data]="data" [class.noBg]="!data.background_media"></nova-title>
				<nova-text-element
					class="con"
					[data]="data.text"
					(openGallery)="gallery.openSlider($event)"
				></nova-text-element>
				<nova-content-links
					class="content-links"
					*ngIf="data.links && data.links.length > 0"
					[floors]="data.links"
				></nova-content-links>
			</div>
		</div>
	</div>
	<nova-text-gallery [medias]="data.textImages" #gallery></nova-text-gallery>
</div>
