<div id="page-{{ id }}" class="page page-portrait top" [ngStyle]="{'backgroundColor':getColor()}">
  <button [hidden]="isRevealed" (click)="toggle(true)" [ngStyle]="setMyStyles(titleStyle)" class="trigger down-arrow floating-arrow flex-item"><i [ngStyle]="setMyStyles(arrowStyle)" class="fa fa-angle-down"></i></button>
  <nova-nav-border-item [hidden]="!isRevealed || isSliderOpen" class="icon-cancel show-for-small-only my-close-icon" (click)="close()"></nova-nav-border-item>
  <div id="container" [ngClass]="{top: !isRevealed, modify: isRevealed}" class="my-container intro-effect-fadeout scrollbar-holder" #container>
    <div class="scroller">
      <header class="header">
        <div class="flexbox">
          <div class="flex-item flex-helper" [ngStyle]="setMyStyles(helperStyle)"></div>
          <div class="flex-item" [ngStyle]="setMyStyles(logoStyle)">
            <h3 *ngIf="data.label" [ngStyle]="{'font-size':label_size}" class="dateline"><nova-element [data]="data" name="label"></nova-element></h3>
            <h1 *ngIf="data.header" [ngStyle]="{'font-size':header_size}"><nova-element [data]="data" name="header"></nova-element></h1>
            <h2 *ngIf="data.subHeader" [ngStyle]="{'font-size':subHeader_size}"><nova-element [data]="data" name="subHeader"></nova-element></h2>
          </div>
        </div>
        <div class="bg-img"><nova-bg forceVideo="true" [data]="data"></nova-bg><div class="after" [ngStyle]="setMyStyles(afterStyle)"></div></div>
      </header>
      <article class="content">
        <div>
          <nova-text-element [data]="data.text" (openGallery)="openSlider($event)"></nova-text-element>
          <nova-content-links class="content-links" *ngIf="data.links && data.links.length > 0" [floors]="data.links"></nova-content-links>
        </div>
      </article>
    </div>
  </div>
  <nova-text-gallery (close)="closeSlider()" [medias]="data.textImages" #gallery></nova-text-gallery>
</div>
