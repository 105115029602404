import { NovaFormComponent } from './nova-form/nova-form.component';
import { NovaContentSidebarComponent } from 'app/_components/nova-content-sidebar/nova-content-sidebar.component';
import { NovaContentLinksComponent } from './nova-content-links/nova-content-links.component';
import { NovaGalleryPopupComponent } from './nova-gallery-popup/nova-gallery-popup.component';
import { NovaTextGalleryComponent } from 'app/_components/nova-text-gallery/nova-text-gallery.component';
import { NovaImpressumComponent } from 'app/_components/nova-impressum/nova-impressum.component';
import { NovaTermsComponent } from 'app/_components/nova-terms/nova-terms.component';
import { SharedModule } from './../shared.module';
import { NgModule } from '@angular/core';
import { NovaFontLoaderComponent } from './nova-font-loader/nova-font-loader.component';
import { NovaLandingSidebarComponent } from './nova-landing-sidebar/nova-landing-sidebar.component';
import { NovaTitleBarComponent } from './nova-title-bar/nova-title-bar.component';
import { NovaNavItemComponent } from './nova-nav-item/nova-nav-item.component';
import { NovaNavBorderItemComponent } from './nova-nav-border-item/nova-nav-border-item.component';
import { NovaMediaComponent } from './nova-media/nova-media.component';
import { NovaBgComponent } from './nova-bg/nova-bg.component';
import { NovaCiStyleComponent } from './nova-ci-style/nova-ci-style.component';
import { NovaCILogoComponent } from './nova-ci-logo/nova-ci-logo.component';
import { NovaTextElementComponent } from 'app/_components/nova-text-element/nova-text-element.component';
import { NovaColumnizerComponent } from 'app/_components/nova-columnizer/nova-columnizer.component';
import { NovaElementComponent } from 'app/_components/nova-element/nova-element.component';
import { NovaMobileImgComponent } from 'app/_components/nova-mobile-img/nova-mobile-img.component';
import { NovaTitleComponent } from 'app/_components/nova-title/nova-title.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NovaContactPopupComponent } from './nova-contact-popup/nova-contact-popup.component';
import { NovaCopyrightComponent } from './nova-copyright/nova-copyright.component';
import { NovaMatrixComponent } from './nova-matrix/nova-matrix.component';
import { NovaNewsletterPopupComponent } from './nova-newsletter-popup/nova-newsletter-popup.component';
import { NovaCookiePopupComponent } from './nova-cookie-popup/nova-cookie-popup.component';
import { NovaLondonTitleBarComponent } from './nova-london-title-bar/nova-london-title-bar.component';
import { NovaLondonOverlayComponent } from './nova-london-overlay/nova-london-overlay.component';
import { NovaLondonOverlayArchiveComponent } from './nova-london-overlay/nova-london-overlay-archive/nova-london-overlay-archive.component';
import { NovaLondonOverlayContactComponent } from './nova-london-overlay/nova-london-overlay-contact/nova-london-overlay-contact.component';
import { NovaLondonOverlayImprintComponent } from './nova-london-overlay/nova-london-overlay-imprint/nova-london-overlay-imprint.component';
import { NovaLondonOverlayPagesComponent } from './nova-london-overlay/nova-london-overlay-pages/nova-london-overlay-pages.component';
import { NovaLondonOverlayPrivacyComponent } from './nova-london-overlay/nova-london-overlay-privacy/nova-london-overlay-privacy.component';
import { NovaLondonOverlaySearchComponent } from './nova-london-overlay/nova-london-overlay-search/nova-london-overlay-search.component';
import { NovaLondonOverlaySocialComponent } from './nova-london-overlay/nova-london-overlay-social/nova-london-overlay-social.component';
import { NovaBudapestTitleBarComponent } from './nova-budapest-title-bar/nova-budapest-title-bar.component';
import { NovaBudapestOverlayComponent } from './nova-budapest-overlay/nova-budapest-overlay.component';
import { NovaBudapestOverlayArchiveComponent } from './nova-budapest-overlay/nova-budapest-overlay-archive/nova-budapest-overlay-archive.component';
import { NovaBudapestOverlayContactComponent } from './nova-budapest-overlay/nova-budapest-overlay-contact/nova-budapest-overlay-contact.component';
import { NovaBudapestOverlayImprintComponent } from './nova-budapest-overlay/nova-budapest-overlay-imprint/nova-budapest-overlay-imprint.component';
import { NovaBudapestOverlayPagesComponent } from './nova-budapest-overlay/nova-budapest-overlay-pages/nova-budapest-overlay-pages.component';
import { NovaBudapestOverlayPrivacyComponent } from './nova-budapest-overlay/nova-budapest-overlay-privacy/nova-budapest-overlay-privacy.component';
import { NovaBudapestOverlaySearchComponent } from './nova-budapest-overlay/nova-budapest-overlay-search/nova-budapest-overlay-search.component';
import { NovaBudapestOverlaySocialComponent } from './nova-budapest-overlay/nova-budapest-overlay-social/nova-budapest-overlay-social.component';
import { NovaFtiTitleBarComponent } from './fti/nova-fti-title-bar/nova-fti-title-bar.component';
import { NovaFtiOverlayComponent } from './fti/nova-fti-overlay/nova-fti-overlay.component';
import { NovaFtiOverlayArchiveComponent } from './fti/nova-fti-overlay/nova-fti-overlay-archive/nova-fti-overlay-archive.component';
import { NovaFtiOverlayContactComponent } from './fti/nova-fti-overlay/nova-fti-overlay-contact/nova-fti-overlay-contact.component';
import { NovaFtiOverlayImprintComponent } from './fti/nova-fti-overlay/nova-fti-overlay-imprint/nova-fti-overlay-imprint.component';
import { NovaFtiOverlayPagesComponent } from './fti/nova-fti-overlay/nova-fti-overlay-pages/nova-fti-overlay-pages.component';
import { NovaFtiOverlayPrivacyComponent } from './fti/nova-fti-overlay/nova-fti-overlay-privacy/nova-fti-overlay-privacy.component';
import { NovaFtiOverlaySocialComponent } from './fti/nova-fti-overlay/nova-fti-overlay-social/nova-fti-overlay-social.component';
import { ImageSliderComponent } from './nova-image-slider/image-slider.component';
import { FullImageSliderComponent } from './nova-full-image-slider/full-image-slider.component';
import { NovaContactFormComponent } from './nova-contactform/nova-contactform.component';
import { NovaContactFormFieldComponent } from './nova-contactform/nova-contactform-field/nova-contactform-field.component';
import {
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MAT_DATE_LOCALE,
    MatNativeDateModule
} from '@angular/material';
import { SafeHtmlPipe } from 'app/_helpers/safe-html-pipe';
import { SafeURLPipe } from 'app/_helpers/safe-url-pipe';
import { RecaptchaModule } from 'angular-google-recaptcha';
import { IndexTransferFilterPipe } from 'app/pages/templates/index-transfer/filter-tags.pipe';
import { NovaFtiAnimatedWorldicon } from './fti/nova-fti-animated-world-icon/nova-fti-animated-world-icon.component';
import { environment } from 'environments/environment';
import { NovaFtiSeasonDataComponent } from './fti/nova-fti-season-data/nova-fti-season-data.component';
import { PagesFilterPipe } from './fti/nova-fti-overlay/pages-filter.pipe';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { NovaElementWithChildrenComponent } from 'app/_components/nova-element-with-children/nova-element-with-children.component';
import { NovaFtiButtonComponent } from './fti/nova-fti-button/nova-fti-button.component';
import { NovaElementSmallComponent } from './nova-element-small/nova-element-small.component';
import { NovaScrollbarHolderComponent } from './nova-scrollbar-holder/nova-scrollbar-holder.component';
import { IframeComponent } from './iframe/iframe.component';
import { IFrameResizerDirective } from 'app/_directives/iframe-resizer.directive';
import { PaidContentDialogComponent } from './paid-content-dialog/paid-content-dialog.component';

@NgModule({
    imports: [
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatNativeDateModule,
        MatSnackBarModule,
        // FTI ONLY
        RecaptchaModule.forRoot({
            siteKey: environment.production
                ? '6LfvSjEUAAAAAPM0NoPvKNjx3EtATvf0fFYdkHYV'
                : '6LdnK8AaAAAAAIU2ZMmvK4DmlcfXmt0l-DXHj0Lr'
        }),
        RouterModule
    ],
    providers: [MatDatepickerModule, { provide: MAT_DATE_LOCALE, useValue: 'de-DE' }],
    exports: [
        NovaFontLoaderComponent,
        NovaLandingSidebarComponent,
        NovaTitleBarComponent,
        NovaNavItemComponent,
        NovaNavBorderItemComponent,
        NovaMediaComponent,
        NovaBgComponent,
        NovaCiStyleComponent,
        NovaCILogoComponent,
        NovaImpressumComponent,
        NovaTermsComponent,
        NovaTextElementComponent,
        NovaTextGalleryComponent,
        NovaGalleryPopupComponent,
        NovaColumnizerComponent,
        NovaContentLinksComponent,
        NovaContentSidebarComponent,
        NovaElementComponent,
        NovaMobileImgComponent,
        NovaTitleComponent,
        NovaFormComponent,
        NovaContactPopupComponent,
        NovaCopyrightComponent,
        NovaMatrixComponent,
        NovaNewsletterPopupComponent,
        NovaCookiePopupComponent,
        NovaLondonTitleBarComponent,
        NovaLondonOverlayComponent,
        NovaLondonOverlayArchiveComponent,
        NovaLondonOverlayContactComponent,
        NovaLondonOverlayImprintComponent,
        NovaLondonOverlayPagesComponent,
        NovaLondonOverlayPrivacyComponent,
        NovaLondonOverlaySearchComponent,
        NovaLondonOverlaySocialComponent,
        NovaBudapestTitleBarComponent,
        NovaBudapestOverlayComponent,
        NovaBudapestOverlayArchiveComponent,
        NovaBudapestOverlayContactComponent,
        NovaBudapestOverlayImprintComponent,
        NovaBudapestOverlayPagesComponent,
        NovaBudapestOverlayPrivacyComponent,
        NovaBudapestOverlaySearchComponent,
        NovaBudapestOverlaySocialComponent,
        NovaFtiTitleBarComponent,
        NovaFtiOverlayComponent,
        NovaFtiOverlayArchiveComponent,
        NovaFtiOverlayContactComponent,
        NovaFtiOverlayImprintComponent,
        NovaFtiOverlayPagesComponent,
        NovaFtiOverlayPrivacyComponent,
        NovaFtiOverlaySocialComponent,
        ImageSliderComponent,
        FullImageSliderComponent,
        NovaContactFormComponent,
        NovaContactFormFieldComponent,
        NovaFtiAnimatedWorldicon,
        NovaFtiSeasonDataComponent,
        NovaElementWithChildrenComponent,
        NovaFtiButtonComponent,
        NovaElementSmallComponent,
        NovaScrollbarHolderComponent,
        IframeComponent,
        PaidContentDialogComponent
    ],
    declarations: [
        IndexTransferFilterPipe,
        PagesFilterPipe,
        NovaMediaComponent,
        NovaBgComponent,
        NovaFontLoaderComponent,
        NovaLandingSidebarComponent,
        NovaTitleBarComponent,
        NovaNavItemComponent,
        NovaNavBorderItemComponent,
        NovaCiStyleComponent,
        NovaCILogoComponent,
        NovaImpressumComponent,
        NovaTermsComponent,
        NovaTextElementComponent,
        NovaTextGalleryComponent,
        NovaGalleryPopupComponent,
        NovaColumnizerComponent,
        NovaContentLinksComponent,
        NovaContentSidebarComponent,
        NovaElementComponent,
        NovaMobileImgComponent,
        NovaTitleComponent,
        NovaFormComponent,
        NovaContactPopupComponent,
        NovaCopyrightComponent,
        NovaMatrixComponent,
        NovaNewsletterPopupComponent,
        NovaCookiePopupComponent,
        NovaLondonTitleBarComponent,
        NovaLondonOverlayComponent,
        NovaLondonOverlayArchiveComponent,
        NovaLondonOverlayContactComponent,
        NovaLondonOverlayImprintComponent,
        NovaLondonOverlayPagesComponent,
        NovaLondonOverlayPrivacyComponent,
        NovaLondonOverlaySearchComponent,
        NovaLondonOverlaySocialComponent,
        NovaBudapestTitleBarComponent,
        NovaBudapestOverlayComponent,
        NovaBudapestOverlayArchiveComponent,
        NovaBudapestOverlayContactComponent,
        NovaBudapestOverlayImprintComponent,
        NovaBudapestOverlayPagesComponent,
        NovaBudapestOverlayPrivacyComponent,
        NovaBudapestOverlaySearchComponent,
        NovaBudapestOverlaySocialComponent,
        NovaFtiTitleBarComponent,
        NovaFtiOverlayComponent,
        NovaFtiOverlayArchiveComponent,
        NovaFtiOverlayContactComponent,
        NovaFtiOverlayImprintComponent,
        NovaFtiOverlayPagesComponent,
        NovaFtiOverlayPrivacyComponent,
        NovaFtiOverlaySocialComponent,
        ImageSliderComponent,
        FullImageSliderComponent,
        NovaContactFormComponent,
        NovaContactFormFieldComponent,
        SafeHtmlPipe,
        SafeURLPipe,
        NovaFtiAnimatedWorldicon,
        NovaFtiSeasonDataComponent,
        NovaElementWithChildrenComponent,
        NovaFtiButtonComponent,
        NovaElementSmallComponent,
        NovaScrollbarHolderComponent,
        IframeComponent,
        IFrameResizerDirective,
        PaidContentDialogComponent
    ]
})
export class ComponentsModule {}
