import { Injectable } from '@angular/core';
import { SSRService } from './ssr.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PresentationService {
    enabled = false;
    nextPage$ = new BehaviorSubject(null);

    private timeout;
    public switchTime;

    constructor(public ssr: SSRService) {}

    load(timer) {
        this.switchTime = timer * 1000;
        if (this.ssr.isBrowser && this.ssr.getParameterByName('presentation') === 'true') {
            this.init();
            $(document).on('click.presentation', 'body', e => {
                this.enabled = false;
                $('.scrollbar-holder').stop(true, false);
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.init();
                }, 30000);
            });
        }
    }

    init() {
        this.enabled = true;
        this.timeout = setTimeout(() => {
            let holder = $('.scrollbar-holder');
            const page = $('.page');
            if (holder.length === 0) {
                holder = page;
            }
            holder = $(holder[0]);
            let height = holder.find('.scroller').height() || 0;

            if (page.hasClass('page-article-scroll')) {
                height += 100;
            } else if (page.hasClass('page-article-teaser')) {
                holder = $('.page-article-teaser .text');
                height = holder.find('.con').height();
            }

            const windowHeight = holder.height();
            let time = (height / windowHeight) * 5000;
            if (windowHeight >= height) {
                time = 0;
            }
            holder.animate({ scrollTop: height - windowHeight }, time, 'linear', () => {
                if (this.enabled) {
                    time = this.switchTime - time;
                    if (time < 0) {
                        time = 1000;
                    }
                    this.timeout = setTimeout(() => {
                        this.nextPage$.next(null);
                        this.init();
                    }, time);
                }
            });
        }, 3000);
    }
}
