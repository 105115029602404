import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { NovaAPIService } from './nova-api.service';
import { UIEventHandlerService } from './uievent-handler.service';
import jwtDecode from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class StripeService {
    public stripeAccount: string;
    public publicationId: string;
    public isAuthenticated: boolean;

    public currentCustomer = {
        email: null,
        isAuthenticated: false
    };

    constructor(private nova: NovaAPIService, private ui: UIEventHandlerService) {}

    public getAuthTokenFromUrl(): string {
        return new URLSearchParams(window.location.search.substring(1)).get('authToken');
    }

    public getAuthTokenFromLocalStorage(): string {
        return localStorage.getItem('authToken');
    }

    public setAuthTokenToLocalStorage(authToken: string): void {
        localStorage.setItem('authToken', authToken);
    }

    public removeAuthTokenFromLocalStorage() {
        localStorage.removeItem('authToken');
    }

    public async checkAuthentication(magazine, currentIssue) {
        this.stripeAccount = magazine.stripeAccount;
        this.publicationId = currentIssue._id;

        const authToken = this.getAuthTokenFromUrl() || this.getAuthTokenFromLocalStorage();
        const params = new URLSearchParams(window.location.search.substring(1));
        const sessionId = params.get('sessionId');

        if (!authToken && !sessionId) {
            this.ui.showPaidContentDialog = true;

            return;
        }

        if (sessionId) {
            const res = await fetch(`${environment.apiUrl}v1/stripe/checkout-success`, {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({
                    session_id: sessionId,
                    stripe_account: this.stripeAccount
                })
            });

            const result = await res.json();
            this.removeParameterFromUrl('sessionId');

            if ('authToken' in result) {
                this.setAuthTokenToLocalStorage(result.authToken);

                this.currentCustomer = {
                    email: result.email,
                    isAuthenticated: true
                };

                this.ui.showPaidContentDialog = false;

                return;
            }

            this.ui.showPaidContentDialog = true;

            return;
        }

        const isAuthenticated: boolean = await this.authenticateCustomer(authToken);

        this.removeParameterFromUrl(['authToken', 'sessionId']);

        this.ui.showPaidContentDialog = isAuthenticated ? false : true;
    }

    public async authenticateCustomer(authToken: string): Promise<boolean> {
        const { email }: { email: string } = jwtDecode(authToken);

        const res = await fetch(`${environment.apiUrl}v1/stripe/authentication`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`
            }),
            body: JSON.stringify({
                stripe_account: this.stripeAccount,
                publication_id: this.publicationId
            })
        });

        const result = await res.json();

        if (result.code === 200) {
            if ('authToken' in result) {
                window.localStorage.setItem('authToken', result.authToken);
            }

            this.currentCustomer = {
                email: email,
                isAuthenticated: true
            };

            return true;
        } else {
            return false;
        }
    }

    public logout() {
        this.removeAuthTokenFromLocalStorage();
        this.removeParameterFromUrl(['authToken', 'sessionId']);
        this.ui.showPaidContentDialog = true;
    }

    public removeParameterFromUrl(parameter: string | string[]): void {
        const url = new URL(location.href);

        if (Array.isArray(parameter)) {
            for (const param of parameter) {
                url.searchParams.delete(param);
            }
        } else {
            url.searchParams.delete(parameter);
        }

        window.history.pushState('', document.title, url.toString());
    }
}
