<div id="page-{{ id }}">
	<nova-scrollbar-holder>
	    <div class="scroller">
		<!-- Header -->
			<div class="grt__grow-fullsize-header">
				<div class="grt__grow-fullsize-header-video">
					<nova-media [bg]="true" [mediaId]="data.headervideo" [forceVideo]="true"></nova-media>
					<div *ngIf="data.headline" class="grt__grow-fullsize-header-headline">
						<nova-text-element class="con" [data]="data.headline" [myid]="data._id" ></nova-text-element>
					</div>
					<div *ngIf="data.logo" class="grt__grow-fullsize-header-logo">
						<img *ngIf="data.headerlogo && isUrl(data.headerlogo)" [src]="getImageProxyUrl(data.headerlogo)">
						<nova-media *ngIf="data.headerlogo && !isUrl(data.headerlogo)" [mediaId]="data.headerlogo" version="small" [bg]="false" [fallback]="true"></nova-media>
					</div>
					<div class="scroll-down" (click)="scrollDown()" [ngStyle]="{'backgroundColor':getColor(data['scrollbackground'], true)}">
						<div [ngStyle]="{'backgroundColor':getColor(data['scrollcolor'], true)}"></div>
						<div [ngStyle]="{'backgroundColor':getColor(data['scrollcolor'], true)}"></div>
					</div>
				</div>
			</div>
			<!-- Header:END -->
			<div class="grt__grow-spacing"></div>
			
			
			<div *ngFor="let row of data.templateorder.split(',')">
				
				<div *ngIf="row == 'overflow'">
					<div class="grt__grow-container">
						<div class="grt__grow-row grt__grow-align-items-center">
							<div class="grt__grow-col">
								<nova-text-element class="con" [data]="data.text" [myid]="data._id" ></nova-text-element>
							</div>
						</div>
					</div>
					<div class="grt__grow-spacing"></div>
					<div class="grt__grow-container-fluid">
						<div class="grt__grow-row">
							<div class="grt__grow-overflow-background">
								<img *ngIf="data.bgvideo && isUrl(data.bgvideo)" [src]="getImageProxyUrl(data.bgvideo)">
								<nova-media *ngIf="data.bgvideo && !isUrl(data.bgvideo)" [mediaId]="data.bgvideo" version="small" [bg]="false" [fallback]="true"></nova-media>
							</div>
							
							<div *ngIf="data.overfloworientation == 'left'" class="grt__grow-container grt__grow-overflow grt__grow-overflow-left">
								<div class="grt__grow-row grt__grow-align-items-center">
									<div class="grt__grow-col">
										<img *ngIf="data.overflowimage && isUrl(data.overflowimage)" [src]="getImageProxyUrl(data.overflowimage)">
										<nova-media *ngIf="data.overflowimage && !isUrl(data.overflowimage)" [mediaId]="data.overflowimage" version="small" [bg]="false" [fallback]="true"></nova-media>
									</div>
									<div class="grt__grow-col">
										<nova-text-element class="con" [data]="data.overflowtext" [myid]="data._id" ></nova-text-element>
									</div>
								</div>
								<div class="grt__grow-overflow-stoerer">
									<img *ngIf="data.stoerer && isUrl(data.stoerer)" [src]="getImageProxyUrl(data.stoerer)">
									<nova-media *ngIf="data.stoerer && !isUrl(data.stoerer)" [mediaId]="data.stoerer" version="small" [bg]="false" [fallback]="true"></nova-media>
								</div>
							</div>
							<div *ngIf="data.overfloworientation == 'right'" class="grt__grow-container grt__grow-overflow grt__grow-overflow-right">
								<div class="grt__grow-row grt__grow-align-items-center">
									<div class="grt__grow-col">
										<nova-text-element class="con" [data]="data.overflowtext" [myid]="data._id" ></nova-text-element>
									</div>
									<div class="grt__grow-col">
										<img *ngIf="data.overflowimage && isUrl(data.overflowimage)" [src]="getImageProxyUrl(data.overflowimage)">
										<nova-media *ngIf="data.overflowimage && !isUrl(data.overflowimage)" [mediaId]="data.overflowimage" version="small" [bg]="false" [fallback]="true"></nova-media>
									</div>
								</div>
								<div class="grt__grow-overflow-stoerer">
									<img *ngIf="data.stoerer && isUrl(data.stoerer)" [src]="getImageProxyUrl(data.stoerer)">
									<nova-media *ngIf="data.stoerer && !isUrl(data.stoerer)" [mediaId]="data.stoerer" version="small" [bg]="false" [fallback]="true"></nova-media>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div *ngIf="row == 'column'">
					<div *ngIf="data.columnorientation == 'left'" class="grt__grow-container">
						<div class="grt__grow-row grt__grow-align-items-center">
							<div class="grt__grow-col">
								<nova-text-element class="con" [data]="data.columntext" [myid]="data._id" ></nova-text-element>
							</div>
							<div class="grt__grow-col">
								<img *ngIf="data.columnimage && isUrl(data.columnimage)" [src]="getImageProxyUrl(data.columnimage)">
								<nova-media *ngIf="data.columnimage && !isUrl(data.columnimage)" [mediaId]="data.columnimage" version="small" [bg]="false" [fallback]="true"></nova-media>
							</div>
						</div>
					</div>
					<div *ngIf="data.columnorientation == 'right'" class="grt__grow-container">
						<div class="grt__grow-row grt__grow-align-items-center">
							<div class="grt__grow-col">
								<img *ngIf="data.columnimage && isUrl(data.columnimage)" [src]="getImageProxyUrl(data.columnimage)">
								<nova-media *ngIf="data.columnimage && !isUrl(data.columnimage)" [mediaId]="data.columnimage" version="small" [bg]="false" [fallback]="true"></nova-media>
							</div>
							<div class="grt__grow-col">
								<nova-text-element class="con" [data]="data.columntext" [myid]="data._id" ></nova-text-element>
							</div>
						</div>
					</div>
				</div>
				
				<div *ngIf="row == 'fullsize'">
					<div class="grt__grow-container">
						<div class="grt__grow-fullsize-bg">
							<div class="grt__grow-fullsize-bg-img">
								<img *ngIf="data.fullsizebg && isUrl(data.fullsizebg)" [src]="getImageProxyUrl(data.fullsizebg)">
								<nova-media *ngIf="data.fullsizebg && !isUrl(data.fullsizebg)" [mediaId]="data.fullsizebg" version="small" [bg]="false" [fallback]="true"></nova-media>
							</div>
							<div class="grt__grow-fullsize-bg-stoerer">
								<img *ngIf="data.fullsizestoerer && isUrl(data.fullsizestoerer)" [src]="getImageProxyUrl(data.fullsizestoerer)">
								<nova-media *ngIf="data.fullsizestoerer && !isUrl(data.fullsizestoerer)" [mediaId]="data.fullsizestoerer" version="small" [bg]="false" [fallback]="true"></nova-media>
							</div>
						</div>
						
						<div class="grt__grow-row grt__grow-align-items-center">
							<div class="grt__grow-col">
								<nova-text-element class="con" [data]="data.fullsizetext" [myid]="data._id" ></nova-text-element>
							</div>
						</div>
					</div>
				</div>
				
				
				<div class="grt__grow-spacing"></div>
			</div>
			

		</div>
	</nova-scrollbar-holder>
</div>
