<div id="page-{{ id }}" class="page page-background-across-right">
    <nova-bg *ngIf="!uiEventHandler.isMobile() && !uiEventHandler.isTablet()" [data]="data"></nova-bg>
    <nova-mobile-img [data]="data" class="show-for-small-only left">
        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 200 200" class="mobile-tri">
          <polygon points="0,200 200,0 200,200" [attr.fill]="getColor(data['box_color'])" />
        </svg>
    </nova-mobile-img>
    <div class="bg" [hidden]="!data.text && !data.header && !data.subHeader && !data.label">
      <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 200 200" class="tri">
        <polygon points="0,200 200,0 200,200" [attr.fill]="getColor(data['box_color'])" />
      </svg>
      <div [style.background-color]="getColor(data['box_color'])" class="bg-color"></div>
      <nova-scrollbar-holder>
        <div class="scroller">
          <nova-title [data]="data"></nova-title>
          <nova-text-element class="con" [data]="data.text" [myid]="data._id" (openGallery)="gallery.openSlider($event)"></nova-text-element>
          <nova-content-links class="content-links" *ngIf="data.links && data.links.length > 0" [floors]="data.links"></nova-content-links>
        </div>
      </nova-scrollbar-holder>
      <div class="fader" [style.background]="getGradient()"></div>
    </div>
    <nova-text-gallery #gallery [medias]="data.textImages" [myid]="data._id"></nova-text-gallery>
  </div>
