<div class="nova-cookie-popup-container" [hidden]="!uiEventHandler.showCookiePopup">
	<div class="nova-cookie-popup">
		<div>
			<h2>{{ 'COOKIE_POPUP_HEADER' | translate }}</h2>
			<p class="cookie-text" [innerHTML]="'COOKIE_POPUP_TEXT' | translate"></p>

			<div *ngIf="showSelections" class="selections">
				<div>
					<input type="checkbox" name="necessary" id="ButtonNecessary" disabled [checked]="categories.necessary">
					<label for="ButtonNecessary">{{'Notwendig' | translate }}</label>
				</div>
				<div>
					<input type="checkbox" name="preferences" id="ButtonPreferences" (change)="toggle($event)"  [checked]="categories.preferences">
					<label for="ButtonPreferences">{{'Präferenzen' | translate }}</label>
				</div>
				<div>
					<input type="checkbox" name="statistics" id="ButtonStatistics" (change)="toggle($event)"  [checked]="categories.statistics">
					<label for="ButtonStatistics">{{'Statistiken' | translate }}</label>
				</div>
				<div>
					<input type="checkbox" name="marketing" id="ButtonMarketing" (change)="toggle($event)"  [checked]="categories.marketing">
					<label for="ButtonMarketing">{{'Marketing' | translate }}</label>
				</div>
			</div>

			<div class="actions">
				<button *ngIf="!showSelections" type="button" class="btn btn-selection" (click)="showSelectionMenu()">{{'COOKIE_POPUP_MORE' | translate }}</button>
				<button type="button" class="btn btn-deny" (click)="denyAllAndClose()">{{ 'COOKIE_POPUP_DENY' | translate }}</button>
				<button type="button" class="btn btn-all" (click)="acceptAllAndClose()">{{ 'COOKIE_POPUP_ALLOW' | translate }}</button>
				<button *ngIf="showSelections" type="button" class="btn btn-selection" (click)="acceptAndClose()">{{'COOKIE_POPUP_ACCEPT' | translate }}</button>
			</div>
		</div>
</div>
