import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { ActivatedRoute, Router } from '@angular/router';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Floor } from '../../floor';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { Meta, Title } from '@angular/platform-browser';
import { NovaGalleryPopupComponent } from '../../../_components/nova-gallery-popup/nova-gallery-popup.component';
import { filter } from 'rxjs/operators';

@Component({
    templateUrl: './image-gallery.component.html',
    styleUrls: ['./image-gallery.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageImageGalleryComponent')
export class PageImageGalleryComponent extends Floor implements OnInit {
    public data: any;
    public id: number;
    public pageId: number;

    @ViewChild('gallery') public gallery: NovaGalleryPopupComponent;

    constructor(
        route: ActivatedRoute,
        router: Router,
        uiEventHandler: UIEventHandlerService,
        nova: NovaAPIService,
        public meta: Meta,
        public title: Title
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    ngOnInit() {
        this.uiEventHandler.hideSwipeOverlay();
        this.route.queryParams.pipe(filter(params => params.img)).subscribe(params => {
            setTimeout(() => {
                let i = 0;
                let index = 0;
                this.data.medias.forEach(entry => {
                    if (params.img === entry.media) {
                        index = i;
                    }
                    i++;
                });
                this.gallery.goTo(index);
            }, 1000);
        });
    }
}
