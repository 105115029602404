import { Pipe, PipeTransform } from '@angular/core';
import { MultiSelectItem } from './MultiSelectItem';
import { Content } from 'app/novamag-api/Types';

@Pipe({
    name: 'indexTransferFilter',
    pure: false
})
export class IndexTransferFilterPipe implements PipeTransform {
    transform(
        contents: Content[],
        selectedInterests: MultiSelectItem[],
        selectedStartingPoints: MultiSelectItem[],
        selectedDestinationPoints: MultiSelectItem[]
    ): Content[] | null {
        if (!contents) {
            return contents;
        }

        let filterInterests = (content: Content) => {
            return content;
        };

        if (selectedInterests && selectedInterests.length) {
            filterInterests = content => {
                return selectedInterests
                    .map(item => item.id)
                    .some(fil => content.interests && content.interests.indexOf(fil) > -1)
                    ? content
                    : null;
            };
        }

        let filterStartingPoints = (content: Content) => {
            return content;
        };

        if (selectedStartingPoints && selectedStartingPoints.length) {
            filterStartingPoints = (content: Content) => {
                return selectedStartingPoints
                    .map(item => item.id)
                    .some(fil => content.startingpoint && content.startingpoint.indexOf(fil) > -1)
                    ? content
                    : null;
            };
        }

        let filterDestinationPoints = (content: Content) => {
            return content;
        };

        if (selectedDestinationPoints && selectedDestinationPoints.length) {
            filterDestinationPoints = content => {
                return selectedDestinationPoints
                    .map(item => item.id)
                    .some(fil => content.destinationpoint && content.destinationpoint.indexOf(fil) > -1)
                    ? content
                    : null;
            };
        }

        return contents.filter(
            content => filterInterests(content) && filterStartingPoints(content) && filterDestinationPoints(content)
        );
    }
}
