<div id="page-{{ id }}" class="page page-multiple-bgs">
  <div class="owl-carousel owl-loaded-desktop"
    [ngClass]="{'owl-loaded': !uiEventHandler.isMobile(), 'owl-loaded-desktop': !uiEventHandler.isMobile()}">
    <div *ngFor="let entry of data.medias;let key = index;let last = last;"
      class="slide slide-{{key}}" attr.data-slidenumber="{{key}}"
      [ngStyle]="{'backgroundColor':getColor(entry['box_color_bottom'])}" [hidden]="!isActive(key)"
      (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
      <div class="clearfix pos-rel">
        <nova-media [mediaId]="entry.media" class="animated fadeIn" [version]="getVersion()" [bg]="true"
          *ngIf="isActive(key)" [backgroundPosition]="(entry && entry.media_position && entry.media_position.split('-').join(' ')) || 'center center'"></nova-media>
      </div>
      <div class="bg animated fadeIn scrollbar-holder"
        [ngStyle]="setMyStyles(getScrollbarStyle(entry['box_color_bottom']))">
        <div class="scroller">
          <div class="clearfix pos-rel show-for-small-only">
            <nova-mobile-img [mediaId]="entry.media" class="left"></nova-mobile-img>
            <span (click)="slideLeft()" [hidden]="key == 0" [ngStyle]="{'backgroundColor':getColor(entry['label_bgColor']), 'color':getColor(entry['label_color'])}"><i class="icon-arrow-left"></i></span>
            <span (click)="slideRight()" [hidden]="last" [ngStyle]="{'backgroundColor':getColor(entry['label_bgColor']), 'color':getColor(entry['label_color'])}"><i class="icon-arrow-right"></i></span>
          </div>
          <div class="clearfix api-title show-for-small-only mobile-api-title">
            <h3 *ngIf="entry.label" class="dateline" [ngStyle]="{'backgroundColor':getColor(entry['box_color_top'])}">
              <nova-element [data]="entry" name="label"></nova-element>
            </h3>
          </div>
          <div class="clearfix">
            <div class="api-title">
              <h3 *ngIf="entry.label" class="dateline hide-for-small-only"
                [ngStyle]="{'backgroundColor':getColor(entry['box_color_top'])}">
                <nova-element [data]="entry" name="label"></nova-element>
              </h3>
              <div class="clearfix title-holder">
                <div>
                  <h1 *ngIf="entry.header" [ngStyle]="{'backgroundColor':getColor(entry['box_color_top'])}">
                    <nova-element [data]="entry" name="header"></nova-element>
                  </h1>
                  <h2 *ngIf="entry.subHeader" [ngStyle]="{'backgroundColor':getColor(entry['box_color_top'])}">
                    <nova-element [data]="entry" name="subHeader"></nova-element>
                  </h2>
                </div>
                <div class="controls hide-for-small-only" *ngIf="numSlides > 1">
                  <span
                    [ngStyle]="{'backgroundColor':getColor(entry['label_bgColor']), 'color':getColor(entry['label_color'])}"
                    (click)="goUp()"
                    [hidden]="key == 0">
                    <i class="fa fa-chevron-up"></i>
                  </span>
                  <span [ngStyle]="{'font-size':'9px', 'backgroundColor':getColor(entry['label_bgColor']), 'color':getColor(entry['label_color'])}"
                    class="num">{{ activeSlide+1 }} / {{ numSlides }}</span>
                  <span
                    [ngStyle]="{'backgroundColor':getColor(entry['label_bgColor']), 'color':getColor(entry['label_color'])}"
                    [hidden]="last"
                    (click)="goDown()">
                    <i class="fa fa-chevron-down"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="text-content" [ngStyle]="{'backgroundColor':getColor(entry['box_color_bottom'])}">
              <nova-text-element class="con" [data]="entry.text" (openGallery)="gallery.openSlider($event)">
              </nova-text-element>
            </div>
          </div>
        </div>
      </div>
      <nova-text-gallery [medias]="entry.textImages" #gallery></nova-text-gallery>
    </div>
  </div>
</div>
