import {
    AfterContentInit,
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    Input,
    OnInit,
    PLATFORM_ID,
    Renderer2,
    ViewChild,
    QueryList,
    ViewChildren
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NovaAPIService } from '../../../../_services/nova-api.service';

interface Price {
    sortorder: string;
    title: string;
    info: string;
    text: string;
    ref: string;
    value: string;
}

@Component({
    selector: 'app-trip-pricing',
    templateUrl: './trip-pricing.component.html',
    styleUrls: ['./trip-pricing.component.scss']
})
export class TripPricingComponent implements OnInit, AfterViewInit, AfterContentInit {
    @Input() public data;
    @Input() public name: string;
    @Input() public font_name: string;
    @Input() public title_font_name: string;
    @Input() public text_font_name: string;
    @Input() public ignoreMobile = false;

    public font: string;
    prices: Price[];
    maxItem: number;
    isBrowser: boolean = isPlatformBrowser(this.platformId);

    @ViewChild('elm') private elm: ElementRef;
    @ViewChildren('title') private titleElements: QueryList<ElementRef>;
    @ViewChildren('text') private textElements: QueryList<ElementRef>;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private novaAPI: NovaAPIService,
        private renderer: Renderer2
    ) {}

    ngOnInit() {
        this.prices = this.data[this.name];

        if (this.prices) {
            this.maxItem = Math.max.apply(Math, this.prices.map(price => this.ftiValueToNumber(price.value)));
        }
    }

    ngAfterViewInit() {
        this.isBrowser && this.handleAnimations();
    }

    ngAfterContentInit() {
        if (this.data[this.font_name]) {
            this.font = this.data[this.font_name];
            this.novaAPI.getFont(this.font).subscribe(fontData => {
                this.renderer.setStyle(this.elm.nativeElement, 'font-family', fontData.name);
            });
        }
        if (this.data[this.title_font_name]) {
            this.font = this.data[this.title_font_name];
            this.novaAPI.getFont(this.font).subscribe(fontData => {
                this.titleElements &&
                    this.titleElements.toArray().map(element => {
                        this.renderer.setStyle(element.nativeElement, 'font-family', fontData.name);
                    });
            });
        }
        if (this.data[this.text_font_name]) {
            this.font = this.data[this.text_font_name];
            this.novaAPI.getFont(this.font).subscribe(fontData => {
                this.titleElements &&
                    this.textElements.toArray().map(element => {
                        this.renderer.setStyle(element.nativeElement, 'font-family', fontData.name);
                    });
            });
        }
    }

    getBarHeight(item: Price) {
        const barHeight = (100 / this.maxItem) * this.ftiValueToNumber(item.value);

        return this.toPercent(barHeight);
    }

    toPercent(num: number): string {
        return num + '%';
    }

    ftiValueToNumber(value: string): number {
        return +value.replace('.', '');
    }

    openLink(item: Price) {
        window.open(item.ref, '_blank');
    }

    private handleAnimations() {
        const ELEMENTS = document.querySelectorAll('.HOVER');
        const ELEMENTS_SPAN = [];
        const MOBILE_SCROLLER = $('.page');
        const SCROLLER = $('.do-scroll');
        const VSCROLLER = $('.price-container');

        const getListener = (index: number, element: HTMLElement) => {
            return (e: MouseEvent) => {
                const scrollTop =
                    this.ignoreMobile || window.innerWidth > 991 ? SCROLLER.scrollTop() : MOBILE_SCROLLER.scrollTop();
                const scrollLeft = VSCROLLER.scrollLeft();
                ELEMENTS_SPAN[index].style.left = e.pageX - (element.offsetLeft - scrollLeft) + 'px';
                ELEMENTS_SPAN[index].style.top = e.pageY - (element.offsetTop - scrollTop) + 'px';
            };
        };

        Array.from(ELEMENTS).forEach((element: HTMLElement, index) => {
            // If The span element for this element does not exist in the array, add it.
            if (!ELEMENTS_SPAN[index]) {
                ELEMENTS_SPAN[index] = element.querySelector('span');
            }

            element.addEventListener('mouseover', getListener(index, element));
            element.addEventListener('mouseout', getListener(index, element));
        });
    }
}
