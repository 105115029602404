import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { NovaAPIService } from 'app/_services/nova-api.service';

@Component({
    selector: 'nova-fti-season-data',
    templateUrl: './nova-fti-season-data.component.html',
    styleUrls: ['./nova-fti-season-data.component.scss']
})
export class NovaFtiSeasonDataComponent implements OnInit {
    @Input() data;

    public isSliderFullWidth = false;
    public fontRegular = '';

    public minDate = new Date();
    public moreInfoForm = {
        formGroup: new FormGroup({ date: new FormControl(new Date()) }),
        formFields: {
            dateField: {
                id: 'date',
                type: 'date',
                disabled: false
            }
        },
        currentDate: new Date().toLocaleDateString(),
        isFetching: false
    };
    public fetchedData = {};

    constructor(private http: HttpClient, public ui: UIEventHandlerService, public nova: NovaAPIService) {}

    ngOnInit() {
        this.fontRegular = `'${this.data.text_fonts || this.data.fonts[1]}'`;
        this.fetchData(this.minDate);
        this.moreInfoForm.formGroup.valueChanges.subscribe(formValues => this.fetchData(formValues.date));
    }

    fetchData(date) {
        new Promise((resolve, reject) => {
            const dateArr = new Date(date).toLocaleDateString('de-De').split('.');
            dateArr[0] = '01';
            const formattedDate = dateArr.reverse().join('-');
            this.moreInfoForm.currentDate = formattedDate;
            this.moreInfoForm.isFetching = true;

            const apiRequestUrl =
                this.nova.getApiUrl() +
                'v1/proxy/fti/' +
                encodeURIComponent(
                    'https://b2bapi.fti-group.com/api/supplementarydata/getproductbycondition?productType=hotel'
                );
            const tourOperator = 'FTI';
            const lang = 'de';

            this.http
                .get(
                    `${apiRequestUrl}&touroperator=${tourOperator}&language=${lang}&date_string=${formattedDate}&cmiid=${
                        this.data.cmiid
                    }`
                )
                .subscribe((result: any) => resolve(result));
        })
            .then((result: any) => {
                this.fetchedData[this.moreInfoForm.currentDate] = result && result.data[0];
                this.moreInfoForm.isFetching = false;
            })
            .catch(err => console.error(err));
    }
}
