/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./landing-page.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../_components/nova-nav-item/nova-nav-item.component.ngfactory";
import * as i3 from "../_components/nova-nav-item/nova-nav-item.component";
import * as i4 from "../_services/nova-api.service";
import * as i5 from "../_services/magazine.service";
import * as i6 from "../_services/uievent-handler.service";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@angular/common";
import * as i9 from "./landing-page.component";
import * as i10 from "../_services/media.service";
import * as i11 from "@angular/router";
var styles_LandingPageComponent = [i0.styles];
var RenderType_LandingPageComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LandingPageComponent, data: {} });
export { RenderType_LandingPageComponent as RenderType_LandingPageComponent };
function View_LandingPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "nova-nav-item", [["class", "logo"]], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onMouseEnter($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseLeave($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onTouchStart($event.target) !== false);
        ad = (pd_2 && ad);
    } if (("touchend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onTouchEnd($event.target) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_NovaNavItemComponent_0, i2.RenderType_NovaNavItemComponent)), i1.ɵdid(1, 245760, null, 0, i3.NovaNavItemComponent, [i4.NovaAPIService, i5.MagazineService, i6.UIEventHandlerService, i7.DomSanitizer, i1.ElementRef, i1.Renderer2], { hoverable: [0, "hoverable"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "img", [["alt", "Logo"]], [[8, "src", 4]], null, null, null, null))], function (_ck, _v) { var currVal_0 = false; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.logo || "assets/img/novamag.svg"); _ck(_v, 2, 0, currVal_1); }); }
function View_LandingPageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "nova-nav-item", [["class", "title title-landing"]], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onMouseEnter($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onMouseLeave($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).onTouchStart($event.target) !== false);
        ad = (pd_2 && ad);
    } if (("touchend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3).onTouchEnd($event.target) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_NovaNavItemComponent_0, i2.RenderType_NovaNavItemComponent)), i1.ɵdid(1, 278528, null, 0, i8.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "font-family": 0 }), i1.ɵdid(3, 245760, null, 0, i3.NovaNavItemComponent, [i4.NovaAPIService, i5.MagazineService, i6.UIEventHandlerService, i7.DomSanitizer, i1.ElementRef, i1.Renderer2], { hoverable: [0, "hoverable"] }, null), (_l()(), i1.ɵted(4, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.font); _ck(_v, 1, 0, currVal_0); var currVal_1 = false; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.magazine.label; _ck(_v, 4, 0, currVal_2); }); }
function View_LandingPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "title-bar"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i8.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { nobg: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LandingPageComponent_2)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LandingPageComponent_3)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "after"]], null, null, null, null, null)), i1.ɵdid(8, 278528, null, 0, i8.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(9, { "border-top-color": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "title-bar"; var currVal_1 = _ck(_v, 2, 0, (_co.magazine.labelHasBackground == "no")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = ((_co.magazine.showLogoOrLabel == "both") || (_co.magazine.showLogoOrLabel == "logo")); _ck(_v, 4, 0, currVal_2); var currVal_3 = ((_co.magazine.showLogoOrLabel == "both") || ((_co.magazine.showLogoOrLabel == "label") && _co.magazine.label)); _ck(_v, 6, 0, currVal_3); var currVal_4 = _ck(_v, 9, 0, _co.ui.getColor(_co.magazine.navigation.backgroundColor)); _ck(_v, 8, 0, currVal_4); }, null); }
function View_LandingPageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_LandingPageComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "fuchs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "assets/img/error-issues.png"]], null, null, null, null, null))], null, null); }
export function View_LandingPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { templateContainer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "landing"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LandingPageComponent_1)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, [[1, 3], ["dynamicItemView", 2]], null, 0, null, View_LandingPageComponent_4)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LandingPageComponent_5)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.magazine && (_co.magazine.showLogoOrLabel != "none")); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.issues && (_co.issues.length == 0)); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_LandingPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-landing-page", [], null, null, null, View_LandingPageComponent_0, RenderType_LandingPageComponent)), i1.ɵdid(1, 245760, null, 0, i9.LandingPageComponent, [i4.NovaAPIService, i6.UIEventHandlerService, i1.ComponentFactoryResolver, i5.MagazineService, i10.MediaService, i11.Router, i7.Meta, i7.Title, i7.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LandingPageComponentNgFactory = i1.ɵccf("app-landing-page", i9.LandingPageComponent, View_LandingPageComponent_Host_0, {}, {}, []);
export { LandingPageComponentNgFactory as LandingPageComponentNgFactory };
