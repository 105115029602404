<div id="page-{{ id }}">
	<nova-scrollbar-holder>
	    <div class="scroller">
		<!-- Header -->
		<div class="grt__pandora-fullsize-header">
			<div class="grt__pandora-fullsize-header-video">
				<nova-media [bg]="true" [mediaId]="data.headervideo" [forceVideo]="true"></nova-media>

				<div *ngIf="data.headerlogo" class="grt__pandora-fullsize-header-video-logo">
					<img *ngIf="data.headerlogo && isUrl(data.headerlogo)" [src]="getImageProxyUrl(data.headerlogo)">
					<nova-media *ngIf="data.headerlogo && !isUrl(data.headerlogo)" [mediaId]="data.headerlogo" version="small" [bg]="false" [fallback]="true"></nova-media>
				</div>

				<div class="scroll-down" (click)="scrollDown()"></div>
			</div>
		</div>

		<div class="grt__pandora-spacing"></div>

		<!-- Intro -->
		<div class="grt__pandora-container">
			<div class="grt__pandora-row grt__pandora-align-items-center">
				<div class="grt__pandora-col">
					<p><nova-text-element class="con" [data]="data.text" [myid]="data._id" ></nova-text-element></p>
					<a href="{{ data.button1link }}" class="grt__pandora-button">{{ data.button1text }}</a>
				</div>
				<div class="grt__pandora-col">
					<div class="grt__pandora-images-overlap">
						<div class="grt__pandora-images-overlap-image1">
							<img *ngIf="data.image1 && isUrl(data.image1)" [src]="getImageProxyUrl(data.image1)">
							<nova-media *ngIf="data.image1 && !isUrl(data.image1)" [mediaId]="data.image1" version="small" [bg]="false" [fallback]="true"></nova-media>
						</div>
						<div class="grt__pandora-images-overlap-image2">
							<img *ngIf="data.image2 && isUrl(data.image2)" [src]="getImageProxyUrl(data.image2)">
							<nova-media *ngIf="data.image2 && !isUrl(data.image2)" [mediaId]="data.image2" version="small" [bg]="false" [fallback]="true"></nova-media>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="grt__pandora-spacing"></div>

		<!-- Content -->
		<div class="grt__pandora-container">
			<div class="grt__pandora-row grt__pandora-align-items-center">
				<div class="grt__pandora-col">

					<div class="grt__pandora-media">
						<div class="grt__pandora-media-content">
							<nova-media [bg]="true" [forceVideo]="true" [mediaId]="data.video1"></nova-media>
						</div>
					</div>
				</div>
				<div class="grt__pandora-col">
					<p><nova-text-element class="con" [data]="data.text2" [myid]="data._id" ></nova-text-element></p>
				</div>
			</div>
		</div>

		<div class="grt__pandora-spacing"></div>

		<!-- Trenner -->
		<div class="grt__pandora-container">
			<div class="grt__pandora-fullsize-image">
				<img *ngIf="data.image3 && isUrl(data.image3)" [src]="getImageProxyUrl(data.image3)">
				<nova-media *ngIf="data.image3 && !isUrl(data.image3)" [mediaId]="data.image3" version="small" [bg]="false" [fallback]="true"></nova-media>
				<div class="grt__pandora-fullsize-image-image">
					<img *ngIf="data.image4 && isUrl(data.image4)" [src]="getImageProxyUrl(data.image4)">
					<nova-media *ngIf="data.image4 && !isUrl(data.image4)" [mediaId]="data.image4" version="small" [bg]="false" [fallback]="true"></nova-media>
				</div>
			</div>
			<p class="text-center"><nova-text-element class="con" [data]="data.text3" [myid]="data._id" ></nova-text-element></p>
		</div>

		<div class="grt__pandora-spacing"></div>

		<!-- Produkte -->
		<div class="grt__pandora-container">
			<div class="grt__pandora-katalog grt__pandora-row grt__pandora-katalog-background">

				<div *ngFor="let product of data.medias" class="grt__pandora-katalog-item">
					<a href="{{ product.link }}" target="_blank">
						<div class="grt__pandora-katalog-item-img">
							<img *ngIf="product.media && isUrl(product.media)" [src]="getImageProxyUrl(product.media)">
							<nova-media *ngIf="product.media && !isUrl(product.media)" [mediaId]="product.media" version="small" [bg]="false" [fallback]="true"></nova-media>
						</div>
						<div class="grt__pandora-katalog-item-content">
							<div class="grt__pandora-katalog-item-tag">
								<nova-element [data]="product" name="tag"></nova-element>
							</div>
							<div class="grt__pandora-katalog-item-name">
								<nova-element [data]="product" name="name"></nova-element>
							</div>
							<div class="grt__pandora-katalog-item-price">
								<nova-element [data]="product" name="price"></nova-element>
							</div>
						</div>
					</a>
				</div>

			</div>
		</div>

		<div class="grt__pandora-spacing"></div>

		</div>
	</nova-scrollbar-holder>
</div>
