import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NetworkService } from './network.service';
import { map, shareReplay, mergeMap } from 'rxjs/operators';

@Injectable()
export class MediaService {
    private mediaCache: Observable<any>[] = [];
    private oldVersion = '';
    public requestedUrls = [];
    private mediaArr: string[];

    constructor(private network: NetworkService) {}

    public getMedia(mediaId: string): Observable<any> {
        if (this.mediaCache[mediaId]) return this.mediaCache[mediaId];

        this.mediaCache[mediaId] = this.network.fetch('media/' + mediaId).pipe(
            map(data => {
                if (data['status'] === 'success') {
                    return data['media'];
                }

                console.log(`Could not find media id: ${mediaId}\nStatus: `, data['status']);
            }),
            shareReplay(1)
        );

        return this.mediaCache[mediaId];
    }

    public getFallbackUrl(mediaId: string, type?: string): Observable<any> {
        type = type || this.getMediaVersion();

        return this.getMedia(mediaId).pipe(
            mergeMap(data => {
                if (this.isVideo(data)) {
                    let found = '';
                    if (data && data.versions) {
                        data.versions.forEach(arr => {
                            if (arr.name === 'screenshot') {
                                found = arr;
                            }
                        });
                        if (found) {
                            return of(this.getMediaUrlByData(found, type));
                        }
                    }
                }

                return of(this.getMediaUrlByData(data, type));
            })
        );
    }

    public getMediaUrl(mediaId: string, type?: string) {
        return this.getMedia(mediaId).pipe(
            map(mediaData => {
                return this.getMediaUrlByData(mediaData, type);
            })
        );
    }

    public getMediaUrlByData(mediaData, type?: string) {
        type = type || this.getMediaVersion();

        if (!mediaData) return;

        if (
            type === 'source' ||
            (mediaData.contentType &&
                (mediaData.contentType === 'image/svg+xml' || mediaData.contentType === 'image/gif'))
        ) {
            this.requestedUrls.push(mediaData.url);

            return mediaData.url;
        }
        if (type === 'thumb') {
            if (mediaData.thumb) {
                this.requestedUrls.push(mediaData.thumb.url);

                return mediaData.thumb.url;
            }
            if (mediaData.thumbnail) {
                this.requestedUrls.push(mediaData.thumbnail.url);

                return mediaData.thumbnail.url;
            }
        }
        if (mediaData['contentType'] === 'application/pdf') {
            return mediaData.url;
        }

        if (this.isYoutube(mediaData)) {
            this.requestedUrls.push(mediaData.thumbnail);

            return mediaData.thumbnail;
        } else if (this.isVimeo(mediaData)) {
            // replace thumbnail whith hd image
            const thumbnail = mediaData.thumbnail && mediaData.thumbnail.replace('640x360', '1920x1080');
            this.requestedUrls.push(thumbnail);

            return thumbnail;
        } else if (this.isVideo(mediaData)) {
            type = 'screenshot';
        }

        let url = '';
        let fallback = '';
        if (!mediaData.versions) {
            return '';
        }
        mediaData.versions.forEach(version => {
            if (version.name === type) {
                url = this.getRenderedImg(version);
            }
            if (url === '' && fallback === '' && version.name !== 'embed' && version.name !== 'background') {
                fallback = this.getRenderedImg(version);
            }
        });

        this.requestedUrls.push(url || fallback);

        return url || fallback;
    }

    public getRequestedUrls() {
        return this.requestedUrls.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });
    }

    public getVideo(mediaData, bg: boolean, org?: boolean) {
        const arr = [];

        if (mediaData.contentType && mediaData.contentType.startsWith('video/')) {
            if (mediaData.type && mediaData.type === 'bunny') {
                arr.push(mediaData.url);
            }

            if (mediaData.contentType === 'video/youtube') {
                if (org) {
                    arr.push({
                        src: `https://www.youtube.com/watch?v=${mediaData.externId}`
                    });
                } else {
                    arr.push({
                        src: `https://www.youtube.com/embed/${mediaData.externId}?modestbranding=0&showinfo=0`
                    });
                }
            } else if (mediaData.contentType === 'video/vimeo') {
                if (org) {
                    arr.push({
                        type: 'vimeo',
                        src: `https://vimeo.com/${mediaData.externId}`
                    });
                } else {
                    const vimeoPlayerBase = 'https://player.vimeo.com/video/';
                    arr.push({
                        type: 'vimeo',
                        src: mediaData.externId.startsWith(vimeoPlayerBase)
                            ? mediaData.externId
                            : `${vimeoPlayerBase}${mediaData.externId}`
                    });
                }
            } else {
                let mp4 = false;
                mediaData.versions &&
                    mediaData.versions.forEach(v => {
                        if (bg === true) {
                            if (v.name === 'background' && !mp4) {
                                arr.push({
                                    src: v.url,
                                    type: 'video/mp4'
                                });
                                mp4 = true;
                            }
                        } else {
                            if (v.name === 'embed' && !mp4) {
                                arr.push({
                                    src: v.url,
                                    type: 'video/mp4'
                                });
                                mp4 = true;
                            }
                        }
                    });
            }
        }

        return arr;
    }

    public getRenderedImg(version) {
        if (!version.url) {
            return '';
        }

        if (version.size && version.size > 0) {
            return version.url;
        } else {
            return 'assets/img/placeholder/' + version.name + '.png';
        }
    }

    private resolveEntry(key, val) {
        // get media
        if (val.pdf_version) {
            this.mediaArr.push(val.pdf_version);
        }
        if (val.background_media) {
            this.mediaArr.push(val.background_media);
        }
        if (val.medias) {
            val.medias.forEach(mVal => {
                this.mediaArr.push(mVal.media);
            });
        }
        if (val.media) {
            this.mediaArr.push(val.media);
        }
        if (val.video) {
            this.mediaArr.push(val.video);
        }
    }

    public resolveMedia(pages) {
        this.mediaArr = [];

        pages.forEach((val, key) => {
            this.resolveEntry(key, val);
            if (val.contents && val.contents.length > 0) {
                val.contents.forEach((sVal, sKey) => {
                    this.resolveEntry(sKey, sVal);
                });
            }
        });

        return [this.mediaArr];
    }

    public loadMedia(media: string[], ignoreErrors: boolean = false): Promise<any[]> {
        let i = 0;
        const length = media && media.length ? media.length : 0;
        let returned = false;
        const arr = [];

        return new Promise((resolve, _reject) => {
            if (length === 0) {
                resolve([]);
            }

            media.forEach((val, index) => {
                let src = val;

                if (typeof val === 'object') {
                    src = val['media'];
                }

                if (src) {
                    this.getMedia(src).subscribe(
                        data => {
                            data['org'] = val;
                            arr[index] = { ...data };
                            i++;
                            if (i >= length && !returned) {
                                returned = true;
                                resolve(arr);
                            }
                        },
                        err => {
                            if (ignoreErrors) {
                                arr[index] = {
                                    org: val
                                };
                            }
                            i++;
                            if (i >= length && !returned) {
                                returned = true;
                                resolve(arr);
                            }
                            console.error(err);
                        }
                    );
                } else {
                    if (ignoreErrors) {
                        arr[index] = {
                            org: val
                        };
                    }
                    i++;
                    if (i >= length && !returned) {
                        returned = true;
                        resolve(arr);
                    }
                }
            });
        });
    }

    public isVideo(mediaData) {
        return mediaData && mediaData.contentType && mediaData.contentType.startsWith('video/');
    }

    public isYoutube(mediaData) {
        return mediaData && mediaData.contentType === 'video/youtube';
    }

    public isVimeo(mediaData) {
        return mediaData && mediaData.contentType === 'video/vimeo';
    }

    public isLocalVideo(mediaData) {
        return this.isVideo(mediaData) && !this.isYoutube(mediaData) && !this.isVimeo(mediaData);
    }

    public isMobile() {
        if (typeof window === 'undefined') {
            return false;
        }

        return window.innerWidth <= 767;
    }

    public getMediaVersion() {
        let aspectRatio;
        if (typeof window === 'undefined') {
            aspectRatio = 1920 / 1080;
        } else {
            aspectRatio = window.innerWidth / window.innerHeight;
        }
        let version = 'horizontal';

        if (this.isMobile() && aspectRatio < 0.75) {
            if (aspectRatio > 0.6) {
                version = 'vertical';
            } else {
                version = 'vertical-long';
            }
        } else {
            if (aspectRatio > 2.1) {
                // landscape wide
                version = 'horizontal-wide';
            }
            if (aspectRatio > 1 && aspectRatio <= 2.1) {
                // landscape
                version = 'horizontal';
            }
            if (aspectRatio > 0.56 && aspectRatio <= 1) {
                // portait
                version = 'vertical-wide';
            }
            if (aspectRatio <= 0.56) {
                // portrait hoch
                version = 'vertical';
            }
        }

        if (version !== this.oldVersion) {
            // console.info('Change media size to ' + version);
            this.oldVersion = version;
        }

        return version;
    }

    public getImageProxyUrl(url, version?, gravity?) {
        let width;
        let height;
        const aspect = '1:1';
        const rt = 'auto';

        version = version || this.getMediaVersion();

        gravity = gravity || 'sm';

        switch (version) {
            case 'horizontal-wide':
                width = 1920;
                height = 1080;
                break;
            case 'horizontal':
                width = 1920;
                height = 1200;
                break;
            case 'vertical':
                width = 1080;
                height = 1600;
                break;
            case 'vertical-wide':
                width = 1440;
                height = 1920;
                break;
            case 'vertical-long':
            case 'gallery':
                width = 1089;
                height = 1920;
                break;
            case 'article':
                width = 1280;
                height = 720;
                break;
            default:
                width = 1920;
                height = 1200;
                break;
        }

        const size = `s:${width}:${height}:${aspect}`;

        return `${this.network.getIMGUrl()}/insecure/rt:${rt}/${size}/g:${gravity}/plain/${url}`;
    }
}
