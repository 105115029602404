import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { NovaTemplate } from 'app/_decorators/template.decorator';
import { Floor } from 'app/pages/floor';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { MagazineService } from 'app/_services/magazine.service';

@Component({
    templateUrl: './article-classic.component.html',
    styleUrls: ['./article-classic.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageArticleClassicComponent')
export class PageArticleClassicComponent extends Floor implements OnInit, OnDestroy {
    @ViewChild('textElm') public textElm: ElementRef;

    public column: boolean;
    public spinnerColor;

    private sub: Subscription;

    constructor(
        nova: NovaAPIService,
        public mag: MagazineService,
        uiEventHandler: UIEventHandlerService,
        route: ActivatedRoute,
        router: Router,
        public meta: Meta,
        public title: Title
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
        this.sub = this.mag.getMagazine().subscribe(data => {
            this.spinnerColor = this.getColor(data.navigation.textColor);
        });
    }

    ngOnInit() {
        if (this.uiEventHandler.isMobile()) {
            this.uiEventHandler.column = 'single';
        } else {
            if (!this.uiEventHandler.columnChanged) {
                this.uiEventHandler.column = !(this.data['default_view'] === 'block') ? 'columns' : 'single';
            }
        }

        if (this.uiEventHandler.column !== 'single' && this.uiEventHandler.columnizeEnabled) {
            this.uiEventHandler.textLoading = true;
        }

        // IE9, Chrome, Safari, Opera
        fromEvent(this.textElm.nativeElement, 'mousewheel').subscribe(event => {
            this.scrollHorizontally(event);
        });

        // Firefox
        fromEvent(this.textElm.nativeElement, 'DOMMouseScroll').subscribe(event => {
            this.scrollHorizontally(event);
        });
    }

    scrollHorizontally(e) {
        if (this.uiEventHandler.column !== 'single') {
            const delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));
            this.textElm.nativeElement.scrollLeft -= delta * 40; // Multiplied by 40
            e.preventDefault();
        }
    }

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe();
    }

    getFontSize() {
        return this.uiEventHandler.fontSize + 'em';
    }
}
