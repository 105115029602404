<div class="share">
    <a class="social-button facebook" (click)="share('facebook')">
      <i class="icon-facebook"></i><span>{{ 'SHARE' | translate }}</span>
    </a><br />
    <a class="social-button twitter" (click)="share('twitter')">
      <i class="icon-twitter"></i><span>{{ 'SHARE' | translate }}</span>
    </a><br />
    <a class="social-button xing" (click)="share('xing')">
      <i class="icon-xing"></i><span>{{ 'SHARE' | translate }}</span>
    </a><br />
    <a class="social-button linkedin" (click)="share('linkedin')">
      <i class="icon-linkedin"></i><span>{{ 'SHARE' | translate }}</span>
    </a><br />
    <a class="social-button mail" (click)="share('mail')">
        <i class="icon-mail-o"></i><span>{{ 'SHARE' | translate }}</span>
    </a>
  </div>