<div #scrollContainer class="flex-container">
    <div class="flex-item image-slider">
        <nova-image-slider [images]="data.blocks" [apiImages]="data.images"></nova-image-slider>
    </div>

    <div class="flex-item do-scroll">
        <div class="padded-content">
            <div class="logo">
                <img *ngIf="data.logo_media && isUrl(data.logo_media)" [src]="getImageProxyUrl(data.logo_media)">
                <nova-media *ngIf="data.logo_media && !isUrl(data.logo_media)"
                            [mediaId]="data.logo_media"
                            version="small"
                            [bg]="false"
                            [fallback]="true"></nova-media>
            </div>
            <div class="hotel-name">
                <nova-title [data]="data" style="margin-bottom: 0; margin-right: 10px"></nova-title>
            </div>
            <div *ngIf="data.country_labels" class="tags">
                <nova-element
                    *ngFor="let label of data.country_labels; let i = index;"
                    [data]="data"
                    content_name="{{'country_labels_' + i}}"
                    name="country_labels"
                ></nova-element>
            </div>
            <div *ngIf="data.hotel_labels" class="tags hotel-tags">
                <nova-element
                    *ngFor="let label of data.hotel_labels; let i = index;"
                    [data]="data"
                    content_name="{{'hotel_labels_' + i}}"
                    name="hotel_labels"
                ></nova-element>
            </div>
            <div class="hotel-text" *ngIf="data.fulltext" style="margin-top: 30px">
                <div [innerHtml]="teaser"></div>
            </div>

            <div class="hotel-pricing"></div>

            <nova-fti-button *ngIf="data.bookinginformationtitle" variant="secondary" (click)="openModal('bookingInformation')">
                <i class="icon icon-information"></i>
                <nova-text-element [data]="data.bookinginformationtitle" class="btn-content"></nova-text-element>
            </nova-fti-button>

            <button *ngIf="data.call_to_action_link && !showContactForm; else openContactFormButton" type="button" class="btn-link btn-primary"
                (click)="goToUrl(data.call_to_action_link)">
                <nova-text-element *ngIf="data.cta[1]" [data]="data.cta[1]" class="btn-content"></nova-text-element>
                <nova-element *ngIf="data.call_to_action_button && !data.cta[1]" [data]="data" name="call_to_action_button"></nova-element>
            </button>

            <ng-template #openContactFormButton>
                <button type="button" class="btn-link btn-primary"
                    (click)="openModal('contact')">
                    <nova-text-element *ngIf="data.cta" [data]="data.cta"></nova-text-element>
                </button>
            </ng-template>

        </div>



    </div>
    <nova-font-loader *ngIf="data.price_text_font" [fonts]="[data.price_text_font]"></nova-font-loader>
    <nova-font-loader *ngIf="data.price_title_font" [fonts]="[data.price_title_font]"></nova-font-loader>

    <div *ngIf="modalOpen"
        [@fadeAnimation]="'in'"
        class="modal contact"
        >
        <nova-nav-border-item (click)="closeModal($event)" class="my-close-icon icon-cancel"></nova-nav-border-item>

        <div class="wrapper">
            <div class="modal-content contact">
                <div *ngIf="modalType === 'contact'">
                    <nova-contactform [data]="data"></nova-contactform>
                </div>

                <div *ngIf="modalType === 'moreInfo'">
                    <div class="modal-content__container">
                        <nova-fti-season-data [data]="data"></nova-fti-season-data>
                    </div>
                </div>

                <div *ngIf="modalType === 'bookingInformation'">
                    <div class="modal-content__container">
                        <div [innerHTML]="data.bookinginformation"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
