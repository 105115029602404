import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { TranslateService } from '@ngx-translate/core';
import { MediaService } from 'app/_services/media.service';

import { Router, NavigationEnd } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
    selector: 'nova-london-overlay',
    templateUrl: './nova-london-overlay.component.html',
    styleUrls: ['./nova-london-overlay.component.scss']
})
export class NovaLondonOverlayComponent implements OnInit {
    visible = false;
    @Input() active = 'pages';
    font: string;
    selectStartPage: boolean;
    currentPageIndex: number;
    currentPage: any;

    @Input() magazine;
    @Input() issue;
    @Input() languages: any[];
    @Output() toggleOverlay = new EventEmitter<boolean | undefined>();
    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public translate: TranslateService,
        private router: Router,
        public media: MediaService
    ) {}

    ngOnInit() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.getCurrentPage();
            }
        });

        this.nova.getFont(this.magazine.navigation.font).subscribe(fontData => {
            this.font = fontData.name;
        });

        this.selectStartPage = this.magazine.selectStartPage === 'archiv';

        this.nova
            .getCurrentIssue()
            .pipe(take(1))
            .subscribe(issue => {
                if (this.issue === undefined) {
                    this.issue = issue;
                }

                this.getCurrentPage();
            });
    }

    getCurrentPage() {
        const params = this.router.routerState.root.firstChild.params['value'];
        const pageHandle = params.pageHandle;
        if (pageHandle) {
            this.nova.pages.forEach((page, index) => {
                if (page.handle === pageHandle) {
                    this.currentPageIndex = index;
                    this.currentPage = page;
                }
            });
        }
    }

    get borderColor() {
        return this.uiEventHandler.getColor(this.magazine.navigation.borderColor);
    }

    hide() {
        this.toggleOverlay.emit();
    }

    open(page) {
        this.active = page;
    }

    showPDF() {
        return this.currentPage && !!this.currentPage['pdf_version'];
    }

    showShare() {
        return this.nova.issue && this.nova.issue.showSocial === 'yes';
    }

    showPrint() {
        return !this.showPDF() && this.issue.showPrint === 'yes';
    }

    print() {
        this.nova.print();
    }

    openCookieLayer() {
        if (this.magazine && this.magazine.cookieConsent && this.magazine.cookieConsent.type === 'usercentrics') {
            // @ts-ignore
            return window.UC_UI && window.UC_UI.showSecondLayer();
        }

        this.uiEventHandler.showCookiePopup = true;
    }

    switchLang(lang) {
        const params = this.router.routerState.root.firstChild.params['value'];
        const oldLang = this.nova.issue.language;
        if (oldLang === lang) return;
        this.nova.lang = lang;

        this.translate.use(lang).subscribe({
            error: () => this.translate.use('en') // Fallback to english if translate loader fails
        });

        this.nova.mapIssue();

        this.translate.get('CONTENT_TABLE').subscribe((res: string) => {
            this.uiEventHandler.CONTENT_TABLE = res;
        });

        let newDomain;
        // check if a domain for this language exists
        if (this.magazine.domains) {
            const currentDomain = this.nova.getMagazineUrl();
            if (!currentDomain.includes(this.nova.getAliasUrl()) && !window.location.origin.includes('localhost')) {
                const getDomainShouldBe = (lang, type) =>
                    this.magazine.domains.find(domain => domain.language === lang && domain.type === type);

                const domainShouldBe =
                    getDomainShouldBe(lang, 'external') ||
                    getDomainShouldBe('all', 'external') ||
                    getDomainShouldBe(lang, 'internal') ||
                    getDomainShouldBe('all', 'internal');

                if (domainShouldBe && domainShouldBe.domain !== currentDomain) {
                    newDomain = domainShouldBe.domain;
                }
            }
        }

        if (params['issueHandle']) {
            let issueHandle;
            this.nova.issues.forEach(value => {
                if (
                    !issueHandle &&
                    value['languagesMap'][oldLang] &&
                    value['languagesMap'][oldLang].handle === params['issueHandle']
                ) {
                    if (value['languagesMap'][lang]) {
                        issueHandle = value['languagesMap'][lang].handle;
                    }
                }
            });
            if (!issueHandle) {
                issueHandle = params['issueHandle'];
            }

            if (!newDomain) {
                window.location.href = `${window.location.origin}/${lang}/${issueHandle}${window.location.search}`;
            } else {
                window.location.href = `//${newDomain}/${lang}/${issueHandle}${window.location.search}`;
            }
        } else {
            if (!newDomain) {
                this.router.navigate(['/', lang], { queryParamsHandling: 'preserve' });
            } else {
                window.location.href = `//${newDomain}/${lang}${window.location.search}`;
            }
        }
        setTimeout(() => {
            this.hide();
        }, 0);
    }
}
