/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../nova-fti-overlay-pages/nova-fti-overlay-pages.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../nova-media/nova-media.component.ngfactory";
import * as i3 from "../../../nova-media/nova-media.component";
import * as i4 from "../../../../_services/nova-api.service";
import * as i5 from "../../../../_services/media.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../../../_services/uievent-handler.service";
import * as i8 from "@angular/common";
import * as i9 from "./nova-fti-overlay-archive.component";
import * as i10 from "../../../../_services/magazine.service";
import * as i11 from "@angular/router";
var styles_NovaFtiOverlayArchiveComponent = [i0.styles];
var RenderType_NovaFtiOverlayArchiveComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NovaFtiOverlayArchiveComponent, data: {} });
export { RenderType_NovaFtiOverlayArchiveComponent as RenderType_NovaFtiOverlayArchiveComponent };
function View_NovaFtiOverlayArchiveComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-media", [], [[2, "nofullscreen", null]], null, null, i2.View_NovaMediaComponent_0, i2.RenderType_NovaMediaComponent)), i1.ɵdid(1, 770048, null, 0, i3.NovaMediaComponent, [i4.NovaAPIService, i5.MediaService, i1.Renderer2, i6.DomSanitizer, i7.UIEventHandlerService], { bg: [0, "bg"], version: [1, "version"], fallback: [2, "fallback"], mediaId: [3, "mediaId"] }, null)], function (_ck, _v) { var currVal_1 = false; var currVal_2 = "horizontal"; var currVal_3 = true; var currVal_4 = (_v.parent.parent.context.$implicit.archiveBackground || _v.parent.parent.context.$implicit.background_media); _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).noFullscreen; _ck(_v, 0, 0, currVal_0); }); }
function View_NovaFtiOverlayArchiveComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = "assets/img/templates/pages/cover-classic-logo.svg"; _ck(_v, 0, 0, currVal_0); }); }
function View_NovaFtiOverlayArchiveComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openIssue(_co.getLangIssue(_v.parent.context.$implicit).handle) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "overlay"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaFtiOverlayArchiveComponent_3)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaFtiOverlayArchiveComponent_4)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 5, "span", [], null, null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i8.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(8, { "fontFamily": 0 }), (_l()(), i1.ɵeld(9, 0, null, null, 1, "b", [], [[4, "background-color", null]], null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵted(11, null, [" ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_v.parent.context.$implicit.archiveBackground || _v.parent.context.$implicit.background_media); _ck(_v, 3, 0, currVal_1); var currVal_2 = (!_v.parent.context.$implicit.archiveBackground && !_v.parent.context.$implicit.background_media); _ck(_v, 5, 0, currVal_2); var currVal_3 = _ck(_v, 8, 0, _co.font); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentIssue == _co.getLangIssue(_v.parent.context.$implicit).handle); _ck(_v, 0, 0, currVal_0); var currVal_4 = _co.isActive((_co.currentIssue == _co.getLangIssue(_v.parent.context.$implicit).handle)); _ck(_v, 9, 0, currVal_4); var currVal_5 = (_v.parent.context.index + 1); _ck(_v, 10, 0, currVal_5); var currVal_6 = (_co.getLangIssue(_v.parent.context.$implicit).number || _co.getLangIssue(_v.parent.context.$implicit).name); _ck(_v, 11, 0, currVal_6); }); }
function View_NovaFtiOverlayArchiveComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "item"], ["tabIndex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaFtiOverlayArchiveComponent_2)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.languagesMap[_co.lang]; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NovaFtiOverlayArchiveComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaFtiOverlayArchiveComponent_1)), i1.ɵdid(2, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nova.issues; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NovaFtiOverlayArchiveComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-fti-overlay-archive", [], null, null, null, View_NovaFtiOverlayArchiveComponent_0, RenderType_NovaFtiOverlayArchiveComponent)), i1.ɵdid(1, 114688, null, 0, i9.NovaFtiOverlayArchiveComponent, [i4.NovaAPIService, i10.MagazineService, i11.Router, i7.UIEventHandlerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NovaFtiOverlayArchiveComponentNgFactory = i1.ɵccf("nova-fti-overlay-archive", i9.NovaFtiOverlayArchiveComponent, View_NovaFtiOverlayArchiveComponent_Host_0, { lang: "lang", font: "font", borderColor: "borderColor" }, { issueChange: "issueChange" }, []);
export { NovaFtiOverlayArchiveComponentNgFactory as NovaFtiOverlayArchiveComponentNgFactory };
