import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    templateUrl: './fti-travelquiz.component.html',
    styleUrls: ['./fti-travelquiz.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageFtiTravelquizComponent')
export class PageFtiTravelquizComponent extends Page implements OnInit {
    public data: any;

    public formStatus: any;
    public showSuccess: boolean;
    public showMsg: boolean;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public router: Router,
        public route: ActivatedRoute
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
        this.route.queryParams.subscribe(params => {
            this.formStatus = params['result'];
        });
        this.showMsg = false;
    }

    ngOnInit() {
        if (typeof window !== 'undefined') {
            $('#travelquiz').append(`<script src="https://46548.hc-apps.de/embed/script/46548"></script>`);
        }
    }
}
