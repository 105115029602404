import * as tslib_1 from "tslib";
import { OnInit, AfterViewInit } from '@angular/core';
import { NovaTemplate } from 'app/_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { MagazineService } from 'app/_services/magazine.service';
import { SSRService } from 'app/_services/ssr.service';
var PagePandoraPage1Component = /** @class */ (function (_super) {
    tslib_1.__extends(PagePandoraPage1Component, _super);
    function PagePandoraPage1Component(nova, uiEventHandler, meta, title, router, route, magazineService, ssr) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.title = title;
        _this.router = router;
        _this.route = route;
        _this.magazineService = magazineService;
        _this.ssr = ssr;
        _this.isRevealed = false;
        _this.isAnimating = false;
        _this.route.queryParams.subscribe(function (params) {
            _this.formStatus = params['result'];
        });
        _this.showMsg = false;
        _this.magazineService.getMagazine().subscribe(function (magazine) {
            if (!_this.ssr.isBrowser || !magazine.navigation) {
                return;
            }
            _this.navigationStyles = magazine.navigation;
        });
        return _this;
    }
    PagePandoraPage1Component.prototype.ngOnInit = function () {
        console.log(this.data);
    };
    PagePandoraPage1Component.prototype.ngAfterViewInit = function () {
        this.initOwlCarousels();
    };
    PagePandoraPage1Component.prototype.isUrl = function (media) {
        return typeof media !== 'object' && media.indexOf('http') > -1;
    };
    PagePandoraPage1Component.prototype.scrollDown = function () {
        var viewPortHeight = $(window).innerHeight();
        $('.scrollbar-holder')
            .stop()
            .animate({ scrollTop: viewPortHeight }, 500, 'swing', function () { });
    };
    PagePandoraPage1Component.prototype.initOwlCarousels = function () {
        // TODO: remove this timeout and find a better solution
        // for example init after all images are loaded
        setTimeout(function () {
            $('.owl-carousel').owlCarousel({
                autoplay: true,
                loop: true,
                // rewind: true,
                autoplayTimeout: 5000,
                dots: true,
                responsive: {
                    0: {
                        items: 2,
                        slideBy: 2,
                        dotsEach: 2
                    },
                    900: {
                        items: 4,
                        slideBy: 4,
                        dotsEach: 4
                    }
                }
            });
        }, 1000);
    };
    PagePandoraPage1Component.prototype.getImageProxyUrl = function (url) {
        var size = "s:200:150:1:1";
        var rt = 'fit';
        return this.nova.getImgUrl() + "/insecure/rt:" + rt + "/" + size + "/g:sm/plain/" + url;
    };
    PagePandoraPage1Component = tslib_1.__decorate([
        NovaTemplate('PagePandoraPage1Component'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            Meta,
            Title,
            Router,
            ActivatedRoute,
            MagazineService,
            SSRService])
    ], PagePandoraPage1Component);
    return PagePandoraPage1Component;
}(Page));
export { PagePandoraPage1Component };
