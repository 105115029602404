/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-slider.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../nova-media/nova-media.component.ngfactory";
import * as i3 from "../nova-media/nova-media.component";
import * as i4 from "../../_services/nova-api.service";
import * as i5 from "../../_services/media.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../_services/uievent-handler.service";
import * as i8 from "@angular/common";
import * as i9 from "../nova-element/nova-element.component.ngfactory";
import * as i10 from "../nova-element/nova-element.component";
import * as i11 from "./image-slider.component";
var styles_ImageSliderComponent = [i0.styles];
var RenderType_ImageSliderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageSliderComponent, data: {} });
export { RenderType_ImageSliderComponent as RenderType_ImageSliderComponent };
function View_ImageSliderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [], null, null, null, null, null))], null, null); }
function View_ImageSliderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-media", [["class", "background-image"], ["version", "vertical-wide"]], [[2, "nofullscreen", null]], null, null, i2.View_NovaMediaComponent_0, i2.RenderType_NovaMediaComponent)), i1.ɵdid(1, 770048, null, 0, i3.NovaMediaComponent, [i4.NovaAPIService, i5.MediaService, i1.Renderer2, i6.DomSanitizer, i7.UIEventHandlerService], { bg: [0, "bg"], version: [1, "version"], mediaId: [2, "mediaId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = true; var currVal_2 = "vertical-wide"; var currVal_3 = _co.images[0].slider_media; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).noFullscreen; _ck(_v, 0, 0, currVal_0); }); }
function View_ImageSliderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "background-image api-background"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i8.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "background-image": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (("url(" + _co.getImageProxyUrl(_co.getImages()[0].filename)) + ")")); _ck(_v, 1, 0, currVal_0); }, null); }
function View_ImageSliderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "backdrop"]], null, null, null, null, null))], null, null); }
function View_ImageSliderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ImageSliderComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getImageProxyUrl(_v.parent.context.$implicit.filename, (!_co.fullWidth ? "slider" : "horizontal")); var currVal_1 = _v.parent.context.$implicit.description1; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ImageSliderComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-media", [["version", "!fullWidth ? horizontal-wide : horizontal"]], [[2, "nofullscreen", null]], null, null, i2.View_NovaMediaComponent_0, i2.RenderType_NovaMediaComponent)), i1.ɵdid(1, 770048, null, 0, i3.NovaMediaComponent, [i4.NovaAPIService, i5.MediaService, i1.Renderer2, i6.DomSanitizer, i7.UIEventHandlerService], { version: [0, "version"], mediaId: [1, "mediaId"] }, null)], function (_ck, _v) { var currVal_1 = "!fullWidth ? horizontal-wide : horizontal"; var currVal_2 = _v.parent.context.$implicit.slider_media; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).noFullscreen; _ck(_v, 0, 0, currVal_0); }); }
function View_ImageSliderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "slide"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageSliderComponent_7)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageSliderComponent_8)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.apiImageMode; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.apiImageMode; _ck(_v, 4, 0, currVal_1); }, null); }
function View_ImageSliderComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "api-caption"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentImage.description1; _ck(_v, 1, 0, currVal_0); }); }
function View_ImageSliderComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-element", [["name", "caption"]], null, null, null, i9.View_NovaElementComponent_0, i9.RenderType_NovaElementComponent)), i1.ɵdid(1, 1097728, null, 0, i10.NovaElementComponent, [i1.Renderer2, i4.NovaAPIService, i1.ElementRef, i7.UIEventHandlerService], { data: [0, "data"], name: [1, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentImage; var currVal_1 = "caption"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ImageSliderComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "caption"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageSliderComponent_10)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageSliderComponent_11)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.apiImageMode; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.apiImageMode; _ck(_v, 4, 0, currVal_1); }, null); }
function View_ImageSliderComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "backdrop"]], null, null, null, null, null))], null, null); }
function View_ImageSliderComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-media", [["version", "thumbnail"]], [[2, "nofullscreen", null]], null, null, i2.View_NovaMediaComponent_0, i2.RenderType_NovaMediaComponent)), i1.ɵdid(1, 770048, null, 0, i3.NovaMediaComponent, [i4.NovaAPIService, i5.MediaService, i1.Renderer2, i6.DomSanitizer, i7.UIEventHandlerService], { version: [0, "version"], mediaId: [1, "mediaId"] }, null)], function (_ck, _v) { var currVal_1 = "thumbnail"; var currVal_2 = _v.parent.context.$implicit.slider_media; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).noFullscreen; _ck(_v, 0, 0, currVal_0); }); }
function View_ImageSliderComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getImageProxyUrl(_v.parent.context.$implicit.filename, "thumb"); var currVal_1 = _v.parent.context.$implicit.description1; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ImageSliderComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "image"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToImage(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageSliderComponent_14)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageSliderComponent_15)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageSliderComponent_16)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.index !== _co.currentIndex); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.apiImageMode; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.apiImageMode; _ck(_v, 6, 0, currVal_2); }, null); }
function View_ImageSliderComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "bottom-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[2, 0], ["owlNav", 1]], null, 2, "div", [["class", "owl-carousel"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageSliderComponent_13)), i1.ɵdid(3, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getImages(); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ImageSliderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { owl: 0 }), i1.ɵqud(671088640, 2, { owlNav: 0 }), (_l()(), i1.ɵand(0, [["empty", 2]], null, 0, null, View_ImageSliderComponent_1)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageSliderComponent_2)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageSliderComponent_3)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageSliderComponent_4)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 16, "div", [["class", "carousel"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageSliderComponent_5)), i1.ɵdid(11, 540672, null, 0, i8.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 11, "div", [["class", "carousel-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 5, "div", [["class", "navigation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "arrows"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["class", "arrow"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.slideLeft() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "i", [["class", "icon-arrow-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "div", [["class", "arrow"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.slideRight() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["class", "icon-arrow-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, [[1, 0], ["owl", 1]], null, 2, "div", [["class", "owl-carousel"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageSliderComponent_6)), i1.ɵdid(21, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageSliderComponent_9)), i1.ɵdid(23, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageSliderComponent_12)), i1.ɵdid(25, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((!_co.fullWidth && _co.images) && _co.images[0]); _ck(_v, 4, 0, currVal_0); var currVal_1 = (!_co.fullWidth && _co.apiImageMode); _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.fullWidth; _ck(_v, 8, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 2); _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.getImages(); _ck(_v, 21, 0, currVal_4); var currVal_5 = !_co.fullWidth; _ck(_v, 23, 0, currVal_5); var currVal_6 = !_co.fullWidth; _ck(_v, 25, 0, currVal_6); }, null); }
export function View_ImageSliderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-image-slider", [], null, null, null, View_ImageSliderComponent_0, RenderType_ImageSliderComponent)), i1.ɵdid(1, 4308992, null, 0, i11.ImageSliderComponent, [i1.PLATFORM_ID, i7.UIEventHandlerService, i4.NovaAPIService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageSliderComponentNgFactory = i1.ɵccf("nova-image-slider", i11.ImageSliderComponent, View_ImageSliderComponent_Host_0, { images: "images", apiImages: "apiImages", fullWidth: "fullWidth" }, {}, []);
export { ImageSliderComponentNgFactory as ImageSliderComponentNgFactory };
