<div #elm class="price-container">

    <div class="item" *ngFor="let item of prices">
        <div class="bar-container">
            <div class="bar HOVER pricing-bar" [ngStyle]="{'height': getBarHeight(item)}" (click)="item.ref && openLink(item)" [attr.data-name]="data.header" [attr.data-cmiid]="data.cmiid" [attr.data-price]="item.value">
                <span></span>
                <div class="price">{{item.value}}</div>
            </div>
        </div>
        <div #title class="bold">
            {{item.title}}
        </div>
        <div #text>
            {{item.text}}
        </div>
    </div>

</div>
