import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from './network.service';

@Injectable()
export class FormService {
    constructor(private network: NetworkService) {}

    public submitForm(id: string, data: any): Observable<any> {
        return this.network.post('customFormContact/' + encodeURI(id), data);
    }
}
