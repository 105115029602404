import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'nova-content-sidebar',
    templateUrl: './nova-content-sidebar.component.html',
    styleUrls: ['./nova-content-sidebar.component.scss']
})
export class NovaContentSidebarComponent implements OnInit {
    @Input() public data;

    @Input() public gallery;

    constructor(public translate: TranslateService) {}

    ngOnInit() {}
}
