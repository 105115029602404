import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './book-scroll-product.component.html',
    styleUrls: ['./book-scroll-product.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageBookScrollProductComponent')
export class PageBookScrollProductComponent extends Page implements OnInit {
    public url: string;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public media: MediaService,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    ngOnInit() {
        this.media.getMedia(this.data.product_image).subscribe(data => {
            this.url = data.url;
        });
    }
}
