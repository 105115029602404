import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
var PageFtiNewsletterComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageFtiNewsletterComponent, _super);
    function PageFtiNewsletterComponent(nova, uiEventHandler, meta, title, router, route) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.title = title;
        _this.router = router;
        _this.route = route;
        _this.route.queryParams.subscribe(function (params) {
            _this.formStatus = params['result'];
        });
        _this.showMsg = false;
        return _this;
    }
    PageFtiNewsletterComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.formStatus) {
            setTimeout(function () {
                _this.showMsg = true;
            }, 600);
        }
    };
    PageFtiNewsletterComponent.prototype.makeUrl = function (result) {
        var urlTree = this.router.createUrlTree([], {
            relativeTo: this.route,
            queryParams: {
                result: result
            },
            queryParamsHandling: 'merge'
        });
        return location.origin + urlTree.toString();
    };
    PageFtiNewsletterComponent = tslib_1.__decorate([
        NovaTemplate('PageFtiNewsletterComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            Meta,
            Title,
            Router,
            ActivatedRoute])
    ], PageFtiNewsletterComponent);
    return PageFtiNewsletterComponent;
}(Page));
export { PageFtiNewsletterComponent };
