import { OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { MagazineService } from '../_services/magazine.service';
import { SSRService } from '../_services/ssr.service';
import { UIEventHandlerService } from '../_services/uievent-handler.service';
import { ColorFormatter } from '../novamag-api/Types';
import { Title } from '@angular/platform-browser';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(route, ui, mag, ssr, router, authenticationService, title) {
        this.route = route;
        this.ui = ui;
        this.mag = mag;
        this.ssr = ssr;
        this.router = router;
        this.authenticationService = authenticationService;
        this.title = title;
        this.model = {
            username: null,
            password: null
        };
        this.loading = false;
        this.buttonHovered = false;
        this.error = null;
    }
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.ssr.isBrowser) {
            this.authenticationService.logout();
        }
        this.ui.disableActionNav = true;
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.sub = this.mag.getMagazine().subscribe(function (mag) {
            _this.authType = mag.authType || 'none';
            _this.magId = mag._id;
            _this.magazine = mag;
            _this.logo = mag.logo && mag.logo.url ? mag.logo.url : '';
        });
        this.paramsSub = this.route.params.subscribe(function (params) {
            _this.code = +params['code'];
        });
        this.title.setTitle('Log in');
    };
    LoginComponent.prototype.login = function (formData) {
        var _this = this;
        this.loading = true;
        if (this.authType === 'global' || this.authType === 'password-protection') {
            this.model.username = this.magId + '@novamag.de';
        }
        this.authenticationService.login(this.model.username, this.model.password, this.magId).subscribe(function () {
            _this.ui.disableActionNav = false;
            if (_this.returnUrl === '/' || !_this.returnUrl) {
                // get index page url
                return _this.mag
                    .getIssues()
                    .toPromise()
                    .then(function (_a) {
                    var issues = _a.issues;
                    if (issues && issues.length > 0) {
                        if (issues[0].languages && issues[0].languages.length > 0) {
                            var url = _this.magazine.language + "/" + issues[0].languages[0].handle;
                            _this.router.navigate([url]);
                            setTimeout(function () { return location.reload(); }, 100);
                            return;
                        }
                    }
                    _this.router.navigate(['/']);
                    setTimeout(function () { return location.reload(); }, 100);
                });
            }
            _this.router.navigate([_this.returnUrl]);
            setTimeout(function () { return location.reload(); }, 100);
        }, function (error) {
            if (error.error.message === 'User not aviable' || error.error.message === 'Wrong password') {
                if (formData.form.controls['username']) {
                    formData.form.controls['username'].setErrors({ incorrect: true });
                }
                formData.form.controls['password'].setErrors({ incorrect: true });
                _this.error = 'invalidUserData';
            }
            if (error.error.message === 'Account is outdated') {
                _this.error = 'accountIsOutdated';
            }
            if (error.error.message === 'Forbidden') {
                _this.error = 'Forbidden';
            }
            _this.loading = false;
        });
    };
    LoginComponent.prototype.imprint = function () {
        this.ui.showTerms = true;
    };
    LoginComponent.prototype.terms = function () {
        this.ui.showTerms = true;
    };
    LoginComponent.prototype.textToHtml = function (text) {
        return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
    };
    LoginComponent.prototype.getVersion = function () {
        if (this.ui.isMobile()) {
            return 'horizontal-wide';
        }
        else {
            return false;
        }
    };
    LoginComponent.prototype.color = function (color) {
        return new ColorFormatter(color).rgba();
    };
    LoginComponent.prototype.ngOnDestroy = function () {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        if (this.paramsSub) {
            this.paramsSub.unsubscribe();
        }
    };
    return LoginComponent;
}());
export { LoginComponent };
