import { Component, ViewEncapsulation, AfterContentInit, OnDestroy } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { MagazineService } from 'app/_services/magazine.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './index-float.component.html',
    styleUrls: ['./index-float.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageIndexFloatComponent')
export class PageIndexFloatComponent extends Page implements AfterContentInit, OnDestroy {
    @ViewChild('elm') public elm: ElementRef;

    public apiUrl: string;
    private sub: Subscription;
    private subFont: Subscription;
    public pages;

    constructor(
        public nova: NovaAPIService,
        public mag: MagazineService,
        public renderer: Renderer2,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    ngAfterContentInit() {
        this.sub = this.mag.getMagazine().subscribe(data => {
            this.subFont = this.nova.getFont(data.navigation.font).subscribe(fontData => {
                this.renderer.setStyle(this.elm.nativeElement, 'font-family', fontData.name);
            });
            this.pages = this.nova.pages.filter(
                page =>
                    (page.templateName === 'index-hotel' ||
                        page.templateName === 'index-roundtrip' ||
                        page.templateName === 'index-trips' ||
                        !page.templateName.startsWith('index-')) &&
                    page.status === 'active' &&
                    page.showInIndex !== 'no'
            );
        });
    }

    goToPage(page, floor?) {
        const params = this.router.routerState.root.firstChild.params['value'];
        if (!floor) {
            this.router.navigate(['/', params['langHandle'], params['issueHandle'], page], {
                queryParamsHandling: 'preserve'
            });
        } else {
            this.router.navigate(['/', params['langHandle'], params['issueHandle'], page, floor], {
                queryParamsHandling: 'preserve'
            });
        }
    }

    getType(type) {
        if (type === 'article-classic') {
            return 'artikel';
        } else if (type === 'image-classic') {
            return 'galerie';
        } else if (type === 'interview-classic') {
            return 'interview';
        } else if (type === 'video-classic') {
            return 'video';
        }

        return type;
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }

        if (this.subFont) {
            this.subFont.unsubscribe();
        }
    }
}
