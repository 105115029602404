/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./external-content.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./external-content.component";
import * as i3 from "../../../_services/nova-api.service";
import * as i4 from "../../../_services/uievent-handler.service";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/router";
var styles_PageExternalContentComponent = [i0.styles];
var RenderType_PageExternalContentComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PageExternalContentComponent, data: {} });
export { RenderType_PageExternalContentComponent as RenderType_PageExternalContentComponent };
export function View_PageExternalContentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { iframe: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "page page-external-content"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["iframe", 1]], null, 0, "iframe", [["frameborder", "0"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "page-", _co.id, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.url; _ck(_v, 2, 0, currVal_1); }); }
export function View_PageExternalContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_PageExternalContentComponent_0, RenderType_PageExternalContentComponent)), i1.ɵdid(1, 114688, null, 0, i2.PageExternalContentComponent, [i3.NovaAPIService, i4.UIEventHandlerService, i5.DomSanitizer, i5.Meta, i5.Title, i6.ActivatedRoute, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageExternalContentComponentNgFactory = i1.ɵccf("ng-component", i2.PageExternalContentComponent, View_PageExternalContentComponent_Host_0, {}, {}, []);
export { PageExternalContentComponentNgFactory as PageExternalContentComponentNgFactory };
