import * as tslib_1 from "tslib";
import { ErrorComponent } from './error.component';
import { UIEventHandlerService } from './../_services/uievent-handler.service';
import { NovaAPIService } from '../_services/nova-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MagazineService } from 'app/_services/magazine.service';
var ErrorPageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ErrorPageComponent, _super);
    function ErrorPageComponent(mag, nova, ui, router, route) {
        var _this = _super.call(this, mag, nova, ui, router, route) || this;
        _this.mag = mag;
        _this.nova = nova;
        _this.ui = ui;
        _this.router = router;
        _this.route = route;
        return _this;
    }
    return ErrorPageComponent;
}(ErrorComponent));
export { ErrorPageComponent };
