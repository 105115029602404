<div id="page-{{ id }}" class="page page-highlights-der-woche"
     [ngStyle]="{'backgroundColor':getColor(data['background_color'])}">
    <nova-bg [data]="data" [forceVideo]="true"></nova-bg>
    <nova-scrollbar-holder>
        <div class="scroller">
            <nova-mobile-img [data]="data" class="show-for-small-only left"></nova-mobile-img>
            <div class="bg animated fadeInUp clear" [ngStyle]="{'background-color':getColor(data['box_color'])}">
                <nova-title [data]="data"></nova-title>
                <nova-text-element [data]="data.text_content_1"></nova-text-element>

                <div class="top-hotels__container">
                    <div class="top-hotels__hotel"
                         *ngFor="let hotel of data.top_hotels"
                         [ngStyle]="{'background-color': getColor(data['top_hotel_box_color'])}">

                        <div class="top-hotels__hotel__image">
                            <nova-media *ngIf="hotel.hotel_image"
                                        [mediaId]="hotel.hotel_image"
                                        version="horizontal">
                            </nova-media>

                            <div style="position: relative">
                                <div style="position: absolute; left: 20px; bottom: 10px;">
                                    <div *ngIf="hotel.hotel_labels" class="tags">
                                        <nova-element *ngFor="let label of hotel.hotel_labels; let i = index;"
                                                      [data]="hotel"
                                                      content_name="{{'hotel_labels_' + i}}"
                                                      name="hotel_labels">
                                        </nova-element>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <nova-element class="top-hotels__hotel__geo" [data]="hotel" name="header"></nova-element>
                        <div class="top-hotels__hotel__headline">
                            <nova-element [data]="hotel" name="name" class="top-hotels__hotel__headline__name"></nova-element>
                            <div class="stars">
                                <div *ngFor="let i of hotelHelper.createArray(hotel.stars)"
                                 [class]="i + ' star--small'"></div>
                            </div>
                        </div>

                        <div class="top-hotels__hotel__text">
                            <nova-text-element style="display: inline; margin-right: 5px" [data]="hotel.text"></nova-text-element>
                            <a *ngIf="hotel.cta && hotel.external_link && hotel.external_link.startsWith('http')"
                                [href]="hotel.external_link" target="_blank"
                                [style.textDecoration]="'none'"
                            >
                                <nova-element-with-children
                                    *ngIf="hotel.cta"
                                    [data]="hotel"
                                    name="cta"
                                    class="top-hotels__hotel__button"
                                    styles="display: block;"
                                >
                                    {{ hotel.cta }}
                                </nova-element-with-children>
                            </a>
                            <nova-element-with-children
                                *ngIf="hotel.cta && hotel.external_link && !hotel.external_link.startsWith('http')"
                                [data]="hotel"
                                name="cta"
                                class="top-hotels__hotel__button"
                                styles="display: block;"
                                [routerLink]="hotel.external_link"
                            >
                                {{ hotel.cta }}
                            </nova-element-with-children>
                        </div>

                    </div>
                    <div *ngIf="((data.top_hotels.length % 3) + 3) % 3 === 2" class="top-hotels__hotel"></div>

                </div>

                <nova-text-element [data]="data.text_content_2"></nova-text-element>
                <nova-content-links class="content-links" *ngIf="data.links && data.links.length > 0" [floors]="data.links"></nova-content-links>
            </div>
        </div>
    </nova-scrollbar-holder>
    <nova-text-gallery [medias]="data.textImages" #gallery></nova-text-gallery>
</div>
