import { Component, Input, Inject, PLATFORM_ID, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { ApiImage, Image } from './image.interface';
import { isPlatformBrowser } from '@angular/common';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import { NovaAPIService } from 'app/_services/nova-api.service';

@Component({
    selector: 'nova-image-slider',
    templateUrl: './image-slider.component.html',
    styleUrls: ['./image-slider.component.scss']
})
export class ImageSliderComponent implements OnInit, AfterViewInit {
    @Input() images: Image[];
    @Input() apiImages: ApiImage[];
    @Input() fullWidth? = false;

    currentIndex = 0;
    currentImage: Image | ApiImage;
    @ViewChild('owl') public owl: ElementRef;
    @ViewChild('owlNav') public owlNav: ElementRef;
    apiImageMode = false;

    private owlOptions = {
        autoplay: true,
        loop: true,
        autoplayTimeout: 6000,
        items: 1,
        dots: false
    };

    private owlNavOptions = {
        items: 5,
        dots: false,
        center: true
    };

    private isBrowser: boolean = isPlatformBrowser(this.platformId);

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private uiEventHandler: UIEventHandlerService,
        public nova: NovaAPIService
    ) {}

    ngOnInit() {
        if (!this.images || this.apiImages) {
            this.apiImageMode = true;
            this.apiImages.sort((a, b) => (a.sortorder > b.sortorder ? 1 : -1));
        }
        this.setCurrentImage();
    }

    getImages() {
        return this.apiImageMode ? this.apiImages : this.images;
    }

    ngAfterViewInit() {
        if (this.isBrowser) {
            this.initOwlCarousels();
        }
    }

    initOwlCarousels() {
        $(this.owl.nativeElement)
            .owlCarousel(this.owlOptions)
            .on('changed.owl.carousel', event => {
                this.currentIndex = this.getFixedOwlImageIndex(event);
                this.setCurrentImage();

                if (!this.fullWidth) {
                    $(this.owlNav.nativeElement).trigger('to.owl.carousel', [this.currentIndex]);
                }
            });

        if (!this.fullWidth) {
            this.owlNavOptions.items = this.getImages().length > 4 ? this.owlNavOptions.items : this.getImages().length;
            this.owlNavOptions.center = this.getImages().length > 4;
            $(this.owlNav.nativeElement).owlCarousel(this.owlNavOptions);
        }
    }

    slideLeft() {
        this.stopAutoSlide();
        $(this.owl.nativeElement).trigger('prev.owl.carousel');
    }

    slideRight() {
        this.stopAutoSlide();
        $(this.owl.nativeElement).trigger('next.owl.carousel');
    }

    goToImage(index: number) {
        this.stopAutoSlide();
        $(this.owl.nativeElement).trigger('to.owl.carousel', [index]);
    }

    stopAutoSlide() {
        $(this.owl.nativeElement).trigger('stop.owl.autoplay');
    }

    getImageProxyUrl(url, format = '') {
        let width = 600;
        let height = 400;

        // if (this.isBrowser) {
        //     let coefficient = this.uiEventHandler.isTablet() ? 1 : 2;
        //     width = window.innerWidth / coefficient;
        //     height = width / 2;
        // }

        if (this.isBrowser) {
            const coefficient = this.uiEventHandler.isTablet() ? 1 : 2;
            width = Math.round(window.innerWidth / (this.fullWidth ? 1 : coefficient));
            height = !this.fullWidth
                ? width / 2
                : Math.round(window.innerHeight / (this.uiEventHandler.isTablet() ? 3 : 1));
        }

        if (format === 'thumb') {
            width = width / 5;
            height = height / 5;
        }
        let aspect = `1:${this.uiEventHandler.isTablet() ? 0 : 1}`;
        let rt = 'auto';
        if (format === 'slider') {
            rt = 'fit';
        }
        let size = `s:${Math.round(width)}:${Math.round(height)}:${aspect}`;

        return `${this.nova.getImgUrl()}/insecure/rt:${rt}/${size}/g:sm/plain/${url}`;
    }

    private getFixedOwlImageIndex(event: any): number {
        // https://github.com/OwlCarousel2/OwlCarousel2/issues/1203#issuecomment-225634756
        let bla = event.item.index - event.relatedTarget._clones.length / 2;
        if (bla >= event.item.count) {
            return event.item.count - 1;
        }
        return bla;
    }

    private setCurrentImage() {
        this.currentImage = this.apiImageMode ? this.apiImages[this.currentIndex] : this.images[this.currentIndex];
    }
}
