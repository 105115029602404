import * as tslib_1 from "tslib";
import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Meta, Title } from '@angular/platform-browser';
import * as $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';
var PageCoverClassicContentComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageCoverClassicContentComponent, _super);
    function PageCoverClassicContentComponent(nova, uiEventHandler, sanitizer, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.sanitizer = sanitizer;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        _this.resizeSub = _this.uiEventHandler.resizeEvent$.subscribe(_this.onResize.bind(_this));
        return _this;
    }
    PageCoverClassicContentComponent.prototype.ngOnDestroy = function () {
        this.resizeSub.unsubscribe();
    };
    PageCoverClassicContentComponent.prototype.ngOnInit = function () {
        var _this = this;
        // this.setFontSize();
        var firstRowStyle = '';
        var secondRowStyle = '';
        var thirdRowStyle = '';
        for (var key in this.data.matrixPosition) {
            var elm = this.data.matrixPosition[key].matrix;
            var num = elm;
            if (elm > 6) {
                num = elm - 6;
            }
            else if (elm > 3) {
                num = elm - 3;
            }
            var style = this.getRowStyle(num, this.data.matrixPosition[key].align);
            style += 'order: ' + elm + ';';
            if (key === 'logo') {
                firstRowStyle = style;
            }
            else if (key === 'text') {
                secondRowStyle = style;
            }
            else if (key === 'contents') {
                thirdRowStyle = style;
            }
        }
        this.firstRowStyle = this.convertCSS(firstRowStyle);
        this.secondRowStyle = this.convertCSS(secondRowStyle);
        this.thirdRowStyle = this.convertCSS(thirdRowStyle);
        setTimeout(function () {
            _this.calcMaxSize();
        }, 100);
    };
    PageCoverClassicContentComponent.prototype.getRowStyle = function (key, align) {
        var ret = '';
        if (key === 1) {
            ret += 'align-self: flex-start;justify-content: flex-start;';
        }
        else if (key === 2) {
            ret += 'align-self: center;justify-content: center;';
        }
        else {
            ret += 'align-self: flex-end;justify-content: flex-end;';
        }
        if (align === 'top') {
            ret += 'align-items: flex-start;';
        }
        else if (align === 'bottom') {
            ret += 'align-items: flex-end;';
        }
        else {
            ret += 'align-items: center;';
        }
        return ret;
    };
    // setFontSize() {
    //     if (this.data['header_size']) {
    //         const headerSize = parseInt(this.data['header_size'], 10) / 10;
    //         this.header_size = this.convertCSS('calc(43px + ' + headerSize + 'vw)');
    //     }
    //     if (this.data['subHeader_size']) {
    //         const subHeaderSize = parseInt(this.data['subHeader_size'], 10) / 10;
    //         this.subHeader_size = this.convertCSS('calc(23px + ' + subHeaderSize + 'vw)');
    //     }
    //     if (this.data['label_size']) {
    //         const labelSize = parseInt(this.data['label_size'], 10) / 10;
    //         this.label_size = this.convertCSS('calc(11px + ' + labelSize + 'vw)');
    //     }
    //     if (this.data['text_size']) {
    //         const textSize = parseInt(this.data['text_size'], 10) / 10;
    //         this.text_size = this.convertCSS('calc(11px + ' + textSize + 'vw)');
    //     }
    // }
    PageCoverClassicContentComponent.prototype.calcMaxSize = function () {
        var _this = this;
        var logo_size = '';
        if (this.data['logo'] && this.data['logo'].url) {
            var divW_1 = $(this.logo.nativeElement).width();
            var logo_1 = $(this.logo.nativeElement).find('img')[0];
            if (logo_1['naturalHeight']) {
                logo_size += 'max-height: ' + (divW_1 * logo_1['naturalHeight']) / logo_1['naturalWidth'] + 'px;';
            }
            else {
                $(logo_1).on('load', function () {
                    divW_1 = $(_this.logo.nativeElement).width();
                    logo_size += 'max-height: ' + (divW_1 * logo_1['naturalHeight']) / logo_1['naturalWidth'] + 'px;';
                });
            }
        }
        if (this.data['logoSize']) {
            logo_size += 'height: calc(50px + ' + this.data['logoSize'] + 'vw);';
        }
        this.logo_size = this.convertCSS(logo_size);
    };
    PageCoverClassicContentComponent.prototype.onResize = function (e) {
        this.calcMaxSize();
    };
    PageCoverClassicContentComponent.prototype.convertCSS = function (css) {
        var input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {}, attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    };
    PageCoverClassicContentComponent.prototype.setMyStyles = function (obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    };
    PageCoverClassicContentComponent = tslib_1.__decorate([
        NovaTemplate('PageCoverClassicContentComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            DomSanitizer,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], PageCoverClassicContentComponent);
    return PageCoverClassicContentComponent;
}(Page));
export { PageCoverClassicContentComponent };
