//https://stackoverflow.com/questions/44330537/need-browseranimationsmodule-in-angular-but-gives-error-in-universal
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppModule } from './app.module';
import { AppComponent } from './app.component';

@NgModule({
    imports: [BrowserAnimationsModule, AppModule],
    bootstrap: [AppComponent]
})
export class AppBrowserModule {}
