/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./error-page.component.1";
var styles_ErrorDisabledComponent = [i0.styles];
var RenderType_ErrorDisabledComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ErrorDisabledComponent, data: {} });
export { RenderType_ErrorDisabledComponent as RenderType_ErrorDisabledComponent };
export function View_ErrorDisabledComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "error-disabled flex-center position-ref full-height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["src", "assets/img/novamag.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Dieses Online-Magazin befindet sich derzeit in Pr\u00FCfung. Bitte schauen Sie zu einem sp\u00E4teren Zeitpunkt noch mal vorbei. Vielen Dank f\u00FCr Ihr Verst\u00E4ndnis."]))], null, null); }
export function View_ErrorDisabledComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ErrorDisabledComponent_0, RenderType_ErrorDisabledComponent)), i1.ɵdid(1, 49152, null, 0, i2.ErrorDisabledComponent, [], null, null)], null, null); }
var ErrorDisabledComponentNgFactory = i1.ɵccf("ng-component", i2.ErrorDisabledComponent, View_ErrorDisabledComponent_Host_0, {}, {}, []);
export { ErrorDisabledComponentNgFactory as ErrorDisabledComponentNgFactory };
