<div id="page-{{ id }}" class="page-pflugfelder-index">
    <nova-bg [data]="data" class="background"></nova-bg>
    <div class="container" [class.no-image]="!data.background_media" style="margin: auto" *ngIf="!isLoading">
        <div>
            <nova-element [data]="data" name="label"></nova-element>
            <h1><nova-element [data]="data" name="header"></nova-element></h1>
            <nova-element [data]="data" name="subHeader"></nova-element>
        </div>

        <div>
            <nova-element [data]="data" name="text"></nova-element>
        </div>

        <div class="search">
            <div class="search-input">
                <i class="fa fa-search"></i>
                <input type="text" [(ngModel)]="searchValue" (ngModelChange)="filter()" placeholder="Suchen …">
            </div>
        </div>

        <ul class="ul-reset">
            <li class="outer-region" *ngFor="let region of regions" [id]="region[0]" [class.open]="searchValue.length > 0">
                <h3 class="outer-region__title" (click)="open(region[0])">{{region[0] === "RemsMurr" ? "Rems-Murr-Kreis" : region[0]}} <i class="fa fa-caret-up"></i></h3>

                <ul class="ul-reset blog-posts">
                    <li *ngFor="let post of region[1]" class="single-post" [routerLink]="post.handle" [@fadeAnimation]="'in'">
                        <div class="single-post__content">
                            <h4 class="single-post__title font-bold">{{post.header}}</h4>
                            <span *ngFor="let district of post.sortedDistricts; last as isLast">
                                <a *ngIf="district.handle" (click)="stop($event)" [routerLink]="district.handle">{{district.name}}</a>
                                <span *ngIf="!district.handle">{{district.name}}</span>
                                <span *ngIf="!isLast">, </span>
                            </span>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div>

