import { Pipe, PipeTransform } from '@angular/core';
import { MultiSelectItem } from './MultiSelectItem';

@Pipe({
    name: 'indexRoundtripFilter',
    pure: false
})
export class IndexRoundtripFilterPipe implements PipeTransform {
    transform(
        contents: any,
        selectedFilters: MultiSelectItem[],
        selectedInterests: MultiSelectItem[],
        selectedStartingPoints: MultiSelectItem[]
    ): any {
        if (!contents) {
            return contents;
        }

        let filterSelected = content => {
            return true;
        };

        if (selectedFilters.length) {
            filterSelected = content => {
                return selectedFilters
                    .map(item => item.id)
                    .some(fil => content.roundtrip_categories && content.roundtrip_categories.indexOf(fil) > -1)
                    ? content
                    : null;
            };
        }

        let filterCountries = content => {
            return true;
        };

        if (selectedInterests.length) {
            filterCountries = content => {
                return selectedInterests
                    .map(item => item.id)
                    .some(fil => content.interests && content.interests.indexOf(fil) > -1)
                    ? content
                    : null;
            };
        }

        let filterStartingPoints = content => {
            return true;
        };

        if (selectedStartingPoints.length) {
            filterStartingPoints = content => {
                return selectedStartingPoints
                    .map(item => item.id)
                    .some(fil => content.startingpoint && content.startingpoint.indexOf(fil) > -1)
                    ? content
                    : null;
            };
        }

        return contents.filter(
            content => filterSelected(content) && filterCountries(content) && filterStartingPoints(content)
        );
    }
}
