<div class="flex-container hotel-item__header">
    <h2 *ngIf="hotel.header" #font_size_bold class="show-pointer hotel-item__title"
        style="margin: 0;" (click)="goTo(hotel._id)">
        <nova-element name="header" [ignoreFont]="true" [ignoreSize]="true" [data]="hotel">
        </nova-element>
    </h2>
    <div class="stars">
        <div *ngFor="let i of hotelHelper.createArray(hotel.stars)"
             [class]="i"></div>
    </div>
</div>
<div class="flex-container" [id]="hotel.name">
    <nova-media *ngIf="!hotel.background_media && hotel.blocks && hotel.blocks[0].slider_media"
                [mediaId]="hotel.blocks[0].slider_media" (click)="goTo(hotel._id)" version="horizontal"
                class="show-pointer" [fallback]="true"></nova-media>

    <nova-media *ngIf="hotel.background_media"
                [mediaId]="hotel.background_media" (click)="goTo(hotel._id)" version="horizontal"
                class="show-pointer" [fallback]="true"></nova-media>
    <img
        class="hotel-item__image show-pointer"
        (click)="goTo(hotel._id)"
        *ngIf="!hotel.background_media && hotel.images && thumbnail.url"
        [src]="thumbnail.url"
        [alt]="thumbnail.alt"
    >
    <div style="position: relative">
        <div style="position: absolute; left: 20px; bottom: 10px;">
            <div *ngIf="hotel.hotel_labels" class="tags ">
                <nova-element *ngFor="let label of hotel.hotel_labels; let i = index;"
                              [ignoreFont]="true" [data]="hotel" name="{{'hotel_labels_' + i}}">
                </nova-element>
            </div>
        </div>
    </div>
</div>
<div class="hotel-item__footer">
    <div *ngIf="hotel.country_labels" class="tags footer-tags">
            <nova-element *ngFor="let label of hotel.country_labels; let i = index;" [ignoreFont]="true"
                    [data]="hotel" name="{{'country_labels_' + i}}"></nova-element>

    </div>
    <div *ngIf="hotel.bestprice" class="bestprice">
        <nova-element name="bestprice" [ignoreFont]="true" [ignoreSize]="true" [data]="hotel"></nova-element>
    </div>
</div>
