<div id="page-{{ id }}" class="page page-poker">
  <div *ngFor="let val of media;let key = index;" [ngClass]="{opened: active == key}" class="detail scrollbar-holder" [ngStyle]="{'backgroundColor':getColor(val.org['box_color'], true)}">
    <div class="scroller" *ngIf="val.org">
      <a class="back" (click)="close()" [ngStyle]="getButtonStyling(val.org)"><i class="icon-arrow-left"></i> Zurück</a>
      <div class="img-placeholder clear">
        <img width="1920px" height="1080px" [src]="mediaService.getImageProxyUrl(mediaService.getMediaUrlByData(val, 'horizontal-wide'),'horizontal-wide')" />
      </div>
      <a><nova-element *ngIf="val.org.header" [data]="val.org" name="header"></nova-element></a>
      <nova-text-element [data]="val.org.text"></nova-text-element>
      <a *ngIf="val.copyright" class="copyright" [href]="val.copyrightUrl" target="_blank">&copy; {{ val.copyright }}</a>
    </div>
  </div>
  <div #mobileScroller class="mobile-scroller-handler scrollbar-holder" [ngClass]="{single: data.medias.length == 1, half: data.medias.length == 2 && !teaser}">
    <div class="scroller" [ngClass]="{hasContent: (data.links && data.links.length > 0) || data.text || data.header || data.subHeader || data.label}">
      <div #teaser class="bg teaser animated fadeInDown scrollbar-holder" [ngStyle]="{'backgroundColor':getColor(data['box_color'], true)}" [ngClass]="{fadeOutUp: active != -1, fadeInDown: active == -1}" *ngIf="teaser">
        <div class="scroller">
          <nova-title [data]="data"></nova-title>
          <nova-text-element *ngIf="data.text" class="con" [data]="data.text" (openGallery)="gallery.openSlider($event)"></nova-text-element>
          <nova-content-links class="content-links" *ngIf="data.links && data.links.length > 0" [floors]="data.links"></nova-content-links>
        </div>
      </div>
      <div class="poker-medium">
        <div *ngFor="let val of media;let key = index;" class="entry" [ngStyle]="getEntryStyle(val,true)" (click)="open(key)">
          <div class="plus-button">
            <span [ngStyle]="getButtonStyling(val.org)">
              <i class="fa fa-plus"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="poker" [ngClass]="calcWidth()">
        <div *ngFor="let val of media;let key = index;" class="slide" [ngStyle]="getEntryStyle(val)" (click)="open(key)" [ngClass]="{active: active == key, inactive: active != key && active != -1}">
          <a *ngIf="val.copyright" class="img-copyright" [href]="val.copyrightUrl" target="_blank">&copy; {{ val.copyright }}</a>
          <div class="plus-button">
            <span [ngStyle]="getButtonStyling(val.org)">
              <i class="fa fa-plus"></i>
            </span>
          </div>
          <div class="bg-small animated fadeInLeft easeOutQuad" [ngStyle]="{'backgroundColor':getColor(val.org['box_color'], true)}" *ngIf="val.org.header || val.org.text">
            <span (mouseover)="hover=true" (mouseleave)="hover=false" [ngStyle]="getButtonStyling(val.org, hover)" class="icon-cancel" (click)="close()"></span>
            <nova-scrollbar-holder>
              <div class="scroller">
                <nova-element *ngIf="val.org.header" [data]="val.org" name="header"></nova-element>
                <nova-text-element [data]="val.org.text" (openGallery)="gallery.openSlider($event)"></nova-text-element>
              </div>
            </nova-scrollbar-holder>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nova-text-gallery [medias]="gallerySliderMedia" #gallery></nova-text-gallery>
</div>
