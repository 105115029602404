<div class="title-bar"
     [ngClass]="{ nobg: issue.labelHasBackground == 'no' }"
     [style.font-family]="font"
     [style.overflow]="nav.logoSize && nav.logoSize !== '100%' ? 'unset': 'hidden'"
	 [style.color]="getColor(nav.textColor)">
	<nova-nav-item (click)="openBudapestOverlay()" class="menu-button"
		[style.border-right-color]="getColor(nav.borderColor)"><i class="fa fa-bars"></i></nova-nav-item>
	<div [style.background-color]="mobileBG()" class="left">
		<div class="logo" (click)="ui.goToFirst()" [style.border-right-color]="getColor(nav.borderColor)"
			*ngIf="issue.showLogo == 'yes' && !logoLink else elseBlock">
			<img [src]="ui.logo || 'assets/img/novamag.svg'" [style.padding]="getLogoPadding(nav.logoSize)" alt="Logo" />
		</div>
		<ng-template #elseBlock>
            <a href={{logoLink}} target={{logoLinkTarget}} class="logo" [style.border-right-color]="getColor(nav.borderColor)" style="padding: 0 20px;">
                <img [src]="ui.logo || 'assets/img/novamag.svg'" [style.padding]="getLogoPadding(nav.logoSize)" alt="Logo" />
            </a>
        </ng-template>
		<div class="hide-mobile" [style.border-right-color]="getColor(nav.borderColor)"
			*ngIf="issue.showName == 'yes' && issue.name">{{ issue.name }}</div>
		<div *ngIf="issue.showNumber == 'yes' && issue.number" [style.border-right-color]="getColor(nav.borderColor)">
			{{ issue.number }}</div>
		<div *ngIf="issue.showPageNumbers == 'yes'" [style.border-right-color]="getColor(nav.borderColor)">
			{{ currentPage + 1 }}/{{ pageNum }}</div>
		<div *ngIf="issue.showPageTitle == 'yes' && !ui.isTablet()"
			[style.border-right-color]="getColor(nav.borderColor)" class="nav-title">{{ pageTitle }}</div>
		<div *ngIf="issue.additionalInformation && !ui.isTablet()"
			[style.border-right-color]="(infoPage || homeLinks?.primary || homeLinks?.secondary) && getColor(nav.borderColor)" class="nav-title">
			{{ issue.additionalInformation }}
		</div>
		<div *ngIf="infoPage"
             [style.border-right-color]="infoPage && (homeLinks?.primary || homeLinks?.secondary) && getColor(nav.borderColor)"
             class="nav-title link" (click)="goToInfoPage(infoPage)">
			<i class="icon-impressum"></i>
		</div>
        <div *ngIf="homeLinks?.primary?.target" class="nav-title link" (click)="goToUrl(homeLinks.primary.target)"
			[style.border-right-color]="homeLinks?.secondary?.target && getColor(nav.borderColor)">
            <nova-media *ngIf="homeLinks.primary.icon" class="home-icon-link" [mediaId]="homeLinks.primary.icon"></nova-media>
            <i *ngIf="!homeLinks.primary.icon" class="fa fa-home"></i>
        </div>
		<div *ngIf="homeLinks?.secondary?.target" class="nav-title link" (click)="goToUrl(homeLinks.secondary.target)"
			[style.border-right-color]="stripe.currentCustomer.email && getColor(nav.borderColor)">
            <nova-media *ngIf="homeLinks.secondary.icon" class="home-icon-link" [mediaId]="homeLinks.secondary.icon"></nova-media>
            <i *ngIf="!homeLinks.secondary.icon" class="fa fa-home"></i>
        </div>
		<div *ngIf="stripe.currentCustomer.email" class="manage-account">
			<i class="far fa-user"></i>&nbsp;&nbsp;{{stripe.currentCustomer.email}}
		<div class="buttons">
			(<button (click)="stripe.logout()">{{ 'LOGOUT' | translate }}</button>)
		</div>
	</div>
	</div>
</div>
