<div class="flex-container trip-item__header">
    <h2 *ngIf="trip.header" class="show-pointer trip-item__title"
        style="margin: 0;" (click)="goTo(trip._id)">
        <nova-element name="header" [ignoreFont]="true" [ignoreSize]="true" [data]="trip">
        </nova-element>
        <div class="trip-item__price">{{trip.price_text}}</div>
    </h2>
</div>
<div class="flex-container">
    <nova-media *ngIf="trip.background_media"
                [mediaId]="trip.background_media" (click)="goTo(trip._id)" version="horizontal"
                class="show-pointer" [fallback]="true"></nova-media>

    <nova-media *ngIf="!trip.background_media && trip.medias && trip.medias[0].slider_media; else fallback"
                [mediaId]="trip.medias[0].slider_media" (click)="goTo(trip._id)" version="horizontal"
                class="show-pointer" [fallback]="true"></nova-media>

    <ng-template #fallback>
        <img
            class="trip-item__image show-pointer"
            (click)="goTo(trip._id)"
            *ngIf="trip.images && [].constructor.isArray(trip.images) && trip.images[0].filename"
            [src]="trip.images && getImageProxyUrl(trip.images[0].filename)"
            [alt]="trip.images[0].description1"
        >
    </ng-template>
    <div style="position: relative" >
        <div style="position: absolute; left: 20px; bottom: 10px;">
	        <div *ngIf="trip.country_labels" class="tags footer-tags">

			    <nova-element *ngFor="let label of trip.country_labels; let i = index;" 
			    	[ignoreFont]="true" [data]="trip" name="country_labels" content_name="{{'country_labels_' + i}}">
			    </nova-element>
			</div>
	        
            <div *ngIf="trip.hotel_labels" class="tags">
                <nova-element *ngFor="let label of trip.hotel_labels; let i = index;"
                    [ignoreFont]="true" [data]="trip" name="hotel_labels" content_name="{{'hotel_labels_' + i}}">
                </nova-element>
            </div>
        </div>
    </div>
    
</div>
<div class="trip__footer" [style.font-family]="contentFontStyle">
    <nova-text-element [data]="trip.text" ></nova-text-element>
</div>
