/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hotel-detail-premium.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./hotel-detail-premium-content/hotel-detail-premium-content.component.ngfactory";
import * as i3 from "../hotel-detail-standard/shared-hotel-helper.service";
import * as i4 from "./hotel-detail-premium-content/hotel-detail-premium-content.component";
import * as i5 from "../../../_services/uievent-handler.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../../_services/nova-api.service";
import * as i8 from "./hotel-detail-premium.component";
import * as i9 from "../../../_services/media.service";
import * as i10 from "@angular/router";
var styles_PageHotelDetailPremiumComponent = [i0.styles];
var RenderType_PageHotelDetailPremiumComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PageHotelDetailPremiumComponent, data: {} });
export { RenderType_PageHotelDetailPremiumComponent as RenderType_PageHotelDetailPremiumComponent };
export function View_PageHotelDetailPremiumComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "page page-hotel-detail-premium"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-hotel-detail-premium-content", [], null, null, null, i2.View_HotelDetailPremiumContentComponent_0, i2.RenderType_HotelDetailPremiumContentComponent)), i1.ɵprd(512, null, i3.SharedHotelHelperService, i3.SharedHotelHelperService, []), i1.ɵdid(3, 4440064, null, 0, i4.HotelDetailPremiumContentComponent, [i5.UIEventHandlerService, i6.DomSanitizer, i3.SharedHotelHelperService, i1.PLATFORM_ID, i7.NovaAPIService], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.data; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "page-", _co.id, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_PageHotelDetailPremiumComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ng-component", [], null, null, null, View_PageHotelDetailPremiumComponent_0, RenderType_PageHotelDetailPremiumComponent)), i1.ɵprd(4608, null, i3.SharedHotelHelperService, i3.SharedHotelHelperService, []), i1.ɵdid(2, 49152, null, 0, i8.PageHotelDetailPremiumComponent, [i7.NovaAPIService, i5.UIEventHandlerService, i6.DomSanitizer, i6.Meta, i9.MediaService, i6.Title, i10.ActivatedRoute, i10.Router], null, null)], null, null); }
var PageHotelDetailPremiumComponentNgFactory = i1.ɵccf("ng-component", i8.PageHotelDetailPremiumComponent, View_PageHotelDetailPremiumComponent_Host_0, {}, {}, []);
export { PageHotelDetailPremiumComponentNgFactory as PageHotelDetailPremiumComponentNgFactory };
