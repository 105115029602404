<div id="page-{{ id }}" class="page page-product-gallery">
    <nova-bg [data]="data" class="hidden-sm"></nova-bg>
    <nova-scrollbar-holder [ngStyle]="{'backgroundColor':getColor(data['background_color'])}">
        <div class="scroller" [ngStyle]="flexboxStyle">
            <nova-mobile-img [data]="data" class="show-for-small-only left"></nova-mobile-img>
            <div [ngStyle]="flexboxStyle" class="bg clear {{data.box_variant}}" [style.background-color]="getColor(data['box_color'])"
                [hidden]="!data.text && !data.header && !data.subHeader">
                <div>
                <div class="flex-item logo" #logo [ngStyle]="logoStyle">
                    <div [ngStyle]="logoSize" *ngIf="data.logo && data.logo.url">
                        <img [src]="data.logo.url" alt="Logo" />
                    </div>
                </div>

                <nova-title [data]="data"></nova-title>
                <nova-text-element class="con" [data]="data.text" [myid]="data._id" (openGallery)="gallery.openSlider($event)"></nova-text-element>

                <div [ngClass]="data.products && data.products.length === 1 && 'single-item-grid'" class="product-grid" [ngStyle]="gridStyle">
                    <div *ngFor="let product of data.products;let key = index">
                        <div [ngClass]="getIsClickable(product)" class="single-product" [style.background-color]="getColor(product['background_color'])">
                            <div>
                                <div #productImage class="media" *ngIf="product.expand === 'yes'; else elseBlock" (click)="open(product._id)" >
                                    <nova-media [mediaId]="product.media" version="block" data-id="{{ key }}"></nova-media>
                                </div>
                                <ng-template #elseBlock>
                                    <a #productImage class="media" *ngIf="product.expand === 'no' && product.link; else fallBack" href="{{product.link}}" target="{{product.target}}">
                                        <nova-media [mediaId]="product.media" version="block"></nova-media>
                                    </a>
                                </ng-template>
                                <ng-template #fallBack><nova-media [mediaId]="product.media" version="block"></nova-media></ng-template>

                                <h5 *ngIf="product.header" class="single-product--title">
                                    <nova-element [data]="product" name="header"></nova-element>
                                </h5>
                                <div class="single-product--description" *ngIf="product.description">
                                    <small>
                                        <nova-text-element class="con" [data]="product.description" [myid]="product._id"></nova-text-element>
                                    </small>
                                </div>
                            </div>

                            <button
                                class="cta"
                                *ngIf="product.expand === 'yes' && product.buttonText && !product.link; else elseLink"
                                [ngStyle]="getButtonStyling(product, hover)"
                                (mouseover)="hover=product._id"
                                (mouseleave)="hover=false"
                                (click)="open(product._id)"
                            >
                                <nova-element [data]="product" name="buttonText"></nova-element>
                            </button>

                            <ng-template #elseLink>
                            <a
                                *ngIf="product.buttonText && product.link"
                                class="cta"
                                href="{{product.link}}"
                                target="{{product.target}}"
                                [ngStyle]="getButtonStyling(product, hover)"
                                (mouseover)="hover=product._id"
                                (mouseleave)="hover=false"
                            >
                                <nova-element [data]="product" name="buttonText"></nova-element>
                            </a>
                        </ng-template>
                        </div>
                    </div>
                </div>
            </div></div>
        </div>
    </nova-scrollbar-holder>
    <nova-text-gallery [medias]="data.textImages" #gallery></nova-text-gallery>
</div>

<div [ngStyle]="getItemStyle()" id="page-{{ id }}-slider" [hidden]="!detail"
	class="page-image-classic-slider">
	<nova-nav-border-item class="icon-cancel my-close-icon" (click)="close()"></nova-nav-border-item>
	<nova-gallery-popup [original]="true" #slider [con]="sliderProducts"></nova-gallery-popup>
</div>

