import { Component, OnInit } from '@angular/core';
import { NovaAPIService } from '../../_services/nova-api.service';
import { MagazineService } from '../../_services/magazine.service';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthGuard } from 'app/_helpers/auth.guard';
import { StripeService } from 'app/_services/stripe.service';
import { environment } from 'environments/environment';
import { SSRService } from 'app/_services/ssr.service';

export type StepStatus = 'pending' | 'success' | 'error' | 'stale';
export type PaidContentDialogSteps = 'initial' | 'login' | 'subscribe' | 'error' | 'authorizing';
export interface PaidContentDialogState {
    step: PaidContentDialogSteps;
    status: StepStatus;
    message?: string;
    email?: string;
}
@Component({
    selector: 'paid-content-dialog',
    templateUrl: './paid-content-dialog.component.html',
    styleUrls: ['./paid-content-dialog.component.scss']
})
export class PaidContentDialogComponent implements OnInit {
    loginForm = new FormGroup(
        {
            email: new FormControl('', [
                Validators.required,
                // regex from: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
                Validators.pattern(
                    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
                )
            ])
        },
        { updateOn: 'blur' }
    );

    public isSubmitting = false;
    public submitSuccess = false;
    public isLoading = false;
    public formError: string | boolean = false;

    state: PaidContentDialogState = {
        step: 'initial',
        status: 'stale',
        message: '',
        email: ''
    };

    constructor(
        public authGuard: AuthGuard,
        public magService: MagazineService,
        public nova: NovaAPIService,
        public ssr: SSRService,
        public stripe: StripeService,
        public uiEventHandler: UIEventHandlerService
    ) {}

    async ngOnInit() {
        this.isLoading = false;
    }

    createCleanUrl(dirtyUrl) {
        const url = new URL(dirtyUrl);

        if (url.hostname === 'localhost') {
            return 'https://novamag.de';
        }

        // remove params and only return path
        return `${url.origin}${url.pathname}`;
    }

    async onLoginSubmit() {
        this.isSubmitting = true;

        this.state = {
            step: 'login',
            status: 'success'
        };

        await fetch(`${environment.apiUrl}v1/stripe/login`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                email: this.loginForm.get('email').value,
                language: this.nova.issue.language,
                publication_id: this.nova.issue.issueId,
                stripe_account: this.stripe.stripeAccount,
                url: this.createCleanUrl(window.location.href)
            })
        });

        this.isSubmitting = false;
    }

    async redirectToCheckout() {
        this.isSubmitting = true;

        const res = await fetch(`${environment.apiUrl}v1/stripe/checkout`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                language: this.nova.issue.language,
                publication_id: this.nova.issue.issueId,
                return_url: this.createCleanUrl(window.location.href),
                stripe_account: this.stripe.stripeAccount
            })
        });

        const result = await res.json();

        if (result.code === 200 && this.ssr.isBrowser && typeof window !== 'undefined') {
            window.location = result.url;
        } else {
            this.isSubmitting = false;
        }
    }

    setStep(state: PaidContentDialogState) {
        this.state = {
            step: 'initial',
            status: 'stale',
            message: '',
            ...state
        };
    }

    close(): void {
        this.uiEventHandler.showPaidContentDialog = false;
    }
}
