<div class="items">
  <a class="item" *ngFor="let page of pages | pagesFilter: searchString; let key = index;" tabIndex="-1"
    [hidden]="page.showInIndex == 'no'" [routerLink]="makeUrl(page)" queryParamsHandling="preserve" (click)="pageChange.emit()">
    <div class="image-container" [class.active]="currentPage == key" [style.background-image]="getThumbnail(page)" >
      <div class="overlay"></div>
      <span [ngStyle]="{'fontFamily':font}"><b [style.background-color]="isActive(currentPage == key)">{{ pad(key + 1,
          2) }}</b>
        {{ uiEventHandler.getContentTableEntryName(page) }}</span>
    </div>
  </a>
</div>
