import { rgbaObjectToString } from '../_helpers/rgba-object-to-string';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Color } from 'app/novamag-api/Types';
import { MediaService } from './media.service';

export interface Category {
    category_color: Color;
    image: string;
    name: string;
    name_color: Color;
    name_fonts: string;
    status: 'active' | 'inactive';
}

@Injectable({
    providedIn: 'root'
})
export class BlogService {
    constructor(private sanitizer: DomSanitizer, private mediaService: MediaService) {}

    setBlogStyles(blogIndexPage) {
        if (!blogIndexPage) {
            return;
        }

        const root = document.documentElement;

        if (root.style.getPropertyValue('--blog-grid-columns-desktop')) {
            return;
        }

        blogIndexPage.font && root.style.setProperty('--font-regular', `'${blogIndexPage.font.replace('+', ' ')}'`);
        blogIndexPage.font_bold &&
            root.style.setProperty('--font-bold', `'${blogIndexPage.font_bold.replace('+', ' ')}'`);
        root.style.setProperty('--blog-grid-columns-desktop', blogIndexPage.gridColumnsDesktop);
        root.style.setProperty('--blog-grid-columns-mobile', blogIndexPage.gridColumnsMobile);
        root.style.setProperty('--blog-grid-gap-desktop', `${blogIndexPage.gridGapDesktop}px`);
        root.style.setProperty('--blog-grid-gap-mobile', `${blogIndexPage.gridGapMobile}px`);
        root.style.setProperty(
            '--blog-background-color-content',
            (blogIndexPage.background_color_content && rgbaObjectToString(blogIndexPage.background_color_content)) ||
                '#fff'
        );

        root.style.setProperty(
            '--blog-background-color',
            (blogIndexPage.background_color && rgbaObjectToString(blogIndexPage.background_color)) || '#fff'
        );
    }

    async getBlogNavigationIcon(id: string) {
        if (!id) {
            return undefined;
        }

        return await this.mediaService.getMediaUrl(id, 'preview').toPromise();
    }

    getActiveCategories(blogCategories, postCategories) {
        return blogCategories.filter(
            cat => (postCategories.includes(cat.name) ? cat.name && cat.status === 'active' : undefined)
        );
    }

    equalizeHeights(selector: string = '.single-post__content') {
        const elements = $(selector);
        let maxHeight = 0;

        for (const post of elements) {
            if (maxHeight === 0 || maxHeight < post.offsetHeight) {
                maxHeight = post.offsetHeight;
            }
        }

        for (const post of elements) {
            post.style.height = `${maxHeight}px`;
        }
    }

    getColoredCategories(blogCategories, postCategories: string[] | Category[]) {
        if (!blogCategories || !postCategories) {
            return '';
        }

        const coloredCategories = [];

        for (const category of postCategories) {
            let categoryArray = [];

            if (typeof category === 'string') {
                categoryArray = blogCategories.filter(cat => cat.name.toUpperCase() === category.toUpperCase());
            } else {
                categoryArray = blogCategories.filter(cat => cat.name.toUpperCase() === category.name.toUpperCase());
            }

            if (!categoryArray[0]) {
                continue;
            }

            const textColor = categoryArray[0].name_color ? rgbaObjectToString(categoryArray[0].name_color) : 'black';
            coloredCategories.push(`<span style="color: ${textColor}">${categoryArray[0].name}</span>`);
        }

        return this.sanitizer.bypassSecurityTrustHtml(coloredCategories.join(' | '));
    }

    getCategoryColor(blogCategories, postCategories): string {
        if (!blogCategories || !postCategories) {
            return '';
        }

        const defaultCategoryColor = { r: 255, g: 255, b: 255, a: 0 };
        let categoryColor = '';

        for (const category of postCategories) {
            let categoryArray = [];

            if (typeof category === 'string') {
                categoryArray = blogCategories.filter(cat => cat.name.toUpperCase() === category.toUpperCase());
            } else {
                categoryArray = blogCategories.filter(cat => cat.name.toUpperCase() === category.name.toUpperCase());
            }

            if (!categoryArray[0]) {
                continue;
            }

            if (JSON.stringify(categoryArray[0].category_color) === JSON.stringify(defaultCategoryColor)) {
                continue;
            }

            categoryColor = rgbaObjectToString(categoryArray[0].category_color);
        }

        return categoryColor;
    }

    getFormattedDate(date) {
        const formattedDate = new Date(date).toLocaleDateString('de');

        if (formattedDate === 'Invalid Date') {
            return date;
        }

        return formattedDate;
    }
}
