import { ColorFormatter } from './../novamag-api/Types';
import { UIEventHandlerService } from './../_services/uievent-handler.service';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { NovaAPIService } from '../_services/nova-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MagazineService } from 'app/_services/magazine.service';

@Component({
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ErrorComponent implements OnInit, OnDestroy {
    public magazine;
    public navStyle;
    public logo;

    public code: number;
    private sub: any;

    constructor(
        public mag: MagazineService,
        public nova: NovaAPIService,
        public ui: UIEventHandlerService,
        public router: Router,
        public route: ActivatedRoute
    ) {
        this.mag.getMagazine().subscribe(data => {
            this.magazine = data;
            this.logo = data.logo && data.logo.url ? data.logo.url : '';
            if (data) {
                this.navStyle = data.navigation;
            }
        });
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.code = +params['code'];
        });

        if (typeof window !== 'undefined') {
            window.setTimeout(function() {
                $('.content-container').addClass('in');
            }, 500);
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    getColor() {
        if (this.navStyle) {
            return new ColorFormatter(this.navStyle.backgroundColor).rgba();
        }
    }

    mobileBG() {
        if (this.ui.isMobile() && this.navStyle) {
            return new ColorFormatter(this.navStyle.backgroundColor).rgba();
        }
    }
}
