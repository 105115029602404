<div id="page-{{ id }}" class="page page-multiple-bgs">
  <div class="owl-carousel owl-loaded">
    <div class="slide" [ngStyle]="{'backgroundColor':getColor(data['background_color'])}">
      <div class="pos-rel clearfix">
        <nova-media [mediaId]="data.background_media" class="animated fadeIn" [version]="getVersion()" [bg]="true"></nova-media>
      </div>
      <div class="bg animated fadeInUp scrollbar-holder" [ngStyle]="setMyStyles(getScrollbarStyle(data['background_color']))">
        <div class="scroller">
          <div class="pos-rel clearfix show-for-small-only">
            <nova-mobile-img [data]="data" class="show-for-small-only left"></nova-mobile-img>
          </div>
          <div class="api-title clearfix show-for-small-only mobile-api-title">
            <h3 *ngIf="data.label" class="dateline" [ngStyle]="{'backgroundColor':getColor(data['box_color_top'])}"><nova-element [data]="data" name="label"></nova-element></h3>
          </div>
          <div class="clearfix">
            <div class="api-title">
              <h3 *ngIf="data.label" class="dateline hide-for-small-only" [ngStyle]="{'backgroundColor':getColor(data['box_color_top'])}"><nova-element [data]="data" name="label"></nova-element></h3>
              <div class="title-holder clearfix">
                <h1 [ngStyle]="{'backgroundColor':getColor(data['box_color_top'])}"><nova-element [data]="data" name="header"></nova-element></h1>
                <h2 *ngIf="data.subHeader" [ngStyle]="{'backgroundColor':getColor(data['box_color_top'])}"><nova-element [data]="data" name="subHeader"></nova-element></h2>
              </div>
            </div>
            <div class="text-content" [ngStyle]="{'backgroundColor':getColor(data['box_color_bottom'])}">
              <nova-text-element class="con" [data]="data.text" (openGallery)="gallery.openSlider($event)"></nova-text-element>
              <nova-content-links class="content-links animated fadeScaleUp fadeScaleUp2" *ngIf="data.links && data.links.length > 0" [floors]="data.links"></nova-content-links>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nova-text-gallery [medias]="data.textImages" #gallery></nova-text-gallery>
</div>
