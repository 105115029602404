import * as tslib_1 from "tslib";
import { OnInit, AfterViewInit } from '@angular/core';
import { NovaTemplate } from 'app/_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
var PagePandoraPage2Component = /** @class */ (function (_super) {
    tslib_1.__extends(PagePandoraPage2Component, _super);
    function PagePandoraPage2Component(nova, uiEventHandler, meta, title, router, route) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.title = title;
        _this.router = router;
        _this.route = route;
        _this.route.queryParams.subscribe(function (params) {
            _this.formStatus = params['result'];
        });
        _this.showMsg = false;
        return _this;
    }
    PagePandoraPage2Component.prototype.ngOnInit = function () { };
    PagePandoraPage2Component.prototype.ngAfterViewInit = function () { };
    PagePandoraPage2Component.prototype.scrollDown = function () {
        var viewPortHeight = $(window).innerHeight();
        $('.scrollbar-holder')
            .stop()
            .animate({ scrollTop: viewPortHeight }, 500, 'swing', function () { });
    };
    PagePandoraPage2Component.prototype.isUrl = function (media) {
        return typeof media !== 'object' && media.indexOf('http') > -1;
    };
    PagePandoraPage2Component.prototype.getImageProxyUrl = function (url) {
        var size = "s:200:150:1:1";
        var rt = 'fit';
        return this.nova.getImgUrl() + "/insecure/rt:" + rt + "/" + size + "/g:sm/plain/" + url;
    };
    PagePandoraPage2Component.prototype.filterItemsOfType = function (type) {
        return this.data.medias.filter(function (x) { return x.placement == type; });
    };
    PagePandoraPage2Component = tslib_1.__decorate([
        NovaTemplate('PagePandoraPage2Component'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            Meta,
            Title,
            Router,
            ActivatedRoute])
    ], PagePandoraPage2Component);
    return PagePandoraPage2Component;
}(Page));
export { PagePandoraPage2Component };
