﻿import { ServerArticleScrollComponent } from 'app/pages/templates_server/article-scroll/article-scroll.component';
import {
    Component,
    ComponentFactoryResolver,
    OnInit,
    Type,
    ViewChild,
    ViewContainerRef,
    OnDestroy
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NovaAPIService } from '../_services/nova-api.service';
import { PageNotFoundComponent } from '../not-found-component';
import { getComponent } from '../_decorators/template.decorator';
import { SSRService } from 'app/_services/ssr.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { NovaTextGalleryComponent } from 'app/_components/nova-text-gallery/nova-text-gallery.component';

@Component({
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit, OnDestroy {
    @ViewChild('dynamicItemView', { read: ViewContainerRef })
    templateContainer: ViewContainerRef;

    private id: number;
    private contentId: number;
    private travelAgency?: string;
    private data: any;
    public hasParent = false;
    public template: string;

    constructor(
        private ssr: SSRService,
        private resolver: ComponentFactoryResolver,
        private nova: NovaAPIService,
        private router: Router,
        private route: ActivatedRoute,
        public uiEventHandler: UIEventHandlerService
    ) {}

    async ngOnInit() {
        this.nova.getCurrentIssue().subscribe(({ travelAgency }) => (this.travelAgency = travelAgency));

        this.nova.getPageData().subscribe(
            data => {
                this.id = data['id'];
                data.data.travelAgency = this.travelAgency;

                if (typeof data['contentId'] !== 'undefined' || typeof data['pageId'] !== 'undefined') {
                    this.contentId = data['contentId'] || data['pageId'];
                }

                this.data = data['data'];
                this.loadComponent(data['data']);
            },
            error => {
                if (error.message === '401') {
                    this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
                } else if (error.message === 'no pages found') {
                    this.router.navigate(['/errorPage'], { queryParamsHandling: 'preserve' });
                } else if (error.message === '423') {
                    return this.router.navigate(['/'], {
                        queryParamsHandling: 'preserve'
                    });
                } else {
                    this.router.navigate(['/error'], { queryParamsHandling: 'preserve' });
                }
            }
        );
    }

    loadComponent(page) {
        const params = this.router.routerState.root.firstChild.params['value'];

        if (!page || !page.templateName) {
            if (page.parentPage) {
                return this.router.navigate(
                    ['/', params['langHandle'], params['issueHandle'], page.parentPage.handle],
                    {
                        queryParamsHandling: 'preserve'
                    }
                );
            } else {
                return this.router.navigate(['/'], {
                    queryParamsHandling: 'preserve'
                });
            }
        }

        this.hasParent = 'pageId' in page;
        this.template = this.data.templateName;

        this.uiEventHandler.topClose = false;
        this.uiEventHandler.showNavArrows = !this.hasParent;

        // find matching component
        const component = this.getComponentMapping(page.templateName);

        // create factory
        const factory = this.resolver.resolveComponentFactory(component);

        // clear container
        this.templateContainer.clear();

        // create component and pass data
        const componentRef = this.templateContainer.createComponent(factory);
        componentRef.instance['data'] = page;
        if (this.hasParent) {
            componentRef.instance['pageId'] = this.id;
            componentRef.instance['id'] = this.contentId;
            this.uiEventHandler.hideTitleBar = true;
        } else {
            componentRef.instance['id'] = this.id;
            this.uiEventHandler.hideTitleBar = false;
        }
    }

    toCamelCase(val) {
        const s = val
            .replace(/\b\w/g, function(m) {
                return m.toUpperCase();
            })
            .replace(/-/g, '');

        return val.charAt(0).toUpperCase() + s.slice(1);
    }

    getComponentMapping(template: string): Type<any> {
        let componentName = 'Page';

        if (!this.ssr.isBrowser) {
            componentName = 'Server';
        }

        componentName += this.toCamelCase(template) + 'Component';

        if (template === 'Pflugfelder - Teilmarkt Index') {
            componentName = 'PagePflugfelderIndexComponent';
        }

        if (template === 'Pflugfelder Contents') {
            componentName = 'PagePflugfelderContentsComponent';
        }

        if (template === 'gebirgslandeplaetze-detailseite') {
            componentName = 'PageGebirgslandeplaetzeDetailComponent';
        }

        const component = getComponent(componentName);

        if (component) {
            return component;
        }

        if (!this.ssr.isBrowser) {
            return ServerArticleScrollComponent;
        }

        return PageNotFoundComponent;
    }

    closeContent(gallery?: NovaTextGalleryComponent) {
        if (gallery && gallery.open) {
            return;
        }

        const params = this.route.params['value'];
        this.uiEventHandler.topClose = false;

        if (this.data && this.data.parentPage && this.data.parentPage.templateName === 'blog-index') {
            history.back();

            return;
        }

        this.router.navigate(['/', params['langHandle'], params['issueHandle'], params['pageHandle']], {
            queryParams: {
                img: null
            },
            queryParamsHandling: 'merge'
        });
    }

    showPrint() {
        if (
            this.nova.issue &&
            !this.data.templateName.includes('-standard') &&
            !this.data.templateName.includes('-premium')
        ) {
            return !this.showPDF() && this.nova.issue.showPrint === 'yes';
        }

        return false;
    }

    showPDF() {
        return this.data && this.data['pdf_version'] ? !!this.data['pdf_version'] : false;
    }

    ngOnDestroy() {
        setTimeout(() => {
            this.uiEventHandler.topClose = false;
        }, 100);
    }
}
