import { Component, OnChanges, Input } from '@angular/core';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { MagazineService } from 'app/_services/magazine.service';

@Component({
    selector: 'nova-fti-overlay-privacy',
    templateUrl: './nova-fti-overlay-privacy.component.html',
    styleUrls: ['./nova-fti-overlay-privacy.component.scss']
})
export class NovaFtiOverlayPrivacyComponent implements OnChanges {
    @Input() magazine;
    @Input() issue;
    @Input() public font;

    public showDefault = false;
    public showCustom = false;
    public defaultTermsText;
    public termsText;

    constructor(public nova: NovaAPIService, public mag: MagazineService) {}

    ngOnChanges() {
        if (this.magazine.legalType !== 'custom') {
            this.showDefault = true;
        }

        if (this.magazine.legalType !== 'default') {
            this.showCustom = true;
        }

        this.defaultTermsText = this.magazine.defaultLegalText;
        this.termsText = this.issue.termsText || this.magazine.termsText;
    }
}
