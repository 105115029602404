/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nova-newsletter-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../nova-nav-item/nova-nav-item.component.ngfactory";
import * as i3 from "../nova-nav-item/nova-nav-item.component";
import * as i4 from "../../_services/nova-api.service";
import * as i5 from "../../_services/magazine.service";
import * as i6 from "../../_services/uievent-handler.service";
import * as i7 from "@angular/platform-browser";
import * as i8 from "./nova-newsletter-popup.component";
var styles_NovaNewsletterPopupComponent = [i0.styles];
var RenderType_NovaNewsletterPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NovaNewsletterPopupComponent, data: {} });
export { RenderType_NovaNewsletterPopupComponent as RenderType_NovaNewsletterPopupComponent };
export function View_NovaNewsletterPopupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { newsletterFrom: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "nova-newsletter-popup"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "nova-nav-item", [["class", "icon-cancel my-close-icon"]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onMouseEnter($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onMouseLeave($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4).onTouchStart($event.target) !== false);
        ad = (pd_2 && ad);
    } if (("touchend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4).onTouchEnd($event.target) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.close() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_NovaNavItemComponent_0, i2.RenderType_NovaNavItemComponent)), i1.ɵdid(4, 245760, null, 0, i3.NovaNavItemComponent, [i4.NovaAPIService, i5.MagazineService, i6.UIEventHandlerService, i7.DomSanitizer, i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵeld(5, 0, [[1, 0], ["newsletterFrom", 1]], null, 0, "div", [["id", "newsletterFrom"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 4, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.uiEventHandler.showNewsletterForm; _ck(_v, 1, 0, currVal_0); }); }
export function View_NovaNewsletterPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-newsletter-popup", [], null, null, null, View_NovaNewsletterPopupComponent_0, RenderType_NovaNewsletterPopupComponent)), i1.ɵdid(1, 245760, null, 0, i8.NovaNewsletterPopupComponent, [i4.NovaAPIService, i5.MagazineService, i6.UIEventHandlerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NovaNewsletterPopupComponentNgFactory = i1.ɵccf("nova-newsletter-popup", i8.NovaNewsletterPopupComponent, View_NovaNewsletterPopupComponent_Host_0, {}, {}, []);
export { NovaNewsletterPopupComponentNgFactory as NovaNewsletterPopupComponentNgFactory };
