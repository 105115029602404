import { OnInit, OnDestroy, EventEmitter, SimpleChanges } from '@angular/core';
import { NovaAPIService } from '../../_services/nova-api.service';
import { Router, NavigationEnd } from '@angular/router';
import { MagazineService } from '../../_services/magazine.service';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import { ColorFormatter } from '../../novamag-api/Types';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';
import { StripeService } from 'app/_services/stripe.service';
var NovaLondonTitleBarComponent = /** @class */ (function () {
    function NovaLondonTitleBarComponent(nova, router, mag, _snackBar, ui, translate, stripe) {
        this.nova = nova;
        this.router = router;
        this.mag = mag;
        this._snackBar = _snackBar;
        this.ui = ui;
        this.translate = translate;
        this.stripe = stripe;
        this.openOverlay = new EventEmitter();
        this.pageChange = new EventEmitter();
        this.hovering = false;
    }
    NovaLondonTitleBarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sub = this.mag.getMagazine().subscribe(function (data) {
            _this.magData = data;
            _this.nav = data.navigation;
            _this.selectStartPage = data.selectStartPage === 'archiv';
            _this.subFont = _this.nova.getFont(data.navigation.font).subscribe(function (fontData) {
                _this.font = fontData.name;
            });
        });
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                _this.getCurrentPage();
            }
        });
        this.getCurrentPage();
    };
    NovaLondonTitleBarComponent.prototype.ngOnChanges = function (changes) {
        if (changes.issue && changes.issue.currentValue !== changes.issue.previousValue) {
            this.getCurrentPage();
        }
    };
    NovaLondonTitleBarComponent.prototype.getCurrentPage = function () {
        var _this = this;
        var params = this.router.routerState.root.firstChild.params['value'];
        var pageHandle = params.pageHandle;
        this.infoPage = this.nova.issue.infoPage;
        this.homeLinks = this.nova.issue.homeLinks;
        this.shareIcon = this.nova.issue.shareIcon;
        if (pageHandle) {
            this.nova.pages.forEach(function (page, index) {
                if (page.handle === pageHandle) {
                    _this.currentPage = index;
                }
            });
            this.pageNum = this.nova.pages.length;
        }
    };
    Object.defineProperty(NovaLondonTitleBarComponent.prototype, "pageTitle", {
        get: function () {
            return this.ui.getContentTableEntryName(this.nova.pages[this.currentPage]);
        },
        enumerable: true,
        configurable: true
    });
    NovaLondonTitleBarComponent.prototype.openLondonOverlay = function () {
        this.openOverlay.emit();
    };
    NovaLondonTitleBarComponent.prototype.getColor = function (c) {
        return new ColorFormatter(c).rgba();
    };
    NovaLondonTitleBarComponent.prototype.getLogoPadding = function (logoSize) {
        var maxPadding = window.innerWidth > 768 ? 25 : 20;
        var calculatedPadding = (maxPadding * Number(logoSize.replace('%', ''))) / 100;
        var yPadding = logoSize ? maxPadding - calculatedPadding : 10;
        return yPadding + "px 20px";
    };
    NovaLondonTitleBarComponent.prototype.mobileBG = function () {
        return new ColorFormatter(this.nav.backgroundColor).rgba();
    };
    NovaLondonTitleBarComponent.prototype.ngOnDestroy = function () {
        this.sub && this.sub.unsubscribe();
        this.subFont && this.subFont.unsubscribe();
        this.subRouter && this.subRouter.unsubscribe();
        this.logoSub && this.logoSub.unsubscribe();
    };
    NovaLondonTitleBarComponent.prototype.goToInfoPage = function (pageId) {
        var pageIndex = this.nova.pages.findIndex(function (page) { return page._id === pageId; });
        this.pageChange.emit(pageIndex + 1);
    };
    NovaLondonTitleBarComponent.prototype.goToUrl = function (url) {
        var currentPage = this.issue.pages[this.currentPage];
        if (currentPage.templateName === 'blog-index') {
            this.router.navigate([this.router.url.substring(0, this.router.url.lastIndexOf('/'))]);
            return;
        }
        this.router.navigate([url], {
            queryParamsHandling: 'preserve'
        });
    };
    NovaLondonTitleBarComponent.prototype.toggleShareMenu = function () {
        this.hovering = !this.hovering;
    };
    NovaLondonTitleBarComponent.prototype.share = function (type) {
        var _this = this;
        var url = window.location.href;
        var shareUrl = encodeURIComponent(url);
        this.translate.get(['MAIL_SUBJECT', 'MAIL_BODY']).subscribe(function (res) {
            var mailSubject = res['MAIL_SUBJECT'];
            var mailBody = res['MAIL_BODY'];
            switch (type) {
                case 'mail':
                    window.location.href = 'mailto:?&subject=' + mailSubject + '&body=' + mailBody + shareUrl;
                    break;
                case 'whatsapp':
                    window.location.href = 'whatsapp://send?text=' + mailBody + shareUrl;
                    break;
                case 'copy':
                    // @ts-ignore needed because of old ts version
                    window.navigator.clipboard.writeText(url);
                    _this.translate.get('COPIED_URL').subscribe(function (res) {
                        var urlMessage = res;
                        _this._snackBar.open(urlMessage, null, {
                            duration: 3000,
                            verticalPosition: 'top',
                            horizontalPosition: 'right'
                        });
                    });
                    break;
                default:
                    break;
            }
        });
    };
    return NovaLondonTitleBarComponent;
}());
export { NovaLondonTitleBarComponent };
