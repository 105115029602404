import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { ColorFormatter } from 'app/novamag-api/Types';
import { NovaGalleryPopupComponent } from 'app/_components/nova-gallery-popup/nova-gallery-popup.component';
import { MediaService } from 'app/_services/media.service';
import { Router, ActivatedRoute } from '@angular/router';
function createFlexPositionStyle(position, variant) {
    if (variant === void 0) { variant = 'boxed'; }
    var elementStyle = {};
    var positionArray = position.split('-');
    // Vertical alignment
    if (positionArray[0] === 'top') {
        elementStyle['align-items'] = 'flex-start';
    }
    else if (positionArray[0] === 'bottom') {
        elementStyle['align-items'] = 'flex-end';
    }
    else if (positionArray[0] === 'center') {
        elementStyle['align-items'] = 'center';
    }
    // Horizontal alignment
    if (positionArray[1] === 'left') {
        elementStyle['justify-content'] = 'flex-start';
        if (variant === 'expanded') {
            elementStyle['left'] = 0;
        }
    }
    else if (positionArray[1] === 'right') {
        elementStyle['justify-content'] = 'flex-end';
        if (variant === 'expanded') {
            elementStyle['right'] = 0;
        }
    }
    else if (positionArray[1] === 'center') {
        elementStyle['justify-content'] = 'center';
        if (variant === 'expanded') {
            elementStyle['left'] = 0;
            elementStyle['right'] = 0;
            elementStyle['margin'] = 'auto';
        }
    }
    return elementStyle;
}
function createProductGridColumnStyle(productCount, isMobile) {
    if (isMobile) {
        return "repeat(2, minmax(30%, 1fr))";
    }
    if (productCount === 1) {
        return "0.5fr 1fr 0.5fr";
    }
    if (productCount === 2) {
        return "repeat(2, 1fr)";
    }
    if (productCount >= 3) {
        return "repeat(auto-fit, minmax(30%, 1fr))";
    }
}
var PageProductGalleryComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageProductGalleryComponent, _super);
    function PageProductGalleryComponent(nova, uiEventHandler, sanitizer, meta, title, mediaService, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.sanitizer = sanitizer;
        _this.meta = meta;
        _this.title = title;
        _this.mediaService = mediaService;
        _this.route = route;
        _this.router = router;
        _this.detail = false;
        _this.mobile = false;
        _this.original = false;
        return _this;
    }
    PageProductGalleryComponent.prototype.ngOnInit = function () {
        this.logoStyle = tslib_1.__assign({}, createFlexPositionStyle(this.data['logoPosition']), { display: 'flex' });
        this.logoSize = { width: this.data['logoSize'] + "%" };
        this.flexboxStyle = createFlexPositionStyle(this.data['contentPosition'], this.data['box_variant']);
        this.mobile = $('body').width() <= 1024 || $('body').height() < 650;
        if (!this.data.products) {
            return;
        }
        this.sliderProducts = this.data.products
            .map(function (product) { return (product.expand === 'yes' || product.buttonText || !product.link ? product : null); })
            .filter(Boolean);
        this.gridStyle = {};
        this.gridStyle['grid-template-columns'] = createProductGridColumnStyle(this.data.products.length, this.mobile);
    };
    PageProductGalleryComponent.prototype.setMyStyles = function (obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    };
    PageProductGalleryComponent.prototype.open = function (id) {
        var _this = this;
        var index = this.sliderProducts.findIndex(function (product) { return product._id === id; });
        this.detail = true;
        this.uiEventHandler.disableNav = true;
        this.slider.goTo(index);
        setTimeout(function () {
            _this.slider.resize();
        }, 100);
        this.uiEventHandler.hideSwipeOverlay();
    };
    PageProductGalleryComponent.prototype.close = function () {
        this.detail = false;
        this.uiEventHandler.disableNav = false;
        $('#mute-button').fadeIn();
    };
    PageProductGalleryComponent.prototype.getItemStyle = function () {
        if ((this.data._id === 'D4gwafRzPFtzjQeYa' ||
            this.data._id === 'N2XCDjjHELb6K39BW' ||
            this.data._id === 'pWKMQYwnBqcdJHB3W') &&
            this.data['background_color']) {
            var c = new ColorFormatter(this.data['background_color']);
            return {
                'background-color': c.rgba()
            };
        }
        return {};
    };
    PageProductGalleryComponent.prototype.getButtonStyling = function (product, hover) {
        if (hover === void 0) { hover = false; }
        // We need the defaults to be downwards compatible
        var defaultPrimaryHoverColor = { r: 0, g: 0, b: 0, a: 1 };
        var defaultSecondaryHoverColor = { r: 255, g: 255, b: 255, a: 1 };
        var isHovered = hover === product._id;
        var c = isHovered
            ? product['buttonColorSecondaryHover'] || defaultSecondaryHoverColor
            : product['buttonColorPrimary'];
        var c2 = isHovered
            ? product['buttonColorPrimaryHover'] || defaultPrimaryHoverColor
            : product['buttonColorSecondary'];
        var borderColor = product['buttonColorPrimary'];
        if (c && c2) {
            return {
                'border-color': "rgba(" + borderColor.r + ", " + borderColor.g + ", " + borderColor.b + ", " + borderColor.a + ")",
                'background-color': "rgba(" + c.r + ", " + c.g + ", " + c.b + ", " + c.a + ")",
                color: "rgba(" + c2.r + ", " + c2.g + ", " + c2.b + ", " + c2.a + ")"
            };
        }
        else {
            return null;
        }
    };
    PageProductGalleryComponent.prototype.getIsClickable = function (product) {
        var expand = product.expand, link = product.link;
        if (expand === 'yes' || link) {
            return 'clickable';
        }
    };
    PageProductGalleryComponent.prototype.ngOnDestroy = function () {
        this.arrowSub && this.arrowSub.unsubscribe();
    };
    PageProductGalleryComponent = tslib_1.__decorate([
        NovaTemplate('PageProductGalleryComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            DomSanitizer,
            Meta,
            Title,
            MediaService,
            ActivatedRoute,
            Router])
    ], PageProductGalleryComponent);
    return PageProductGalleryComponent;
}(Page));
export { PageProductGalleryComponent };
