<div id="page-{{ id }}">
	<nova-scrollbar-holder>
	    <div class="scroller">
		<!-- Header -->
		<div class="grt__pandora-fullsize-header-overflow">
			<div class="grt__pandora-multi-img-header">
				<div class="grt__pandora-multi-img-header-wrapper">
					<div class="grt__pandora-multi-img-header-1">
						<img *ngIf="data.headerimage1 && isUrl(data.headerimage1)" [src]="getImageProxyUrl(data.headerimage1)">
						<nova-media *ngIf="data.headerimage1 && !isUrl(data.headerimage1)" [mediaId]="data.headerimage1" version="small" [bg]="false" [fallback]="true"></nova-media>
					</div>
					<div class="grt__pandora-multi-img-header-2">
						<img *ngIf="data.headerimage2 && isUrl(data.headerimage2)" [src]="getImageProxyUrl(data.headerimage2)">
						<nova-media *ngIf="data.headerimage2 && !isUrl(data.headerimage2)" [mediaId]="data.headerimage2" version="small" [bg]="false" [fallback]="true"></nova-media>
					</div>
					<div class="grt__pandora-multi-img-header-3">
						<img *ngIf="data.headerimage3 && isUrl(data.headerimage3)" [src]="getImageProxyUrl(data.headerimage3)">
						<nova-media *ngIf="data.headerimage3 && !isUrl(data.headerimage3)" [mediaId]="data.headerimage3" version="small" [bg]="false" [fallback]="true"></nova-media>
					</div>
					<div class="grt__pandora-multi-img-header-4">
						<img *ngIf="data.headerimage4 && isUrl(data.headerimage4)" [src]="getImageProxyUrl(data.headerimage4)">
						<nova-media *ngIf="data.headerimage4 && !isUrl(data.headerimage4)" [mediaId]="data.headerimage4" version="small" [bg]="false" [fallback]="true"></nova-media>
					</div>
					<div class="grt__pandora-multi-img-header-headline">
						<img *ngIf="data.headerheadlineimage && isUrl(data.headerheadlineimage)" [src]="getImageProxyUrl(data.headerheadlineimage)">
						<nova-media *ngIf="data.headerheadlineimage && !isUrl(data.headerheadlineimage)" [mediaId]="data.headerheadlineimage" version="small" [bg]="false" [fallback]="true"></nova-media>
					</div>
				</div>
				<div class="scroll-down" (click)="scrollDown()"></div>
			</div>
		</div>


		<!-- second intro -->
		<div class="grt__pandora-container grt__pandora-bg">
			<div class="grt__pandora-row grt__pandora-align-items-center">
				<div class="grt__pandora-col">
					<p><nova-text-element class="con" [data]="data.text" [myid]="data._id" ></nova-text-element></p>
				</div>
				<div class="grt__pandora-col">
					<div class="grt__pandora-images-overlap">
						<div class="grt__pandora-images-overlap-image1">
							<img *ngIf="data.image1 && isUrl(data.image1)" [src]="getImageProxyUrl(data.image1)">
							<nova-media *ngIf="data.image1 && !isUrl(data.image1)" [mediaId]="data.image1" version="small" [bg]="false" [fallback]="true"></nova-media>
						</div>
						<div class="grt__pandora-images-overlap-image2">
							<img *ngIf="data.image2 && isUrl(data.image2)" [src]="getImageProxyUrl(data.image2)">
							<nova-media *ngIf="data.image2 && !isUrl(data.image2)" [mediaId]="data.image2" version="small" [bg]="false" [fallback]="true"></nova-media>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="grt__pandora-spacing"></div>

		<!-- Content -->
		<div class="grt__pandora-container">
			<div class="grt__pandora-row grt__pandora-align-items-center">
				<div class="grt__pandora-col">
					<div class="grt__pandora-media">
						<div class="grt__pandora-media-content">
							<nova-media [bg]="true" [mediaId]="data.video" [forceVideo]="true"></nova-media>
						</div>
					</div>
				</div>
				<div class="grt__pandora-col">
					<p><nova-text-element class="con" [data]="data.text2" [myid]="data._id" ></nova-text-element></p>
				</div>
			</div>
		</div>

		<div class="grt__pandora-spacing"></div>

		<!-- PRODUKTE HEADLINE -->
		<div class="grt__pandora-container grt__pandora-bg">
			<div class="grt__pandora-row justify-content-center">
				<div class="grt__pandora-col grt__pandora-single-col">
					<p><nova-text-element class="con" [data]="data.text3" [myid]="data._id" ></nova-text-element></p>
			</div>
		</div>

		<div class="grt__pandora-spacing-small"></div>

		<!-- Produkte -->
		<div class="grt__pandora-container">
			<div class="grt__pandora-katalog grt__pandora-row">

				<div *ngFor="let product of data.medias" class="grt__pandora-katalog-item">
					<a href="{{ product.link }}" target="_blank">
					<div class="grt__pandora-katalog-item-img">
						<img *ngIf="product.media && isUrl(product.media)" [src]="getImageProxyUrl(product.media)">
						<nova-media *ngIf="product.media && !isUrl(product.media)" [mediaId]="product.media" version="small" [bg]="false" [fallback]="true"></nova-media>
					</div>
					<div class="grt__pandora-katalog-item-content">
						<div class="grt__pandora-katalog-item-tag">
							<nova-element [data]="product" name="tag"></nova-element>
						</div>
						<div class="grt__pandora-katalog-item-name">
							<nova-element [data]="product" name="name"></nova-element>
						</div>
						<div class="grt__pandora-katalog-item-price">
							<nova-element [data]="product" name="price"></nova-element>
						</div>
					</div>
					</a>
				</div>

			</div>
		</div>

		<div class="grt__pandora-spacing"></div>

		<!-- Intro -->
		<div class="grt__pandora-container grt__pandora-bg">
			<div class="grt__pandora-row justify-content-center">
				<div class="grt__pandora-col grt__pandora-single-col">
					<p><nova-text-element class="con" [data]="data.text4" [myid]="data._id" ></nova-text-element></p>
				</div>
			</div>
		</div>

		<div class="grt__pandora-spacing"></div>

		<div class="grt__pandora-container">
			<div class="grt__pandora-row grt__pandora-align-items-center">
				<div class="grt__pandora-col">
					<div class="grt__pandora-media">
						<div class="grt__pandora-media-content">
							<img *ngIf="data.image3 && isUrl(data.image3)" [src]="getImageProxyUrl(data.image3)">
							<nova-media *ngIf="data.image3 && !isUrl(data.image3)" [mediaId]="data.image3" version="small" [bg]="false" [fallback]="true"></nova-media>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="grt__pandora-spacing"></div>

		</div>
	</div>
</nova-scrollbar-holder>
