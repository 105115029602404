import { Component, ViewEncapsulation } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import { SharedHotelHelperService } from '../hotel-detail-standard/shared-hotel-helper.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './hotel-detail-premium.component.html',
    styleUrls: ['./hotel-detail-premium.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [SharedHotelHelperService]
})
@NovaTemplate('PageHotelDetailPremiumComponent')
export class PageHotelDetailPremiumComponent extends Page {
    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public media: MediaService,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }
}
