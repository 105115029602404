import { setTimeout } from 'timers';
import { Meta, Title } from '@angular/platform-browser';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import { Subscription } from 'rxjs';
import { MagazineService } from '../../_services/magazine.service';
import { NovaAPIService } from '../../_services/nova-api.service';
import { OnDestroy } from '@angular/core';

export class ArchiveTemplate implements OnDestroy {
    public issues: any;
    public magazine;
    public font;

    private sub: Subscription;
    private subFont: Subscription;

    constructor(
        public mag: MagazineService,
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService
    ) {
        this.sub = this.mag.getMagazine().subscribe(mag => {
            this.magazine = mag;
            this.subFont = this.nova.getFont(this.magazine.navigation.font).subscribe(fontData => {
                this.font = fontData.name;
            });
        });
    }

    getStyleTextInv(issue) {
        if (issue.archiveStyle === 'white') {
            return '#fff';
        }
        return '#000';
    }

    getStyleText(issue) {
        if (issue.archiveStyle === 'white') {
            return '#000';
        }
        return '#fff';
    }

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe();
        if (this.subFont) this.subFont.unsubscribe();
    }
}
