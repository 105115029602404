import {
    Component,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
    ViewContainerRef,
    ViewChild,
    ComponentFactoryResolver
} from '@angular/core';
import { NovaAPIService } from '../_services/nova-api.service';
import { Router } from '@angular/router';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { MagazineService } from 'app/_services/magazine.service';
import { Subscription } from 'rxjs';
import { MediaService } from 'app/_services/media.service';
import { ArchiveScrollComponent } from './templates/archive-scroll/archive-scroll.component';
import { ArchiveGridComponent } from './templates/archive-grid/archive-grid.component';
import { UIEventHandlerService } from '../_services/uievent-handler.service';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LandingPageComponent implements OnInit, OnDestroy {
    public font;
    public magazine;
    public logo;
    public issues;
    private sub: Subscription;
    private subFont: Subscription;

    @ViewChild('dynamicItemView', { read: ViewContainerRef })
    templateContainer: ViewContainerRef;

    constructor(
        public nova: NovaAPIService,
        public ui: UIEventHandlerService,
        private resolver: ComponentFactoryResolver,
        private mag: MagazineService,
        private media: MediaService,
        private router: Router,
        public meta: Meta,
        public title: Title,
        public sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        this.sub = this.mag.getIssues().subscribe(issuesData => {
            this.issues = this.nova.filterIssuesByLang(issuesData.issues);
            this.magazine = issuesData.magazine;
            this.logo = this.magazine.logo && this.magazine.logo.url;

            if (this.issues[0]) {
                const val = this.issues[0];
                if (val.archiveBackground || val.background_media) {
                    this.media.getMediaUrl(val.archiveBackground || val.background_media, 'thumb').subscribe(data => {
                        this.nova.setMetaData(this.magazine.name, val['metaDescription'], data, val['metaKeywords']);
                    });
                } else {
                    this.nova.setMetaData(this.magazine.name, val['metaDescription'], null, val['metaKeywords']);
                }
            }

            this.subFont = this.nova.getFont(this.magazine.navigation.font).subscribe(fontData => {
                this.font = fontData.name;
            });

            if (this.issues && this.issues.length > 0) {
                if (this.magazine.selectStartPage === 'archiv') {
                    let component = ArchiveScrollComponent;
                    if (this.magazine.archiveTheme === 'archive-grid') {
                        component = ArchiveGridComponent;
                    }

                    // create component and attach it to landingpage
                    const factory = this.resolver.resolveComponentFactory(component);
                    this.templateContainer.clear();
                    const componentRef = this.templateContainer.createComponent(factory);
                    componentRef.instance['issues'] = this.issues;
                } else {
                    let foundHandle = '';
                    this.issues.forEach(data => {
                        if (data._id === this.magazine.selectStartPage) {
                            if (data['handle']) {
                                foundHandle = data.handle;
                            } else {
                                const first = Object.keys(data.languagesMap)[0];
                                foundHandle = data.languagesMap[first].handle;
                                this.nova.lang = data.languagesMap[first].language;
                            }
                        }
                    });
                    if (foundHandle !== '') {
                        this.router.navigate(['/', this.nova.lang, foundHandle], { queryParamsHandling: 'preserve' });
                        return;
                    }
                }
            }
        });
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }

        if (this.subFont) {
            this.subFont.unsubscribe();
        }
    }
}
