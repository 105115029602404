import * as tslib_1 from "tslib";
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import { ActivatedRoute, Router } from '@angular/router';
var PageHotelDetailPremiumComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageHotelDetailPremiumComponent, _super);
    function PageHotelDetailPremiumComponent(nova, uiEventHandler, sanitizer, meta, media, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.sanitizer = sanitizer;
        _this.meta = meta;
        _this.media = media;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        return _this;
    }
    PageHotelDetailPremiumComponent = tslib_1.__decorate([
        NovaTemplate('PageHotelDetailPremiumComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            DomSanitizer,
            Meta,
            MediaService,
            Title,
            ActivatedRoute,
            Router])
    ], PageHotelDetailPremiumComponent);
    return PageHotelDetailPremiumComponent;
}(Page));
export { PageHotelDetailPremiumComponent };
