<div id="page-{{ id }}" class="page page-portrait top" [ngStyle]="{'backgroundColor':getColor()}">
  <div id="container" class="my-container intro-effect-fadeout">
    <div class="scroller">
      <header class="header">
        <div class="flexbox">
          <div class="flex-item flex-helper" [ngStyle]="setMyStyles(helperStyle)"></div>
          <div class="flex-item" [ngStyle]="setMyStyles(logoStyle)">
            <h3 *ngIf="data.label" class="dateline"><nova-element [data]="data" name="label"></nova-element></h3>
            <h1 *ngIf="data.header"><nova-element [data]="data" name="header"></nova-element></h1>
            <h2 *ngIf="data.subHeader"><nova-element [data]="data" name="subHeader"></nova-element></h2>
            <nova-content-links class="content-links" *ngIf="data.links && data.links.length > 0" [floors]="data.links"></nova-content-links>
          </div>
        </div>
        <div class="bg-img"><nova-bg [data]="data" [forceVideo]="true"></nova-bg></div>
      </header>
    </div>
  </div>
</div>
