<div id="page-{{ id }}" class="page page-image-classic">
	<nova-bg [data]="data" [forceVideo]="true"></nova-bg>
	<nova-nav-border-item class="icon-cancel my-close-icon" [hidden]="!detail || mobile" (click)="close()"></nova-nav-border-item>
	<nova-scrollbar-holder>
		<div class="scroller">
			<div class="row overview" [hidden]="detail">
				<div class="col-lg-3 col-md-4">
					<nova-mobile-img [data]="data" class="show-for-small-only left"></nova-mobile-img>
					<div class="api-title clear" [class.padTop]="!data.background_media">
						<h3 *ngIf="data.label" class="dateline"
							[ngStyle]="{ 'background-color': getColor(data['box_color_top']) }">
							<nova-element [data]="data" name="label"></nova-element>
						</h3>
						<div *ngIf="data.header || data.subHeader" class="headline"
							[ngStyle]="{ 'background-color': getColor(data['box_color_top']) }">
							<h1 *ngIf="data.header">
								<nova-element [data]="data" name="header"></nova-element>
							</h1>
							<h2 *ngIf="data.subHeader">
								<nova-element [data]="data" name="subHeader"></nova-element>
							</h2>
						</div>
					</div>
					<div class="text" [ngStyle]="{ 'background-color': getColor(data['box_color_bottom']) }">
						<nova-text-element class="con" [data]="data.text" (openGallery)="gallery.openSlider($event)">
						</nova-text-element>
						<nova-content-links class="content-links" *ngIf="data.links && data.links.length > 0"
							[floors]="data.links"></nova-content-links>
					</div>
				</div>
				<div class="col-lg-9 col-md-8 img-con" *ngIf="media">
					<div class="row">
						<div class="col-md-3 col-lg-2 col-sm-3 col-xs-6" *ngFor="let val of media; let key = index">
							<div class="img-bg" (click)="open(key)">
								<div class="img-placeholder img-placeholder-43">
									<img [src]="mediaService.getMediaUrlByData(thumbnails && thumbnails[key] || val, 'thumbnail')"
										data-id="{{ key }}" width="400px" height="300px" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="detail" *ngIf="media" [hidden]="!detail || mobile">
				<section id="dg-container" class="dg-container" #dg>
					<div class="dg-wrapper">
						<div *ngFor="let val of media">
							<nova-media [version]="getVersion()" [mediaId]="val.org.media" [copyright]="false">
							</nova-media>
							<div>
								<div class="caption">
                                    <div style="display:flex;gap:10px;">
										<nova-element-small *ngIf="val.org.name || val.filename" [data]="val.org" name="name"></nova-element-small>
									</div>
									<nova-element-small *ngIf="val.org.description" [data]="val.org" name="description"></nova-element-small>
									<a *ngIf="val.org.link" [href]="val.org.link" style="margin-left:0;margin-top:10px;">
										<nova-element-small [data]="val.org" name="link_text"></nova-element-small>
									</a>
									<span *ngIf="val.copyright && !getCopyrightUrl(val.copyrightUrl)">&copy; {{ val.copyright }}</span>
									<a target="_blank" *ngIf="getCopyrightUrl(val.copyrightUrl)" [href]="val.copyrightUrl">
										&copy; {{ val.copyright || val.copyrightUrl }}
									</a>
								</div>
							</div>
						</div>
					</div>
					<div class="dg-nav">
						<div (click)="desktopGalleryNav('left')"><i class="icon-arrow icon-arrow-left"></i></div>
						<div (click)="desktopGalleryNav('right')"><i class="icon-arrow icon-arrow-right"></i></div>
					</div>
				</section>
			</div>
		</div>
	</nova-scrollbar-holder>

	<nova-text-gallery [medias]="data.textImages" #gallery></nova-text-gallery>
</div>

<div [ngStyle]="getItemStyle()" id="page-{{ id }}-slider" [hidden]="!detail || !mobile"
	class="page-image-classic-slider">
	<nova-nav-border-item class="icon-cancel my-close-icon" (click)="close()"></nova-nav-border-item>
	<nova-gallery-popup [original]="original" #slider [showArrows]="false" [con]="data.medias"></nova-gallery-popup>
	<div class="dg-nav">
		<div (click)="desktopGalleryNav('left')"><i class="icon-arrow icon-arrow-left"></i></div>
		<div (click)="desktopGalleryNav('right')"><i class="icon-arrow icon-arrow-right"></i></div>
	</div>
</div>
