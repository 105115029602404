import * as tslib_1 from "tslib";
import { DomSanitizer } from '@angular/platform-browser';
import * as $ from 'jquery';
import { UIEventHandlerService } from './uievent-handler.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "./uievent-handler.service";
var defaultConfig = {
    type: 'default'
};
var CookieService = /** @class */ (function () {
    function CookieService(sanitizer, uiEventHandler) {
        this.sanitizer = sanitizer;
        this.uiEventHandler = uiEventHandler;
        this.config = defaultConfig;
        this.language = 'de';
        this.hasMappedCustomServices = false;
    }
    CookieService.prototype.init = function (language, serviceConfig, trackingProvider) {
        if (language === void 0) { language = 'de'; }
        this.language = language;
        if (!serviceConfig || serviceConfig.type === 'default') {
            this.injectDefaultScripts();
            var cookieConsent = {};
            try {
                var consentCookie = this.uiEventHandler.getCookie('CookieConsent');
                if (consentCookie) {
                    cookieConsent = JSON.parse(consentCookie);
                }
            }
            catch (e) {
                console.log('CookieConsent Error', e);
            }
            return;
        }
        if (serviceConfig.type === 'usercentrics') {
            if (!serviceConfig.userId) {
                throw new Error('No userCentrics Id given!');
            }
            this.config = serviceConfig;
            $('head').append("\n                <meta data-privacy-proxy-server = \"https://privacy-proxy-server.usercentrics.eu\">\n                <script id=\"usercentrics-cmp\" data-settings-id=\"" + serviceConfig.userId + "\" src=\"https://app.usercentrics.eu/browser-ui/latest/bundle.js\" defer></script>\n                <script type=\"application/javascript\" src=\"https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js\"></script>");
            this.injectUserCentricsScripts();
        }
    };
    CookieService.prototype.createCookieCompliantContent = function (content) {
        if (!content) {
            return;
        }
        return this.sanitizer.bypassSecurityTrustHtml(content);
    };
    CookieService.prototype.injectDefaultScripts = function () {
        var scripts = "<script async defer type=\"application/javascript\">(function(d, s, id){\n            var js, fjs = d.getElementsByTagName(s)[0];\n            if (d.getElementById(id)) {return;}\n            js = d.createElement(s); js.id = id;\n            js.src = \"https://connect.facebook.net/en_US/sdk.js\";\n            fjs.parentNode.insertBefore(js, fjs);\n          }(document, 'script', 'facebook-jssdk'));</script>\n        <script async defer type=\"application/javascript\" src=\"https://platform.instagram.com/en_US/embeds.js\"></script>\n        <script async defer type=\"application/javascript\" src=\"https://cdn.embedly.com/widgets/platform.js\" charset=\"utf-8\"></script>";
        $('head').append(scripts);
    };
    CookieService.prototype.injectUserCentricsScripts = function () {
        var scripts = "<script async defer type=\"text/plain\" data-type=\"application/javascript\" data-usercentrics=\"Facebook\" data-name=\"facebook\">(function(d, s, id){\n            var js, fjs = d.getElementsByTagName(s)[0];\n            if (d.getElementById(id)) {return;}\n            js = d.createElement(s); js.id = id;\n            js.src = \"https://connect.facebook.net/en_US/sdk.js\";\n            fjs.parentNode.insertBefore(js, fjs);\n          }(document, 'script', 'facebook-jssdk'));</script>\n        <script async defer type=\"text/plain\" data-type=\"application/javascript\" data-usercentrics=\"Instagram\" data-name=\"instagram\" src=\"https://platform.instagram.com/en_US/embeds.js\"></script>\n        <script async defer type=\"text/plain\" data-type=\"application/javascript\" data-usercentrics=\"Embedly\" data-name=\"embedly\" src=\"https://cdn.embedly.com/widgets/platform.js\" charset=\"utf-8\"></script>\n        ";
        $('head').append(scripts);
    };
    CookieService.prototype.mapUserCentricsCustomServices = function (serviceConfig) {
        var _a;
        if (!serviceConfig || serviceConfig.type === 'default' || this.hasMappedCustomServices) {
            return;
        }
        if (window &&
            window.uc &&
            serviceConfig.customServiceIds &&
            serviceConfig.customServiceIds.length > 0) {
            var mappedServiceIds = serviceConfig.customServiceIds.map(function (service) {
                var _a;
                return (_a = {},
                    _a[service.standardId] = service.customId,
                    _a);
            });
            (_a = window.uc).setServiceAlias.apply(_a, tslib_1.__spread(mappedServiceIds));
            // prevent re-rendering
            this.hasMappedCustomServices = true;
        }
    };
    CookieService.ngInjectableDef = i0.defineInjectable({ factory: function CookieService_Factory() { return new CookieService(i0.inject(i1.DomSanitizer), i0.inject(i2.UIEventHandlerService)); }, token: CookieService, providedIn: "root" });
    return CookieService;
}());
export { CookieService };
