import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './video-cinema.component.html',
    styleUrls: ['../video-classic/video-classic.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageVideoCinemaComponent')
export class PageVideoCinemaComponent extends Page implements OnInit {
    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
        this.customPhantomEvent = true;
    }

    ngOnInit() {
        if (typeof window !== 'undefined') {
            setTimeout(() => {
                this.uiEventHandler.onScreenShotEvent(this.data.id);
            }, 2000);
        }
    }
}
