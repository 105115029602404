import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { ColorFormatter } from 'app/novamag-api/Types';
import { NovaGalleryPopupComponent } from 'app/_components/nova-gallery-popup/nova-gallery-popup.component';
import { MediaService } from 'app/_services/media.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

function createFlexPositionStyle(position, variant: 'boxed' | 'expanded' = 'boxed') {
    const elementStyle = {};
    const positionArray = position.split('-');

    // Vertical alignment
    if (positionArray[0] === 'top') {
        elementStyle['align-items'] = 'flex-start';
    } else if (positionArray[0] === 'bottom') {
        elementStyle['align-items'] = 'flex-end';
    } else if (positionArray[0] === 'center') {
        elementStyle['align-items'] = 'center';
    }

    // Horizontal alignment
    if (positionArray[1] === 'left') {
        elementStyle['justify-content'] = 'flex-start';

        if (variant === 'expanded') {
            elementStyle['left'] = 0;
        }
    } else if (positionArray[1] === 'right') {
        elementStyle['justify-content'] = 'flex-end';

        if (variant === 'expanded') {
            elementStyle['right'] = 0;
        }
    } else if (positionArray[1] === 'center') {
        elementStyle['justify-content'] = 'center';

        if (variant === 'expanded') {
            elementStyle['left'] = 0;
            elementStyle['right'] = 0;
            elementStyle['margin'] = 'auto';
        }
    }

    return elementStyle;
}

function createProductGridColumnStyle(productCount, isMobile) {
    if (isMobile) {
        return `repeat(2, minmax(30%, 1fr))`;
    }

    if (productCount === 1) {
        return `0.5fr 1fr 0.5fr`;
    }

    if (productCount === 2) {
        return `repeat(2, 1fr)`;
    }

    if (productCount >= 3) {
        return `repeat(auto-fit, minmax(30%, 1fr))`;
    }
}

@Component({
    templateUrl: './product-gallery.component.html',
    styleUrls: ['./product-gallery.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageProductGalleryComponent')
export class PageProductGalleryComponent extends Page implements OnInit, OnDestroy {
    public logoStyle;
    public logoSize;
    public flexboxStyle;
    public gridStyle;
    public detail = false;
    public mobile = false;
    public media: any[];
    public original = false;
    public sliderProducts;

    @ViewChild('slider') private slider: NovaGalleryPopupComponent;

    private arrowSub: Subscription;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public title: Title,
        public mediaService: MediaService,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    ngOnInit() {
        this.logoStyle = { ...createFlexPositionStyle(this.data['logoPosition']), display: 'flex' };
        this.logoSize = { width: `${this.data['logoSize']}%` };
        this.flexboxStyle = createFlexPositionStyle(this.data['contentPosition'], this.data['box_variant']);
        this.mobile = $('body').width() <= 1024 || $('body').height() < 650;

        if (!this.data.products) {
            return;
        }

        this.sliderProducts = this.data.products
            .map(product => (product.expand === 'yes' || product.buttonText || !product.link ? product : null))
            .filter(Boolean);

        this.gridStyle = {};
        this.gridStyle['grid-template-columns'] = createProductGridColumnStyle(this.data.products.length, this.mobile);
    }

    setMyStyles(obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    }

    open(id) {
        const index = this.sliderProducts.findIndex(product => product._id === id);

        this.detail = true;
        this.uiEventHandler.disableNav = true;
        this.slider.goTo(index);

        setTimeout(() => {
            this.slider.resize();
        }, 100);

        this.uiEventHandler.hideSwipeOverlay();
    }

    close() {
        this.detail = false;
        this.uiEventHandler.disableNav = false;
        $('#mute-button').fadeIn();
    }

    getItemStyle() {
        if (
            (this.data._id === 'D4gwafRzPFtzjQeYa' ||
                this.data._id === 'N2XCDjjHELb6K39BW' ||
                this.data._id === 'pWKMQYwnBqcdJHB3W') &&
            this.data['background_color']
        ) {
            const c = new ColorFormatter(this.data['background_color']);

            return {
                'background-color': c.rgba()
            };
        }

        return {};
    }

    getButtonStyling(product, hover = false) {
        // We need the defaults to be downwards compatible
        const defaultPrimaryHoverColor = { r: 0, g: 0, b: 0, a: 1 };
        const defaultSecondaryHoverColor = { r: 255, g: 255, b: 255, a: 1 };
        const isHovered = hover === product._id;

        const c = isHovered
            ? product['buttonColorSecondaryHover'] || defaultSecondaryHoverColor
            : product['buttonColorPrimary'];
        const c2 = isHovered
            ? product['buttonColorPrimaryHover'] || defaultPrimaryHoverColor
            : product['buttonColorSecondary'];
        const borderColor = product['buttonColorPrimary'];

        if (c && c2) {
            return {
                'border-color': `rgba(${borderColor.r}, ${borderColor.g}, ${borderColor.b}, ${borderColor.a})`,
                'background-color': `rgba(${c.r}, ${c.g}, ${c.b}, ${c.a})`,
                color: `rgba(${c2.r}, ${c2.g}, ${c2.b}, ${c2.a})`
            };
        } else {
            return null;
        }
    }

    getIsClickable(product) {
        const { expand, link } = product;

        if (expand === 'yes' || link) {
            return 'clickable';
        }
    }

    ngOnDestroy() {
        this.arrowSub && this.arrowSub.unsubscribe();
    }
}
