import * as tslib_1 from "tslib";
import { DomSanitizer } from '@angular/platform-browser';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MediaService } from 'app/_services/media.service';
import { ActivatedRoute, Router } from '@angular/router';
var ServerPokerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ServerPokerComponent, _super);
    function ServerPokerComponent(nova, mediaService, sanitizer, renderer, uiEventHandler, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.mediaService = mediaService;
        _this.sanitizer = sanitizer;
        _this.renderer = renderer;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        return _this;
    }
    ServerPokerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.addOgTags();
        var length = Object.keys(this.data.medias).length;
        if (length === 1) {
            this.class = 'slides-1 ';
        }
        else if (length === 2) {
            this.class = 'slides-2 ';
        }
        else if (length === 3) {
            this.class = 'slides-3 ';
        }
        else if (length === 4) {
            this.class = 'slides-4 ';
        }
        else if (length === 5) {
            this.class = 'slides-5 ';
        }
        this.teaser =
            (this.data.contents && this.data.contents.length > 0) ||
                (this.data.text && this.data.text.length > 0) ||
                this.data.header ||
                this.data.subHeader ||
                this.data.label;
        this.mediaService.loadMedia(this.data.medias).then(function (data) {
            _this.media = data;
        });
    };
    ServerPokerComponent.prototype.getEntryStyle = function (val, wide) {
        if (wide === true) {
            return 'url(' + this.mediaService.getMediaUrlByData(val, 'horizontal-wide') + ')';
        }
        return 'url(' + this.mediaService.getMediaUrlByData(val) + ')';
    };
    ServerPokerComponent = tslib_1.__decorate([
        NovaTemplate('ServerPokerComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            MediaService,
            DomSanitizer,
            Renderer2,
            UIEventHandlerService,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], ServerPokerComponent);
    return ServerPokerComponent;
}(Page));
export { ServerPokerComponent };
