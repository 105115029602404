import * as tslib_1 from "tslib";
import { Location } from '@angular/common';
import { AfterViewInit, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Page } from 'app/pages/page';
import { NovaTemplate } from 'app/_decorators/template.decorator';
import { BlogService } from 'app/_services/blog.service';
import { MediaService } from 'app/_services/media.service';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
var PageBlogIndexComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageBlogIndexComponent, _super);
    function PageBlogIndexComponent(nova, uiEventHandler, meta, route, title, mediaService, location, blogService, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.route = route;
        _this.title = title;
        _this.mediaService = mediaService;
        _this.location = location;
        _this.blogService = blogService;
        _this.router = router;
        _this.categories = [];
        _this.selectedCategories = [];
        _this.posts = [];
        _this.filteredPosts = [];
        _this.blogFonts = [];
        _this.categoriesHaveIcons = false;
        _this.isLoading = true;
        _this.categoriesVisible = false;
        _this.searchValue = '';
        return _this;
    }
    PageBlogIndexComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.blogFonts = [this.data.font, this.data.font_bold];
                        this.uiEventHandler.blogPage = tslib_1.__assign({}, this.data, { contents: this.data.contents.filter(function (post) { return post.templateName === 'blog-article'; }) });
                        this.uiEventHandler.currentBlogPost = undefined;
                        this.route.queryParams.subscribe(function (_a) {
                            var categories = _a.categories;
                            return (categories ? (_this.selectedCategories = categories.split(',')) : null);
                        });
                        this.blogService.setBlogStyles(this.data);
                        this.posts = this.data.contents;
                        return [4 /*yield*/, this.getCategories()];
                    case 1:
                        _b.sent();
                        this.filterPosts();
                        _a = this;
                        return [4 /*yield*/, this.blogService.getBlogNavigationIcon(this.data.blogNavigationIcon)];
                    case 2:
                        _a.blogNavigationIcon = _b.sent();
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    PageBlogIndexComponent.prototype.ngAfterViewInit = function () {
        this.data.contents &&
            this.data.contents.length > 0 &&
            $('.next-page')[0].style.setProperty('opacity', '1', 'important');
    };
    PageBlogIndexComponent.prototype.selectCategory = function (event, category) {
        event.stopPropagation();
        this.selectedCategories = category ? [category.toLowerCase()] : [];
        this.filterPosts();
        this.location.replaceState(window.location.pathname, this.selectedCategories.length > 0 ? "?categories=" + this.selectedCategories.join(',') : '');
        this.categoriesVisible = false;
    };
    PageBlogIndexComponent.prototype.sortPosts = function (posts) {
        return posts.filter(function (post) { return post.templateName === 'blog-article'; }).sort(function (a, b) {
            var orderA = a.order;
            var orderB = b.order;
            if (orderA > orderB) {
                return -1;
            }
            if (orderA < orderB) {
                return 1;
            }
            return 0;
        });
    };
    PageBlogIndexComponent.prototype.filterPosts = function () {
        var _this = this;
        var posts = this.selectedCategories.length > 0
            ? this.posts.filter(function (post) {
                return post.categories &&
                    post.categories.some(function (cat) { return _this.selectedCategories.includes(cat.toLowerCase()); });
            })
            : this.posts;
        this.filteredPosts = this.sortPosts(this.filterByKeywords(posts, this.searchValue));
        setTimeout(function () { return _this.blogService.equalizeHeights('.single-post__content'); }, 1500);
    };
    PageBlogIndexComponent.prototype.onChangeSearchFilter = function (newValue) {
        this.searchValue = newValue;
        this.filterPosts();
    };
    PageBlogIndexComponent.prototype.filterByKeywords = function (entries, keyword) {
        var fields = ['label', 'subHeader', 'text', 'header', 'name', 'dateCreated'];
        keyword = keyword.toLowerCase();
        if (keyword.trim() === '') {
            return entries;
        }
        return entries.filter(function (post) {
            for (var i = 0; i < fields.length; i++) {
                var field = fields[i];
                if (!post.hasOwnProperty(field)) {
                    continue;
                }
                if (post[field].toLowerCase().indexOf(keyword) >= 0) {
                    return true;
                }
            }
            return false;
        });
    };
    PageBlogIndexComponent.prototype.getCategories = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1, _a, blogCategories, blogCategories_1, blogCategories_1_1, category, url, e_1_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        blogCategories = this.data && this.data.categories
                            ? this.data.categories.filter(function (cat) { return cat.name && cat.status === 'active'; })
                            : [];
                        if (this.categories.length > 0) {
                            return [2 /*return*/];
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 6, 7, 8]);
                        blogCategories_1 = tslib_1.__values(blogCategories), blogCategories_1_1 = blogCategories_1.next();
                        _b.label = 2;
                    case 2:
                        if (!!blogCategories_1_1.done) return [3 /*break*/, 5];
                        category = blogCategories_1_1.value;
                        if (!category.image) {
                            this.categories = tslib_1.__spread(this.categories, [tslib_1.__assign({}, category)]);
                            return [3 /*break*/, 4];
                        }
                        return [4 /*yield*/, this.mediaService.getMediaUrl(category.image, 'preview').toPromise()];
                    case 3:
                        url = _b.sent();
                        this.categories = tslib_1.__spread(this.categories, [tslib_1.__assign({}, category, { image: url })]);
                        _b.label = 4;
                    case 4:
                        blogCategories_1_1 = blogCategories_1.next();
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        e_1_1 = _b.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 8];
                    case 7:
                        try {
                            if (blogCategories_1_1 && !blogCategories_1_1.done && (_a = blogCategories_1.return)) _a.call(blogCategories_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 8:
                        if (this.categories.some(function (cat) { return Boolean(cat.image); })) {
                            this.categoriesHaveIcons = true;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PageBlogIndexComponent.prototype.toggleCategories = function () {
        this.categoriesVisible = !this.categoriesVisible;
    };
    PageBlogIndexComponent = tslib_1.__decorate([
        NovaTemplate('PageBlogIndexComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            Meta,
            ActivatedRoute,
            Title,
            MediaService,
            Location,
            BlogService,
            Router])
    ], PageBlogIndexComponent);
    return PageBlogIndexComponent;
}(Page));
export { PageBlogIndexComponent };
