/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nova-fti-overlay-social.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./nova-fti-overlay-social.component";
import * as i4 from "../../../../_services/nova-api.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/material/snack-bar";
var styles_NovaFtiOverlaySocialComponent = [i0.styles];
var RenderType_NovaFtiOverlaySocialComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NovaFtiOverlaySocialComponent, data: {} });
export { RenderType_NovaFtiOverlaySocialComponent as RenderType_NovaFtiOverlaySocialComponent };
export function View_NovaFtiOverlaySocialComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "share"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "social-button whatsapp"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.share("whatsapp") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "a", [["class", "social-button mail"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.share("mail") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "a", [["class", "social-button url"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.share("copy") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("WhatsApp")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("E-Mail")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("URL")); _ck(_v, 8, 0, currVal_2); }); }
export function View_NovaFtiOverlaySocialComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-fti-overlay-social", [], null, null, null, View_NovaFtiOverlaySocialComponent_0, RenderType_NovaFtiOverlaySocialComponent)), i1.ɵdid(1, 49152, null, 0, i3.NovaFtiOverlaySocialComponent, [i4.NovaAPIService, i5.Router, i6.MatSnackBar, i2.TranslateService], null, null)], null, null); }
var NovaFtiOverlaySocialComponentNgFactory = i1.ɵccf("nova-fti-overlay-social", i3.NovaFtiOverlaySocialComponent, View_NovaFtiOverlaySocialComponent_Host_0, { font: "font" }, {}, []);
export { NovaFtiOverlaySocialComponentNgFactory as NovaFtiOverlaySocialComponentNgFactory };
