import { Component, ViewEncapsulation } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './hotel-detail-standard.component.html',
    styleUrls: ['./hotel-detail-standard.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageHotelDetailStandardComponent')
export class PageHotelDetailStandardComponent extends Page {
    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }
}
