/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./error.component";
import * as i4 from "../_services/magazine.service";
import * as i5 from "../_services/nova-api.service";
import * as i6 from "../_services/uievent-handler.service";
import * as i7 from "@angular/router";
var styles_ErrorComponent = [i0.styles];
var RenderType_ErrorComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ErrorComponent, data: {} });
export { RenderType_ErrorComponent as RenderType_ErrorComponent };
function View_ErrorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "subheader primary-text-color"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Oops, the page you're "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" looking for does not exist. "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "context primary-text-color"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You may want to head back to the Magazine."])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" If you think something is broken, report a problem. "])), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "buttons-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "a", [["class", "border-button"], ["href", "/"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Go to Magazine"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "a", [["class", "border-button"], ["href", "mailto:support@novamag.de"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Report a problem"]))], null, null); }
function View_ErrorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "subheader primary-text-color"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Oops, you're not authorized"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" to read this magazine. "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "context primary-text-color"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You may want to head back to the Login."])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" If you think something is broken, report a problem. "])), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "buttons-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "a", [["class", "border-button"], ["href", "login"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Go to Login"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "a", [["class", "border-button"], ["href", "mailto:support@novamag.de"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Report a problem"]))], null, null); }
function View_ErrorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "subheader primary-text-color"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Oops, you're not authorized"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" to read this magazine. "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "context primary-text-color"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You may want to head back to the Login."])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" If you think something is broken, report a problem. "])), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "buttons-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "a", [["class", "border-button"], ["href", "login"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Go to Login"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "a", [["class", "border-button"], ["href", "mailto:support@novamag.de"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Report a problem"]))], null, null); }
export function View_ErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "plain error-page-wrapper background-color background-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "content-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "head-line secondary-text-color"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (!_co.code || (_co.code === 404)); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.code === 401); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.code === 403); _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.code || 404); _ck(_v, 3, 0, currVal_0); }); }
export function View_ErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ErrorComponent_0, RenderType_ErrorComponent)), i1.ɵdid(1, 245760, null, 0, i3.ErrorComponent, [i4.MagazineService, i5.NovaAPIService, i6.UIEventHandlerService, i7.Router, i7.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorComponentNgFactory = i1.ɵccf("ng-component", i3.ErrorComponent, View_ErrorComponent_Host_0, {}, {}, []);
export { ErrorComponentNgFactory as ErrorComponentNgFactory };
