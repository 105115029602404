import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import { ActivatedRoute, Router } from '@angular/router';
var PageBookScrollProductComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageBookScrollProductComponent, _super);
    function PageBookScrollProductComponent(nova, uiEventHandler, meta, title, media, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.title = title;
        _this.media = media;
        _this.route = route;
        _this.router = router;
        return _this;
    }
    PageBookScrollProductComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.media.getMedia(this.data.product_image).subscribe(function (data) {
            _this.url = data.url;
        });
    };
    PageBookScrollProductComponent = tslib_1.__decorate([
        NovaTemplate('PageBookScrollProductComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            Meta,
            Title,
            MediaService,
            ActivatedRoute,
            Router])
    ], PageBookScrollProductComponent);
    return PageBookScrollProductComponent;
}(Page));
export { PageBookScrollProductComponent };
