﻿import { Color, ColorFormatter } from '../novamag-api/Types';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { Meta, Title } from '@angular/platform-browser';

export class ComponentTemplate {
    public data: any;
    public customPhantomEvent = false;
    public id: number;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title
    ) {
        if (typeof window !== 'undefined') {
            setTimeout(() => {
                this.addOgTags();
            }, 10);
            setTimeout(() => {
                if (!this.customPhantomEvent) {
                    this.uiEventHandler.onScreenShotEvent(this.data.id);
                }
            }, 1000);
        }
    }

    addOgTags() {
        let titleClear = this.data.name;
        if (this.data.templateName === 'multiple-background') {
            titleClear = this.data.name;
        } else if (this.data.header) {
            titleClear = this.data.header;
        }

        const subHeader = this.data.subHeader;

        let thumb;
        if (this.data.thumbnail) {
            thumb = this.data.thumbnail.url;
        }

        this.nova.setMetaData(titleClear, this.data['metaDescription'] || subHeader, thumb, this.data['metaKeywords']);
        if (this.data['origin_source']) {
            this.nova.addTag({
                rel: 'canonical',
                href: this.data['origin_source']
            });
        }
    }

    getColor(data: Color, alpha?: boolean) {
        if (!data || typeof data.r === 'undefined') {
            return;
        }

        if (typeof window !== 'undefined') {
            if (alpha && $('body').width() <= 991) {
                data.a = 1;
            }
        }

        const c = new ColorFormatter(data);

        return c.rgba();
    }
}
