import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pagesFilter'
})
export class PagesFilterPipe implements PipeTransform {
    searchPage(page, s) {
        if (page.header && page.header.search(s) != -1) {
            return true;
        }
        if (page.subHeader && page.subHeader.search(s) != -1) {
            return true;
        }
        if (page.text && page.text.search(s) != -1) {
            return true;
        }
        if (page.label && page.label.search(s) != -1) {
            return true;
        }
        if (page.index_title && page.index_title.search(s) != -1) {
            return true;
        }
        if (page.medias) {
            let found = false;
            page.medias.forEach(block => {
                if (this.searchPage(block, s)) {
                    found = true;
                }
            });
            if (found) return true;
        }
        if (page.blocks) {
            let found = false;
            page.blocks.forEach(block => {
                if (this.searchPage(block, s)) {
                    found = true;
                }
            });
            if (found) return true;
        }
        if (page.textLeft && page.textLeft.search(s) != -1) {
            return true;
        }
        if (page.textRight && page.textRight.search(s) != -1) {
            return true;
        }

        return false;
    }

    escapeRegExp(str) {
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    }

    transform(pages: any, search: string): any {
        if (!search) {
            return pages;
        }

        let s = new RegExp(this.escapeRegExp(search), 'i');
        let arr = pages.map((page, index) => {
            return {
                ...page,
                key: index,
                subKey: 1
            };
        });
        arr = arr.filter(page => {
            return this.searchPage(page, s);
        });
        pages.forEach((page, index) => {
            page.contents.filter((con, index2) => {
                if (this.searchPage(con, s)) {
                    con.type = 'content';
                    con.pageHandle = page.handle;
                    con.key = index;
                    con.subKey = index2;
                    arr.push(con);
                }
            });
        });

        return arr;
    }
}
