import { Component, Input, ViewEncapsulation, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ColorFormatter } from '../../novamag-api/Types';
import { NovaAPIService } from '../../_services/nova-api.service';
import { Renderer2 } from '@angular/core';
import { AfterContentInit } from '@angular/core/src/metadata/lifecycle_hooks';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';

const tagDefaults = {
    primaryTags: {
        backgroundColor: { r: 255, g: 153, b: 52, a: 1 },
        textColor: { r: 255, g: 255, b: 255, a: 1 },
        fontId: 'r6JLonbigDWZwvtBZ'
    },
    secondaryTags: {
        backgroundColor: { r: 135, g: 136, b: 138, a: 1 },
        textColor: { r: 255, g: 255, b: 255, a: 1 },
        fontId: 'r6JLonbigDWZwvtBZ'
    }
};

const shouldUseDefaults = (name: string) => {
    return name.includes('roundtrip_categories') || name.includes('interests');
};

@Component({
    selector: 'nova-element-small',
    templateUrl: './nova-element-small.component.html',
    encapsulation: ViewEncapsulation.None
})
export class NovaElementSmallComponent implements AfterContentInit {
    @Input() public data;
    @Input() public name: string;
    @Input() public content_name: string;
    @Input() public ignoreSize: boolean;
    @Input() public ignoreFont: boolean;

    public font: string;
    public bgColor: string;
    public color: string;

    @ViewChild('elm') private elm: ElementRef;

    constructor(
        private renderer: Renderer2,
        private novaAPI: NovaAPIService,
        private hostElement: ElementRef,
        private ui: UIEventHandlerService
    ) {}

    ngAfterContentInit() {
        if (this.data[this.name + '_fonts'] && !this.ignoreFont) {
            this.novaAPI.getFont(this.data[this.name + '_fonts']).subscribe(fontData => {
                this.renderer.setStyle(this.elm.nativeElement, 'font-family', fontData.name);
            });
        } else {
            if (shouldUseDefaults(this.name)) {
                this.novaAPI
                    .getFont(tagDefaults[this.name.includes('interests') ? 'primaryTags' : 'secondaryTags'].fontId)
                    .subscribe(fontData => {
                        this.renderer.setStyle(this.elm.nativeElement, 'font-family', fontData.name);
                    });
            }
        }

        if (this.data[this.name + '_color']) {
            const c = new ColorFormatter(this.data[this.name + '_color']);
            this.renderer.setStyle(this.elm.nativeElement, 'color', c.rgb());
        } else {
            if (shouldUseDefaults(this.name)) {
                const c = new ColorFormatter(
                    tagDefaults[this.name.includes('interests') ? 'primaryTags' : 'secondaryTags'].textColor
                );

                this.renderer.setStyle(this.elm.nativeElement, 'color', c.rgba());
            }
        }

        if (this.data[this.name + '_bgColor']) {
            const c = new ColorFormatter(this.data[this.name + '_bgColor']);
            this.renderer.setStyle(this.elm.nativeElement, 'background-color', c.rgba());
        } else {
            if (shouldUseDefaults(this.name)) {
                const c = new ColorFormatter(
                    tagDefaults[this.name.includes('interests') ? 'primaryTags' : 'secondaryTags'].backgroundColor
                );

                this.renderer.setStyle(this.elm.nativeElement, 'background-color', c.rgb());
            }
        }

        if (this.data[this.name + '_bold']) {
            this.renderer.setStyle(this.elm.nativeElement, 'font-weight', 'bold');
        }

        if (this.data[this.name + '_italic']) {
            this.renderer.setStyle(this.elm.nativeElement, 'font-style', 'italic');
        }

        if (this.data[this.name + '_underline']) {
            this.renderer.setStyle(this.elm.nativeElement, 'text-decoration', 'underline');
        }

        if (this.data[this.name + '_size'] && !this.ignoreSize) {
            this.renderer.setStyle(
                this.elm.nativeElement,
                'font-size',
                this.ui.calcFontSizeSmall(this.data[this.name + '_size'])
            );
        }

        if (this.data[this.name + '_padding']) {
            const paddingData = this.data[this.name + '_padding'];

            this.renderer.setStyle(this.elm.nativeElement, 'padding-top', paddingData['top'] + 'px');
            this.renderer.setStyle(this.elm.nativeElement, 'padding-bottom', paddingData['bottom'] + 'px');
            this.renderer.setStyle(this.hostElement.nativeElement, 'padding-left', paddingData['left'] + 'px');
            this.renderer.setStyle(this.hostElement.nativeElement, 'padding-right', paddingData['right'] + 'px');

            let boxShadow = '';
            let bg = 'transparent';

            if (this.data[this.name + '_bgColor']) {
                const c = new ColorFormatter(this.data[this.name + '_bgColor']);
                bg = c.rgba();
            }

            boxShadow += '-' + paddingData['left'] + 'px 0 0 ' + bg;
            boxShadow += ', ' + paddingData['right'] + 'px 0 0 ' + bg;
            this.renderer.setStyle(this.elm.nativeElement, 'box-shadow', boxShadow);

            const paddingHeight = paddingData['top'] || 0 + paddingData['bottom'] || 0;
            this.renderer.setStyle(this.elm.nativeElement, 'line-height', 'calc(1.3em + ' + paddingHeight + 'px)');
        } else {
            this.renderer.setStyle(this.elm.nativeElement, 'line-height', '1.4em');
        }
    }
}
