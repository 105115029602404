/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./nova-scrollbar-holder.component";
var styles_NovaScrollbarHolderComponent = [];
var RenderType_NovaScrollbarHolderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NovaScrollbarHolderComponent, data: {} });
export { RenderType_NovaScrollbarHolderComponent as RenderType_NovaScrollbarHolderComponent };
export function View_NovaScrollbarHolderComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { element: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["scrollbar", 1]], null, 3, "div", [], [[8, "scrollTop", 0], [8, "scrollLeft", 0]], [[null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (_co.onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵdid(3, 278528, null, 0, i1.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.class || "scrollbar-holder"); var currVal_3 = _co.ngClass; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = _co.ngStyle; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.spt; var currVal_1 = _co.spl; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_NovaScrollbarHolderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "nova-scrollbar-holder", [], null, null, null, View_NovaScrollbarHolderComponent_0, RenderType_NovaScrollbarHolderComponent)), i0.ɵdid(1, 114688, null, 0, i2.NovaScrollbarHolderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NovaScrollbarHolderComponentNgFactory = i0.ɵccf("nova-scrollbar-holder", i2.NovaScrollbarHolderComponent, View_NovaScrollbarHolderComponent_Host_0, { class: "class", ngStyle: "ngStyle", ngClass: "ngClass" }, {}, ["*"]);
export { NovaScrollbarHolderComponentNgFactory as NovaScrollbarHolderComponentNgFactory };
