import { Component, Inject, OnInit, ViewEncapsulation, Renderer2, AfterViewChecked, PLATFORM_ID } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { ActivatedRoute, Router } from '@angular/router';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Floor } from '../../floor';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { Meta, Title } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
    templateUrl: './pflugfelder-contents.component.html',
    styleUrls: ['./pflugfelder-contents.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PagePflugfelderContentsComponent')
export class PagePflugfelderContentsComponent extends Floor implements OnInit, AfterViewChecked {
    public data: any;
    public id: number;
    public pageId: number;
    public url: string;
    public urlThumb: string;

    public marketSections = {
        buyHouses: [],
        buyFlats: [],
        rentFlats: []
    };

    public marketSectionsLoading = true;
    public selectedCategory = 0;
    public districts = [];

    private mapLoaded = false;

    public contactData = {
        name: 'Pflugfelder Immobilien',
        position: 'Ihr Team der P Immobilien Beratung GmbH',
        phone: '07141/93660',
        mail: 'info@pflugfelder.de'
    };

    public sortedDistricts = [];

    constructor(
        public media: MediaService,
        route: ActivatedRoute,
        router: Router,
        uiEventHandler: UIEventHandlerService,
        nova: NovaAPIService,
        public meta: Meta,
        public title: Title,
        private _renderer2: Renderer2,
        @Inject(DOCUMENT) private document,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    async ngOnInit() {
        this.uiEventHandler.showNavArrows = false;
        this.uiEventHandler.topClose = true;
        const indexUrl = location.href.substring(0, location.href.lastIndexOf('/'));

        this.sortedDistricts = this.renderDistricts(this.data.relatedDistricts, this.data.districts);

        this.marketSections = {
            buyHouses: [
                {
                    title: this.data.section01,
                    image: await this.media.getMediaUrl(this.data.section01_image, 'source').toPromise(),
                    price: this.data.section01_price,
                    priceRange: this.data.section01_priceRange
                },
                {
                    title: this.data.section05,
                    image: await this.media.getMediaUrl(this.data.section05_image, 'source').toPromise(),
                    priceTrend: this.data.section05_priceTrend
                },
                {
                    title: this.data.section09,
                    image: await this.media.getMediaUrl(this.data.section09_image, 'source').toPromise(),
                    price: this.data.section09_price,
                    priceRange: this.data.section09_priceRange
                },
                {
                    title: this.data.section08,
                    image: await this.media.getMediaUrl(this.data.section08_image, 'source').toPromise()
                }
            ],
            buyFlats: [
                {
                    title: this.data.section02,
                    image: await this.media.getMediaUrl(this.data.section02_image, 'source').toPromise(),
                    price: this.data.section02_price,
                    priceRange: this.data.section02_priceRange
                },
                {
                    title: this.data.section06,
                    image: await this.media.getMediaUrl(this.data.section06_image, 'source').toPromise(),
                    priceTrend: this.data.section06_priceTrend
                },
                {
                    title: this.data.section10,
                    image: await this.media.getMediaUrl(this.data.section10_image, 'source').toPromise(),
                    price: this.data.section10_price,
                    priceRange: this.data.section10_priceRange
                },
                {
                    title: this.data.section04,
                    image: await this.media.getMediaUrl(this.data.section04_image, 'source').toPromise(),
                    rentalYield: this.data.section04_rentalYield
                }
            ],
            rentFlats: [
                {
                    title: this.data.section03,
                    image: await this.media.getMediaUrl(this.data.section03_image, 'source').toPromise(),
                    price: this.data.section03_price,
                    priceRange: this.data.section03_priceRange
                },
                {
                    title: this.data.section07,
                    image: await this.media.getMediaUrl(this.data.section07_image, 'source').toPromise(),
                    priceTrend: this.data.section07_priceTrend
                },
                {
                    title: this.data.section11,
                    image: await this.media.getMediaUrl(this.data.section11_image, 'source').toPromise(),
                    price: this.data.section11_price,
                    priceRange: this.data.section11_priceRange
                },
                {
                    title: this.data.section04,
                    image: await this.media.getMediaUrl(this.data.section04_image, 'source').toPromise(),
                    rentalYield: this.data.section04_rentalYield
                }
            ]
        };

        this.marketSectionsLoading = false;
        this.setContactData(this.data.contact);

        if (isPlatformBrowser(this.platformId)) {
            const styleLink = this._renderer2.createElement('link');
            styleLink.href = 'https://api.mapbox.com/mapbox-gl-js/v2.4.1/mapbox-gl.css';
            styleLink.rel = 'stylesheet';
            this._renderer2.appendChild(this.document.head, styleLink);

            const mapGlScript = this._renderer2.createElement('script');
            mapGlScript.src = 'https://api.mapbox.com/mapbox-gl-js/v2.4.1/mapbox-gl.js';
            mapGlScript.setAttribute('defer', 'defer');

            this._renderer2.appendChild(this.document.head, mapGlScript);

            // embed rating widget at the bottom of the page
            const widgetScript = this._renderer2.createElement('script');
            widgetScript.src = 'https://iframe.immowissen.org/loader.min.js';
            widgetScript.dataset.company = 'pflugfelder-immobilien';
            widgetScript.dataset.slug = 'immobilienbewertung';
            widgetScript.dataset.bottimmo = true;
            widgetScript.setAttribute('defer', 'defer');

            this._renderer2.appendChild(this.document.querySelector('#widget-container'), widgetScript);
        }
    }

    async setContactData(contact: string): Promise<void> {
        switch (contact) {
            case 'Ansprechpartner 2':
                this.contactData = {
                    name: 'Fabian Dietz',
                    position: 'Regionalleiter Neckar-Enz',
                    phone: '+49 (0) 171 44 97 956',
                    mail: 'fdietz@pflugfelder.de'
                };

                break;
            case 'Ansprechpartner 3':
                this.contactData = {
                    name: 'Jürgen Hengstberger',
                    position: 'Regionalleiter Strohgäu',
                    phone: '+49 (0) 173 / 425 77 55',
                    mail: 'jhengstberger@pflugfelder.de'
                };

                break;
            case 'Ansprechpartner 4':
                this.contactData = {
                    name: 'Yannick Altendorfer',
                    position: 'Regionalleiter Ludwigsburg',
                    phone: '+49 (0) 171 / 44 95 181',
                    mail: 'yaltendorfer@pflugfelder.de'
                };

                break;
            case 'Ansprechpartner 5':
                this.contactData = {
                    name: 'Nathalie Schmid',
                    position: 'Regionalleiterin Stuttgart',
                    phone: '+49 (0)174 / 318 77 55',
                    mail: 'nschmid@pflugfelder.de'
                };

                break;

            default:
                break;
        }
    }

    async selectCategory(category: number) {
        this.selectedCategory = category;
    }

    async ngAfterViewChecked() {
        if (isPlatformBrowser(this.platformId)) {
            // @ts-ignore
            if (window.mapboxgl && !this.mapLoaded) {
                const mapScript = this._renderer2.createElement('script');
                const [lat, lng] = this.data.coordinates.split(',');

                const randomLetter = (Math.random() + 1).toString(36).substring(7);
                const mapId = this.data._id.replace(/\d+/g, randomLetter);

                mapScript.text = `
                window.mapboxgl.accessToken = 'pk.eyJ1IjoiaWlibWFwcyIsImEiOiJja3dseG93d3AweGt5Mm9tdG8ydnZsNGt2In0.ovU408o_AqCDJgESUApaHA';
                let ${mapId} = new mapboxgl.Map({
                    container: 'map',
                    style: 'mapbox://styles/iibmaps/ckromvmcq3up519o1au7nfo37', center: { lat: ${lat}, lon: ${lng} },
                    zoom: ${this.data.zoomLevel || '12'},
                });

                ${mapId}.on('load', () => {
                    ${mapId}.addSource('residential-map-areas-source', { type: 'raster',
                        tiles: ['https://prod.gcp-maps.iib-it.de/areas/{z}/{x}/{y}.png'],
                        scheme: 'tms',
                        tileSize: 256
                    });

                    ${mapId}.addSource('residential-map-ratings-source', {
                        type: 'raster',
                        tiles: ['https://prod.gcp-maps.iib-it.de/ratings/{z}/{x}/{y}.png'],
                        scheme: 'tms',
                        tileSize: 256
                    });

                    ${mapId}.addLayer({
                        id: 'residential-map-areas',
                        type: 'raster',
                        source: 'residential-map-areas-source', minzoom: 10,
                        maxzoom: 18,
                    }, 'iib_gewerbe');

                    ${mapId}.addLayer({
                        id: 'residential-map-ratings',
                        type: 'raster',
                        source: 'residential-map-ratings-source', minzoom: 10,
                        maxzoom: 18,
                    });
                });
            `;

                mapScript.setAttribute('defer', 'defer');

                this._renderer2.appendChild(this.document.body, mapScript);
                this.mapLoaded = true;
            }
        }
    }

    getItemTitle(title: string) {
        return title.replace('m2 - Preisen', 'm<sup>2</sup> - Preisen');
    }

    renderDistricts(relatedDistricts, districts) {
        const arr = [...relatedDistricts];

        if (districts.length > 0) {
            districts.split(',').map(district => {
                arr.push({ name: district.trim() });
            });
        }

        const sortedDistricts = arr.filter(item => item.name).sort((a, b) => {
            if (a.name > b.name) return 1;
            if (b.name > a.name) return -1;

            return 0;
        });

        return sortedDistricts;
    }
}
