<div class="form-container" [style.font-family]="fontRegular">
    <form [formGroup]="form" class="form" (ngSubmit)="onSubmit()">
        <div *ngIf="formTemplate['travelinfo'].label" [innerHTML]="formTemplate['travelinfo'].label | safeHtml" style="margin-bottom: 36px;"></div>

        <div *ngIf="getFormSection('topSection')" [formGroup]="getFormSection('topSection')">
            <div *ngFor="let formField of formTemplate['topSection'].field">
                <nova-contactform-field [form]="form.get('topSection')" [field]="formField" [formSubmitted]="submitted" [fontBold]="fontBold"></nova-contactform-field>
            </div>
        </div>

        <div *ngIf="getFormSection('travelinfo')" [formGroup]="getFormSection('travelinfo')">
            <div *ngFor="let formField of formTemplate['travelinfo'].field">
                <nova-contactform-field [form]="form.get('travelinfo')" [field]="formField" [formSubmitted]="submitted" [fontBold]="fontBold"></nova-contactform-field>
            </div>
        </div>

        <div *ngIf="getFormSection('contactdata')" [formGroup]="getFormSection('contactdata')">
            <div [innerHTML]="formTemplate['contactdata'].label | safeHtml" style="margin: 50px 0 36px;"></div>
            <div *ngFor="let formField of formTemplate['contactdata'].field">
                <nova-contactform-field [form]="form.get('contactdata')" [field]="formField" [formSubmitted]="submitted" [fontBold]="fontBold"></nova-contactform-field>
            </div>
        </div>

        <recaptcha *ngIf="useCaptcha" [formControl]="form.get('recaptcha')" (scriptError)="onCaptchaScriptError()"></recaptcha>

        <div class="form__footer">
            <p *ngIf="data.contactForm.messages && data.contactForm.messages.dataprivacy"><small [innerHTML]="data.contactForm.messages.dataprivacy | safeHtml"></small></p>
            <p *ngIf="data.contactForm.messages && data.contactForm.messages.footer"><small [innerHTML]="data.contactForm.messages.footer | safeHtml"></small></p>

            <p *ngIf="errors.length > 0 && submitted && !isSubmitting && data.contactForm.messages && data.contactForm.messages.errormessage" style="color:red;"><i class="fas fa-exclamation-circle"></i>&nbsp;<span [innerHTML]="data.contactForm.messages.errormessage | safeHtml"></span></p>
            <p *ngIf="submitSuccess && data.contactForm.messages && data.contactForm.messages.successmessage" style="color:green;"><i class="fas fa-check"></i>&nbsp;<span [innerHTML]="data.contactForm.messages.successmessage | safeHtml"></span></p>

            <button class="btn btn-submit-contact-form" type="submit" [disabled]="isSubmitting" [attr.data-name]="data.header" [attr.data-cmiid]="data.cmiid">
                <span *ngIf="isSubmitting; else buttonText"><i class="fas fa-spinner fa-spin"></i>&nbsp; Ihre Anfrage wird gesendet ...</span>
                <ng-template #buttonText>Absenden</ng-template>
            </button>
        </div>

    </form>
</div>
