import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { PageExternalInlineComponent } from '../external-inline/external-inline.component';
var PageFtiWeatherfinderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageFtiWeatherfinderComponent, _super);
    function PageFtiWeatherfinderComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PageFtiWeatherfinderComponent.prototype.ngOnInit = function () {
        if (typeof window !== 'undefined') {
            if (this.data.weatherscript) {
                if (this.data.weatherscript.substring(0, 7) === '<iframe') {
                    var tmp = document.createElement('div');
                    tmp.innerHTML = this.data.weatherscript;
                    var src = tmp.querySelector('iframe').getAttribute('src');
                    this.iframeSrc = src;
                }
                else {
                    this.iframeSrc = this.data.weatherscript;
                }
                this.scrolling = this.data.scrolling || this.scrolling;
                this.minHeight = this.data.minHeight || this.minHeight;
                this.heightCalculationMethod = this.data.heightCalculationMethod || this.heightCalculationMethod;
                var self_1 = this;
                setTimeout(function () {
                    self_1.uiEventHandler.onScreenShotEvent(self_1.data.id);
                }, 5000);
            }
        }
    };
    PageFtiWeatherfinderComponent = tslib_1.__decorate([
        NovaTemplate('PageFtiWeatherfinderComponent')
    ], PageFtiWeatherfinderComponent);
    return PageFtiWeatherfinderComponent;
}(PageExternalInlineComponent));
export { PageFtiWeatherfinderComponent };
