<ng-template #empty>
    <span></span>
</ng-template>

<div class="carousel">
    <div class="carousel-container">
        <div class="navigation">
            <i class="fas fa-expand"></i>
            <div class="arrows">
                <div class="arrow" (click)="slideLeft($event)">
                    <i class="icon-arrow-left"></i>
                </div>
                <div class="arrow" (click)="slideRight($event)">
                    <i class="icon-arrow-right"></i>
                </div>
            </div>
        </div>
        <div #owl
             class="owl-carousel">
            <div *ngFor="let image of getImages()"
                 class="slide">
                <img
                    *ngIf="apiImageMode"
                    [src]="getImageProxyUrl(image.filename)"
                    [alt]="image.description1"
                >
                <nova-media
                    *ngIf="!apiImageMode"
                    [mediaId]="image.slider_media"
                    [version]="imageVersion"
                ></nova-media>
            </div>
        </div>
        <div>
            <div *ngIf="apiImageMode && (currentImage.caption || currentImage.copyright)" class="caption caption--api caption--pos-relative caption--small">
                <nova-text-element *ngIf="currentImage.caption" class="description" [data]="currentImage.caption"></nova-text-element>
                <nova-text-element *ngIf="currentImage.copyright" class="copyright" [data]="currentImage.copyright"></nova-text-element>
            </div>

            <div *ngIf="!apiImageMode && (currentImage.caption || currentImage.copyright)" class="caption caption--pos-relative caption--small">
                <nova-text-element *ngIf="currentImage.caption" class="description" [data]="currentImage.caption"></nova-text-element>
                <nova-text-element *ngIf="currentImage.copyright" class="copyright" [data]="currentImage.copyright"></nova-text-element>
            </div>
        </div>
    </div>
</div>
