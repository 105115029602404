export interface Color {
    r: number;
    g: number;
    b: number;
    a: number;
}

export class ColorFormatter {
    constructor(public c: Color) {}

    rgba(): string {
        if (!this.c || typeof this.c.r === 'undefined') return null;
        return 'rgba(' + this.c.r + ', ' + this.c.g + ', ' + this.c.b + ', ' + this.c.a + ')';
    }

    rgb(): string {
        if (!this.c || typeof this.c.r === 'undefined') return null;
        return 'rgb(' + this.c.r + ', ' + this.c.g + ', ' + this.c.b + ')';
    }
}
