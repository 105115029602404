import * as tslib_1 from "tslib";
import { SSRService } from './../_services/ssr.service';
import { Router } from '@angular/router';
import { MagazineService } from '../_services/magazine.service';
import { UIEventHandlerService } from '../_services/uievent-handler.service';
import { AuthService } from 'app/_services/auth.service';
import { StripeService } from 'app/_services/stripe.service';
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, magService, ui, ssr, auth, stripe) {
        this.router = router;
        this.magService = magService;
        this.ui = ui;
        this.ssr = ssr;
        this.auth = auth;
        this.stripe = stripe;
        this.issueHandle = null;
        this.language = null;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var magazine, issues, currentIssue_1, isPreviewEnvironment, cookiesAccepted, cookie;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.magService.getMagazine().toPromise()];
                    case 1:
                        magazine = _a.sent();
                        if (!(this.ssr.isBrowser && magazine.authType && magazine.authType === 'paid-content')) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.magService.getIssues().toPromise()];
                    case 2:
                        issues = (_a.sent()).issues;
                        currentIssue_1 = issues.find(function (issue) {
                            return issue.handle === route.paramMap.get('issueHandle') &&
                                issue.language === route.paramMap.get('langHandle');
                        });
                        if (!currentIssue_1) {
                            console.error('Could not find issue');
                            return [2 /*return*/, true];
                        }
                        if (currentIssue_1.paidContent) {
                            if (!magazine.stripeAccount) {
                                console.error('Could not find stripe account id');
                                return [2 /*return*/, true];
                            }
                            isPreviewEnvironment = function () {
                                return window.location.host.includes(currentIssue_1.magazineId.toLowerCase()) ||
                                    window.location.host.includes('localhost');
                            };
                            if (isPreviewEnvironment() && window.location.search.includes('preview=true')) {
                                this.stripe.stripeAccount = magazine.stripeAccount;
                                // set dummy customer to hide paid content dialog & show view of logged in customer
                                this.stripe.currentCustomer = {
                                    email: 'preview@paywall.de',
                                    isAuthenticated: true
                                };
                                if (window.location.host.includes('localhost')) {
                                    console.log('Paywall is disabled.\nRemove "?preview=true" to enable again.');
                                }
                            }
                            else {
                                this.stripe.checkAuthentication(magazine, currentIssue_1);
                            }
                        }
                        _a.label = 3;
                    case 3:
                        if (!this.issueHandle) {
                            this.issueHandle = route.paramMap.get('issueHandle');
                            this.language = route.paramMap.get('langHandle');
                        }
                        if (this.ssr.isBrowser) {
                            if (!magazine.cookieConsent || (magazine.cookieConsent && magazine.cookieConsent.type === 'default')) {
                                cookiesAccepted = this.ui.getCookie('CookieConsent');
                                if (!cookiesAccepted && !this.ssr.getParameterByName('screenShot')) {
                                    this.ui.showCookiePopup = true;
                                }
                            }
                        }
                        if (magazine.authType &&
                            (magazine.authType === 'global' ||
                                magazine.authType === 'password-protection' ||
                                magazine.authType === 'user')) {
                            cookie = this.ui.getCookie('currentUser');
                            if (cookie) {
                                if (JSON.parse(cookie)) {
                                    return [2 /*return*/, true];
                                }
                            }
                            if (this.ssr.getParameterByName('token') && this.ssr.getParameterByName('screenShot')) {
                                return [2 /*return*/, true];
                            }
                            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/, true];
                }
            });
        });
    };
    return AuthGuard;
}());
export { AuthGuard };
