﻿import { ComponentTemplate } from './component';
import { NovaAPIService } from '../_services/nova-api.service';
import { UIEventHandlerService } from '../_services/uievent-handler.service';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

export class Page extends ComponentTemplate {
    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public route: ActivatedRoute,
        public router: Router,
        public meta: Meta,
        public title: Title
    ) {
        super(nova, uiEventHandler, meta, title);
    }
}
