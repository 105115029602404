import { Pipe, PipeTransform } from '@angular/core';
import { MultiSelectItem } from './MultiSelectItem';

@Pipe({
    name: 'indexHotelFilter',
    pure: false
})
export class IndexHotelFilterPipe implements PipeTransform {
    transform(
        contents: any,
        selectedFilters: MultiSelectItem[],
        selectedStar: MultiSelectItem[],
        selectedCountry: MultiSelectItem[]
    ): any {
        if (!contents) {
            return contents;
        }
        let filterSelected = content => {
            return true;
        };

        if (selectedFilters.length) {
            filterSelected = content => {
                return selectedFilters
                    .map(item => item.id)
                    .every(fil => content.hotel_labels && content.hotel_labels.indexOf(fil) > -1)
                    ? content
                    : null;
            };
        }
        let filterStars = content => {
            return true;
        };
        if (selectedStar.length) {
            filterStars = content => {
                return selectedStar.map(item => item.id).indexOf(content.stars.slice(0, 1)) > -1;
            };
        }

        let filterCountries = content => {
            return true;
        };
        if (selectedCountry.length) {
            filterCountries = content => {
                return selectedCountry
                    .map(item => item.id)
                    .some(fil => content.country_filters && content.country_filters.indexOf(fil) > -1)
                    ? content
                    : null;
            };
        }

        return contents.filter(content => filterSelected(content) && filterCountries(content) && filterStars(content));
    }
}
