<div class="auth" [style.background]="color(magazine.navigation.borderColor)">
	<div class="flex-center">
		<nova-media [mediaId]="magazine.cookieBackground" [version]="getVersion()" [bg]="true" [opacity]="0.8">
		</nova-media>

        <paid-content-dialog></paid-content-dialog>
        <div class="links">
            <a *ngIf="magazine.termsTitle || magazine.termsText" (click)="terms()"
                [style.color]="color(magazine.navigation.textColor)">{{
                magazine.termsTitle || 'Privacy'
            }}</a>
        </div>
	</div>
</div>
