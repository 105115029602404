import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Floor } from 'app/pages/floor';
import { NovaTemplate } from 'app/_decorators/template.decorator';
import { BlogService } from 'app/_services/blog.service';
import { MediaService } from 'app/_services/media.service';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
var PageBlogArticleComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageBlogArticleComponent, _super);
    function PageBlogArticleComponent(nova, uiEventHandler, meta, route, router, title, mediaService, blogService) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.route = route;
        _this.router = router;
        _this.title = title;
        _this.mediaService = mediaService;
        _this.blogService = blogService;
        _this.sliderHeight = '200px';
        _this.categories = [];
        _this.relatedPosts = [];
        _this.blogFonts = [];
        _this.uiEventHandler.showNavArrows = true;
        return _this;
    }
    PageBlogArticleComponent.prototype.ngOnDestroy = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                $('.last-page')[0].style.setProperty('opacity', 'unset');
                $('.next-page')[0].style.setProperty('opacity', 'unset');
                this.uiEventHandler.currentBlogPost = undefined;
                return [2 /*return*/];
            });
        });
    };
    PageBlogArticleComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, posts, parentPageId, currentPostIndex, currentPageIndex, sliderImages;
            var _this = this;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.blogFonts = [this.data.parentPage.font, this.data.parentPage.font_bold];
                        this.sliderHeight = $('body').width() < 991 && this.data.mediaSize + "vh";
                        this.getCategories();
                        this.blogService.setBlogStyles(this.data.parentPage);
                        _a = this;
                        return [4 /*yield*/, this.blogService.getBlogNavigationIcon(this.data.parentPage.blogNavigationIcon)];
                    case 1:
                        _a.blogNavigationIcon = _c.sent();
                        if (this.data.parentPage.showRelatedPosts === 'active') {
                            this.relatedPosts = this.data.parentPage.contents
                                .filter(function (blogPost) { return blogPost._id !== _this.data._id && blogPost.templateName === 'blog-article'; })
                                .sort(function (a, b) { return b.order - a.order; })
                                .map(function (value) { return ({ value: value, sort: Math.random() }); })
                                .sort(function (a, b) { return a.sort - b.sort; })
                                .map(function (_a) {
                                var value = _a.value;
                                return value;
                            })
                                .slice(0, 3);
                        }
                        this.uiEventHandler.hideTitleBar = false;
                        _b = this.data.parentPage, posts = _b.contents, parentPageId = _b._id;
                        currentPostIndex = posts.findIndex(function (post) { return post._id === _this.data._id; });
                        currentPageIndex = this.nova.pages.findIndex(function (page) { return page._id === parentPageId; });
                        this.uiEventHandler.hideTitleBar = false;
                        this.uiEventHandler.blogPage = tslib_1.__assign({}, this.data.parentPage, { contents: this.data.parentPage.contents.filter(function (post) { return post.templateName === 'blog-article'; }) });
                        this.uiEventHandler.currentBlogPost = this.data;
                        $('.last-page')[0].style.setProperty('opacity', '1', 'important');
                        if (currentPageIndex === this.nova.pages.length - 1 && currentPostIndex === 0) {
                            $('.next-page')[0].style.setProperty('opacity', '0.5', 'important');
                            $('.next-page')[0].setAttribute('disabled', 'true');
                        }
                        return [4 /*yield*/, this.mediaService.loadMedia(this.data.medias, true)];
                    case 2:
                        sliderImages = _c.sent();
                        this.media = sliderImages.map(function (val) { return (tslib_1.__assign({}, val, { pos: _this.calcPosition(val.org['description_position'] || 'center-center') })); });
                        setTimeout(function () {
                            var options = {
                                autoScaleSlider: false,
                                navigateByClick: false,
                                arrowsNav: true,
                                arrowsNavAutoHide: true,
                                arrowsNavHideOnTouch: true,
                                imageScalePadding: 0,
                                loop: true,
                                keyboardNavEnabled: true,
                                globalCaption: true,
                                imgHeight: '100%'
                            };
                            if (_this.data.showThumbs && _this.data.showThumbs === 'yes' && !_this.uiEventHandler.isMobile()) {
                                options['controlNavigation'] = 'thumbnails';
                                options['thumbs'] = {
                                    appendSpan: true,
                                    firstMargin: true,
                                    paddingBottom: 4,
                                    fitInViewport: false
                                };
                            }
                            if (_this.data.slider_speed && _this.data.slider_speed !== 0) {
                                options['autoPlay'] = {
                                    enabled: true,
                                    stopAtAction: false,
                                    pauseOnHover: true,
                                    delay: _this.data.slider_speed * 1000
                                };
                            }
                            $(_this.royalSlider.nativeElement).royalSlider(options);
                            _this.uiEventHandler.showMenu = true;
                            _this.uiEventHandler.showCloseIcon = false;
                        }, 0);
                        return [2 /*return*/];
                }
            });
        });
    };
    PageBlogArticleComponent.prototype.getCategories = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1, _a, blogCategories, _loop_1, this_1, blogCategories_1, blogCategories_1_1, category, e_1_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        blogCategories = this.data.categories;
                        _loop_1 = function (category) {
                            var imageId, url;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        imageId = this_1.data.parentPage.categories.filter(function (cat) { return cat.name === category; });
                                        if (!imageId || !imageId.length) {
                                            return [2 /*return*/, "continue"];
                                        }
                                        return [4 /*yield*/, this_1.mediaService.getMediaUrl(imageId[0].image, 'preview').toPromise()];
                                    case 1:
                                        url = _a.sent();
                                        this_1.categories = tslib_1.__spread(this_1.categories, [{ name: category, image: url }]);
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 6, 7, 8]);
                        blogCategories_1 = tslib_1.__values(blogCategories), blogCategories_1_1 = blogCategories_1.next();
                        _b.label = 2;
                    case 2:
                        if (!!blogCategories_1_1.done) return [3 /*break*/, 5];
                        category = blogCategories_1_1.value;
                        return [5 /*yield**/, _loop_1(category)];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4:
                        blogCategories_1_1 = blogCategories_1.next();
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        e_1_1 = _b.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 8];
                    case 7:
                        try {
                            if (blogCategories_1_1 && !blogCategories_1_1.done && (_a = blogCategories_1.return)) _a.call(blogCategories_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    PageBlogArticleComponent.prototype.getCopyrightUrl = function (c) {
        if (c) {
            if (!c.startsWith('http://') && !c.startsWith('https://')) {
                return 'http://' + c;
            }
            return c;
        }
    };
    PageBlogArticleComponent.prototype.getMediaUrl = function (data) {
        return this.mediaService.getMediaUrlByData(data, 'horizontal-wide');
    };
    PageBlogArticleComponent.prototype.calcPosition = function (posLogo) {
        var logoStyle = '';
        var flexboxStyle = '';
        var posLogoArr = posLogo.split('-');
        if (posLogoArr[0] === 'top') {
            flexboxStyle += 'justify-content: flex-start;';
        }
        else if (posLogoArr[0] === 'bottom') {
            flexboxStyle += 'justify-content: flex-end;';
        }
        else if (posLogoArr[0] === 'center') {
            flexboxStyle += 'justify-content: center;';
        }
        if (posLogoArr[1] === 'left') {
            logoStyle += 'align-self: flex-start;text-align: left;';
        }
        else if (posLogoArr[1] === 'right') {
            logoStyle += 'align-self: flex-end;text-align: right;';
        }
        else if (posLogoArr[1] === 'center') {
            logoStyle += 'align-self: center;text-align: center;';
        }
        return {
            logo: this.convertCSS(logoStyle),
            flexbox: this.convertCSS(flexboxStyle)
        };
    };
    PageBlogArticleComponent.prototype.convertCSS = function (css) {
        var input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {};
            var attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    };
    PageBlogArticleComponent.prototype.setMyStyles = function (obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    };
    PageBlogArticleComponent.prototype.getThumbnail = function (post) {
        if (post.thumbnail && post.thumbnail.url) {
            return 'url(' + post.thumbnail.url + ')';
        }
    };
    PageBlogArticleComponent.prototype.sliderPrev = function () {
        $(this.royalSlider.nativeElement)
            .data('royalSlider')
            .prev();
    };
    PageBlogArticleComponent.prototype.sliderNext = function () {
        $(this.royalSlider.nativeElement)
            .data('royalSlider')
            .next();
    };
    PageBlogArticleComponent = tslib_1.__decorate([
        NovaTemplate('PageBlogArticleComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            Meta,
            ActivatedRoute,
            Router,
            Title,
            MediaService,
            BlogService])
    ], PageBlogArticleComponent);
    return PageBlogArticleComponent;
}(Floor));
export { PageBlogArticleComponent };
