import { Component, Input } from '@angular/core';

@Component({
    selector: 'nova-fti-button',
    templateUrl: './nova-fti-button.component.html',
    styleUrls: ['./nova-fti-button.component.scss']
})
export class NovaFtiButtonComponent {
    @Input() variant = 'primary';

    constructor() {}
}
