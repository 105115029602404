import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { NovaTemplate } from 'app/_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { MagazineService } from 'app/_services/magazine.service';
import { SSRService } from 'app/_services/ssr.service';

@Component({
    templateUrl: './pandora-page-1.component.html',
    styleUrls: ['./pandora-page-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PagePandoraPage1Component')
export class PagePandoraPage1Component extends Page implements OnInit, AfterViewInit {
    public data: any;
    public formStatus: any;
    public showSuccess: boolean;
    public showMsg: boolean;
    public isRevealed = false;
    public isAnimating = false;
    public navigationStyles;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public router: Router,
        public route: ActivatedRoute,
        public magazineService: MagazineService,
        public ssr: SSRService
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
        this.route.queryParams.subscribe(params => {
            this.formStatus = params['result'];
        });
        this.showMsg = false;

        this.magazineService.getMagazine().subscribe(magazine => {
            if (!this.ssr.isBrowser || !magazine.navigation) {
                return;
            }

            this.navigationStyles = magazine.navigation;
        });
    }

    ngOnInit() {
        console.log(this.data);
    }

    ngAfterViewInit() {
        this.initOwlCarousels();
    }

    isUrl(media) {
        return typeof media !== 'object' && media.indexOf('http') > -1;
    }

    scrollDown() {
        var viewPortHeight = $(window).innerHeight();
        $('.scrollbar-holder')
            .stop()
            .animate({ scrollTop: viewPortHeight }, 500, 'swing', function() {});
    }

    initOwlCarousels() {
        // TODO: remove this timeout and find a better solution
        // for example init after all images are loaded
        setTimeout(() => {
            $('.owl-carousel').owlCarousel({
                autoplay: true,
                loop: true,
                // rewind: true,
                autoplayTimeout: 5000,
                dots: true,
                responsive: {
                    0: {
                        items: 2,
                        slideBy: 2,
                        dotsEach: 2
                    },
                    900: {
                        items: 4,
                        slideBy: 4,
                        dotsEach: 4
                    }
                }
            });
        }, 1000);
    }

    getImageProxyUrl(url) {
        const size = `s:200:150:1:1`;
        const rt = 'fit';
        return `${this.nova.getImgUrl()}/insecure/rt:${rt}/${size}/g:sm/plain/${url}`;
    }
}
