import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Meta, Title } from '@angular/platform-browser';
import * as $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';
var PageExternalContentComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageExternalContentComponent, _super);
    function PageExternalContentComponent(nova, uiEventHandler, sanitizer, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.sanitizer = sanitizer;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        _this.customPhantomEvent = true;
        return _this;
    }
    PageExternalContentComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.data.url.startsWith('http://') && !this.data.url.startsWith('https://')) {
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl('http://' + this.data.url);
        }
        else {
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
        }
        if (typeof window !== 'undefined') {
            setTimeout(function () {
                $(_this.iframe.nativeElement).on('load error', function () {
                    setTimeout(function () {
                        _this.uiEventHandler.onScreenShotEvent(_this.data.id);
                    }, 1000);
                });
            });
        }
    };
    PageExternalContentComponent = tslib_1.__decorate([
        NovaTemplate('PageExternalContentComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            DomSanitizer,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], PageExternalContentComponent);
    return PageExternalContentComponent;
}(Page));
export { PageExternalContentComponent };
