import * as tslib_1 from "tslib";
import { ComponentTemplate } from './component';
var Page = /** @class */ (function (_super) {
    tslib_1.__extends(Page, _super);
    function Page(nova, uiEventHandler, route, router, meta, title) {
        var _this = _super.call(this, nova, uiEventHandler, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.route = route;
        _this.router = router;
        _this.meta = meta;
        _this.title = title;
        return _this;
    }
    return Page;
}(ComponentTemplate));
export { Page };
