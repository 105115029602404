import { Component, Input, ViewChild, ElementRef, ViewEncapsulation, OnInit } from '@angular/core';

@Component({
    selector: 'nova-scrollbar-holder',
    templateUrl: './nova-scrollbar-holder.component.html',
    encapsulation: ViewEncapsulation.None
})
export class NovaScrollbarHolderComponent implements OnInit {
    public spt;
    public spl;
    @Input() public class: string;
    @Input() public ngStyle: string;
    @Input() public ngClass: string;

    @ViewChild('scrollbar') element: ElementRef;

    ngOnInit() {
        const path = window.location.pathname;

        const scrollPosition = sessionStorage.getItem('scroll:' + path);
        if (scrollPosition) {
            const sp = JSON.parse(scrollPosition);
            this.spt = sp.st;
            this.spl = sp.sl;
        }
    }

    onScroll($event) {
        const path = window.location.pathname;
        sessionStorage.setItem(
            'scroll:' + path,
            JSON.stringify({
                st: $event.srcElement.scrollTop,
                sl: $event.srcElement.scrollLeft
            })
        );
    }
}
