/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../_components/nova-nav-border-item/nova-nav-border-item.component.ngfactory";
import * as i3 from "../../../_components/nova-nav-border-item/nova-nav-border-item.component";
import * as i4 from "../../../_services/nova-api.service";
import * as i5 from "../../../_services/magazine.service";
import * as i6 from "../../../_services/uievent-handler.service";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@angular/common";
import * as i9 from "./product.component";
import * as i10 from "../../../_services/media.service";
import * as i11 from "@angular/router";
var styles_PageProductComponent = [i0.styles];
var RenderType_PageProductComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PageProductComponent, data: {} });
export { RenderType_PageProductComponent as RenderType_PageProductComponent };
function View_PageProductComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "pan"]], [[1, "data-big", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "controls"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "nova-nav-border-item", [["class", "my-close-icon zoom-in"]], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onMouseEnter($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onMouseLeave($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).onTouchStart($event.target) !== false);
        ad = (pd_2 && ad);
    } if (("touchend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3).onTouchEnd($event.target) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_NovaNavBorderItemComponent_0, i2.RenderType_NovaNavBorderItemComponent)), i1.ɵdid(3, 245760, null, 0, i3.NovaNavBorderItemComponent, [i4.NovaAPIService, i5.MagazineService, i6.UIEventHandlerService, i7.DomSanitizer, i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 0, "i", [["class", "icon-plus"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "nova-nav-border-item", [["class", "my-close-icon zoom-out"]], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onMouseEnter($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onMouseLeave($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6).onTouchStart($event.target) !== false);
        ad = (pd_2 && ad);
    } if (("touchend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6).onTouchEnd($event.target) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_NovaNavBorderItemComponent_0, i2.RenderType_NovaNavBorderItemComponent)), i1.ɵdid(6, 245760, null, 0, i3.NovaNavBorderItemComponent, [i4.NovaAPIService, i5.MagazineService, i6.UIEventHandlerService, i7.DomSanitizer, i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 0, "i", [["class", "icon-minus"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 0, 0, currVal_0); }); }
function View_PageProductComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "pan-mobile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 1, 0, currVal_0); }); }
export function View_PageProductComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "content-product"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i8.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "background-color": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageProductComponent_1)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageProductComponent_2)), i1.ɵdid(7, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "rsCaption"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.getColor(_co.data["background_color"])); _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.uiEventHandler.isMobile(); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.uiEventHandler.isMobile(); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.data.image_header; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.data.image_subHeader; _ck(_v, 13, 0, currVal_4); }); }
export function View_PageProductComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_PageProductComponent_0, RenderType_PageProductComponent)), i1.ɵdid(1, 114688, null, 0, i9.PageProductComponent, [i10.MediaService, i11.ActivatedRoute, i11.Router, i6.UIEventHandlerService, i4.NovaAPIService, i7.Meta, i7.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageProductComponentNgFactory = i1.ɵccf("ng-component", i9.PageProductComponent, View_PageProductComponent_Host_0, {}, {}, []);
export { PageProductComponentNgFactory as PageProductComponentNgFactory };
