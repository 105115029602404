<div id="page-{{ id }}" class="page page-book-scroll-product">
    <div class="left">
        <nova-bg [data]="data" [version]="'vertical-wide'"></nova-bg>
        <div class="center">
            <div class="center-child">
                <img class="product-img" *ngIf="url" [src]="url" />
                <h2 *ngIf="data.product_header"><nova-element [data]="data" name="product_header"></nova-element></h2>
                <p *ngIf="data.product_subHeader"><nova-element [data]="data" name="product_subHeader"></nova-element></p>
            </div>
        </div>
    </div>
    <nova-scrollbar-holder [ngStyle]="{'backgroundColor':getColor(data['box_color'])}">
        <div class="scroller">
            <div class="bg">
                <div class="real-bg" [ngStyle]="{'backgroundColor':getColor(data['box_color'])}">
                    <div class="mobile-bg show-for-medium-only">
                        <nova-bg *ngIf="!uiEventHandler.isMobile()" [data]="data" [version]="'horizontal-wide'"></nova-bg>
                        <div class="center">
                            <div>
                                <img class="product-img" *ngIf="url" [src]="url" />
                                <h2 *ngIf="data.product_header"><nova-element [data]="data" name="product_header"></nova-element></h2>
                                <p *ngIf="data.product_subHeader"><nova-element [data]="data" name="product_subHeader"></nova-element></p>
                            </div>
                        </div>
                    </div>
                    <nova-title [data]="data"></nova-title>
                    <nova-text-element [data]="data.text" (openGallery)="gallery.openSlider($event)"></nova-text-element>
                    <nova-content-links class="content-links" *ngIf="data.links && data.links.length > 0" [floors]="data.links"></nova-content-links>
                </div>
            </div>
        </div>
    </nova-scrollbar-holder>
    <nova-text-gallery [medias]="data.textImages" #gallery></nova-text-gallery>
</div>
