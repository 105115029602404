/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nova-text-gallery.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../nova-nav-border-item/nova-nav-border-item.component.ngfactory";
import * as i3 from "../nova-nav-border-item/nova-nav-border-item.component";
import * as i4 from "../../_services/nova-api.service";
import * as i5 from "../../_services/magazine.service";
import * as i6 from "../../_services/uievent-handler.service";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../nova-gallery-popup/nova-gallery-popup.component.ngfactory";
import * as i9 from "../nova-gallery-popup/nova-gallery-popup.component";
import * as i10 from "../../_services/media.service";
import * as i11 from "@angular/common";
import * as i12 from "./nova-text-gallery.component";
var styles_NovaTextGalleryComponent = [i0.styles];
var RenderType_NovaTextGalleryComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_NovaTextGalleryComponent, data: {} });
export { RenderType_NovaTextGalleryComponent as RenderType_NovaTextGalleryComponent };
function View_NovaTextGalleryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["modal", 1]], null, 4, "div", [["class", "page-image-classic-slider"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "nova-nav-border-item", [["class", "icon-cancel"]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onMouseEnter($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onMouseLeave($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).onTouchStart($event.target) !== false);
        ad = (pd_2 && ad);
    } if (("touchend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2).onTouchEnd($event.target) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.closeSlider() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_NovaNavBorderItemComponent_0, i2.RenderType_NovaNavBorderItemComponent)), i1.ɵdid(2, 245760, null, 0, i3.NovaNavBorderItemComponent, [i4.NovaAPIService, i5.MagazineService, i6.UIEventHandlerService, i7.DomSanitizer, i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "nova-gallery-popup", [], null, null, null, i8.View_NovaGalleryPopupComponent_0, i8.RenderType_NovaGalleryPopupComponent)), i1.ɵdid(4, 245760, [[2, 4], ["gallery", 4]], 0, i9.NovaGalleryPopupComponent, [i4.NovaAPIService, i10.MediaService, i6.UIEventHandlerService], { con: [0, "con"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_1 = _co.medias; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.open; _ck(_v, 0, 0, currVal_0); }); }
export function View_NovaTextGalleryComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { modal: 0 }), i1.ɵqud(671088640, 2, { gallery: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaTextGalleryComponent_1)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.medias && (_co.medias.length > 0)); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_NovaTextGalleryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-text-gallery", [], null, null, null, View_NovaTextGalleryComponent_0, RenderType_NovaTextGalleryComponent)), i1.ɵdid(1, 49152, null, 0, i12.NovaTextGalleryComponent, [i6.UIEventHandlerService, i1.Renderer2, i11.DOCUMENT], null, null)], null, null); }
var NovaTextGalleryComponentNgFactory = i1.ɵccf("nova-text-gallery", i12.NovaTextGalleryComponent, View_NovaTextGalleryComponent_Host_0, { medias: "medias", myid: "myid" }, { onClose: "close" }, []);
export { NovaTextGalleryComponentNgFactory as NovaTextGalleryComponentNgFactory };
