import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { NovaAPIService } from 'app/_services/nova-api.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { MagazineService } from 'app/_services/magazine.service';
import { NovaTemplate } from 'app/_decorators/template.decorator';

@Component({
    selector: 'nova-newsletter-popup',
    templateUrl: './nova-newsletter-popup.component.html',
    styleUrls: ['./nova-newsletter-popup.component.scss']
})
@NovaTemplate('NovaNewsletterPopupComponent')
export class NovaNewsletterPopupComponent implements OnInit, OnDestroy {
    public font;
    private sub: Subscription;
    private subFont: Subscription;
    public magazine;

    @ViewChild('newsletterFrom') public newsletterFrom: ElementRef;

    constructor(
        public nova: NovaAPIService,
        public mag: MagazineService,
        public uiEventHandler: UIEventHandlerService
    ) {}

    ngOnInit() {
        this.sub = this.mag.getMagazine().subscribe(data => {
            this.magazine = data;
            this.subFont = this.nova.getFont(data.navigation.font).subscribe(fontData => {
                this.font = fontData.name;
            });
            // this.newsletterFrom.nativeElement.innerHTML = this.magazine.newsletter.code;

            this.magazine.newsletter.languages.forEach(element => {
                if (element && element.language === this.nova.lang) {
                    $('#newsletterFrom').append(element.code);
                }
            });
        });
    }

    close() {
        this.uiEventHandler.setCookie('newsletterShowed', 'true', 30);
        this.uiEventHandler.showNewsletterForm = false;
    }

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe();
        if (this.subFont) this.subFont.unsubscribe();
    }
}
