import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { AppBrowserModule } from './app/app.browser.module';
if (environment.production) {
    enableProdMode();
}

import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';

if (typeof Modernizr !== 'undefined' && !Modernizr['flexbox']) {
    window.location.href = 'BROWSER_UPDATE_URL_REPLACE';
}

if (typeof window !== 'undefined') {
    document.body.classList.remove('hidden');
}

document.addEventListener('DOMContentLoaded', () => {
    platformBrowserDynamic()
        .bootstrapModule(AppBrowserModule)
        .catch(err => console.log(err));
});
