import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './book-scroll-striking.component.html',
    styleUrls: ['./book-scroll-striking.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageBookScrollStrikingComponent')
export class PageBookScrollStrikingComponent extends Page implements OnInit {
    public positonStyle;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
        this.customPhantomEvent = true;
    }

    ngOnInit() {
        let positonStyle = 'flex-grow: 1;';

        const positon = this.data['contentPosition'] || 'center-center';
        const positonArr = positon.split('-');

        if (positonArr[0] === 'top') {
            positonStyle += 'align-self: flex-start;';
            positonStyle += 'margin-top: 75px;';
        }

        if (positonArr[0] === 'center') {
            positonStyle += 'align-self: center;';
        }

        if (positonArr[0] === 'bottom') {
            positonStyle += 'align-self: flex-end;';
        }

        if (positonArr[1] === 'left') {
            positonStyle += 'text-align: left;';
        }
        if (positonArr[1] === 'center') {
            positonStyle += 'text-align: center;';
        }
        if (positonArr[1] === 'right') {
            positonStyle += 'text-align: right;';
        }

        this.positonStyle = this.convertCSS(positonStyle);

        if (typeof window !== 'undefined') {
            setTimeout(() => {
                this.uiEventHandler.onScreenShotEvent(this.data.id);
                $('.page-book-scroll').removeClass('animated slideInLeft fadeScaleUp fadeScaleUp2');
            }, 1000);
        }
    }

    convertCSS(css) {
        const input = css.toString();
        if (input.indexOf(':') > -1) {
            const result = {};
            const attributes = input.split(';');

            for (let i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    const entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }

            return result;
        }

        return css;
    }

    setMyStyles(obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    }
}
