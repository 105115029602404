import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { Router } from '@angular/router';
import { MediaService } from 'app/_services/media.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { SSRService } from 'app/_services/ssr.service';

@Component({
    selector: 'nova-matrix',
    templateUrl: './nova-matrix.component.html',
    styleUrls: ['./nova-matrix.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NovaMatrixComponent implements OnInit {
    @Input() languages: any[];
    @Input() magazine;
    @Input() showPDF;
    @Input() showPrint;
    @Input() mobileStyle;
    @Input() showMagazine;

    @Output() openImpressum = new EventEmitter();
    @Output() openTerms = new EventEmitter();

    private clickWait = false;

    constructor(
        public nova: NovaAPIService,
        public media: MediaService,
        public uiEventHandler: UIEventHandlerService,
        public translate: TranslateService,
        private router: Router,
        public ssr: SSRService
    ) {}

    ngOnInit() {
        if (this.ssr.isBrowser && typeof window !== 'undefined') {
            if ($('html.touchevents').length === 0) {
                let timer;
                let timerSub;
                $(document).on('mouseenter', 'nav.right-nav nova-matrix .icon, nav.right-nav .matrix', function() {
                    $('nav.right-nav .matrix').addClass('active');
                    if (timer) {
                        clearTimeout(timer);
                        timer = null;
                    }
                    timer = setTimeout(() => {
                        $('nav.right-nav .matrix').removeClass('active');
                    }, 400);

                    if (
                        $(this)
                            .parent()
                            .parent()
                            .hasClass('sub') ||
                        $(this)
                            .parent()
                            .parent()
                            .hasClass('sub2')
                    ) {
                        $('nav.right-nav nova-matrix .icon').removeClass('active');
                        $(this)
                            .parent()
                            .parent()
                            .parent()
                            .addClass('active');
                        if (timerSub) {
                            clearTimeout(timerSub);
                            timerSub = null;
                        }
                        timerSub = setTimeout(() => {
                            $(this)
                                .parent()
                                .parent()
                                .parent()
                                .removeClass('active');
                        }, 400);
                    }
                });
            }

            let menuClick = false;
            $(document).click(function(event) {
                if (!$(event.target).closest('nav.right-nav .matrix').length) {
                    $('.touchevents nav.right-nav nova-matrix .icon, .touchevents nav.right-nav .matrix').removeClass(
                        'active'
                    );
                }
            });

            $(document).on('click touchend', 'nav.right-nav nova-matrix .icon, nav.right-nav .matrix', val => {
                if (!menuClick) {
                    menuClick = true;
                    setTimeout(() => {
                        menuClick = false;
                    }, 100);
                    if ($(val.currentTarget).data('close') === false) {
                        return;
                    }
                    if ($(val.currentTarget).find('.sub').length > 0) {
                        $(val.currentTarget).toggleClass('active');
                    } else {
                        $(
                            '.touchevents nav.right-nav nova-matrix .icon, .touchevents nav.right-nav .matrix'
                        ).removeClass('active');
                    }

                    if ($(val.currentTarget).attr('id') === 'openContentTable') {
                        this.toggleContentTable();
                    }
                    if ($(val.currentTarget).attr('id') === 'openSearch') {
                        this.toggleSearch();
                    }
                }
            });
        }
    }

    increaseFont() {
        if (this.uiEventHandler.fontSize >= 4) return;
        if (this.uiEventHandler.column !== 'single' && this.uiEventHandler.columnizeEnabled) {
            this.uiEventHandler.textLoading = true;
        }
        this.uiEventHandler.fontSize += 0.2;
        setTimeout(() => {
            this.uiEventHandler.onFontEvent(this.uiEventHandler.fontSize);
        }, 50);
    }

    decreaseFont() {
        if (this.uiEventHandler.fontSize <= 0.6) return;
        if (this.uiEventHandler.column !== 'single' && this.uiEventHandler.columnizeEnabled) {
            this.uiEventHandler.textLoading = true;
        }
        this.uiEventHandler.fontSize -= 0.2;
        setTimeout(() => {
            this.uiEventHandler.onFontEvent(this.uiEventHandler.fontSize);
        }, 50);
    }

    printPdf() {
        this.nova.print();
    }

    share(type) {
        const params = this.router.routerState.root.firstChild.params['value'];
        if (params['issueHandle']) {
            this.nova.getPageData().subscribe(data => {
                this.realShare(type, data['data']);
            });
        } else {
            this.realShare(type);
        }
    }

    private realShare(type, data?) {
        let shareUrl = window.location.href; // current page
        shareUrl = shareUrl.replace('#/', '');
        const encodedUrl = encodeURIComponent(shareUrl);

        this.translate.get(['MAIL_SUBJECT', 'MAIL_BODY']).subscribe((res: Object) => {
            const mailSubject = res['MAIL_SUBJECT'];
            const mailBody = res['MAIL_BODY'];

            // get data from current page
            let pageTitle = '';
            const title = this.getTitle();

            // image url
            let media = '';
            if (data) {
                media = data.thumb;
                pageTitle = data.header;
            }

            if (type === 'mail') {
                window.location.href = 'mailto:?&subject=' + mailSubject + '&body=' + mailBody + shareUrl;
            } else if (type === 'whatsapp') {
                window.location.href = 'whatsapp://send?text=' + mailBody + shareUrl;
            } else {
                let host = '';

                if (type === 'xing') {
                    host = 'https://www.xing.com/spi/shares/new?url=' + encodedUrl;
                } else if (type === 'facebook') {
                    host = 'https://www.facebook.com/sharer/sharer.php?u=' + encodedUrl;
                    if (media) {
                        host += '&picture=' + media;
                    }
                    host += '&title=' + title + '&description=' + pageTitle;
                } else if (type === 'linkedin') {
                    host =
                        'https://www.linkedin.com/shareArticle?mini=true&title=' +
                        mailSubject +
                        '&summary=' +
                        mailBody +
                        '&source=&url=' +
                        shareUrl;
                } else if (type === 'twitter') {
                    host = 'https://twitter.com/intent/tweet?text=' + mailBody + shareUrl;
                } else if (type === 'pinterest') {
                    host =
                        'https://pinterest.com/pin/create/button/?url=' +
                        shareUrl +
                        '&description=' +
                        mailBody +
                        shareUrl +
                        '&media=' +
                        media;
                }

                window.open(host, '_blank');
            }
        });
    }

    getTitle() {
        if (this.nova.issue) {
            return this.nova.issue.name;
        } else if (this.magazine) {
            return this.magazine.name;
        }
        return 'NO TITLE SET';
    }

    columnClass() {
        if (this.uiEventHandler.column === 'columns') {
            return 'icon-text';
        }
        return 'icon-two-columns-layout';
    }

    switchColumns() {
        this.uiEventHandler.columnChanged = true;
        if (this.uiEventHandler.column === 'columns') {
            this.uiEventHandler.column = 'single';
        } else {
            this.uiEventHandler.column = 'columns';
            this.uiEventHandler.textLoading = true;
        }
        this.uiEventHandler.onFontEvent(this.uiEventHandler.fontSize);
    }

    toggleContentTable() {
        if (this.clickWait) return;
        this.clickWait = true;
        setTimeout(() => {
            this.clickWait = false;
        }, 100);
        this.uiEventHandler.showContentTableFirstTime = true;
        this.uiEventHandler.showContentTable = !this.uiEventHandler.showContentTable;
    }

    toggleSearch() {
        if (this.clickWait) return;
        this.clickWait = true;
        setTimeout(() => {
            this.clickWait = false;
        }, 100);
        this.uiEventHandler.showSearch = !this.uiEventHandler.showSearch;
    }

    openurl = function(url) {
        window.open(url, '_blank');
    };

    getBrandingLink() {
        let url = environment['utmSource'];

        url += `/?utm_source=${(this.uiEventHandler.isMobile() && 'branding-mobile') || 'branding-desktop'}`;
        url += `${typeof window !== 'undefined' && `&utm_medium=${encodeURIComponent(window.location.href)}`}`;

        return url;
    }

    openContactForm() {
        if (this.clickWait) return;
        this.clickWait = true;
        setTimeout(() => {
            this.clickWait = false;
        }, 100);
        this.uiEventHandler.showContactForm = !this.uiEventHandler.showContactForm;
    }

    switchLang(lang) {
        const params = this.router.routerState.root.firstChild.params['value'];
        const oldLang = this.nova.lang;
        this.nova.lang = lang;
        this.translate.use(lang);
        this.nova.mapIssue();
        this.translate.get('CONTENT_TABLE').subscribe((res: string) => {
            this.uiEventHandler.CONTENT_TABLE = res;
        });
        if (params['issueHandle']) {
            let found;
            this.nova.issues.forEach(value => {
                if (
                    !found &&
                    value['languagesMap'][oldLang] &&
                    value['languagesMap'][oldLang].handle === params['issueHandle']
                ) {
                    if (value['languagesMap'][lang]) {
                        found = value['languagesMap'][lang].handle;
                    }
                }
            });
            if (!found) {
                found = params['issueHandle'];
            }
            if (params['pageHandle']) {
                if (params['contentHandle']) {
                    this.router.navigate(['/', lang, found, params['pageHandle'], params['contentHandle']], {
                        queryParamsHandling: 'preserve'
                    });
                } else {
                    this.router.navigate(['/', lang, found, params['pageHandle']], { queryParamsHandling: 'preserve' });
                }
            } else {
                this.router.navigate(['/', lang, found], { queryParamsHandling: 'preserve' });
            }
        } else {
            this.router.navigate(['/', lang], { queryParamsHandling: 'preserve' });
        }
    }

    get isSeat() {
        return this.uiEventHandler.customIcons;
    }
}
