import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NovaAPIService } from '../../_services/nova-api.service';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { MediaService } from 'app/_services/media.service';

@Component({
    selector: 'nova-gallery-popup',
    templateUrl: './nova-gallery-popup.component.html',
    styleUrls: ['./nova-gallery-popup.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NovaGalleryPopupComponent implements OnInit, OnDestroy {
    public loaded = false;

    @Input() public con;
    @Input() public original = false;
    @Input() public showArrows = true;

    public mediaData = [];

    @ViewChild('royalSlider') private royalSlider;

    constructor(
        public novaAPI: NovaAPIService,
        public mediaService: MediaService,
        public uiEventHandler: UIEventHandlerService
    ) {}

    private arrowSub: Subscription;

    ngOnInit() {
        if (typeof window === 'undefined') {
            return;
        }

        this.arrowSub = this.uiEventHandler.arrowEvent$.subscribe((dir: string) => {
            if (dir === 'right') {
                this.nextPage();
            } else if (dir === 'left') {
                this.previousPage();
            }
        });

        this.mediaService
            .loadMedia(this.con, true)
            .then((data: any[]) => {
                if (!data) {
                    return;
                }

                this.mediaData = data.map(val => {
                    if (!val.contentType) {
                        val.url = val.org.url;
                    } else {
                        const ext = val.contentType.replace('image/', '');
                        val.filename = val.filename.replace(new RegExp('.' + ext + '$'), '');
                        val.filename = val.filename.replace(new RegExp('.jpg$'), '');
                        if (val.copyrightUrl) {
                            if (!val.copyrightUrl.startsWith('http://') && !val.copyrightUrl.startsWith('https://')) {
                                val.copyrightUrl = 'http://' + val.copyrightUrl;
                            }
                        }
                        if (this.mediaService.isVideo(val)) {
                            val.lokal = this.mediaService.isLocalVideo(val);
                            val.video = true;
                            const video = this.mediaService.getVideo(val, false, true);
                            if (video && video.length > 0) {
                                val.videoSrc = video[0].src;
                            }
                        }
                        if (!val.org.original) {
                            val.url = this.getMediaUrl(val);
                        }
                    }

                    return val;
                });

                this.loaded = true;

                setTimeout(() => {
                    const slider = $(this.royalSlider.nativeElement)
                        .royalSlider({
                            imageScalePadding: 0,
                            imageScaleMode: slide =>
                                slide.holder.find('.my-slide').hasClass('org') ? 'fit-if-smaller' : 'fit',
                            loop: true,
                            arrowsNav: false, // we use custom arrows
                            keyboardNavEnabled: false,
                            navigateByClick: false,
                            autoScaleSlider: false,
                            controlNavigation: 'none',
                            globalCaption: true,
                            imgWidth: 800,
                            video: {
                                // video options go here
                                autoHideBlocks: true,
                                autoHideArrows: false
                            }
                        })
                        .data('royalSlider');
                    slider.ev.on('rsOnCreateVideoElement', (e, url) => {
                        this.uiEventHandler.showCloseIcon = false;
                        setTimeout(() => {
                            slider.updateSliderSize();
                        }, 100);

                        const elm = e.target.currSlide.content.find('img');
                        if (elm.data('lokal') === true) {
                            slider.videoObj = $(
                                `<video controls autoplay>
                                    <source src="${url}" [type]="video/mp4">
                                </video>`
                            );
                        }
                    });
                    slider.ev.on('rsOnDestroyVideoElement', e => {
                        this.uiEventHandler.showCloseIcon = true;
                        setTimeout(() => {
                            slider.updateSliderSize();
                        }, 100);
                    });
                }, 100);
            })
            .catch(console.error);
    }

    public resize() {
        if (this.royalSlider) {
            const slider = $(this.royalSlider.nativeElement).data('royalSlider');
            slider && slider.updateSliderSize();
        }
    }

    public goTo(key) {
        if (this.royalSlider) {
            const slider = $(this.royalSlider.nativeElement).data('royalSlider');
            slider && slider.goTo(key);
        }
    }

    ngOnDestroy() {
        if (this.royalSlider) {
            const slider = $(this.royalSlider.nativeElement).data('royalSlider');
            slider && slider.destroy();
        }

        this.arrowSub && this.arrowSub.unsubscribe();
    }

    len() {
        return Object.keys(this.mediaData).length;
    }

    previousPage() {
        const slider = $(this.royalSlider.nativeElement).data('royalSlider');
        slider && slider.prev();
    }

    nextPage() {
        const slider = $(this.royalSlider.nativeElement).data('royalSlider');
        slider && slider.next();
    }

    getMediaUrl(data) {
        return this.mediaService.getMediaUrlByData(data, this.original ? 'original' : 'gallery');
    }

    closeSwipeOverlay() {
        this.uiEventHandler.showSwipeOverlay = false;
    }
}
