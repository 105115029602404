<nova-nav-border-item
	[ngStyle]="mobileStyle"
	class="matrix"
	[ngClass]="{ nonav: uiEventHandler.isMobile() && !showMagazine }"
>
	<i class="icon-matrix" id="matrix"></i>
	<div class="sub" [class.noBranding]="!magazine.branding">
		<nova-nav-border-item [forceClick]="false" 
			class="icon">
			<i class="icon-paragraph-law"></i>
			<div class="sub">
				<div class="bg">
					<nova-nav-border-item
						[forceClick]="false"
						(click)="openImpressum.emit()"
						class="icon"
					>
						<i class="icon-impressum"></i>
					</nova-nav-border-item>
					<nova-nav-border-item
						[forceClick]="false"
						(click)="openTerms.emit()"
						class="icon"
					>
						<i class="icon-terms"></i>
					</nova-nav-border-item>
				</div>
			</div>
		</nova-nav-border-item>
		<nova-nav-border-item class="icon" [hidden]="languages.length <= 1">
			<i class="icon-language"></i>
			<div class="sub">
				<div class="bg">
					<nova-nav-border-item
						*ngFor="let value of languages"
						[ngStyle]="{ 'font-family': font }"
						[hidden]="value == nova.lang"
						(click)="switchLang(value)"
						class="icon"
					>
						<span [class.active]="value == nova.lang">{{ value }}</span>
					</nova-nav-border-item>
				</div>
			</div>
		</nova-nav-border-item>
		<nova-nav-border-item
			(click)="openContactForm()"
			class="icon"
			[hidden]="!magazine.contact_enabled || (magazine.contact_position == 'top' && !uiEventHandler.isMobile())"
		>
			<i class="icon-mail-o"></i>
		</nova-nav-border-item>
		<nova-nav-border-item (click)="printPdf()" class="icon" *ngIf="showPrint">
			<i [class.icon-print]="!isSeat" [class.icon-pdf-seat]="isSeat"></i>
		</nova-nav-border-item>
		<nova-nav-border-item (click)="printPdf()" class="icon" *ngIf="showPDF">
			<i class="icon-pdf"></i>
		</nova-nav-border-item>
		<nova-nav-border-item
			class="icon"
			(click)="increaseFont()"
			[hidden]="!uiEventHandler.textFloor"
			data-close="false"
		>
			<i class="icon-font-increase"></i>
		</nova-nav-border-item>
		<nova-nav-border-item
			class="icon"
			(click)="decreaseFont()"
			[hidden]="!uiEventHandler.textFloor"
			data-close="false"
		>
			<i class="icon-font-decrease"></i>
		</nova-nav-border-item>
		<nova-nav-border-item
			class="icon hide-for-small-only"
			(click)="switchColumns()"
			[hidden]="!uiEventHandler.textFloor || !uiEventHandler.columnizeEnabled"
		>
			<i [ngClass]="columnClass()"></i>
		</nova-nav-border-item>
		<nova-nav-border-item class="icon" id="share" [hidden]="uiEventHandler.disableNav || ssr.hasToken()">
			<i class="icon-share-new"></i>
			<div class="sub">
				<div class="bg">
					<nova-nav-border-item class="icon" (click)="share('xing')">
						<i class="icon-xing"></i>
					</nova-nav-border-item>
					<nova-nav-border-item class="icon" (click)="share('facebook')">
						<i class="icon-facebook"></i>
					</nova-nav-border-item>
					<nova-nav-border-item class="icon" (click)="share('twitter')">
						<i class="icon-twitter"></i>
					</nova-nav-border-item>
					<nova-nav-border-item class="icon" (click)="share('mail')">
						<i class="icon-mail-o"></i>
					</nova-nav-border-item>
				</div>
			</div>
			<div class="sub2">
				<div class="bg">
					<nova-nav-border-item
						class="icon"
						(click)="share('whatsapp')"
						[hidden]="!uiEventHandler.isMobile()"
					>
						<i class="icon-whatsapp"></i>
					</nova-nav-border-item>
					<nova-nav-border-item class="icon" (click)="share('pinterest')">
						<i class="icon-pinterest"></i>
					</nova-nav-border-item>
					<nova-nav-border-item class="icon" (click)="share('linkedin')">
						<i class="icon-linkedin"></i>
					</nova-nav-border-item>
				</div>
			</div>
		</nova-nav-border-item>
		<nova-nav-border-item
			[hidden]="
				!uiEventHandler.showMenu || !nova.pages || nova.pages.length == 0 || uiEventHandler.customContentTable
			"
			class="icon"
			(click)="toggleContentTable()"
			id="openContentTable"
		>
			<i class="icon-open-book"></i>
		</nova-nav-border-item>
		<nova-nav-border-item
			[hidden]="!uiEventHandler.showMenu || !nova.pages || nova.pages.length == 0"
			class="icon"
			(click)="toggleSearch()"
			id="openSearch"
		>
			<i class="icon-search"></i>
		</nova-nav-border-item>
		<nova-nav-border-item
			*ngIf="magazine.selectStartPage == 'archiv'"
			class="icon show-for-small-only"
			(click)="uiEventHandler.showLandingSidebar = true"
		>
			<i class="icon-big-folder"></i>
		</nova-nav-border-item>
		<nova-nav-border-item
			class="icon show-for-small-only mobile-branding"
			(click)="openurl(getBrandingLink())"
			*ngIf="magazine.branding"
		>
			<img src="assets/img/faviconnova.svg" />
		</nova-nav-border-item>
	</div>
</nova-nav-border-item>
