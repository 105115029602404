<div class="white-popup mfp-with-anim" [ngStyle]="{'background-color':getColor(data['background_color'])}">
  <div class="table-row scrollbar-holder">
    <div class="scroller">
      <nova-content-sidebar [gallery]="gallery" [data]="data"></nova-content-sidebar>
      <div class="text-content">
        <nova-scrollbar-holder>
          <div class="scroller" [ngStyle]="{'fontSize':getFontSize()}">
            <div class="interview" *ngIf="loaded">
                <div *ngFor="let block of data.blocks;" class="row first" [class.first]="block.showSide == 'left'" [class.second]="block.showSide == 'right'">
                    <div class="col-md-6" [class.right]="block.showSide == 'right'">
                        <div *ngIf="block.mediaUrl" [ngStyle]="{'background-image':'url(' + block.mediaUrl + ')'}" class="icon"></div>
                        <div class="box" [class.no-img]="!block.mediaUrl">
                          <nova-element *ngIf="block.name" class="author-name" [data]="block" name="name"></nova-element>
                          <div [ngStyle]="{'background-color':getColor(block['background_color'])}">
                            <span *ngIf="block.showSide == 'right'" [ngStyle]="{'border-left-color':getColor(block['background_color'])}"></span>
                            <span *ngIf="block.showSide == 'left'" [ngStyle]="{'border-right-color':getColor(block['background_color'])}"></span>
                            <nova-text-element *ngIf="block.text" (openGallery)="gallery.openSlider($event)" [data]="block.text"></nova-text-element>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </nova-scrollbar-holder>
      </div>
    </div>
  </div>
  <nova-text-gallery [medias]="data.textImages" #gallery></nova-text-gallery>
</div>
