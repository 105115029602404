<div id="page-{{ id }}" class="page page-index-hotel"
     [ngStyle]="{'backgroundColor':getColor(data['background_color'])}">
    <nova-bg [data]="data" [forceVideo]="true"></nova-bg>
    <nova-scrollbar-holder>
        <div class="scroller">
            <div class="show-for-medium-only">
                <nova-media [mediaId]="data.background_media" version="horizontal"></nova-media>
            </div>
            <div class="center clear">
                <div class="title-wrapper">
                    <nova-title [data]="data"></nova-title>
                </div>
                <div class="filter-bar">
                    <div class="filter-bar__filters">
                        <div class="filter-bar__box">
                            <angular2-multiselect
                                [data]="allFilters"
                                [(ngModel)]="selectedFilters"
                                (ngModelChange)="filter()"
                                [settings]="dropdownSettings"
                            ></angular2-multiselect>
                        </div>
                        <div class="filter-bar__box">
                            <angular2-multiselect
                                [data]="allCountryFilters"
                                [(ngModel)]="selectedCountryFilters"
                                (ngModelChange)="filter()"
                                [settings]="countryDropdownSettings"
                            ></angular2-multiselect>
                        </div>
                        <div class="filter-bar__box">
                            <angular2-multiselect
                                [data]="stars"
                                [(ngModel)]="selectedStars"
                                (ngModelChange)="filter()"
                                [settings]="starDropdownSettings"
                            ></angular2-multiselect>
                        </div>
                    </div>

                    <p class="space-top"><b
                        #font_size_bold>{{(filteredData).length}}</b>
                        {{(filteredData).length  == 1 ? 'Hotel' : 'Hotels'}}
                        verfügbar</p>
                </div>
                <div class="product-list flex-container">
                    <div *ngFor="let block of premium;"
                         [@fadeAnimation]="'in'"
                         class="flex-container hotel-item premium"
                         [ngStyle]="{backgroundColor: getColor(block.background_color)}">
                        <app-hotel (onClick)="goTo($event)" [hotel]="block"></app-hotel>
                    </div>
                </div>
                <div class="product-list flex-container">
                    <div class="flex-container hotel-item standard"
                         *ngFor="let block of standard"
                         [@fadeAnimation]="'in'"
                         [ngStyle]="{backgroundColor: getColor(block.background_color)}">
                        <app-hotel [hotel]="block" (onClick)="goTo($event)"></app-hotel>
                    </div>
                    <div class="hotel-item standard"
                         *ngIf="(((filteredData).length % 3) + 3) % 3 === 2"></div>
                </div>

                <div class="filter-bar" *ngIf="data.text">
                    <nova-text-element [data]="data.text"></nova-text-element>
                </div>
            </div>
        </div>
        <nova-text-gallery [medias]="data.textImages" #gallery></nova-text-gallery>
        <nova-font-loader *ngIf="data.font" [fonts]="[data.font]"></nova-font-loader>
        <nova-font-loader *ngIf="data.font_bold" [fonts]="[data.font_bold]"></nova-font-loader>
    </nova-scrollbar-holder>
</div>
