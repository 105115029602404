import * as tslib_1 from "tslib";
import { ElementRef, OnInit, QueryList, Renderer2 } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import { SharedHotelHelperService } from '../hotel-detail-standard/shared-hotel-helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IndexTransferFilterPipe } from './filter-tags.pipe';
import { setRemainingFilters, setFilters } from '../index-trips/index-trips.component';
var PageIndexTransferComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageIndexTransferComponent, _super);
    function PageIndexTransferComponent(nova, uiEventHandler, sanitizer, meta, route, router, transferHelper, media, title, renderer, filterPipe) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.sanitizer = sanitizer;
        _this.meta = meta;
        _this.route = route;
        _this.router = router;
        _this.transferHelper = transferHelper;
        _this.media = media;
        _this.title = title;
        _this.renderer = renderer;
        _this.filterPipe = filterPipe;
        _this.filteredData = [];
        _this.allInterestFilters = [];
        _this.allStartingPointsFilters = [];
        _this.allDestinationPointsFilters = [];
        _this.selectedInterests = [];
        _this.selectedStartingPoints = [];
        _this.selectedDestinationPoints = [];
        _this.interestsDropdownSettings = {
            singleSelection: false,
            text: 'Nach Transferart filtern',
            enableCheckAll: false,
            showCheckbox: true,
            enableSearchFilter: false,
            classes: 'nova-multiselect'
        };
        _this.startingPointsDropdownSettings = {
            singleSelection: false,
            text: 'Nach Startort filtern',
            enableCheckAll: false,
            showCheckbox: true,
            enableSearchFilter: false,
            classes: 'nova-multiselect'
        };
        _this.destinationPointsDropdownSettings = {
            singleSelection: false,
            text: 'Nach Zielort filtern',
            enableCheckAll: false,
            showCheckbox: true,
            enableSearchFilter: false,
            classes: 'nova-multiselect'
        };
        _this.contentFontStyle = '';
        _this.allInterestFilterStrings = [];
        _this.allStartingPointsFilterStrings = [];
        _this.allDestinationPointsFilterStrings = [];
        var path = window.location.pathname;
        var filter = sessionStorage.getItem('filter:' + path);
        if (filter) {
            var filterData = JSON.parse(filter);
            if (filterData.selectedInterestFilters) {
                _this.selectedInterests = filterData.selectedInterests;
            }
            if (filterData.selectedStartingPoints) {
                _this.selectedStartingPoints = filterData.selectedStartingPoints;
            }
            if (filterData.selectedDestinationPoints) {
                _this.selectedDestinationPoints = filterData.selectedDestinationPoints;
            }
        }
        return _this;
    }
    PageIndexTransferComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.data.contents.forEach(function (content) {
            return _this.transferHelper.handleTagInputs(content, [
                'interests',
                'categories',
                'startingpoint',
                'destinationpoint'
            ]);
        });
        this.allInterestFilters = setFilters(this.allInterestFilterStrings, this.data.contents, 'interests');
        this.allStartingPointsFilters = setFilters(this.allStartingPointsFilterStrings, this.data.contents, 'startingpoint');
        this.allDestinationPointsFilters = setFilters(this.allDestinationPointsFilterStrings, this.data.contents, 'destinationpoint');
        this.filter();
    };
    PageIndexTransferComponent.prototype.filter = function () {
        this.filteredData = this.filterPipe.transform(this.data.contents, this.selectedInterests, this.selectedStartingPoints, this.selectedDestinationPoints);
        // FTI-83 - dont show transfers without name
        this.filteredData = this.filteredData.filter(function (transfer) { return transfer.header !== undefined; });
        // setRemainingFilters(this.allFilters, this.filteredData, 'transfer_categories');
        setRemainingFilters(this.allStartingPointsFilters, this.filteredData, 'startingpoint');
        setRemainingFilters(this.allDestinationPointsFilters, this.filteredData, 'destinationpoint');
        var path = window.location.pathname;
        sessionStorage.setItem('filter:' + path, JSON.stringify({
            selectedInterestFilters: this.selectedInterests,
            selectedStartingPoints: this.selectedStartingPoints,
            selectedDestinationPoints: this.selectedDestinationPoints
        }));
    };
    PageIndexTransferComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.data['font']) {
            this.nova.getFont(this.data['font']).subscribe(function (fontData) {
                _this.renderer.setStyle(_this.fontSizeElement.nativeElement, 'font-family', fontData.name);
            });
        }
        if (this.data['filter_font'] && this.filterFontElement) {
            this.nova.getFont(this.data['filter_font']).subscribe(function (fontData) {
                _this.renderer.setStyle(_this.filterFontElement.nativeElement, 'font-family', fontData.name);
            });
        }
        if (this.data['transfer_detail_header_font']) {
            this.nova.getFont(this.data['transfer_detail_header_font']).subscribe(function (fontData) {
                _this.transferDetailHeaderElements &&
                    _this.transferDetailHeaderElements.toArray().map(function (element) {
                        _this.renderer.setStyle(element.nativeElement, 'font-family', fontData.name);
                    });
            });
        }
        if (this.data['transfer_detail_font']) {
            this.nova.getFont(this.data['transfer_detail_font']).subscribe(function (fontData) {
                _this.contentFontStyle = fontData.name;
            });
        }
    };
    PageIndexTransferComponent.prototype.goTo = function (id) {
        var floor = this.data.contents.filter(function (content) { return content._id === id; })[0];
        var params = this.route.params['value'];
        this.router.navigate(['/', params['langHandle'], params['issueHandle'], params['pageHandle'], floor.handle], {
            queryParamsHandling: 'preserve'
        });
    };
    PageIndexTransferComponent = tslib_1.__decorate([
        NovaTemplate('PageIndexTransferComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            DomSanitizer,
            Meta,
            ActivatedRoute,
            Router,
            SharedHotelHelperService,
            MediaService,
            Title,
            Renderer2,
            IndexTransferFilterPipe])
    ], PageIndexTransferComponent);
    return PageIndexTransferComponent;
}(Page));
export { PageIndexTransferComponent };
