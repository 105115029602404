/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nova-ci-logo.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./nova-ci-logo.component";
import * as i4 from "../../_services/nova-api.service";
import * as i5 from "../../_services/magazine.service";
import * as i6 from "../../_services/uievent-handler.service";
import * as i7 from "@angular/router";
var styles_NovaCILogoComponent = [i0.styles];
var RenderType_NovaCILogoComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_NovaCILogoComponent, data: {} });
export { RenderType_NovaCILogoComponent as RenderType_NovaCILogoComponent };
function View_NovaCILogoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "branding"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "assets/img/novamag.svg"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getBrandingLink(); _ck(_v, 0, 0, currVal_0); }); }
export function View_NovaCILogoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaCILogoComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isVisible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NovaCILogoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-ci-logo", [], null, null, null, View_NovaCILogoComponent_0, RenderType_NovaCILogoComponent)), i1.ɵdid(1, 245760, null, 0, i3.NovaCILogoComponent, [i4.NovaAPIService, i5.MagazineService, i6.UIEventHandlerService, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NovaCILogoComponentNgFactory = i1.ɵccf("nova-ci-logo", i3.NovaCILogoComponent, View_NovaCILogoComponent_Host_0, {}, {}, []);
export { NovaCILogoComponentNgFactory as NovaCILogoComponentNgFactory };
