/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./iframe.component";
import * as i2 from "@angular/platform-browser";
var styles_IframeComponent = [];
var RenderType_IframeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IframeComponent, data: {} });
export { RenderType_IframeComponent as RenderType_IframeComponent };
export function View_IframeComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { iframe: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["iframe", 1]], null, 0, "iframe", [["style", "width: 100%; border: 0; overflow-x: hidden; overflow-y: auto;"]], [[8, "src", 5]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.onIframeLoaded() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sanitizedUrl; _ck(_v, 1, 0, currVal_0); }); }
export function View_IframeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "nova-iframe", [], null, null, null, View_IframeComponent_0, RenderType_IframeComponent)), i0.ɵdid(1, 4440064, null, 0, i1.IframeComponent, [i2.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IframeComponentNgFactory = i0.ɵccf("nova-iframe", i1.IframeComponent, View_IframeComponent_Host_0, { src: "src", minHeight: "minHeight", heightCalculationMethod: "heightCalculationMethod", scrolling: "scrolling" }, {}, []);
export { IframeComponentNgFactory as IframeComponentNgFactory };
