import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MagazineService } from './magazine.service';
import { NovaAPIService } from './nova-api.service';
import Plausible from './lib/tracker';
import { environment } from 'environments/environment';

declare let ga;
declare let gtag;

@Injectable()
export class InjectService {
    private injectedAnalytics;
    public tracker;

    constructor(private router: Router, private magService: MagazineService, private nova: NovaAPIService) {}

    public injectPlausibleProvider() {
        if (!environment.plausibleApiUrl) return;

        const { enableAutoPageviews } = Plausible({
            trackLocalhost: true,
            domain: window.location.host,
            apiHost: environment.plausibleApiUrl
        });

        enableAutoPageviews();
    }

    public gaConf() {
        if (typeof ga === 'function') {
            this.tracker = ga;
        }

        if (typeof gtag === 'function') {
            this.tracker = gtag;
        }

        if (this.tracker) {
            this.tracker('set', 'anonymizeIp', true);
            this.tracker('require', 'linker');
            this.tracker('linker:autoLink', ['novamag.me']);
            this.tracker('send', 'pageview');
        }
    }

    public injectAnalyticsProvider(project, language?: string) {
        $('#analytics').empty();
        const trackingProvider = project.trackingProvider;
        language = language && language !== 'all' ? language : project.language;

        switch (trackingProvider) {
            case 'analytics':
                return this.injectAnalytics();

            default:
                return null;
        }
    }

    public injectAnalytics() {
        const params =
            this.router.routerState.root.firstChild && this.router.routerState.root.firstChild['params']['value'];

        if (params && params.issueHandle) {
            this.nova.getCurrentIssue().subscribe(
                issue => {
                    if (issue.analytics && issue._id !== this.injectedAnalytics) {
                        this.injectedAnalytics = issue._id;
                        $('#analytics')
                            .empty()
                            .append(issue.analytics);
                        this.gaConf();
                    }

                    if (!issue.analytics) {
                        this.magService.getMagazine().subscribe(
                            magazine => {
                                if (magazine.analytics && magazine._id !== this.injectedAnalytics) {
                                    this.injectedAnalytics = magazine._id;
                                    $('#analytics')
                                        .empty()
                                        .append(magazine.analytics);
                                    this.gaConf();
                                }
                            },
                            err => {
                                console.log('injectAnalytics -> getCurrentIssue -> getMagazine', err);
                            }
                        );
                    }
                },
                err => {
                    console.log('injectAnalytics -> getCurrentIssue', err);
                }
            );
        } else {
            this.magService.getMagazine().subscribe(
                magazine => {
                    if (magazine.analytics && magazine._id !== this.injectedAnalytics) {
                        this.injectedAnalytics = magazine._id;

                        $('#analytics')
                            .empty()
                            .append(magazine.analytics);

                        this.gaConf();
                    }
                },
                err => {
                    console.log('injectAnalytics -> getMagazine', err);
                }
            );
        }
    }
}
