import {
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
    SimpleChanges
} from '@angular/core';
import { OnChanges } from '@angular/core/src/metadata/lifecycle_hooks';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NovaAPIService } from '../../_services/nova-api.service';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import { Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Renderer2 } from '@angular/core';
import { MediaService } from 'app/_services/media.service';

import * as Plyr from 'plyr';
import Hls from 'hls.js';

@Component({
    selector: 'nova-media',
    templateUrl: './nova-media.component.html',
    styleUrls: ['./nova-media.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NovaMediaComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public bg: boolean;
    @Input() public backgroundPosition = 'center center';
    @Input() public version: string;
    @Input() public fallback: boolean;
    @HostBinding('class.nofullscreen') noFullscreen = false;
    @Input() public mediaId: string;
    @Input() public copyright = true;
    @Input() public copyrightPosition = 'right';
    @Input() public opacity: string;
    @Input() public autoplay: boolean;
    @Input() public forceVideo = false;
    @Input() public forceAutoplay = false;
    @Input() public iframe = false;

    @Input() public playOnHover: boolean;
    @Input() public forcePlay: boolean;

    @ViewChild('videoElm') private videoElm: ElementRef;
    @ViewChild('videoBgElm') private videoBgElm: ElementRef;
    @ViewChild('bgElm') private bgElm: ElementRef;

    public mediaData;
    public mediaUrl;

    public video: boolean;
    public videoSources: any;
    public customScreenshot?: {
        contentType: string;
        filename: string;
        size: number;
        url: string;
    };

    public trustedUrl: SafeResourceUrl;
    private muteSub: Subscription;
    public player: any;

    public copyrightPositionCSS = {};

    constructor(
        public novaAPI: NovaAPIService,
        public mediaService: MediaService,
        public renderer: Renderer2,
        private sanitizer: DomSanitizer,
        public uiEventHandler: UIEventHandlerService
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.mediaId.previousValue !== changes.mediaId.currentValue) {
            this.mediaService
                .getMedia(changes.mediaId.currentValue)
                .pipe(
                    mergeMap(data => {
                        if (this.mediaService.isVideo(data)) {
                            this.video = true;
                            this.videoSources = this.mediaService.getVideo(data, this.bg);
                        }
                        this.mediaData = data;

                        if (data && 'customScreenshot' in data && data.customScreenshot) {
                            this.customScreenshot = data.customScreenshot.url;
                        }

                        return this.mediaService.getMediaUrl(
                            this.mediaId,
                            (this.video && 'screenshot') || this.version
                        );
                    })
                )
                .subscribe(url => {
                    this.mediaUrl = url;

                    if (this.player) {
                        this.player.stop();

                        if (this.video && typeof window !== undefined) {
                            setTimeout(() => {
                                this.player.source = {
                                    type: 'video',
                                    poster: this.customScreenshot || this.mediaUrl,
                                    sources: [
                                        {
                                            src: this.videoSources[0].src || this.mediaData.url,
                                            type: this.videoSources[0].type || this.mediaData.contentType
                                        }
                                    ]
                                };

                                if (this.forceAutoplay) {
                                    setTimeout(() => {
                                        this.player.play();
                                    }, 500);
                                }
                            }, 10);
                        }
                    }
                });
        }
    }

    ngOnInit() {
        if (this.copyright) {
            this.copyrightPositionCSS = {
                bottom: '41px',
                right: this.copyrightPosition === 'right' ? '78px' : 'auto',
                left: this.copyrightPosition === 'right' ? 'auto' : '20px',
                'transform-origin': 'top right',
                transform: 'rotate(-90deg) translateX(100%)'
            };
        }

        this.noFullscreen = !this.bg;
        let isWebkit = false;

        if (typeof window !== 'undefined') {
            isWebkit = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        }

        if (this.novaAPI.isScreenShot) {
            this.fallback = true;
        }

        if (this.uiEventHandler.isMobile() && this.bg && !this.forceVideo) {
            this.fallback = true;
        }

        if (this.mediaId) {
            this.mediaService
                .getMedia(this.mediaId)
                .pipe(
                    mergeMap(data => {
                        if (this.mediaService.isVideo(data)) {
                            this.video = true;
                            this.videoSources = this.mediaService.getVideo(data, this.bg);
                        }
                        this.mediaData = data;
                        if (data && 'customScreenshot' in data && data.customScreenshot) {
                            this.customScreenshot = data.customScreenshot.url;
                        }

                        if (this.videoSources) {
                            this.mediaUrl = data.thumbnail;
                        }

                        return this.mediaService.getMediaUrl(
                            this.mediaId,
                            (this.video && 'screenshot') || this.version
                        );
                    })
                )
                .subscribe(url => {
                    if (!this.mediaUrl) {
                        this.mediaUrl = url;
                    }

                    if (this.iframe) {
                        this.trustedUrl =
                            'iframeUrl' in this.mediaData
                                ? this.sanitizer.bypassSecurityTrustResourceUrl(this.mediaData.iframeUrl)
                                : '';

                        return;
                    }

                    if (this.bg || this.autoplay) {
                        setTimeout(() => {
                            if (this.bg && this.bgElm) {
                                this.renderer.setStyle(
                                    this.bgElm.nativeElement,
                                    'background-position',
                                    this.backgroundPosition
                                );

                                this.renderer.setStyle(
                                    this.bgElm.nativeElement,
                                    'background-image',
                                    'url(' + this.mediaService.getImageProxyUrl(this.mediaUrl, this.version) + ')'
                                );
                            }

                            if (this.opacity) {
                                this.renderer.setStyle(this.bgElm.nativeElement, 'opacity', this.opacity);
                            }
                            if (this.video && this.novaAPI.isScreenShot) {
                                this.renderer.setStyle(
                                    this.bgElm.nativeElement,
                                    'background-image',
                                    `url(${this.mediaData.thumbnail})`
                                );

                                return;
                            }

                            if (this.video && typeof window !== 'undefined' && this.videoBgElm && this.mediaData) {
                                if (this.mediaData.type && this.mediaData.type === 'bunny') {
                                    if (Hls.isSupported()) {
                                        const hls = new Hls({
                                            enableWorker: true,
                                            startLevel: 5
                                        });

                                        hls.loadSource(this.mediaData.url);
                                        hls.attachMedia(this.videoBgElm.nativeElement);

                                        hls.on(Hls.Events.MANIFEST_PARSED, () => {
                                            this.videoBgElm.nativeElement.play();
                                        });
                                    } else {
                                        if (
                                            this.videoBgElm &&
                                            this.videoBgElm.nativeElement.canPlayType('application/vnd.apple.mpegurl')
                                        ) {
                                            this.videoBgElm.nativeElement.src = this.mediaData.url;
                                            this.player = new Plyr(this.videoBgElm.nativeElement, {
                                                autoplay: !this.playOnHover,
                                                autopause: this.playOnHover,
                                                loop: { active: this.bg },
                                                muted: this.bg,
                                                controls: [],
                                                clickToPlay: false,
                                                fullscreen: { enabled: false },
                                                storage: { enabled: false }
                                            });
                                        }
                                    }
                                }

                                if (this.mediaService.isLocalVideo(this.mediaData)) {
                                    setTimeout(() => {
                                        this.player = new Plyr(this.videoBgElm.nativeElement, {
                                            autoplay: !this.playOnHover,
                                            autopause: this.playOnHover,
                                            loop: { active: this.bg },
                                            muted: this.bg,
                                            controls: [],
                                            clickToPlay: false,
                                            fullscreen: { enabled: false },
                                            storage: { enabled: false }
                                        });
                                    }, 10);
                                } else if (this.mediaService.isVimeo(this.mediaData)) {
                                    if (typeof window !== undefined) {
                                        const vimeoSettings: {
                                            url: string;
                                            autoplay: boolean;
                                            autopause: boolean;
                                            loop: boolean;
                                            muted: boolean;
                                            controls?;
                                            responsive: boolean;
                                            transparent: boolean;
                                        } = {
                                            url: this.mediaData.url,
                                            autoplay: !this.playOnHover,
                                            autopause: this.playOnHover,
                                            loop: this.bg,
                                            muted: this.bg,
                                            responsive: true,
                                            transparent: true
                                        };

                                        if (this.bg) {
                                            vimeoSettings.controls = 0;
                                        }

                                        this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                                            this.videoSources[0].src + (this.bg && '?background=true')
                                        );

                                        setTimeout(() => {
                                            this.player = new Plyr(this.videoBgElm.nativeElement, {
                                                autoplay: !this.playOnHover,
                                                autopause: this.playOnHover,
                                                loop: { active: this.bg },
                                                muted: this.playOnHover || this.bg,
                                                controls: [],
                                                clickToPlay: false,
                                                fullscreen: { enabled: false },
                                                vimeo: { ...vimeoSettings }
                                            });
                                        }, 10);
                                    }
                                }
                            }
                        }, 10);
                    }

                    setTimeout(() => {
                        if (
                            this.video &&
                            this.videoElm &&
                            this.mediaData &&
                            this.mediaData.type &&
                            this.mediaData.type === 'bunny' &&
                            !this.bg
                        ) {
                            if (Hls.isSupported()) {
                                if (typeof window !== undefined) {
                                    setTimeout(() => {
                                        const videoOptions = {
                                            quality: {
                                                default: 720,
                                                options: [0, 720]
                                            }
                                        };

                                        const hls = new Hls({
                                            enableWorker: true,
                                            startLevel: 5
                                        });

                                        hls.loadSource(this.mediaData.url);
                                        hls.attachMedia(this.videoElm.nativeElement);
                                        hls.on(Hls.Events.MANIFEST_PARSED, () => {
                                            if (hls.currentLevel === -1) {
                                                // Auto
                                                if (hls.levels.length === 1) {
                                                    hls.currentLevel = 0;
                                                } else if (hls.levels.length === 2) {
                                                    hls.currentLevel = 1;
                                                } else {
                                                    hls.currentLevel = 2;
                                                }
                                            }

                                            this.player = new Plyr(this.videoElm.nativeElement, {
                                                ...videoOptions,
                                                muted: this.bg,
                                                autoplay: true
                                            });
                                        });
                                    }, 10);
                                }
                            } else {
                                // handle ios safari
                                this.videoSources = [
                                    {
                                        src: this.mediaData.url,
                                        type: 'audio/mpegURL'
                                    }
                                ];
                            }

                            return;
                        }
                    }, 0);

                    if (this.video && !this.fallback && !this.bg && this.mediaData) {
                        if (this.mediaService.isLocalVideo(this.mediaData)) {
                            if (typeof window !== undefined) {
                                setTimeout(() => {
                                    this.player = new Plyr(this.videoElm.nativeElement, {});

                                    if (this.forceAutoplay) {
                                        setTimeout(() => {
                                            this.player.play();
                                        }, 500);
                                    }
                                }, 10);
                            }
                        } else if (this.videoSources.length > 0) {
                            const isYoutube = this.videoSources[0].src.startsWith('https://www.youtube.com');
                            const videoUrl =
                                (isYoutube && this.videoSources[0].src + '&rel=0&control=1') ||
                                this.videoSources[0].src;

                            const cleanVideoUrl = isYoutube
                                ? videoUrl.replace(
                                      'https://www.youtube.com/embed/https://www.youtube.com/embed/',
                                      'https://www.youtube.com/embed/'
                                  )
                                : videoUrl;

                            this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(cleanVideoUrl);

                            if (typeof window !== undefined) {
                                setTimeout(() => {
                                    this.player = this.videoElm && new Plyr(this.videoElm.nativeElement, {});
                                }, 10);
                            }
                        }
                    }
                });
        }
    }

    getCopyrightUrl(c) {
        if (c) {
            if (!c.startsWith('http://') && !c.startsWith('https://')) {
                return 'http://' + c;
            }

            return c;
        }
    }

    mouseover() {
        if (this.player) {
            this.player.play();
        }
    }

    mouseout(event) {
        if (this.player) {
            const e = event.toElement || event.relatedTarget;

            if (e && $(e.parentNode).hasClass('center')) {
                return false;
            }

            this.player.pause();
        }
    }

    ngOnDestroy() {
        this.uiEventHandler.videoBg = false;

        if (this.player) {
            this.player.destroy();
        }

        if (this.muteSub) {
            this.muteSub.unsubscribe();
        }
    }
}
