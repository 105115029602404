<div id="page-{{ id }}" class="page page-cover">
	<nova-bg [data]="data" [forceVideo]="true"></nova-bg>
	<div class="flexbox" [ngStyle]="setMyStyles(flexboxStyle)">
		<div class="flex-item flex-helper" [ngStyle]="setMyStyles(helperStyle)"></div>
		<div class="flex-item logo" #logo [ngStyle]="setMyStyles(logoStyle)">
			<div [ngStyle]="setMyStyles(logo_size)" *ngIf="data.logo && data.logo.url">
				<img [src]="data.logo.url" alt="Logo" />
			</div>
		</div>
		<!--<div class="flex-item logo" ng-style="logoStyle" ng-class="{defaultLogo: !data.logo.url}"></div>-->
		<div [ngStyle]="setMyStyles(titleStyle)" class="flex-item title-item">
			<h3 *ngIf="data.label" class="dateline"><nova-element [data]="data" name="label"></nova-element></h3>
			<h1 *ngIf="data.header"><nova-element [data]="data" name="header"></nova-element></h1>
			<h2 *ngIf="data.subHeader"><nova-element [data]="data" name="subHeader"></nova-element></h2>
			<p class="text"><nova-element [data]="data" name="text"></nova-element></p>
		</div>
	</div>
    <div class="ta" *ngIf="data.travelAgency">
        <h4 class="ta__headline">{{ 'TRAVEL_AGENCY_HEADER' | translate }}</h4>
        <div class="ta__content">
            <p *ngIf="data.travelAgency.fullname">{{data.travelAgency.fullname}}</p>
            <p *ngIf="data.travelAgency.street">{{data.travelAgency.street}}</p>
            <p *ngIf="data.travelAgency.city || data.travelAgency.zip">{{[data.travelAgency.zip, data.travelAgency.city].join(" ")}}</p>
            <p *ngIf="data.travelAgency.phone">{{ 'TRAVEL_AGENCY_PHONE' | translate }}: {{data.travelAgency.phone}}</p>
            <p *ngIf="data.travelAgency.fax">{{ 'TRAVEL_AGENCY_FAX' | translate }}: {{data.travelAgency.fax}}</p>
            <p *ngIf="data.travelAgency.email">{{ 'TRAVEL_AGENCY_MAIL' | translate }}: {{data.travelAgency.email}}</p>
        </div>
    </div>
</div>
