import { Component, Input } from '@angular/core';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { MagazineService } from '../../../_services/magazine.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'nova-budapest-overlay-social',
    templateUrl: './nova-budapest-overlay-social.component.html',
    styleUrls: ['./nova-budapest-overlay-social.component.scss']
})
export class NovaBudapestOverlaySocialComponent {
    @Input() public font;
    @Input() magazine;

    constructor(
        public nova: NovaAPIService,
        public mag: MagazineService,
        public uiEventHandler: UIEventHandlerService,
        public router: Router,
        public translate: TranslateService
    ) {}

    share(type) {
        const params = this.router.routerState.root.firstChild.params['value'];
        if (params['issueHandle']) {
            this.nova.getPageData().subscribe(data => {
                this.realShare(type, data['data']);
            });
        } else {
            this.realShare(type);
        }
    }

    private realShare(type, data?) {
        let shareUrl = window.location.href; // current page
        shareUrl = shareUrl.replace('#/', '');
        const encodedUrl = encodeURIComponent(shareUrl);

        this.translate.get(['MAIL_SUBJECT', 'MAIL_BODY']).subscribe((res: Object) => {
            const mailSubject = res['MAIL_SUBJECT'];
            const mailBody = res['MAIL_BODY'];

            // get data from current page
            let pageTitle = '';
            const title = this.getTitle();

            // image url
            let media = '';
            if (data) {
                media = data.thumb;
                pageTitle = data.header;
            }

            if (type === 'mail') {
                window.location.href = 'mailto:?&subject=' + mailSubject + '&body=' + mailBody + shareUrl;
            } else if (type === 'whatsapp') {
                window.location.href = 'whatsapp://send?text=' + mailBody + shareUrl;
            } else {
                let host = '';

                if (type === 'xing') {
                    host = 'https://www.xing.com/spi/shares/new?url=' + encodedUrl;
                } else if (type === 'facebook') {
                    host = 'https://www.facebook.com/sharer/sharer.php?u=' + encodedUrl;
                    if (media) {
                        host += '&picture=' + media;
                    }
                    host += '&title=' + title + '&description=' + pageTitle;
                } else if (type === 'linkedin') {
                    host =
                        'https://www.linkedin.com/shareArticle?mini=true&title=' +
                        mailSubject +
                        '&summary=' +
                        mailBody +
                        '&source=&url=' +
                        shareUrl;
                } else if (type === 'twitter') {
                    host = 'https://twitter.com/intent/tweet?text=' + mailBody + shareUrl;
                } else if (type === 'pinterest') {
                    host =
                        'https://pinterest.com/pin/create/button/?url=' +
                        shareUrl +
                        '&description=' +
                        mailBody +
                        shareUrl +
                        '&media=' +
                        media;
                }

                window.open(host, '_blank');
            }
        });
    }

    getTitle() {
        if (this.nova.issue) {
            return this.nova.issue.name;
        } else if (this.magazine) {
            return this.magazine.name;
        }

        return 'NO TITLE SET';
    }
}
