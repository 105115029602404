<div class="flex-container roundtrip-item__header">
    <h2 *ngIf="roundtrip.header" class="show-pointer roundtrip-item__title"
        style="margin: 0;" (click)="goTo(roundtrip._id)">
        <nova-element name="header" [ignoreFont]="true" [ignoreSize]="true" [data]="roundtrip"></nova-element>
    </h2>
</div>
<div class="flex-container">
    <nova-media *ngIf="roundtrip.background_media"
                [mediaId]="roundtrip.background_media" (click)="goTo(roundtrip._id)" version="horizontal"
                class="show-pointer" [fallback]="true"></nova-media>

    <nova-media *ngIf="!roundtrip.background_media && roundtrip.medias && roundtrip.medias[0].slider_media; else fallback"
                [mediaId]="roundtrip.medias[0].slider_media" (click)="goTo(roundtrip._id)" version="horizontal"
                class="show-pointer" [fallback]="true"></nova-media>

    <ng-template #fallback>
        <img
            class="roundtrip-item__image show-pointer"
            (click)="goTo(roundtrip._id)"
            *ngIf="roundtrip.images && [].constructor.isArray(roundtrip.images) && roundtrip.images[0].filename"
            [src]="roundtrip.images && getImageProxyUrl(roundtrip.images[0].filename)"
            [alt]="roundtrip.images[0].description1"
        >
    </ng-template>
    <div style="position: relative" >
        <div style="position: absolute; left: 20px; bottom: 10px;">
            <div *ngIf="roundtrip.roundtrip_categories" class="tags footer-tags">
                <nova-element *ngFor="let label of roundtrip.roundtrip_categories; let i = index;" [ignoreFont]="true"
                          [data]="roundtrip" name="{{'roundtrip_categories_' + i}}"></nova-element>
            </div>

            <div *ngIf="roundtrip.interests" class="tags" >
                <nova-element *ngFor="let label of roundtrip.interests; let i = index;" [ignoreFont]="true"
                              [data]="roundtrip" name="{{'interests_' + i}}" [style.font-family]="contentFontStyle"></nova-element>
            </div>
        </div>
    </div>
</div>

<div class="roundtrip__footer" [style.font-family]="contentFontStyle">
    <nova-text-element [data]="roundtrip.producttags" ></nova-text-element>
</div>
