/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./easy-scroll.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../_components/nova-bg/nova-bg.component.ngfactory";
import * as i3 from "../../../_components/nova-bg/nova-bg.component";
import * as i4 from "../../../_services/uievent-handler.service";
import * as i5 from "../../../_components/nova-content-links/nova-content-links.component.ngfactory";
import * as i6 from "../../../_components/nova-content-links/nova-content-links.component";
import * as i7 from "../../../_services/nova-api.service";
import * as i8 from "@angular/router";
import * as i9 from "@angular/common";
import * as i10 from "../../../_components/nova-mobile-img/nova-mobile-img.component.ngfactory";
import * as i11 from "../../../_components/nova-mobile-img/nova-mobile-img.component";
import * as i12 from "../../../_services/media.service";
import * as i13 from "@angular/platform-browser";
import * as i14 from "../../../_components/nova-title/nova-title.component.ngfactory";
import * as i15 from "../../../_components/nova-title/nova-title.component";
import * as i16 from "../../../_components/nova-text-element/nova-text-element.component.ngfactory";
import * as i17 from "../../../_components/nova-text-element/nova-text-element.component";
import * as i18 from "../../../_services/magazine.service";
import * as i19 from "../../../_services/cookie.service";
import * as i20 from "../../../_components/nova-text-gallery/nova-text-gallery.component.ngfactory";
import * as i21 from "../../../_components/nova-text-gallery/nova-text-gallery.component";
import * as i22 from "./easy-scroll.component";
var styles_PageEasyScrollComponent = [i0.styles];
var RenderType_PageEasyScrollComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PageEasyScrollComponent, data: {} });
export { RenderType_PageEasyScrollComponent as RenderType_PageEasyScrollComponent };
function View_PageEasyScrollComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-bg", [], null, null, null, i2.View_NovaBgComponent_0, i2.RenderType_NovaBgComponent)), i1.ɵdid(1, 114688, null, 0, i3.NovaBgComponent, [i4.UIEventHandlerService], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PageEasyScrollComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-content-links", [["class", "content-links"]], null, null, null, i5.View_NovaContentLinksComponent_0, i5.RenderType_NovaContentLinksComponent)), i1.ɵdid(1, 114688, null, 0, i6.NovaContentLinksComponent, [i7.NovaAPIService, i8.Router, i8.ActivatedRoute, i4.UIEventHandlerService], { floors: [0, "floors"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.links; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PageEasyScrollComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "page page-easy-scroll"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageEasyScrollComponent_1)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "bg animated slideInLeft easeOutQuad scrollbar-holder"]], null, null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i9.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(5, { backgroundColor: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 9, "div", [["class", "scroller"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "nova-mobile-img", [["class", "show-for-small-only left"]], [[1, "style", 2]], null, null, i10.View_NovaMobileImgComponent_0, i10.RenderType_NovaMobileImgComponent)), i1.ɵdid(8, 114688, null, 0, i11.NovaMobileImgComponent, [i12.MediaService, i1.Renderer2, i13.DomSanitizer, i4.UIEventHandlerService], { data: [0, "data"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [["class", "shift clear"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "nova-title", [], [[2, "noBg", null]], null, null, i14.View_NovaTitleComponent_0, i14.RenderType_NovaTitleComponent)), i1.ɵdid(11, 114688, null, 0, i15.NovaTitleComponent, [], { data: [0, "data"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "nova-text-element", [["class", "con"]], null, [[null, "openGallery"]], function (_v, en, $event) { var ad = true; if (("openGallery" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).openSlider($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_NovaTextElementComponent_0, i16.RenderType_NovaTextElementComponent)), i1.ɵdid(13, 4964352, null, 0, i17.NovaTextElementComponent, [i4.UIEventHandlerService, i12.MediaService, i7.NovaAPIService, i8.Router, i13.DomSanitizer, i8.ActivatedRoute, i18.MagazineService, i19.CookieService, i9.DOCUMENT], { data: [0, "data"] }, { openGallery: "openGallery" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageEasyScrollComponent_2)), i1.ɵdid(15, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 1, "nova-text-gallery", [], null, null, null, i20.View_NovaTextGalleryComponent_0, i20.RenderType_NovaTextGalleryComponent)), i1.ɵdid(17, 49152, [["gallery", 4]], 0, i21.NovaTextGalleryComponent, [i4.UIEventHandlerService, i1.Renderer2, i9.DOCUMENT], { medias: [0, "medias"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.uiEventHandler.isMobile(); _ck(_v, 2, 0, currVal_1); var currVal_2 = _ck(_v, 5, 0, _co.getColor(_co.bgColor)); _ck(_v, 4, 0, currVal_2); var currVal_4 = _co.data; _ck(_v, 8, 0, currVal_4); var currVal_6 = _co.data; _ck(_v, 11, 0, currVal_6); var currVal_7 = _co.data.text; _ck(_v, 13, 0, currVal_7); var currVal_8 = (_co.data.links && (_co.data.links.length > 0)); _ck(_v, 15, 0, currVal_8); var currVal_9 = _co.data.textImages; _ck(_v, 17, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "page-", _co.id, ""); _ck(_v, 0, 0, currVal_0); var currVal_3 = i1.ɵnov(_v, 8).valueAsStyle; _ck(_v, 7, 0, currVal_3); var currVal_5 = !_co.data.background_media; _ck(_v, 10, 0, currVal_5); }); }
export function View_PageEasyScrollComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_PageEasyScrollComponent_0, RenderType_PageEasyScrollComponent)), i1.ɵdid(1, 114688, null, 0, i22.PageEasyScrollComponent, [i7.NovaAPIService, i4.UIEventHandlerService, i13.Meta, i13.Title, i8.ActivatedRoute, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageEasyScrollComponentNgFactory = i1.ɵccf("ng-component", i22.PageEasyScrollComponent, View_PageEasyScrollComponent_Host_0, {}, {}, []);
export { PageEasyScrollComponentNgFactory as PageEasyScrollComponentNgFactory };
