/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./full-image-slider.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../nova-media/nova-media.component.ngfactory";
import * as i3 from "../nova-media/nova-media.component";
import * as i4 from "../../_services/nova-api.service";
import * as i5 from "../../_services/media.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../_services/uievent-handler.service";
import * as i8 from "@angular/common";
import * as i9 from "../nova-text-element/nova-text-element.component.ngfactory";
import * as i10 from "../nova-text-element/nova-text-element.component";
import * as i11 from "@angular/router";
import * as i12 from "../../_services/magazine.service";
import * as i13 from "../../_services/cookie.service";
import * as i14 from "./full-image-slider.component";
var styles_FullImageSliderComponent = [i0.styles];
var RenderType_FullImageSliderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FullImageSliderComponent, data: {} });
export { RenderType_FullImageSliderComponent as RenderType_FullImageSliderComponent };
function View_FullImageSliderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [], null, null, null, null, null))], null, null); }
function View_FullImageSliderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getImageProxyUrl(_v.parent.context.$implicit.filename); var currVal_1 = _v.parent.context.$implicit.description1; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FullImageSliderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-media", [], [[2, "nofullscreen", null]], null, null, i2.View_NovaMediaComponent_0, i2.RenderType_NovaMediaComponent)), i1.ɵdid(1, 770048, null, 0, i3.NovaMediaComponent, [i4.NovaAPIService, i5.MediaService, i1.Renderer2, i6.DomSanitizer, i7.UIEventHandlerService], { version: [0, "version"], mediaId: [1, "mediaId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.imageVersion; var currVal_2 = _v.parent.context.$implicit.slider_media; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).noFullscreen; _ck(_v, 0, 0, currVal_0); }); }
function View_FullImageSliderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "slide"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullImageSliderComponent_3)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullImageSliderComponent_4)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.apiImageMode; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.apiImageMode; _ck(_v, 4, 0, currVal_1); }, null); }
function View_FullImageSliderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-text-element", [["class", "description"]], null, null, null, i9.View_NovaTextElementComponent_0, i9.RenderType_NovaTextElementComponent)), i1.ɵdid(1, 4964352, null, 0, i10.NovaTextElementComponent, [i7.UIEventHandlerService, i5.MediaService, i4.NovaAPIService, i11.Router, i6.DomSanitizer, i11.ActivatedRoute, i12.MagazineService, i13.CookieService, i8.DOCUMENT], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentImage.caption; _ck(_v, 1, 0, currVal_0); }, null); }
function View_FullImageSliderComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-text-element", [["class", "copyright"]], null, null, null, i9.View_NovaTextElementComponent_0, i9.RenderType_NovaTextElementComponent)), i1.ɵdid(1, 4964352, null, 0, i10.NovaTextElementComponent, [i7.UIEventHandlerService, i5.MediaService, i4.NovaAPIService, i11.Router, i6.DomSanitizer, i11.ActivatedRoute, i12.MagazineService, i13.CookieService, i8.DOCUMENT], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentImage.copyright; _ck(_v, 1, 0, currVal_0); }, null); }
function View_FullImageSliderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "caption caption--api caption--pos-relative caption--small"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullImageSliderComponent_6)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullImageSliderComponent_7)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentImage.caption; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.currentImage.copyright; _ck(_v, 4, 0, currVal_1); }, null); }
function View_FullImageSliderComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-text-element", [["class", "description"]], null, null, null, i9.View_NovaTextElementComponent_0, i9.RenderType_NovaTextElementComponent)), i1.ɵdid(1, 4964352, null, 0, i10.NovaTextElementComponent, [i7.UIEventHandlerService, i5.MediaService, i4.NovaAPIService, i11.Router, i6.DomSanitizer, i11.ActivatedRoute, i12.MagazineService, i13.CookieService, i8.DOCUMENT], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentImage.caption; _ck(_v, 1, 0, currVal_0); }, null); }
function View_FullImageSliderComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-text-element", [["class", "copyright"]], null, null, null, i9.View_NovaTextElementComponent_0, i9.RenderType_NovaTextElementComponent)), i1.ɵdid(1, 4964352, null, 0, i10.NovaTextElementComponent, [i7.UIEventHandlerService, i5.MediaService, i4.NovaAPIService, i11.Router, i6.DomSanitizer, i11.ActivatedRoute, i12.MagazineService, i13.CookieService, i8.DOCUMENT], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentImage.copyright; _ck(_v, 1, 0, currVal_0); }, null); }
function View_FullImageSliderComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "caption caption--pos-relative caption--small"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullImageSliderComponent_9)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullImageSliderComponent_10)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentImage.caption; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.currentImage.copyright; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_FullImageSliderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { owl: 0 }), (_l()(), i1.ɵand(0, [["empty", 2]], null, 0, null, View_FullImageSliderComponent_1)), (_l()(), i1.ɵeld(2, 0, null, null, 16, "div", [["class", "carousel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 15, "div", [["class", "carousel-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "navigation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fas fa-expand"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "arrows"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "arrow"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.slideLeft($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "icon-arrow-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "arrow"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.slideRight($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "icon-arrow-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, [[1, 0], ["owl", 1]], null, 2, "div", [["class", "owl-carousel"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullImageSliderComponent_2)), i1.ɵdid(13, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullImageSliderComponent_5)), i1.ɵdid(16, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullImageSliderComponent_8)), i1.ɵdid(18, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getImages(); _ck(_v, 13, 0, currVal_0); var currVal_1 = (_co.apiImageMode && (_co.currentImage.caption || _co.currentImage.copyright)); _ck(_v, 16, 0, currVal_1); var currVal_2 = (!_co.apiImageMode && (_co.currentImage.caption || _co.currentImage.copyright)); _ck(_v, 18, 0, currVal_2); }, null); }
export function View_FullImageSliderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-full-image-slider", [], null, null, null, View_FullImageSliderComponent_0, RenderType_FullImageSliderComponent)), i1.ɵdid(1, 4308992, null, 0, i14.FullImageSliderComponent, [i1.PLATFORM_ID, i7.UIEventHandlerService, i4.NovaAPIService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FullImageSliderComponentNgFactory = i1.ɵccf("nova-full-image-slider", i14.FullImageSliderComponent, View_FullImageSliderComponent_Host_0, { images: "images", apiImages: "apiImages", imageVersion: "imageVersion", currentIndex: "currentIndex", fullWidth: "fullWidth" }, {}, []);
export { FullImageSliderComponentNgFactory as FullImageSliderComponentNgFactory };
