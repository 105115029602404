import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Floor } from '../../floor';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MediaService } from 'app/_services/media.service';

@Component({
    templateUrl: './interview-classic.component.html',
    styleUrls: ['./interview-classic.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageInterviewClassicComponent')
export class PageInterviewClassicComponent extends Floor implements OnInit {
    public loaded = false;
    private i = 0;

    constructor(
        nova: NovaAPIService,
        private media: MediaService,
        uiEventHandler: UIEventHandlerService,
        route: ActivatedRoute,
        router: Router,
        public meta: Meta,
        public title: Title
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    ngOnInit() {
        this.i = 0;

        this.data.blocks.forEach(block => {
            if (block.media) {
                this.media.getMediaUrl(block.media, 'source').subscribe(data => {
                    block.mediaUrl = data;
                    this.load();
                });
            } else {
                this.load();
            }
        });
    }

    load() {
        this.i++;
        if (this.i === this.data.blocks.length) {
            this.loaded = true;
        }
    }

    getFontSize() {
        return this.uiEventHandler.fontSize + 'em';
    }
}
