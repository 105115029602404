<div id="page-{{ id }}" class="page page-book-scroll-reverse">
  <div class="left">
    <nova-bg [data]="data" [version]="'vertical-wide'"></nova-bg>
  </div>
  <nova-scrollbar-holder [ngStyle]="{'backgroundColor':getColor(data['box_color'])}">
    <div class="scroller">
      <div class="bg">
        <div class="real-bg" [ngStyle]="{'backgroundColor':getColor(data['box_color'])}">
          <nova-mobile-img [data]="data" class="show-for-small-only"></nova-mobile-img>
          <nova-title [class.noBg]="!data.background_media" [data]="data"></nova-title>
          <nova-text-element [data]="data.text" (openGallery)="gallery.openSlider($event)"></nova-text-element>
          <nova-content-links class="content-links" *ngIf="data.links && data.links.length > 0" [floors]="data.links"></nova-content-links>
        </div>
      </div>
    </div>
  </nova-scrollbar-holder>
  <nova-text-gallery [medias]="data.textImages" #gallery></nova-text-gallery>
</div>
