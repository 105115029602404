import { Component, Input } from '@angular/core';
import { NovaAPIService } from '../../../../_services/nova-api.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'nova-fti-overlay-social',
    templateUrl: './nova-fti-overlay-social.component.html',
    styleUrls: ['./nova-fti-overlay-social.component.scss']
})
export class NovaFtiOverlaySocialComponent {
    @Input() public font;

    constructor(
        public nova: NovaAPIService,
        public router: Router,
        private _snackBar: MatSnackBar,
        public translate: TranslateService
    ) {}

    share(type) {
        const params = this.router.routerState.root.firstChild.params['value'];
        if (params['issueHandle']) {
            this.nova.getPageData().subscribe(data => {
                this.realShare(type, data['data']);
            });
        } else {
            this.realShare(type);
        }
    }

    private realShare(type, data?) {
        let shareUrl = window.location.href; // current page
        shareUrl = shareUrl.replace('#/', '');
        const encodedUrl = encodeURIComponent(shareUrl);

        this.translate.get(['COPIED_URL']).subscribe((res: Object) => {
            const urlMessage = res['COPIED_URL'];

            if (type === 'mail') {
                window.location.href = `mailto:?body=${encodedUrl}`;
            } else if (type === 'whatsapp') {
                window.open(`https://api.whatsapp.com/send?text=${encodedUrl}`);
            } else if (type === 'copy') {
                const inputElement = document.createElement('input');
                document.body.appendChild(inputElement);
                inputElement.setAttribute('id', 'copyInput');
                (document.getElementById('copyInput') as HTMLInputElement).value = shareUrl;
                inputElement.select();
                document.execCommand('copy');
                document.body.removeChild(inputElement);

                this._snackBar.open(urlMessage, null, {
                    duration: 3000,
                    verticalPosition: 'top',
                    horizontalPosition: 'right'
                });
            }
        });
    }
}
