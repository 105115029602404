import * as tslib_1 from "tslib";
import { environment } from 'environments/environment';
import { NovaAPIService } from './nova-api.service';
import { UIEventHandlerService } from './uievent-handler.service';
import jwtDecode from 'jwt-decode';
import * as i0 from "@angular/core";
import * as i1 from "./nova-api.service";
import * as i2 from "./uievent-handler.service";
var StripeService = /** @class */ (function () {
    function StripeService(nova, ui) {
        this.nova = nova;
        this.ui = ui;
        this.currentCustomer = {
            email: null,
            isAuthenticated: false
        };
    }
    StripeService.prototype.getAuthTokenFromUrl = function () {
        return new URLSearchParams(window.location.search.substring(1)).get('authToken');
    };
    StripeService.prototype.getAuthTokenFromLocalStorage = function () {
        return localStorage.getItem('authToken');
    };
    StripeService.prototype.setAuthTokenToLocalStorage = function (authToken) {
        localStorage.setItem('authToken', authToken);
    };
    StripeService.prototype.removeAuthTokenFromLocalStorage = function () {
        localStorage.removeItem('authToken');
    };
    StripeService.prototype.checkAuthentication = function (magazine, currentIssue) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var authToken, params, sessionId, res, result, isAuthenticated;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.stripeAccount = magazine.stripeAccount;
                        this.publicationId = currentIssue._id;
                        authToken = this.getAuthTokenFromUrl() || this.getAuthTokenFromLocalStorage();
                        params = new URLSearchParams(window.location.search.substring(1));
                        sessionId = params.get('sessionId');
                        if (!authToken && !sessionId) {
                            this.ui.showPaidContentDialog = true;
                            return [2 /*return*/];
                        }
                        if (!sessionId) return [3 /*break*/, 3];
                        return [4 /*yield*/, fetch(environment.apiUrl + "v1/stripe/checkout-success", {
                                method: 'POST',
                                headers: new Headers({
                                    'Content-Type': 'application/json'
                                }),
                                body: JSON.stringify({
                                    session_id: sessionId,
                                    stripe_account: this.stripeAccount
                                })
                            })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, res.json()];
                    case 2:
                        result = _a.sent();
                        this.removeParameterFromUrl('sessionId');
                        if ('authToken' in result) {
                            this.setAuthTokenToLocalStorage(result.authToken);
                            this.currentCustomer = {
                                email: result.email,
                                isAuthenticated: true
                            };
                            this.ui.showPaidContentDialog = false;
                            return [2 /*return*/];
                        }
                        this.ui.showPaidContentDialog = true;
                        return [2 /*return*/];
                    case 3: return [4 /*yield*/, this.authenticateCustomer(authToken)];
                    case 4:
                        isAuthenticated = _a.sent();
                        this.removeParameterFromUrl(['authToken', 'sessionId']);
                        this.ui.showPaidContentDialog = isAuthenticated ? false : true;
                        return [2 /*return*/];
                }
            });
        });
    };
    StripeService.prototype.authenticateCustomer = function (authToken) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var email, res, result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        email = jwtDecode(authToken).email;
                        return [4 /*yield*/, fetch(environment.apiUrl + "v1/stripe/authentication", {
                                method: 'POST',
                                headers: new Headers({
                                    'Content-Type': 'application/json',
                                    Authorization: "Bearer " + authToken
                                }),
                                body: JSON.stringify({
                                    stripe_account: this.stripeAccount,
                                    publication_id: this.publicationId
                                })
                            })];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, res.json()];
                    case 2:
                        result = _a.sent();
                        if (result.code === 200) {
                            if ('authToken' in result) {
                                window.localStorage.setItem('authToken', result.authToken);
                            }
                            this.currentCustomer = {
                                email: email,
                                isAuthenticated: true
                            };
                            return [2 /*return*/, true];
                        }
                        else {
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    StripeService.prototype.logout = function () {
        this.removeAuthTokenFromLocalStorage();
        this.removeParameterFromUrl(['authToken', 'sessionId']);
        this.ui.showPaidContentDialog = true;
    };
    StripeService.prototype.removeParameterFromUrl = function (parameter) {
        var e_1, _a;
        var url = new URL(location.href);
        if (Array.isArray(parameter)) {
            try {
                for (var parameter_1 = tslib_1.__values(parameter), parameter_1_1 = parameter_1.next(); !parameter_1_1.done; parameter_1_1 = parameter_1.next()) {
                    var param = parameter_1_1.value;
                    url.searchParams.delete(param);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (parameter_1_1 && !parameter_1_1.done && (_a = parameter_1.return)) _a.call(parameter_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        else {
            url.searchParams.delete(parameter);
        }
        window.history.pushState('', document.title, url.toString());
    };
    StripeService.ngInjectableDef = i0.defineInjectable({ factory: function StripeService_Factory() { return new StripeService(i0.inject(i1.NovaAPIService), i0.inject(i2.UIEventHandlerService)); }, token: StripeService, providedIn: "root" });
    return StripeService;
}());
export { StripeService };
