import * as tslib_1 from "tslib";
import { ComponentTemplate } from './component';
var Floor = /** @class */ (function (_super) {
    tslib_1.__extends(Floor, _super);
    function Floor(nova, uiEventHandler, route, router, meta, title) {
        var _this = _super.call(this, nova, uiEventHandler, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.route = route;
        _this.router = router;
        _this.meta = meta;
        _this.title = title;
        return _this;
    }
    Floor.prototype.closeContent = function (gallery) {
        if (gallery && gallery.open) {
            return;
        }
        var params = this.route.params['value'];
        if (this.data && this.data.parentPage && this.data.parentPage.templateName === 'blog-index') {
            history.back();
            return;
        }
        this.router.navigate(['/', params['langHandle'], params['issueHandle'], params['pageHandle']], {
            queryParams: {
                img: null
            },
            queryParamsHandling: 'merge'
        });
    };
    Floor.prototype.showPrint = function () {
        return !this.showPDF() && this.nova.issue.showPrint === 'yes';
    };
    Floor.prototype.showPDF = function () {
        return !!this.data['pdf_version'];
    };
    Floor.prototype.print = function () {
        this.nova.print();
    };
    Floor.prototype.printPDF = function () {
        this.nova.print();
    };
    return Floor;
}(ComponentTemplate));
export { Floor };
