import * as tslib_1 from "tslib";
import { OnInit, AfterContentInit, Renderer2, OnDestroy } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MagazineService } from '../../../_services/magazine.service';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
var PageIndexCoverComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageIndexCoverComponent, _super);
    function PageIndexCoverComponent(nova, mag, renderer, uiEventHandler, meta, title, translate, router, route) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.mag = mag;
        _this.renderer = renderer;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.title = title;
        _this.translate = translate;
        _this.router = router;
        _this.route = route;
        _this.customPhantomEvent = true;
        return _this;
    }
    PageIndexCoverComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (typeof window !== 'undefined') {
            setTimeout(function () {
                _this.uiEventHandler.onScreenShotEvent(_this.data.id);
                $('.page-index-cover .bg').removeClass('animated slideInLeft fadeScaleUp');
            }, 3000);
        }
        var logoStyle = {};
        var flexboxStyle = {};
        var posLogo = this.data['logoPosition'];
        var posLogoArr = posLogo.split('-');
        if (posLogoArr[0] === 'top') {
            flexboxStyle['justify-content'] = 'flex-start';
        }
        else if (posLogoArr[0] === 'bottom') {
            flexboxStyle['justify-content'] = 'flex-end';
        }
        else if (posLogoArr[0] === 'center') {
            flexboxStyle['justify-content'] = 'center';
        }
        if (posLogoArr[1] === 'left') {
            logoStyle['align-self'] = 'flex-start';
        }
        else if (posLogoArr[1] === 'right') {
            logoStyle['align-self'] = 'flex-end';
        }
        else if (posLogoArr[1] === 'center') {
            logoStyle['align-self'] = 'center';
        }
        this.logoStyle = tslib_1.__assign({}, logoStyle, { width: this.data.logoSize ? this.data['logoSize'] * 10 + "%" : '50%' });
        this.flexboxStyle = flexboxStyle;
    };
    PageIndexCoverComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        this.sub = this.mag
            .getMagazine()
            .pipe(switchMap(function (data) {
            var special;
            var pages = _this.nova.pages.filter(function (page) {
                if (_this.isSpecialPage(page.handle)) {
                    special = page;
                    return false;
                }
                return ((page.templateName === 'index-hotel' ||
                    page.templateName === 'index-roundtrip' ||
                    page.templateName === 'index-trips' ||
                    !page.templateName.startsWith('index-')) &&
                    page.status === 'active' &&
                    page.showInIndex !== 'no');
            });
            if (special) {
                _this.pages = tslib_1.__spread([special], pages);
            }
            else {
                _this.pages = pages;
            }
            return _this.nova.getFont(data.navigation.font);
        }))
            .subscribe(function (fontData) {
            _this.font = fontData.name;
        });
    };
    PageIndexCoverComponent.prototype.isSpecialPage = function (handle) {
        var specialPages = [
            'zusatzartikel-abu-simbel',
            'amendment-abu-simbel',
            'additional-item-police-headquarters',
            'zusatzartikel-polizeipraesidium'
        ];
        return specialPages.indexOf(handle) > -1;
    };
    PageIndexCoverComponent.prototype.goToPage = function (page, floor) {
        var params = this.router.routerState.root.firstChild.params['value'];
        if (!floor) {
            this.router.navigate(['/', params['langHandle'], params['issueHandle'], page], {
                queryParamsHandling: 'preserve'
            });
        }
        else {
            this.router.navigate(['/', params['langHandle'], params['issueHandle'], page, floor], {
                queryParamsHandling: 'preserve'
            });
        }
    };
    PageIndexCoverComponent.prototype.ngOnDestroy = function () {
        if (this.sub)
            this.sub.unsubscribe();
    };
    PageIndexCoverComponent = tslib_1.__decorate([
        NovaTemplate('PageIndexCoverComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            MagazineService,
            Renderer2,
            UIEventHandlerService,
            Meta,
            Title,
            TranslateService,
            Router,
            ActivatedRoute])
    ], PageIndexCoverComponent);
    return PageIndexCoverComponent;
}(Page));
export { PageIndexCoverComponent };
