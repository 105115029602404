<div id="page-{{ id }}" class="page page-cover-logo">
	<nova-bg [data]="data" [forceVideo]="true"></nova-bg>
	<div class="flexbox" [ngStyle]="setMyStyles(flexboxStyle)">
		<div class="flex-item logo" #logo [ngStyle]="setMyStyles(logoStyle)">
			<div [ngStyle]="setMyStyles(logo_size)" *ngIf="data.logo && data.logo.url">
				<img [src]="data.logo.url" alt="Logo" />
			</div>
		</div>
	</div>
</div>
