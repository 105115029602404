import { ErrorComponent } from './error.component';
import { ColorFormatter } from './../novamag-api/Types';
import { UIEventHandlerService } from './../_services/uievent-handler.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NovaAPIService } from '../_services/nova-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MagazineService } from 'app/_services/magazine.service';

@Component({
    templateUrl: './error-page.component.html',
    styleUrls: ['./error.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ErrorPageComponent extends ErrorComponent {
    public magazine;
    public navStyle;

    constructor(
        public mag: MagazineService,
        public nova: NovaAPIService,
        public ui: UIEventHandlerService,
        public router: Router,
        public route: ActivatedRoute
    ) {
        super(mag, nova, ui, router, route);
    }
}
