<div class="archive-grid">
    <div *ngIf="issues && issues.length > 0" class="scroller"
        [ngClass]="{single: issues.length == 1, half: issues.length == 2, three: issues.length == 3}">

        <a *ngFor="let val of issues; let key = index;"
            [class.gridHalf]="(key != issues.length - 1 || issues.length % 2 != 0) && key != 0"
            [class.last]="key == issues.length - 1 && issues.length % 2 == 0"
            [class.first]="key == 0"
            class="issue"
            [ngStyle]="{'background-color':getStyleText(val)}"
            [routerLink]="['/', nova.lang, val.handle]"
            queryParamsHandling="preserve"
            >
            <nova-media [mediaId]="val.archiveBackground || val.background_media" [bg]="true" [fallback]="false"
                [playOnHover]="true"></nova-media>
            <section class="center">
                <section class="inner">
                    <h2
                        [ngStyle]="{'font-family':font, 'color':getStyleText(val), 'background-color':getStyleTextInv(val)}">
                        {{ val.number }}</h2>
                    <h1 [ngStyle]="{'font-family':font, 'color':getStyleTextInv(val)}">{{ val.name }}</h1>
                    <p [ngStyle]="{'font-family':font, 'color':getStyleTextInv(val)}">{{ val.subHeader }}</p><br />
                    <span [ngStyle]="{'font-family':font, 'color':getStyleTextInv(val)}"><i
                            class="icon-open-magazine"></i><br /><small>{{ 'OPEN' | translate }}</small></span>
                </section>
            </section>
        </a>
    </div>
</div>
