/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nova-text-element.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../nova-columnizer/nova-columnizer.component.ngfactory";
import * as i3 from "../nova-columnizer/nova-columnizer.component";
import * as i4 from "../../_services/uievent-handler.service";
import * as i5 from "@angular/common";
import * as i6 from "./nova-text-element.component";
import * as i7 from "../../_services/media.service";
import * as i8 from "../../_services/nova-api.service";
import * as i9 from "@angular/router";
import * as i10 from "@angular/platform-browser";
import * as i11 from "../../_services/magazine.service";
import * as i12 from "../../_services/cookie.service";
var styles_NovaTextElementComponent = [i0.styles];
var RenderType_NovaTextElementComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_NovaTextElementComponent, data: {} });
export { RenderType_NovaTextElementComponent as RenderType_NovaTextElementComponent };
function View_NovaTextElementComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "nova-columnizer", [["class", "columnizer-box"]], null, null, null, i2.View_NovaColumnizerComponent_0, i2.RenderType_NovaColumnizerComponent)), i1.ɵdid(1, 4374528, null, 0, i3.NovaColumnizerComponent, [i4.UIEventHandlerService], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "div", [["class", "innerText clearfix fr-view"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.htmlData; _ck(_v, 2, 0, currVal_0); }); }
function View_NovaTextElementComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "innerText clearfix fr-view"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.htmlData; _ck(_v, 0, 0, currVal_0); }); }
function View_NovaTextElementComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["text", 1]], null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaTextElementComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaTextElementComponent_3)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.columnize && _co.uiEventHandler.columnizeEnabled); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.columnize || !_co.uiEventHandler.columnizeEnabled); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_NovaTextElementComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { textElm: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaTextElementComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.htmlData; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NovaTextElementComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-text-element", [], null, null, null, View_NovaTextElementComponent_0, RenderType_NovaTextElementComponent)), i1.ɵdid(1, 4964352, null, 0, i6.NovaTextElementComponent, [i4.UIEventHandlerService, i7.MediaService, i8.NovaAPIService, i9.Router, i10.DomSanitizer, i9.ActivatedRoute, i11.MagazineService, i12.CookieService, i5.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NovaTextElementComponentNgFactory = i1.ɵccf("nova-text-element", i6.NovaTextElementComponent, View_NovaTextElementComponent_Host_0, { data: "data", wait: "wait", clickListeners: "clickListeners", myid: "myid", columnize: "columnize", initAfter: "initAfter" }, { openGallery: "openGallery" }, []);
export { NovaTextElementComponentNgFactory as NovaTextElementComponentNgFactory };
