import * as tslib_1 from "tslib";
import { DomSanitizer } from '@angular/platform-browser';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
var ServerMultipleBackgroundComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ServerMultipleBackgroundComponent, _super);
    function ServerMultipleBackgroundComponent(nova, sanitizer, renderer, uiEventHandler, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.sanitizer = sanitizer;
        _this.renderer = renderer;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        return _this;
    }
    ServerMultipleBackgroundComponent.prototype.ngOnInit = function () {
        this.addOgTags();
    };
    ServerMultipleBackgroundComponent = tslib_1.__decorate([
        NovaTemplate('ServerMultipleBackgroundComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            DomSanitizer,
            Renderer2,
            UIEventHandlerService,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], ServerMultipleBackgroundComponent);
    return ServerMultipleBackgroundComponent;
}(Page));
export { ServerMultipleBackgroundComponent };
