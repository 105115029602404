<div id="page-{{ id }}" class="page page-multiple-bgs">
    <div #owl class="owl-carousel" [ngClass]="{'owl-loaded': !uiEventHandler.isMobile(), 'owl-loaded-desktop': !uiEventHandler.isMobile()}">
        <div *ngFor="let entry of data.medias;let key = index;let last = last;" [hidden]="key != 0" class="slide" [ngStyle]="{'background-color':getColor(entry['box_color_bottom'], true)}">
            <div class="pos-rel clearfix">
                <nova-media [mediaId]="entry.media" class="animated fadeIn" [bg]="true" *ngIf="key == 0" [backgroundPosition]="(entry && entry.media_position && entry.media_position.split('-').join(' ')) || 'center center'"></nova-media>
            </div>
            <div class="bg animated fadeInUp scrollbar-holder">
                <div class="scroller">
                    <div class="api-title clearfix">
                        <h3 *ngIf="entry.label" class="dateline" [ngStyle]="{'background-color':getColor(entry['box_color_top'], true)}">
                            <nova-element [data]="entry" name="label"></nova-element>
                        </h3>
                        <div class="title-holder clearfix">
                            <h1 [ngStyle]="{'background-color':getColor(entry['box_color_top'], true)}">
                                <nova-element [data]="entry" name="header"></nova-element>
                            </h1>
                            <h2 *ngIf="entry.subHeader" [ngStyle]="{'background-color':getColor(entry['box_color_top'], true)}">
                                <nova-element [data]="entry" name="subHeader"></nova-element>
                            </h2>
                        </div>
                    </div>
                    <div class="text-content" [ngStyle]="{'background-color':getColor(entry['box_color_bottom'], true)}">
                        <nova-text-element class="con" [data]="entry.text"></nova-text-element>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
