<div>
    <div *ngIf="!isLoading" class="nova-fti-overlay" [ngClass]="visible ? 'visible': ''">
        <div class="scrollbar-holder">
            <div class="scroller">
                <div class="top-nav">
                    <div>
                        <a [class.active]="active == 'pages'" (click)="open('pages')">{{ 'PAGES' | translate }}</a>
                        <a *ngIf="nova.issues && nova.issues.length > 1" [class.active]="active == 'archive'"
                            (click)="open('archive')">{{ 'TRAVELWORLDS' | translate }}</a>
                    </div>

                    <div class="right-container">
                        <div *ngIf="this.languages.length > 1" style="display: inline-block;">
                            <a *ngFor="let value of languages" [class.active]="value == nova.lang"
                                (click)="switchLang(value)">
                                {{ value }}
                            </a>
                        </div>

                        <span class="search" (click)="toggleSearch() && $event.preventDefault()">
                            <i [class.bold]="showSearch" class="fa fa-search"></i>
                        </span>
                    </div>

                </div>
                <input *ngIf="showSearch" [placeholder]="'SEARCH' | translate" type="text" [(ngModel)]="searchField"
                    class="form-control search-input" #search />

                <hr />

                <nova-fti-overlay-pages [currentPage]="currentPageIndex" [borderColor]="borderColor" [font]="font"
                    [searchString]="searchField" (pageChange)="pageChange.emit($event);hide()" *ngIf="active == 'pages'"
                    [pages]="nova.pages"></nova-fti-overlay-pages>
                <nova-fti-overlay-archive [borderColor]="borderColor" [font]="font" [lang]="nova.lang"
                    (issueChange)="hide();" *ngIf="active == 'archive'"></nova-fti-overlay-archive>
                <nova-fti-overlay-imprint [font]="font" [magazine]="magazine" [issue]="issue"
                    *ngIf="active === 'imprint'"></nova-fti-overlay-imprint>
                <nova-fti-overlay-privacy [font]="font" [magazine]="magazine" [issue]="issue"
                    *ngIf="active == 'privacy'"></nova-fti-overlay-privacy>
                <nova-fti-overlay-contact [font]="font" [magazine]="magazine" [issue]="issue"
                    *ngIf="active == 'contact'"></nova-fti-overlay-contact>

                <div class="nav-footer">
                    <a *ngIf="magazine.imprintTitle || magazine.imprintText" [class.active]="active === 'imprint'"
                        (click)="open('imprint', true)">
                        {{ 'IMPRINT' | translate }}
                    </a>
                    <a [class.active]="active === 'privacy'"
                        (click)="open('privacy', true)">
                        {{ 'PRIVACY_POLICY' | translate }}
                    </a>
                    <a *ngIf="magazine && magazine.cookieConsent && magazine.cookieConsent.type === 'usercentrics'" onclick="window.UC_UI && window.UC_UI.showSecondLayer()">
                        {{ 'COOKIE_SETTINGS' | translate }}
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="share-menu" [class.hide-share]="this.overlayVisible" *ngIf="!nova.isScreenShot && showShare()">
        <div class="share-menu-icon" [class.open]="shareVisible" (click)="shareVisible = !shareVisible"
            [style.backgroundColor]="uiEventHandler.getColor(magazine.navigation.backgroundHoverColor)">
            <i class=" icon-share"></i>
        </div>
        <div class="share-overlay" [class.visible]="shareVisible">
            <nova-fti-overlay-social [font]="font"></nova-fti-overlay-social>
        </div>
    </div>
</div>
