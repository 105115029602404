import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { NovaAPIService } from '../../_services/nova-api.service';
import { Router, NavigationEnd } from '@angular/router';
import { MagazineService } from '../../_services/magazine.service';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import { ColorFormatter } from '../../novamag-api/Types';
import { OnChanges } from '@angular/core/src/metadata/lifecycle_hooks';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';
import { StripeService } from 'app/_services/stripe.service';

@Component({
    selector: 'nova-london-title-bar',
    templateUrl: './nova-london-title-bar.component.html',
    styleUrls: ['./nova-london-title-bar.component.scss']
})
export class NovaLondonTitleBarComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public issue;
    @Input() public magazine;
    @Output() openOverlay = new EventEmitter();
    @Output() pageChange = new EventEmitter();

    public nav;
    public font;
    public infoPage: string;
    public homeLinks;
    public shareIcon: string;
    public magData;
    public selectStartPage: boolean;
    public hovering = false;

    private sub: Subscription;
    private subFont: Subscription;
    private subRouter: Subscription;
    private logoSub: Subscription;

    currentPage: number;
    pageNum: number;

    constructor(
        public nova: NovaAPIService,
        private router: Router,
        private mag: MagazineService,
        private _snackBar: MatSnackBar,
        public ui: UIEventHandlerService,
        public translate: TranslateService,
        public stripe: StripeService
    ) {}

    ngOnInit() {
        this.sub = this.mag.getMagazine().subscribe(data => {
            this.magData = data;
            this.nav = data.navigation;
            this.selectStartPage = data.selectStartPage === 'archiv';
            this.subFont = this.nova.getFont(data.navigation.font).subscribe(fontData => {
                this.font = fontData.name;
            });
        });

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.getCurrentPage();
            }
        });

        this.getCurrentPage();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.issue && changes.issue.currentValue !== changes.issue.previousValue) {
            this.getCurrentPage();
        }
    }

    getCurrentPage() {
        const params = this.router.routerState.root.firstChild.params['value'];
        const pageHandle = params.pageHandle;

        this.infoPage = this.nova.issue.infoPage;
        this.homeLinks = this.nova.issue.homeLinks;
        this.shareIcon = this.nova.issue.shareIcon;

        if (pageHandle) {
            this.nova.pages.forEach((page, index) => {
                if (page.handle === pageHandle) {
                    this.currentPage = index;
                }
            });

            this.pageNum = this.nova.pages.length;
        }
    }

    get pageTitle() {
        return this.ui.getContentTableEntryName(this.nova.pages[this.currentPage]);
    }

    openLondonOverlay() {
        this.openOverlay.emit();
    }

    getColor(c) {
        return new ColorFormatter(c).rgba();
    }

    getLogoPadding(logoSize) {
        const maxPadding = window.innerWidth > 768 ? 25 : 20;
        const calculatedPadding = (maxPadding * Number(logoSize.replace('%', ''))) / 100;
        const yPadding = logoSize ? maxPadding - calculatedPadding : 10;

        return `${yPadding}px 20px`;
    }

    mobileBG() {
        return new ColorFormatter(this.nav.backgroundColor).rgba();
    }

    ngOnDestroy() {
        this.sub && this.sub.unsubscribe();
        this.subFont && this.subFont.unsubscribe();
        this.subRouter && this.subRouter.unsubscribe();
        this.logoSub && this.logoSub.unsubscribe();
    }

    goToInfoPage(pageId) {
        const pageIndex = this.nova.pages.findIndex(page => page._id === pageId);
        this.pageChange.emit(pageIndex + 1);
    }

    goToUrl(url: string) {
        const currentPage = this.issue.pages[this.currentPage];

        if (currentPage.templateName === 'blog-index') {
            this.router.navigate([this.router.url.substring(0, this.router.url.lastIndexOf('/'))]);

            return;
        }

        this.router.navigate([url], {
            queryParamsHandling: 'preserve'
        });
    }

    toggleShareMenu() {
        this.hovering = !this.hovering;
    }

    share(type) {
        const url = window.location.href;
        const shareUrl = encodeURIComponent(url);

        this.translate.get(['MAIL_SUBJECT', 'MAIL_BODY']).subscribe((res: Object) => {
            const mailSubject = res['MAIL_SUBJECT'];
            const mailBody = res['MAIL_BODY'];

            switch (type) {
                case 'mail':
                    window.location.href = 'mailto:?&subject=' + mailSubject + '&body=' + mailBody + shareUrl;
                    break;
                case 'whatsapp':
                    window.location.href = 'whatsapp://send?text=' + mailBody + shareUrl;
                    break;

                case 'copy':
                    // @ts-ignore needed because of old ts version
                    window.navigator.clipboard.writeText(url);

                    this.translate.get('COPIED_URL').subscribe((res: string) => {
                        const urlMessage = res;

                        this._snackBar.open(urlMessage, null, {
                            duration: 3000,
                            verticalPosition: 'top',
                            horizontalPosition: 'right'
                        });
                    });
                    break;

                default:
                    break;
            }
        });
    }
}
