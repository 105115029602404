import {
    Component,
    Inject,
    Input,
    OnInit,
    PLATFORM_ID,
    ViewEncapsulation,
    ViewChild,
    Output,
    EventEmitter,
    AfterViewInit,
    OnDestroy
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { UIEventHandlerService } from '../../../../_services/uievent-handler.service';
import { SharedHotelHelperService } from '../../hotel-detail-standard/shared-hotel-helper.service';
import { fadeAnimation } from '../../travelworld-modern/travelworld-slider/fadeIn.animation';
import { Color } from '../../../../novamag-api/Types';
import { ColorFormatter } from '../../../../novamag-api/Types';
import { ModalType } from '../../hotel-detail-standard/hotel-detail-standard-content/hotel-detail-standard-content.component';
import { NovaAPIService } from 'app/_services/nova-api.service';

@Component({
    selector: 'app-hotel-detail-premium-content',
    templateUrl: './hotel-detail-premium-content.component.html',
    styleUrls: ['./hotel-detail-premium-content.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [SharedHotelHelperService],
    animations: [fadeAnimation]
})
export class HotelDetailPremiumContentComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() data;
    @Output() modalOpenChange = new EventEmitter<boolean>();
    @ViewChild('scrollContainer') scrollContainer;

    googleMapsUrl;
    selectedBlock;
    isBrowser: boolean = isPlatformBrowser(this.platformId);
    images;

    public showContactForm: boolean;
    public modalOpen = false;
    public modalType: ModalType;
    public teaser: SafeHtml;

    constructor(
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public hotelHelper: SharedHotelHelperService,
        @Inject(PLATFORM_ID) private platformId: Object,
        public nova: NovaAPIService
    ) {}

    selectBlock(block) {
        this.selectedBlock = block;
    }

    ngOnInit() {
        this.images = this.getImages(this.data);

        this.showContactForm = this.data.ibe === 'false';
        this.data = this.hotelHelper.handleTagInputs(this.data);
        this.googleMapsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.hotelHelper.getMapsUrl(this.data));
        this.teaser = this.sanitizer.bypassSecurityTrustHtml(this.data.text);
    }

    ngAfterViewInit() {
        const moreInfoButton = document.querySelector('#moreInfo');

        if (!moreInfoButton) {
            return;
        }

        moreInfoButton.addEventListener('click', () => this.openModal('moreInfo'));
    }

    ngOnDestroy() {
        const moreInfoButton = document.querySelector('#moreInfo');

        if (!moreInfoButton) {
            return;
        }

        moreInfoButton.removeEventListener('click', () => this.openModal('moreInfo'));
    }

    goToUrl(url: string) {
        if (this.isBrowser) {
            window.open(url);
        }
    }

    openModal(modalType: ModalType): void {
        const scrollContainer = this.scrollContainer.nativeElement;
        scrollContainer.scroll(0, 0);
        scrollContainer.parentElement.parentElement.classList.add('modal-open');

        this.modalOpen = true;
        this.modalType = modalType;
        this.modalOpenChange.emit(this.modalOpen);

        $('body').addClass('nested-modal-open');
    }

    closeModal(event) {
        event.stopImmediatePropagation();

        const page = this.scrollContainer.nativeElement.parentElement.parentElement;
        page.classList.remove('modal-open');

        this.modalOpen = false;
        this.modalOpenChange.emit(this.modalOpen);

        $('body').removeClass('nested-modal-open');
    }

    scroll(el: HTMLElement) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    getVersion() {
        if (this.uiEventHandler.isMobile()) {
            return 'vertical-wide';
        } else {
            return 'horizontal-wide';
        }
    }

    getWindowHeight() {
        if (this.isBrowser) {
            if (this.uiEventHandler.isMobile()) {
                return 0;
            }

            return window.innerHeight;
        }
    }

    getImageProxyUrl(url) {
        const size = `s:200:150:1:1`;
        const rt = 'fit';
        return `${this.nova.getImgUrl()}/insecure/rt:${rt}/${size}/g:sm/plain/${url}`;
    }

    getImageProxyUrlForBlock(url) {
        let width = 600;
        let height = 400;
        let aspect = '1:1';
        let rt = 'fill';
        let size = `s:${width}:${height}:${aspect}`;
        return `${this.nova.getImgUrl()}/insecure/rt:${rt}/${size}/g:sm/plain/${url}`;
    }

    isUrl(media) {
        return typeof media !== 'object' && media.indexOf('http') > -1;
    }

    getColor(data: Color) {
        if (!data || typeof data.r === 'undefined') return;
        const c = new ColorFormatter(data);
        return c.rgba();
    }

    getImages(data: any) {
        const images = [];
        const sliderImageProps = [
            {
                mediaId: 'background_media'
            },
            {
                mediaId: 'background_media_2'
            },
            {
                mediaId: 'background_media_3'
            },
            {
                mediaId: 'background_media_4'
            },
            {
                mediaId: 'background_media_5'
            }
        ];

        sliderImageProps.forEach(
            prop =>
                data[prop.mediaId]
                    ? images.push({
                          mediaId: data[prop.mediaId]
                      })
                    : null
        );

        return images;
    }
}
