import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
var PageBackgroundCleanComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageBackgroundCleanComponent, _super);
    function PageBackgroundCleanComponent(nova, uiEventHandler, sanitizer, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.sanitizer = sanitizer;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        return _this;
    }
    PageBackgroundCleanComponent.prototype.ngOnInit = function () {
        var logoStyle = {};
        var flexboxStyle = {};
        var posLogo = this.data['contentPosition'];
        var posLogoArr = posLogo.split('-');
        if (posLogoArr[0] === 'top') {
            flexboxStyle['justify-content'] = 'flex-start';
        }
        else if (posLogoArr[0] === 'bottom') {
            flexboxStyle['justify-content'] = 'flex-end';
        }
        else if (posLogoArr[0] === 'center') {
            flexboxStyle['justify-content'] = 'center';
        }
        if (posLogoArr[1] === 'left') {
            logoStyle['align-self'] = 'flex-start';
        }
        else if (posLogoArr[1] === 'right') {
            logoStyle['align-self'] = 'flex-end';
        }
        else if (posLogoArr[1] === 'center') {
            logoStyle['align-self'] = 'center';
        }
        this.logoStyle = logoStyle;
        this.flexboxStyle = flexboxStyle;
    };
    PageBackgroundCleanComponent = tslib_1.__decorate([
        NovaTemplate('PageBackgroundCleanComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            DomSanitizer,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], PageBackgroundCleanComponent);
    return PageBackgroundCleanComponent;
}(Page));
export { PageBackgroundCleanComponent };
