import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { NovaGalleryPopupComponent } from 'app/_components/nova-gallery-popup/nova-gallery-popup.component';
import { Subscription } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { MediaService } from 'app/_services/media.service';
import { ColorFormatter } from '../../../novamag-api/Types';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './image-classic.component.html',
    styleUrls: ['./image-classic.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageImageClassicComponent')
export class PageImageClassicComponent extends Page implements OnInit, OnDestroy {
    public media: any[];
    public thumbnails: any[];
    public len: number;
    public detail = false;
    public mobile = false;
    public original = false;

    @ViewChild('dg') private dgElm: ElementRef;
    @ViewChild('slider') private slider: NovaGalleryPopupComponent;

    private arrowSub: Subscription;

    constructor(
        public nova: NovaAPIService,
        public mediaService: MediaService,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    ngOnInit() {
        this.len = this.data.medias.length;
        this.mobile = $('body').width() <= 1024 || $('body').height() < 650 || this.len < 3;

        this.original =
            this.data._id === 'NSPgz74sSTxWEoxat' ||
            this.data._id === 'FQpQdu6jppKNgdm22' ||
            this.data._id === 'jdFaLnmQCM3MbPqJu';

        const thumbnails = this.data.medias.map(x => x.thumbnail || x.media);
        this.mediaService.loadMedia(thumbnails).then(data => {
            this.thumbnails = data;
        });

        this.mediaService.loadMedia(this.data.medias).then(data => {
            this.media = data;

            if (!this.mobile) {
                setTimeout(() => {
                    $(this.dgElm.nativeElement).gallery();
                    let width;
                    if ($('body').width() <= 991) {
                        width = $('body').width() * 0.8;
                    } else {
                        width = $('body').width() * 0.5;
                    }

                    $(this.dgElm.nativeElement)
                        .find('.dg-wrapper > div')
                        .css('width', width + 'px');
                    $(this.dgElm.nativeElement)
                        .find('.dg-wrapper')
                        .css('width', width + 'px');
                }, 100);
            }
        });
    }

    desktopGalleryNav(dir: string) {
        if (this.mobile) {
            dir === 'right' ? this.slider.nextPage() : this.slider.previousPage();

            return;
        }

        const gallery = $(this.dgElm.nativeElement).data('gallery');

        if (!gallery) {
            return;
        }

        if (dir === 'right') {
            gallery._navigate('next');
        }

        if (dir === 'left') {
            gallery._navigate('prev');
        }
    }

    getItemStyle() {
        if (
            (this.data._id === 'D4gwafRzPFtzjQeYa' ||
                this.data._id === 'N2XCDjjHELb6K39BW' ||
                this.data._id === 'pWKMQYwnBqcdJHB3W') &&
            this.data['background_color']
        ) {
            const c = new ColorFormatter(this.data['background_color']);

            return {
                'background-color': c.rgba()
            };
        }

        return {};
    }

    open(key) {
        this.detail = true;
        $('#mute-button').fadeOut();

        setTimeout(() => {
            const dgHeight = $('.dg-center .nofullscreen').css('height');
            const navBgColor = $('.next-page').css('background-color');
            const navTextColor = $('.next-page').css('color');

            $('.dg-nav .icon-arrow').css('background-color', navBgColor);
            $('.dg-nav .icon-arrow').css('color', navTextColor);
            $('.dg-nav').css('top', `${parseInt(dgHeight, 10) / 2 - 10}px`);
        }, 500);

        this.uiEventHandler.hideContentCloseIcon = true;

        if (this.mobile) {
            this.uiEventHandler.showNavArrows = false;
            this.uiEventHandler.hideTitleBar = true;
            this.slider.goTo(key);

            setTimeout(() => {
                this.slider.resize();
            }, 100);

            this.uiEventHandler.hideSwipeOverlay();
        } else {
            const gallery = $(this.dgElm.nativeElement).data('gallery');
            gallery && gallery.goTo(key);
        }
    }

    close() {
        this.detail = false;
        this.uiEventHandler.hideTitleBar = false;
        this.uiEventHandler.hideContentCloseIcon = false;
        $('#mute-button').fadeIn();
    }

    getVersion() {
        if (this.original) {
            return 'original';
        }

        if ($('body').width() < 991) {
            return 'horizontal-wide';
        }
    }

    ngOnDestroy() {
        this.arrowSub && this.arrowSub.unsubscribe();
    }

    getCopyrightUrl(copyrightUrl) {
        if (!copyrightUrl) {
            return null;
        }

        const regex = '^https?://';
        const match = copyrightUrl.match(regex);

        if (match) {
            return copyrightUrl;
        }

        return null;
    }
}
