import { Component, ElementRef, OnInit, QueryList, Renderer2, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import { SharedHotelHelperService } from '../hotel-detail-standard/shared-hotel-helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AfterContentInit } from '@angular/core/src/metadata/lifecycle_hooks';
import { MultiSelectItem } from './MultiSelectItem';
import { fadeAnimation } from '../travelworld-modern/travelworld-slider/fadeIn.animation';
import { IndexHotelFilterPipe } from './filter-tags.pipe';
import { setRemainingFilters, setFilters } from '../index-trips/index-trips.component';

@Component({
    templateUrl: './index-hotel.component.html',
    styleUrls: ['./index-hotel.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [SharedHotelHelperService, IndexHotelFilterPipe],
    animations: [fadeAnimation]
})
@NovaTemplate('PageIndexHotelComponent')
export class PageIndexHotelComponent extends Page implements OnInit, AfterContentInit {
    standard;
    premium;
    filteredData = [];
    allFilters: MultiSelectItem[] = [];
    allCountryFilters: MultiSelectItem[] = [];
    selectedFilters: MultiSelectItem[] = [];
    selectedCountryFilters: MultiSelectItem[] = [];
    selectedStars: MultiSelectItem[] = [];
    stars: MultiSelectItem[] = [];
    defaultStars: MultiSelectItem[] = [
        {
            itemName: '6 Sterne',
            id: '6'
        },
        {
            itemName: '5 Sterne',
            id: '5'
        },
        {
            itemName: '4 Sterne',
            id: '4'
        },
        {
            itemName: '3 Sterne',
            id: '3'
        },

        {
            itemName: '2 Sterne',
            id: '2'
        },
        {
            itemName: '1 Stern',
            id: '1'
        }
    ];
    dropdownSettings = {
        singleSelection: false,
        text: 'Nach Interessen filtern',
        enableCheckAll: false,
        showCheckbox: true,
        enableSearchFilter: true,
        classes: 'nova-multiselect'
    };

    starDropdownSettings = {
        singleSelection: false,
        text: 'Nach Hotelkategorie filtern',
        enableCheckAll: false,
        showCheckbox: true,
        enableSearchFilter: false,
        classes: 'nova-multiselect'
    };
    countryDropdownSettings = {
        singleSelection: false,
        text: 'Nach Regionen filtern',
        enableCheckAll: false,
        showCheckbox: true,
        enableSearchFilter: true,
        classes: 'nova-multiselect'
    };

    allFilterStrings = [];
    allCountryFilterStrings = [];

    @ViewChildren('font_size_bold') private fontSizeBoldElements: QueryList<ElementRef>;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public route: ActivatedRoute,
        public router: Router,
        public hotelHelper: SharedHotelHelperService,
        public media: MediaService,
        public title: Title,
        private renderer: Renderer2,
        private filterPipe: IndexHotelFilterPipe
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
        const path = window.location.pathname;
        const filter = sessionStorage.getItem('filter:' + path);
        if (filter) {
            const filterData = JSON.parse(filter);
            if (filterData.selectedFilters) {
                this.selectedFilters = filterData.selectedFilters;
            }
            if (filterData.selectedCountryFilters) {
                this.selectedCountryFilters = filterData.selectedCountryFilters;
            }
            if (filterData.selectedStars) {
                this.selectedStars = filterData.selectedStars;
            }
        }
    }

    setRemainingFilters(contents) {
        let allFilterStrings = [];
        contents.forEach(
            content => (allFilterStrings = [...allFilterStrings, ...(content.hotel_labels ? content.hotel_labels : [])])
        );

        this.allFilters = allFilterStrings
            .filter((item, pos) => allFilterStrings.indexOf(item) === pos)
            .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
            .map(item => ({ id: item, itemName: item }));

        const path = window.location.pathname;
        const filter = sessionStorage.getItem('filter:' + path);
        if (filter) {
            const filterData = JSON.parse(filter);
            if (filterData.selectedFilters) {
                this.selectedFilters = filterData.selectedFilters;
            }
            if (filterData.selectedCountryFilters) {
                this.selectedCountryFilters = filterData.selectedCountryFilters;
            }
            if (filterData.selectedStars) {
                this.selectedStars = filterData.selectedStars;
            }
        }
    }

    ngOnInit(): void {
        this.data.contents.forEach(content => {
            if (!content.stars || (content.stars && content.stars.length <= 0)) {
                content.stars = '0';
            }

            return this.hotelHelper.handleTagInputs(content);
        });

        this.allFilters = setFilters(this.allFilterStrings, this.data.contents, 'hotel_labels');
        this.allCountryFilters = setFilters(this.allCountryFilterStrings, this.data.contents, 'country_filters');
        this.setStars(this.data.contents);
        this.filter();
    }

    setStars(contents) {
        this.stars = this.defaultStars.filter(
            star => contents.map(content => content.stars.slice(0, 1)).indexOf(star.id) > -1
        );
    }

    filter() {
        this.filteredData = this.filterPipe.transform(
            this.data.contents,
            this.selectedFilters,
            this.selectedStars,
            this.selectedCountryFilters
        );

        // FTI-83 - dont show hotels without name
        this.filteredData = this.filteredData.filter(hotel => hotel.header !== undefined);

        setRemainingFilters(this.allFilters, this.filteredData, 'hotel_labels');

        this.standard = this.filteredData.filter(hotel => hotel.templateName === 'hotel-detail-standard');
        this.premium = this.filteredData.filter(hotel => hotel.templateName === 'hotel-detail-premium');

        const path = window.location.pathname;
        sessionStorage.setItem(
            'filter:' + path,
            JSON.stringify({
                selectedFilters: this.selectedFilters,
                selectedCountryFilters: this.selectedCountryFilters,
                selectedStars: this.selectedStars
            })
        );
    }

    ngAfterContentInit() {
        if (this.data['font_bold']) {
            this.nova.getFont(this.data['font_bold']).subscribe(fontData => {
                this.fontSizeBoldElements &&
                    this.fontSizeBoldElements.toArray().map(element => {
                        if (!element) {
                            return;
                        }

                        this.renderer.setStyle(element.nativeElement, 'font-family', fontData.name);
                    });
            });
        }
    }

    goTo(id) {
        const floor = this.data.contents.filter(content => content._id === id)[0];
        const params = this.route.params['value'];
        this.router.navigate(['/', params['langHandle'], params['issueHandle'], params['pageHandle'], floor.handle], {
            queryParamsHandling: 'preserve'
        });
    }
}
