import { AfterViewChecked, Component, HostListener, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet, RoutesRecognized } from '@angular/router';
import { slideInOutAnimation } from 'app/_helpers/slide-in-out.animation';
import { NovaAPIService } from 'app/_services/nova-api.service';
import * as _ from 'underscore';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { TranslateService } from '@ngx-translate/core';
import { TransferState, makeStateKey, Meta, Title } from '@angular/platform-browser';
import { SSRService } from 'app/_services/ssr.service';
import { InjectService } from 'app/_services/inject.service';
import { MagazineService } from 'app/_services/magazine.service';
import { MediaService } from 'app/_services/media.service';
import { ChangeDetectorRef } from '@angular/core';
import { PresentationService } from 'app/_services/presentation.service';
import { NewsletterService } from 'app/_services/newsletter.service';
import { LogoAndFaviconService } from 'app/_services/logoAndFavicon.service';
import { skip } from 'rxjs/operators';
import { NovaLondonOverlayComponent } from 'app/_components/nova-london-overlay/nova-london-overlay.component';
import { NovaBudapestOverlayComponent } from 'app/_components/nova-budapest-overlay/nova-budapest-overlay.component';
import { NovaFtiOverlayComponent } from 'app/_components/fti/nova-fti-overlay/nova-fti-overlay.component';
import { DOCUMENT } from '@angular/common';
import { CookieService as CustomCookieService } from './_services/cookie.service';
import { CookieService } from 'ngx-cookie-service';

const LANG_KEY = makeStateKey('lang');

export type DeepLinkParam = 'imprint' | 'privacy' | null;

function isValidDeepLinkParam(param: string): param is DeepLinkParam {
    return param === 'imprint' || param === 'privacy';
}

@Component({
    selector: 'app-root',
    animations: [slideInOutAnimation],
    templateUrl: './app.component.html'
})
export class AppComponent implements AfterViewChecked {
    private oldId: number;
    private oldAni: string;
    private currAni: string;
    public pageIndex = 1;
    public loaded: boolean;
    public deepLink: DeepLinkParam;

    public langsLoaded: boolean;
    public langs = [];

    @ViewChild('londonOverlay') public londonOverlay: NovaLondonOverlayComponent;
    @ViewChild('budapestOverlay') public budapestOverlay: NovaBudapestOverlayComponent;
    @ViewChild('ftiOverlay') public ftiOverlay: NovaFtiOverlayComponent;

    public showMagazine = false;

    public magazine;
    public travelAgency;
    public navStyle;
    public font;

    public mobileLinkStyle;
    public preloaderLogo: string;

    private clickWait = false;
    private wait = false;

    public showPDF = false;
    public showPrint = false;

    constructor(
        public nova: NovaAPIService,
        public cdr: ChangeDetectorRef,
        public media: MediaService,
        public magazineService: MagazineService,
        public ssr: SSRService,
        public injectService: InjectService,
        public translate: TranslateService,
        public uiEventHandler: UIEventHandlerService,
        private router: Router,
        public presentation: PresentationService,
        public newsletter: NewsletterService,
        public LogoAndFavicon: LogoAndFaviconService,
        private metaService: Meta,
        private titleService: Title,
        @Inject(DOCUMENT) private document: Document,
        public customCookieService: CustomCookieService,
        private cookieService: CookieService,
        public state?: TransferState
    ) {
        let defaultLang = this.state.get(LANG_KEY, null as any);
        let presentationPagesIndex = 0;

        if (!defaultLang) {
            defaultLang = this.nova.findLang();
        }

        this.translate.setDefaultLang(defaultLang);
        this.translate.use(defaultLang);

        this.translate.get('CONTENT_TABLE').subscribe((res: string) => {
            this.uiEventHandler.CONTENT_TABLE = res;
        });

        this.magazineService.getMagazine().subscribe(magazine => {
            if (!this.ssr.isBrowser) {
                return;
            }

            if (magazine.notifications && magazine.notifications.enabled) {
                this.loadCleverPushIntegration(magazine);
            }
        });

        if (this.ssr.isBrowser && typeof window !== 'undefined') {
            this.injectService.injectPlausibleProvider();
        }

        this.router.events.subscribe(event => {
            if (event instanceof RoutesRecognized && !this.loaded) {
                const eventParams = event.state.root.firstChild.params;

                this.magazineService.getIssues().subscribe(
                    data => {
                        let params = eventParams;
                        if (router.routerState.root.firstChild && router.routerState.root.firstChild['params']) {
                            params = router.routerState.root.firstChild.params['value'];
                        }

                        this.nova.issuesOrg = data.issues;
                        this.magazine = data.magazine;
                        this.navStyle = this.magazine.navigation;
                        this.magazineService.issuesData = data.issuesData;

                        this.nova.getFont(this.magazine.navigation.font).subscribe(fontData => {
                            this.font = fontData.name;
                        });

                        if (params['issueHandle']) {
                            this.showMagazine = true;
                        } else {
                            this.showMagazine = false;
                        }

                        this.checkForLanguageUpdate(Object.assign({}, params));
                        this.loaded = true;

                        if (this.magazine.preloaderLogo) {
                            this.preloaderLogo = this.magazine.preloaderLogo.url;
                        } else if (this.magazine.logo) {
                            this.preloaderLogo = this.magazine.logo.url;
                        }

                        this.mobileNavStyle();
                        this.LogoAndFavicon.setFaviconAndLogo();

                        if (this.ssr.isBrowser && this.loaded && this.showMagazine) {
                            this.nova.getPageData().subscribe(data => {
                                if (data['data']['pdf_version']) {
                                    this.showPDF = true;
                                } else {
                                    this.showPDF = false;
                                }

                                if (this.nova.issue && this.nova.issue['showPrint'] === 'no') {
                                    this.showPrint = false;
                                } else {
                                    this.showPrint = true;
                                }
                            });
                        }

                        if (this.magazine.presentation) {
                            this.presentation.load(this.magazine.presentation);
                        }

                        if (this.presentation.enabled) {
                            this.presentation.nextPage$.pipe(skip(1)).subscribe(() => {
                                const presentationPages = this.nova.issue.presentationPages;
                                if (presentationPages) {
                                    presentationPagesIndex = this.handlePresentationPages(
                                        presentationPagesIndex,
                                        presentationPages
                                    );
                                } else {
                                    this.autoNavigate(this.pageIndex);
                                }
                            });
                        }

                        if (this.ssr.isBrowser && typeof window !== 'undefined') {
                            this.handleCookies(this.magazine);
                        }
                    },
                    err => {
                        this.loaded = true;

                        if (err.status === 451) {
                            this.router.navigate(['/errorDisabled'], { queryParamsHandling: 'preserve' });

                            return;
                        }

                        if (err.status === 401 || (err instanceof Error && err.message === 'Unauthorized')) {
                            this.router.navigate(['/login'], {
                                queryParamsHandling: 'preserve'
                            });

                            return;
                        }

                        if (err.status === 403 || (err instanceof Error && err.message === 'Forbidden')) {
                            this.router.navigate(['/login'], {
                                queryParamsHandling: 'preserve'
                            });

                            return;
                        }

                        this.router.navigate(['/error'], { queryParamsHandling: 'preserve' });
                    }
                );
            }

            if (event instanceof NavigationEnd) {
                this.magazineService.getSEOData(event.url).subscribe(data => {
                    if (this.ssr.isBrowser) {
                        this.renderSeoData(data);
                    }
                });

                const params = router.routerState.root.firstChild.params['value'];

                if (params['issueHandle']) {
                    this.showMagazine = true;

                    if (params['contentHandle']) {
                        this.uiEventHandler.showNavArrows = false;
                    } else {
                        this.uiEventHandler.showNavArrows = true;
                    }

                    this.uiEventHandler.showMenu = true;

                    if (!params['pageHandle']) {
                        this.pageIndex = 1;
                    }
                } else {
                    this.uiEventHandler.showNavArrows = false;
                    this.uiEventHandler.showMenu = false;
                    this.showMagazine = false;
                }

                this.pageIndex = this.getCurrentRouteId(router.routerState.root.firstChild);

                if (this.ssr.isBrowser && this.loaded && this.showMagazine) {
                    this.nova.getPageData().subscribe(data => {
                        if (params['contentHandle']) {
                            if (data.data.templateName === 'image-gallery') {
                                this.uiEventHandler.showNavArrows = true;
                            } else {
                                this.uiEventHandler.showNavArrows = false;
                            }
                        } else {
                            this.uiEventHandler.showNavArrows = true;
                        }

                        if (data['data']['pdf_version']) {
                            this.showPDF = true;
                        } else {
                            this.showPDF = false;
                        }

                        if (this.nova.issue && this.nova.issue['showPrint'] === 'no') {
                            this.showPrint = false;
                        } else {
                            this.showPrint = true;
                        }
                    });

                    this.LogoAndFavicon.setFaviconAndLogo();
                }
            }
        });

        this.uiEventHandler.screenshotEvent$.subscribe(() => {
            if (this.nova.isScreenShot) {
                this.nova.preloadPageMedia().then(() => {
                    $('body').addClass('readyForScreenshot');
                });
            }
        });

        this.uiEventHandler.resizeEvent$.subscribe(() => {
            this.nova.media.getMediaVersion();
        });
    }

    async ngAfterViewChecked() {
        const params = new URLSearchParams(this.document.location.search.substring(1));
        const pageParam = params.get('page');

        if (this.nova.issue && pageParam && isValidDeepLinkParam(pageParam)) {
            this.deepLink = pageParam;

            if (this.ftiOverlay) {
                this.ftiOverlay.visible = true;
            }

            if (this.budapestOverlay) {
                this.budapestOverlay.visible = true;
            }

            if (this.londonOverlay) {
                this.londonOverlay.visible = true;
            }
        }

        this.ssr.isBrowser &&
            typeof window !== 'undefined' &&
            this.magazine &&
            this.customCookieService.mapUserCentricsCustomServices(this.magazine.cookieConsent);
    }

    private handleCookies(magazine) {
        const consentCookie = this.uiEventHandler.getCookie('CookieConsent');

        if (magazine.cookieConsent.type === 'none') {
            return;
        }

        if ((consentCookie && JSON.parse(consentCookie).statistics) || magazine.cookieConsent.type === 'usercentrics') {
            this.injectService.injectAnalyticsProvider(magazine, this.nova.lang);

            if (!this.nova.isScreenShot) {
                this.customCookieService.init(magazine.language, magazine.cookieConsent, magazine.trackingProvider);
            }
        } else {
            const cookies = this.cookieService.getAll();

            if (!cookies) {
                return;
            }

            for (const cookie of Object.keys(cookies)) {
                if (cookie.startsWith('_g')) {
                    const novamagDomain = document.location.hostname.includes('localhost')
                        ? `localhost`
                        : document.location.hostname.includes('novlab')
                            ? `.novlab.de`
                            : `.novamag.de`;

                    this.cookieService.delete(cookie, '/', novamagDomain);

                    for (const domain of magazine.domains) {
                        const domainArr = domain.domain.split('.');
                        const domainRoot = `.${domainArr.splice(domainArr.length - 2).join('.')}`;
                        this.cookieService.delete(cookie, '/', domainRoot);
                    }
                }
            }
        }
    }

    private renderSeoData(data) {
        this.titleService.setTitle(data.metaTitle);
        if (data.canonicalLink) {
            this.metaService.addTag({
                rel: 'canonical',
                href: data.canonicalLink
            });
        }
        const robots = [];
        robots.push(data.noIndex ? 'noindex' : 'index');
        robots.push(data.noFollow ? 'nofollow' : 'follow');
        this.nova.addOrSetTag({ name: 'description', content: data.metaDescription || '' });
        this.nova.addOrSetTag({
            name: 'keywords',
            content: (data.metaKeywords && data.metaKeywords.join(', ')) || ''
        });
        this.nova.addOrSetTag({ name: 'robots', content: robots.join(', ') });
        this.nova.addOrSetTag({ property: 'og:type', content: data.ogType || 'website' });
        this.nova.addOrSetTag({ property: 'og:title', content: data.ogTitle || '' });
        this.nova.addOrSetTag({ property: 'og:description', content: data.ogDescription || '' });
        this.nova.addOrSetTag({ property: 'og:image', content: data.ogImage || '' });
        this.nova.addOrSetTag({ name: 'twitter:card', content: data.twitterType || '' });
        this.nova.addOrSetTag({ name: 'twitter:title', content: data.twitterTitle || '' });
        this.nova.addOrSetTag({ name: 'twitter:description', content: data.twitterDescription || '' });
        this.nova.addOrSetTag({ name: 'twitter:image', content: data.twitterImage || '' });

        if (data.ogType === 'article') {
            if (data.ArticlePublishedTime) {
                this.nova.addOrSetTag({
                    property: 'article:published_time',
                    content: data.ArticlePublishedTime
                });
            }

            if (data.ArticleModifiedTime) {
                this.nova.addOrSetTag({
                    property: 'article:modified_time',
                    content: data.ArticleModifiedTime
                });
            }

            if (data.ArticleExpirationTime) {
                this.nova.addOrSetTag({
                    property: 'article:expiration_time',
                    content: data.ArticleExpirationTime
                });
            }

            if (data.articleAuthor) {
                this.nova.addOrSetTag({ property: 'article:author', content: data.articleAuthor });
            }

            if (data.articleSection) {
                this.nova.addOrSetTag({ property: 'article:section', content: data.articleSection });
            }

            if (data.articleTags) {
                data.articleTags.map(tag => this.nova.addTag({ property: 'article:tag', content: tag }));
            }
        }
    }

    private handlePresentationPages(presentationPagesIndex: number, presentationPages): number {
        presentationPagesIndex = presentationPages.findIndex(
            presentationPageId => presentationPageId === this.nova.issue.pages[this.pageIndex - 1]._id
        );

        let nextPageId = presentationPages[presentationPagesIndex + 1];

        if (!nextPageId) {
            nextPageId = presentationPages[0];
            presentationPagesIndex = -1;
        }

        const nextPageIndex = this.nova.issue.pages.findIndex(page => page._id === nextPageId);
        this.autoNavigate(nextPageIndex);

        return presentationPagesIndex + 1;
    }

    get navType(): 'london' | 'budapest' | 'hamburg' | 'fti' {
        return this.magazine && this.magazine.navigation.style;
    }

    getNavClass() {
        if (this.magazine) {
            return this.magazine.navigation.style;
        }
    }

    getNavColors() {
        if (['london', 'budapest', 'fti'].includes(this.navType)) {
            return {
                color: this.uiEventHandler.getColor(this.magazine.navigation.arrowColor),
                'background-color': this.uiEventHandler.getColor(this.magazine.navigation.arrowColorBackground)
            };
        }

        return {};
    }

    getNavActiveColors() {
        if (['london', 'budapest', 'fti'].includes(this.navType)) {
            return {
                color: this.uiEventHandler.getColor(this.magazine.navigation.arrowColorHover),
                'background-color': this.uiEventHandler.getColor(this.magazine.navigation.arrowColorBackgroundHover)
            };
        }

        return {};
    }

    customContentTable() {
        if (this.clickWait || this.uiEventHandler.topClose) {
            return;
        }

        this.clickWait = true;
        setTimeout(() => {
            this.clickWait = false;
        }, 100);

        if (!this.nova.pages) {
            return;
        }

        const params = this.router.routerState.root.firstChild.params['value'];

        this.nova.pages.forEach(page => {
            if (page._id === this.nova.issue.navigation.indexPage) {
                this.router.navigate(['/', params['langHandle'], params['issueHandle'], page.handle], {
                    queryParamsHandling: 'preserve'
                });
            }
        });
    }

    mobileNavStyle() {
        const style = `
            border-left: 1px solid ${this.c(this.navStyle.borderColor)};
            color: ${this.c(this.navStyle.textColor)};
        `;

        this.mobileLinkStyle = this.convertCSS(style);
    }

    isDisabled() {
        const params = this.router.routerState.root.firstChild.params['value'];

        let floor = -1;
        let page = -1;

        if (params['pageHandle']) {
            this.nova.pages.forEach((val, index) => {
                if (val.handle === params['pageHandle']) {
                    page = index;
                }
            });
        }

        if (params['contentHandle'] && page !== -1) {
            this.nova.pages[page].contents.forEach((val, index) => {
                if (val.handle === params['contentHandle']) {
                    floor = index;
                }
            });
        }

        if (page !== -1) {
            if (
                floor !== -1 &&
                this.nova.pages[page].contents[floor] &&
                this.nova.pages[page].contents[floor].status !== 'active'
            ) {
                return 'Dieser Inhalt ist <b class="inactive">Inaktiv</b>';
            }
            if (this.nova.pages[page].status !== 'active') {
                return 'Diese Seite ist <b class="inactive">Inaktiv</b>';
            }
        }
        if (params['issueHandle']) {
            let found;
            this.nova.issues.forEach(issue => {
                if (issue.handle === params['issueHandle']) {
                    found = issue;
                }
            });
            if (found && found['status'] !== 'active') {
                return 'Diese Ausgabe ist <b class="inactive">Inaktiv</b>';
            }
        }

        if (this.magazine && this.magazine.status !== 'active') {
            return 'Dieses Magazin ist <b class="inactive">Inaktiv</b>';
        }

        return '';
    }

    c(color, alpha?) {
        return this.uiEventHandler.getColor(color, alpha);
    }

    checkForLanguageUpdate(params) {
        let newLang = params.langHandle;

        if (!newLang || newLang === '') {
            newLang = this.nova.findLang();
        }

        if (this.nova.lang !== newLang) {
            this.nova.lang = newLang;
            this.nova.mapIssue();
            // check if language exists
            let langs = this.getLanguages();

            if (langs.length <= 0) {
                if (newLang === this.magazine.language) return;

                newLang = this.magazine.language;
                this.nova.lang = newLang;
                const availableLanguages = [];

                Array.from(this.magazineService.issuesData).map(item => {
                    availableLanguages.push(item[0].language);
                });

                this.magazineService.availableLanguages = _.unique(availableLanguages, 'language');
                langs = this.magazineService.availableLanguages;
            }

            if (langs.indexOf(newLang) === -1) {
                newLang = langs[0];
            }

            if (this.nova.lang !== newLang) {
                this.nova.lang = newLang;
                this.nova.mapIssue();
                params.langHandle = this.nova.lang;

                // this prevents the router from navigating to the start page everytime, otherwise deeplinks won't work
                if (!params.issueHandle) {
                    this.router.navigate(['/', params.langHandle], { queryParamsHandling: 'preserve' });
                }
            }
        }

        if (this.ssr.isBrowser && typeof window !== 'undefined') {
            $('html').attr('lang', newLang);
        }

        this.state.set(LANG_KEY, newLang);

        this.translate.use(newLang);
        this.translate.get('CONTENT_TABLE').subscribe((res: string) => {
            this.uiEventHandler.CONTENT_TABLE = res;
        });
    }

    getLanguages() {
        return this.magazineService.availableLanguages;
    }

    toggleOverlay(value?: boolean) {
        this.closeOverlay();

        if (this.ftiOverlay) {
            this.ftiOverlay.visible = value !== undefined ? value : !this.ftiOverlay.visible;
        }
    }

    closeOverlay() {
        const overlayComponent = this.ftiOverlay || this.budapestOverlay || this.londonOverlay;

        if (overlayComponent.visible) {
            this.router.navigate([], {
                queryParams: {
                    page: null
                },
                queryParamsHandling: 'merge'
            });

            overlayComponent.visible = false;
            overlayComponent.active = 'pages';

            return;
        }
    }

    goTo(pageIndex: number) {
        if (this.wait || !pageIndex) return;
        this.wait = true;
        const nextPageIndex = this.validatePage(pageIndex);
        const params = this.router.routerState.root.firstChild.params['value'];
        this.closeOverlay();

        this.router.navigate(
            ['/', params['langHandle'], params['issueHandle'], this.nova.pages[nextPageIndex - 1].handle],
            {
                queryParamsHandling: 'preserve'
            }
        );

        // reset menu button
        if (this.ssr.isBrowser && typeof window !== 'undefined') {
            this.document.querySelector('.menu-button svg').classList.remove('fa-close');
            this.document.querySelector('.menu-button svg').classList.add('fa-bars');
        }

        setTimeout(() => {
            this.wait = false;
        }, 100);
    }

    urlHasImg() {
        const params = new URLSearchParams(this.router.url.split('?')[1]);

        return params.has('img');
    }

    slideLeft() {
        if (this.uiEventHandler.currentBlogPost) {
            const params = this.router.routerState.root.firstChild.params['value'];

            const currentPageIndex = this.uiEventHandler.blogPage.contents.findIndex(
                page => page._id === this.uiEventHandler.currentBlogPost._id
            );

            this.router.navigate(
                [
                    '/',
                    params['langHandle'],
                    params['issueHandle'],
                    this.uiEventHandler.blogPage.handle,
                    currentPageIndex === this.uiEventHandler.blogPage.contents.length - 1
                        ? ''
                        : this.uiEventHandler.blogPage.contents[currentPageIndex + 1].handle
                ],
                {
                    queryParamsHandling: 'preserve'
                }
            );
            return;
        }

        if (this.uiEventHandler.disableNav) {
            this.uiEventHandler.onArrowEvent('left');
        } else {
            return this.goTo(this.pageIndex - 1);
        }
    }

    slideRight() {
        if (this.uiEventHandler.blogPage) {
            if ($('.next-page').attr('disabled')) return;

            // navigate from blog index to first single blog post
            if (this.uiEventHandler.blogPage.contents.length > 0 && !this.uiEventHandler.currentBlogPost) {
                this.router.navigate([
                    '/',
                    this.router.routerState.root.firstChild.params['value']['langHandle'],
                    this.router.routerState.root.firstChild.params['value']['issueHandle'],
                    this.uiEventHandler.blogPage.handle,
                    this.uiEventHandler.blogPage.contents[this.uiEventHandler.blogPage.contents.length - 1].handle
                ]);

                return;
            }

            // navigate from single blog post to next single blog post or to the next page if it's the last blog post and there are more pages
            if (this.uiEventHandler.currentBlogPost) {
                const params = this.router.routerState.root.firstChild.params['value'];

                const currentBlogPostIndex = this.uiEventHandler.blogPage.contents.findIndex(
                    page => page._id === this.uiEventHandler.currentBlogPost._id
                );

                if (currentBlogPostIndex === 0) {
                    return this.goTo(this.pageIndex + 1);
                }

                this.router.navigate(
                    [
                        '/',
                        params['langHandle'],
                        params['issueHandle'],
                        this.uiEventHandler.blogPage.handle,
                        this.uiEventHandler.blogPage.contents[currentBlogPostIndex - 1].handle
                    ],
                    {
                        queryParamsHandling: 'preserve'
                    }
                );

                return;
            }
        }

        if (this.uiEventHandler.disableNav) {
            this.uiEventHandler.onArrowEvent('right');
        } else {
            return this.goTo(this.pageIndex + 1);
        }
    }

    validatePage(pageIndex) {
        if (pageIndex < 1) return 1;
        if (pageIndex > this.nova.pages.length) return this.nova.pages.length;

        return pageIndex;
    }

    isLast() {
        return this.pageIndex >= this.nova.pages.length;
    }

    @HostListener('document:keyup', ['$event'])
    handleKeyboardEvent(keypressEvent) {
        if (this.uiEventHandler.showPaidContentDialog === true) return;

        if (keypressEvent.which === 39) {
            this.slideRight();
        } else if (keypressEvent.which === 37) {
            this.slideLeft();
        }
    }

    getCurrentRouteId(route: ActivatedRoute) {
        let found = 1;
        if (this.nova.pages) {
            this.nova.pages.forEach((page, index) => {
                if (page.handle === route.params['value'].pageHandle) {
                    found = index + 1;
                }
            });
        }

        return found;
    }

    prepareRouteTransition(outlet: RouterOutlet): string | null {
        if (!outlet['activated']) {
            return null;
        }
        try {
            this.pageIndex = this.getCurrentRouteId(outlet.activatedRoute);
            this.currAni = '';

            if (!this.oldId) {
                this.currAni = '';

                return this.currAni;
            }

            if (this.pageIndex < this.oldId) {
                this.currAni = 'left';
            } else {
                this.currAni = 'right';
            }

            if (this.oldId !== this.pageIndex) {
                if (this.currAni !== '' && this.oldAni === this.currAni) {
                    if (this.currAni.endsWith('-alt')) {
                        this.currAni = this.currAni.substring(0, this.currAni.length - 4);
                    } else {
                        this.currAni += '-alt';
                    }
                }
            } else {
                this.currAni = this.oldAni;
            }

            return this.currAni;
        } catch (e) {
            console.error(e);

            return '';
        }
    }

    animationDone() {
        this.oldId = this.pageIndex;
        this.oldAni = this.currAni;
    }

    isPhantomJS() {
        return this.nova.isScreenShot;
    }

    mobileMatrixStyle() {
        let ret = '';

        if (this.uiEventHandler.isMobile()) {
            ret += 'border-top: 1px solid ' + this.uiEventHandler.getColor(this.magazine.navigation.borderColor) + ';';
            ret += 'border-left: 1px solid ' + this.uiEventHandler.getColor(this.magazine.navigation.borderColor) + ';';
        }

        return this.convertCSS(ret);
    }

    getMobileStyle() {
        let ret = '';

        if (typeof window === 'undefined') {
            return;
        }

        if (this.magazine.navigation['backgroundColor']) {
            ret += `background-color: ${this.uiEventHandler.getColor(this.magazine.navigation['backgroundColor'])}; `;
        }
        if (this.magazine.navigation['textColor']) {
            ret += `color: ${this.uiEventHandler.getColor(this.magazine.navigation['textColor'])}; `;
        }
        if (this.magazine.navigation['font']) {
            ret += `font-family: ${this.magazine.navigation['font']}; `;
        }
        if (this.magazine.navigation['borderColor']) {
            ret += `border-top: 1px solid ${this.uiEventHandler.getColor(this.magazine.navigation['borderColor'])}; `;
        }

        return this.convertCSS(ret);
    }

    convertCSS(css) {
        const input = css.toString();
        if (input.indexOf(':') > -1) {
            const result = {},
                attributes = input.split(';');
            for (let i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    const entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }

            return result;
        }

        return css;
    }

    setMyStyles(obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    }

    private loadCleverPushIntegration(mag: any) {
        const defaultCleverPushScript = `<script type="application/javascript" src="https://static.cleverpush.com/channel/loader/${
            mag.notifications.channel_id
        }.js"></script>`;

        const userCentricscleverPushScript = `<script type="text/plain" data-usercentrics="CleverPush" data-name="CleverPush" src="https://static.cleverpush.com/channel/loader/${
            mag.notifications.channel_id
        }.js"></script>`;

        const cleverPushScript =
            !mag.cookieConsent || (mag.cookieConsent && mag.cookieConsent.type === 'default')
                ? defaultCleverPushScript
                : userCentricscleverPushScript;

        $('head').append(cleverPushScript);
    }

    private autoNavigate(page: number): void {
        if (page === this.nova.pages.length) {
            this.goTo(1);
        } else {
            this.goTo(page + 1);
        }
    }
}
