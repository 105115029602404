<div class="title-bar"
    [ngClass]="{ nobg: issue.labelHasBackground == 'no' }"
    [style.overflow]="nav.logoSize && nav.logoSize !== '100%' ? 'unset': 'hidden'"
	[style.color]="getColor(nav.textColor)">
	
	<nova-nav-item (click)="toggleFtiOverlay($event)" class="menu-button"
		[style.border-right-color]="getColor(nav.borderColor)">
            <span *ngIf="!overlayVisible"><i class="fa fa-bars"></i></span>
            <span *ngIf="overlayVisible"><i class="fa fa-times"></i></span>
    </nova-nav-item>

	<div [style.background-color]="mobileBG()" class="left">
		<div class="logo" (click)="goToUrl(nova.lang + '/gluecksmomente');" [style.border-right-color]="getColor(nav.borderColor)"
			*ngIf="issue.showLogo == 'yes'">
			<img [src]="ui.logo || 'assets/img/novamag.svg'" [style.height]="nav.logoSize" alt="Logo" />
		</div>

		<div *ngIf="homeLinks?.primary?.target" class="nav-title link animated-world-icon-container" (click)="goToUrl(homeLinks.primary.target)"
			[style.border-right-color]="getColor(nav.borderColor)">
            <nova-fti-animated-world-icon [text]="homeLinks.primary.text"></nova-fti-animated-world-icon>
        </div>

		<div *ngIf="homeLinks?.secondary?.target" class="nav-title link animated-world-icon-container" (click)="goToUrl(homeLinks.secondary.target)"
			[style.border-right-color]="getColor(nav.borderColor)">
            <nova-fti-animated-world-icon [text]="homeLinks.secondary.text"></nova-fti-animated-world-icon>
        </div>

		<div class="hide-mobile link" (click)="ui.goToFirst()" [style.border-right-color]="getColor(nav.borderColor)"
			*ngIf="issue.showName == 'yes' && issue.name">{{ issue.name }}
		</div>

		<div *ngIf="issue.showNumber == 'yes' && issue.number"  class="link" [style.border-right-color]="getColor(nav.borderColor)">
			{{ issue.number }}
		</div>

		<div *ngIf="issue.showPageNumbers == 'yes'" class="link" (click)="goToIndexPage()" [style.border-right-color]="getColor(nav.borderColor)">
			{{ currentPage + 1 }}/{{ pageNum }}
		</div>

		<div *ngIf="issue.showPageTitle == 'yes' && !ui.isTablet()"	[style.border-right-color]="getColor(nav.borderColor)" class="nav-title">
			{{ pageTitle }}
		</div>
		
		<div *ngIf="issue.additionalInformation && !ui.isTablet()" [style.border-right-color]="(infoPage || homeLinks) && getColor(nav.borderColor)" class="nav-title">
			{{ issue.additionalInformation }}
		</div>

		<div *ngIf="infoPage" [style.border-right-color]="infoPage && homeLinks && getColor(nav.borderColor)" class="nav-title link" (click)="goToInfoPage(infoPage)">
			<i class="icon-impressum"></i>
		</div>
	</div>

</div>
