import { Component, OnChanges, Input } from '@angular/core';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { MagazineService } from 'app/_services/magazine.service';

@Component({
    selector: 'nova-budapest-overlay-privacy',
    templateUrl: './nova-budapest-overlay-privacy.component.html',
    styleUrls: ['./nova-budapest-overlay-privacy.component.scss']
})
export class NovaBudapestOverlayPrivacyComponent implements OnChanges {
    @Input() magazine;
    @Input() issue;
    @Input() public font;

    public termsTitle: string;
    public termsText: string;
    public termsUrl: string;

    constructor(public nova: NovaAPIService, public mag: MagazineService) {}

    ngOnChanges() {
        this.termsTitle = this.issue.termsTitle || this.magazine.termsTitle;
        this.termsText =
            (this.issue.termsText || this.magazine.termsText) &&
            (this.issue.termsText || this.magazine.termsText).replace(/(?:\r\n|\r|\n)/g, '<br>');
        this.termsUrl = this.issue.termsUrl || this.magazine.termsUrl;
    }
}
