import * as tslib_1 from "tslib";
import { DomSanitizer } from '@angular/platform-browser';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { PageVideoClassicComponent } from 'app/pages/templates/video-classic/video-classic.component';
import { ActivatedRoute, Router } from '@angular/router';
var ServerVideoClassicComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ServerVideoClassicComponent, _super);
    function ServerVideoClassicComponent(nova, sanitizer, renderer, uiEventHandler, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, meta, title, route, router) || this;
        _this.nova = nova;
        _this.sanitizer = sanitizer;
        _this.renderer = renderer;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        return _this;
    }
    ServerVideoClassicComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.addOgTags();
    };
    ServerVideoClassicComponent = tslib_1.__decorate([
        NovaTemplate('ServerVideoClassicComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            DomSanitizer,
            Renderer2,
            UIEventHandlerService,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], ServerVideoClassicComponent);
    return ServerVideoClassicComponent;
}(PageVideoClassicComponent));
export { ServerVideoClassicComponent };
