<div class="archive-scroll">
    <div *ngIf="issues && issues.length > 0" class="scroller"
        [ngClass]="{single: issues.length == 1, half: issues.length == 2}">
        <a *ngFor="let val of issues" class="issue" [ngStyle]="{'background-color':getStyleText(val)}"
            [routerLink]="['/', nova.lang, val.handle]" queryParamsHandling="preserve">
            <nova-media [mediaId]="val.archiveBackground || val.background_media" [bg]="true" [fallback]="true"
                [playOnHover]="true" [forceVideo]="true">
            </nova-media>
            <section class="center">
                <section class="inner">
                    <h2
                        [ngStyle]="{'font-family':font, 'color':getStyleText(val), 'background-color':getStyleTextInv(val)}">
                        {{ val.number }}</h2>
                    <h1 [ngStyle]="{'font-family':font, 'color':getStyleTextInv(val)}">{{ val.name }}</h1>
                    <p [ngStyle]="{'font-family':font, 'color':getStyleTextInv(val)}">{{ val.subHeader }}</p><br />
                    <span [ngStyle]="{'font-family':font, 'color':getStyleTextInv(val)}"><i
                            class="icon-open-magazine"></i><br /><small>{{ 'OPEN' | translate }}</small></span>
                </section>
            </section>
        </a>
    </div>
</div>