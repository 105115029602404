import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { MagazineService } from 'app/_services/magazine.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { Router } from '@angular/router';

function createUrl(template: string, issueHandle: string, language: string, handle: string, parentHandle?: string) {
    return `/${language}/${issueHandle}${
        parentHandle && template !== 'blog-article' ? `/${parentHandle}` : ''
    }/${handle}`;
}

@Component({
    selector: 'nova-london-overlay-pages',
    templateUrl: './nova-london-overlay-pages.component.html',
    styleUrls: ['./nova-london-overlay-pages.component.scss']
})
export class NovaLondonOverlayPagesComponent implements OnInit {
    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
    @Input() currentPage = -1;
    @Input() public font;
    @Input() public borderColor;

    public pages = [];

    constructor(
        public nova: NovaAPIService,
        public mag: MagazineService,
        public uiEventHandler: UIEventHandlerService,
        public router: Router
    ) {}

    ngOnInit() {
        this.pages = this.nova.pages.reduce((acc, page) => {
            if (page.pageId || page.showInIndex === 'no') return acc;

            acc.push({
                ...page,
                routerLink: createUrl(page.templateName, this.nova.issue.handle, page.language, page.handle)
            });

            if (
                page.templateName === 'blog-index' &&
                page.showBlogPostsInIndex === 'active' &&
                page.contents.length > 0
            ) {
                for (const post of page.contents.sort((a, b) => b.order - a.order)) {
                    acc.push({
                        ...post,
                        routerLink: createUrl(
                            post.templateName,
                            this.nova.issue.handle,
                            post.language,
                            post.handle,
                            page.handle
                        )
                    });
                }
            }

            return acc;
        }, []);
    }

    isActivePage(page) {
        return (
            page.handle ===
            window.location.href
                .split('?')[0]
                .split('/')
                .pop()
        );
    }

    isActive(check) {
        if (check) {
            return this.borderColor;
        }
    }

    pad(num, size) {
        let s = num + '';

        while (s.length < size) {
            s = '0' + s;
        }

        return s;
    }
}
