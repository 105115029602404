<div *ngIf="visible" class="nova-budapest-overlay">
  <i class="icon-cancel my-close-icon" (click)="hide()"></i>
  <div class="scrollbar-holder">
    <div class="scroller">
      <div class="top-nav" [style.font-family]="font">
        <a [class.active]="active == 'pages'" (click)="open('pages')">{{ 'PAGES' | translate }}</a>
        <a *ngIf="nova.issues && nova.issues.length > 1" [class.active]="active == 'archive'"
          (click)="open('archive')">{{ 'ARCHIVE' | translate }}</a>
        <a [class.active]="active == 'search'" (click)="open('search')">{{ 'SEARCH' | translate }}</a>
        <div *ngIf="this.languages.length > 1" style="display: inline-block;">
          <span [style.color]="borderColor">|</span>
          <a *ngFor="let value of languages" [class.active]="value == nova.lang"
            (click)="switchLang(value)">{{ value }}</a>
        </div>
        <span *ngIf="showPDF()" [style.color]="borderColor">|</span>
        <a *ngIf="showPDF()" (click)="print()">{{ 'PDF' | translate }}</a>
        <span *ngIf="showPrint()" [style.color]="borderColor">|</span>
        <a *ngIf="showPrint()" (click)="print()">{{ 'PRINT' | translate }}</a>
        <span [style.color]="borderColor" *ngIf="showShare()">|</span>
        <a *ngIf="showShare()" [class.active]="active == 'social'"
          (click)="open('social')">{{ 'SHARE' | translate }}</a>
        <span [style.color]="borderColor" *ngIf="(magazine.contact_enabled && magazine.contact_form) || (issue.contact_enabled && issue.contact_form)">|</span>
        <a *ngIf="(magazine.contact_enabled && magazine.contact_form) || (issue.contact_enabled && issue.contact_form)"
          [class.active]="active == 'contact'" (click)="open('contact')">{{ 'CONTACT' | translate }}</a>
        <span *ngIf="magazine.imprintTitle || magazine.imprintText || magazine.termsTitle || magazine.termsText"
          [style.color]="borderColor">|</span>
        <a *ngIf="magazine.imprintTitle || magazine.imprintText" [class.active]="active == 'imprint'"
          (click)="open('imprint')">{{ 'IMPRINT' | translate }}</a>
        <a *ngIf="magazine.termsTitle || magazine.termsText" [class.active]="active == 'privacy'"
          (click)="open('privacy')">{{ 'PRIVACY_POLICY' | translate }}</a>
      </div>
      <hr class="show-for-small-only" />
      <nova-budapest-overlay-pages [currentPage]="currentPageIndex" [borderColor]="borderColor" [font]="font"
        (pageChange)="pageChange.emit($event);hide()" *ngIf="active == 'pages'"></nova-budapest-overlay-pages>
      <nova-budapest-overlay-archive [borderColor]="borderColor" [font]="font" [lang]="nova.lang" (issueChange)="hide()"
        *ngIf="active == 'archive'"></nova-budapest-overlay-archive>
      <nova-budapest-overlay-imprint [font]="font" [magazine]="magazine" [issue]="issue" *ngIf="active == 'imprint'">
      </nova-budapest-overlay-imprint>
      <nova-budapest-overlay-privacy [font]="font" [magazine]="magazine" [issue]="issue" *ngIf="active == 'privacy'">
      </nova-budapest-overlay-privacy>
      <nova-budapest-overlay-contact [font]="font" [magazine]="magazine" [issue]="issue" *ngIf="active == 'contact'">
      </nova-budapest-overlay-contact>
      <nova-budapest-overlay-search [borderColor]="borderColor" [font]="font"
        (pageChange)="pageChange.emit($event);hide()" *ngIf="active == 'search'"></nova-budapest-overlay-search>
      <nova-budapest-overlay-social [magazine]="magazine" [font]="font" *ngIf="active == 'social'">
      </nova-budapest-overlay-social>
    </div>
  </div>
</div>
