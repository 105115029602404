import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './external-inline.component.html',
    styleUrls: ['./external-inline.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageExternalInlineComponent')
export class PageExternalInlineComponent extends Page implements OnInit {
    public data: any;

    public iframeSrc: SafeResourceUrl;
    public minHeight: number = 600;
    public heightCalculationMethod: string = 'max';
    public scrolling: boolean = false;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
        this.customPhantomEvent = true;
    }

    ngOnInit() {
        if (typeof window !== 'undefined') {
            if (this.data.external) {
                if (this.data.external.substring(0, 7) === '<iframe') {
                    const tmp = document.createElement('div');
                    tmp.innerHTML = this.data.external;
                    const src = tmp.querySelector('iframe').getAttribute('src');
                    this.iframeSrc = src;
                } else {
                    this.iframeSrc = this.data.external;
                }

                this.scrolling = this.data.scrolling || this.scrolling;
                this.minHeight = this.data.minHeight || this.minHeight;
                this.heightCalculationMethod = this.data.heightCalculationMethod || this.heightCalculationMethod;

                const self = this;
                setTimeout(() => {
                    self.uiEventHandler.onScreenShotEvent(self.data.id);
                }, 5000);
            }
        }
    }
}
