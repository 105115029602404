import { Component, Input, OnInit } from '@angular/core';
import { SSRService } from 'app/_services/ssr.service';

@Component({
    selector: 'nova-fti-animated-world-icon',
    templateUrl: './nova-fti-animated-world-icon.component.html',
    styleUrls: ['./nova-fti-animated-world-icon.component.scss']
})
export class NovaFtiAnimatedWorldicon implements OnInit {
    @Input() text: string;

    constructor(public ssr: SSRService) {}

    ngOnInit() {
        if (this.ssr.isBrowser && typeof window !== 'undefined') {
            document.documentElement.style.setProperty('--globus-size', `30px`);
        }
    }
}
