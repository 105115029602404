<div id="page-{{ id }}" class="page page-background-clean">
  <nova-bg [data]="data" class="hidden-sm"></nova-bg>
  <nova-scrollbar-holder [ngStyle]="{'backgroundColor':getColor(data['background_color'])}">
    <div class="scroller" [ngStyle]="flexboxStyle">
      <nova-mobile-img [data]="data" class="show-for-small-only left"></nova-mobile-img>
      <div [ngStyle]="logoStyle" class="bg clear" [style.background-color]="getColor(data['box_color'])"
        [hidden]="!data.text && !data.header && !data.subHeader && !data.label">
        <nova-title [data]="data"></nova-title>
        <nova-text-element class="con" [data]="data.text" [myid]="data._id" (openGallery)="gallery.openSlider($event)">
        </nova-text-element>
        <nova-content-links class="content-links" *ngIf="data.links && data.links.length > 0" [floors]="data.links">
        </nova-content-links>
      </div>
    </div>
  </nova-scrollbar-holder>
  <nova-text-gallery #gallery [medias]="data.textImages" [myid]="data._id"></nova-text-gallery>
</div>