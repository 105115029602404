import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ColorFormatter } from '../../../novamag-api/Types';
import { Meta, Title } from '@angular/platform-browser';
import * as $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './cover-title.component.html',
    styleUrls: ['./cover-title.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageCoverTitleComponent')
export class PageCoverTitleComponent extends Page implements OnInit {
    public fonts: string;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    ngOnInit() {
        if (this.data['header_fonts']) {
            this.nova.getFont(this.data['header_fonts']).subscribe(fontData => {
                this.fonts = fontData.name;
            });
        }
    }

    getTitleStyle() {
        let arr = '';

        if (this.data['header_color']) {
            const c = new ColorFormatter(this.data['header_color']);
            arr += 'color: ' + c.rgb() + ';';
        }
        if (this.fonts !== '') {
            arr += 'font-family: ' + this.fonts + ';';
        }
        if (this.data['header_bgColor']) {
            const c = new ColorFormatter(this.data['header_bgColor']);
            arr += 'border-bottom-color: ' + c.rgba() + ';';
            if ($('body').width() <= 767) {
                arr += 'background-color: ' + c.rgba() + ';';
            }
        }

        return this.convertCSS(arr);
    }

    getLogoStyle() {
        let style = '';
        if (this.data['logo_background_color']) {
            const c = new ColorFormatter(this.data['logo_background_color']);
            if ($('body').width() <= 767) {
                style += 'background-color: ' + c.rgba() + ';';
            }
            style += 'border-top-color: ' + c.rgba() + ';';
        }
        return this.convertCSS(style);
    }

    getBorderTopColor() {
        let style = '';
        if (this.data['header_bgColor']) {
            const c = new ColorFormatter(this.data['header_bgColor']);
            style += 'border-top-color: ' + c.rgba() + ';';
        }
        return this.convertCSS(style);
    }

    convertCSS(css) {
        let input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {},
                attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    }

    setMyStyles(obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    }
}
