import * as tslib_1 from "tslib";
import { Location } from '@angular/common';
import { AfterViewInit, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Page } from 'app/pages/page';
import { NovaTemplate } from 'app/_decorators/template.decorator';
import { BlogService } from 'app/_services/blog.service';
import { MediaService } from 'app/_services/media.service';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { ActivatedRoute, Router } from '@angular/router';
var PageGebirgslandeplaetzeIndexComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageGebirgslandeplaetzeIndexComponent, _super);
    function PageGebirgslandeplaetzeIndexComponent(nova, uiEventHandler, meta, title, mediaService, location, blogService, router, route) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.title = title;
        _this.mediaService = mediaService;
        _this.location = location;
        _this.blogService = blogService;
        _this.router = router;
        _this.route = route;
        _this.glaciers = [];
        _this.isLoading = true;
        _this.searchValue = '';
        _this.filterSmallGlaciers = false;
        _this.filterMediumGlaciers = false;
        _this.filterLargeGlaciers = false;
        return _this;
    }
    PageGebirgslandeplaetzeIndexComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.glaciers = this.data.contents.sort(function (a, b) {
                    if (a.glacier_name > b.glacier_name)
                        return 1;
                    if (b.glacier_name > a.glacier_name)
                        return -1;
                    return 0;
                });
                this.isLoading = false;
                return [2 /*return*/];
            });
        });
    };
    PageGebirgslandeplaetzeIndexComponent.prototype.filter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.glaciers = this.data.contents
                    .filter(function (content) {
                    return content.glacier_name.toLowerCase().includes(_this.searchValue.toLowerCase()) ||
                        content.glacier_number.toLowerCase().includes(_this.searchValue.toLowerCase()) ||
                        content.canton.some(function (canton) { return canton.toLowerCase().includes(_this.searchValue.toLowerCase()); }) ||
                        (content.glacier_nickname &&
                            content.glacier_nickname.toLowerCase().includes(_this.searchValue.toLowerCase()));
                })
                    .filter(function (content) {
                    if (_this.filterSmallGlaciers && _this.filterMediumGlaciers && _this.filterLargeGlaciers) {
                        return content;
                    }
                    if (!_this.filterSmallGlaciers && !_this.filterMediumGlaciers && !_this.filterLargeGlaciers) {
                        return content;
                    }
                    return ((_this.filterSmallGlaciers && content.glacierHeight_index < 2000) ||
                        (_this.filterMediumGlaciers &&
                            content.glacierHeight_index >= 2000 &&
                            content.glacierHeight_index < 2700) ||
                        (_this.filterLargeGlaciers && content.glacierHeight_index >= 2700));
                });
                return [2 /*return*/];
            });
        });
    };
    PageGebirgslandeplaetzeIndexComponent.prototype.ngAfterViewInit = function () {
        this.data.contents.length > 0 && $('.next-page')[0].style.setProperty('opacity', '1', 'important');
    };
    PageGebirgslandeplaetzeIndexComponent.prototype.getIconColor = function (height) {
        if (height < 2000)
            return '#00F91A'; // green
        if (height >= 2000 && height < 2700)
            return '#FFFC04'; // yellow
        if (height >= 2700)
            return '#FF2600'; // red
        return 'black';
    };
    PageGebirgslandeplaetzeIndexComponent.prototype.downloadGebirgslandeplaetzeMap = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.mediaService.getMediaUrl(this.data.gebirgslandeplaetze_pdf, 'original').toPromise()];
                    case 1:
                        url = _a.sent();
                        window.open(url);
                        return [2 /*return*/];
                }
            });
        });
    };
    PageGebirgslandeplaetzeIndexComponent = tslib_1.__decorate([
        NovaTemplate('PageGebirgslandeplaetzeIndexComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            Meta,
            Title,
            MediaService,
            Location,
            BlogService,
            Router,
            ActivatedRoute])
    ], PageGebirgslandeplaetzeIndexComponent);
    return PageGebirgslandeplaetzeIndexComponent;
}(Page));
export { PageGebirgslandeplaetzeIndexComponent };
