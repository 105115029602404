/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nova-bg.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../nova-media/nova-media.component.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "../nova-media/nova-media.component";
import * as i5 from "../../_services/nova-api.service";
import * as i6 from "../../_services/media.service";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../../_services/uievent-handler.service";
import * as i9 from "./nova-bg.component";
var styles_NovaBgComponent = [i0.styles];
var RenderType_NovaBgComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NovaBgComponent, data: {} });
export { RenderType_NovaBgComponent as RenderType_NovaBgComponent };
export function View_NovaBgComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "nova-media", [], [[2, "nofullscreen", null]], null, null, i2.View_NovaMediaComponent_0, i2.RenderType_NovaMediaComponent)), i1.ɵdid(1, 278528, null, 0, i3.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵdid(2, 770048, null, 0, i4.NovaMediaComponent, [i5.NovaAPIService, i6.MediaService, i1.Renderer2, i7.DomSanitizer, i8.UIEventHandlerService], { bg: [0, "bg"], backgroundPosition: [1, "backgroundPosition"], version: [2, "version"], fallback: [3, "fallback"], mediaId: [4, "mediaId"], copyright: [5, "copyright"], copyrightPosition: [6, "copyrightPosition"], forceVideo: [7, "forceVideo"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.setMyStyles(_co.getStyle()); _ck(_v, 1, 0, currVal_1); var currVal_2 = true; var currVal_3 = _co.backgroundPosition; var currVal_4 = _co.version; var currVal_5 = _co.fallback; var currVal_6 = _co.mediaId; var currVal_7 = _co.copyright; var currVal_8 = _co.copyrightPosition; var currVal_9 = _co.forceVideo; _ck(_v, 2, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).noFullscreen; _ck(_v, 0, 0, currVal_0); }); }
export function View_NovaBgComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-bg", [], null, null, null, View_NovaBgComponent_0, RenderType_NovaBgComponent)), i1.ɵdid(1, 114688, null, 0, i9.NovaBgComponent, [i8.UIEventHandlerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NovaBgComponentNgFactory = i1.ɵccf("nova-bg", i9.NovaBgComponent, View_NovaBgComponent_Host_0, { data: "data", version: "version", fallback: "fallback", copyright: "copyright", forceVideo: "forceVideo", media: "media", bgColor: "bgColor", backgroundPosition: "backgroundPosition" }, {}, []);
export { NovaBgComponentNgFactory as NovaBgComponentNgFactory };
