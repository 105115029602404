import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { SharedHotelHelperService } from '../../hotel-detail-standard/shared-hotel-helper.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { NovaAPIService } from 'app/_services/nova-api.service';

@Component({
    selector: 'app-hotel',
    templateUrl: './hotel.component.html',
    encapsulation: ViewEncapsulation.None
})
export class HotelComponent implements OnInit {
    @Input() hotel;
    // tslint:disable-next-line:no-output-on-prefix
    @Output() onClick = new EventEmitter();

    thumbnail = {
        url: '',
        alt: ''
    };

    constructor(
        public hotelHelper: SharedHotelHelperService,
        public uiEventHandler: UIEventHandlerService,
        public nova: NovaAPIService
    ) {}

    goTo(id) {
        this.onClick.emit(id);
    }

    ngOnInit() {
        if (this.hotel.images.length === 0) return;
        const sortedImages = this.hotel.images.sort((a, b) => a.sortorder - b.sortorder);
        this.thumbnail.url = this.getImageProxyUrl(sortedImages[0].filename);
        this.thumbnail.alt = this.hotel.nameplain;
    }

    getImageProxyUrl(url) {
        const width = 600;
        const height = 400;
        const aspect = '1:1';
        const rt = 'auto';
        const size = `s:${width}:${height}:${aspect}`;

        return `${this.nova.getImgUrl()}/insecure/rt:${rt}/${size}/g:sm/plain/${url}`;
    }
}
