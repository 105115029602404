import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NovaAPIService } from '../../_services/nova-api.service';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import { Subscription } from 'rxjs';
import { MagazineService } from 'app/_services/magazine.service';
import { NovaTemplate } from '../../_decorators/template.decorator';

@Component({
    selector: 'nova-contact-popup',
    templateUrl: './nova-contact-popup.component.html',
    styleUrls: ['./nova-contact-popup.component.scss']
})
@NovaTemplate('NovaContactPopupComponent')
export class NovaContactPopupComponent implements OnInit, OnDestroy {
    public font;
    private sub: Subscription;
    private subFont: Subscription;
    public magazine;

    constructor(
        public nova: NovaAPIService,
        public mag: MagazineService,
        public uiEventHandler: UIEventHandlerService
    ) {}

    ngOnInit() {
        this.sub = this.mag.getMagazine().subscribe(data => {
            this.magazine = data;
            this.subFont = this.nova.getFont(data.navigation.font).subscribe(fontData => {
                this.font = fontData.name;
            });
        });
    }

    close() {
        this.uiEventHandler.showContactForm = false;
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }

        if (this.subFont) {
            this.subFont.unsubscribe();
        }
    }
}
