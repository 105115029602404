import { DomSanitizer } from '@angular/platform-browser';
import { Component, Input, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser/src/security/dom_sanitization_service';
import { FontService } from 'app/_services/font.service';

@Component({
    selector: 'nova-font-loader',
    templateUrl: './nova-font-loader.component.html'
})
export class NovaFontLoaderComponent implements OnInit {
    @Input() private fonts: string[];

    public url: SafeResourceUrl;

    constructor(public fontService: FontService, public sanitizer: DomSanitizer) {}

    ngOnInit() {
        if (this.fonts && this.fonts.length == 1 && this.fonts[0] === 'Arial') {
        } else if (this.fonts && this.fonts.length > 0) {
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.fontService.getFontUrl(this.fonts));
        }
    }
}
