/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../nova-nav-item/nova-nav-item.component.ngfactory";
import * as i2 from "../nova-nav-item/nova-nav-item.component";
import * as i3 from "../../_services/nova-api.service";
import * as i4 from "../../_services/magazine.service";
import * as i5 from "../../_services/uievent-handler.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/common";
import * as i8 from "./nova-title-bar.component";
import * as i9 from "../../_services/ssr.service";
import * as i10 from "@angular/router";
var styles_NovaTitleBarComponent = [];
var RenderType_NovaTitleBarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NovaTitleBarComponent, data: {} });
export { RenderType_NovaTitleBarComponent as RenderType_NovaTitleBarComponent };
function View_NovaTitleBarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "nova-nav-item", [["class", "archive-link"]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onMouseEnter($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onMouseLeave($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).onTouchStart($event.target) !== false);
        ad = (pd_2 && ad);
    } if (("touchend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1).onTouchEnd($event.target) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.openLandingpagePreview() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i1.View_NovaNavItemComponent_0, i1.RenderType_NovaNavItemComponent)), i0.ɵdid(1, 245760, null, 0, i2.NovaNavItemComponent, [i3.NovaAPIService, i4.MagazineService, i5.UIEventHandlerService, i6.DomSanitizer, i0.ElementRef, i0.Renderer2], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 0, "i", [["class", "icon-big-folder"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_NovaTitleBarComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "nova-nav-item", [["class", "contact-link"]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onMouseEnter($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onMouseLeave($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).onTouchStart($event.target) !== false);
        ad = (pd_2 && ad);
    } if (("touchend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1).onTouchEnd($event.target) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.openContactForm() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i1.View_NovaNavItemComponent_0, i1.RenderType_NovaNavItemComponent)), i0.ɵdid(1, 245760, null, 0, i2.NovaNavItemComponent, [i3.NovaAPIService, i4.MagazineService, i5.UIEventHandlerService, i6.DomSanitizer, i0.ElementRef, i0.Renderer2], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 0, "i", [["class", "icon-mail-o"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_NovaTitleBarComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "nova-nav-item", [["class", "title"]], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onMouseEnter($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onMouseLeave($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 3).onTouchStart($event.target) !== false);
        ad = (pd_2 && ad);
    } if (("touchend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 3).onTouchEnd($event.target) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_NovaNavItemComponent_0, i1.RenderType_NovaNavItemComponent)), i0.ɵdid(1, 278528, null, 0, i7.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(2, { "font-family": 0 }), i0.ɵdid(3, 245760, null, 0, i2.NovaNavItemComponent, [i3.NovaAPIService, i4.MagazineService, i5.UIEventHandlerService, i6.DomSanitizer, i0.ElementRef, i0.Renderer2], { hoverable: [0, "hoverable"] }, null), (_l()(), i0.ɵted(4, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.font); _ck(_v, 1, 0, currVal_0); var currVal_1 = false; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.issue.number; _ck(_v, 4, 0, currVal_2); }); }
function View_NovaTitleBarComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "nova-nav-item", [["class", "logo"]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onMouseEnter($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onMouseLeave($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).onTouchStart($event.target) !== false);
        ad = (pd_2 && ad);
    } if (("touchend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1).onTouchEnd($event.target) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.ui.goToFirst() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i1.View_NovaNavItemComponent_0, i1.RenderType_NovaNavItemComponent)), i0.ɵdid(1, 245760, null, 0, i2.NovaNavItemComponent, [i3.NovaAPIService, i4.MagazineService, i5.UIEventHandlerService, i6.DomSanitizer, i0.ElementRef, i0.Renderer2], { hoverable: [0, "hoverable"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 0, "img", [["alt", "Logo"]], [[8, "src", 4]], null, null, null, null))], function (_ck, _v) { var currVal_0 = false; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.ui.logo || "assets/img/novamag.svg"); _ck(_v, 2, 0, currVal_1); }); }
function View_NovaTitleBarComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "nova-nav-item", [["class", "title"]], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onMouseEnter($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onMouseLeave($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 3).onTouchStart($event.target) !== false);
        ad = (pd_2 && ad);
    } if (("touchend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 3).onTouchEnd($event.target) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_NovaNavItemComponent_0, i1.RenderType_NovaNavItemComponent)), i0.ɵdid(1, 278528, null, 0, i7.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(2, { "font-family": 0 }), i0.ɵdid(3, 245760, null, 0, i2.NovaNavItemComponent, [i3.NovaAPIService, i4.MagazineService, i5.UIEventHandlerService, i6.DomSanitizer, i0.ElementRef, i0.Renderer2], { hoverable: [0, "hoverable"] }, null), (_l()(), i0.ɵted(4, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.font); _ck(_v, 1, 0, currVal_0); var currVal_1 = false; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.issue.name; _ck(_v, 4, 0, currVal_2); }); }
export function View_NovaTitleBarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 20, "div", [["class", "title-bar"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i7.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { nobg: 0 }), i0.ɵdid(3, 278528, null, 0, i7.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(4, { "background-color": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NovaTitleBarComponent_1)), i0.ɵdid(6, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NovaTitleBarComponent_2)), i0.ɵdid(8, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NovaTitleBarComponent_3)), i0.ɵdid(10, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NovaTitleBarComponent_4)), i0.ɵdid(12, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NovaTitleBarComponent_5)), i0.ɵdid(14, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(15, 0, null, null, 2, "nova-nav-item", [["class", "mobile-menu-button"]], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i0.ɵnov(_v, 16).onMouseEnter($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i0.ɵnov(_v, 16).onMouseLeave($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 16).onTouchStart($event.target) !== false);
        ad = (pd_2 && ad);
    } if (("touchend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 16).onTouchEnd($event.target) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_NovaNavItemComponent_0, i1.RenderType_NovaNavItemComponent)), i0.ɵdid(16, 245760, null, 0, i2.NovaNavItemComponent, [i3.NovaAPIService, i4.MagazineService, i5.UIEventHandlerService, i6.DomSanitizer, i0.ElementRef, i0.Renderer2], null, null), (_l()(), i0.ɵeld(17, 0, null, 0, 0, "i", [["class", "fa fa-bars"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 2, "div", [["class", "after"]], null, null, null, null, null)), i0.ɵdid(19, 278528, null, 0, i7.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(20, { "border-top-color": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "title-bar"; var currVal_1 = _ck(_v, 2, 0, (_co.issue.labelHasBackground == "no")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 4, 0, _co.mobileBG()); _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.selectStartPage; _ck(_v, 6, 0, currVal_3); var currVal_4 = (_co.magData.contact_enabled && (_co.magData.contact_position == "top")); _ck(_v, 8, 0, currVal_4); var currVal_5 = ((_co.issue.showNumber == "yes") && _co.issue.number); _ck(_v, 10, 0, currVal_5); var currVal_6 = ((_co.issue.showLogo == "yes") || _co.ui.isMobile()); _ck(_v, 12, 0, currVal_6); var currVal_7 = ((_co.issue.showName == "yes") && _co.issue.name); _ck(_v, 14, 0, currVal_7); _ck(_v, 16, 0); var currVal_8 = _ck(_v, 20, 0, _co.getColor(_co.nav.backgroundColor)); _ck(_v, 19, 0, currVal_8); }, null); }
export function View_NovaTitleBarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "nova-title-bar", [], null, null, null, View_NovaTitleBarComponent_0, RenderType_NovaTitleBarComponent)), i0.ɵdid(1, 245760, null, 0, i8.NovaTitleBarComponent, [i3.NovaAPIService, i9.SSRService, i10.Router, i4.MagazineService, i5.UIEventHandlerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NovaTitleBarComponentNgFactory = i0.ɵccf("nova-title-bar", i8.NovaTitleBarComponent, View_NovaTitleBarComponent_Host_0, { issue: "issue" }, {}, []);
export { NovaTitleBarComponentNgFactory as NovaTitleBarComponentNgFactory };
