/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nova-fti-overlay.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "@angular/forms";
import * as i5 from "./nova-fti-overlay-pages/nova-fti-overlay-pages.component.ngfactory";
import * as i6 from "./nova-fti-overlay-pages/nova-fti-overlay-pages.component";
import * as i7 from "../../../_services/nova-api.service";
import * as i8 from "../../../_services/magazine.service";
import * as i9 from "../../../_services/uievent-handler.service";
import * as i10 from "@angular/router";
import * as i11 from "./nova-fti-overlay-archive/nova-fti-overlay-archive.component.ngfactory";
import * as i12 from "./nova-fti-overlay-archive/nova-fti-overlay-archive.component";
import * as i13 from "./nova-fti-overlay-imprint/nova-fti-overlay-imprint.component.ngfactory";
import * as i14 from "./nova-fti-overlay-imprint/nova-fti-overlay-imprint.component";
import * as i15 from "./nova-fti-overlay-privacy/nova-fti-overlay-privacy.component.ngfactory";
import * as i16 from "./nova-fti-overlay-privacy/nova-fti-overlay-privacy.component";
import * as i17 from "./nova-fti-overlay-contact/nova-fti-overlay-contact.component.ngfactory";
import * as i18 from "./nova-fti-overlay-contact/nova-fti-overlay-contact.component";
import * as i19 from "./nova-fti-overlay-social/nova-fti-overlay-social.component.ngfactory";
import * as i20 from "./nova-fti-overlay-social/nova-fti-overlay-social.component";
import * as i21 from "@angular/material/snack-bar";
import * as i22 from "./nova-fti-overlay.component";
import * as i23 from "../../../_services/media.service";
import * as i24 from "../../../_services/ssr.service";
var styles_NovaFtiOverlayComponent = [i0.styles];
var RenderType_NovaFtiOverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NovaFtiOverlayComponent, data: {} });
export { RenderType_NovaFtiOverlayComponent as RenderType_NovaFtiOverlayComponent };
function View_NovaFtiOverlayComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open("archive") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.active == "archive"); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("TRAVELWORLDS")); _ck(_v, 1, 0, currVal_1); }); }
function View_NovaFtiOverlayComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchLang(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit == _co.nova.lang); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_1); }); }
function View_NovaFtiOverlayComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "display: inline-block;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaFtiOverlayComponent_4)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languages; _ck(_v, 2, 0, currVal_0); }, null); }
function View_NovaFtiOverlayComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["search", 1]], null, 6, "input", [["class", "form-control search-input"], ["type", "text"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.searchField = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(3, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(5, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.searchField; _ck(_v, 3, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 6).transform("SEARCH")); var currVal_1 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 5).ngClassValid; var currVal_6 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_NovaFtiOverlayComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-fti-overlay-pages", [], null, [[null, "pageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        _co.pageChange.emit($event);
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_NovaFtiOverlayPagesComponent_0, i5.RenderType_NovaFtiOverlayPagesComponent)), i1.ɵdid(1, 49152, null, 0, i6.NovaFtiOverlayPagesComponent, [i7.NovaAPIService, i8.MagazineService, i9.UIEventHandlerService, i10.Router], { currentPage: [0, "currentPage"], font: [1, "font"], borderColor: [2, "borderColor"], searchString: [3, "searchString"], pages: [4, "pages"] }, { pageChange: "pageChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentPageIndex; var currVal_1 = _co.font; var currVal_2 = _co.borderColor; var currVal_3 = _co.searchField; var currVal_4 = _co.nova.pages; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_NovaFtiOverlayComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-fti-overlay-archive", [], null, [[null, "issueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("issueChange" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_NovaFtiOverlayArchiveComponent_0, i11.RenderType_NovaFtiOverlayArchiveComponent)), i1.ɵdid(1, 114688, null, 0, i12.NovaFtiOverlayArchiveComponent, [i7.NovaAPIService, i8.MagazineService, i10.Router, i9.UIEventHandlerService], { lang: [0, "lang"], font: [1, "font"], borderColor: [2, "borderColor"] }, { issueChange: "issueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nova.lang; var currVal_1 = _co.font; var currVal_2 = _co.borderColor; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_NovaFtiOverlayComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-fti-overlay-imprint", [], null, null, null, i13.View_NovaFtiOverlayImprintComponent_0, i13.RenderType_NovaFtiOverlayImprintComponent)), i1.ɵdid(1, 573440, null, 0, i14.NovaFtiOverlayImprintComponent, [i7.NovaAPIService, i8.MagazineService], { magazine: [0, "magazine"], issue: [1, "issue"], font: [2, "font"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.magazine; var currVal_1 = _co.issue; var currVal_2 = _co.font; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_NovaFtiOverlayComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-fti-overlay-privacy", [], null, null, null, i15.View_NovaFtiOverlayPrivacyComponent_0, i15.RenderType_NovaFtiOverlayPrivacyComponent)), i1.ɵdid(1, 573440, null, 0, i16.NovaFtiOverlayPrivacyComponent, [i7.NovaAPIService, i8.MagazineService], { magazine: [0, "magazine"], issue: [1, "issue"], font: [2, "font"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.magazine; var currVal_1 = _co.issue; var currVal_2 = _co.font; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_NovaFtiOverlayComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-fti-overlay-contact", [], null, null, null, i17.View_NovaFtiOverlayContactComponent_0, i17.RenderType_NovaFtiOverlayContactComponent)), i1.ɵdid(1, 573440, null, 0, i18.NovaFtiOverlayContactComponent, [i7.NovaAPIService, i8.MagazineService, i9.UIEventHandlerService], { magazine: [0, "magazine"], issue: [1, "issue"], font: [2, "font"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.magazine; var currVal_1 = _co.issue; var currVal_2 = _co.font; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_NovaFtiOverlayComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open("imprint", true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.active === "imprint"); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("IMPRINT")); _ck(_v, 1, 0, currVal_1); }); }
function View_NovaFtiOverlayComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["onclick", "window.UC_UI && window.UC_UI.showSecondLayer()"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("COOKIE_SETTINGS")); _ck(_v, 1, 0, currVal_0); }); }
function View_NovaFtiOverlayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 36, "div", [["class", "nova-fti-overlay"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 34, "div", [["class", "scrollbar-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 33, "div", [["class", "scroller"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["class", "top-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open("pages") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaFtiOverlayComponent_2)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "right-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaFtiOverlayComponent_3)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "search"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.toggleSearch() && $event.preventDefault()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "fa fa-search"]], [[2, "bold", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaFtiOverlayComponent_5)), i1.ɵdid(17, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaFtiOverlayComponent_6)), i1.ɵdid(20, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaFtiOverlayComponent_7)), i1.ɵdid(22, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaFtiOverlayComponent_8)), i1.ɵdid(24, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaFtiOverlayComponent_9)), i1.ɵdid(26, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaFtiOverlayComponent_10)), i1.ɵdid(28, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(29, 0, null, null, 7, "div", [["class", "nav-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaFtiOverlayComponent_11)), i1.ɵdid(31, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(32, 0, null, null, 2, "a", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open("privacy", true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(33, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaFtiOverlayComponent_12)), i1.ɵdid(36, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "nova-fti-overlay"; var currVal_1 = (_co.visible ? "visible" : ""); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = (_co.nova.issues && (_co.nova.issues.length > 1)); _ck(_v, 10, 0, currVal_4); var currVal_5 = (_co.languages.length > 1); _ck(_v, 13, 0, currVal_5); var currVal_7 = _co.showSearch; _ck(_v, 17, 0, currVal_7); var currVal_8 = (_co.active == "pages"); _ck(_v, 20, 0, currVal_8); var currVal_9 = (_co.active == "archive"); _ck(_v, 22, 0, currVal_9); var currVal_10 = (_co.active === "imprint"); _ck(_v, 24, 0, currVal_10); var currVal_11 = (_co.active == "privacy"); _ck(_v, 26, 0, currVal_11); var currVal_12 = (_co.active == "contact"); _ck(_v, 28, 0, currVal_12); var currVal_13 = (_co.magazine.imprintTitle || _co.magazine.imprintText); _ck(_v, 31, 0, currVal_13); var currVal_16 = ((_co.magazine && _co.magazine.cookieConsent) && (_co.magazine.cookieConsent.type === "usercentrics")); _ck(_v, 36, 0, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.active == "pages"); _ck(_v, 6, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("PAGES")); _ck(_v, 7, 0, currVal_3); var currVal_6 = _co.showSearch; _ck(_v, 15, 0, currVal_6); var currVal_14 = (_co.active === "privacy"); _ck(_v, 32, 0, currVal_14); var currVal_15 = i1.ɵunv(_v, 33, 0, i1.ɵnov(_v, 34).transform("PRIVACY_POLICY")); _ck(_v, 33, 0, currVal_15); }); }
function View_NovaFtiOverlayComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "share-menu"]], [[2, "hide-share", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "share-menu-icon"]], [[2, "open", null], [4, "backgroundColor", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.shareVisible = !_co.shareVisible) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", " icon-share"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "share-overlay"]], [[2, "visible", null]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "nova-fti-overlay-social", [], null, null, null, i19.View_NovaFtiOverlaySocialComponent_0, i19.RenderType_NovaFtiOverlaySocialComponent)), i1.ɵdid(5, 49152, null, 0, i20.NovaFtiOverlaySocialComponent, [i7.NovaAPIService, i10.Router, i21.MatSnackBar, i2.TranslateService], { font: [0, "font"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.font; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.overlayVisible; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.shareVisible; var currVal_2 = _co.uiEventHandler.getColor(_co.magazine.navigation.backgroundHoverColor); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _co.shareVisible; _ck(_v, 3, 0, currVal_3); }); }
export function View_NovaFtiOverlayComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { searchElement: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaFtiOverlayComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaFtiOverlayComponent_13)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoading; _ck(_v, 3, 0, currVal_0); var currVal_1 = (!_co.nova.isScreenShot && _co.showShare()); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_NovaFtiOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-fti-overlay", [], null, null, null, View_NovaFtiOverlayComponent_0, RenderType_NovaFtiOverlayComponent)), i1.ɵdid(1, 114688, null, 0, i22.NovaFtiOverlayComponent, [i7.NovaAPIService, i9.UIEventHandlerService, i2.TranslateService, i10.Router, i23.MediaService, i24.SSRService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NovaFtiOverlayComponentNgFactory = i1.ɵccf("nova-fti-overlay", i22.NovaFtiOverlayComponent, View_NovaFtiOverlayComponent_Host_0, { magazine: "magazine", issue: "issue", languages: "languages", overlayVisible: "overlayVisible", active: "active" }, { pageChange: "pageChange" }, []);
export { NovaFtiOverlayComponentNgFactory as NovaFtiOverlayComponentNgFactory };
