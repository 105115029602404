import * as tslib_1 from "tslib";
import { ElementRef, OnInit, QueryList, Renderer2 } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import { SharedHotelHelperService } from '../hotel-detail-standard/shared-hotel-helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
var PageHighlightsDerWocheComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageHighlightsDerWocheComponent, _super);
    function PageHighlightsDerWocheComponent(nova, uiEventHandler, sanitizer, meta, route, router, hotelHelper, media, title, renderer, platformId) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.sanitizer = sanitizer;
        _this.meta = meta;
        _this.route = route;
        _this.router = router;
        _this.hotelHelper = hotelHelper;
        _this.media = media;
        _this.title = title;
        _this.renderer = renderer;
        _this.platformId = platformId;
        _this.isBrowser = isPlatformBrowser(_this.platformId);
        return _this;
    }
    PageHighlightsDerWocheComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.data.top_hotels.forEach(function (content) {
            return _this.hotelHelper.handleTagInputs(content);
        });
    };
    PageHighlightsDerWocheComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        if (this.data['font']) {
            this.nova.getFont(this.data['font']).subscribe(function (fontData) {
                _this.renderer.setStyle(_this.fontSizeElement.nativeElement, 'font-family', fontData.name);
            });
        }
        if (this.data['font_bold']) {
            this.nova.getFont(this.data['font_bold']).subscribe(function (fontData) {
                _this.fontSizeBoldElements.toArray().map(function (element) {
                    _this.renderer.setStyle(element.nativeElement, 'font-family', fontData.name);
                });
            });
        }
    };
    PageHighlightsDerWocheComponent.prototype.scroll = function (el) {
        el.scrollIntoView({ behavior: 'smooth' });
    };
    PageHighlightsDerWocheComponent.prototype.getVersion = function () {
        if (this.uiEventHandler.isMobile()) {
            return 'vertical-wide';
        }
        else {
            return 'horizontal-wide';
        }
    };
    PageHighlightsDerWocheComponent.prototype.goToUrl = function (url) {
        if (this.isBrowser) {
            window.open(url);
        }
    };
    PageHighlightsDerWocheComponent.prototype.getWindowHeight = function () {
        if (this.isBrowser) {
            return window.innerHeight + 'px';
        }
    };
    PageHighlightsDerWocheComponent = tslib_1.__decorate([
        NovaTemplate('PageHighlightsDerWocheComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            DomSanitizer,
            Meta,
            ActivatedRoute,
            Router,
            SharedHotelHelperService,
            MediaService,
            Title,
            Renderer2,
            Object])
    ], PageHighlightsDerWocheComponent);
    return PageHighlightsDerWocheComponent;
}(Page));
export { PageHighlightsDerWocheComponent };
