import * as tslib_1 from "tslib";
import { ElementRef, OnInit, QueryList, Renderer2 } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import { SharedHotelHelperService } from '../hotel-detail-standard/shared-hotel-helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IndexRoundtripFilterPipe } from './filter-tags.pipe';
import { setRemainingFilters, setFilters } from '../index-trips/index-trips.component';
var PageIndexRoundtripComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageIndexRoundtripComponent, _super);
    function PageIndexRoundtripComponent(nova, uiEventHandler, sanitizer, meta, route, router, roundtripHelper, media, title, renderer, filterPipe) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.sanitizer = sanitizer;
        _this.meta = meta;
        _this.route = route;
        _this.router = router;
        _this.roundtripHelper = roundtripHelper;
        _this.media = media;
        _this.title = title;
        _this.renderer = renderer;
        _this.filterPipe = filterPipe;
        _this.filteredData = [];
        _this.allFilters = [];
        _this.allCountryFilters = [];
        _this.selectedFilters = [];
        _this.selectedInterests = [];
        _this.selectedStartingPoints = [];
        _this.interestsDropdownSettings = {
            singleSelection: false,
            text: 'Nach Reiseart filtern',
            enableCheckAll: false,
            showCheckbox: true,
            enableSearchFilter: false,
            classes: 'nova-multiselect'
        };
        _this.categoriesDropdownSettings = {
            singleSelection: false,
            text: 'Nach Interessen filtern',
            enableCheckAll: false,
            showCheckbox: true,
            enableSearchFilter: false,
            classes: 'nova-multiselect'
        };
        _this.startingPointsDropdownSettings = {
            singleSelection: false,
            text: 'Nach Startort filtern',
            enableCheckAll: false,
            showCheckbox: true,
            enableSearchFilter: false,
            classes: 'nova-multiselect'
        };
        _this.contentFontStyle = '';
        _this.allFilterStrings = [];
        _this.allCountryFilterStrings = [];
        _this.startingPointFilters = [];
        var path = window.location.pathname;
        var filter = sessionStorage.getItem('filter:' + path);
        if (filter) {
            var filterData = JSON.parse(filter);
            if (filterData.selectedFilters) {
                _this.selectedFilters = filterData.selectedFilters;
            }
            if (filterData.selectedInterests) {
                _this.selectedInterests = filterData.selectedInterests;
            }
            if (filterData.selectedStartingPoints) {
                _this.selectedStartingPoints = filterData.selectedStartingPoints;
            }
        }
        return _this;
    }
    PageIndexRoundtripComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.data.contents.forEach(function (content) {
            return _this.roundtripHelper.handleTagInputs(content, ['roundtrip_categories', 'interests', 'startingpoint']);
        });
        this.allFilters = setFilters(this.allFilterStrings, this.data.contents, 'roundtrip_categories');
        this.allCountryFilters = setFilters(this.allCountryFilterStrings, this.data.contents, 'interests');
        this.startingPointFilters = setFilters(this.startingPointFilters, this.data.contents.filter(function (item) { return item.startingpoint && item.startingpoint.every(function (i) { return Boolean(i); }); }), 'startingpoint');
        this.filter();
    };
    PageIndexRoundtripComponent.prototype.filter = function () {
        this.filteredData = this.filterPipe.transform(this.data.contents, this.selectedFilters, this.selectedInterests, this.selectedStartingPoints);
        // FTI-83 - dont show roundtrips without name
        this.filteredData = this.filteredData.filter(function (roundtrip) { return roundtrip.header !== undefined; });
        // setRemainingFilters(this.allFilters, this.filteredData, 'roundtrip_categories');
        setRemainingFilters(this.allCountryFilters, this.filteredData, 'interests');
        var path = window.location.pathname;
        sessionStorage.setItem('filter:' + path, JSON.stringify({
            selectedFilters: this.selectedFilters,
            selectedInterests: this.selectedInterests,
            selectedStartingPoints: this.selectedStartingPoints
        }));
    };
    PageIndexRoundtripComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.data['font']) {
            this.nova.getFont(this.data['font']).subscribe(function (fontData) {
                _this.renderer.setStyle(_this.fontSizeElement.nativeElement, 'font-family', fontData.name);
            });
        }
        if (this.data['filter_font'] && this.filterFontElement) {
            this.nova.getFont(this.data['filter_font']).subscribe(function (fontData) {
                _this.renderer.setStyle(_this.filterFontElement.nativeElement, 'font-family', fontData.name);
            });
        }
        if (this.data['roundtrip_detail_header_font']) {
            this.nova.getFont(this.data['roundtrip_detail_header_font']).subscribe(function (fontData) {
                _this.roundtripDetailHeaderElements &&
                    _this.roundtripDetailHeaderElements.toArray().map(function (element) {
                        _this.renderer.setStyle(element.nativeElement, 'font-family', fontData.name);
                    });
            });
        }
        if (this.data['roundtrip_detail_font']) {
            this.nova.getFont(this.data['roundtrip_detail_font']).subscribe(function (fontData) {
                _this.contentFontStyle = fontData.name;
            });
        }
    };
    PageIndexRoundtripComponent.prototype.goTo = function (id) {
        var floor = this.data.contents.filter(function (content) { return content._id === id; })[0];
        var params = this.route.params['value'];
        this.router.navigate(['/', params['langHandle'], params['issueHandle'], params['pageHandle'], floor.handle], {
            queryParamsHandling: 'preserve'
        });
    };
    PageIndexRoundtripComponent = tslib_1.__decorate([
        NovaTemplate('PageIndexRoundtripComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            DomSanitizer,
            Meta,
            ActivatedRoute,
            Router,
            SharedHotelHelperService,
            MediaService,
            Title,
            Renderer2,
            IndexRoundtripFilterPipe])
    ], PageIndexRoundtripComponent);
    return PageIndexRoundtripComponent;
}(Page));
export { PageIndexRoundtripComponent };
