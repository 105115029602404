<div id="page-{{ id }}">
	<nova-scrollbar-holder>
	    <div class="scroller">
		<!-- Header -->
		<div class="grt__pandora-fullsize-header">
			<div class="grt__pandora-fullsize-header-video">
				<nova-media [bg]="true" [mediaId]="data.headervideo" [forceVideo]="true"></nova-media>

				<div *ngIf="data.headerlogo" class="grt__pandora-fullsize-header-video-logo">
					<img *ngIf="data.headerlogo && isUrl(data.headerlogo)" [src]="getImageProxyUrl(data.headerlogo)">
					<nova-media *ngIf="data.headerlogo && !isUrl(data.headerlogo)" [mediaId]="data.headerlogo" version="small" [bg]="false" [fallback]="true"></nova-media>
				</div>

				<div class="scroll-down" (click)="scrollDown()"></div>
			</div>
		</div>

		<div class="grt__pandora-spacing"></div>

		<!-- Intro -->
		<div class="grt__pandora-container">
			<div class="grt__pandora-row justify-content-center">
				<div class="grt__pandora-col grt__pandora-single-col">
					<p><nova-text-element class="con" [data]="data.text" [myid]="data._id" ></nova-text-element></p>
				</div>
			</div>
		</div>

		<div class="grt__pandora-spacing"></div>


		<!-- Produkte -->
		<div class="grt__pandora-container">
			<div class="grt__pandora-katalog grt__pandora-row">

				<div *ngFor="let product of filterItemsOfType('t')" class="grt__pandora-katalog-item">
					<a href="{{ product.link }}" target="_blank">
					<div class="grt__pandora-katalog-item-img">
						<img *ngIf="product.media && isUrl(product.media)" [src]="getImageProxyUrl(product.media)">
						<nova-media *ngIf="product.media && !isUrl(product.media)" [mediaId]="product.media" version="small" [bg]="false" [fallback]="true"></nova-media>
					</div>
					<div class="grt__pandora-katalog-item-content">
						<div class="grt__pandora-katalog-item-tag">
							<nova-element [data]="product" name="tag"></nova-element>
						</div>
						<div class="grt__pandora-katalog-item-name">
							<nova-element [data]="product" name="name"></nova-element>
						</div>
						<div class="grt__pandora-katalog-item-price">
							<nova-element [data]="product" name="price"></nova-element>
						</div>
					</div>
					</a>
				</div>

			</div>
		</div>

		<div class="grt__pandora-spacing"></div>

		<!-- Trenner -->
		<div class="grt__pandora-container">
			<div class="grt__pandora-background-margin">
				<div class="grt__pandora-row justify-content-center">
					<div class="grt__pandora-col grt__pandora-single-col">
						<p><nova-text-element class="con" [data]="data.text2" [myid]="data._id" ></nova-text-element></p>
					</div>
				</div>
			</div>
		</div>

		<!-- Trenner -->
		<div class="grt__pandora-container grt__pandora-white">
			<div class="grt__pandora-katalog grt__pandora-row">

				<div *ngFor="let product of filterItemsOfType('b')" class="grt__pandora-katalog-item">
					<a href="{{ product.link }}" target="_blank">
					<div class="grt__pandora-katalog-item-img">
						<img *ngIf="product.media && isUrl(product.media)" [src]="getImageProxyUrl(product.media)">
						<nova-media *ngIf="product.media && !isUrl(product.media)" [mediaId]="product.media" version="small" [bg]="false" [fallback]="true"></nova-media>
					</div>
					<div class="grt__pandora-katalog-item-content">
						<div class="grt__pandora-katalog-item-tag">
							<nova-element [data]="product" name="tag"></nova-element>
						</div>
						<div class="grt__pandora-katalog-item-name">
							<nova-element [data]="product" name="name"></nova-element>
						</div>
						<div class="grt__pandora-katalog-item-price">
							<nova-element [data]="product" name="price"></nova-element>
						</div>
					</div>
					</a>
				</div>

			</div>
		</div>

		<div class="grt__pandora-spacing"></div>

		</div>
	</nova-scrollbar-holder>
</div>
