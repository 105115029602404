<div class="videoPopup page-video page-{{ id }}">
  <nova-bg [data]="data" [forceVideo]="true"></nova-bg>
  <nova-scrollbar-holder>
    <div class="scroller">
      <div class="center-box animated fadeIn center">
        <div class="hide-for-small-only">
          <nova-title [data]="data"></nova-title>
        </div>
        <div class="bg row">
          <div class="video col-md-7 right iframe" #iframe>
            <nova-media [bg]="false" [mediaId]="data['video']" [iframe]="true"></nova-media>
          </div>
          <div class="col-md-5 left" [ngStyle]="{'height': iframeHeight}">
            <div class="white scrollbar-holder" [ngStyle]="{'background-color':getColor(data['box_color'])}">
              <div class="scroller">
                <div class="show-for-small-only">
                  <nova-title [data]="data"></nova-title>
                </div>
                <nova-text-element class="con" [data]="data.text" (openGallery)="gallery.openSlider($event)"></nova-text-element>
                <nova-content-links class="content-links" *ngIf="data.links && data.links.length > 0" [floors]="data.links"></nova-content-links>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nova-scrollbar-holder>
  <nova-text-gallery [medias]="data.textImages" #gallery></nova-text-gallery>
</div>
