import { Component, Inject, OnInit, ViewEncapsulation, PLATFORM_ID } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { ActivatedRoute, Router } from '@angular/router';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Floor } from '../../floor';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { Meta, Title } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import { DOCUMENT } from '@angular/common';

@Component({
    templateUrl: './gebirgslandeplaetze-detail.component.html',
    styleUrls: ['./gebirgslandeplaetze-detail.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageGebirgslandeplaetzeDetailComponent')
export class PageGebirgslandeplaetzeDetailComponent extends Floor implements OnInit {
    public data: any;
    public id: number;
    public pageId: number;
    public url: string;
    public urlThumb: string;
    public params: any;

    constructor(
        public media: MediaService,
        route: ActivatedRoute,
        router: Router,
        uiEventHandler: UIEventHandlerService,
        nova: NovaAPIService,
        public meta: Meta,
        public title: Title,
        @Inject(DOCUMENT) private document,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    async ngOnInit() {
        this.uiEventHandler.showNavArrows = false;
        this.uiEventHandler.topClose = true;
        this.params = this.route.params['value'];
    }

    async downloadMap2() {
        const url = await this.media.getMediaUrl(this.data.map2, 'original').toPromise();
        window.open(url);
    }

    getSymbolClass(symbol) {
        switch (symbol) {
            case 'abwindN':
            case 'abwindNO':
            case 'abwindO':
            case 'abwindSO':
            case 'hindernisBahninstallationen':
            case 'sackgasseSO':
            case 'schattenSO':
                return symbol + '-' + this.params['langHandle'];

            default:
                return symbol;
        }
    }

    goToLegend() {
        const pageName = this.params['langHandle'] === 'en' ? 'symbols-and-terms' : 'symbole-und-begriffe';

        this.router.navigate(['/', this.params['langHandle'], this.params['issueHandle'], pageName], {
            queryParamsHandling: 'preserve'
        });
    }
}
