<div id="page-{{ id }}" class="page page-index-cover">
	<nova-bg [data]="data" [forceVideo]="true"></nova-bg>
	<div class="bg animated slideInLeft easeOutQuad scrollbar-holder"
		[ngStyle]="{ backgroundColor: getColor(data['box_color']) }">
		<div class="scroller">
			<div class="mobile-img show-for-small-only left">
				<nova-mobile-img [data]="data" class="show-for-small-only mobile-image"></nova-mobile-img>
				<div class="img-holder">
					<img *ngIf="data.logo && data.logo.url" [src]="data.logo.url" alt="Logo" />
				</div>
			</div>
			<div class="shift clear" [class.no-background-image]="!data.background_media">
				<nova-title [data]="data"></nova-title>
				<div class="item" *ngFor="let value of pages; let key = index" tabIndex="-1">
					<img (click)="goToPage(value.handle)" *ngIf="value.thumbnail && !nova.isThumbLoading(value.thumbnail)"
						[src]="value.thumbnail.url" />
					<img (click)="goToPage(value.handle)" *ngIf="!value.thumbnail || nova.isThumbLoading(value.thumbnail)"
						[src]="'assets/img/templates/pages/' + value.templateName + '.svg'" />
					<div class="right-con">
						<b (click)="goToPage(value.handle)" class="title"
							[style.font-family]="font"
							[style.color]="key == 0 ? getColor(data.firstHeaderColor) : getColor(data.headerColor)"
							[style.background-color]="key == 0 ? getColor(data.firstHeaderBgColor) : 'transparent'">{{ uiEventHandler.getContentTableEntryName(value) }}</b>
						<span (click)="goToPage(value.handle)" *ngIf="value.index_subtitle"
							[style.font-family]="font"
							[style.color]="getColor(data.subtitleColor)">{{ value.index_subtitle }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="logo-container" [ngStyle]="flexboxStyle">
		<img [ngStyle]="logoStyle" *ngIf="data.logo && data.logo.url" [src]="data.logo.url" alt="Logo" />
	</div>
	<nova-text-gallery [medias]="data.textImages" #gallery></nova-text-gallery>
</div>
