import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PageSingleBackgroundComponent } from '../single-background/single-background.component';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

//import 'owl.carousel/dist/owl.carousel'

@Component({
    templateUrl: './multiple-background.component.html',
    styleUrls: ['./multiple-background.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageMultipleBackgroundComponent')
export class PageMultipleBackgroundComponent extends PageSingleBackgroundComponent implements OnInit, AfterViewInit {
    public activeSlide = 0;
    public numSlides: number;

    private swipeCoord?: [number, number];
    private swipeTime?: number;

    @ViewChild('owl') public owl: ElementRef;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public title: Title,
        public router: Router,
        public route: ActivatedRoute
    ) {
        super(nova, uiEventHandler, sanitizer, meta, title, route, router);
    }

    ngOnInit() {
        this.numSlides = this.data.medias.length;
    }

    getId(id, key) {
        return id + '-' + key;
    }

    isActive($index) {
        // if (this.uiEventHandler.isMobile()) return true;
        return $index === this.activeSlide;
    }

    goDown() {
        this.activeSlide++;
        if (this.activeSlide >= this.numSlides - 1) {
            this.activeSlide = this.numSlides - 1;
        }
        this.nova.setMetaData(this.data.medias[this.activeSlide].header || this.data.name);
    }

    goUp() {
        this.activeSlide--;
        if (this.activeSlide <= 0) {
            this.activeSlide = 0;
        }
        this.nova.setMetaData(this.data.medias[this.activeSlide].header || this.data.name);
    }

    slideLeft() {
        this.activeSlide--;
        if (this.activeSlide <= 0) {
            this.activeSlide = 0;
        }
        // $(this.owl.nativeElement).trigger('prev.owl.carousel');
    }

    slideRight() {
        this.activeSlide++;
        if (this.activeSlide >= this.numSlides - 1) {
            this.activeSlide = this.numSlides - 1;
        }
        // $(this.owl.nativeElement).trigger('next.owl.carousel');
    }

    swipe(e: TouchEvent, when: string): void {
        const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
        const time = new Date().getTime();

        if (when === 'start') {
            this.swipeCoord = coord;
            this.swipeTime = time;
        } else if (when === 'end') {
            const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
            const duration = time - this.swipeTime;

            if (
                duration < 1000 && //
                Math.abs(direction[0]) > 30 && // Long enough
                Math.abs(direction[0]) > Math.abs(direction[1] * 3)
            ) {
                // Horizontal enough
                const swipe = direction[0] < 0 ? 'next' : 'previous';

                if (swipe === 'next') {
                    this.slideRight();
                }
                if (swipe === 'previous') {
                    this.slideLeft();
                }
            }
        }
    }

    ngAfterViewInit() {
        // if (this.uiEventHandler.isMobile() && typeof window !== 'undefined') {
        //     setTimeout(() => {
        //         $(this.owl.nativeElement).owlCarousel({
        //             items: 1,
        //             responsive: {
        //                 767: {
        //                     mouseDrag: false,
        //                     touchDrag: false
        //                 }
        //             }
        //         });
        //     }, 100);
        // }
        Array.from(document.getElementsByClassName('slide')).forEach(function(element, index) {
            const css = `.slide-${index} .img-copyright {z-index: ${10 + index};}`;
            const style = document.createElement('style');
            style.type = 'text/css';
            style.appendChild(document.createTextNode(css));
            element.appendChild(style);
        });
    }

    convertCSS(css) {
        let input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {},
                attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    }

    setMyStyles(obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    }
}
