import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import * as $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './cover-classic.component.html',
    styleUrls: ['./cover-classic.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageCoverClassicComponent')
export class PageCoverClassicComponent extends Page implements OnInit, OnDestroy {
    public titleStyle;
    public helperStyle;
    public flexboxStyle;
    public logoStyle;

    public logo_size;

    @ViewChild('logo') public logo: ElementRef;

    private resizeSub: Subscription;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
        this.resizeSub = this.uiEventHandler.resizeEvent$.subscribe(this.onResize.bind(this));
    }

    ngOnDestroy() {
        this.resizeSub.unsubscribe();
    }

    ngOnInit() {
        let titleStyle = '';
        let helperStyle = '';
        let flexboxStyle = '';
        let logoStyle = '';

        const posLogo = this.data['logoPosition'];
        const posTitle = this.data['contentPosition'];
        const posLogoArr = posLogo.split('-');
        const posTitleArr = posTitle.split('-');

        if (posTitleArr[1] === 'right') {
            titleStyle += 'text-align: right;';
        } else if (posTitleArr[1] === 'center') {
            titleStyle += 'text-align: center;';
        }

        // move boxes next to each other
        if (posLogoArr[0] === posTitleArr[0] && posLogoArr[1] !== posTitleArr[1]) {
            flexboxStyle += 'flex-direction: row;';
            if (posTitleArr[0] === 'center') {
                titleStyle += 'align-self: center;';
                logoStyle += 'align-self: center;';
            } else if (posTitleArr[0] === 'bottom') {
                titleStyle += 'align-self: flex-end;';
                logoStyle += 'align-self: flex-end;';
            } else {
                titleStyle += 'align-self: flex-start;';
                logoStyle += 'align-self: flex-start;';
            }

            if (posLogoArr[1] === 'right' || (posLogoArr[1] === 'center' && posTitleArr[1] === 'left')) {
                logoStyle += 'order: 2;';
                titleStyle += 'margin-right: 20px;';
                if (posLogoArr[1] === 'right') {
                    logoStyle += 'text-align: right;';
                }
            } else {
                titleStyle += 'margin-left: 20px;';
            }
            if (posLogoArr[1] === 'left' && posTitleArr[1] === 'right') {
                flexboxStyle += 'justify-content: space-between;';
                helperStyle += 'display: none;';
            }
        } else {
            if (posLogoArr[1] === 'left') {
                logoStyle += 'align-self: flex-start;text-align: left;';
            } else if (posLogoArr[1] === 'right') {
                logoStyle += 'align-self: flex-end;text-align: right;';
            } else if (posLogoArr[1] === 'center') {
                logoStyle += 'align-self: center;text-align: center;';
            }

            if (posTitleArr[1] === 'center') {
                titleStyle += 'text-align: center;';
            } else if (posTitleArr[1] === 'left') {
                titleStyle += 'align-self: flex-start;';
            } else if (posTitleArr[1] === 'right') {
                titleStyle += 'align-self: flex-end;';
            }

            if (posLogoArr[0] === 'center' && posTitleArr[0] === 'center') {
                logoStyle += 'margin-bottom: 20px;';
            }

            // move logo to top
            if (posLogoArr[0] === 'top') {
                if (posTitleArr[0] !== 'top') {
                    logoStyle += 'flex-grow: 1;';
                    if (posTitleArr[0] === 'center') {
                        titleStyle += 'flex-grow: 1;';
                    }
                } else {
                    helperStyle += 'order: 2;';
                    helperStyle += 'flex-grow: 1;';
                    logoStyle += 'margin-bottom: 20px;';
                }
            }
            if (posTitleArr[0] === 'top') {
                if (posLogo !== 'top-left' && posLogo !== 'top-center' && posLogo !== 'top-right') {
                    titleStyle += 'flex-grow: 1;';
                    // titleStyle += 'order: 0;';
                    if (posLogo === 'center-center' || posLogo === 'center-right' || posLogo === 'center-left') {
                        logoStyle += 'flex-grow: 1;';
                    }
                }
            }

            // move logo to bottom
            if (posLogo === 'bottom-left' || posLogo === 'bottom-center' || posLogo === 'bottom-right') {
                if (posTitle !== 'bottom-left' && posTitle !== 'bottom-center' && posTitle !== 'bottom-right') {
                    logoStyle += 'order: 2;';
                } else {
                    helperStyle += 'flex-grow: 1;';
                    logoStyle += 'margin-bottom: 20px;';
                }
            }
            // move title to top
            if (posTitle === 'top-left' || posTitle === 'top-center' || posTitle === 'top-right') {
                if (posLogo !== 'top-left' && posLogo !== 'top-center' && posLogo !== 'top-right') {
                    titleStyle += 'order: 0;';
                    logoStyle += 'order: 1;';
                }
            }

            if (posLogo === 'bottom-left' || posLogo === 'bottom-right' || posLogo === 'bottom-center') {
                if (posTitle === 'center-center' || posTitle === 'center-right' || posTitle === 'center-left') {
                    helperStyle += 'flex-grow: 1;';
                    titleStyle += 'flex-grow: 1;';
                }
            }
            if (posTitle === 'bottom-left' || posTitle === 'bottom-right' || posTitle === 'bottom-center') {
                if (posLogo === 'center-center' || posLogo === 'center-right' || posLogo === 'center-left') {
                    helperStyle += 'flex-grow: 1;';
                    logoStyle += 'flex-grow: 1;';
                }
            }
        }

        this.titleStyle = this.convertCSS(titleStyle);
        this.helperStyle = this.convertCSS(helperStyle);
        this.flexboxStyle = this.convertCSS(flexboxStyle);
        this.logoStyle = this.convertCSS(logoStyle);

        setTimeout(() => {
            this.calcMaxSize();
        }, 100);
    }

    calcMaxSize() {
        let logo_size = '';
        if (this.data['logo'] && this.data['logo'].url) {
            let divW = $(this.logo.nativeElement).width();
            const logo = $(this.logo.nativeElement).find('img')[0];
            if (logo['naturalHeight']) {
                logo_size += 'max-height: ' + (divW * logo['naturalHeight']) / logo['naturalWidth'] + 'px;';
            } else {
                $(logo).on('load', () => {
                    divW = $(this.logo.nativeElement).width();
                    logo_size += 'max-height: ' + (divW * logo['naturalHeight']) / logo['naturalWidth'] + 'px;';
                });
            }
        }
        if (this.data['logoSize']) {
            logo_size += 'height: calc(50px + ' + this.data['logoSize'] / 2 + 'vw);';
        }

        this.logo_size = this.convertCSS(logo_size);
    }

    onResize(e) {
        this.calcMaxSize();
    }

    convertCSS(css) {
        const input = css.toString();
        if (input.indexOf(':') > -1) {
            const result = {};
            const attributes = input.split(';');
            for (let i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    const entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    }

    setMyStyles(obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    }
}
