/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nova-fti-overlay-pages.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../pages-filter.pipe";
import * as i5 from "./nova-fti-overlay-pages.component";
import * as i6 from "../../../../_services/nova-api.service";
import * as i7 from "../../../../_services/magazine.service";
import * as i8 from "../../../../_services/uievent-handler.service";
var styles_NovaFtiOverlayPagesComponent = [i0.styles];
var RenderType_NovaFtiOverlayPagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NovaFtiOverlayPagesComponent, data: {} });
export { RenderType_NovaFtiOverlayPagesComponent as RenderType_NovaFtiOverlayPagesComponent };
function View_NovaFtiOverlayPagesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "a", [["class", "item"], ["queryParamsHandling", "preserve"], ["tabIndex", "-1"]], [[8, "hidden", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.pageChange.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { queryParamsHandling: [0, "queryParamsHandling"], routerLink: [1, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "image-container"]], [[2, "active", null], [4, "background-image", null]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "span", [], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i3.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(6, { "fontFamily": 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "b", [], [[4, "background-color", null]], null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵted(9, null, [" ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "preserve"; var currVal_4 = _co.makeUrl(_v.context.$implicit); _ck(_v, 1, 0, currVal_3, currVal_4); var currVal_7 = _ck(_v, 6, 0, _co.font); _ck(_v, 5, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.showInIndex == "no"); var currVal_1 = i1.ɵnov(_v, 1).target; var currVal_2 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = (_co.currentPage == _v.context.index); var currVal_6 = _co.getThumbnail(_v.context.$implicit); _ck(_v, 2, 0, currVal_5, currVal_6); var currVal_8 = _co.isActive((_co.currentPage == _v.context.index)); _ck(_v, 7, 0, currVal_8); var currVal_9 = _co.pad((_v.context.index + 1), 2); _ck(_v, 8, 0, currVal_9); var currVal_10 = _co.uiEventHandler.getContentTableEntryName(_v.context.$implicit); _ck(_v, 9, 0, currVal_10); }); }
export function View_NovaFtiOverlayPagesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.PagesFilterPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NovaFtiOverlayPagesComponent_1)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵppd(4, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), _co.pages, _co.searchString)); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_NovaFtiOverlayPagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-fti-overlay-pages", [], null, null, null, View_NovaFtiOverlayPagesComponent_0, RenderType_NovaFtiOverlayPagesComponent)), i1.ɵdid(1, 49152, null, 0, i5.NovaFtiOverlayPagesComponent, [i6.NovaAPIService, i7.MagazineService, i8.UIEventHandlerService, i2.Router], null, null)], null, null); }
var NovaFtiOverlayPagesComponentNgFactory = i1.ɵccf("nova-fti-overlay-pages", i5.NovaFtiOverlayPagesComponent, View_NovaFtiOverlayPagesComponent_Host_0, { currentPage: "currentPage", font: "font", borderColor: "borderColor", searchString: "searchString", pages: "pages" }, { pageChange: "pageChange" }, []);
export { NovaFtiOverlayPagesComponentNgFactory as NovaFtiOverlayPagesComponentNgFactory };
