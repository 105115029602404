/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cover-classic-logo.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../_components/nova-bg/nova-bg.component.ngfactory";
import * as i4 from "../../../_components/nova-bg/nova-bg.component";
import * as i5 from "../../../_services/uievent-handler.service";
import * as i6 from "./cover-classic-logo.component";
import * as i7 from "../../../_services/nova-api.service";
import * as i8 from "@angular/platform-browser";
import * as i9 from "@angular/router";
var styles_PageCoverClassicLogoComponent = [i0.styles];
var RenderType_PageCoverClassicLogoComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PageCoverClassicLogoComponent, data: {} });
export { RenderType_PageCoverClassicLogoComponent as RenderType_PageCoverClassicLogoComponent };
function View_PageCoverClassicLogoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "Logo"]], [[8, "src", 4]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.setMyStyles(_co.logo_size); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.data.logo.url; _ck(_v, 2, 0, currVal_1); }); }
export function View_PageCoverClassicLogoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { logo: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "page page-cover-logo"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "nova-bg", [], null, null, null, i3.View_NovaBgComponent_0, i3.RenderType_NovaBgComponent)), i1.ɵdid(3, 114688, null, 0, i4.NovaBgComponent, [i5.UIEventHandlerService], { data: [0, "data"], forceVideo: [1, "forceVideo"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "flexbox"]], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(6, 0, [[1, 0], ["logo", 1]], null, 3, "div", [["class", "flex-item logo"]], null, null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageCoverClassicLogoComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.data; var currVal_2 = true; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = _co.setMyStyles(_co.flexboxStyle); _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.setMyStyles(_co.logoStyle); _ck(_v, 7, 0, currVal_4); var currVal_5 = (_co.data.logo && _co.data.logo.url); _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "page-", _co.id, ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_PageCoverClassicLogoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_PageCoverClassicLogoComponent_0, RenderType_PageCoverClassicLogoComponent)), i1.ɵdid(1, 245760, null, 0, i6.PageCoverClassicLogoComponent, [i7.NovaAPIService, i5.UIEventHandlerService, i8.DomSanitizer, i8.Meta, i8.Title, i9.ActivatedRoute, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageCoverClassicLogoComponentNgFactory = i1.ɵccf("ng-component", i6.PageCoverClassicLogoComponent, View_PageCoverClassicLogoComponent_Host_0, {}, {}, []);
export { PageCoverClassicLogoComponentNgFactory as PageCoverClassicLogoComponentNgFactory };
