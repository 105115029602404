import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { Subscription } from 'rxjs';
import { MagazineService } from 'app/_services/magazine.service';
import { environment } from '../../../environments/environment';
@Component({
    selector: 'nova-ci-logo',
    styleUrls: ['./nova-ci-logo.component.scss'],
    template:
        '<a [href]="getBrandingLink()" target="_blank" class="branding" *ngIf="isVisible"><img src="assets/img/novamag.svg" /></a>  ',
    encapsulation: ViewEncapsulation.None
})
export class NovaCILogoComponent implements OnInit, OnDestroy {
    private sub: Subscription;
    public isVisible = false;

    constructor(
        public nova: NovaAPIService,
        public mag: MagazineService,
        public uiEventHandler: UIEventHandlerService,
        public router: Router
    ) {}

    ngOnInit() {
        this.sub = this.mag.getMagazine().subscribe(data => {
            this.isVisible = !this.nova.isScreenShot && (data && data.branding);
        });
    }

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe();
    }

    getBrandingLink() {
        let url = environment['utmSource'];

        url += `/?utm_source=${(this.uiEventHandler.isMobile() && 'branding-mobile') || 'branding-desktop'}`;
        url += `${typeof window !== 'undefined' && `&utm_medium=${encodeURIComponent(window.location.href)}`}`;

        return url;
    }
}
