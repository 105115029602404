import { OnInit, EventEmitter } from '@angular/core';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { TranslateService } from '@ngx-translate/core';
import { MediaService } from 'app/_services/media.service';
import { Router, NavigationEnd } from '@angular/router';
import { take } from 'rxjs/operators';
var NovaBudapestOverlayComponent = /** @class */ (function () {
    function NovaBudapestOverlayComponent(nova, uiEventHandler, translate, router, media) {
        this.nova = nova;
        this.uiEventHandler = uiEventHandler;
        this.translate = translate;
        this.router = router;
        this.media = media;
        this.visible = false;
        this.active = 'pages';
        this.toggleOverlay = new EventEmitter();
        this.pageChange = new EventEmitter();
    }
    NovaBudapestOverlayComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                _this.getCurrentPage();
            }
        });
        this.nova.getFont(this.magazine.navigation.font).subscribe(function (fontData) {
            _this.font = fontData.name;
        });
        this.selectStartPage = this.magazine.selectStartPage === 'archiv';
        this.nova
            .getCurrentIssue()
            .pipe(take(1))
            .subscribe(function (issue) {
            if (_this.issue === undefined) {
                _this.issue = issue;
            }
            _this.getCurrentPage();
        });
    };
    NovaBudapestOverlayComponent.prototype.getCurrentPage = function () {
        var _this = this;
        var params = this.router.routerState.root.firstChild.params['value'];
        var pageHandle = params.pageHandle;
        if (pageHandle) {
            this.nova.pages.forEach(function (page, index) {
                if (page.handle === pageHandle) {
                    _this.currentPageIndex = index;
                    _this.currentPage = page;
                }
            });
        }
    };
    Object.defineProperty(NovaBudapestOverlayComponent.prototype, "borderColor", {
        get: function () {
            return this.uiEventHandler.getColor(this.magazine.navigation.borderColor);
        },
        enumerable: true,
        configurable: true
    });
    NovaBudapestOverlayComponent.prototype.hide = function () {
        this.toggleOverlay.emit();
    };
    NovaBudapestOverlayComponent.prototype.open = function (page) {
        this.active = page;
    };
    NovaBudapestOverlayComponent.prototype.showPDF = function () {
        return this.currentPage && !!this.currentPage['pdf_version'];
    };
    NovaBudapestOverlayComponent.prototype.showShare = function () {
        return this.nova.issue && this.nova.issue.showSocial === 'yes';
    };
    NovaBudapestOverlayComponent.prototype.showPrint = function () {
        return !this.showPDF() && this.issue.showPrint === 'yes';
    };
    NovaBudapestOverlayComponent.prototype.print = function () {
        this.nova.print();
    };
    NovaBudapestOverlayComponent.prototype.switchLang = function (lang) {
        var _this = this;
        var params = this.router.routerState.root.firstChild.params['value'];
        var oldLang = this.nova.issue.language;
        if (oldLang === lang)
            return;
        this.nova.lang = lang;
        this.translate.use(lang).subscribe({
            error: function () { return _this.translate.use('en'); } // Fallback to english if translate loader fails
        });
        this.nova.mapIssue();
        this.translate.get('CONTENT_TABLE').subscribe(function (res) {
            _this.uiEventHandler.CONTENT_TABLE = res;
        });
        var newDomain;
        // check if a domain for this language exists
        if (this.magazine.domains) {
            var currentDomain = this.nova.getMagazineUrl();
            if (!currentDomain.includes(this.nova.getAliasUrl()) && !window.location.origin.includes('localhost')) {
                var getDomainShouldBe = function (lang, type) {
                    return _this.magazine.domains.find(function (domain) { return domain.language === lang && domain.type === type; });
                };
                var domainShouldBe = getDomainShouldBe(lang, 'external') ||
                    getDomainShouldBe('all', 'external') ||
                    getDomainShouldBe(lang, 'internal') ||
                    getDomainShouldBe('all', 'internal');
                if (domainShouldBe && domainShouldBe.domain !== currentDomain) {
                    newDomain = domainShouldBe.domain;
                }
            }
        }
        if (params['issueHandle']) {
            var issueHandle_1;
            this.nova.issues.forEach(function (value) {
                if (!issueHandle_1 &&
                    value['languagesMap'][oldLang] &&
                    value['languagesMap'][oldLang].handle === params['issueHandle']) {
                    if (value['languagesMap'][lang]) {
                        issueHandle_1 = value['languagesMap'][lang].handle;
                    }
                }
            });
            if (!issueHandle_1) {
                issueHandle_1 = params['issueHandle'];
            }
            if (!newDomain) {
                window.location.href = window.location.origin + "/" + lang + "/" + issueHandle_1 + window.location.search;
            }
            else {
                window.location.href = "//" + newDomain + "/" + lang + "/" + issueHandle_1 + window.location.search;
            }
        }
        else {
            if (!newDomain) {
                this.router.navigate(['/', lang], { queryParamsHandling: 'preserve' });
            }
            else {
                window.location.href = "//" + newDomain + "/" + lang + window.location.search;
            }
        }
        setTimeout(function () {
            _this.hide();
        }, 0);
    };
    return NovaBudapestOverlayComponent;
}());
export { NovaBudapestOverlayComponent };
