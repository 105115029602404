/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nova-impressum.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../nova-nav-item/nova-nav-item.component.ngfactory";
import * as i4 from "../nova-nav-item/nova-nav-item.component";
import * as i5 from "../../_services/nova-api.service";
import * as i6 from "../../_services/magazine.service";
import * as i7 from "../../_services/uievent-handler.service";
import * as i8 from "@angular/platform-browser";
import * as i9 from "./nova-impressum.component";
import * as i10 from "@angular/router";
var styles_NovaImpressumComponent = [i0.styles];
var RenderType_NovaImpressumComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NovaImpressumComponent, data: {} });
export { RenderType_NovaImpressumComponent as RenderType_NovaImpressumComponent };
function View_NovaImpressumComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h2", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "fontFamily": 0 }), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.font); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.imprintTitle; _ck(_v, 3, 0, currVal_1); }); }
function View_NovaImpressumComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imprintText; _ck(_v, 0, 0, currVal_0); }); }
export function View_NovaImpressumComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { textElm: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["id", "impressum"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "nova-nav-item", [["class", "icon-cancel my-close-icon"]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onMouseEnter($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onMouseLeave($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4).onTouchStart($event.target) !== false);
        ad = (pd_2 && ad);
    } if (("touchend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4).onTouchEnd($event.target) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.hide() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i3.View_NovaNavItemComponent_0, i3.RenderType_NovaNavItemComponent)), i1.ɵdid(4, 245760, null, 0, i4.NovaNavItemComponent, [i5.NovaAPIService, i6.MagazineService, i7.UIEventHandlerService, i8.DomSanitizer, i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaImpressumComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NovaImpressumComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_1 = _co.imprintTitle; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.imprintText; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.visible; _ck(_v, 1, 0, currVal_0); }); }
export function View_NovaImpressumComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-impressum", [], null, null, null, View_NovaImpressumComponent_0, RenderType_NovaImpressumComponent)), i1.ɵdid(1, 245760, null, 0, i9.NovaImpressumComponent, [i5.NovaAPIService, i6.MagazineService, i8.DomSanitizer, i10.Router, i7.UIEventHandlerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NovaImpressumComponentNgFactory = i1.ɵccf("nova-impressum", i9.NovaImpressumComponent, View_NovaImpressumComponent_Host_0, {}, {}, []);
export { NovaImpressumComponentNgFactory as NovaImpressumComponentNgFactory };
