<div id="page-{{ id }}" class="page page-index-transfer"
[ngStyle]="{'backgroundColor':getColor(data['background_color'])}">
    <nova-bg [data]="data" [forceVideo]="true"></nova-bg>
    <nova-scrollbar-holder>
        <div class="scroller">
            <div class="show-for-medium-only">
                <nova-media [mediaId]="data.background_media" version="horizontal"></nova-media>
            </div>
            <div class="center clear">
                <div class="title-wrapper">
                    <nova-title [data]="data"></nova-title>
                </div>
                <div class="filter-bar" #filter_font>
                    <div class="filter-bar__results">
                        <span class="bold">{{(filteredData).length}}</span>
                        {{(filteredData).length == 1 ? 'Transfer' : 'Transfers'}} verfügbar
                    </div>
                    <div class="filter-bar__filters">
                        <div class="filter-bar__box">
                            <angular2-multiselect
                                [data]="allInterestFilters"
                                [(ngModel)]="selectedInterests"
                                (ngModelChange)="filter()"
                                [settings]="interestsDropdownSettings"
                            ></angular2-multiselect>
                        </div>
                        <div class="filter-bar__box">
                            <angular2-multiselect
                                [data]="allStartingPointsFilters"
                                [(ngModel)]="selectedStartingPoints"
                                (ngModelChange)="filter()"
                                [settings]="startingPointsDropdownSettings"
                            ></angular2-multiselect>
                        </div>
                        <div class="filter-bar__box">
                            <angular2-multiselect
                                [data]="allDestinationPointsFilters"
                                [(ngModel)]="selectedDestinationPoints"
                                (ngModelChange)="filter()"
                                [settings]="destinationPointsDropdownSettings"
                            ></angular2-multiselect>
                        </div>
                    </div>
                </div>

                <div class="product-list flex-container">
                    <div #transferDetailHeaderElements
                        class="flex-container transfer-item standard"
                        *ngFor="let transfer of filteredData"
                        [@fadeAnimation]="'in'"
                        [ngStyle]="{backgroundColor: getColor(transfer.background_color)}">

                        <app-transfer [transfer]="transfer" (handleClick)="goTo($event)" class="transfer-item-container" [contentFontStyle]="contentFontStyle"></app-transfer>
                    </div>
                </div>

                <div class="filter-bar filter-footer" *ngIf="data.text">
                    <nova-text-element [data]="data.text"></nova-text-element>
                </div>
            </div>
        </div>
        <nova-content-links style="display: none" [floors]="data.links"></nova-content-links>
        <nova-text-gallery [medias]="data.textImages" #gallery></nova-text-gallery>
        <nova-font-loader *ngIf="data.font" [fonts]="[data.font]"></nova-font-loader>
        <nova-font-loader *ngIf="data.font_bold" [fonts]="[data.font_bold]"></nova-font-loader>
    </nova-scrollbar-holder>
</div>
