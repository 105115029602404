import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    templateUrl: './fti-newsletter.component.html',
    styleUrls: ['./fti-newsletter.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageFtiNewsletterComponent')
export class PageFtiNewsletterComponent extends Page implements OnInit {
    public data: any;

    public formStatus: any;
    public showSuccess: boolean;
    public showMsg: boolean;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public router: Router,
        public route: ActivatedRoute
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
        this.route.queryParams.subscribe(params => {
            this.formStatus = params['result'];
        });
        this.showMsg = false;
    }

    ngOnInit() {
        if (this.formStatus) {
            setTimeout(() => {
                this.showMsg = true;
            }, 600);
        }
    }

    makeUrl(result) {
        const urlTree = this.router.createUrlTree([], {
            relativeTo: this.route,
            queryParams: {
                result
            },
            queryParamsHandling: 'merge'
        });

        return location.origin + urlTree.toString();
    }
}
