import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, ElementRef } from '@angular/core';
import { NovaTemplate } from 'app/_decorators/template.decorator';
import { Floor } from 'app/pages/floor';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, fromEvent } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { MagazineService } from 'app/_services/magazine.service';
var PageArticleClassicComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageArticleClassicComponent, _super);
    function PageArticleClassicComponent(nova, mag, uiEventHandler, route, router, meta, title) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.mag = mag;
        _this.meta = meta;
        _this.title = title;
        _this.sub = _this.mag.getMagazine().subscribe(function (data) {
            _this.spinnerColor = _this.getColor(data.navigation.textColor);
        });
        return _this;
    }
    PageArticleClassicComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.uiEventHandler.isMobile()) {
            this.uiEventHandler.column = 'single';
        }
        else {
            if (!this.uiEventHandler.columnChanged) {
                this.uiEventHandler.column = !(this.data['default_view'] === 'block') ? 'columns' : 'single';
            }
        }
        if (this.uiEventHandler.column !== 'single' && this.uiEventHandler.columnizeEnabled) {
            this.uiEventHandler.textLoading = true;
        }
        // IE9, Chrome, Safari, Opera
        fromEvent(this.textElm.nativeElement, 'mousewheel').subscribe(function (event) {
            _this.scrollHorizontally(event);
        });
        // Firefox
        fromEvent(this.textElm.nativeElement, 'DOMMouseScroll').subscribe(function (event) {
            _this.scrollHorizontally(event);
        });
    };
    PageArticleClassicComponent.prototype.scrollHorizontally = function (e) {
        if (this.uiEventHandler.column !== 'single') {
            var delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));
            this.textElm.nativeElement.scrollLeft -= delta * 40; // Multiplied by 40
            e.preventDefault();
        }
    };
    PageArticleClassicComponent.prototype.ngOnDestroy = function () {
        if (this.sub)
            this.sub.unsubscribe();
    };
    PageArticleClassicComponent.prototype.getFontSize = function () {
        return this.uiEventHandler.fontSize + 'em';
    };
    PageArticleClassicComponent = tslib_1.__decorate([
        NovaTemplate('PageArticleClassicComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            MagazineService,
            UIEventHandlerService,
            ActivatedRoute,
            Router,
            Meta,
            Title])
    ], PageArticleClassicComponent);
    return PageArticleClassicComponent;
}(Floor));
export { PageArticleClassicComponent };
