
<div [ngSwitch]="field.type" [formGroup]="form" class="form-field-container {{field.type}}" #ngForm="ngForm">
    <div *ngSwitchCase="'text'">
        <input class="form-field form-field__text" type="text" [formControlName]="field.id" [placeholder]="label" />
    </div>

    <div *ngSwitchCase="'email'">
        <input class="form-field form-field__text" type="email" [formControlName]="field.id" [placeholder]="label" />
    </div>

    <div *ngSwitchCase="'number'">
        <input class="form-field form-field__number" type="number" [formControlName]="field.id" [placeholder]="label" />
    </div>

    <div *ngSwitchCase="'tel'">
        <input class="form-field form-field__number" type="tel" [formControlName]="field.id" [placeholder]="label" />
    </div>

    <div *ngSwitchCase="'date'">
        <mat-form-field>
            <input matInput [matDatepicker]="picker" [min]="minDate" [placeholder]="label" [formControlName]="field.id"  (focus)="picker.open()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>

    <div *ngSwitchCase="'select'">
        <label [style.font-family]="fontBold">{{field.label}}</label>
        <select class="form-field form-field__select" [formControlName]="field.id">
            <option *ngFor="let opt of field.options">
                {{opt}}
            </option>
        </select>

        <div *ngIf="field.id === 'childpax'">
            <div *ngFor="let child of form.get('childage').controls; let i=index" formArrayName="childage" >
                <div [formGroupName]="i" class="subfield">
                    <label [style.font-family]="fontBold">{{field.subfield.label}}</label>
                    <select class="form-field form-field__select" formControlName="age">
                        <option *ngFor="let opt of field.subfield.options">
                            {{opt}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

    </div>

    <div *ngSwitchCase="'checkbox'">
        <label class="form-field form-field__checkbox checkbox">
            <input type="checkbox" name="checkbox" id="{{field.id}}" [formControlName]="field.id">
            <span></span>
            {{field.label}}
        </label>
    </div>

    <div *ngSwitchCase="'textarea'">
        <textarea class="form-field form-field__textarea" name="" id="" cols="30" rows="5" [placeholder]="label" [formControlName]="field.id"></textarea>
    </div>

    <div *ngIf="isValid" class="form-field__error">{{isValid}}</div>
</div>
