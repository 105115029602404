import { Injectable, Inject, Injector, ViewEncapsulation, RendererFactory2 } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { TransferState } from '@angular/platform-browser';

@Injectable()
export class SSRService {
    public isBrowser: boolean = isPlatformBrowser(this.platformId);
    private token = '';

    constructor(
        private state: TransferState,
        private injector: Injector,
        private rendererFactory: RendererFactory2,
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(DOCUMENT) private document
    ) {
        this.token = this.getParameterByName('preview');
    }

    public getState(key) {
        return this.state.get(key, null as any);
    }

    hasToken() {
        return this.getToken() && this.getToken() !== '';
    }

    public getToken(): string {
        return this.token;
    }

    public getParameterByName(name: string) {
        if (this.isBrowser) {
            const url = window.location.href;
            name = name.replace(/[\[\]]/g, '\\$&');
            const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' ')) || '';
        } else {
            const req = this.getRequest();
            return req.query[name] || '';
        }
    }

    public setState(key, value) {
        this.state.set(key, value as any);
    }

    public getRequest() {
        return this.injector.get('request');
    }

    public getDocument() {
        return this.document;
    }

    addTag(tag: LinkDefinition) {
        try {
            const head = this.document.head;
            const renderer = this.rendererFactory.createRenderer(head, {
                id: '-1',
                encapsulation: ViewEncapsulation.None,
                styles: [],
                data: {}
            });

            const link = renderer.createElement('link');

            if (head === null) {
                throw new Error('<head> not found within DOCUMENT.');
            }

            Object.keys(tag).forEach((prop: string) => {
                return renderer.setAttribute(link, prop, tag[prop]);
            });

            renderer.appendChild(head, link);
        } catch (e) {
            console.error('Error within linkService : ', e);
        }
    }

    public getRequestUrl() {
        const req = this.getRequest();
        return req.protocol + '://' + req.get('host') + req.originalUrl;
    }
}

export declare type LinkDefinition = {
    charset?: string;
    crossorigin?: string;
    href?: string;
    hreflang?: string;
    media?: string;
    rel?: string;
    rev?: string;
    sizes?: string;
    target?: string;
    type?: string;
} & {
    [prop: string]: string;
};
