<div
	class="title-bar"
	[ngClass]="{ nobg: issue.labelHasBackground == 'no' }"
	[ngStyle]="{ 'background-color': mobileBG() }"
>
	<nova-nav-item class="archive-link" (click)="openLandingpagePreview()" *ngIf="selectStartPage">
		<i class="icon-big-folder"></i>
	</nova-nav-item>
	<nova-nav-item
		*ngIf="magData.contact_enabled && magData.contact_position == 'top'"
		class="contact-link"
		(click)="openContactForm()"
	>
		<i class="icon-mail-o"></i>
	</nova-nav-item>
	<nova-nav-item
		[ngStyle]="{ 'font-family': font }"
		[hover]="false"
		class="title"
		*ngIf="issue.showNumber == 'yes' && issue.number"
		>{{ issue.number }}</nova-nav-item
	>
	<nova-nav-item
		[hover]="false"
		class="logo"
		*ngIf="issue.showLogo == 'yes' || ui.isMobile()"
		(click)="ui.goToFirst()"
	>
		<img [src]="ui.logo || 'assets/img/novamag.svg'" alt="Logo" />
	</nova-nav-item>
	<nova-nav-item
		[ngStyle]="{ 'font-family': font }"
		[hover]="false"
		class="title"
		*ngIf="issue.showName == 'yes' && issue.name"
		>{{ issue.name }}</nova-nav-item
	>
	<nova-nav-item class="mobile-menu-button"> <i class="fa fa-bars"></i> </nova-nav-item>
	<div [ngStyle]="{ 'border-top-color': getColor(nav.backgroundColor) }" class="after"></div>
</div>
