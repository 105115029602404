import * as tslib_1 from "tslib";
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Meta, Title } from '@angular/platform-browser';
import * as $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';
var PageSingleBackgroundComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageSingleBackgroundComponent, _super);
    function PageSingleBackgroundComponent(nova, uiEventHandler, sanitizer, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.sanitizer = sanitizer;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        return _this;
    }
    PageSingleBackgroundComponent.prototype.getVersion = function () {
        if (this.uiEventHandler.isMobile()) {
            return 'horizontal-wide';
        }
        else {
            return false;
        }
    };
    PageSingleBackgroundComponent.prototype.getScrollbarStyle = function (c) {
        if (this.uiEventHandler.isMobile()) {
            var height = $('body').height() - $('.mobile-nav').height();
            return this.convertCSS("\n        background-color: rgba(" + c.r + ", " + c.g + ", " + c.b + ", " + c.a + ");\n        height: " + height + "px;\n      ");
        }
        return '';
    };
    PageSingleBackgroundComponent.prototype.convertCSS = function (css) {
        var input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {}, attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    };
    PageSingleBackgroundComponent.prototype.setMyStyles = function (obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    };
    PageSingleBackgroundComponent = tslib_1.__decorate([
        NovaTemplate('PageSingleBackgroundComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            DomSanitizer,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], PageSingleBackgroundComponent);
    return PageSingleBackgroundComponent;
}(Page));
export { PageSingleBackgroundComponent };
