import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { PagePortraitClassicComponent } from '../portrait-classic/portrait-classic.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ColorFormatter } from '../../../novamag-api/Types';
import { Meta, Title } from '@angular/platform-browser';
import { NovaTextGalleryComponent } from 'app/_components/nova-text-gallery/nova-text-gallery.component';
import * as $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './portrait.component.html',
    styleUrls: ['./portrait.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PagePortraitComponent')
export class PagePortraitComponent extends PagePortraitClassicComponent implements OnInit, OnDestroy {
    public arrowStyle;
    public afterStyle;
    public isRevealed = false;
    public isAnimating = false;
    public isSliderOpen = false;

    @ViewChild('container') public container: ElementRef;
    @ViewChild('gallery') public gallery: NovaTextGalleryComponent;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public title: Title,
        public router: Router,
        public route: ActivatedRoute
    ) {
        super(nova, uiEventHandler, sanitizer, meta, title, route, router);
    }

    ngOnInit() {
        super.ngOnInit();

        let arrowStyle = '';
        let afterStyle = '';
        let c;

        if (this.data.arrowColor) {
            c = new ColorFormatter(this.data['arrowColor']);
            arrowStyle += 'color: ' + c.rgb() + ';';
        }

        c = new ColorFormatter({ r: 255, g: 255, b: 255, a: 255 });
        if (this.data['box_color']) {
            c = new ColorFormatter(this.data['box_color']);
        }

        c.c.a = 0;
        afterStyle = 'background: -webkit-linear-gradient(top, ' + c.rgba() + ' 0%, ';
        c.c.a = 1;
        afterStyle += c.rgba() + ' 85%);';
        c.c.a = 0;
        afterStyle += 'background: linear-gradient(to bottom, ' + c.rgba() + ' 0%, ';
        c.c.a = 1;
        afterStyle += c.rgba() + ' 85%);';

        this.arrowStyle = this.convertCSS(arrowStyle);
        this.afterStyle = this.convertCSS(afterStyle);

        setTimeout(() => {
            $(this.container.nativeElement).on('scroll', () => {
                this.scrollPage();
            });
        }, 2000);

        // refreshing the page...
        const pageScroll = this.scrollY();
        if (pageScroll) {
            this.isRevealed = true;
            $(this.container.nativeElement).addClass('notrans modify');
        }
    }

    scrollPage() {
        const scrollVal = this.scrollY();

        if ($(this.container.nativeElement).hasClass('notrans')) {
            $(this.container.nativeElement).removeClass('notrans');
            return false;
        }

        if (this.isAnimating) {
            return false;
        }

        if (scrollVal <= 0 && this.isRevealed) {
            this.toggle(false);
        } else if (scrollVal > 0 && !this.isRevealed) {
            this.toggle(true);
        }
    }

    close() {
        $(this.container.nativeElement).animate(
            {
                scrollTop: 0
            },
            500
        );
        this.toggle(false);
    }

    scrollY() {
        return $(this.container.nativeElement).scrollTop();
    }

    toggle(reveal) {
        this.isAnimating = true;
        const container = $(this.container);

        if (reveal) {
            this.isRevealed = true;
        } else {
            this.isRevealed = false;
        }

        setTimeout(() => {
            this.isAnimating = false;
        }, 600);
    }

    openSlider($event) {
        this.gallery.openSlider($event);
        this.isSliderOpen = true;
    }

    closeSlider() {
        // console.log('close');
        setTimeout(() => {
            this.isSliderOpen = false;
        }, 100);
    }

    ngOnDestroy() {
        $(this.container.nativeElement).off('scroll');
    }

    convertCSS(css) {
        let input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {},
                attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    }

    setMyStyles(obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    }
}
