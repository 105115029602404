<div class="modal-content__container" [style.font-family]="fontRegular">
    <h1 *ngIf="data.hotelmodal && data.hotelmodal.phheader">{{data.hotelmodal.phheader}}</h1>
    <p *ngIf="data.hotelmodal && data.hotelmodal.phdate">
        {{data.hotelmodal.phdate}}
    </p>

    <div class="date-wrapper">
        <nova-contactform-field [form]="moreInfoForm.formGroup" [field]="moreInfoForm.formFields.dateField">
        </nova-contactform-field>
    </div>

    <div *ngIf="moreInfoForm.isFetching">
        <span class="spinner"><i class="fas fa-spinner fa-spin"></i>&nbsp;{{data.hotelmodal.phfetch}}</span>
    </div>

    <div *ngIf="!moreInfoForm.isFetching && fetchedData[moreInfoForm.currentDate]; else noData">
        <nova-full-image-slider class="slider-big" *ngIf="ui.isMobile() || isSliderFullWidth"
            (click)="isSliderFullWidth = false" [fullWidth]="true"
            [apiImages]="fetchedData[moreInfoForm.currentDate].image" imageVersion="horizontal-wide" [currentIndex]="0">
        </nova-full-image-slider>

        <div class="modal-content__grid">
            <div ng-style="ui.isMobile() ? {'width' : '100%'} : {'width' : '50%'}">
                <h3 class="hotel-name">{{fetchedData[moreInfoForm.currentDate].name}}</h3>
                <div [innerHTML]="fetchedData[moreInfoForm.currentDate].fulltext"></div>
            </div>

            <div ng-style="ui.isMobile() ? {'width' : '100%'} : {'width' : '50%'}" class="sticky">

                <nova-full-image-slider *ngIf="!ui.isMobile()" [ngClass]="isSliderFullWidth ? 'hidden' : 'visible'"
                    style="width: 100%;height:unset;" (click)="isSliderFullWidth = true" [fullWidth]="true"
                    [apiImages]="fetchedData[moreInfoForm.currentDate].image" imageVersion="horizontal-wide"
                    [currentIndex]="0"></nova-full-image-slider>

                <a *ngIf="data.call_to_action_link" type="button" class="btn-link btn-primary"
                    [href]="data.call_to_action_link" target="_blank">
                    <nova-text-element *ngIf="data.cta" [data]="data.cta" class="btn-content"></nova-text-element>
                    <nova-element *ngIf="data.call_to_action_button && !data.cta" [data]="data"
                        name="call_to_action_button"></nova-element>
                </a>
            </div>
        </div>
    </div>
    <ng-template #noData>
        <div *ngIf="!moreInfoForm.isFetching && data.hotelmodal && data.hotelmodal.phnoresult">
            {{data.hotelmodal.phnoresult}}</div>
    </ng-template>
</div>