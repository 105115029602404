import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
var PageEasyScrollComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageEasyScrollComponent, _super);
    function PageEasyScrollComponent(nova, uiEventHandler, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        _this.customPhantomEvent = true;
        return _this;
    }
    PageEasyScrollComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (typeof window !== 'undefined') {
            setTimeout(function () {
                _this.uiEventHandler.onScreenShotEvent(_this.data.id);
                $('.page-easy-scroll .bg').removeClass('animated slideInLeft fadeScaleUp');
            }, 3000);
        }
        this.bgColor = this.data['box_color'];
        if (this.uiEventHandler.isMobile() && this.data['box_color'].a === 0) {
            this.bgColor = this.data['background_color'];
        }
    };
    PageEasyScrollComponent = tslib_1.__decorate([
        NovaTemplate('PageEasyScrollComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], PageEasyScrollComponent);
    return PageEasyScrollComponent;
}(Page));
export { PageEasyScrollComponent };
