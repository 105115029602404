<div id="page-{{ id }}" class="page page-fti-travelquiz">
  <nova-bg [data]="data" [forceVideo]="true"></nova-bg>
  <nova-scrollbar-holder>
    <div class="scroller">
      <nova-mobile-img [data]="data" class="show-for-small-only left"></nova-mobile-img>
      <div class="bg animated fadeInUp clear" [ngStyle]="{'background-color':getColor(data['box_color'])}"
        [hidden]="!data.text && !data.header && !data.subHeader && !data.label">
        <nova-title [class.noBg]="!data.background_media" [data]="data"></nova-title>
        <nova-text-element class="con" [data]="data.text" [myid]="data._id"
          (openGallery)="gallery.openSlider($event)"></nova-text-element>
        <div id="travelquiz">
          <a id="hc2-embed">Lädt …</a>
        </div>
        <nova-text-element class="con" [data]="data.text_bottom" [myid]="data._id"
        (openGallery)="gallery.openSlider($event)"></nova-text-element>
      </div>
    </div>
  </nova-scrollbar-holder>
  <nova-text-gallery #gallery [medias]="data.textImages" [myid]="data._id"></nova-text-gallery>
</div>
