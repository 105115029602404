import { Pipe, PipeTransform } from '@angular/core';
import { MultiSelectItem } from '../index-hotel/MultiSelectItem';

@Pipe({
    name: 'filterTravelworldModern'
})
export class FilterTravelworldModernPipe implements PipeTransform {
    transform(
        contents: any,
        selectedContinentFilters: MultiSelectItem[],
        selectedCountryFilters: MultiSelectItem[],
        selectedCategoryFilters: MultiSelectItem[]
    ): any {
        let continentFilterSelected = content => {
            return true;
        };

        if (selectedContinentFilters.length) {
            continentFilterSelected = content =>
                selectedContinentFilters
                    .map(item => item.id)
                    .some(
                        fil => (content.continent_labels && content.continent_labels.indexOf(fil) > -1 ? content : null)
                    );
        }

        let countryFilterSelected = content => {
            return true;
        };
        if (selectedCountryFilters.length) {
            countryFilterSelected = content =>
                selectedCountryFilters
                    .map(item => item.id)
                    .every(
                        fil => (content.country_labels && content.country_labels.indexOf(fil) > -1 ? content : null)
                    );
        }

        let categoryFilterSelected = content => {
            return true;
        };
        if (selectedCategoryFilters.length) {
            categoryFilterSelected = content =>
                selectedCategoryFilters
                    .map(item => item.id)
                    .every(fil => (content.category_labels.indexOf(fil) > -1 ? content : null));
        }

        return contents.filter(
            content =>
                continentFilterSelected(content) && countryFilterSelected(content) && categoryFilterSelected(content)
        );
    }
}
