import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import { Subscription } from 'rxjs';
import * as $ from 'jquery';

@Component({
    selector: 'nova-columnizer',
    template: '<div #elm><ng-content></ng-content></div>',
    encapsulation: ViewEncapsulation.None
})
export class NovaColumnizerComponent implements AfterViewInit, OnDestroy {
    private backup;
    private columnHeight;

    @ViewChild('elm') private elm;

    private fontSub: Subscription;
    private resizeSub: Subscription;

    ngOnDestroy() {
        this.fontSub.unsubscribe();
        this.resizeSub.unsubscribe();
    }

    constructor(private uiEventHandler: UIEventHandlerService) {
        this.fontSub = this.uiEventHandler.fontEvent$.subscribe(fontSize => {
            this.columnize();
        });
        this.resizeSub = this.uiEventHandler.resizeEvent$.subscribe(() => {
            this.columnize();
        });
    }

    columnize() {
        if (typeof window == 'undefined') {
            return;
        }

        if (!this.backup) {
            this.backup = this.elm.nativeElement.innerHTML;
        } else {
            this.elm.nativeElement.innerHTML = this.backup;
        }

        setTimeout(() => {
            let $elm = $(this.elm.nativeElement);

            if (this.uiEventHandler.column == 'single') {
                $elm.css({
                    '-webkit-column-width': 'initial',
                    '-moz-column-width': 'initial',
                    'column-width': 'initial',
                    width: 'initial'
                });
                setTimeout(() => {
                    this.uiEventHandler.textLoading = false;
                    this.uiEventHandler.onColumnizeEvent();
                });
            } else {
                this.calcColumns();
                if (!Modernizr.csscolumns) {
                    this.ie_columnizer($elm);
                } else {
                    this.html5_columnizer($elm);
                }
            }
        });
    }

    html5_columnizer($elm) {
        $elm.css({
            '-webkit-column-width': '280px',
            '-moz-column-width': '280px',
            'column-width': '280px'
        });
        setTimeout(() => {
            this.uiEventHandler.textLoading = false;
            this.uiEventHandler.onColumnizeEvent();
        });
    }

    ie_columnizer($elm) {
        $elm.columnize({
            width: 280,
            height: this.columnHeight,
            cssClassPrefix: 'columnizer',
            buildOnce: true
        });
        $elm.find('.columnizer-column:empty').remove();
        $elm.find('.columnizer-column > div[ng-transclude]:last-child:empty')
            .parent()
            .remove();
        $elm.css('width', $elm.find('.columnizer-column').length * 280 + 'px');
        setTimeout(() => {
            this.uiEventHandler.textLoading = false;
            this.uiEventHandler.onColumnizeEvent();
        });
    }

    calcColumns() {
        var article = $(this.elm.nativeElement);
        article
            .find(
                'table, thead, tbody, tfoot, colgroup, caption, label, legend, script, style, textarea, button, object, embed, tr, th, td, li, h1, h2, h3, h4, h5, h6, form, figure'
            )
            .addClass('dontsplit');
        article.find('h1, h2, h3, h4, h5, h6').addClass('dontend');
        article
            .find('br')
            .addClass('removeiflast')
            .addClass('removeiffirst');

        var windowHeight = $(window).height();

        //get padding of scrollcontainer
        var paddingOfScrollcontainer = article.parent().innerHeight() - article.parent().height();
        this.columnHeight = windowHeight - paddingOfScrollcontainer - 120;

        if (this.uiEventHandler.column != 'single') {
            article.height($(window).height() - 100);
        } else {
            article.css('height', 'auto');
        }
        article.css({
            'max-width': 'initial'
        });
    }

    ngAfterViewInit() {
        this.columnize();
    }
}
