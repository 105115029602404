/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./auth.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../_components/nova-media/nova-media.component.ngfactory";
import * as i3 from "../_components/nova-media/nova-media.component";
import * as i4 from "../_services/nova-api.service";
import * as i5 from "../_services/media.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../_services/uievent-handler.service";
import * as i8 from "../_components/paid-content-dialog/paid-content-dialog.component.ngfactory";
import * as i9 from "../_components/paid-content-dialog/paid-content-dialog.component";
import * as i10 from "../_helpers/auth.guard";
import * as i11 from "../_services/magazine.service";
import * as i12 from "../_services/ssr.service";
import * as i13 from "../_services/stripe.service";
import * as i14 from "@angular/common";
import * as i15 from "./auth.component";
import * as i16 from "@angular/router";
import * as i17 from "../_services/auth.service";
var styles_AuthComponent = [i0.styles];
var RenderType_AuthComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthComponent, data: {} });
export { RenderType_AuthComponent as RenderType_AuthComponent };
function View_AuthComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [], [[4, "color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.terms() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.color(_co.magazine.navigation.textColor); _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.magazine.termsTitle || "Privacy"); _ck(_v, 1, 0, currVal_1); }); }
export function View_AuthComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "auth"]], [[4, "background", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "flex-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "nova-media", [], [[2, "nofullscreen", null]], null, null, i2.View_NovaMediaComponent_0, i2.RenderType_NovaMediaComponent)), i1.ɵdid(3, 770048, null, 0, i3.NovaMediaComponent, [i4.NovaAPIService, i5.MediaService, i1.Renderer2, i6.DomSanitizer, i7.UIEventHandlerService], { bg: [0, "bg"], version: [1, "version"], mediaId: [2, "mediaId"], opacity: [3, "opacity"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "paid-content-dialog", [], null, null, null, i8.View_PaidContentDialogComponent_0, i8.RenderType_PaidContentDialogComponent)), i1.ɵdid(5, 114688, null, 0, i9.PaidContentDialogComponent, [i10.AuthGuard, i11.MagazineService, i4.NovaAPIService, i12.SSRService, i13.StripeService, i7.UIEventHandlerService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "links"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthComponent_1)), i1.ɵdid(8, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = true; var currVal_3 = _co.getVersion(); var currVal_4 = _co.magazine.cookieBackground; var currVal_5 = 0.8; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4, currVal_5); _ck(_v, 5, 0); var currVal_6 = (_co.magazine.termsTitle || _co.magazine.termsText); _ck(_v, 8, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.color(_co.magazine.navigation.borderColor); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 3).noFullscreen; _ck(_v, 2, 0, currVal_1); }); }
export function View_AuthComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_AuthComponent_0, RenderType_AuthComponent)), i1.ɵdid(1, 245760, null, 0, i15.AuthComponent, [i16.ActivatedRoute, i7.UIEventHandlerService, i11.MagazineService, i12.SSRService, i16.Router, i17.AuthService, i6.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthComponentNgFactory = i1.ɵccf("ng-component", i15.AuthComponent, View_AuthComponent_Host_0, {}, {}, []);
export { AuthComponentNgFactory as AuthComponentNgFactory };
