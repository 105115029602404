import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './cover-classic-text.component.html',
    styleUrls: ['./cover-classic-text.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageCoverClassicTextComponent')
export class PageCoverClassicTextComponent extends Page implements OnInit {
    public titleStyle;
    public flexboxStyle;

    public header_size;
    public subHeader_size;
    public label_size;
    public text_size;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    ngOnInit() {
        // this.setFontSize();

        let titleStyle = '';
        let flexboxStyle = '';

        const posTitle = this.data['contentPosition'];
        const posTitleArr = posTitle.split('-');

        if (posTitleArr[0] === 'top') {
            flexboxStyle += 'justify-content: flex-start;';
        } else if (posTitleArr[0] === 'bottom') {
            flexboxStyle += 'justify-content: flex-end;';
        } else if (posTitleArr[0] === 'center') {
            flexboxStyle += 'justify-content: center;';
        }

        if (posTitleArr[1] === 'left') {
            titleStyle += 'align-self: flex-start;text-align: left;';
        } else if (posTitleArr[1] === 'right') {
            titleStyle += 'align-self: flex-end;text-align: right;';
        } else if (posTitleArr[1] === 'center') {
            titleStyle += 'align-self: center;text-align: center;';
        }

        this.titleStyle = this.convertCSS(titleStyle);
        this.flexboxStyle = this.convertCSS(flexboxStyle);
    }

    // setFontSize() {
    //     if (this.data['header_size']) {
    //         const headerSize = parseInt(this.data['header_size'], 10) / 10;
    //         this.header_size = this.convertCSS('calc(43px + ' + headerSize + 'vw)');
    //     }
    //     if (this.data['subHeader_size']) {
    //         const subHeaderSize = parseInt(this.data['subHeader_size'], 10) / 10;
    //         this.subHeader_size = this.convertCSS('calc(23px + ' + subHeaderSize + 'vw)');
    //     }
    //     if (this.data['label_size']) {
    //         const labelSize = parseInt(this.data['label_size'], 10) / 10;
    //         this.label_size = this.convertCSS('calc(11px + ' + labelSize + 'vw)');
    //     }
    //     if (this.data['text_size']) {
    //         const textSize = parseInt(this.data['text_size'], 10) / 10;
    //         this.text_size = this.convertCSS('calc(11px + ' + textSize + 'vw)');
    //     }
    // }

    convertCSS(css) {
        let input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {},
                attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    }

    setMyStyles(obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    }
}
