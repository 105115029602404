import { SSRService } from './../_services/ssr.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MagazineService } from '../_services/magazine.service';
import { UIEventHandlerService } from '../_services/uievent-handler.service';
import { AuthService } from 'app/_services/auth.service';
import { StripeService } from 'app/_services/stripe.service';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthGuard implements CanActivate {
    issueHandle: string | null = null;
    language: string | null = null;
    issue: any;

    constructor(
        private router: Router,
        private magService: MagazineService,
        private ui: UIEventHandlerService,
        private ssr: SSRService,
        public auth: AuthService,
        private stripe: StripeService
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const magazine = await this.magService.getMagazine().toPromise();

        if (this.ssr.isBrowser && magazine.authType && magazine.authType === 'paid-content') {
            const { issues } = await this.magService.getIssues().toPromise();

            const currentIssue = issues.find(
                issue =>
                    issue.handle === route.paramMap.get('issueHandle') &&
                    issue.language === route.paramMap.get('langHandle')
            );

            if (!currentIssue) {
                console.error('Could not find issue');

                return true;
            }

            if (currentIssue.paidContent) {
                if (!magazine.stripeAccount) {
                    console.error('Could not find stripe account id');

                    return true;
                }

                const isPreviewEnvironment = () =>
                    window.location.host.includes(currentIssue.magazineId.toLowerCase()) ||
                    window.location.host.includes('localhost');

                if (isPreviewEnvironment() && window.location.search.includes('preview=true')) {
                    this.stripe.stripeAccount = magazine.stripeAccount;

                    // set dummy customer to hide paid content dialog & show view of logged in customer
                    this.stripe.currentCustomer = {
                        email: 'preview@paywall.de',
                        isAuthenticated: true
                    };

                    if (window.location.host.includes('localhost')) {
                        console.log('Paywall is disabled.\nRemove "?preview=true" to enable again.');
                    }
                } else {
                    this.stripe.checkAuthentication(magazine, currentIssue);
                }
            }
        }

        if (!this.issueHandle) {
            this.issueHandle = route.paramMap.get('issueHandle');
            this.language = route.paramMap.get('langHandle');
        }

        if (this.ssr.isBrowser) {
            if (!magazine.cookieConsent || (magazine.cookieConsent && magazine.cookieConsent.type === 'default')) {
                const cookiesAccepted = this.ui.getCookie('CookieConsent');

                if (!cookiesAccepted && !this.ssr.getParameterByName('screenShot')) {
                    this.ui.showCookiePopup = true;
                }
            }
        }

        if (
            magazine.authType &&
            (magazine.authType === 'global' ||
                magazine.authType === 'password-protection' ||
                magazine.authType === 'user')
        ) {
            const cookie = this.ui.getCookie('currentUser');

            if (cookie) {
                if (JSON.parse(cookie)) {
                    return true;
                }
            }

            if (this.ssr.getParameterByName('token') && this.ssr.getParameterByName('screenShot')) {
                return true;
            }

            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });

            return false;
        }

        return true;
    }
}
