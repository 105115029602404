import { Injectable } from '@angular/core';

@Injectable()
export class SharedHotelHelperService {
    createArray(len): string[] {
        if (!len || len == undefined || len == '' || len == '0') {
            return [];
        }

        const stars = (parseInt(len) && new Array(parseInt(len)).fill('star')) || [];
        len.indexOf(',') > -1 ? stars.push('star star--half') : stars;

        return stars;
    }
    public handleTagInputs(data: any, tagInputs: string[] = ['country_labels', 'hotel_labels']) {
        const styleProps = ['_bgColor', '_color'];

        tagInputs.forEach(label => {
            if (typeof data[label] === 'string') {
                data[label] = [data[label]];
            }

            if (data[label] && data[label].length) {
                data[label].forEach((tagValue, i) => {
                    const newProp = label + '_' + i;
                    data[newProp] = tagValue;
                    styleProps.forEach(style => {
                        data[newProp + style] = data[label + style];
                    });
                });
            }
        });

        return data;
    }

    public getMapsUrl(data: any) {
        const apiKey = 'AIzaSyB_v-RRBhm8K8zcJRpZPNhFvnTCGKmdzFw';
        return `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${data.location_latitude},${
            data.location_longitude
        }`;
    }
}
