import {
    Component,
    Input,
    OnInit,
    ViewChild,
    ElementRef,
    Renderer2,
    HostBinding,
    ViewEncapsulation
} from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { MediaService } from 'app/_services/media.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import Hls from 'hls.js';
import * as Plyr from 'plyr';
@Component({
    selector: 'nova-mobile-img',
    templateUrl: './nova-mobile-img.component.html',
    styleUrls: ['./nova-mobile-img.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class NovaMobileImgComponent implements OnInit {
    @Input() public data;
    @Input() public mediaId;
    @Input() public mediaSize;
    @Input() public mediaVersion: 'horizontal-wide' | 'vertical' = 'horizontal-wide';

    @ViewChild('bgElm') private bgElm: ElementRef;
    @ViewChild('videoBgElm') private videoBgElm: ElementRef;

    public mediaData;
    public url: string;
    public video: boolean;
    public videoSources: any[];
    public trustedUrl: SafeResourceUrl;

    public player: any;

    constructor(
        public mediaService: MediaService,
        public renderer: Renderer2,
        private sanitizer: DomSanitizer,
        public uiEventHandler: UIEventHandlerService
    ) {}

    @HostBinding('attr.style')
    public get valueAsStyle(): any {
        return this.sanitizer.bypassSecurityTrustStyle(
            `--mobile-img-size: ${this.mediaId ? (this.data && this.data.mediaSize) || 25 : 0}vh`
        );
    }

    ngOnInit() {
        if (this.data) {
            this.mediaId = this.data.mobileMedia || this.data.tabletMedia || this.data.background_media;
            this.mediaVersion = this.data.mediaVersion || this.data.tabletMediaVersion || 'horizontal-w3ide';
        }

        if (!this.mediaId) {
            return;
        }

        this.mediaService
            .getMedia(this.mediaId)
            .pipe(
                switchMap(data => {
                    if (this.mediaService.isVideo(data)) {
                        this.video = true;
                        this.videoSources = this.mediaService.getVideo(data, true);
                    }

                    this.mediaData = data;

                    return this.mediaService.getMediaUrl(
                        this.mediaId,
                        (this.video && 'screenshot') || this.mediaVersion
                    );
                })
            )
            .subscribe(url => {
                this.url = url;

                setTimeout(() => {
                    if (this.mediaData.type && this.mediaData.type === 'bunny') {
                        if (Hls.isSupported()) {
                            if (typeof window !== undefined) {
                                setTimeout(() => {
                                    const videoOptions = {
                                        quality: {
                                            default: 720,
                                            options: [0, 720]
                                        }
                                    };

                                    const hls = new Hls({
                                        enableWorker: true,
                                        startLevel: 4
                                    });

                                    hls.loadSource(this.mediaData.url);
                                    hls.attachMedia(this.videoBgElm.nativeElement);
                                    hls.on(Hls.Events.MANIFEST_PARSED, () => {
                                        if (hls.currentLevel === -1) {
                                            // Auto
                                            if (hls.levels.length === 1) {
                                                hls.currentLevel = 0;
                                            } else if (hls.levels.length === 2) {
                                                hls.currentLevel = 1;
                                            } else {
                                                hls.currentLevel = 2;
                                            }
                                        }

                                        this.player = new Plyr(this.videoBgElm.nativeElement, {
                                            ...videoOptions,
                                            muted: true,
                                            controls: [],
                                            autoplay: true
                                        });
                                    });
                                }, 10);
                            }
                        } else {
                            if (
                                this.videoBgElm &&
                                this.videoBgElm.nativeElement.canPlayType('application/vnd.apple.mpegurl')
                            ) {
                                this.videoBgElm.nativeElement.src = this.mediaData.url;
                                this.player = new Plyr(this.videoBgElm.nativeElement, {
                                    autoplay: true,
                                    loop: { active: true },
                                    muted: true,
                                    controls: [],
                                    clickToPlay: false,
                                    fullscreen: { enabled: false },
                                    storage: { enabled: false }
                                });
                            }
                        }

                        if (this.video && typeof window !== 'undefined' && this.videoBgElm) {
                            if (this.mediaService.isLocalVideo(this.mediaData)) {
                            } else if (this.mediaService.isVimeo(this.mediaData)) {
                                this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                                    `${this.videoSources[0].src}?background=1`
                                );
                            }
                        }

                        if (this.bgElm && this.bgElm.nativeElement) {
                            this.renderer.setStyle(
                                this.bgElm.nativeElement,
                                'background-image',
                                'url(' +
                                    this.mediaService.getImageProxyUrl(
                                        (this.video && this.url) || this.mediaData.thumbnail,
                                        (this.video && 'screenshot') || this.mediaVersion
                                    ) +
                                    ')'
                            );
                        } else {
                            this.url = this.mediaService.getImageProxyUrl(url, this.mediaVersion);
                        }
                    }
                }, 100);
            });
    }
}
