import { NovaAPIService } from './../../_services/nova-api.service';
import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Validators, FormBuilder } from '@angular/forms';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import { FormService } from 'app/_services/form.service';
import { MagazineService } from 'app/_services/magazine.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'nova-form',
    templateUrl: './nova-form.component.html',
    styleUrls: ['./nova-form.component.scss']
})
export class NovaFormComponent implements OnInit, OnDestroy {
    @Input() public data;
    @Input() public form;

    // @Output() openTerms = new EventEmitter();

    public myForm;

    public model = {};

    public formSent = false;
    public termsAccepted = false;
    public magazine;

    private sub: Subscription;

    constructor(
        private formService: FormService,
        private fb: FormBuilder,
        public novaAPI: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public mag: MagazineService
    ) {}

    ngOnInit() {
        this.sub = this.mag.getMagazine().subscribe(data => {
            this.magazine = data;
        });

        const arr = {};

        this.form &&
            this.form.fields &&
            this.form.fields.map(field => {
                if (field.required && field.type !== 'emailField') {
                    arr[field.key] = ['', Validators.required];
                } else if (!field.required && field.type === 'emailField') {
                    arr[field.key] = ['', Validators.email];
                } else if (field.required && field.type === 'emailField') {
                    arr[field.key] = ['', [Validators.required, Validators.email]];
                } else {
                    arr[field.key] = '';
                }
            });

        this.myForm = this.fb.group(arr);
        this.model = this.form && this.form.fields;
    }

    convertType(type) {
        if (type === 'textField') {
            return 'text';
        }
        if (type === 'emailField') {
            return 'email';
        }
        if (type === 'numberField') {
            return 'number';
        }
        if (type === 'customField') {
            return 'text';
        }
    }

    onSubmit() {
        if (this.myForm.valid) {
            this.formService
                .submitForm(
                    this.form._id,
                    this.form.fields.map(field => {
                        field.value = this.myForm.value[field.key];

                        return field;
                    })
                )
                .subscribe(data => {
                    if (data && data.status && data.status === 'success') {
                        this.myForm.reset();
                        this.formSent = true;
                    } else {
                        console.log(data);
                    }
                });
        }
    }

    toggletTermsAccepted(event) {
        this.termsAccepted = event.target.checked;
    }

    getLang() {
        return this.novaAPI.lang;
    }

    openTerms() {
        this.uiEventHandler.showTerms = true;
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }
}
