import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { map } from 'rxjs/operators';
import { UIEventHandlerService } from './uievent-handler.service';

@Injectable()
export class AuthService {
    constructor(private network: NetworkService, private ui: UIEventHandlerService) {}

    public login(username: string, password: string, magazineId: string) {
        return this.network.post('login', { email: username, password, magazineId }).pipe(
            map(user => {
                if (user && user.authToken) {
                    this.ui.setCookie('currentUser', JSON.stringify(user), 30);
                }

                return user;
            })
        );
    }

    public logout() {
        this.ui.deleteCookie('currentUser');
    }
}
