import { Component, EventEmitter, Output, Input } from '@angular/core';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { MagazineService } from 'app/_services/magazine.service';
import { UIEventHandlerService } from 'app/_services/uievent-handler.service';
import { Router } from '@angular/router';

@Component({
    selector: 'nova-budapest-overlay-pages',
    templateUrl: './nova-budapest-overlay-pages.component.html',
    styleUrls: ['./nova-budapest-overlay-pages.component.scss']
})
export class NovaBudapestOverlayPagesComponent {
    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
    @Input() currentPage = -1;
    @Input() public font;
    @Input() public borderColor;

    constructor(
        public nova: NovaAPIService,
        public mag: MagazineService,
        public uiEventHandler: UIEventHandlerService,
        public router: Router
    ) {}

    isActive(check) {
        if (check) {
            return this.borderColor;
        }
    }

    goTo(page) {
        this.pageChange.emit(page);
    }

    pad(num, size) {
        let s = num + '';
        while (s.length < size) s = '0' + s;
        return s;
    }
}
