/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fti-travelquiz.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../_components/nova-bg/nova-bg.component.ngfactory";
import * as i3 from "../../../_components/nova-bg/nova-bg.component";
import * as i4 from "../../../_services/uievent-handler.service";
import * as i5 from "../../../_components/nova-scrollbar-holder/nova-scrollbar-holder.component.ngfactory";
import * as i6 from "../../../_components/nova-scrollbar-holder/nova-scrollbar-holder.component";
import * as i7 from "../../../_components/nova-mobile-img/nova-mobile-img.component.ngfactory";
import * as i8 from "../../../_components/nova-mobile-img/nova-mobile-img.component";
import * as i9 from "../../../_services/media.service";
import * as i10 from "@angular/platform-browser";
import * as i11 from "@angular/common";
import * as i12 from "../../../_components/nova-title/nova-title.component.ngfactory";
import * as i13 from "../../../_components/nova-title/nova-title.component";
import * as i14 from "../../../_components/nova-text-element/nova-text-element.component.ngfactory";
import * as i15 from "../../../_components/nova-text-element/nova-text-element.component";
import * as i16 from "../../../_services/nova-api.service";
import * as i17 from "@angular/router";
import * as i18 from "../../../_services/magazine.service";
import * as i19 from "../../../_services/cookie.service";
import * as i20 from "../../../_components/nova-text-gallery/nova-text-gallery.component.ngfactory";
import * as i21 from "../../../_components/nova-text-gallery/nova-text-gallery.component";
import * as i22 from "./fti-travelquiz.component";
var styles_PageFtiTravelquizComponent = [i0.styles];
var RenderType_PageFtiTravelquizComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PageFtiTravelquizComponent, data: {} });
export { RenderType_PageFtiTravelquizComponent as RenderType_PageFtiTravelquizComponent };
export function View_PageFtiTravelquizComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "page page-fti-travelquiz"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "nova-bg", [], null, null, null, i2.View_NovaBgComponent_0, i2.RenderType_NovaBgComponent)), i1.ɵdid(2, 114688, null, 0, i3.NovaBgComponent, [i4.UIEventHandlerService], { data: [0, "data"], forceVideo: [1, "forceVideo"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 16, "nova-scrollbar-holder", [], null, null, null, i5.View_NovaScrollbarHolderComponent_0, i5.RenderType_NovaScrollbarHolderComponent)), i1.ɵdid(4, 114688, null, 0, i6.NovaScrollbarHolderComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 14, "div", [["class", "scroller"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "nova-mobile-img", [["class", "show-for-small-only left"]], [[1, "style", 2]], null, null, i7.View_NovaMobileImgComponent_0, i7.RenderType_NovaMobileImgComponent)), i1.ɵdid(7, 114688, null, 0, i8.NovaMobileImgComponent, [i9.MediaService, i1.Renderer2, i10.DomSanitizer, i4.UIEventHandlerService], { data: [0, "data"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 11, "div", [["class", "bg animated fadeInUp clear"]], [[8, "hidden", 0]], null, null, null, null)), i1.ɵdid(9, 278528, null, 0, i11.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(10, { "background-color": 0 }), (_l()(), i1.ɵeld(11, 0, null, null, 1, "nova-title", [], [[2, "noBg", null]], null, null, i12.View_NovaTitleComponent_0, i12.RenderType_NovaTitleComponent)), i1.ɵdid(12, 114688, null, 0, i13.NovaTitleComponent, [], { data: [0, "data"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "nova-text-element", [["class", "con"]], null, [[null, "openGallery"]], function (_v, en, $event) { var ad = true; if (("openGallery" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).openSlider($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_NovaTextElementComponent_0, i14.RenderType_NovaTextElementComponent)), i1.ɵdid(14, 4964352, null, 0, i15.NovaTextElementComponent, [i4.UIEventHandlerService, i9.MediaService, i16.NovaAPIService, i17.Router, i10.DomSanitizer, i17.ActivatedRoute, i18.MagazineService, i19.CookieService, i11.DOCUMENT], { data: [0, "data"], myid: [1, "myid"] }, { openGallery: "openGallery" }), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["id", "travelquiz"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "a", [["id", "hc2-embed"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["L\u00E4dt \u2026"])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "nova-text-element", [["class", "con"]], null, [[null, "openGallery"]], function (_v, en, $event) { var ad = true; if (("openGallery" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).openSlider($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_NovaTextElementComponent_0, i14.RenderType_NovaTextElementComponent)), i1.ɵdid(19, 4964352, null, 0, i15.NovaTextElementComponent, [i4.UIEventHandlerService, i9.MediaService, i16.NovaAPIService, i17.Router, i10.DomSanitizer, i17.ActivatedRoute, i18.MagazineService, i19.CookieService, i11.DOCUMENT], { data: [0, "data"], myid: [1, "myid"] }, { openGallery: "openGallery" }), (_l()(), i1.ɵeld(20, 0, null, null, 1, "nova-text-gallery", [], null, null, null, i20.View_NovaTextGalleryComponent_0, i20.RenderType_NovaTextGalleryComponent)), i1.ɵdid(21, 49152, [["gallery", 4]], 0, i21.NovaTextGalleryComponent, [i4.UIEventHandlerService, i1.Renderer2, i11.DOCUMENT], { medias: [0, "medias"], myid: [1, "myid"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.data; var currVal_2 = true; _ck(_v, 2, 0, currVal_1, currVal_2); _ck(_v, 4, 0); var currVal_4 = _co.data; _ck(_v, 7, 0, currVal_4); var currVal_6 = _ck(_v, 10, 0, _co.getColor(_co.data["box_color"])); _ck(_v, 9, 0, currVal_6); var currVal_8 = _co.data; _ck(_v, 12, 0, currVal_8); var currVal_9 = _co.data.text; var currVal_10 = _co.data._id; _ck(_v, 14, 0, currVal_9, currVal_10); var currVal_11 = _co.data.text_bottom; var currVal_12 = _co.data._id; _ck(_v, 19, 0, currVal_11, currVal_12); var currVal_13 = _co.data.textImages; var currVal_14 = _co.data._id; _ck(_v, 21, 0, currVal_13, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "page-", _co.id, ""); _ck(_v, 0, 0, currVal_0); var currVal_3 = i1.ɵnov(_v, 7).valueAsStyle; _ck(_v, 6, 0, currVal_3); var currVal_5 = (((!_co.data.text && !_co.data.header) && !_co.data.subHeader) && !_co.data.label); _ck(_v, 8, 0, currVal_5); var currVal_7 = !_co.data.background_media; _ck(_v, 11, 0, currVal_7); }); }
export function View_PageFtiTravelquizComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_PageFtiTravelquizComponent_0, RenderType_PageFtiTravelquizComponent)), i1.ɵdid(1, 114688, null, 0, i22.PageFtiTravelquizComponent, [i16.NovaAPIService, i4.UIEventHandlerService, i10.Meta, i10.Title, i17.Router, i17.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageFtiTravelquizComponentNgFactory = i1.ɵccf("ng-component", i22.PageFtiTravelquizComponent, View_PageFtiTravelquizComponent_Host_0, {}, {}, []);
export { PageFtiTravelquizComponentNgFactory as PageFtiTravelquizComponentNgFactory };
