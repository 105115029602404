<div id="page-{{ id }}" class="page page-travelworld-modern"
     [ngStyle]="{'backgroundColor':getColor(data['background_color'])}">

	<nova-bg [data]="data" [forceVideo]="true"></nova-bg>
    <nova-scrollbar-holder>
        <div class="scroller">
            <div class="center clear">

                <div class="fti-container">
                    <div class="title-wrapper content-box">
                        <nova-title [data]="data"></nova-title>
                    </div>

                    <div class="filter-bar" *ngIf="(continentFilters.length || countryFilters.length || categoryFilters.length)">

                        <div class="filter-bar__box">
                            <angular2-multiselect
                                [data]="continentFilters"
                                [(ngModel)]="selectedContinentFilters"
                                (ngModelChange)="filter()"
                                [settings]="continentDropdownSettings"
                                [disabled]="continentFilters.length">
                            </angular2-multiselect>
                        </div>

                        <div class="filter-bar__box">
                            <angular2-multiselect
                                [data]="countryFilters"
                                [(ngModel)]="selectedCountryFilters"
                                (ngModelChange)="filter()"
                                [settings]="countryDropdownSettings"
                                [disabled]="countryFilters.length">
                            </angular2-multiselect>
                        </div>

                        <div class="filter-bar__box">
                            <angular2-multiselect
                                [data]="categoryFilters"
                                [(ngModel)]="selectedCategoryFilters"
                                (ngModelChange)="filter()"
                                [settings]="categoryDropdownSettings"
                                [disabled]="categoryFilters.length">
                            </angular2-multiselect>
                        </div>
                    </div>
                </div>

                <ul class="product-list" *ngIf="filteredData.length">
                    <li *ngFor="let item of filteredData" class="product-item">
                        <div class="product-item__image-container" [style.background-image]="item.image ? 'url(' + getImageUrl(item.image) + ')' : null">
                            <div *ngIf="item.status === 'inactive'" class="coming-soon-overlay">
                                <div class="coming-soon-overlay__text">Coming Soon</div>
                            </div>
                            <a [attr.href]="item.link ? item.link : null" class="product-item__link" [class.has-link]="item.link ? true : false">
                                <div class="product-item__title">
                                    <nova-title [data]="item"></nova-title>
                                </div>
                                <ul class="product-item__tags" [ngStyle]="{'font-family': item.region_labels_fonts && getFont(item.region_labels_fonts)}">
                                    <li *ngFor="let tag of item.region_labels">
                                        {{tag}}
                                    </li>
                                </ul>
                            </a>
                        </div>
                        <div *ngIf="item.text" class="product-item__description">
                            <nova-element [data]="item" name="text"></nova-element>
                        </div>
                    </li>
                </ul>

                <div class="fti-container fti-container__footer" *ngIf="data.footer">
                    <nova-text-element [data]="data.footer"></nova-text-element>
                </div>
            </div>
        </div>
        <nova-content-links *ngIf="data.links" style="display: none" [floors]="data.links"></nova-content-links>
        <nova-text-gallery [medias]="data.textImages" #gallery></nova-text-gallery>
        <nova-font-loader *ngIf="data.filter_font" [fonts]="[data.filter_font]"></nova-font-loader>
        <nova-font-loader *ngIf="data.filter_font_header" [fonts]="[data.filter_font_header]"></nova-font-loader>
    </nova-scrollbar-holder>
</div>
