import { Injectable } from '@angular/core';
import { SSRService } from './ssr.service';
import { MagazineService } from './magazine.service';
import { UIEventHandlerService } from './uievent-handler.service';
import { Router } from '@angular/router';
import { NovaAPIService } from 'app/_services/nova-api.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class LogoAndFaviconService {
    constructor(
        public ssr: SSRService,
        public magazine: MagazineService,
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        private router: Router
    ) {}

    setFaviconAndLogo() {
        if (this.ssr.isBrowser && typeof window !== 'undefined') {
            this.magazine.getMagazine().subscribe(magazine => {
                if (magazine.favicon) {
                    $('link[rel="icon"]')
                        .attr('type', magazine.favicon['contentType'])
                        .attr('href', `${magazine.favicon['url']}?ver=1.1`);
                    $('link[rel="apple-touch-icon"]')
                        .attr('type', magazine.favicon['contentType'])
                        .attr('href', `${magazine.favicon['url']}?ver=1.1`);
                } else {
                    $('link[rel="icon"]')
                        .attr('type', 'image/ico')
                        .attr('href', 'assets/img/favicon.png');
                    $('link[rel="apple-touch-icon"]')
                        .attr('type', 'image/ico')
                        .attr('href', 'assets/img/favicon.png');
                }

                if (magazine.logo) {
                    this.uiEventHandler.logo = magazine.logo.url;
                }

                const params =
                    this.router.routerState.root.firstChild &&
                    this.router.routerState.root.firstChild['params']['value'];

                if (params && params.issueHandle) {
                    this.nova.getCurrentIssue().subscribe(
                        issue => {
                            if (issue.favicon) {
                                $('link[rel="icon"]')
                                    .attr('type', issue.favicon['contentType'])
                                    .attr('href', issue.favicon['url']);
                                $('link[rel="apple-touch-icon"]')
                                    .attr('type', issue.favicon['contentType'])
                                    .attr('href', issue.favicon['url']);
                            }

                            if (issue.logo) {
                                this.uiEventHandler.logo = issue.logo.url;
                            }
                        },
                        err => {
                            console.log('setFaviconAndLogo -> getCurrentIssue', err);
                        }
                    );
                }
            });
        }
    }
}
