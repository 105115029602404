import { EventEmitter } from '@angular/core';
var PreloaderService = /** @class */ (function () {
    function PreloaderService() {
        this.states = {
            PENDING: 1,
            LOADING: 2,
            RESOLVED: 3,
            REJECTED: 4
        };
        this.loadCount = 0;
        this.imageCount = 0;
        this.loadEvent$ = new EventEmitter();
    }
    PreloaderService.prototype.preloadImages = function (imageLocations) {
        // I am the image SRC values to preload.
        this.imageLocations = imageLocations;
        // As the images load, we'll need to keep track of the load/error
        // counts when announing the progress on the loading.
        this.imageCount = this.imageLocations.length;
        // I keep track of the current state of the preloader.
        this.state = this.states.PENDING;
        // When loading the images, a promise will be returned to indicate
        // when the loading has completed (and / or progressed).
        this.loadCount = 0;
        return this.load();
    };
    // I determine if the preloader has started loading images yet.
    PreloaderService.prototype.isInitiated = function () {
        return this.state !== this.states.PENDING;
    };
    // I determine if the preloader has failed to load all of the images.
    PreloaderService.prototype.isRejected = function () {
        return this.state === this.states.REJECTED;
    };
    // I determine if the preloader has successfully loaded all of the images.
    PreloaderService.prototype.isResolved = function () {
        return this.state === this.states.RESOLVED;
    };
    // I initiate the preload of the images. Returns a promise.
    PreloaderService.prototype.load = function () {
        var _this = this;
        // If the images are already loading, return the existing promise.
        if (this.isInitiated()) {
            return this.promise;
        }
        this.state = this.states.LOADING;
        this.promise = new Promise(function (resolve, reject) {
            var _loop_1 = function (i) {
                var image = $(new Image())
                    .on('load error', function (event) {
                    _this.handleImageLoad(event.target['src'], resolve);
                    image = event = null;
                })
                    .prop('src', _this.imageLocations[i]);
            };
            for (var i = 0; i < _this.imageCount; i++) {
                _loop_1(i);
            }
        });
        // Return the deferred promise for the load event.
        return this.promise;
    };
    // I handle the load-success of the given image location.
    PreloaderService.prototype.handleImageLoad = function (imageLocation, resolve) {
        this.loadCount++;
        // Notify the progress of the overall deferred. This is different
        // than Resolving the deferred - you can call notify many times
        // before the ultimate resolution (or rejection) of the deferred.
        var percent = Math.ceil((this.loadCount / this.imageCount) * 100);
        this.loadEvent$.emit({
            percent: (percent < 100 && percent) || 100,
            imageLocation: imageLocation
        });
        // If all of the images have loaded, we can resolve the deferred
        // value that we returned to the calling context.
        // if (this.loadCount === this.imageCount) {
        if (this.loadCount >= this.imageCount || percent >= 100) {
            this.state = this.states.RESOLVED;
            resolve(this.imageLocations);
        }
    };
    return PreloaderService;
}());
export { PreloaderService };
