import { Component, ElementRef, OnInit, Input, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { IFrameComponent, iframeResizer } from 'iframe-resizer';

@Component({
    selector: 'nova-iframe',
    templateUrl: './iframe.component.html'
})
export class IframeComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() public src;
    @Input() public minHeight;
    @Input() public heightCalculationMethod;
    @Input() public scrolling;

    component: IFrameComponent;

    loading = true;

    @ViewChild('iframe') iframe: ElementRef;

    public sanitizedUrl: SafeUrl;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit() {
        this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
    }

    ngAfterViewInit() {
        const components = iframeResizer(
            {
                checkOrigin: false,
                log: false,
                heightCalculationMethod: this.heightCalculationMethod || 'max',
                minHeight: this.minHeight || 600,
                sizeHeight: true,
                autoResize: true,
                scrolling: this.scrolling || false
            },
            this.iframe.nativeElement
        );

        this.component = components && components.length > 0 ? components[0] : null;
    }

    ngOnDestroy(): void {
        if (this.component && this.component.iFrameResizer) {
            this.component.iFrameResizer.close();
        }
    }

    onIframeLoaded() {
        this.loading = false;
        if (window.addEventListener) {
            window.addEventListener('message', this.receiveResizeMessage, false);
        }
    }

    receiveResizeMessage(e) {
        if (typeof e.data != 'string') {
            return;
        }
        var t = e.data.split(':');
        var i = t[0];
        var n,
            r,
            a = 0;
        if (!Array.prototype.indexOf) {
            Array.prototype.indexOf = function(e, t) {
                for (var i = t || 0, n = this.length; i < n; i++) {
                    if (this[i] === e) {
                        return i;
                    }
                }
                return -1;
            };
        }
        if (i === 'resize') {
            if (this.component && this.component.iFrameResizer) {
                this.component.iFrameResizer.resize();
            } else {
                n = document.getElementsByTagName('iframe');
                r = n.length;
                for (; a < r; a++) {
                    if ((n[a].contentWindow || n[a].documentWindow) == e.source) {
                        n[a].style.height = t[1] + 'px';
                        return;
                    }
                }
            }
        }
    }
}
