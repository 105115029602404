import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { ActivatedRoute, Router } from '@angular/router';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Floor } from '../../floor';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { Meta, Title } from '@angular/platform-browser';
import { NovaGalleryPopupComponent } from '../../../_components/nova-gallery-popup/nova-gallery-popup.component';
import { filter } from 'rxjs/operators';
var PageImageGalleryComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageImageGalleryComponent, _super);
    function PageImageGalleryComponent(route, router, uiEventHandler, nova, meta, title) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.meta = meta;
        _this.title = title;
        return _this;
    }
    PageImageGalleryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.uiEventHandler.hideSwipeOverlay();
        this.route.queryParams.pipe(filter(function (params) { return params.img; })).subscribe(function (params) {
            setTimeout(function () {
                var i = 0;
                var index = 0;
                _this.data.medias.forEach(function (entry) {
                    if (params.img === entry.media) {
                        index = i;
                    }
                    i++;
                });
                _this.gallery.goTo(index);
            }, 1000);
        });
    };
    PageImageGalleryComponent = tslib_1.__decorate([
        NovaTemplate('PageImageGalleryComponent'),
        tslib_1.__metadata("design:paramtypes", [ActivatedRoute,
            Router,
            UIEventHandlerService,
            NovaAPIService,
            Meta,
            Title])
    ], PageImageGalleryComponent);
    return PageImageGalleryComponent;
}(Floor));
export { PageImageGalleryComponent };
