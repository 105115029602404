import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { NetworkService } from './network.service';
import * as hash from 'object-hash';
import { map, filter, switchMap, shareReplay, mergeMap, withLatestFrom, takeUntil } from 'rxjs/operators';
import { makeStateKey } from '@angular/platform-browser';
import { SSRService } from './ssr.service';
import { RenderService } from 'app/_services/render.service';

const MAGAZINE_KEY = makeStateKey('magazine');
const ISSUES_KEY = makeStateKey('issues');

@Injectable()
export class MagazineService {
    private magCache: Observable<any>;
    private issuesCache: Observable<any>;
    public issueCache: Observable<any>;
    public availableLanguages: string[] = [];
    private magStateFirst = true;
    private issuesStateFirst = true;
    private issuesHash: string;
    private lastIssue: string;
    private lastLang: string;
    private issueSubject: Subject<any>;
    public issuesData;
    public currentIssue;

    constructor(private network: NetworkService, private ssr: SSRService, private renderService: RenderService) {}

    public getMagazine(): Observable<any> {
        if (this.magCache) {
            return this.magCache;
        }

        this.magCache = this.network.createInterval().pipe(
            mergeMap(() => {
                if (this.magStateFirst) {
                    this.magStateFirst = false;
                    const mag = this.ssr.getState(MAGAZINE_KEY);
                    if (mag) {
                        return of(mag);
                    }
                }

                return this.network.fetch(`magazine/${this.network.getMagazineUrl()}`);
            }),
            map(data => {
                this.ssr.setState(MAGAZINE_KEY, data);
                if (data['status'] === 'success') {
                    if (data['data'].impressumText && !data['data'].impressumImages) {
                        data['data'].impressumText = this.renderService.render(data['data'].impressumText);
                        data['data'].impressumImages = this.renderService.foundImages;
                    }

                    return data['data'];
                }
                throw new Error(data['status'] || 404);
            }),
            shareReplay(1)
        );

        return this.magCache;
    }

    public getIssues(): Observable<any> {
        if (this.issuesCache) {
            return this.issuesCache;
        }

        let mag;

        this.issuesCache = this.getMagazine().pipe(
            mergeMap(data => {
                mag = data;
                if (this.issuesStateFirst) {
                    this.issuesStateFirst = false;
                    const issues = this.ssr.getState(ISSUES_KEY);
                    if (issues) return of(issues);
                }

                return this.network.fetch(`issues/${data._id}`);
            }),
            map(data => {
                if (data['status'] === 'success') {
                    const issues = [];
                    const issuesData = new Map();
                    data['issues'].forEach(issue => {
                        if (issue._id) {
                            const languagesMap = {};

                            issue.languages.forEach(lang => {
                                languagesMap[lang.language] = lang;
                                issuesData.set(
                                    lang,
                                    Object.assign(
                                        {
                                            issueId: issue._id
                                        },
                                        lang
                                    )
                                );
                            });

                            issue.languagesMap = languagesMap;

                            if (Object.keys(languagesMap).length > 0) {
                                issues.push(issue);
                            }
                        }
                    });

                    return {
                        issues,
                        issuesData,
                        magazine: mag
                    };
                } else if (data['status'] === '423') {
                    return {
                        issues: [],
                        issuesData: new Map(),
                        magazine: mag
                    };
                }
                throw new Error(data['status'] || 404);
            }),
            filter(data => {
                const myHash = hash(data);
                if (this.issuesHash && this.issuesHash === myHash) {
                    return false;
                }
                this.issuesHash = myHash;

                return true;
            }),
            shareReplay(1)
        );

        return this.issuesCache;
    }

    public getSEOData(path): Observable<any> {
        const magUrl = this.network.getMagazineUrl();

        return this.network
            .postRaw('seo', {
                url: `https://${magUrl}${path}`
            })
            .pipe(map(data => data));
    }

    public getIssueById(id: string): Observable<any> {
        return this.getIssues().pipe(
            switchMap(data => {
                const issue = data.issuesData.get(id);

                return this.getIssue(issue.handle, issue.language);
            })
        );
    }

    public getIssue(handle: string, lang: string): Observable<any> {
        if (this.issueCache && this.lastIssue === handle && this.lastLang === lang) {
            return this.issueCache;
        }

        this.lastIssue = handle;
        this.lastLang = lang;

        let issues;

        if (this.issueSubject) {
            this.issueSubject.next(null);
            this.issueCache = null;
        }

        this.issueSubject = new Subject();

        this.issueCache = this.network.createInterval().pipe(
            withLatestFrom(this.getIssues(), (_, issues) => {
                return issues;
            }),
            mergeMap(data => {
                issues = data;
                let params = window.location.search.substring(1);

                if (params) {
                    params = `&${params.replace('ag', 'ta')}`;
                }

                return this.network.fetch(`issue/${data['magazine']._id}/${handle}?lang=${lang}${params}`);
            }),
            map(data => {
                if (data['status'] === 'success') {
                    if (data['languages'].length > 0) {
                        let issueLang = data['issue'];
                        data['languages'].forEach(issueLanguage => {
                            if (this.ssr.getParameterByName('preview')) {
                                this.availableLanguages.push(issueLanguage.language);
                            } else {
                                issueLanguage.status === 'active' &&
                                    this.availableLanguages.push(issueLanguage.language);
                            }

                            this.availableLanguages = [...new Set(this.availableLanguages)];

                            if (issueLanguage && issueLanguage.language === lang) {
                                issueLang.pages = issueLanguage.pages;
                                issueLang.fonts = issueLanguage.fonts;
                            }
                        });

                        let found;
                        data['languages'].forEach(val => {
                            if (val.language === lang) {
                                found = val;
                            }
                        });

                        if (found) {
                            issues.issuesData.forEach(value => {
                                if (value.handle === handle && value.language === lang) {
                                    Object.assign(value, issueLang);
                                    issueLang = value;
                                }
                            });
                        }

                        return { ...issueLang, travelAgency: data['ta'] };
                    }
                    throw new Error('can not find pages for language');
                } else if (data['status']) {
                    throw new Error(data['status']);
                }
                throw new Error('can not find issue');
            }),
            map(issue => {
                issue.pageIds = new Map();
                issue.contentIds = new Map();

                if (issue.pages.length === 0) {
                    throw new Error('no pages found');
                }

                issue.pages.forEach((page, pIndex) => {
                    page['travelAgency'] = issue['travelAgency'];
                    if (page.status === 'active' || this.ssr.getToken()) {
                        issue.pageIds.set(page._id, {
                            page: page.handle,
                            thumb: page.thumb,
                            templateName: page.templateName,
                            pageIndex: pIndex
                        });
                        if (page.contents) {
                            page.contents.forEach((floor, fIndex) => {
                                floor['travelAgency'] = issue['travelAgency'];
                                if (floor.status === 'active' || this.ssr.getToken()) {
                                    issue.contentIds.set(floor._id, {
                                        floor: floor.handle,
                                        page: page.handle,
                                        thumb: floor.thumb,
                                        templateName: floor.templateName,
                                        linkIcon: floor.link_icon,
                                        pageIndex: pIndex,
                                        floorIndex: fIndex
                                    });
                                }
                            });
                        }
                    }
                });

                return issue;
            }),
            takeUntil(this.issueSubject),
            shareReplay(1)
        );

        return this.issueCache;
    }

    public formatHtml(val) {
        if (val.rendered) {
            return val;
        }
        val.rendered = true;

        if (val.text) {
            val.text = this.renderService.render(val.text);
            val.textImages = this.renderService.foundImages;
        }

        if (!val.textImages) {
            val.textImages = [];
        }

        if (val.textLeft) {
            val.textLeft = this.renderService.render(val.textLeft);
            val.textImages = val.textImages.concat(this.renderService.foundImages);
        }
        if (val.textRight) {
            val.textRight = this.renderService.render(val.textRight);
            val.textImages = val.textImages.concat(this.renderService.foundImages);
        }

        if (val.medias && val.medias.length) {
            val.medias.forEach(mVal => {
                mVal.text = this.renderService.render(mVal.text);
                mVal.textImages = this.renderService.foundImages;
            });
        }
        if (val.blocks) {
            val.blocks.forEach(mVal => {
                if (mVal.text) {
                    mVal.text = this.renderService.render(mVal.text);
                    mVal.textImages = this.renderService.foundImages;
                    if (val.templateName.startsWith('timeline-')) {
                        if (val.textImages) {
                            val.textImages = val.textImages.concat(mVal.textImages);
                        } else {
                            val.textImages = mVal.textImages;
                        }
                    }
                }
            });
        }
        if ((val.templateName === 'article-classic' || val.templateName === 'interview-classic') && val.subHeader) {
            val.subHeader = this.renderService.render(val.subHeader);
            if (!this.renderService.foundImages) {
                this.renderService.foundImages = [];
            }
            if (!val.textImages) {
                val.textImages = [];
            }
            val.textImages = this.renderService.foundImages.concat(val.textImages);
        }

        return val;
    }
}
