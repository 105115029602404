/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-gallery.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../_components/nova-gallery-popup/nova-gallery-popup.component.ngfactory";
import * as i3 from "../../../_components/nova-gallery-popup/nova-gallery-popup.component";
import * as i4 from "../../../_services/nova-api.service";
import * as i5 from "../../../_services/media.service";
import * as i6 from "../../../_services/uievent-handler.service";
import * as i7 from "@angular/common";
import * as i8 from "./image-gallery.component";
import * as i9 from "@angular/router";
import * as i10 from "@angular/platform-browser";
var styles_PageImageGalleryComponent = [i0.styles];
var RenderType_PageImageGalleryComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PageImageGalleryComponent, data: {} });
export { RenderType_PageImageGalleryComponent as RenderType_PageImageGalleryComponent };
function View_PageImageGalleryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nova-gallery-popup", [], null, null, null, i2.View_NovaGalleryPopupComponent_0, i2.RenderType_NovaGalleryPopupComponent)), i1.ɵdid(1, 245760, [[1, 4], ["gallery", 4]], 0, i3.NovaGalleryPopupComponent, [i4.NovaAPIService, i5.MediaService, i6.UIEventHandlerService], { con: [0, "con"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.medias; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PageImageGalleryComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { gallery: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "page-image-classic-slider"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageImageGalleryComponent_1)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.medias; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_PageImageGalleryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_PageImageGalleryComponent_0, RenderType_PageImageGalleryComponent)), i1.ɵdid(1, 114688, null, 0, i8.PageImageGalleryComponent, [i9.ActivatedRoute, i9.Router, i6.UIEventHandlerService, i4.NovaAPIService, i10.Meta, i10.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageImageGalleryComponentNgFactory = i1.ɵccf("ng-component", i8.PageImageGalleryComponent, View_PageImageGalleryComponent_Host_0, {}, {}, []);
export { PageImageGalleryComponentNgFactory as PageImageGalleryComponentNgFactory };
