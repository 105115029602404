import { Component, ViewEncapsulation } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './cover-atlas-copco.component.html',
    styleUrls: ['./cover-atlas-copco.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageCoverAtlasCopcoComponent')
export class PageCoverAtlasCopcoComponent extends Page {
    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    get blue() {
        return this.data.ciEdge === 'blue';
    }
}
