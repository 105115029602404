import {
    Component,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation,
    Output,
    EventEmitter,
    Renderer2,
    Inject
} from '@angular/core';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import { NovaGalleryPopupComponent } from '../nova-gallery-popup/nova-gallery-popup.component';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'nova-text-gallery',
    templateUrl: './nova-text-gallery.component.html',
    styleUrls: ['./nova-text-gallery.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NovaTextGalleryComponent {
    constructor(
        public uiEventHandler: UIEventHandlerService,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document
    ) {}

    @Input() public medias;
    @Input() public myid;
    @Output('close') public onClose = new EventEmitter<boolean>();
    @ViewChild('modal') private modal;
    @ViewChild('gallery') private gallery: NovaGalleryPopupComponent;

    public open = false;
    public scrollPos = 0;

    openSlider(data?) {
        let i = 0;
        let index = 0;
        this.medias.forEach(entry => {
            if (data.src && data.src === entry.media) {
                index = i;
            } else if (data.url && data.url === entry.url) {
                index = i;
            }
            i++;
        });
        this.gallery.goTo(index);
        this.open = true;
        this.uiEventHandler.disableNav = true;
        this.uiEventHandler.hideTitleBar = true;
        this.uiEventHandler.galleryPopup = true;
        this.uiEventHandler.hideContentCloseIcon = true;

        if (this.uiEventHandler.isMobile() || this.uiEventHandler.isTablet()) {
            this.renderer.addClass(this.document.body, 'mobile-gallery-open');
            this.scrollPos = $('.page').scrollTop();
            $('.page').scrollTop(0);
        }

        setTimeout(() => {
            this.gallery.resize();
        }, 100);
    }

    closeSlider() {
        setTimeout(() => {
            this.open = false;
            this.uiEventHandler.disableNav = false;
            this.uiEventHandler.hideTitleBar = false;
            this.uiEventHandler.galleryPopup = false;
            this.uiEventHandler.hideContentCloseIcon = false;
            this.onClose.emit(null);

            if (this.uiEventHandler.isMobile() || this.uiEventHandler.isTablet()) {
                this.renderer.removeClass(this.document.body, 'mobile-gallery-open');
                $('.page').scrollTop(this.scrollPos);
            }
        }, 100);
    }
}
