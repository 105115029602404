<div id="page-{{ id }}" class="page-blog-index">
    <nova-bg [data]="data" class="background"></nova-bg>
    <div class="container" [class.no-image]="!data.background_media" style="margin: auto" *ngIf="!isLoading">
        <div>
            <nova-element [data]="data" name="label"></nova-element>
            <br/>
            <nova-element [data]="data" name="header"></nova-element>
            <br/>
            <nova-element [data]="data" name="subHeader"></nova-element>
        </div>

        <div class='blog-search'>
            <input (ngModelChange)='onChangeSearchFilter($event)'
                   [(ngModel)]='searchValue' class='blog-search__input'
                   placeholder="{{ 'SEARCH_PLACEHOLDER' | translate }}"
                   type='text'
            >
        </div>

        <div class="blog-category-filter">
            <button *ngIf="categories.length > 0" class="btn btn-reset" [class.hidden]="!uiEventHandler.isMobile() || !uiEventHandler.isTablet()" (click)="toggleCategories()">
                <nova-element [data]="data" name="categoryFilterToggleTitle"></nova-element>
                    &nbsp;<i class="fa" [class.fa-caret-down]="!categoriesVisible" [class.fa-caret-up]="categoriesVisible" aria-hidden="true"></i>
                <br/>
                <small>{{selectedCategories.join(",")}}&nbsp;<i *ngIf="selectedCategories.length > 0" (click)="selectCategory($event)" class="fa fa-icon fa-close"></i></small>
            </button>
            <div [class.hidden]="(uiEventHandler.isMobile() || uiEventHandler.isTablet()) && !categoriesVisible" class="blog-category-container">
                <button
                    class="btn-reset item"
                    (click)="selectCategory($event)"
                    [class.active]="selectedCategories.length === 0"
                    [class.with-padding-left]="categoriesHaveIcons">
                        <nova-element *ngIf="selectedCategories.length > 0; else categoryFilterShowAllText" [data]="data" name="categoryFilterResetText"></nova-element>
                        <ng-template #categoryFilterShowAllText>
                            <nova-element [data]="data" name="categoryFilterShowAllText"></nova-element>
                        </ng-template>
                </button>
                <button *ngFor="let category of categories" (click)="selectCategory($event, category.name)" class="btn-reset category item" [class.active]="category && selectedCategories.includes(category.name.toLowerCase())" [class.with-padding-left]="categoriesHaveIcons && category.image">
                    <img *ngIf="category.image" [src]="category.image" [alt]="category.name" />&nbsp;<nova-element [data]="category" name="name"></nova-element>
                </button>
            </div>
        </div>

        <ul class="ul-reset blog-posts">

            <li *ngFor="let post of filteredPosts">
                <a routerLink="./{{post.handle}}" class="single-post" [style.background-image]="post.thumbnail && post.thumbnail.url && 'url(' + post.thumbnail.url + ')'" [@fadeAnimation]="'in'" [style.background-color]="blogService.getCategoryColor(categories, post.categories)">
                <div *ngIf="post.categories" class="categories-container">
                    <div *ngFor="let category of blogService.getActiveCategories(categories, post.categories)">
                        <button (click)="selectCategory($event, category.name)" class="btn-reset category-icon">
                            <img *ngIf="category.image" [src]="category.image" [alt]="category.name" />
                        </button>
                    </div>
                </div>

                <div class="single-post__content" [style.background-color]="blogService.getCategoryColor(categories, post.categories)">
                    <div class="meta">
                        <span *ngIf="post.categories && post.categories.length > 0" [innerHtml]="blogService.getColoredCategories(categories, post.categories)"></span>
                        <span *ngIf="post.categories && post.categories.length > 0">&nbsp;|&nbsp;</span>
                        <time *ngIf="data.showPublicationDate === 'active'" class="date">{{post.dateCreated}}</time>
                    </div>
                    <h3 class="font-bold">{{post.header}}</h3>
                    <h4>{{post.subHeader}}</h4>
                    <img [src]="blogNavigationIcon || 'assets/img/right-arrow.png'" class="blog-navigation-icon" alt="" />
                </div>
            </a>
            </li>
        </ul>
    </div>
</div>

<nova-font-loader *ngIf="blogFonts" [fonts]="blogFonts" class="hidden"></nova-font-loader>
