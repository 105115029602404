import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
var PageExternalInlineComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageExternalInlineComponent, _super);
    function PageExternalInlineComponent(nova, uiEventHandler, meta, title, route, router) {
        var _this = _super.call(this, nova, uiEventHandler, route, router, meta, title) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.meta = meta;
        _this.title = title;
        _this.route = route;
        _this.router = router;
        _this.minHeight = 600;
        _this.heightCalculationMethod = 'max';
        _this.scrolling = false;
        _this.customPhantomEvent = true;
        return _this;
    }
    PageExternalInlineComponent.prototype.ngOnInit = function () {
        if (typeof window !== 'undefined') {
            if (this.data.external) {
                if (this.data.external.substring(0, 7) === '<iframe') {
                    var tmp = document.createElement('div');
                    tmp.innerHTML = this.data.external;
                    var src = tmp.querySelector('iframe').getAttribute('src');
                    this.iframeSrc = src;
                }
                else {
                    this.iframeSrc = this.data.external;
                }
                this.scrolling = this.data.scrolling || this.scrolling;
                this.minHeight = this.data.minHeight || this.minHeight;
                this.heightCalculationMethod = this.data.heightCalculationMethod || this.heightCalculationMethod;
                var self_1 = this;
                setTimeout(function () {
                    self_1.uiEventHandler.onScreenShotEvent(self_1.data.id);
                }, 5000);
            }
        }
    };
    PageExternalInlineComponent = tslib_1.__decorate([
        NovaTemplate('PageExternalInlineComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            Meta,
            Title,
            ActivatedRoute,
            Router])
    ], PageExternalInlineComponent);
    return PageExternalInlineComponent;
}(Page));
export { PageExternalInlineComponent };
