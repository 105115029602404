import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './book-scroll-slider.component.html',
    styleUrls: ['./book-scroll-slider.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageBookScrollSliderComponent')
export class PageBookScrollSliderComponent extends Page implements OnInit {
    public media;
    public sliderHeight = '100vh';

    @ViewChild('royalSlider') private royalSlider;

    constructor(
        public nova: NovaAPIService,
        public sanitizer: DomSanitizer,
        public mediaService: MediaService,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    ngOnInit() {
        this.sliderHeight = $('body').width() < 991 ? `${this.data.mediaSize}vh` : '100vh';

        this.mediaService.loadMedia(this.data.medias, true).then(data => {
            this.media = data.map(val => {
                val.pos = this.calcPosition(val.org['description_position'] || 'center-center');

                return val;
            });

            setTimeout(() => {
                const options = {
                    autoScaleSlider: false,
                    navigateByClick: false,
                    arrowsNav: true,
                    arrowsNavAutoHide: true,
                    arrowsNavHideOnTouch: true,
                    imageScalePadding: 0,
                    imageScaleMode: 'fill',
                    loop: true,
                    keyboardNavEnabled: false,
                    globalCaption: true,
                    imgHeight: 800
                };

                if (this.data.showThumbs && this.data.showThumbs === 'yes' && !this.uiEventHandler.isMobile()) {
                    options['controlNavigation'] = 'thumbnails';
                    options['thumbs'] = {
                        appendSpan: true,
                        firstMargin: true,
                        paddingBottom: 4,
                        fitInViewport: false
                    };
                }
                if (this.data.slider_speed && this.data.slider_speed !== 0) {
                    options['autoPlay'] = {
                        enabled: true,
                        stopAtAction: false,
                        pauseOnHover: true,
                        delay: this.data.slider_speed * 1000
                    };
                }
                $(this.royalSlider.nativeElement).royalSlider(options);
            }, 100);
        });
    }

    getCopyrightUrl(c) {
        if (c) {
            if (!c.startsWith('http://') && !c.startsWith('https://')) {
                return 'http://' + c;
            }

            return c;
        }
    }

    getMediaUrl(data) {
        return this.mediaService.getMediaUrlByData(
            data,
            !this.uiEventHandler.isMobile()
                ? !this.uiEventHandler.isTablet()
                    ? 'vertical-wide'
                    : 'horizontal-wide'
                : 'horizontal-wide'
        );
    }

    calcPosition(posLogo) {
        let logoStyle = '';
        let flexboxStyle = '';
        const posLogoArr = posLogo.split('-');

        if (posLogoArr[0] === 'top') {
            flexboxStyle += 'justify-content: flex-start;';
        } else if (posLogoArr[0] === 'bottom') {
            flexboxStyle += 'justify-content: flex-end;';
        } else if (posLogoArr[0] === 'center') {
            flexboxStyle += 'justify-content: center;';
        }

        if (posLogoArr[1] === 'left') {
            logoStyle += 'align-self: flex-start;text-align: left;';
        } else if (posLogoArr[1] === 'right') {
            logoStyle += 'align-self: flex-end;text-align: right;';
        } else if (posLogoArr[1] === 'center') {
            logoStyle += 'align-self: center;text-align: center;';
        }

        return {
            logo: this.convertCSS(logoStyle),
            flexbox: this.convertCSS(flexboxStyle)
        };
    }

    convertCSS(css) {
        const input = css.toString();
        if (input.indexOf(':') > -1) {
            const result = {},
                attributes = input.split(';');
            for (let i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    const entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }

            return result;
        }

        return css;
    }

    setMyStyles(obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    }
}
