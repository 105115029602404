import { Subscription } from 'rxjs';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    OnDestroy,
    OnChanges,
    ViewEncapsulation,
    Inject
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { UIEventHandlerService } from '../../_services/uievent-handler.service';
import { NovaAPIService } from '../../_services/nova-api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaService } from 'app/_services/media.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MagazineService } from '../../_services/magazine.service';
import { switchMap, tap, delay } from 'rxjs/operators';
import * as Plyr from 'plyr';
import { CookieService } from '../../_services/cookie.service';

declare var $: any;

@Component({
    selector: 'nova-text-element',
    templateUrl: './nova-text-element.component.html',
    styleUrls: ['./nova-text-element.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NovaTextElementComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    private columnizeSub: Subscription;

    @Output() openGallery: EventEmitter<any> = new EventEmitter<any>();
    @Input() public data;
    @Input() public wait = 200;
    @Input() public clickListeners = true;
    @Input() public myid;
    @Input() public columnize: boolean;
    @Input() public initAfter = true;
    @ViewChild('text') private textElm: ElementRef;

    public htmlData;
    public font;
    public loaded = false;

    constructor(
        public uiEventHandler: UIEventHandlerService,
        public media: MediaService,
        public nova: NovaAPIService,
        public router: Router,
        public sanitizer: DomSanitizer,
        public route: ActivatedRoute,
        private mag: MagazineService,
        public cookieService: CookieService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.columnizeSub = this.mag
            .getMagazine()
            .pipe(
                switchMap(data => this.nova.getFont(data.navigation.font)),
                tap(fontData => {
                    this.font = fontData.name;
                }),
                switchMap(() => this.uiEventHandler.columnizeEvent$),
                delay(100)
            )
            .subscribe(() => this.init());
    }

    ngOnInit() {
        this.data = this.cookieService.createCookieCompliantContent(this.data);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data.currentValue !== changes.data.previousValue) {
            this.htmlData = this.data ? this.cookieService.createCookieCompliantContent(this.data) : '';
        }
    }

    ngOnDestroy() {
        this.columnizeSub.unsubscribe();
    }

    private handleButtonClick(e) {
        const params = this.route.params['value'];

        const handle =
            this.nova.findPage($(e.currentTarget).data('item-id')) ||
            this.nova.findContent($(e.currentTarget).data('item-id'));

        this.router.navigate(
            ['/', params['langHandle'], params['issueHandle'], handle.page, handle.floor ? handle.floor : ''],
            {
                queryParamsHandling: 'preserve'
            }
        );
    }

    public turnOffClick() {
        const $element = $(this.textElm.nativeElement);
        $element.off('click', '.figure.img.hover .inner');
    }

    public turnOnClick() {
        const $element = $(this.textElm.nativeElement);
        setTimeout(() => {
            $element.off('click', '.figure.img.hover .inner').on('click', '.figure.img.hover .inner', elm => {
                if (
                    $(elm.currentTarget)
                        .find('img')
                        .data('source')
                ) {
                    this.openGallery.emit({
                        src: $(elm.currentTarget)
                            .find('img')
                            .data('source'),
                        id: this.myid
                    });

                    window.scrollTo(0, 0);
                } else {
                    this.openGallery.emit({
                        url: $(elm.currentTarget)
                            .find('img')
                            .attr('src'),
                        id: this.myid
                    });
                }
            });
        }, this.wait);
    }

    init() {
        if (!this.textElm || !this.textElm.nativeElement) {
            return;
        }

        const $element = $(this.textElm.nativeElement);

        this.initSocial($element);
        this.initGalleries($element);

        this.uiEventHandler.resizeVideos();

        // REMOVE DRAGGABLE class
        $('.fr-draggable').each((_key, elem) => {
            $(elem).removeClass('fr-draggable');
        });

        // REMOVE DRAGGABLE attr
        $('[draggable]').each((_key, elem) => {
            elem.removeAttribute('draggable');
        });

        const $button = $element.find(
            'button:not(.plyr__control):not(.uc-embedding-accept):not(.uc-embedding-more-info)'
        );

        $button.off('click').on('click', btn => {
            let href = $(btn.currentTarget).data('href');
            const target = $(btn.currentTarget).data('target') || '_self';

            if (href && !href.includes('https://')) {
                href = 'https://' + href;
            }

            const win = window.open(href, target);
            win.focus();
        });

        const handleHoverEffects = (btn, action) => {
            const hoverEffect = $(btn.currentTarget).data('hover-style');

            if (hoverEffect === 'invert') {
                const color = $(btn.currentTarget).data('color');
                const backgroundColor = $(btn.currentTarget).data('background-color');

                if (action === 'mouseenter') {
                    $(btn.currentTarget).css('background-color', color);
                    $(btn.currentTarget).css('color', backgroundColor);
                } else {
                    $(btn.currentTarget).css('background-color', backgroundColor);
                    $(btn.currentTarget).css('color', color);
                }
            }

            if (hoverEffect === 'dim') {
                $(btn.currentTarget).css('filter', action === 'mouseenter' ? 'brightness(0.95)' : '');
            }

            if (hoverEffect === 'brighten') {
                $(btn.currentTarget).css('filter', action === 'mouseenter' ? 'brightness(1.05)' : '');
            }
        };

        $button.hover(
            function(btn) {
                handleHoverEffects(btn, 'mouseenter');
            },
            function(btn) {
                handleHoverEffects(btn, 'mouseleave');
            }
        );

        $element.find('.figure.img').each((_, elm) => {
            if ($(elm).find('.caption').length > 0) {
                return;
            }

            const imgElm = $(elm).find('img');

            if (imgElm.data('source')) {
                this.media.getMedia(imgElm.data('source')).subscribe(data => {
                    if ($(elm).find('.caption').length > 0) {
                        return;
                    }
                    $(elm).append(this.resolveBu(imgElm, data));
                });
            } else {
                $(elm).append(this.resolveBu(imgElm, null));
            }
        });

        $element.find('.figure.video').each((_, elm) => {
            const videoElm = $(elm).find('video');

            videoElm.attr('src', null);
            videoElm.attr('data-url', null);
            videoElm.css('width', '100%');

            if (videoElm.data('video-id')) {
                this.media.getMedia(videoElm.data('video-id')).subscribe(data => {
                    if (!videoElm.data('poster')) {
                        const screen = data.versions.find(element => element.name === 'screenshot');

                        if (screen) {
                            videoElm.data('poster', screen.url);
                        }
                    }

                    if ($(elm).find('.caption').length > 0) {
                        return;
                    }
                    $(elm)
                        .find('.fr-video')
                        .append(this.resolveBu(videoElm, data));
                });
            } else {
                $(elm)
                    .find('.fr-video')
                    .append(this.resolveBu(videoElm, null));
            }

            if (typeof window !== undefined) {
                if (videoElm.data('type') === 'internal') {
                    new Plyr(videoElm[0], {});
                }
            }
        });

        if (this.clickListeners) {
            this.turnOnClick();
        }

        $element
            .off('click', 'a[data-internal-link]')
            .on('click', 'a[data-internal-link]', e => this.handleButtonClick(e));

        $element
            .off('click', 'button[data-internal-link]')
            .on('click', 'button[data-internal-link]', e => this.handleButtonClick(e));

        $element.off('click', 'button[data-internal-link]').on('click', 'button[data-internal-link]', e => {
            const params = this.route.params['value'];

            if ($(e.currentTarget).data('item-type') === 'page') {
                const handle = this.nova.findPage($(e.currentTarget).data('item-id'));
                this.router.navigate(['/', params['langHandle'], params['issueHandle'], handle.page], {
                    queryParamsHandling: 'preserve'
                });
            } else {
                const handle = this.nova.findContent($(e.currentTarget).data('item-id'));
                this.router.navigate(['/', params['langHandle'], params['issueHandle'], handle.page, handle.floor], {
                    queryParamsHandling: 'preserve'
                });
            }
        });

        $element.off('click', '.figure.link').on('click', '.figure.link', function() {
            let url = $(this)
                .find('img')
                .data('link');
            if (!url.startsWith('http://') && !url.startsWith('https://')) {
                url = 'http://' + url;
            }

            if (
                $(this)
                    .find('img')
                    .data('target') !== 'self'
            ) {
                const win = window.open(url, '_blank');
                win.focus();
            } else {
                window.location.href = url;
            }
        });
    }

    resolveBu(imgElm, imageData) {
        let ret = '';

        // Caption Title
        if (imgElm.data('bu-titel')) {
            ret += `<p>${imgElm.data('bu-titel')}</p>`;
        }

        if (imgElm.data('caption-titel')) {
            ret += `<p>${imgElm.data('caption-titel')}</p>`;
        }

        // Caption Description
        if (imgElm.data('bu-description')) {
            ret += `<p>${imgElm.data('bu-description')}</p>`;
        }

        if (imgElm.data('caption-description')) {
            ret += `<p>${imgElm.data('caption-description')}</p>`;
        }

        // Copyright
        if ((imageData && imageData.copyright) || (imageData && imageData.copyrightUrl)) {
            if (imageData.copyrightUrl) {
                if (
                    !imageData.copyrightUrl.startsWith('http://') &&
                    !imageData.copyrightUrl.startsWith('https://') &&
                    /[a-zA-Z0-9]+[.]+[a-zA-Z0-9]/g.test(imageData.copyrightUrl)
                ) {
                    imageData.copyrightUrl = 'http://' + imageData.copyrightUrl;
                }

                if (imageData.copyright) {
                    ret +=
                        '<a target="_blank" href="' +
                        imageData.copyrightUrl +
                        '">&copy; ' +
                        imageData.copyright +
                        '</a>';
                } else {
                    ret +=
                        '<a target="_blank" href="' +
                        imageData.copyrightUrl +
                        '">&copy; ' +
                        imageData.copyrightUrl.replace(/(^\w+:|^)\/\//, '').split('/')[0] +
                        '</a>';
                }
            } else {
                ret += '<p class="copyright">&copy; ' + imageData.copyright + '</p>';
            }
        }

        if (ret !== '') {
            ret =
                '<span style="text-align: inherit; font-family: ' + this.font + '" class="caption">' + ret + '</span>';
        }

        return ret;
    }

    initGalleries($element) {
        $element.find('.nova-gallery').each((key, val) => {
            $(val).html('');
            const id = $(val).data('gallery-id');
            const found = this.nova.findContent(id);

            if (found) {
                let content = this.nova.pages[found.pageIndex];
                if (content) {
                    content = content.contents[found.floorIndex];
                }
                if (content) {
                    this.media.loadMedia(content.medias).then(data => {
                        let html = '<div class="owl-carousel owl-theme">';
                        data.forEach(val => {
                            const ext = val.contentType.replace('image/', '');
                            val.filename = val.filename.replace(new RegExp('.' + ext + '$'), '');
                            val.filename = val.filename.replace(new RegExp('.jpg$'), '');
                            if (val.copyrightUrl) {
                                if (
                                    !val.copyrightUrl.startsWith('http://') &&
                                    !val.copyrightUrl.startsWith('https://')
                                ) {
                                    val.copyrightUrl = 'http://' + val.copyrightUrl;
                                }
                            }
                            const url = this.media.getMediaUrlByData(val, 'gallery');

                            const caption =
                                val.org.name || val.filename ? '<p>' + (val.org.name || val.filename) + '</p>' : '';

                            const description = val.org.description ? '<p>' + val.org.description + '</p>' : '';

                            const link = val.org.link
                                ? '<a target="_blank" href="' + val.org.link + '">' + val.org.link_text + '</a>'
                                : '';

                            const copyright = val.copyright
                                ? '<a target="_blank" href="' + val.copyrightUrl + '">&copy; ' + val.copyright + '</a>'
                                : '';

                            const isVideo = this.media.isVideo(val)
                                ? '<div class="rsBtnCenterer"><div class="rsPlayBtn"><div class="rsPlayBtnIcon"></div></div></div>'
                                : '';

                            html += `<div class="item">
                                        <div class="pos-rel">
                                            <img data-source="${val.org.media}" src="${url}" />
                                            ${isVideo}
                                        </div>
                                        <div style="font-family: ${this.font}" class="caption">
                                            ${caption}
                                            ${description}
                                            ${link}
                                            ${copyright}
                                        </div>
                                     </div>`;
                        });
                        html += '</div>';
                        $(val).html(html);
                        requestAnimationFrame(() => {
                            $(val)
                                .find('.owl-carousel')
                                .owlCarousel({
                                    items: 1,
                                    loop: true,
                                    nav: true
                                });
                            setTimeout(() => {
                                const pageHandle = this.nova.pages[found.pageIndex].handle;
                                const contentHandle = content.handle;
                                const params = this.route.params['value'];

                                $(val)
                                    .find('.owl-carousel')
                                    .off('click', '.item')
                                    .on('click', '.item', elm => {
                                        this.router.navigate(
                                            [
                                                '/',
                                                params['langHandle'],
                                                params['issueHandle'],
                                                pageHandle,
                                                contentHandle
                                            ],
                                            {
                                                queryParams: {
                                                    img: $(elm.currentTarget)
                                                        .find('img')
                                                        .data('source')
                                                },
                                                queryParamsHandling: 'merge'
                                            }
                                        );
                                    });
                            }, 50);
                        });
                    });
                }
            }
        });
    }

    initSocial($element) {
        $element.find('.fb-comment-embed, .fb-video, .fb-post').each(function() {
            $(this).html('');
        });

        $element.find('.twitter-timeline, .twitter-profile').each(function() {
            if ($(this).hasClass('loaded')) {
                return;
            }
            $(this)
                .addClass('loaded')
                .hide();
            $(this)
                .parent()
                .append('<a class="twitter-timeline loaded" href="' + $(this).data('href') + '"></a>');
        });

        $element.find('.nova-twitter [data-tweet]').each(function() {
            if ($(this).hasClass('loaded')) {
                return;
            }
            $(this)
                .addClass('loaded')
                .hide();
            const elm = $(decodeURIComponent(atob($(this).data('tweet'))));
            $(this)
                .parent()
                .append(elm);
        });

        $element.find('.nova-soundcloud [data-soundcloud]').each(function() {
            if ($(this).hasClass('loaded')) {
                return;
            }
            $(this)
                .addClass('loaded')
                .hide();
            const elm = $(decodeURIComponent(atob($(this).data('soundcloud'))));
            $(this)
                .parent()
                .append(elm);
        });

        $element.find('.nova-instagram [data-instagram]').each(function() {
            if ($(this).hasClass('loaded')) {
                return;
            }
            $(this)
                .addClass('loaded')
                .hide();
            const elm = $(decodeURIComponent(atob($(this).data('instagram'))));
            $(this)
                .parent()
                .append(elm);
        });

        if (typeof twttr !== 'undefined') {
            twttr.widgets.load();
        }

        if (typeof FB !== 'undefined') {
            FB.init({
                xfbml: true,
                version: 'v2.10'
            });
        }

        if (typeof instgrm !== 'undefined') {
            instgrm.Embeds.process();
        }
    }

    ngAfterViewInit() {
        if (typeof window !== 'undefined' && this.initAfter !== false) {
            setTimeout(() => {
                this.init();
            }, 100);
        }
    }
}
