/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./nova-nav-border-item.component";
import * as i2 from "../../_services/nova-api.service";
import * as i3 from "../../_services/magazine.service";
import * as i4 from "../../_services/uievent-handler.service";
import * as i5 from "@angular/platform-browser";
var styles_NovaNavBorderItemComponent = ["\n            nova-nav-border-item > span {\n                opacity: 0.4;\n            }\n            nova-nav-border-item > span.active {\n                opacity: 1;\n            }\n        "];
var RenderType_NovaNavBorderItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NovaNavBorderItemComponent, data: {} });
export { RenderType_NovaNavBorderItemComponent as RenderType_NovaNavBorderItemComponent };
export function View_NovaNavBorderItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵncd(null, 0)], null, null); }
export function View_NovaNavBorderItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "nova-nav-border-item", [], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "touchstart"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onMouseEnter($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onMouseLeave($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).onTouchStart($event.target) !== false);
        ad = (pd_2 && ad);
    } if (("touchend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1).onTouchEnd($event.target) !== false);
        ad = (pd_3 && ad);
    } return ad; }, View_NovaNavBorderItemComponent_0, RenderType_NovaNavBorderItemComponent)), i0.ɵdid(1, 245760, null, 0, i1.NovaNavBorderItemComponent, [i2.NovaAPIService, i3.MagazineService, i4.UIEventHandlerService, i5.DomSanitizer, i0.ElementRef, i0.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NovaNavBorderItemComponentNgFactory = i0.ɵccf("nova-nav-border-item", i1.NovaNavBorderItemComponent, View_NovaNavBorderItemComponent_Host_0, { style: "style", activeStyle: "activeStyle", forceClick: "forceClick", hoverable: "hover", inverted: "inverted" }, {}, ["*"]);
export { NovaNavBorderItemComponentNgFactory as NovaNavBorderItemComponentNgFactory };
