import { Component, OnInit, ViewEncapsulation, AfterContentInit, Renderer2, OnDestroy } from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MagazineService } from '../../../_services/magazine.service';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';

@Component({
    templateUrl: './index-cover.component.html',
    styleUrls: ['./index-cover.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageIndexCoverComponent')
export class PageIndexCoverComponent extends Page implements AfterContentInit, OnDestroy, OnInit {
    public apiUrl: string;
    private sub: Subscription;
    public pages;
    public logoStyle;
    public flexboxStyle;
    public font;

    constructor(
        public nova: NovaAPIService,
        public mag: MagazineService,
        public renderer: Renderer2,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public translate: TranslateService,
        public router: Router,
        public route: ActivatedRoute
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
        this.customPhantomEvent = true;
    }

    ngOnInit() {
        if (typeof window !== 'undefined') {
            setTimeout(() => {
                this.uiEventHandler.onScreenShotEvent(this.data.id);
                $('.page-index-cover .bg').removeClass('animated slideInLeft fadeScaleUp');
            }, 3000);
        }

        const logoStyle = {};
        const flexboxStyle = {};

        const posLogo = this.data['logoPosition'];
        const posLogoArr = posLogo.split('-');

        if (posLogoArr[0] === 'top') {
            flexboxStyle['justify-content'] = 'flex-start';
        } else if (posLogoArr[0] === 'bottom') {
            flexboxStyle['justify-content'] = 'flex-end';
        } else if (posLogoArr[0] === 'center') {
            flexboxStyle['justify-content'] = 'center';
        }

        if (posLogoArr[1] === 'left') {
            logoStyle['align-self'] = 'flex-start';
        } else if (posLogoArr[1] === 'right') {
            logoStyle['align-self'] = 'flex-end';
        } else if (posLogoArr[1] === 'center') {
            logoStyle['align-self'] = 'center';
        }

        this.logoStyle = { ...logoStyle, width: this.data.logoSize ? `${this.data['logoSize'] * 10}%` : '50%' };
        this.flexboxStyle = flexboxStyle;
    }

    ngAfterContentInit() {
        this.sub = this.mag
            .getMagazine()
            .pipe(
                switchMap(data => {
                    let special;
                    const pages = this.nova.pages.filter(page => {
                        if (this.isSpecialPage(page.handle)) {
                            special = page;
                            return false;
                        }

                        return (
                            (page.templateName === 'index-hotel' ||
                                page.templateName === 'index-roundtrip' ||
                                page.templateName === 'index-trips' ||
                                !page.templateName.startsWith('index-')) &&
                            page.status === 'active' &&
                            page.showInIndex !== 'no'
                        );
                    });
                    if (special) {
                        this.pages = [special, ...pages];
                    } else {
                        this.pages = pages;
                    }
                    return this.nova.getFont(data.navigation.font);
                })
            )
            .subscribe(fontData => {
                this.font = fontData.name;
            });
    }

    isSpecialPage(handle) {
        const specialPages = [
            'zusatzartikel-abu-simbel',
            'amendment-abu-simbel',
            'additional-item-police-headquarters',
            'zusatzartikel-polizeipraesidium'
        ];
        return specialPages.indexOf(handle) > -1;
    }

    goToPage(page, floor?) {
        const params = this.router.routerState.root.firstChild.params['value'];
        if (!floor) {
            this.router.navigate(['/', params['langHandle'], params['issueHandle'], page], {
                queryParamsHandling: 'preserve'
            });
        } else {
            this.router.navigate(['/', params['langHandle'], params['issueHandle'], page, floor], {
                queryParamsHandling: 'preserve'
            });
        }
    }

    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe();
    }
}
