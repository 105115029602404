import {
    Component,
    ElementRef,
    OnInit,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import { Page } from '../../page';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { MediaService } from '../../../_services/media.service';
import { SharedHotelHelperService } from '../hotel-detail-standard/shared-hotel-helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit } from '@angular/core/src/metadata/lifecycle_hooks';
import { MultiSelectItem } from './MultiSelectItem';
import { fadeAnimation } from '../travelworld-modern/travelworld-slider/fadeIn.animation';
import { IndexTransferFilterPipe } from './filter-tags.pipe';
import { setRemainingFilters, setFilters } from '../index-trips/index-trips.component';
import { Content } from 'app/novamag-api/Types';

@Component({
    templateUrl: './index-transfer.component.html',
    styleUrls: ['./index-transfer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [SharedHotelHelperService, IndexTransferFilterPipe],
    animations: [fadeAnimation]
})
@NovaTemplate('PageIndexTransferComponent')
export class PageIndexTransferComponent extends Page implements OnInit, AfterViewInit {
    filteredData: Content[] = [];

    allInterestFilters: MultiSelectItem[] = [];
    allStartingPointsFilters: MultiSelectItem[] = [];
    allDestinationPointsFilters: MultiSelectItem[] = [];

    selectedInterests: MultiSelectItem[] = [];
    selectedStartingPoints: MultiSelectItem[] = [];
    selectedDestinationPoints: MultiSelectItem[] = [];

    interestsDropdownSettings = {
        singleSelection: false,
        text: 'Nach Transferart filtern',
        enableCheckAll: false,
        showCheckbox: true,
        enableSearchFilter: false,
        classes: 'nova-multiselect'
    };

    startingPointsDropdownSettings = {
        singleSelection: false,
        text: 'Nach Startort filtern',
        enableCheckAll: false,
        showCheckbox: true,
        enableSearchFilter: false,
        classes: 'nova-multiselect'
    };

    destinationPointsDropdownSettings = {
        singleSelection: false,
        text: 'Nach Zielort filtern',
        enableCheckAll: false,
        showCheckbox: true,
        enableSearchFilter: false,
        classes: 'nova-multiselect'
    };

    contentFontStyle = '';
    allInterestFilterStrings = [];
    allStartingPointsFilterStrings = [];
    allDestinationPointsFilterStrings = [];

    @ViewChild('font_size') private fontSizeElement: ElementRef;
    @ViewChild('filter_font') private filterFontElement: ElementRef;
    @ViewChildren('transferDetailHeaderElements') private transferDetailHeaderElements: QueryList<ElementRef>;

    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public sanitizer: DomSanitizer,
        public meta: Meta,
        public route: ActivatedRoute,
        public router: Router,
        public transferHelper: SharedHotelHelperService,
        public media: MediaService,
        public title: Title,
        private renderer: Renderer2,
        private filterPipe: IndexTransferFilterPipe
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
        const path = window.location.pathname;
        const filter = sessionStorage.getItem('filter:' + path);

        if (filter) {
            const filterData = JSON.parse(filter);
            if (filterData.selectedInterestFilters) {
                this.selectedInterests = filterData.selectedInterests;
            }

            if (filterData.selectedStartingPoints) {
                this.selectedStartingPoints = filterData.selectedStartingPoints;
            }

            if (filterData.selectedDestinationPoints) {
                this.selectedDestinationPoints = filterData.selectedDestinationPoints;
            }
        }
    }

    ngOnInit(): void {
        this.data.contents.forEach((content: Content) =>
            this.transferHelper.handleTagInputs(content, [
                'interests',
                'categories',
                'startingpoint',
                'destinationpoint'
            ])
        );

        this.allInterestFilters = setFilters(this.allInterestFilterStrings, this.data.contents, 'interests');

        this.allStartingPointsFilters = setFilters(
            this.allStartingPointsFilterStrings,
            this.data.contents,
            'startingpoint'
        );

        this.allDestinationPointsFilters = setFilters(
            this.allDestinationPointsFilterStrings,
            this.data.contents,
            'destinationpoint'
        );

        this.filter();
    }

    filter() {
        this.filteredData = this.filterPipe.transform(
            this.data.contents,
            this.selectedInterests,
            this.selectedStartingPoints,
            this.selectedDestinationPoints
        );

        // FTI-83 - dont show transfers without name
        this.filteredData = this.filteredData.filter(transfer => transfer.header !== undefined);

        // setRemainingFilters(this.allFilters, this.filteredData, 'transfer_categories');
        setRemainingFilters(this.allStartingPointsFilters, this.filteredData, 'startingpoint');

        setRemainingFilters(this.allDestinationPointsFilters, this.filteredData, 'destinationpoint');

        const path = window.location.pathname;
        sessionStorage.setItem(
            'filter:' + path,
            JSON.stringify({
                selectedInterestFilters: this.selectedInterests,
                selectedStartingPoints: this.selectedStartingPoints,
                selectedDestinationPoints: this.selectedDestinationPoints
            })
        );
    }

    ngAfterViewInit() {
        if (this.data['font']) {
            this.nova.getFont(this.data['font']).subscribe(fontData => {
                this.renderer.setStyle(this.fontSizeElement.nativeElement, 'font-family', fontData.name);
            });
        }

        if (this.data['filter_font'] && this.filterFontElement) {
            this.nova.getFont(this.data['filter_font']).subscribe(fontData => {
                this.renderer.setStyle(this.filterFontElement.nativeElement, 'font-family', fontData.name);
            });
        }

        if (this.data['transfer_detail_header_font']) {
            this.nova.getFont(this.data['transfer_detail_header_font']).subscribe(fontData => {
                this.transferDetailHeaderElements &&
                    this.transferDetailHeaderElements.toArray().map(element => {
                        this.renderer.setStyle(element.nativeElement, 'font-family', fontData.name);
                    });
            });
        }

        if (this.data['transfer_detail_font']) {
            this.nova.getFont(this.data['transfer_detail_font']).subscribe(fontData => {
                this.contentFontStyle = fontData.name;
            });
        }
    }

    goTo(id) {
        const floor = this.data.contents.filter(content => content._id === id)[0];
        const params = this.route.params['value'];

        this.router.navigate(['/', params['langHandle'], params['issueHandle'], params['pageHandle'], floor.handle], {
            queryParamsHandling: 'preserve'
        });
    }
}
