<div class="plain error-page-wrapper background-color background-image">
	<div class="content-container">
		<div class="head-line secondary-text-color">
			{{ code || 404 }}
		</div>

		<div *ngIf="!code || code === 404">
			<div class="subheader primary-text-color">
				Oops, the page you're <br />
				looking for does not exist.
			</div>

			<hr />
			<div class="clearfix"></div>
			<div class="context primary-text-color">
				<!-- doesn't use context_content because it's ALWAYS the same thing -->
				<p>
					You may want to head back to the Magazine.<br />
					If you think something is broken, report a problem.
				</p>
			</div>
			<div class="buttons-container">
				<a class="border-button" href="/">Go to Magazine</a>
				<a class="border-button" href="mailto:support@novamag.de" target="_blank">Report a problem</a>
			</div>
		</div>

		<div *ngIf="code === 401">
			<div class="subheader primary-text-color">
				Oops, you're not authorized<br />
				to read this magazine.
			</div>

			<hr />
			<div class="clearfix"></div>
			<div class="context primary-text-color">
				<!-- doesn't use context_content because it's ALWAYS the same thing -->
				<p>
					You may want to head back to the Login.<br />
					If you think something is broken, report a problem.
				</p>
			</div>
			<div class="buttons-container">
				<a class="border-button" href="login">Go to Login</a>
				<a class="border-button" href="mailto:support@novamag.de" target="_blank">Report a problem</a>
			</div>
		</div>

		<div *ngIf="code === 403">
			<div class="subheader primary-text-color">
				Oops, you're not authorized<br />
				to read this magazine.
			</div>

			<hr />
			<div class="clearfix"></div>
			<div class="context primary-text-color">
				<!-- doesn't use context_content because it's ALWAYS the same thing -->
				<p>
					You may want to head back to the Login.<br />
					If you think something is broken, report a problem.
				</p>
			</div>
			<div class="buttons-container">
				<a class="border-button" href="login">Go to Login</a>
				<a class="border-button" href="mailto:support@novamag.de" target="_blank">Report a problem</a>
			</div>
		</div>
	</div>
</div>
