import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PageSingleBackgroundComponent } from '../single-background/single-background.component';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
//import 'owl.carousel/dist/owl.carousel'
var PageMultipleBackgroundComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageMultipleBackgroundComponent, _super);
    function PageMultipleBackgroundComponent(nova, uiEventHandler, sanitizer, meta, title, router, route) {
        var _this = _super.call(this, nova, uiEventHandler, sanitizer, meta, title, route, router) || this;
        _this.nova = nova;
        _this.uiEventHandler = uiEventHandler;
        _this.sanitizer = sanitizer;
        _this.meta = meta;
        _this.title = title;
        _this.router = router;
        _this.route = route;
        _this.activeSlide = 0;
        return _this;
    }
    PageMultipleBackgroundComponent.prototype.ngOnInit = function () {
        this.numSlides = this.data.medias.length;
    };
    PageMultipleBackgroundComponent.prototype.getId = function (id, key) {
        return id + '-' + key;
    };
    PageMultipleBackgroundComponent.prototype.isActive = function ($index) {
        // if (this.uiEventHandler.isMobile()) return true;
        return $index === this.activeSlide;
    };
    PageMultipleBackgroundComponent.prototype.goDown = function () {
        this.activeSlide++;
        if (this.activeSlide >= this.numSlides - 1) {
            this.activeSlide = this.numSlides - 1;
        }
        this.nova.setMetaData(this.data.medias[this.activeSlide].header || this.data.name);
    };
    PageMultipleBackgroundComponent.prototype.goUp = function () {
        this.activeSlide--;
        if (this.activeSlide <= 0) {
            this.activeSlide = 0;
        }
        this.nova.setMetaData(this.data.medias[this.activeSlide].header || this.data.name);
    };
    PageMultipleBackgroundComponent.prototype.slideLeft = function () {
        this.activeSlide--;
        if (this.activeSlide <= 0) {
            this.activeSlide = 0;
        }
        // $(this.owl.nativeElement).trigger('prev.owl.carousel');
    };
    PageMultipleBackgroundComponent.prototype.slideRight = function () {
        this.activeSlide++;
        if (this.activeSlide >= this.numSlides - 1) {
            this.activeSlide = this.numSlides - 1;
        }
        // $(this.owl.nativeElement).trigger('next.owl.carousel');
    };
    PageMultipleBackgroundComponent.prototype.swipe = function (e, when) {
        var coord = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
        var time = new Date().getTime();
        if (when === 'start') {
            this.swipeCoord = coord;
            this.swipeTime = time;
        }
        else if (when === 'end') {
            var direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
            var duration = time - this.swipeTime;
            if (duration < 1000 && //
                Math.abs(direction[0]) > 30 && // Long enough
                Math.abs(direction[0]) > Math.abs(direction[1] * 3)) {
                // Horizontal enough
                var swipe = direction[0] < 0 ? 'next' : 'previous';
                if (swipe === 'next') {
                    this.slideRight();
                }
                if (swipe === 'previous') {
                    this.slideLeft();
                }
            }
        }
    };
    PageMultipleBackgroundComponent.prototype.ngAfterViewInit = function () {
        // if (this.uiEventHandler.isMobile() && typeof window !== 'undefined') {
        //     setTimeout(() => {
        //         $(this.owl.nativeElement).owlCarousel({
        //             items: 1,
        //             responsive: {
        //                 767: {
        //                     mouseDrag: false,
        //                     touchDrag: false
        //                 }
        //             }
        //         });
        //     }, 100);
        // }
        Array.from(document.getElementsByClassName('slide')).forEach(function (element, index) {
            var css = ".slide-" + index + " .img-copyright {z-index: " + (10 + index) + ";}";
            var style = document.createElement('style');
            style.type = 'text/css';
            style.appendChild(document.createTextNode(css));
            element.appendChild(style);
        });
    };
    PageMultipleBackgroundComponent.prototype.convertCSS = function (css) {
        var input = css.toString();
        if (input.indexOf(':') > -1) {
            var result = {}, attributes = input.split(';');
            for (var i = 0; i < attributes.length; i++) {
                if (attributes[i].length > 0) {
                    var entry = attributes[i].split(':');
                    result[entry.splice(0, 1)[0]] = entry
                        .join(':')
                        .trim()
                        .replace(/"/g, '');
                }
            }
            return result;
        }
        return css;
    };
    PageMultipleBackgroundComponent.prototype.setMyStyles = function (obj) {
        if (typeof obj !== 'undefined') {
            return obj;
        }
    };
    PageMultipleBackgroundComponent = tslib_1.__decorate([
        NovaTemplate('PageMultipleBackgroundComponent'),
        tslib_1.__metadata("design:paramtypes", [NovaAPIService,
            UIEventHandlerService,
            DomSanitizer,
            Meta,
            Title,
            Router,
            ActivatedRoute])
    ], PageMultipleBackgroundComponent);
    return PageMultipleBackgroundComponent;
}(PageSingleBackgroundComponent));
export { PageMultipleBackgroundComponent };
