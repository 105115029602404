/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hotel-pricing.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./hotel-pricing.component";
import * as i4 from "../../../../_services/nova-api.service";
var styles_HotelPricingComponent = [i0.styles];
var RenderType_HotelPricingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HotelPricingComponent, data: {} });
export { RenderType_HotelPricingComponent as RenderType_HotelPricingComponent };
function View_HotelPricingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "bar-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "bar HOVER pricing-bar"]], [[1, "data-name", 0], [1, "data-cmiid", 0], [1, "data-price", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_v.context.$implicit.ref && _co.openLink(_v.context.$implicit)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "height": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "price"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, [[2, 0], ["title", 1]], null, 1, "div", [["class", "bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), (_l()(), i1.ɵeld(10, 0, [[3, 0], ["text", 1]], null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 4, 0, _co.getBarHeight(_v.context.$implicit)); _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.header; var currVal_1 = _co.data.cmiid; var currVal_2 = _v.context.$implicit.value; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _v.context.$implicit.value; _ck(_v, 7, 0, currVal_4); var currVal_5 = _v.context.$implicit.title; _ck(_v, 9, 0, currVal_5); var currVal_6 = _v.context.$implicit.text; _ck(_v, 11, 0, currVal_6); }); }
export function View_HotelPricingComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { elm: 0 }), i1.ɵqud(671088640, 2, { titleElements: 1 }), i1.ɵqud(671088640, 3, { textElements: 1 }), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["elm", 1]], null, 2, "div", [["class", "price-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HotelPricingComponent_1)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.prices; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_HotelPricingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hotel-pricing", [], null, null, null, View_HotelPricingComponent_0, RenderType_HotelPricingComponent)), i1.ɵdid(1, 5357568, null, 0, i3.HotelPricingComponent, [i1.PLATFORM_ID, i4.NovaAPIService, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HotelPricingComponentNgFactory = i1.ɵccf("app-hotel-pricing", i3.HotelPricingComponent, View_HotelPricingComponent_Host_0, { data: "data", name: "name", font_name: "font_name", title_font_name: "title_font_name", text_font_name: "text_font_name", ignoreMobile: "ignoreMobile" }, {}, []);
export { HotelPricingComponentNgFactory as HotelPricingComponentNgFactory };
