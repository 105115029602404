<div id="page-{{ id }}" class="page page-index-issue">
	<div class="left" [hidden]="mobile">
		<nova-bg [data]="data" [media]="data.backgroundMediaLeft" [bgColor]="data.backgroundColorLeft"></nova-bg>
		<nova-scrollbar-holder #leftHolder>
			<div class="scroller">
				<div class="bg clear" #left [class.hideImg]="data.showImagesLeft !== 'yes'">
					<div *ngIf="data.logoLeft && data.logoLeft.url" class="logo" (click)="goToPage(urlLeft)">
						<img [src]="data.logoLeft.url" alt="Logo" />
					</div>
					<h1 *ngIf="data.headerLeft"><nova-element [data]="data" name="headerLeft"></nova-element></h1>
					<h2 *ngIf="data.subHeaderLeft"><nova-element [data]="data" name="subHeaderLeft"></nova-element></h2>
					<div class="item" *ngFor="let value of pagesLeft; let key = index" tabindex="-1">
						<img
							(click)="goToPage(value.handle)"
							*ngIf="value.thumbnail && !nova.isThumbLoading(value.thumbnail)"
							[src]="value.thumbnail.url"
						/>
						<img
							(click)="goToPage(value.handle)"
							*ngIf="!value.thumbnail || nova.isThumbLoading(value.thumbnail)"
							[src]="'assets/img/templates/pages/' + value.templateName + '.svg'"
						/>
						<div class="right-con">
							<b (click)="goToPage(value.handle)" class="title">{{
								uiEventHandler.getContentTableEntryName(value)
							}}</b>
							<span
								(click)="goToPage(value.handle, floor.handle)"
								*ngFor="let floor of value.contents; let key2 = index"
							>
								<b>&bull; {{ getType(floor.templateName) | async }} //</b>
								{{ floor.index_title || floor.header || floor.name }}
							</span>
						</div>
					</div>
				</div>
			</div>
		</nova-scrollbar-holder>
	</div>
	<div class="right" [hidden]="mobile">
		<nova-bg [data]="data" [media]="data.backgroundMediaRight" [bgColor]="data.backgroundColorRight"></nova-bg>
		<div class="scrollbar-holder" #rightHolder>
			<div class="scroller">
				<div class="bg clear" #right [class.hideImg]="data.showImagesRight !== 'yes'">
					<div *ngIf="data.logoRight && data.logoRight.url" class="logo" (click)="goToIssue(urlRight)">
						<img [src]="data.logoRight.url" alt="Logo" />
					</div>
					<h1 *ngIf="data.headerRight"><nova-element [data]="data" name="headerRight"></nova-element></h1>
					<h2 *ngIf="data.subHeaderRight">
						<nova-element [data]="data" name="subHeaderRight"></nova-element>
					</h2>
					<div class="item" *ngFor="let value of pagesRight; let key = index" tabindex="-1">
						<img
							(click)="goToIssue(value.handle)"
							*ngIf="value.thumb && !nova.isThumbLoading(value.thumb)"
							[src]="value.thumb.url"
						/>
						<img
							(click)="goToIssue(value.handle)"
							*ngIf="!value.thumb || nova.isThumbLoading(value.thumb)"
							[src]="'assets/img/templates/pages/' + value.templateName + '.svg'"
						/>
						<div class="right-con">
							<b class="title" (click)="goToIssue(value.handle)" target="_blank">{{ value.name }}</b>
							<span
								class="contentTitle"
								*ngFor="let floor of value.contents; let key2 = index"
								(click)="goToIssue(floor.handle)"
							>
								{{ floor.name }} <b>// {{ getType(floor.templateName) | async }} &bull;</b>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="scrollbar-holder" [hidden]="!mobile">
		<div class="scroller">
			<div class="bg clear" #leftMobile [class.hideImg]="data.showImagesLeft !== 'yes'">
				<nova-bg
					[data]="data"
					[media]="data.backgroundMediaLeft"
					[bgColor]="data.backgroundColorLeft"
				></nova-bg>
				<div *ngIf="data.logoLeft && data.logoLeft.url" class="logo" (click)="goToPage(urlLeft)">
					<img [src]="data.logoLeft.url" alt="Logo" />
				</div>
				<h1 *ngIf="data.headerLeft"><nova-element [data]="data" name="headerLeft"></nova-element></h1>
				<h2 *ngIf="data.subHeaderLeft"><nova-element [data]="data" name="subHeaderLeft"></nova-element></h2>
				<div class="item" *ngFor="let value of pagesLeft; let key = index" tabindex="-1">
					<img
						(click)="goToPage(value.handle)"
						*ngIf="value.thumbnail && !nova.isThumbLoading(value.thumbnail)"
						[src]="value.thumbnail.url"
					/>
					<img
						(click)="goToPage(value.handle)"
						*ngIf="!value.thumbnail || nova.isThumbLoading(value.thumbnail)"
						[src]="'assets/img/templates/pages/' + value.templateName + '.svg'"
					/>
					<div class="right-con">
						<b (click)="goToPage(value.handle)" class="title">{{
							uiEventHandler.getContentTableEntryName(value)
						}}</b>
						<span
							(click)="goToPage(value.handle, floor.handle)"
							*ngFor="let floor of value.contents; let key2 = index"
						>
							<b>&bull; {{ getType(floor.templateName) | async }} //</b>
							{{ floor.index_title || floor.header }}
						</span>
					</div>
				</div>
			</div>
			<div class="bg clear" #rightMobile [class.hideImg]="data.showImagesRight !== 'yes'">
				<nova-bg
					[data]="data"
					[media]="data.backgroundMediaRight"
					[bgColor]="data.backgroundColorRight"
				></nova-bg>
				<div *ngIf="data.logoRight && data.logoRight.url" class="logo" (click)="goToIssue(urlRight)">
					<img [src]="data.logoRight.url" alt="Logo" />
				</div>
				<h1 *ngIf="data.headerRight"><nova-element [data]="data" name="headerRight"></nova-element></h1>
				<h2 *ngIf="data.subHeaderRight"><nova-element [data]="data" name="subHeaderRight"></nova-element></h2>
				<div class="item" *ngFor="let value of pagesRight; let key = index" tabindex="-1">
					<img
						(click)="goToIssue(value.handle)"
						*ngIf="value.thumb && !nova.isThumbLoading(value.thumb)"
						[src]="value.thumb.url"
					/>
					<img
						(click)="goToIssue(value.handle)"
						*ngIf="!value.thumb || nova.isThumbLoading(value.thumb)"
						[src]="'assets/img/templates/pages/' + value.templateName + '.svg'"
					/>
					<div class="right-con">
						<b class="title" (click)="goToIssue(value.handle)" target="_blank">{{ value.name }}</b>
						<span
							class="contentTitle"
							*ngFor="let floor of value.contents; let key2 = index"
							(click)="goToIssue(floor.handle)"
						>
							<b>&bull; {{ getType(floor.templateName) | async }} //</b> {{ floor.name }}
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
