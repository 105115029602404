<section id="page-{{ id }}" class="page-blog-article">
    <article class="container">
        <div class="content-container">
            <nova-bg [data]="data" class="background"></nova-bg>

            <div class="slider-container">
                <div *ngIf="categories && categories.length > 0" class="categories-container">
                    <div *ngFor="let category of categories">
                        <a *ngIf="category.image" [routerLink]="['../']" [queryParams]="{categories: category.name.toLowerCase()}" class="category-icon">
                            <img [src]="category.image" [alt]="category.name" />
                        </a>
                    </div>
                </div>

                <div #royalSlider class="slider">
                    <div *ngFor="let val of media" class="rsContent"
                        [ngStyle]="{'background-color':getColor(val.org['background_color'])}">
                        <img *ngIf="val.size" class="rsImg" [src]="this.getMediaUrl(val)" />

                        <div class="rsCaption center" [ngStyle]="setMyStyles(val.pos.flexbox)">
                            <div [ngStyle]="{'padding-bottom': (data.showThumbs) ? '30px' : '10px'}">
                                <span class="caption" *ngIf="val.org.description" [ngStyle]="setMyStyles(val.pos.logo)">
                                    <nova-element [data]="val.org" name="description"></nova-element>
                                </span>
                            </div>
                        </div>

                        <a target="_blank" [href]="getCopyrightUrl(val.copyrightUrl)" *ngIf="val && val.copyright"
                            class="img-copyright">&copy; {{ val.copyright }}</a>

                        <div *ngIf="!val.size" [ngStyle]="{'background-color':getColor(val.org['background_color'])}"
                            class="rsTmb rsTmbDiv"></div>
                    </div>
                </div>

                <div *ngIf="media && media.length > 1" class="slider-navigation">
                    <img [src]="blogNavigationIcon || 'assets/img/right-arrow.png'" class="slider-navigation-icon" alt="" (click)="sliderPrev()" />
                    <img [src]="blogNavigationIcon || 'assets/img/right-arrow.png'" class="slider-navigation-icon" alt="" (click)="sliderNext()" />
                </div>
            </div>

            <nova-mobile-img [data]="data" class="show-for-small-only left"></nova-mobile-img>

            <div class="content">
                <div class="bg animated fadeInUp clear" [ngStyle]="{'background-color':getColor(data['box_color'])}" [hidden]="!data.text && !data.header && !data.subHeader && !data.label">
                    <div class="meta font-regular">
                        <span *ngIf="categories && categories.length > 0" [innerHtml]="blogService.getColoredCategories(data.parentPage.categories, categories)"></span>
                        <span *ngIf="categories && categories.length > 0">&nbsp;|&nbsp;</span>
                        <time *ngIf="data.parentPage.showPublicationDate === 'active'" class="date">{{data.dateCreated}}</time>
                    </div>
                    <nova-title [data]="data"></nova-title>
                    <nova-text-element class="con" [data]="data.text" [myid]="data._id" (openGallery)="gallery.openSlider($event)"></nova-text-element>
                    <nova-content-links class="content-links" *ngIf="data.links && data.links.length > 0" [floors]="data.links"></nova-content-links>
                </div>
                <nova-text-gallery #gallery [medias]="data.textImages" [myid]="data._id"></nova-text-gallery>

            </div>
        </div>
    </article>

    <div class="container related-posts">
        <div class="content content-container">
            <div *ngIf="relatedPosts && relatedPosts.length > 0">
                <h3>Related Posts</h3>
                <ul class="ul-reset related-posts-grid">
                    <li *ngFor="let post of relatedPosts">
                        <a class="single-post" [routerLink]="'../' + post.handle" [style.background-image]="getThumbnail(post)">
                            <div class="single-post__content" [style.background-color]="blogService.getCategoryColor(data.parentPage.categories, post.categories)">
                                <h5 class="font-bold">{{post.header}}</h5>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="text-center">
                <a routerLink="../" class="back-to-index">
                    <nova-element-with-children name="backToIndexButton" [data]="data.parentPage">
                        <img [src]="blogNavigationIcon || 'assets/img/right-arrow.png'" class="blog-navigation-icon" alt="" />
                        {{data.parentPage.backToIndexButton}}
                    </nova-element-with-children>
                </a>
            </div>
        </div>
    </div>
</section>

<nova-font-loader *ngIf="blogFonts" [fonts]="blogFonts" class="hidden"></nova-font-loader>
