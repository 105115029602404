import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './video-classic.component.html',
    styleUrls: ['./video-classic.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageVideoClassicComponent')
export class PageVideoClassicComponent extends Page implements OnInit, AfterViewInit {
    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
        this.customPhantomEvent = true;
    }

    @ViewChild('iframe') elementView: ElementRef;
    iframeHeight: string;

    ngOnInit() {
        if (typeof window !== 'undefined') {
            setTimeout(() => {
                this.uiEventHandler.onScreenShotEvent(this.data.id);
            }, 2000);
        }
    }

    ngAfterViewInit() {
        this.iframeHeight =
            this.uiEventHandler.isMobile() || this.uiEventHandler.isTablet()
                ? 'auto'
                : this.elementView.nativeElement.offsetHeight + 'px';
    }
}
