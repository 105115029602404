import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NovaTemplate } from '../../../_decorators/template.decorator';
import { Page } from '../../page';
import { NovaAPIService } from '../../../_services/nova-api.service';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './topic-teaser.component.html',
    styleUrls: ['./topic-teaser.component.scss'],
    encapsulation: ViewEncapsulation.None
})
@NovaTemplate('PageTopicTeaserComponent')
export class PageTopicTeaserComponent extends Page implements OnInit {
    constructor(
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService,
        public meta: Meta,
        public title: Title,
        public route: ActivatedRoute,
        public router: Router
    ) {
        super(nova, uiEventHandler, route, router, meta, title);
    }

    ngOnInit() {}
}
