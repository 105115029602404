<div id="page-{{ id }}" class="page page-article-teaser">
	<nova-bg [data]="data" [forceVideo]="true"></nova-bg>
	<div class="bottom animated fadeInUp">
		<div class="my-clearfix">
			<nova-mobile-img [data]="data" class="show-for-small-only"></nova-mobile-img>
			<div
				class="headline fadeIn"
				#headline
				*ngIf="data.label || data.header || data.subHeader"
				[ngStyle]="setMyStyles(boxStyle())"
			>
				<h3 *ngIf="data.label" class="dateline" ng-class="classes">
					<nova-element [data]="data" name="label"></nova-element>
				</h3>
				<h1 *ngIf="data.header" class="org"><nova-element [data]="data" name="header"></nova-element></h1>
				<br *ngIf="data.subHeader" />
				<h1 *ngIf="data.subHeader"><nova-element [data]="data" name="subHeader"></nova-element></h1>
			</div>
		</div>

		<div class="text-container fadeIn" *ngIf="data.text || (data.links && data.links.length > 0)">
			<div *ngIf="data.text" class="text" [ngStyle]="setMyStyles(boxStyle())">
				<nova-text-element
					class="con"
					[data]="data.text"
					(openGallery)="gallery.openSlider($event)"
				></nova-text-element>
			</div>
			<nova-content-links
				[ngStyle]="setMyStyles(boxStyleBottom())"
				class="content-links"
				*ngIf="data.links && data.links.length > 0"
				[floors]="data.links"
			></nova-content-links>
		</div>
	</div>
	<nova-text-gallery [medias]="data.textImages" #gallery></nova-text-gallery>
</div>
