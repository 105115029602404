import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UIEventHandlerService } from '../../../_services/uievent-handler.service';
import { ArchiveTemplate } from '../ArchiveTemplate';
import { MagazineService } from '../../../_services/magazine.service';
import { NovaAPIService } from '../../../_services/nova-api.service';

@Component({
    templateUrl: './archive-scroll.component.html',
    styleUrls: ['./archive-scroll.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ArchiveScrollComponent extends ArchiveTemplate implements OnInit {
    constructor(
        public mag: MagazineService,
        public nova: NovaAPIService,
        public uiEventHandler: UIEventHandlerService
    ) {
        super(mag, nova, uiEventHandler);
    }

    ngOnInit() {}
}
