<div id="page-{{ id }}" class="page page-switch">

	<!-- Mobile Detail -->
	<div *ngFor="let val of media;let key = index;" [ngClass]="{opened: active == key}" class="detail scrollbar-holder" [ngStyle]="{'backgroundColor':getColor(val.org['box_color'], true)}">
		<div class="scroller" *ngIf="val.org">
			<a class="back" (click)="close()" [ngStyle]="getButtonStyling(val.org)"><i class="icon-arrow-left"></i> Zurück</a>
			<div class="img-placeholder clear">
				<img width="1920px" height="1080px" [src]="mediaService.getImageProxyUrl(mediaService.getMediaUrlByData(val, 'horizontal-wide'),'horizontal-wide')" />
			</div>
			<a>
				<nova-element *ngIf="val.org.header" [data]="val.org" name="header"></nova-element>
			</a>
			<nova-text-element [data]="val.org.text2"></nova-text-element>
		</div>
	</div>
	<!-- Mobile Detail: END -->


	<div #mobileScroller class="mobile-scroller-handler scrollbar-holder" [ngClass]="{single: data.medias.length == 1, half: data.medias.length == 2 && !teaser}">
		<div class="scroller" [ngClass]="{hasContent: (data.links && data.links.length > 0) || data.text || data.header || data.subHeader || data.label}">

			<!-- Page Text -->
			<div #teaser class="bg teaser animated fadeInDown scrollbar-holder" [ngStyle]="{'backgroundColor':getColor(data['box_color'], true)}" [ngClass]="{fadeOutUp: active != -1, fadeInDown: active == -1}" *ngIf="teaser">
				<div class="scroller">
					<nova-text-element *ngIf="data.text" class="con" [data]="data.text" (openGallery)="gallery.openSlider($event)"></nova-text-element>
				</div>
			</div>
			<!-- Page Text:END -->

			<!-- Mobile View -->
			<div class="switch-medium">
				<div *ngFor="let val of media;let key = index;" class="entry" [ngStyle]="{'backgroundImage':getEntryStyle(val, true)}" (click)="open(key)">
					<div *ngIf="val.org.text1 != ''" class="switch-content">
						<nova-text-element [data]="val.org.text1"></nova-text-element>
					</div>
				</div>
			</div>
			<!-- Mobile View: END -->

			<!-- Desktop View -->
			<div class="switch" [ngClass]="calcWidth()">
				<div *ngFor="let val of media;let key = index;" class="slide" [ngStyle]="{'backgroundImage':getEntryStyle(val)}" (click)="open(key)" [ngClass]="{active: active == key, inactive: active != key && active != -1}">
					<div *ngIf="val.org.text1 != ''" class="switch-content">
						<nova-text-element [data]="val.org.text1"></nova-text-element>
					</div>
					<div class="bg-small animated fadeInLeft easeOutQuad" [ngStyle]="{'backgroundColor':getColor(val.org['box_color'], true)}" *ngIf="val.org.header || val.org.text2">
						<span (mouseover)="hover=true" (mouseleave)="hover=false" [ngStyle]="getButtonStyling(val.org, hover)" class="icon-cancel" (click)="close()"></span>
						<nova-scrollbar-holder>
							<div class="scroller">
								<nova-element *ngIf="val.org.header" [data]="val.org" name="header"></nova-element>
								<nova-text-element [data]="val.org.text2" (openGallery)="gallery.openSlider($event)"></nova-text-element>
							</div>
						</nova-scrollbar-holder>
					</div>
				</div>
			</div>
			<!-- Desktop View:END -->

		</div>
	</div>
	<nova-text-gallery [medias]="gallerySliderMedia" #gallery></nova-text-gallery>
</div>
