<div id="page-{{ id }}" class="page page-index-float">
    <nova-bg [data]="data" [forceVideo]="true"></nova-bg>
    <nova-scrollbar-holder>
        <div class="scroller">
            <div class="bg" ng-class="{hideImg: data.showImages != 'yes'}">
                <div class="main-container clearfix" #elm>
                    <h1 *ngIf="data.header">
                        <nova-element [data]="data" name="header"></nova-element>
                    </h1>
                    <h1 *ngIf="!data.header">{{ uiEventHandler.CONTENT_TABLE }}</h1>
                    <h2 *ngIf="data.subHeader">
                        <nova-element [data]="data" name="subHeader"></nova-element>
                    </h2>
                    <div>
                        <div class="item" *ngFor="let value of pages;let key = index;" tabIndex="-1">
                            <b [ngStyle]="{'background-color':getColor(data['box_color'], true), 'color':getColor(data['textColor'], true)}"
                                (click)="goToPage(value.handle)"
                                class="title">{{ uiEventHandler.getContentTableEntryName(value) }}</b>
                            <div class="img">
                                <img (click)="goToPage(value.handle)" *ngIf="value.thumbnail && !nova.isThumbLoading(value.thumbnail)"
                                    [src]="value.thumbnail.url" />
                                <img (click)="goToPage(value.handle)" *ngIf="!value.thumbnail || nova.isThumbLoading(value.thumbnail)"
                                    [src]="'assets/img/templates/pages/' + value.templateName+'.svg'" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nova-scrollbar-holder>
</div>
